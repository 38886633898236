import * as React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import megaphoneImage from '@/react/assets/images/help-center.svg';

const CancelRefundConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  const handleClose = async () => {
    await onConfirm();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle />
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          color: ({ palette }) => palette.grey[500],
          position: 'absolute',
          right: ({ spacing }) => spacing(2),
          top: ({ spacing }) => spacing(2.5),
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack spacing={3}>
          <Box component="img" src={megaphoneImage} sx={{ width: 128 }} />
          <Typography variant="h6" sx={{ mb: 1 }}>
            Tem certeza que deseja cancelar o pedido?
          </Typography>
        </Stack>
        <Stack spacing={2}>
          <Typography component="p" variant="body3">
            Se cancelar, terá que abrir uma nova solicitação refazendo todo o processo e aguardando as aprovações necessárias.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
      <Button color="primary" onClick={onClose}>
          Voltar
        </Button>
        <Button color="error" variant="contained" onClick={handleClose}>
          Cancelar solicitação
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelRefundConfirmationDialog;
