import * as React from 'react';
import { styled } from '@mui/material/styles';
import logoGalenaEdupass from '@/react/assets/logo-nav-galena.svg';

const LogoWrapper = styled('div')(({ theme }) => ({
  width: 80,
  [theme.breakpoints.up('md')]: {
    marginRight: theme.spacing(3),
  },
}));

const Logo = () => {
  return (
    <LogoWrapper>
      <a href="/">
        <img className="w-100" src={logoGalenaEdupass} />
      </a>
    </LogoWrapper>
  );
};

export default Logo;
