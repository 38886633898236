import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { TranslatedComponent } from 'src/app/utils/translated.component';
import { UsersService } from 'src/app/services/users.service';
import {
  NAVIGATION_ROUTES,
  CorporateUniverstyRoutes,
} from 'src/app/pages/corporate-university/corporate-university.routes';
import { RouterService } from 'src/app/services/router.service';

@Component({
  selector: 'app-corporate-university-navigation',
  templateUrl: './corporate-university-navigation.component.html',
  styleUrls: ['./corporate-university-navigation.component.scss'],
})
export class CorporateUniversityNavigationComponent
  extends TranslatedComponent
  implements OnInit
{
  readonly translationKey: string = 'CORPORATE_UNIVERSITY.NAVIGATION';

  @Input()
  menu: string = CorporateUniverstyRoutes.CATALOG;

  menus = Object.keys(NAVIGATION_ROUTES);
  activeColor: string;

  constructor(
    private routerService: RouterService,
    private router: Router,
    private userService: UsersService
  ) {
    super();
  }

  ngOnInit(): void {
    this.activeColor = this.userService.user.colaborador.empresa.primary_color;

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.checkMenu();
      }
    });

    this.checkMenu();
  }

  checkMenu() {
    const splitedUrl = this.router.url.split('/');
    this.menu = splitedUrl[splitedUrl.length - 1];
  }

  navigate(route: string) {
    this.routerService.navigate([
      'corporate_university',
      CorporateUniverstyRoutes[route],
    ]);
  }

  isActive(menu: string): boolean {
    return this.menu === CorporateUniverstyRoutes[menu];
  }

  getBoxShadow(menu: string): { [key: string]: string } {
    return this.isActive(menu)
      ? {
          boxShadow: `0px 5px ${this.activeColor}`,
        }
      : {};
  }
}
