import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

// React
import React, { ComponentProps, createElement, ElementType } from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ToastContainer } from 'react-toastify'; // TODO: don't know what's this, we probably should remove it

import theme from '@/react/@mui/theme';
import { NotificationProvider } from '@/react/components/notification';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

// Just to make sure we import React
React.version;

@Component({
  selector: 'ng-react-mui',
  template: '',
})
export class NgReactMuiComponent<Comp extends ElementType>
  implements OnChanges
{
  @Input() component: Comp;
  @Input() props: ComponentProps<Comp>;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  private root = createRoot(inject(ElementRef).nativeElement);

  ngOnChanges(changes: SimpleChanges) {
    this.render();
  }

  ngOnDestroy() {
    this.root.unmount();
  }

  private render() {
    const reactComponent = createElement(this.component, this.props);

    this.root.render(
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <NotificationProvider>
            <CssBaseline />
            {reactComponent}
          </NotificationProvider>
        </ThemeProvider>
        <ToastContainer />
      </QueryClientProvider>
    );
  }
}
