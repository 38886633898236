import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReactWrapperComponent } from './react-wrapper.component';
import Cheatsheet from '@/react/components/cheatsheet.component';
import InlineEditExample from '@/react/components/form/inline-edit-examples';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/cursos',
      },
      {
        path: 'react-cheatsheet',
        component: ReactWrapperComponent,
        data: { reactComponent: Cheatsheet, componentProps: {} },
      },
      {
        path: 'inline-edit',
        component: ReactWrapperComponent,
        data: { reactComponent: InlineEditExample, componentProps: {} },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DevRoutingModule {}
