import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import { useScreenSizes } from '@/react/utils/useScreenSizes';

export const defaultActionLabels = {
  cancel: 'Cancelar',
  confirm: 'Confirmar',
};

export interface ConfirmDialogProps {
  actionLabels?: {
    cancel?: string;
    confirm?: string;
  };
  children: React.ReactNode;
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
  title?: React.ReactNode | string;
}

export const ConfirmDialog = ({
  actionLabels = {
    cancel: defaultActionLabels.cancel,
    confirm: defaultActionLabels.confirm,
  },
  children,
  onClose,
  onConfirm,
  open,
  title,
}: ConfirmDialogProps) => {
  const { isSmallScreen } = useScreenSizes();

  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
    >
      <DialogTitle>
        <Stack
          sx={{
            alignItems: 'flex-start',
            flexDirection: 'row',
            gap: 2,
            justifyContent: 'flex-end',
          }}
        >
          {title && <Box sx={{ flex: 1 }}>{title}</Box>}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: ({ palette }) => palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions
        sx={{
          flexDirection: isSmallScreen ? 'column' : 'row',
          justifyContent: isSmallScreen ? 'center' : 'flex-end',
        }}
      >
        <Button
          color="error"
          fullWidth={isSmallScreen}
          onClick={onConfirm}
          variant="contained"
        >
          {actionLabels.confirm}
        </Button>
        <Button fullWidth={isSmallScreen} onClick={onClose} variant="text">
          {actionLabels.cancel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
