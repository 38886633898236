import { z } from 'zod';
import { createPlanSchema } from '@/react/data/idp/schemas';

type CreatePlan = z.infer<typeof createPlanSchema>;

enum PlanStatuses {
  DRAFT = 'draft',
  IN_PROGRESS = 'in_progress',
  CLOSED = 'closed',
}

type PlanResponseType = {
  id: string;
  title: string;
  description: string;
  duration_in_months: number;
  started_at: string;
  estimated_ends_at: string;
  ended_at: string;
  status: string;
};

type PlansResponseType = {
  plans: PlanResponseType[];
};

type PlanType = Omit<
  PlanResponseType,
  'duration_in_months' | 'started_at' | 'estimated_ends_at' | 'ended_at'
> & {
  durationInMonths: number;
  startedAt: string;
  estimatedEndsAt: string;
  endedAt: string;
};

export { CreatePlan, PlanStatuses, PlanResponseType, PlansResponseType, PlanType };
