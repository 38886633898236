import { Component, Input } from '@angular/core';

import { NavItem } from '@/components/banner-navigation/banner-navigation.component';
import { TranslatedComponent } from '@/utils/translated.component';

@Component({
  selector: 'app-my-refunds-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class MyRefundsNavigationComponent extends TranslatedComponent {
  translationKey = 'COLABORADOR.MY_REFUNDS.NAVIGATION';

  navItems: NavItem[] = [
    {
      label: this.getTranslation('pending'),
      route: 'my_refunds/pending',
      name: 'pending',
    },
    {
      label: this.getTranslation('overview'),
      route: 'my_refunds/overview',
      name: 'overview',
    },
  ];

  @Input()
  menu: string;
}
