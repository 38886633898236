import { AbstractControl, ValidationErrors } from '@angular/forms';
import { extractNumber } from './number';

export const cpfMask = (cpf: string) => {
  let cpfNumber = extractNumber(cpf);
  return cpfNumber.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};

export function cpfValidation(cpf: string = '') {
  cpf = cpf.replace(/\D/g, '');

  if (cpf.length !== 11) {
    return false;
  }

  if (/^(\d)\1+$/.test(cpf)) {
    return false;
  }
  return true;
}

export function cpfValidator(
  control: AbstractControl
): ValidationErrors | null {
  const numberCpf = extractNumber(control.value);
  const isValid = cpfValidation(numberCpf);
  return isValid ? null : { cpf: { value: control.value } };
}
