import { CONST } from 'src/app/constants';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { getHeaders } from '@/utils/apiHeaders';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  getAll(url: string, params = {}): Observable<any> {
    const queryParams = Object.keys(params).map((key) => {
      return `${key}=${params[key]}`;
    });

    const query = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

    return this.http.get(`${CONST.API_ENDPOINT}/${url}${query}`, {
      headers: getHeaders(),
    });
  }

  post(url: string, body: any = {}): Observable<any> {
    return this.http.post(`${CONST.API_ENDPOINT}/${url}`, body, {
      headers: getHeaders(),
    });
  }

  delete(url: string): any {
    return this.http.delete(`${CONST.API_ENDPOINT}/${url}`, {
      headers: getHeaders(),
    });
  }

  put(url: string, body: any) {
    return this.http.put(`${CONST.API_ENDPOINT}/${url}`, body, {
      headers: getHeaders(),
    });
  }
}
