import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { ErrorResponse, IdpClient } from '@/react/data/idp/idp-client';
import { Activity } from '@/react/data/idp/interfaces';
import Divider from '@mui/material/Divider';
import { InlineEditTextField } from '@/react/components/form/inline-edit-textfield.component';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import * as Sentry from '@sentry/browser';
import IconButton from '@mui/material/IconButton';
import {
  NotificationProvider,
  useNotification,
} from '@/react/components/notification';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

type TUpdateField = (
  activity: Activity,
  title: string,
  description: string,
  value: string
) => Promise<boolean>;

type ComponentArgs = {
  activity: Activity;
  updateField: TUpdateField;
};

type FieldArgs = {
  title?: string;
  field: string;
} & ComponentArgs;

const ITEM_HEIGHT = 48;

const backToPlanListLink = (activityPlanId: string): string => {
  return `/pdi/planos/${activityPlanId}`;
};

const FieldComponent = ({ updateField, activity, title, field, ...rest }: FieldArgs) => {
  const onAccept = async (value: string): Promise<boolean> => {
    return await updateField(activity, title, field, value);
  };

  const value = activity[field] || '';

  return (
    <>
      {title && (
        <Typography fontSize={16} variant="h5">
          {title}
        </Typography>
      )}

      <InlineEditTextField {...rest} onAccept={onAccept} value={value} />
    </>
  );
};

const Header = ({ activity, updateField }: ComponentArgs) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = ["Deletar Atividade"];

  return (
    <Grid xs={12} item>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid
          item
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          gap={1}
        >
          <IconButton
            aria-label="voltar"
            size="small"
            href={backToPlanListLink(activity.planId)}
          >
            <ArrowBackIosIcon />
          </IconButton>

          <FieldComponent
            activity={activity}
            updateField={updateField}
            field="title"
            data-testid="title-input"
          />
        </Grid>

        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          slotProps={{
            paper: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '20ch',
              },
            },
          }}
        >
          {options.map((option) => (
            <MenuItem
              key={option}
              onClick={handleClose}
            >
              <Typography fontSize={16} variant="h5">
                {option}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </Grid>
    </Grid>
  );
};

const Body = ({ activity, updateField }: ComponentArgs) => {
  return (
    <Grid item paddingTop={2}>
      <FieldComponent
        activity={activity}
        updateField={updateField}
        title="Descrição"
        field="description"
        data-testid="description-input"
      />

      <FieldComponent
        activity={activity}
        updateField={updateField}
        title="ID do curso"
        field="courseId"
        data-testid="course-id-input"
      />
    </Grid>
  );
};

const ComponentLoading = () => {
  return (
    <Stack spacing={2} padding={3} data-testid="show-loading">
      <Grid item display="flex" justifyContent="space-between">
        <Grid
          item
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          gap={1}
          width="80%"
        >
          <Skeleton variant="rectangular" width="20%" height={20} />
          <Skeleton variant="rectangular" width="50%" height={20} />
        </Grid>
        <Skeleton variant="rectangular" width="20%" height={20} />
      </Grid>
      <Skeleton variant="rectangular" width="100%" height={40} />
      <Skeleton variant="rectangular" width="100%" height={40} />
    </Stack>
  );
};

const ComponentFetched = ({ activity, updateField }: ComponentArgs) => {
  return (
    <NotificationProvider>
      <Grid container spacing={2} padding={3} data-testid="show-activity">
        <Header activity={activity} updateField={updateField}></Header>

        <Grid xs={12} item>
          <Divider sx={{ opacity: 0.6 }} flexItem />
        </Grid>

        <Body activity={activity} updateField={updateField}></Body>
      </Grid>
    </NotificationProvider>
  );
};

const ShowActivity = ({ activityPlanId, activityId }) => {
  const [activity, setActivity] = React.useState<Activity>(null);
  const { toast } = useNotification();

  const fetch = async () => {
    try {
      const activity = await IdpClient.showPlanActivity(
        activityPlanId,
        activityId
      );

      if (activity)
        setActivity(activity);
      else
        window.location.href = backToPlanListLink(activityPlanId);
    } catch (error) {
      Sentry.captureException(error);
      window.location.href = backToPlanListLink(activityPlanId);
    }
  };

  const updateField = async (
    activity: Activity,
    title: string,
    field: string,
    value: any
  ): Promise<boolean> => {
    const response = await IdpClient.updateActivityField(
      activity,
      field,
      value
    );

    if (response instanceof Activity) {
      setActivity(response);
      return true;
    } else if (response instanceof ErrorResponse) {
      toast({
        content: <Error error={`Erro ao editar ${title}`} />,
        type: 'error',
      });
    }

    return false;
  };

  const Error = ({ error }) => {
    return <div>{error}</div>;
  };

  useEffect(() => {
    if (activityPlanId && activityId) fetch();
  }, [activityPlanId, activityId]);

  if (!activity) return <ComponentLoading />;

  return <ComponentFetched activity={activity} updateField={updateField} />;
};

ShowActivity.propTypes = {
  activityPlanId: PropTypes.string,
  activityId: PropTypes.string,
};

export { ShowActivity };
