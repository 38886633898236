import { Component, Input } from '@angular/core';

import { NavItem } from '@/components/banner-navigation/banner-navigation.component';
import { TranslatedComponent } from '@/utils/translated.component';

@Component({
  selector: 'app-refund-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class RefundNavigationComponent extends TranslatedComponent {
  translationKey = 'EMPRESA.REFUND.REFUND_NAVIGATION';

  @Input()
  menu = 'pendentes';

  navItems: NavItem[] = [
    {
      label: this.getTranslation('pendentes'),
      name: 'pendentes',
      route: 'empresa/refund/refund_pending',
    },
    {
      label: this.getTranslation('all'),
      name: 'reembolso',
      route: 'empresa/refund/refund_report',
    },
  ];
}
