<div class="modal">
  <div class="modal-overlay"></div>

  <div class="indicator d-none d-lg-block" *ngIf="navbarHighlight">
    <div class="d-flex w-100 justify-content-end">
      <img class="navbar-search m-2" [src]="getSvg('navbarSearch.svg')" />
    </div>
    <div class="row">
      <img class="second-arrow" [src]="getSvg('secondArrow.svg')" />
    </div>
  </div>

  <div class="indicator col d-none d-lg-block" *ngIf="sidebarHighlight">
    <div class="row">
      <img class="sidebar" [src]="getSvg('sidebarHighlight.svg')" />
    </div>
    <div class="row">
      <img class="first-arrow" [src]="getSvg('firstArrow.svg')" />
    </div>
  </div>

  <div class="modal-container d-flex">
    <div *ngIf="closeButton" class="modal-close">
      <button class="material-icons-outlined notranslate" (click)="onFechar()">
        close
      </button>
    </div>
    <div class="m-3 p-3 d-flex flex-column align-items-center">
      <ng-content></ng-content>
    </div>
  </div>
</div>
