import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { AccordionStep } from '@/react/components/refund/accordion/interfaces';
import AccordionColumnWrapper from '@/react/components/refund/accordion/accordion-column-wrapper.component';
import StatusIcon from '@/react/components/refund/accordion/accordion-status-icon.component';

const AccordionHeader = ({
  children,
  status,
}: {
  children?: React.ReactNode;
  status?: AccordionStep['status'];
}) => {
  return (
    <AccordionColumnWrapper>
      {!!status && (
        <Box sx={{ minWidth: ({ spacing }) => spacing(3) }}>
          <Typography
            variant="h5"
            sx={{ alignItems: 'start', display: 'flex', marginTop: 0 }}
          >
            <StatusIcon status={status} />
          </Typography>
        </Box>
      )}
      <Box sx={{ flexGrow: 1 }}>{children}</Box>
    </AccordionColumnWrapper>
  );
};

export default AccordionHeader;
