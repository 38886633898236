import Box, { BoxProps } from '@mui/material/Box';
import React, { JSX } from 'react';
import cx from 'clsx';
import type { Cast } from 'powership';

/**
 * This helper creates an object of named components from a given object definition.
 * This is util because, when using multiple Box components, it is hard
 * to inspect and debug components in the browser or in React dev tools, because
 * every component is named as "Box"
 */
export function styledBoxes<
  Definition extends {
    [K: string]: BoxProps;
  }
>(name: string, definition: Definition): DefinitionToComponents<Definition> {
  const prefix = name[0].toUpperCase() + name.slice(1);

  return Object.entries(definition).reduce(
    (acc, [name, predefinedProps]: any) => {
      const displayName = `${prefix}${name}`;

      return {
        ...acc,
        [name]: (() => {
          function NamedWrapper(runtimeProps: BoxProps) {
            const className = cx(
              displayName,
              predefinedProps.className,
              runtimeProps.className
            );

            const props = {
              ...predefinedProps,
              ...runtimeProps,
              sx: { ...predefinedProps.sx, ...runtimeProps.sx },
              className,
            };

            // inferring display flex when a flex property is used
            [props, props.sx].forEach((el) => {
              if (
                el.display === 'flex' ||
                el.justifyContent ||
                el.flexDirection ||
                el.flex
              ) {
                props.display = 'flex';
              }
            });

            return React.createElement<any>(Box, props);
          }

          Object.defineProperties(NamedWrapper, {
            name: { value: displayName },
            displayName: { value: displayName },
          });

          return NamedWrapper;
        })(),
      };
    },
    {} as any
  );
}

type DefinitionToComponents<Definition> = {
  [K in keyof Definition]: Definition[K] extends infer Def
    ? Def extends unknown
      ? ((
          props: BoxPropsExtended<
            Cast<
              'component' extends keyof Def ? Def['component'] : 'div',
              React.ElementType
            >
          >
        ) => JSX.Element) & { displayName: string }
      : never
    : never;
} & {};

type BoxPropsExtended<Element extends React.ElementType> = Omit<
  BoxProps<Element>,
  'className'
> & { className?: cx.ClassValue };

