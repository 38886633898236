import * as React from 'react';
import Container from '@mui/material/Container';
import { useQuery } from '@tanstack/react-query';

import { CursosSection } from './section.component';
import KnowledgeAreaList from './knowledge-area-list.component';
import ContentContainer from '@/react/components/layout/content-container.component';
import { restClient } from '@/react/utils/fetch';

export const CourseThemesPageComponent = () => {
  const { data: fetchedData = {} } = useQuery({
    queryKey: ['bootstrapDataCourseThemesPageComponent'],
    queryFn: () =>
      restClient.get('/users/bootstrap-data').then((res) => res.data),
  });

  const taxonomy = fetchedData.data?.taxonomy || {};
  const knowledgeAreas = taxonomy.knowledge_areas || [];

  const knowledgeAreasWithLink = knowledgeAreas.map((knowledgeArea) => ({
    ...knowledgeArea,
    link: `cursos/novo_catalogo?knowledge_areas=${knowledgeArea.id}`,
  }));

  return (
    <Container fixed maxWidth="xl">
      <ContentContainer>
        <CursosSection title="Explore cursos por Temas">
          <KnowledgeAreaList data={knowledgeAreasWithLink} />
        </CursosSection>
      </ContentContainer>
    </Container>
  );
};
