<app-corporate-university-banner></app-corporate-university-banner>

<div class="container p-3">
  <div class="row flex-wrap-reverse mt-4">
    <div class="navigation-container col-lg-4" *ngIf="course">
      <app-card
        class="w-100 d-flex h-100 flex-column"
        innerClass="d-flex flex-column w-100 h-100 overflow-auto"
      >
        <p class="course-name">{{ course.name }}</p>
        <p class="visao-geral">{{ getTranslation('visao') | translate }}</p>

        <div class="navigation-list">
          <ng-container>
            <button
              *ngFor="let lesson of course.lessons"
              class="navigation-item"
              (click)="changeLesson(lesson)"
              [ngClass]="{ active: currentLesson === lesson }"
            >
              <div class="d-flex justify-content-between w-100">
                <span class="aula-titulo"> {{ lesson.title }} </span>
                <span class="material-icons-outlined notranslate">
                  {{
                    lesson.type === 'video'
                      ? 'play_circle_outline'
                      : 'picture_as_pdf'
                  }}
                </span>
              </div>

              <p>
                {{ getTranslation('progresso') | translate }}
                {{ getProgress(lesson) }}%
              </p>

              <div class="progress-bar">
                <span
                  [ngStyle]="{ width: lesson.progress + '%' }"
                  class="progress-meter"
                  >&nbsp;</span
                >
              </div>
            </button>
          </ng-container>
        </div>
      </app-card>
    </div>

    <div
      #videoContainer
      [ngStyle]="{ height: getHeight() }"
      class="d-flex flex-column video-container align-self-end flex-grow-1"
      [ngClass]="{ 'col-lg-8': !pdfFile, 'd-none': pdfFile }"
    ></div>

    <div class="col-lg-8" *ngIf="pdfFile">
      <pdf-viewer
        [src]="pdfFile"
        [render-text]="true"
        [original-size]="false"
        class="pdf-viewer"
        (after-load-complete)="onPdfLoadComplete()"
      ></pdf-viewer>
    </div>
  </div>
  <div *ngIf="course?.isCompleted || course?.hasFiles" class="row mt-4">
    <div class="col-12">
      <component-botao
        *ngIf="course.hasFiles"
        (onClick)="downloadContents()"
        class="mr-3"
        >{{ getTranslation('downloadContent') | translate }}</component-botao
      >

      <component-botao
        *ngIf="course.isCompleted && !course.isApproved && course.exameUrl"
        type="primary"
        (onClick)="openExam()"
        >{{ getTranslation('openExam') | translate }}</component-botao
      >

      <component-botao
        *ngIf="course.isCompleted && (course.isApproved || !course.exameUrl)"
        type="primary"
        (onClick)="downloadCertificado()"
        >{{ getTranslation('btnVerCertificado') | translate }}</component-botao
      >
    </div>
  </div>
</div>

<app-nps-eduplay-half
  *ngIf="npsModal"
  (onClose)="closeNpsModal()"
></app-nps-eduplay-half>
