import { SpacingArgument } from '@mui/system/createTheme/createSpacing';
import { Paths, pick, PathType } from 'powership';
import { Breakpoint, Theme } from '@mui/material';
import { AppThemeValues } from '@/react/@mui/interfaces';

/**
 * Creates a function to print media queries for
 *    `styled` (from styled-components or mui/styled)
 * @param breakPoint
 *
 * @param method
 * @example ```tsx
 *     const StyledRow = styled(Row)`
 *       ${up('lg')} { // <--- will print "@media (min-width:1200px)"
 *         align-self: flex-start;
 *       }
 *       flex-direction: column;
 *       align-self: center;
 *    `
 * ```
 */
function mq(
  breakPoint: Breakpoint,
  method: 'up' | 'down'
): StyledThemeFunction {
  return ({ theme }) => {
    return theme.breakpoints[method](breakPoint);
  };
}
export const upScreen = (breakPoint: Breakpoint) => mq(breakPoint, 'up');
export const downScreen = (breakPoint: Breakpoint) => mq(breakPoint, 'down');
export const upMediumScreen = upScreen('md');
export const downMediumScreen = downScreen('md');

export function spacing(value: number): StyledThemeFunction;
export function spacing(
  topBottom: SpacingArgument,
  rightLeft: SpacingArgument
): StyledThemeFunction;
export function spacing(
  top: SpacingArgument,
  rightLeft: SpacingArgument,
  bottom: SpacingArgument
): StyledThemeFunction;
export function spacing(
  top: SpacingArgument,
  right: SpacingArgument,
  bottom: SpacingArgument,
  left: SpacingArgument
): StyledThemeFunction;

export function spacing(...sizes: any[]): StyledThemeFunction {
  return ({ theme }) => {
    // @ts-ignore
    return theme.spacing(...sizes);
  };
}

type _ValidKeys =
  | `palette.${Exclude<Paths<AppThemeValues['palette']>, ''>}`
  | `typography.${Exclude<Paths<AppThemeValues['typography']>, ''>}`;

// removing keys for non string or number types
type ValidKeys = keyof {
  [K in _ValidKeys as PathType<AppThemeValues, K> extends string | number
    ? K
    : never]: K;
};

export function themeValue<Path extends ValidKeys>(prop: Path) {
  return ({ theme }) => {
    return pick(theme, prop) as PathType<AppThemeValues, Path>;
  };
}

export type StyledThemeFunction<T = string> = ({
  theme,
}: {
  theme: Theme;
}) => T;
