import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import * as React from 'react';

import useToggle from '@/react/hooks/useToggle';
import EditEmployeeEmail from './edit-employee-email.component';
import RemoveEmployee from './remove-employee-component';
import ResetEmployeePassword from './reset-employee-password.component';

const EmployeerMenu = ({ employeer, onUpdateEmployee }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const {
    open: showAddOrEditEmployeeDialog,
    toggle: toggleAddOrEditEmployeeDialog,
  } = useToggle({ startOpen: false });

  const { open: showRemoveEmployeeDialog, toggle: toggleRemoveEmployeeDialog } =
    useToggle({ startOpen: false });

  const {
    open: showResetEmployeePasswordDialog,
    toggle: toggleResetEmployeePasswordDialog,
  } = useToggle({ startOpen: false });

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    toggleAddOrEditEmployeeDialog();
    handleClose();
  };

  const handleRemove = () => {
    toggleRemoveEmployeeDialog();
    handleClose();
  };

  const handleResetPassword = () => {
    toggleResetEmployeePasswordDialog();
    handleClose();
  };

  return (
    <>
      <Stack direction="row" spacing={1}>
        <Box>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleOpen}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            elevation={5}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem
              component="a"
              href={`/empresa/employees/edit/${employeer?.id}`}
              sx={{
                '&:hover': {
                  color: 'inherit',
                },
              }}
            >
              Editar
            </MenuItem>
            <MenuItem onClick={handleEdit}>Alterar email</MenuItem>
            <MenuItem onClick={handleResetPassword}>Redefinir senha</MenuItem>
            <MenuItem onClick={handleRemove}>Excluir usuário</MenuItem>
          </Menu>
        </Box>
      </Stack>
      <EditEmployeeEmail
        employee={employeer}
        open={showAddOrEditEmployeeDialog}
        toggleOpen={toggleAddOrEditEmployeeDialog}
        onUpdateEmployee={onUpdateEmployee}
      />
      <RemoveEmployee
        employeeId={employeer?.id}
        open={showRemoveEmployeeDialog}
        toggleOpen={toggleRemoveEmployeeDialog}
        onUpdateEmployee={onUpdateEmployee}
      />
      <ResetEmployeePassword
        employee={employeer}
        open={showResetEmployeePasswordDialog}
        toggleOpen={toggleResetEmployeePasswordDialog}
      />
    </>
  );
};

export default EmployeerMenu;
