<label *ngIf="label" class="BodyText-1-SemiBold">{{ label }} </label>

<div class="dp-hidden position-absolute date-picker">
  <input
    name="datepicker"
    class="form-control"
    ngbDatepicker
    #datepicker="ngbDatepicker"
    [autoClose]="'outside'"
    (dateSelect)="onDateSelection($event)"
    [displayMonths]="1"
    [dayTemplate]="t"
    outsideDays="hidden"
    [startDate]="fromDate!"
    tabindex="-1"
  />
  <ng-template #t let-date let-focused="focused">
    <span
      class="custom-day"
      [class.focused]="focused"
      [class.range]="isRange(date)"
      [class.faded]="isHovered(date) || isInside(date)"
      (mouseenter)="hoveredDate = date"
      (mouseleave)="hoveredDate = null"
    >
      {{ date.day }}
    </span>
  </ng-template>
</div>

<div class="input-group">
  <input
    #dpFromDate
    class="form-control"
    placeholder="aaaa-mm-dd"
    name="dpFromDate"
    [value]="formatter.format(fromDate)"
    (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
  />
  <input
    #dpToDate
    class="form-control"
    placeholder="aaaa-mm-dd"
    name="dpToDate"
    [value]="formatter.format(toDate)"
    (input)="toDate = validateInput(toDate, dpToDate.value)"
  />
  <button
    class="material-icons-outlined notranslate"
    (click)="datepicker.toggle()"
    type="button"
  >
    calendar_today
  </button>
</div>
