import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { green } from '@mui/material/colors';
import { useRefundRequest } from '@/react/components/refund/refund-request/state/useRefundRequest';
import { formatDate } from '@/react/utils/date';
import { formatMoney } from '@/utils/money-parser';
import { RefundRequestStatus } from '@/react/data/subsidy/interfaces';

const Styled = {
  Grid: styled(Grid)(({ theme: { palette, spacing } }) => ({
    alignItems: 'flex-start',
    backgroundColor: palette.neutral[200],
    border: `1px solid ${palette.neutral[300]}`,
    borderRadius: 0,
    color: palette.neutral[800],
    display: 'flex',
    flexDirection: 'column',
    marginBottom: spacing(2),
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
    paddingBottom: spacing('0.5'),
    paddingTop: spacing('0.5'),
    width: 'fit-content',
  })),
};

export const PaymentResume: React.FC = () => {
  const { resourceApproval } = useRefundRequest();

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {resourceApproval?.status === RefundRequestStatus.paid ? (
            <>
              <Grid item xs={12}>
                <Typography
                  variant="body3"
                  fontWeight={400}
                  sx={{
                    paddingBottom: 2,
                  }}
                >
                  {` Pago em: ${formatDate(new Date())}`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body3" fontWeight={900}>
                  Seu reembolso
                </Typography>
                <Typography
                  variant="h5"
                  fontWeight={600}
                  sx={{
                    color: green[700],
                  }}
                >
                  {formatMoney(resourceApproval?.refund_request?.purchase_amount)}
                </Typography>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Styled.Grid>
                <Typography variant="body3" fontWeight={400}>
                  Documentação aprovada, o pagamento será feito em até 5 dias úteis
                </Typography>
              </Styled.Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default PaymentResume;
