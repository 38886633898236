import { Component, OnInit } from '@angular/core';

import { Refund, RefundStatus } from '@/models/refund.model';
import { RefundsService } from '@/services/api/refunds.service';
import { TranslatedComponent } from '@/utils/translated.component';
import { LayoutService } from '@/services/layout.service';

@Component({
  selector: 'app-my-refunds-pending',
  templateUrl: './refunds-pending.component.html',
  styleUrls: ['./refunds-pending.component.scss'],
})
export class MyRefundsPendingComponent
  extends TranslatedComponent
  implements OnInit
{
  translationKey = 'COLABORADOR.MY_REFUNDS.PENDING';

  refunds: Refund[];
  refundCancel: Refund;

  isLoading = false;
  isSendingInvoice = false;
  isCanceling = false;

  constructor(
    private refundService: RefundsService,
    private layoutService: LayoutService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadRefunds();
  }

  async loadRefunds() {
    this.isLoading = true;
    this.refunds = [];
    await this.getPaginatedRefunds();
    this.isLoading = false;
  }

  async getPaginatedRefunds() {
    const userData = await this.refundService.listUserRefunds({
      page: 1,
      status: [
        RefundStatus.COURSE_APPROVED,
        RefundStatus.COURSE_PENDING,
        RefundStatus.REFUND_PENDING,
      ],
    });

    this.refunds = userData.rows.sort((refundA, refundB) => {
      return refundA.status !== RefundStatus.COURSE_APPROVED &&
        refundB.status === RefundStatus.COURSE_APPROVED
        ? 1
        : -1;
    });
  }

  async onCancelConfirm() {
    if (this.isCanceling) return;

    this.isCanceling = true;

    await this.refundService.cancelRefund(this.refundCancel.id);

    this.refunds = this.refunds.filter(
      (_refund) => _refund.id !== this.refundCancel.id
    );

    this.layoutService.success('Reembolso cancelado com sucesso');

    this.onCloseModal();
    this.isCanceling = false;
  }

  onCloseModal() {
    this.refundCancel = undefined;
  }

  onCancel(refund: Refund) {
    this.refundCancel = refund;
  }

  async onSendInvoices(files: File[], refund: Refund) {
    if (!this.isSendingInvoice) {
      this.isSendingInvoice = true;
      try {
        const formData: FormData = new FormData();

        files.forEach((file, index) => {
          formData.append('invoices', file);
        });
        await this.refundService.uploadReceipt(refund.id, formData);
        this.layoutService.success('Comprovante enviado com sucesso');
        refund.status = RefundStatus.REFUND_PENDING;
        this.isSendingInvoice = false;
      } catch (e) {
        console.error('Error', e);
        this.layoutService.error('Não foi possível enviar seu comprovante');
        this.isSendingInvoice = false;
      }
    }
  }
}
