import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { getAsset } from 'src/app/utils/helpers';
import { PasswordUtil } from 'src/app/utils/password';
import { TranslationService } from 'src/app/services/translation.service';
import { RouterService } from 'src/app/services/router.service';

@Component({
  selector: 'app-trocasenha',
  templateUrl: './trocasenha.component.html',
  styleUrls: ['./trocasenha.component.scss'],
})
export class TrocasenhaComponent implements OnInit {
  getAsset = getAsset;

  form = {
    senha: '',
    confirmPassword: '',
  };

  errors = {
    confirmPassword: false,
    password: false,
  };

  token: string;

  invalidConfirm: boolean;
  invalidPass: boolean;

  constructor(
    private usuarioService: UsersService,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private routerService: RouterService,
    private translate: TranslationService
  ) {}

  ngOnInit() {
    const params = this.activatedRoute.snapshot.queryParams;
    if (!params || !params.token) {
      this.routerService.home();
    }
    this.token = params.token;
  }

  onSubmit() {
    this.errors.confirmPassword = false;
    this.errors.password = false;

    if (!PasswordUtil.validate(this.form.senha)) {
      this.errors.password = true;
      return;
    }

    if (this.form.confirmPassword !== this.form.senha) {
      this.errors.confirmPassword = true;
      return;
    }

    this.apiService
      .post('trocasenha', {
        senha: this.form.senha,
        token: this.token,
      })
      .subscribe(
        (response) => {
          this.usuarioService.store(response.data);

          this.routerService.navigate(['']);
        },
        (error) => {
          this.routerService.navigate(['']);
        }
      );
  }

  getPasswordError(): string {
    return this.form.senha
      ? this.translate.getTranslation(this.getTranslation('senhaFraca'))
      : this.translate.getTranslation(this.getTranslation('senhaValida'));
  }

  getConfirmPasswordError(): string {
    return this.form.confirmPassword
      ? this.translate.getTranslation(this.getTranslation('senhaInformada'))
      : this.translate.getTranslation(this.getTranslation('mesmaSenha'));
  }

  getTranslation(key: string): string {
    return `LOGIN.TROCASENHA.${key}`;
  }
}
