import type { QuestionNode } from '../quiz.interfaces';
import { keyBy } from 'powership';
import {
  QUIZ_ERRORS_ENUM,
  QuizStateValue,
  RuntimeQuestionNode,
} from './interfaces';
import { handleNodeConditions } from './conditions';
import { StateUpdateContext } from '../utils/state';
import { fromDraft } from '@/react/components/quiz/utils/fromDraft';

export type ReduceQuizPayload<ExtraData extends object = object> = {
  extraData: ExtraData;
  runtimeNodes: RuntimeQuestionNode[];
  initialNodesById: { [K: string]: QuestionNode };
  initialNodes: QuestionNode[];
  flags: string[];
  submittingNodeId: string | null;
  activeNodeId: string | null;
  previousActiveNodeId: string | null;
  actionContext: StateUpdateContext | null;
};

export function reduceQuizState<ExtraData extends object = object>(
  payload: ReduceQuizPayload<ExtraData>
): QuizStateValue<ExtraData> {
  payload = fromDraft(payload);

  let {
    //
    initialNodes,
    runtimeNodes,
    initialNodesById,
    submittingNodeId,
  } = payload;

  const flags = new Set([...payload.flags]);

  const runtimeNodeById = keyBy(runtimeNodes, (el) => el.id);

  /* all nodes, including the hidden ones */
  const __all__nodes__ = initialNodes.map(
    ({ id }, index): RuntimeQuestionNode => {
      const node = runtimeNodeById[id] || initialNodesById[id];
      const errors = new Set(node.errors || []);
      const value = node?.value?.length ? node.value : [];

      const draftState = {
        ...node.draftState,
        value: node.draftState?.value || [],
      };

      const touched = (() => {
        return !!(
          node.touched ||
          errors.size ||
          value.length ||
          draftState.value?.length
        );
      })();

      if (touched && node.required && !node.draftState.value.length) {
        errors.add(QUIZ_ERRORS_ENUM.REQUIRED_FIELD_EMPTY);
      } else {
        errors.delete(QUIZ_ERRORS_ENUM.REQUIRED_FIELD_EMPTY);
      }

      const defaults = {
        // that values are updated after
        // conditions are checked,
        // just below this loop
        replaceActive: false,
        visible: true,
        step: -1,
      };

      return {
        ...node,
        touched,
        errors: [...errors.values()],
        value,
        draftState,
        index,
        ...defaults,
      };
    }
  );

  const {
    visible: visibleNodes,
    steps,
    replaceActiveNode,
  } = (() => {
    // the first node with `conditions.replaceActive` matching the current state,
    // that node will replace the current active one
    let replaceActiveNode: RuntimeQuestionNode | null = null;

    const all = __all__nodes__.map((node, index) => {
      // HANDLING NODE CONDITIONS
      return handleNodeConditions({
        currentNode: node,
        index,
        allNodes: __all__nodes__,
        flags,
        payload,
      });
    });

    let step = 0;

    const visible = all
      .filter((el) => el.visible)
      .map((el, index) => {
        if (el.countAsStep) {
          step += 1;
        }
        const node: RuntimeQuestionNode = { ...el, index, step };

        if (node.replaceActive && !replaceActiveNode) {
          replaceActiveNode = node;
        }

        return node;
      });

    return { visible, steps: step, replaceActiveNode };
  })();

  const visibleNodeById = keyBy(visibleNodes, (el) => el.id);

  const next = (() => {
    if (replaceActiveNode) {
      return {
        activeNode: replaceActiveNode,
        activeIndex: replaceActiveNode.index,
      };
    }

    if (submittingNodeId) {
      const submittingNode = visibleNodeById[submittingNodeId];
      const nextIndex = submittingNode.index + 1;
      const activeNode = visibleNodes[nextIndex];

      return {
        activeNode,
        activeIndex: nextIndex,
      };
    }

    const activeNode =
      typeof payload.activeNodeId === 'string'
        ? visibleNodeById[payload.activeNodeId]
        : null;

    return {
      activeNode: activeNode,
      activeIndex: activeNode?.index || null,
    };
  })();

  const previousActiveNodeId = (() => {
    if (payload.activeNodeId && next.activeNode?.id !== payload.activeNodeId) {
      return payload.activeNodeId;
    }
    return payload.previousActiveNodeId;
  })();

  const { activeNode, activeIndex } = next;

  if (activeNode) {
    flags.delete('willClose');
  }

  const activeNodeId = activeNode?.id ?? null;

  const requiredNodesEmpty: string[] = [];

  visibleNodes.forEach((el) => {
    const { value, errors, visible, required } = el;

    if (!visible) return;
    if (!required) return;

    if (value.length && !errors.length) return;

    requiredNodesEmpty.push(el.id);
  });

  const hasRequiredNodesEmpty = !!requiredNodesEmpty.length;

  if (requiredNodesEmpty.length) {
    console.info('requiredNodesEmpty:', ...requiredNodesEmpty);
  }

  const hasOptionalNodesEmpty = visibleNodes.some(
    (el) => el.visible && !el.required && (!el.value.length || el.errors.length)
  );

  const progress = (() => {
    const current = activeNode?.step;
    const percentage = Math.floor((current * 100) / (steps || 1));
    return { total: steps, current, percentage };
  })();

  return {
    ...payload.extraData,
    extraData: payload.extraData,
    flags: [...flags.values()],
    previousActiveNodeId,
    submittingNodeId: null,
    progress,
    visibleNodes,
    nodeById: visibleNodeById,
    hasRequiredNodesEmpty,
    hasOptionalNodesEmpty,
    activeIndex,
    activeNodeId,
  };
}
