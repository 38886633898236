export function downloadFile(content: string, filename: string) {
  const encodedUri = encodeURI(content);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${filename}`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export async function downloadImage(imageUrl: string) {
  const imageName = imageUrl.split('/').pop();
  const response = await fetch(imageUrl);
  const blobImage = await response.blob();
  const href = URL.createObjectURL(blobImage);
  const anchorElement = document.createElement('a');
  anchorElement.href = href;
  anchorElement.download = imageName;
  document.body.appendChild(anchorElement);
  anchorElement.click();
  document.body.removeChild(anchorElement);
  window.URL.revokeObjectURL(href);
}

export function downloadCSV(data: string, filename: string) {
  const element = document.createElement('a');

  const blob = new Blob([data], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);

  element.setAttribute('style', 'display: none');
  element.setAttribute('download', `${filename}`);
  element.setAttribute('href', url);
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
