import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';

const AutocompleteCompanies = ({ companyes, onCompanySelect }) => {
  const [selectedCompany, setSelectedCompany] = useState(null);

  const handleOptionChange = (event, newValue) => {
    setSelectedCompany(newValue);
    onCompanySelect(newValue);
  };

  return (
    <Grid item sm={12} xs={12}>
      <Autocomplete
        fullWidth
        options={companyes?.rows ?? []}
        value={selectedCompany}
        onChange={handleOptionChange}
        getOptionLabel={(option) => option.empresa}
        renderOption={(props, option) => (
          <li {...props}>
            {option.empresa} (ID: {option.id})
          </li>
        )}
        renderInput={(params) => (
          <TextField {...params} placeholder="Escolha uma empresa" />
        )}
      />
    </Grid>
  );
};

export default AutocompleteCompanies;
