import React, { createContext, useContext, useState } from 'react';
import {
  RefundListContextType,
  RefundListProviderProps,
  StatusLabels,
} from './interfaces';

const RefundListContext = createContext<RefundListContextType | undefined>(
  undefined
);

export const RefundListProvider: React.FC<RefundListProviderProps> = ({
  children,
}) => {
  const [status, setStatus] = useState<string>('all');
  const [page, setPage] = useState<number>(1);
  const [value, setValue] = useState<number>(0);
  const [currentLabel, setCurrentLabel] = useState(['Todos']);

  const handleTabChange = async (
    _event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    let newStatus: 'in_progress' | 'finalized' | 'all';
    let newLabel: StatusLabels[] = [];

    switch (newValue) {
      case 0:
      default:
        newStatus = 'all';
        newLabel = [StatusLabels.ALL];
        break;
      case 1:
        newStatus = 'in_progress';
        newLabel = [StatusLabels.IN_PROGRESS];
        break;
      case 2:
        newStatus = 'finalized';
        newLabel = [StatusLabels.DONE];
        break;
    }
    setValue(newValue);
    setStatus(newStatus);
    setCurrentLabel(newLabel);
    setPage(1);
  };

  const handlePageChange = (value: number) => {
    setPage(value);
  };

  return (
    <RefundListContext.Provider
      value={{
        status,
        setStatus,
        handleTabChange,
        page,
        setPage,
        handlePageChange,
        value,
        setValue,
        currentLabel,
        setCurrentLabel,
      }}
    >
      {children}
    </RefundListContext.Provider>
  );
};

export const useRefundList = () => {
  const context = useContext(RefundListContext);
  if (context === undefined) {
    throw new Error('useRefundList must be used within a RefundListProvider');
  }
  return context;
};
