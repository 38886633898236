<div class="container">
  <div class="row">
    <div *ngFor="let curso of cursos" class="col-sm-6 col-lg-4 col-xl-3">
      <div class="card">
        <img
          class="imagem-curso"
          [src]="getImagemCurso(curso)"
          (error)="onImageError($event)"
          alt="pessoas dando as mãos para simbolizar trabalho em equipe"
        />
        <div class="card-body">
          <h4>{{ curso.curso_horario.curso_detalhe.curso.curso }}</h4>

          <div class="card-content d-flex justify-content-center">
            <component-botao type="secondary" (onClick)="onAcessar(curso)">{{
              getTranslation('accessCertificate') | translate
            }}</component-botao>
          </div>
          <a (click)="cursoDetalhes(curso)" class="card-text">{{
            getTranslation('courseDetails') | translate
          }}</a>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="cursos.length === 0">
      <img
        class="NoFinishedImage"
        [src]="getAsset('noResults.png')"
        alt="Ilustração de pessoa clicando em uma tela"
      />
      <p>
        {{ getTranslation('nenhumCursoConcluido') | translate }}
      </p>
      <div class="NoFinishedCourses row">
        <p class="BodyText-1">{{ getTranslation('explore') | translate }}</p>
        <component-botao (click)="onNavigate()" type="primary">
          {{ getTranslation('catalogo') | translate }}
        </component-botao>
      </div>
    </div>
  </div>
</div>
