<div class="container">
  <ng-container *ngIf="!submiting">
    <div class="row edupass-container">
      <img
        class="galena-logo"
        [src]="getAsset('logo-galena-emails.png')"
        alt="logo da Galena"
      />
    </div>
    <div class="row">
      <div class="col-12 form">
        <div class="content">
          <p class="greetings">
            {{ getTranslation('ola') | translate }} {{ userName }}!
          </p>
          <p class="title">{{ getTranslation('crieSenha') | translate }}</p>
          <component-input
            label="{{ getTranslation('labelDigiteSenha') | translate }}"
            type="password"
            [(ngModel)]="form.password"
            (onBlur)="onValidatePassword()"
            [error]="erros.weakPassword || erros.blank"
            [errorMessage]="getPasswordErrorMessage()"
          >
          </component-input>
          <component-input
            label="{{
              getTranslation('labelDigiteSenhaNovamente') | translate
            }}"
            type="password"
            [(ngModel)]="form.passwordConfirm"
            (onBlur)="onComparePassword()"
            [error]="erros.differentPassword"
            errorMessage="{{ getTranslation('erroCoincidir') | translate }}"
          >
          </component-input>
        </div>

        <div class="save-btn">
          <component-botao (click)="onRegisterConfirm()">
            {{ getTranslation('btnSalvar') | translate }}
          </component-botao>
        </div>
      </div>
    </div>
  </ng-container>
  <div *ngIf="submiting" class="container h-100 p-0">
    <div class="row edupass-container">
      <img
        class="galena-logo"
        [src]="getAsset('logo-galena-emails.png')"
        alt="logo da Galena"
      />
    </div>

    <div class="form-content">
      <div class="row">
        <div class="content">
          <div class="title">
            <p>{{ getTranslation('direcionadoEdupass') | translate }}</p>
          </div>
          <div class="user-message">
            <p>{{ getTranslation('direcionadoAutomatico') | translate }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
