import { Component, OnDestroy, OnInit } from '@angular/core';
import type { ComponentProps } from 'react';

import { ForgotPasswordComponentReact } from '@/react/components/login/forgot-password/forgot-password.component';
import {
  displayPurpleMetricsModal,
  hidePurpleMetricsModal,
} from '@/utils/purple-metrics';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  ForgotPasswordPageComponent = ForgotPasswordComponentReact;
  reactForgotPasswordPageProps: ComponentProps<
    typeof ForgotPasswordComponentReact
  > = {};

  ngOnInit(): void {
    hidePurpleMetricsModal();
  }

  ngOnDestroy() {
    displayPurpleMetricsModal();
  }
}
