import { Injectable } from '@angular/core';

import { Refund, RefundStatus } from '@/models/refund.model';
import { RefundRequest } from '@/pages/colaborador/refund-form/refund-form.schema';

import { ApiService } from '../api.service';

export interface RefundData {
  is_edupass_enrollment: boolean;
  institution_name: string;
  course_name: string;
  total_course_value: number;
  is_cash: boolean;
  is_installments: boolean;
  installments_number: number;
  refund_percentage: number;
  full_refund_amount: number;
  installment_amount: number;
  manager_name: string;
  manager_email: string;
  employee_department: string;
  course_id?: number;
  refund_type_id?: number;
  reason: string;
  bank_name: string;
  bank_num: string;
  bank_ag: string;
  bank_cc: string;
}

export interface SubscribedInstitution {
  id: number;
  instituicao: string;
  cursos: SubscribedCourse[];
}
export interface SubscribedCourse {
  id: number;
  curso: string;
  valor_original: number;
  desconto: number;
}

export const PAGE_LIMIT = 50;
export const MAX_ITEMS_LOADED = 400;

@Injectable({
  providedIn: 'root',
})
export class RefundsService {
  constructor(private apiService: ApiService) {}

  async createRefund(data: RefundRequest): Promise<Refund> {
    return new Promise((resolve, reject) => {
      this.apiService.post('refunds/create', data).subscribe((response) => {
        resolve(response.data);
      }, reject);
    });
  }

  async listRefunds(filters?: {
    page: number;
    dateFilter?: { from: string; to: string };
    status?: RefundStatus;
    colaborador?: string;
  }): Promise<Refund[]> {
    return new Promise((resolve, reject) => {
      const query = {
        page: filters.page,
        pageLimit: PAGE_LIMIT,
      };

      if (filters?.status) {
        query['status'] = filters.status;
      }
      if (filters?.dateFilter) {
        query['from'] = filters.dateFilter.from;
        query['to'] = filters.dateFilter.to;
      }
      if (filters?.colaborador) {
        query['colaborador'] = filters.colaborador;
      }

      this.apiService
        .getAll('refunds/listRefunds', query)
        .subscribe((response) => {
          resolve(response.data.rows);
        }, reject);
    });
  }

  loadBudget(): Promise<number> {
    return new Promise((resolve) => {
      this.apiService.getAll('refunds/budget').subscribe(
        (response) => {
          resolve(response.data.budget);
        },
        () => resolve(0)
      );
    });
  }

  async listInvoices(filters?: {
    page: number;
    dateFilter?: { from: string; to: string };
    status?: RefundStatus;
    colaborador?: string;
  }): Promise<Refund[]> {
    return new Promise((resolve, reject) => {
      const query = {
        page: filters.page,
        pageLimit: PAGE_LIMIT,
      };

      if (filters?.status) {
        query['status'] = filters.status;
      }
      if (filters?.dateFilter) {
        query['from'] = filters.dateFilter.from;
        query['to'] = filters.dateFilter.to;
      }
      if (filters?.colaborador) {
        query['colaborador'] = filters.colaborador;
      }

      this.apiService
        .getAll('refunds/invoices', query)
        .subscribe((response) => {
          resolve(response.data.rows);
        }, reject);
    });
  }

  async cancelRefund(refundId: number) {
    return new Promise((resolve, reject) => {
      this.apiService
        .delete(`refunds/${refundId}/cancelRequest`)
        .subscribe((response: any) => {
          resolve(response.data);
        }, reject);
    });
  }

  async listUserRefunds(filters?: {
    page: number;
    dateFilter?: { from: string; to: string };
    status?: RefundStatus | RefundStatus[];
  }): Promise<{ rows: Refund[] }> {
    const query = {
      page: filters.page,
      pageLimit: PAGE_LIMIT,
    };

    if (filters?.status) {
      query['status'] = Array.isArray(filters.status)
        ? filters.status.join(',')
        : filters.status;
    }
    if (filters?.dateFilter) {
      query['from'] = filters.dateFilter.from;
      query['to'] = filters.dateFilter.to;
    }

    return new Promise((resolve, reject) => {
      this.apiService
        .getAll('refunds/listUserRefunds', query)
        .subscribe((response) => {
          resolve(response.data);
        }, reject);
    });
  }

  async reviewCourseRequests(items: {
    approvingRequestsIds: number[];
    rejectingRequests: { id: number; reason: string }[];
  }): Promise<void> {
    return new Promise((resolve, reject) => {
      this.apiService
        .post('refunds/reviewCourses', items)
        .subscribe(resolve, reject);
    });
  }

  async reviewInvoicesRequests(items: {
    approvingRequestsIds: number[];
    rejectingRequests: { id: number; reason: string }[];
  }): Promise<void> {
    return new Promise((resolve, reject) => {
      this.apiService
        .post('refunds/invoices/review', items)
        .subscribe(resolve, reject);
    });
  }

  async uploadReceipt(refundId: number, formData: any) {
    return new Promise((resolve, reject) => {
      this.apiService
        .post(`refunds/${refundId}/uploadInvoice`, formData)
        .subscribe(resolve, reject);
    });
  }

  async loadSubscriptions(): Promise<SubscribedInstitution[]> {
    return new Promise((resolve) => {
      this.apiService.getAll('institutions/subscribed').subscribe(
        (response) => {
          resolve(response.data.institutions);
        },
        () => resolve([])
      );
    });
  }
}
