import { RouterService } from './../../../services/router.service';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import CONST from '@/constants';

@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.scss'],
})
export class SsoComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private routerService: RouterService,
    private apiService: ApiService
  ) {}

  ngOnInit(): void {
    const { token, raw_token } = this.activatedRoute.snapshot.queryParams;
    if (!token) {
      this.routerService.home();
    }
    localStorage.setItem('token', decodeURIComponent(token));
    localStorage.setItem(CONST.TOKEN_KEY, raw_token);
    this.apiService.getAll('user').subscribe(
      () => {
        const redirectAfterLogin = localStorage.getItem('redirect_after_login');
        if (redirectAfterLogin) {
          localStorage.removeItem('redirect_after_login');
          window.location.href = redirectAfterLogin;
          return;
        }
        this.routerService.home();
      },
      (error) => {
        this.routerService.navigate(['login'], {
          queryParams: { ssoError: true },
        });
      }
    );
  }
}
