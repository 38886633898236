import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';

export const PartialDiscountChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.success[100],
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.success[700],
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.caption.fontSize,
}));
