import { inject } from '@angular/core';
import { UrlTree, CanActivateFn } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PlansService } from '../api/plans.service';
import { SubsidiesService } from '../api/subsidies.service';
import { RouterService } from '../router.service';

export const SubsidiesGuard: CanActivateFn = (): Observable<
  boolean | UrlTree
> => {
  const routerService = inject(RouterService);
  const plansService = inject(PlansService);
  const subsidiesService = inject(SubsidiesService);

  return plansService.planChange.pipe(
    switchMap(() =>
      checkEligibilityAndNavigate(routerService, plansService, subsidiesService)
    )
  );

  async function checkEligibilityAndNavigate(
    routerService: RouterService,
    plansService: PlansService,
    subsidiesService: SubsidiesService
  ): Promise<boolean | UrlTree> {
    await subsidiesService.fetchEligibilityForSubsidies();

    if (isEligibleForSubsidies(plansService, subsidiesService)) {
      return true;
    }

    routerService.navigate(['cursos'], {
      queryParams: { redirectUrl: window.location.href },
    });
    return false;
  }

  function isEligibleForSubsidies(
    plansService: PlansService,
    subsidiesService: SubsidiesService
  ): boolean {
    return (
      plansService.plan?.features.includes('subsidies') &&
      subsidiesService.eligible_for_subsidies
    );
  }
};
