import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { toastSuccess, toastError } from '@/react/utils/custom-toast';
import * as Sentry from '@sentry/browser';
import { restClient } from '@/react/utils/fetch';

const RemovetDialog = ({ employee, open, toggleOpen }) => {
  const [loading, setLoading] = React.useState(false);

  const handleRemove = async () => {
    setLoading(true);

    try {
      const response = await restClient.delete(`/employees/${employee.id}`);
      const messageToast =
        response.data.data.message ?? 'Colaborador excluído com sucesso!';

      toastSuccess({ messageToast });
      window.location.assign('/empresa/employees');

      handleClose();
    } catch (error) {
      const messageToast = error?.message ?? 'Erro ao remover colaborador!';

      toastError({ messageToast });
      setLoading(false);
      Sentry.captureException(error);
    }

    setLoading(false);
  };

  const handleClose = () => {
    toggleOpen();
  };

  return (
    <>
      <Dialog onClose={handleClose} fullWidth open={open}>
        <DialogTitle>Excluir usuário</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Typography variant="body2">
              <p> Tem certeza que deseja excluir esse usuário? </p>
              <p>
                O acesso à plataforma será revogado imediatamente, mas
                matrículas feitas antes da exclusão continuam válidas.
              </p>
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Cancelar
          </Button>
          <LoadingButton
            color="error"
            loading={loading}
            onClick={handleRemove}
            type="submit"
            variant="contained"
          >
            Excluir usuário
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RemovetDialog;
