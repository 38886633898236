import { Component } from '@angular/core';
import type { ComponentProps } from 'react';

import ReactEditEmployees from '@/react/components/employer/manage/edit';

@Component({
  selector: 'component-employees-edit',
  templateUrl: './edit-employees.component.html',
})
export class EditEmployeesComponent {
  ReactEditEmployees = ReactEditEmployees;
  reactEditEmployeesProps: ComponentProps<typeof ReactEditEmployees> = {};
}
