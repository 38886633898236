import { ArrowForward, Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Button,
  Grid,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Link,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import { constants } from '@/react/constants';
import CONST from '@/constants';

import { useForm, Controller, type SubmitHandler } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { getHeaders } from '@/utils/apiHeaders';
import { restClient } from '@/react/utils/fetch';
import { useGtm } from '../state/useGtm';


const passwordValidationSchema = z.object({
  password: z.string().min(1, 'Senha é obrigatória'),
});

type LoginValidationData = z.infer<typeof passwordValidationSchema>;

export type PasswordInputProps = {
  login: string;
};

const fetchLogin = async ({ user, password }) => {
  const postData = { access: user, password };

  const response = await restClient.post(
    `${constants.API_ENDPOINT}/auth/login`,
    postData
  );

  return response?.data
};

export const PasswordInput = ({ login }: PasswordInputProps) => {
  const [userAccount, setUserAccount] = useState(login);
  const [isLogeed, setIsLogeed] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const gtm = useGtm();

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    const resizeButton = () => {
      const inputHeight = inputRef.current?.offsetHeight;
      const button = document.querySelector('.submitButton') as HTMLElement | null;
      if (inputHeight && button) {
        button.style.height = `${inputHeight}px`;
      }
    };
    window.addEventListener('resize', resizeButton);
    resizeButton();
    return () => window.removeEventListener('resize', resizeButton);
  }, []);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: '',
    },
    resolver: zodResolver(passwordValidationSchema),
  });

  const checkEmail = async (password) => {
    setIsLogeed(true);

    if (password.trim() !== '') {
      setUserAccount(login);

      fetchLogin({ user: userAccount, password }).then(
        (response) => {
            console.log(response)
            localStorage.setItem(CONST.TOKEN_KEY, response.data.raw_token);
            localStorage.setItem(CONST.USER_KEY, JSON.stringify(response.data.id));
            
            restClient.defaults.headers.common = getHeaders();
            gtm.trackLoginEvent();
            
            window.location.href = '/';
            setIsLogeed(false);

            return;
        },
        (error) => {
          setError('password', {
            type: 'server',
            message: error?.response?.data?.error?.message || 'Usuário não encontrado!',
          });

          setIsLogeed(false);
          return;
        }
      );
    }

    setIsLogeed(false);
  };

  const onSubmit: SubmitHandler<LoginValidationData> = (event) => {
    checkEmail(event.password);
  };

  const handleForgotPasswordClick = () => {
    window.location.assign('/login/forgot-password');
  };

  return (
    <>
      <Grid container direction={'column'} rowGap={1}>
        <Typography variant="h5" fontWeight="normal">Olá, {login}</Typography>
      </Grid>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container columnGap={2}>
          <Grid item flexGrow={1}>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  inputRef={inputRef}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Digite sua senha"
                  fullWidth
                  error={Boolean(errors.password)}
                  helperText={errors?.password?.message}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item>
            <Button
              sx={{ height: '100%' }}
              className="submitButton"
              type="submit"
              disabled={isLogeed}
              variant="contained"
            >
              <ArrowForward />
            </Button>
          </Grid>
          <Grid item sm={12} xs={12}>
            <Link
              onClick={handleForgotPasswordClick}
              underline="none"
              variant="body3"
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'none',
                },
              }}
            >
              Esqueci minha senha
            </Link>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
