export const AccordionStepStatus = {
  error: 'error',
  pending: 'pending',
  success: 'success',
  warning: 'warning',
} as const;

export interface AccordionStep {
  disabled?: boolean;
  expanded?: boolean;
  renderContent: (props?: any) => React.ReactElement;
  renderHeader: (props?: any) => React.ReactElement;
  status?: keyof typeof AccordionStepStatus;
}

export type AccordionSteps = { [key: string]: AccordionStep };
