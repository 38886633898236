import { ApiService } from 'src/app/services/api.service';
import { EventEmitter, Injectable } from '@angular/core';
import { CompanyPlansResponse } from '@/models/responses/companyPlans.model';

@Injectable({
  providedIn: 'root',
})
export class PlansService {
  readonly router: string = 'company_plans';

  isLoaded = false;

  plan: CompanyPlansResponse = { code: null, name: null, features: [], zoom_config: null, promo_landing_page: null };

  planChange = new EventEmitter<CompanyPlansResponse>();

  constructor(private apiService: ApiService) {
    this.load();
  }

  public load() {
    this.apiService.getAll(`${this.router}`).subscribe(
      (response) => {
        this.plan = response.data;
        this.isLoaded = true;
        this.planChange.emit(this.plan);
      },
      () => {
        this.plan = null;
        this.isLoaded = true;
        this.planChange.emit(this.plan);
      }
    );
  }
}
