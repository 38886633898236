export type ReplaceDiacriticsOptions = {
  replaceSpace?: boolean;
  lowercase?: boolean;
};

export type Option = {
  label: string;
  value: string;
  defaultSelected?: boolean;
  icon?: string;
};

/**
 * convert strings -> {label, value}
 * @example: ```ts
 * stringToOption(['Ensino Médio Completo']) -> [{ label: 'Ensino Médio Completo', value: 'ensino_medio_completo' }]
 * ```
 * @param labels
 * @param options
 */
export function labelsToOptions<T extends string, Extra extends object = {}>(
  labels: (T | (Partial<Option> & { label: T } & Extra))[] | T,
  options?: ReplaceDiacriticsOptions
): ({ value: string; label: T } & Extra)[] {
  function convert(label: string | (Partial<Option> & { label: string })) {
    if (typeof label === 'string') {
      return labelToOption(label, options);
    }
    return { ...label, ...labelToOption(label.label) };
  }

  return Array.isArray(labels)
    ? labels.map(convert)
    : (labels
        .split(/[\n;]/g)
        .map((el) => el.trim())
        .filter(Boolean)
        .map(convert) as any);
}

export function labelToOption(
  label: string,
  options?: ReplaceDiacriticsOptions
): { value: string; label: string } {
  label = label.trim();
  return {
    label: label,
    value: replaceDiacritics(label, options),
  };
}

export function replaceDiacritics(
  value: string,
  options: ReplaceDiacriticsOptions = {}
) {
  const { replaceSpace = true, lowercase = true } = options;
  if (replaceSpace) value = value.replace(/\s+|-/g, '_');
  value = value.normalize('NFKD').replace(/[^\w]/g, '');
  if (lowercase) value = value.toLowerCase();
  return value;
}
