import { CONST } from 'src/app/constants';

const getAsset = (asset: string) => {
  return `${CONST.API_ENDPOINT}/contents/assets/${asset}`;
};

const getSvg = (asset: string) => {
  return `${CONST.API_ENDPOINT}/contents/svg/${asset}`;
};

const normalizedMatch = (stringA: string, stringB: string) => {
  const normalize = (value: string) =>
    value
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

  return normalize(stringA).indexOf(normalize(stringB)) > -1;
};

const clampProgress = (value: number) => {
  return clampNumber(value, 100, 0);
};

const clampNumber = (value: number, max: number, min: number) => {
  return Math.min(Math.max(value, min), max);
};

export { getAsset, getSvg, normalizedMatch, clampNumber, clampProgress };
