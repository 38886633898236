import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Offer } from '@/react/data/catalog/interfaces';
import { formatMoney } from '@/react/utils/money';

export const RegressiveDiscount = ({
  regressiveDiscount,
}: {
  regressiveDiscount: Offer['regressive_discount'];
}) => {
  return (
    <Box>
      <Typography
        variant="body3"
        sx={(theme) => ({
          color: theme.palette.neutral[800],
          fontWeight: theme.typography.fontWeightBold,
        })}
      >
        Desconto regressivo
      </Typography>
      <Typography variant="caption">
        A partir do {regressiveDiscount.month}º mês, o desconto altera para{' '}
        {regressiveDiscount.discount}% e a mensalidade para{' '}
        <Box
          component="span"
          sx={(theme) => ({
            color: theme.palette.primary[700],
            fontWeight: theme.typography.fontWeightBold,
          })}
        >
          {formatMoney(regressiveDiscount.sale_price)}
        </Box>
      </Typography>
    </Box>
  );
};
