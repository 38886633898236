import React, { ReactNode } from 'react';

interface RefundListContextType {
  status: string;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  currentLabel: string[];
  setCurrentLabel: React.Dispatch<React.SetStateAction<string[]>>;
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  handleTabChange: (_event: React.ChangeEvent<{}>, newValue: number) => void;
  handlePageChange: (value: number) => void;
}

interface RefundListProviderProps {
  children: ReactNode;
}

enum StatusLabels {
  ALL = 'Todos',
  IN_PROGRESS = 'Abertos',
  DONE = 'Finalizados',
}

export { RefundListContextType, RefundListProviderProps, StatusLabels };

