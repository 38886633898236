import * as React from 'react';

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import ContentContainer from '@/react/components/layout/content-container.component';
import { Filters } from '@/react/components/filters';
import { FiltersDesktopHeader } from './catalog-filters-desktop-header.component';

import Catalog from '@/react/components/catalog';

export const CatalogFiltersDesktop = () => {
  return (
    <Container fixed maxWidth="xl">
      <ContentContainer>
        <Grid container spacing={{ xs: 2, sm: 4 }}>
          <Grid item sm={6} md={4} lg={3}>
            <FiltersDesktopHeader />
            <Filters />
          </Grid>
          <Grid item sm={6} md={8} lg={9}>
            <Catalog />
          </Grid>
        </Grid>
      </ContentContainer>
    </Container>
  );
};
