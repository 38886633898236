import statesJson from 'src/app/data/estados.json';

const citiesByState = statesJson.reduce((acc, state) => {
  acc[state.sigla] = state.cidades.map((city) => ({
    label: city,
    value: city,
  }));

  return acc;
}, {});

const states = statesJson.reduce((acc, state) => {
  const stateOptions = {
    label: `${state.nome} (${state.sigla})`,
    value: state.sigla,
  };

  acc.push(stateOptions);

  return acc;
}, []);

export const locationSelectOptions = {
  states,
  citiesByState,
};
