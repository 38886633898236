import { keys, tupleEnum } from 'powership';
import type { QuestionNode } from '../quiz.interfaces';
import type { MiniState } from '@/react/components/quiz/utils/state';

export const QUIZ_ERROS_MAP = {
  REQUIRED_FIELD_EMPTY: 'Campo obrigatório.',
} as const;

export const QUIZ_ERRORS_ENUM = tupleEnum(...keys(QUIZ_ERROS_MAP));

export type QuizStateInit<ExtraData extends object = object> = {
  nodes: QuestionNode[];
  extraData: ExtraData;
  activeNodeId: string | null;
  defaultActiveNodeId: string;
};

// QuestionNode plus some runtime helpers
type _RuntimeExtraFields = {
  index: number;
  visible: boolean;
  replaceActive?: boolean;
  step: number;
  meta?: any;
};

export type RuntimeQuestionNode = Omit<
  QuestionNode,
  keyof _RuntimeExtraFields
> &
  _RuntimeExtraFields;

// Represents the plain state object value,
// present in the QuizStateInstance (MiniState instance),
// inside the `.current` property
export type QuizStateValue<ExtraData extends object = { [K: string]: any }> = {
  extraData: Readonly<ExtraData>;
  nodeById: { [K: string]: RuntimeQuestionNode };
  visibleNodes: RuntimeQuestionNode[];
  hasRequiredNodesEmpty: boolean;
  hasOptionalNodesEmpty: boolean;
  activeNodeId: string | null;
  submittingNodeId: string | null;
  activeIndex: number | null;
  previousActiveNodeId: string | null;
  progress: {
    total: number;
    current: number;
    percentage: number;
  };

  /**
   *  flags is a list of any strings.
   *  It can be changed by any question
   *  node using the functions `addFlags`
   *  and `removeFlags`.
   *  Flags are a way to communicate to
   *  and between nodes.
   *  An example of a flag is "willClose",
   *  defined when the user tries to close the quiz.
   */
  flags: ('willClose' | AnyString)[];
} & ExtraData;

// `string & {}` is just a way to force
// typescript to not merge string with a
// string literal.
// for instance:
// - `'abc'|string` is `string `
// - `'abc'|string & {}` is `'abc' | string & {}`
type AnyString = string & {};

export type QuizMethods<State> = MethodDefinitions<State> extends infer M
  ? {
      [K in keyof M]: M[K] extends unknown
        ? M[K] extends (state: any, ...payload: infer Payload) => any
          ? (...payload: Payload) => State
          : never
        : never;
    } & {}
  : never;

export type MethodDefinitions<State> = {
  setActiveNodeMetadata(state: State, value: object): any;
  setActiveNodeValue(state: State, value: string[]): any;
  setActiveNodeErrors(state: State, errors: string[]): any;
  submitActiveNode(state: State): any;
  goBack(state: State): any;
  exitQuiz(state: State): any;
  addFlags(state: State, flags: string[]): any;
  removeFlags(state: State, flags: string[]): any;
};

export type QuizState<ExtraProps extends object = { [K: string]: any }> =
  MiniState<QuizStateValue<ExtraProps>> &
    QuizMethods<QuizStateValue<ExtraProps>>;
