import { Component, OnInit } from '@angular/core';

import { CorporateCourse } from 'src/app/models/corporate_course.model';
import { Trail } from 'src/app/models-new/media/trails.model';
import { MediaService } from 'src/app/services/media.service';
import { CorporateUniverstyRoutes } from 'src/app/pages/corporate-university/corporate-university.routes';
import { RouterService } from 'src/app/services/router.service';
import { LayoutService } from 'src/app/services/layout.service';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss'],
})
export class CatalogComponent implements OnInit {
  categorias: Trail[] = [];
  courses: CorporateCourse[];

  constructor(
    private routerService: RouterService,
    private mediaService: MediaService,
    private layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.layoutService.updateBreadCrumb([
      {
        label: 'Home',
        route: ['/'],
      },
      {
        label: 'Meu aprendizado',
      },
      {
        label: 'Trilhas',
      },
    ]);
    this.loadTrails();
  }

  onNavigate(trail: Trail) {
    this.routerService.navigate([
      CorporateUniverstyRoutes.CORPORATE_UNIVERSITY,
      CorporateUniverstyRoutes.TRAILS,
      trail.id,
    ]);
  }

  async loadTrails() {
    try {
      this.categorias = await this.mediaService.getTrails();
    } catch (error) {
      this.routerService.home();
    }
  }
}
