import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UnibradPosService {
  constructor(private http: HttpClient) {}

  getUnibradUrl(url: string, token: string) {
    return new Promise<string>((resolve, reject) => {
      this.http
        .get(url, {
          headers: {
            'Authorization': `${token}`,
          },
        })
        .subscribe((response) => {
          const redirectUrl = response['data']['redirectUrl'];
          return redirectUrl ? resolve(redirectUrl) : reject();
        }, reject);
    });
  }
}
