import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { User } from '@/models/user.model';
import { LmsReportComponent } from '@/pages/empresa/relatorios/lms-report/lms-report.component';
import { RoleGuard } from '@/services/guards/role.guard';

import { FeatureRefundGuard } from '@/services/guards/feature-refund.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RefundPendingComponent } from './refund/pending/pending.component';
import { RefundReceiptApprovalComponent } from './refund_receipt/review/approval.component';
import { RefundReceiptReportComponent } from './refund_receipt/report/report.component';
import { RefundReportComponent } from './refund/report/report.component';
import { RelatoriosComponent } from './relatorios/relatorios.component';
import { CorporateUniversityGuard } from 'src/app/services/guards/corporate-university.guard';
import { ManageEmployeesComponent } from '../colaborador/employee/manage-employees.component';
import { RegisterEmployeesComponent } from '../colaborador/employee/register/register-employees.component';
import { EditEmployeesComponent } from '../colaborador/employee/edit/edit-employees.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [RoleGuard],
    data: {
      roles: [User.ROLES.ADMIN, User.ROLES.HR, User.ROLES.FINANCIAL],
    },
    children: [
      {
        path: 'employees',
        component: ManageEmployeesComponent,
      },
      {
        path: 'employees/register',
        component: RegisterEmployeesComponent,
      },
      {
        path: 'employees/edit/:id',
        component: EditEmployeesComponent,
      },

      {
        path: 'refund',
        canActivate: [FeatureRefundGuard, RoleGuard],
        data: {
          roles: [User.ROLES.ADMIN, User.ROLES.HR],
        },
        children: [
          {
            path: 'refund_pending',
            component: RefundPendingComponent,
          },
          {
            path: 'refund_report',
            component: RefundReportComponent,
          },
        ],
      },
      {
        path: 'refund_receipt',
        canActivate: [FeatureRefundGuard, RoleGuard],
        data: {
          roles: [User.ROLES.ADMIN, User.ROLES.FINANCIAL],
        },
        children: [
          {
            path: 'refund_receipt_approval',
            component: RefundReceiptApprovalComponent,
          },

          {
            path: 'refund_report',
            component: RefundReceiptReportComponent,
          },
        ],
      },

      {
        path: 'relatorios',
        component: RelatoriosComponent,
        children: [
          {
            path: 'lms_report',
            canActivate: [CorporateUniversityGuard],
            component: LmsReportComponent,
          },

          {
            path: '',
            redirectTo: 'empresa',
            pathMatch: 'prefix',
          },
        ],
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EmpresaRoutingModule {}
