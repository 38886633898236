<app-card>
  <div class="d-flex justify-content-between flex-column flex-md-row p-4">
    <div
      class="d-flex flex-column col-md-6 justify-content-center p-3 align-items-center text-center text-md-left"
    >
      <p class="Headline-2 w-100">
        {{ getTranslation('pergunta') | translate }}
      </p>
      <img
        class="d-flex d-md-none w-100 duvida-asset"
        [src]="getSvg('marketing_promotion.svg')"
      />
      <p class="BodyText-1">
        {{ getTranslation('resposta') | translate }}
      </p>
      <component-botao type="primary" (click)="clickZendesk()" class="mt-md-4">
        Enviar uma solicitação
      </component-botao>
    </div>
    <div class="d-md-flex col-md-6 d-none justify-content-end p-3">
      <img
        class="w-100 duvida-asset"
        [src]="getSvg('marketing_promotion.svg')"
      />
    </div>
  </div>
</app-card>
