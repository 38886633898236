import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';

import { Prices } from './prices.component';
import { useProductPage } from '../state';
import { ProductPageAsyncResources } from '../state/interfaces';
import { PriceTypes } from '@/react/data/catalog/interfaces';
import { Typography } from '@mui/material';

export const OfferPrices = () => {
  const { offer, loading, isSingleOffer, institution } = useProductPage();

  if (loading.includes(ProductPageAsyncResources.offer)) {
    return <Skeleton sx={{ height: 80 }} animation="wave" variant="rounded" />;
  }

  if (!offer) {
    return null;
  }

  const { pricing_description: pricing } = offer;
  const included = offer.is_full_included && pricing.discount_percentage === 100;

  return (
    <>
    <Prices
      checkoutConfig={offer.checkout_config}
      included={included}
      paymentConditions={pricing.payment_conditions}
      originalPrice={pricing.original_price}
      saleDiscountPercentage={pricing.discount_percentage}
      salePrice={pricing.sale_price}
      isSingleOffer={isSingleOffer}
      type={pricing.type}
    />
    {pricing.type === PriceTypes.tuition && offer?.checkout_config?.has_extra_payment && (
      <Typography component="div" variant="caption">
        + Taxa de matrícula
      </Typography>
    )}
    </>
  );
};
