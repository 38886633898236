import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { SubsidyPolicy } from '@/react/data/subsidy/interfaces';
import { SubsidyClient } from '@/react/data/subsidy/subsidy-client';

const TTL_IN_MS = 1000 * 60; // 1 minute

interface GetAllSubsidyPoliciesResponse {
  policies: SubsidyPolicy[];
}

interface useSubsidyPolicyReturn {
  error: AxiosError | undefined;
  isFetching: boolean;
  policy: SubsidyPolicy;
  refundValue: string;
}

export function useSubsidyPolicies() {
  const result = useQuery<GetAllSubsidyPoliciesResponse>({
    queryKey: ['subsidy-getAllPolicies'],
    queryFn: async () => {
      return SubsidyClient.getAllPolicies();
    },
    staleTime: TTL_IN_MS,
  });

  const error = result.error as AxiosError;
  const policies = result.data?.policies || [];
  // TODO: at the moment we're not supporting multiple policies,
  // so we're just getting the first one. This should change in the future.
  const policy = policies[0] || null;
  const refundValue = policy?.limits.amount_limit_per_purchase_cents || '0';

  return {
    error,
    isFetching: result.isFetching,
    policy,
    refundValue,
  } as useSubsidyPolicyReturn;
}
