import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CursoAluno } from 'src/app/models/curso-aluno';
import { LayoutService } from 'src/app/services/layout.service';
import { MediaService } from 'src/app/services/media/media.service';
import { RouterService } from 'src/app/services/router.service';
import { getSvg } from 'src/app/utils/helpers';

@Component({
  selector: 'app-conclusao-curso',
  templateUrl: './conclusao-curso.component.html',
  styleUrls: ['./conclusao-curso.component.scss'],
})
export class ConclusaoCursoComponent implements OnInit {
  getSvg = getSvg;

  course: CursoAluno;

  constructor(
    private mediaService: MediaService,
    private activatedRoute: ActivatedRoute,
    private routerService: RouterService,
    private layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.loadCourse();
  }

  async loadCourse() {
    this.activatedRoute.params.subscribe(async (params) => {
      try {
        this.course = await this.mediaService.getCursoCompletoById(params.id);
        this.layoutService.updateBreadCrumb([
          {
            label: 'Home',
            route: ['/'],
          },
          {
            label: this.course.curso_horario.curso_detalhe.curso.curso,
            route: ['eduplay', this.course.curso_horario.id.toString()],
          },
          {
            label: 'Conclusão',
          },
        ]);
      } catch (e) {
        this.routerService.home();
      }
    });
  }

  downloadCertificate() {
    this.mediaService.downloadCertificado(this.course.curso_horario.id);
  }

  getCourseName() {
    return this.course?.curso_horario?.curso_detalhe?.curso?.curso || '';
  }
}
