import { CONST } from 'src/app/constants';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { getHeaders } from '@/utils/apiHeaders';

type QueryParams =
  | string
  | URLSearchParams
  | string[][]
  | Record<string, string>;
@Injectable({
  providedIn: 'root',
})
export abstract class Api2Service {
  abstract router: string;

  constructor(private http: HttpClient) {}

  private getQueryParams(queryParams: QueryParams): string {
    return queryParams ? `?${new URLSearchParams(queryParams).toString()}` : '';
  }

  protected get(route: string, queryParams?: QueryParams): Promise<any> {
    const query = queryParams ? this.getQueryParams(queryParams) : '';
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `${CONST.API_ENDPOINT}/${this.router}${
            route ? '/' : ''
          }${route}${query}`,
          {
            headers: getHeaders(),
          }
        )
        .subscribe((response: any) => {
          resolve(response.data);
        }, reject);
    });
  }

  protected post(route: string, body: any): Promise<any> {
    return this.http
      .post(`${CONST.API_ENDPOINT}/${this.router}/${route}`, body, {
        headers: getHeaders(),
      })
      .toPromise();
  }

  protected put(route: string, body: any): Promise<any> {
    return this.http
      .put(`${CONST.API_ENDPOINT}/${this.router}/${route}`, body, {
        headers: getHeaders(),
      })
      .toPromise();
  }

  protected delete(route: string): Promise<any> {
    return this.http
      .delete(`${CONST.API_ENDPOINT}/${this.router}/${route}`, {
        headers: getHeaders(),
      })
      .toPromise();
  }
}
