<app-my-refunds-navigation menu="pending"></app-my-refunds-navigation>

<div class="container">
  <div class="row mt-4">
    <div class="col-12">
      <p class="Subtitle-2">
        {{ getTranslation('requestReview') | translate }}
      </p>
    </div>
  </div>

  <div class="row">
    <div *ngIf="refunds.length === 0" class="col-12">
      <p>{{ getTranslation('noRequestReview') | translate }}</p>
    </div>

    <div
      *ngFor="let refund of refunds"
      class="d-flex flex-column col-md-6 col-lg-4 pb-3"
    >
      <app-refund-card
        class="h-100 d-flex"
        (onSendInvoice)="onSendInvoices($event, refund)"
        (onCancel)="onCancel(refund)"
        [refund]="refund"
      ></app-refund-card>
    </div>
  </div>
</div>

<app-modal *ngIf="refundCancel" header="ATENÇÃO">
  <div class="cancel-modal">
    <p>{{ getTranslation('sure') | translate }}</p>
    <p>{{ getTranslation('undo') | translate }}</p>
    <p>{{ getTranslation('anotherRequest') | translate }}</p>
    <div class="d-flex justify-content-between">
      <component-botao type="secondary" (click)="onCloseModal()">{{
        getTranslation('back') | translate
      }}</component-botao>
      <component-botao type="warning" (click)="onCancelConfirm()">{{
        getTranslation('continue') | translate
      }}</component-botao>
    </div>
  </div>
</app-modal>
