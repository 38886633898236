import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Stack from '@mui/material/Stack';

import EditDependentDialog from './edit-dependent-dialog.component';
import RemoveDependentDialog from './remove-dependent-dialog.component';
import ResetDependentPasswordDialog from './reset-dependent-password-dialog.component';
import useToggle from '@/react/hooks/useToggle';

const DependentMenu = ({ dependent }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    open: showAddOrEditDependentDialog,
    toggle: toggleAddOrEditDependentDialog,
  } = useToggle({ startOpen: false });
  const {
    open: showRemoveDependentDialog,
    toggle: toggleRemoveDependentDialog,
  } = useToggle({ startOpen: false });
  const {
    open: showResetDependentPasswordDialog,
    toggle: toggleResetDependentPasswordDialog,
  } = useToggle({ startOpen: false });

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    toggleAddOrEditDependentDialog();
    handleClose();
  };

  const handleRemove = () => {
    toggleRemoveDependentDialog();
    handleClose();
  };

  const handleResetPassword = () => {
    toggleResetDependentPasswordDialog();
    handleClose();
  };

  return (
    <>
      <Stack direction="row" spacing={1}>
        <Box>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={(e) => handleOpen(e)}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            elevation={5}
            open={Boolean(anchorEl)}
            onClose={() => handleClose()}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={handleEdit}>Editar</MenuItem>
            <MenuItem onClick={handleResetPassword}>Redefinir senha</MenuItem>
            <MenuItem onClick={handleRemove}>Remover</MenuItem>
          </Menu>
        </Box>
      </Stack>
      <EditDependentDialog
        dependent={dependent}
        open={showAddOrEditDependentDialog}
        toggleOpen={toggleAddOrEditDependentDialog}
      />
      <RemoveDependentDialog
        dependentId={dependent.id}
        open={showRemoveDependentDialog}
        toggleOpen={toggleRemoveDependentDialog}
      />
      <ResetDependentPasswordDialog
        dependent={dependent}
        open={showResetDependentPasswordDialog}
        toggleOpen={toggleResetDependentPasswordDialog}
      />
    </>
  );
};

export default DependentMenu;
