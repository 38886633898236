import * as React from 'react';
import { useQuery } from '@tanstack/react-query';

import { useScreenSizes } from '@/react/utils/useScreenSizes';
import { restClient } from '@/react/utils/fetch';
import { FiltersSchema } from '@/react/components/filters/state/interfaces';
import { FiltersContext } from '@/react/components/filters/state/context';
import { CatalogContext } from '@/react/components/catalog/state/context';
import { getFilterValuesFromUrl } from '@/react/components/filters/state';
import { useInstitutionsSearch } from '@/react/data/institutions/useInstitutionsData';

import { createSchema } from './catalog-filters.schema';
import { CatalogFiltersDesktop } from './desktop/catalog-filters-desktop.component';
import { CatalogFiltersMobile } from './mobile/catalog-filters-mobile.component';
import { GtmContext } from '../state/context';

const ResponsiveCatalog = () => {
  const { isSmallScreen } = useScreenSizes();

  return isSmallScreen ? <CatalogFiltersMobile /> : <CatalogFiltersDesktop />;
};

export const CatalogFilters = () => {
  const { institutions, isFetching: institutionsLoading } =
    useInstitutionsSearch();
  const { data: bootstrapData } = useQuery({
    queryKey: ['bootstrapData'],
    queryFn: async () =>
      await restClient
        .get('/users/bootstrap-data')
        .then((res) => res.data.data),
  });

  const [filtersSchema, setFiltersSchema] = React.useState<FiltersSchema>(null);

  React.useEffect(() => {
    if (institutionsLoading || !bootstrapData?.taxonomy) {
      return;
    }

    const schema = createSchema(bootstrapData?.taxonomy, institutions);
    setFiltersSchema(schema);
  }, [bootstrapData?.taxonomy, institutions, institutionsLoading]);

  if (!filtersSchema) {
    return null;
  }

  const initialValues = getFilterValuesFromUrl();

  return (
    <FiltersContext
      schema={filtersSchema}
      initialValues={initialValues}
      setSchema={setFiltersSchema}
    >
      <GtmContext>
        <CatalogContext>
          <ResponsiveCatalog />
        </CatalogContext>
      </GtmContext>
    </FiltersContext>
  );
};
