import * as React from 'react';
import CONST from '@/constants'
import { 
  Box, 
  CardMedia, 
  Container, 
  Paper, 
  ToggleButton, 
  ToggleButtonGroup, 
  Typography, 
  styled 
} from '@mui/material';

const DASHBOARD_VIEW = 'p_ydtreq2xhd';
const EXPORT_VIEW = 'p_6z02jl77hd';

const Dashboard = ({ companyUuid }) => {
  const [view, setView] = React.useState(DASHBOARD_VIEW);

  const lookerParams = {
    'ds138.company_uuid': companyUuid,
    'ds69.company_uuid': companyUuid,
    'ds142.company_uuid': companyUuid,
    'ds236.company_uuid': companyUuid,
    'ds88.company_uuid': companyUuid,
  };

  const lookerParamsAsString = JSON.stringify(lookerParams);
  const encodedLookerParams = encodeURIComponent(lookerParamsAsString);
  const iframeSize = view === DASHBOARD_VIEW ? 5900 : 1700;

  const StyledToggleButton =  styled(ToggleButton)(({ theme }) => ({
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,

    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: theme.palette.primary.main,
    }
  }));

  return (
    <Container>
      <Paper elevation={5} sx={{ my: 2 }} style={{ padding: '32px' }}>
        <Box mb={2}> 
          <Typography gutterBottom variant='h4' my={2}> Relatórios </Typography>
          <ToggleButtonGroup
            value={view}
            exclusive
            onChange={(_, value) => setView(value)}
            aria-label='Platform'
          >
            <StyledToggleButton value={DASHBOARD_VIEW}>Dashboard</StyledToggleButton>
            <StyledToggleButton value={EXPORT_VIEW}>Tabelas para exportar</StyledToggleButton>
          </ToggleButtonGroup>
        </Box>

        <CardMedia
          component='iframe'
          src={`${CONST.LOOKER_DASHBOARD_URL}${view}?params=${encodedLookerParams}`}
          sx={{ border: 0, height: iframeSize }}
        />
      </Paper>
    </Container>
  );
};

export default Dashboard;
