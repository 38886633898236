import React, { createContext, useMemo } from 'react';
import { GoogleTagManager } from '@/libs/googleTagManager';

export const Context = createContext<GoogleTagManager | null>(null);

export function GtmContext({ children }) {
  // useMemo to create a single instance of GoogleTagManager
  const gtmInstance = useMemo(() => new GoogleTagManager(), []);

  return <Context.Provider value={gtmInstance}>{children}</Context.Provider>;
}
