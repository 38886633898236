import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CorporateUniversityCoursesComponent } from './corporate-university-courses/corporate-university-courses.component';
import { CorporateUniversityPanelComponent } from './corporate-university-panel/corporate-university-panel.component';
import { CorporateUniversityVideosComponent } from './corporate-university-videos/corporate-university-videos.component';
import { CatalogComponent } from './corporate-university-panel/catalog/catalog.component';
import { PendingComponent } from './corporate-university-panel/pending/pending.component';
import { CompleteComponent } from './corporate-university-panel/complete/complete.component';
import { CorporateUniverstyRoutes as CorporateUniversityRoutes } from './corporate-university.routes';
import { CorporateUniversityGuard } from 'src/app/services/guards/corporate-university.guard';
import { CompletedCourseComponent } from './completed-course/completed-course.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [CorporateUniversityGuard],
    children: [
      {
        path: `${CorporateUniversityRoutes.TRAILS}/:id`,
        component: CorporateUniversityCoursesComponent,
      },
      {
        path: `${CorporateUniversityRoutes.COURSES}/:id/${CorporateUniversityRoutes.COMPLETED_COURSE}`,
        component: CompletedCourseComponent,
      },
      {
        path: `${CorporateUniversityRoutes.COURSES}/:id`,
        component: CorporateUniversityVideosComponent,
      },

      {
        path: '',
        component: CorporateUniversityPanelComponent,

        children: [
          {
            path: CorporateUniversityRoutes.CATALOG,
            component: CatalogComponent,
          },
          {
            path: CorporateUniversityRoutes.PENDING,
            component: PendingComponent,
          },
          {
            path: CorporateUniversityRoutes.COMPLETE,
            component: CompleteComponent,
          },
          {
            path: '**',
            redirectTo: '/cursos',
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CorporateUniversityRoutingModule {}
