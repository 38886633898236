import { Join, NestedPaths, PropertyType } from 'powership';
import model, { comparisonFunctions } from 'aggio/lib/model';

export type Operators<T = any> = {
  $eq?: T; // equals
  $ne?: T; // not equals

  // $empty is custom operator defined bellow (matchers.$empty = function...)
  // `{ $empty: true }` matches --> "", [], null, undefined, 0
  $empty?: boolean;

  $regex?: RegexCondition; // match regex or a regex string
  $in?: T;
  $elemMatch?: T;

  // Not declaring all possible operators to limit only by usage
  // and preventing dependency on the lib.
  // Some of the possible operators from "aggio" are:
  //  $lt
  //  $lte
  //  $gt
  //  $eq
  //  $gte
  //  $ne
  //  $in
  //  $nin
  //  $regex
  //  $exists
  //  $size
  //  $elemMatch
};

extendMatchers();

export function objectMatch<Value extends object>(
  value: Value,
  condition: Condition<Value>
) {
  return model.match(value, condition);
}

/**
 * Maps all possible fields/conditions for an object.
 * @example: { 'user.address.city': {$empty: false} }
 */
export type Condition<RootObject> =
  | Partial<RootObject>
  | ({
      [Property in Join<NestedPaths<RootObject>, '.'>]?: FieldCondition<
        PropertyType<RootObject, Property>
      >;
    } & RootOperators<RootObject>);

export interface RootOperators<RootObject> {
  $and?: Condition<RootObject>[];
  $or?: Condition<RootObject>[];
  $not?: Condition<RootObject>;
}

export type FieldCondition<T> =
  | AlternativeType<T>
  | Condition<AlternativeType<T>>;

export type AlternativeType<T> = T extends ReadonlyArray<infer U>
  ? T | Operators<U> | RegExpOrString<U>
  : Operators<T> | RegExpOrString<T>;

export type RegExpOrString<T> = T extends string
  ? { $regex: RegexCondition } | T
  : T;
export type RegexFlag = `${'g' | ''}${'i' | ''}${'m' | ''}`;
export type RegexPattern = string & {};
export type RegexCondition = `/${RegexPattern}/${RegexFlag}`;

type Matchers = { [K: string]: (a: any, b: any) => boolean };

function extendMatchers() {
  const matchers: Matchers = comparisonFunctions;
  const { $regex: $originalRegex } = matchers;

  const REGEX_REGEX = /^\/.*\/g?i?m?$/;

  // The original `$regex` comparison accepts
  // only RegExp instances, here we allow regex strings
  matchers.$regex = function $regex(value, condition) {
    return $originalRegex(value, ensureRegex(condition));
  };

  const empties = new Set(['', null, undefined, 0]);

  matchers.$empty = function $falsy(value, shouldBeEmpty: boolean) {
    const isEmptyArray = Array.isArray(value) && !value.length;
    const is = isEmptyArray || empties.has(value);
    return is === shouldBeEmpty;
  };

  function ensureRegex(condition: unknown) {
    if (condition instanceof RegExp) return condition;

    if (typeof condition !== 'string' || !REGEX_REGEX.test(condition)) {
      throw new Error(`Invalid $regex condition ${condition}`);
    }

    const flags = condition.match(/\/([gim]*)$/)?.[1];
    if (flags) {
      condition = new RegExp(
        condition
          // removes the first bar "/"
          .slice(1)
          // removes the flags and the closing bar "/gim"
          .slice(0, (flags.length + 1) * -1),
        flags
      );
    } else {
      condition = new RegExp(condition.slice(1, -1));
    }

    return condition;
  }
}
