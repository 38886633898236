<component-modal>
  <div class="container-fluid p-5">
    <div class="row">
      <div
        class="col-12 d-flex flex-column align-items-center justify-content-center"
      >
        <p class="Subtitle-2 text-center mb-0">
          Conta pra gente! <br />
          O que você está achando desse curso?
        </p>

        <img class="my-5" [src]="getSvg('nps_half.svg')" />

        <p class="BodyText-1 text-center mb-0">
          Que tal contar pra gente o que você está achando do curso? <br />
          Assim, podemos melhorar nosso conteúdo pra você!
        </p>
        <div class="d-flex mt-5">
          <component-botao (onClick)="closeModal()" type="secondary"
            >Agora não</component-botao
          >
          <component-botao (onClick)="openNpsForm()" class="ml-4"
            >Contar agora</component-botao
          >
        </div>
      </div>
    </div>
  </div>
</component-modal>
