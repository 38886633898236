<label class="BodyText-1 checkbox-container">
  <ng-content></ng-content>
  <input
    (input)="onInput($event)"
    [formControl]="formControl"
    type="checkbox"
    class="checkbox"
  />
  <span class="checkmark"></span>
</label>
