import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React from 'react';
import { CourseClient } from './course-client';
import { Subscription } from './interfaces';


interface UseCourseSubscriptionsResult {
  data: Subscription[] | undefined;
  error: AxiosError['response'] | undefined;
  isFetching: boolean;
}

export function useCourseSubscriptions(id: number): UseCourseSubscriptionsResult {
  const result = useQuery({
    queryKey: ['useCourseSubscriptions', id],
    refetchOnMount: false,
    queryFn: async () => {
      return CourseClient.getSubscriptionsByCourseId(id);
    },
  });

  return React.useMemo(() => {
    const error = result.error as AxiosError;
    const data = result.data?.data;

    return {
      data: data?.subscriptions,
      error: error?.response,
      isFetching: result.isFetching,
    };
  }, [result.data, result.isFetching]);
}
