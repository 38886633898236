<button
  (click)="clicked()"
  [disabled]="disabled"
  class="justify-content-center botao {{ type }} {{ innerClass }}"
  [ngClass]="{ skeleton: skeleton, 'w-100': fullWidth }"
>
  <ng-content></ng-content>
  <span
    *ngIf="icon"
    class="material-icons-outlined notranslate"
    [ngClass]="{ expanded: expanded }"
    >{{ icon }}</span
  >
</button>
