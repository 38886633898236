import { Component, OnInit, Input } from '@angular/core';
import { CONST } from 'src/app/constants';
import { Trail } from 'src/app/models-new/media/trails.model';

@Component({
  selector: 'app-corporate-university-card',
  templateUrl: './corporate-university-card.component.html',
  styleUrls: ['./corporate-university-card.component.scss'],
})
export class CorporateUniversityCardComponent implements OnInit {
  @Input()
  categoria: Trail;

  constructor() {}

  ngOnInit(): void {}

  getImage(): string {
    return `${CONST.API_ENDPOINT}/contents/assets/${this.categoria.image}`;
  }

  getTranslation(key: string): string {
    return `CORPORATE_UNIVERSITY.CORPORATE_UNIVERSITY_CARD.${key}`;
  }
}
