<div class="d-flex justify-content-between">
  <p class="BodyText-2 gray-60 mt-2">Página {{ currentPage + 1 }}</p>
  <div class="d-flex pagination-container">
    <component-button-icon
      *ngIf="currentPage > 0"
      (onClick)="previousPage()"
      type="secondary"
      icon="chevron_left"
    ></component-button-icon>
    <button (click)="goToPage(0)" [ngClass]="{ active: currentPage === 0 }">
      1
    </button>

    <ng-container *ngIf="currentPage <= 2">
      <button (click)="goToPage(1)" [ngClass]="{ active: currentPage === 1 }">
        2
      </button>
      <button
        (click)="goToPage(2)"
        [ngClass]="{ active: currentPage === 2 }"
        *ngIf="maxPages >= 3"
      >
        3
      </button>
      <button
        (click)="goToPage(3)"
        [ngClass]="{ active: currentPage === 3 }"
        *ngIf="maxPages >= 4"
      >
        4
      </button>
    </ng-container>

    <ng-container *ngIf="currentPage > 2">
      <p class="ml-2">...</p>
      <button (click)="goToPage(currentPage - 1)">{{ currentPage }}</button>
      <button class="active">{{ currentPage + 1 }}</button>
      <button
        (click)="goToPage(currentPage + 1)"
        *ngIf="currentPage + 2 <= maxPages"
      >
        {{ currentPage + 2 }}
      </button>
    </ng-container>

    <p *ngIf="currentPage + 2 < maxPages" class="mr-2">...</p>
    <component-button-icon
      *ngIf="currentPage < maxPages - 1"
      (onClick)="nextPage()"
      type="secondary"
      icon="chevron_right"
    ></component-button-icon>
  </div>
</div>
