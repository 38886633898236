import * as React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

interface CustomProps {
  currency?: string;
  onChange: (value: number) => void;
  value: number;
}

export const MoneyField = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, currency = 'BRL', value, ...inputProps } = props;

    const currencyConfig = {
      DEFAULT: {
        decimalSeparator: '.',
        thousandSeparator: ',',
      },
      BRL: {
        decimalSeparator: ',',
        thousandSeparator: '.',
      },
    };

    const currencyProps = currencyConfig[currency] ?? currencyConfig.DEFAULT;

    return (
      <NumericFormat
        {...inputProps}
        {...currencyProps}
        allowNegative={false}
        decimalScale={2}
        fixedDecimalScale={true}
        value={value || ''}
        getInputRef={ref}
        onValueChange={(values) => {
          const value = values.floatValue ?? null;
          onChange(value);
        }}
        valueIsNumericString
        placeholder="Ex.: R$ 100,00"
      />
    );
  }
);
