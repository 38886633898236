<app-card class="d-flex w-100 h-100" innerClass="w-100" padding="2rem">
  <div class="p-mb-0">
    <p class="BodyText-1-Bold">{{ getTranslation('status') | translate }}</p>
    <p class="BodyText-1-SemiBold">
      {{ getStatus(refund.status) | translate }}
    </p>
    <p class="mt-5 Subtitle-2">{{ refund.institution_name }}</p>
    <p class="mt-2 Subtitle-2-Regular">{{ refund.course_name }}</p>

    <div class="mt-5">
      <component-collapsible label="Detalhes da solicitação">
        <p class="mt-3">
          <span class="BodyText-1-Bold">{{
            getTranslation('date') | translate
          }}</span>
          <span class="BodyText-1 ml-1">{{
            refund.created_at | date : 'dd/LL/yy'
          }}</span>
        </p>

        <p class="mt-2">
          <span class="BodyText-1-Bold">{{
            getTranslation('courseAmount') | translate
          }}</span>
          <span class="BodyText-1 ml-1">{{
            refund.total_course_value
              | currency : 'BRL' : 'R$' : '1.2-2' : 'pt-BR'
          }}</span>
        </p>

        <p class="mt-2">
          <span class="BodyText-1-Bold">{{
            getTranslation('requestedRefund') | translate
          }}</span>
          <span class="BodyText-1 ml-1">{{
            refund.full_refund_amount
              | currency : 'BRL' : 'R$' : '1.2-2' : 'pt-BR'
          }}</span>
        </p>
      </component-collapsible>
    </div>

    <p *ngIf="isInvoicePending()" class="BodyText-1 mt-5">
      {{ getTranslation('approvedCourse') | translate }} <br />
      {{ getTranslation('requestInvoice') | translate }}
    </p>

    <div *ngIf="isInvoicePending()" class="mt-5">
      <input
        #fileInput
        multiple="true"
        accept="image/*, application/pdf"
        (change)="onLoadFiles($event)"
        class="d-none"
        type="file"
      />
      <component-botao
        [type]="filesLoaded.length > 0 ? 'text' : 'primary'"
        (onClick)="fileInput.click()"
        >{{ getTranslation('loadInvoice') | translate }}</component-botao
      >
    </div>

    <div class="mt-2">
      <div
        class="filenames d-flex w-100 mt-1"
        *ngFor="let fileName of fileNames; let fileIndex = index"
      >
        <p class="FeedbackText">{{ fileName }}</p>
        <button (click)="removeFile(fileIndex)">X</button>
      </div>
    </div>

    <div *ngIf="isInvoicePending() && filesLoaded.length > 0" class="mt-3">
      <component-botao (onClick)="sendInvoices()">
        {{ getTranslation('sendInvoice') | translate }}</component-botao
      >
    </div>

    <hr class="mt-3" />

    <p *ngIf="refund.status === 'REFUND_PENDING'" class="BodyText-1 mt-9">
      {{ getTranslation('cantCancel') | translate }}
    </p>

    <p *ngIf="refund.status === 'COURSE_PENDING'" class="BodyText-1 mt-9">
      {{ getTranslation('canCancel') | translate }}
    </p>

    <div *ngIf="isCancealable()" class="mt-4">
      <component-botao (onClick)="onCancel.emit()" type="warning">{{
        getTranslation('cancelRequest') | translate
      }}</component-botao>
    </div>
  </div>
</app-card>
