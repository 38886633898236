import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import type { ComponentProps } from 'react';

import ProductPage from '@/react/components/cursos/product-page';

@Component({
  selector: 'product-page',
  templateUrl: './product-page.component.html',
})
export class ProductPageComponentReact implements OnInit {
  ProductPage = ProductPage;
  productPageProps: ComponentProps<typeof ProductPage> = {
    courseId: undefined,
  };

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.productPageProps = {
        courseId: params.id,
      };
    });
  }
}
