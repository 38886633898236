<app-certificate-help></app-certificate-help>

<div class="container">
  <div class="row">
    <div *ngFor="let curso of cursos" class="col-sm-6 col-lg-4 col-xl-3">
      <div class="card my-4">
        <div class="crop">
          <img
            class="imagem-curso"
            [src]="getImagemCurso(curso)"
            (error)="onImageError($event)"
            alt="pessoas dando as mãos para simbolizar trabalho em equipe"
          />
        </div>

        <div class="card-body">
          <h4>{{ getCourseName(curso) }}</h4>
          <p class="card-text">
            {{ getTranslation('progress') | translate }}:
            {{ getProgresso(curso) }}%
          </p>
          <div class="progress-bar">
            <span
              [ngStyle]="{ width: getProgresso(curso) + '%' }"
              class="progress-meter"
              >&nbsp;</span
            >
          </div>
          <div class="d-flex justify-content-center mt-4">
            <component-botao type="secondary" (onClick)="onAcessar(curso)">{{
              getTranslation('continuarCurso') | translate
            }}</component-botao>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12" *ngIf="cursos.length === 0">
      <img [src]="getAsset('noResultsOnGoingCourses.png')" alt="" />
      <p class="title">{{ getTranslation('nenhumCurso') | translate }}</p>
      <div class="no-course row">
        <p>{{ getTranslation('explore') | translate }}</p>
        <component-botao (click)="onCatalog()" type="primary">
          {{ getTranslation('catalogo') | translate }}
        </component-botao>
      </div>
    </div>
  </div>
</div>
