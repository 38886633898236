import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoginComponent } from './login.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RegistrarComponent } from './registrar/registrar.component';
import { LoginRoutingModule } from './login-routing.module';
import { SsoComponent } from './sso/sso.component';
import { NewRegisterPageComponent } from './new-register-page/new-register-page.component';
import { TrocasenhaComponent } from './trocasenha/trocasenha.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
@NgModule({
  declarations: [
    LoginComponent,
    RegistrarComponent,
    ForgotPasswordComponent,
    SsoComponent,
    NewRegisterPageComponent,
    TrocasenhaComponent,
  ],
  imports: [
    CommonModule,
    LoginRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class LoginModule {}
