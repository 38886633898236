import { LayoutService } from 'src/app/services/layout.service';
import { Component, OnInit } from '@angular/core';

import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { RouterService } from 'src/app/services/router.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UsersService } from 'src/app/services/users.service';
import { GlobalProfileStateEmitter } from '@/react/components/profile-quiz/global-profile-emitter';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnInit {
  user: User;

  lang;

  checkbox: boolean = false;
  mensagemErro: boolean = false;
  loading: boolean = false;

  constructor(
    private userService: UsersService,
    private apiService: ApiService,
    private routerService: RouterService,
    private translate: TranslationService,
    private layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.user = this.userService.user;
    if (this.user.terms_accepted) {
      this.routerService.home();
    }
    this.lang = this.translate.getLang();
    this.translate.onLangChange().subscribe(() => {
      this.lang = this.translate.getLang();
    });

    this.layoutService.updateBreadCrumb([
      {
        label: 'Termos de Uso',
      },
    ]);
  }

  onEnviar() {
    if (this.checkbox) {
      this.loading = true;
      this.apiService.post('accept_terms').subscribe(() => {
        this.userService.user.terms_accepted = true;
        this.loading = false;
        this.routerService.home();
        GlobalProfileStateEmitter.load({ forceProfileForm: true });
      });
    } else {
      this.mensagemErro = true;
    }
  }

  isDeloitte(): boolean {
    return this.user.colaborador.empresa.empresa === 'Deloitte';
  }
}
