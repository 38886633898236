import React from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

interface SectionHeaderProps {
  title: string;
  onBackClicked: () => void;
}

const SectionHeader = ({ title, onBackClicked }: SectionHeaderProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <IconButton aria-label="voltar" size="small" onClick={onBackClicked}>
        <ArrowBackIosIcon />
      </IconButton>
      <Typography variant="h5">{title}</Typography>
    </Box>
  );
};

export { SectionHeader };
