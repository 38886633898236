import * as React from 'react';
import Grid from '@mui/material/Grid';

import { CourseTypeCard } from './course-type-card.component';

export type CourseTypeListProps = {
  data: {
    description?: string;
    image?: string;
    label: string;
    link: string;
    type: string;
  }[];
};

const CourseTypeList = ({ data }: CourseTypeListProps) => (
  <Grid container spacing={{ xs: 2, sm: 4 }}>
    {data.map((courseType, idx) => (
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        xl={3}
        pt={2}
        key={courseType.type + idx}
      >
        <CourseTypeCard data={courseType} />
      </Grid>
    ))}
  </Grid>
);

export default CourseTypeList;
