<div class="banner-eduplay row align-items-center flex-wrap-reverse">
  <div class="col-md-6">
    <h1
      class="Headline-1-Bold"
      [ngClass]="{ skeleton: loading, 'mb-0': subtitulo }"
    >
      {{ curso }}
    </h1>

    <p class="Headline-2" *ngIf="subtitulo">
      {{ subtitulo }}
    </p>
    <ng-content></ng-content>

    <component-botao
      *ngIf="botao"
      [skeleton]="loading"
      type="attention"
      (onClick)="onAcessar()"
      [disabled]="disabled"
      [icon]="icon"
      >{{ label }}</component-botao
    >
  </div>

  <div *ngIf="!logo" class="col-md-6 d-flex justify-content-md-end">
    <img
      class="logo"
      [src]="getSvg('logoEdupass-branco.svg')"
      alt="eduplay logo"
    />
  </div>

  <div *ngIf="logo" class="col-md-6 d-flex justify-content-md-end mb-md-3">
    <img
      class="logo"
      [src]="getAsset('eduplayBranco.png')"
      alt="eduplay logo"
    />
  </div>
</div>
