import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  ValidatorFn,
} from '@angular/forms';

@Component({
  selector: 'component-checkbox',
  templateUrl: './checkbox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements OnInit, ControlValueAccessor {
  formControl: UntypedFormControl;

  @Input()
  validators: ValidatorFn[];

  @Input('disabled')
  set _disabled(value: boolean) {
    this.disabled = value;
    if (this.formControl) {
      if (value) {
        this.formControl.disable();
      } else {
        this.formControl.enable();
      }
    }
  }

  disabled: boolean = false;

  private onChange: (value) => void;
  private onTouched: () => void;

  constructor() {
    this.onChange = (_: any) => {};
    this.onTouched = () => {};
  }

  ngOnInit(): void {
    this.formControl = new UntypedFormControl(null, this.validators);
    if (this.disabled) {
      this.formControl.disable();
    }
  }

  writeValue(obj: any): void {
    this.formControl.setValue(obj);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.formControl?.disable();
    } else {
      this.formControl?.enable();
    }
  }

  onInput(el) {
    this.onChange(el.target.checked);
  }
}
