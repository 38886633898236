<div *ngIf="courseName" class="container-fluid p-5 page-expand bg-white">
  <div class="row">
    <div
      class="col-12 d-flex flex-column align-items-center justify-content-center"
    >
      <p class="Subtitle-2 text-center mb-0">
        Parabéns! <br />
        Você concluiu o curso {{ courseName }}!
      </p>

      <img class="my-4" [src]="getSvg('nps_full.svg')" />

      <component-botao (onClick)="downloadCertificate()" icon="download"
        >Baixar Certificado</component-botao
      >

      <p class="BodyText-1 text-center mt-5 mb-0">
        Que tal nos
        <a
          class="custom-link"
          href="https://forms.gle/xyi28sQnw7KmRvYZ9"
          target="_blank"
        >
          contar mais do que você achou sobre o curso clicando aqui?!
        </a>
        <br />

        Com isso, você nos ajuda a melhorar nossos conteúdos, vamos evoluir
        juntos?
      </p>
    </div>
  </div>
</div>
