import { useQuery } from '@tanstack/react-query';
import { RefundableAmountResponse } from '@/react/data/subsidy/interfaces';
import { SubsidyClient } from './subsidy-client';

export function useRefundableAmount() {
  const result = useQuery<RefundableAmountResponse>({
    queryKey: ['useRefundableAmount'],
    refetchOnMount: false,
    queryFn: async () => {
      return SubsidyClient.getAvaiableRefundableAmount();
    },
  });

  const availableRefundableList = result.data?.resource_approvals || [];

    return {
      availableRefundableList,
      isFetching: result.isFetching,
      error: !!result.error,
    };

}
