import { useEffect } from 'react';

interface ClickOutsideProps {
  ref: React.MutableRefObject<any>;
  onClickedOutside: () => void;
}

export const useClickOutside = ({
  ref,
  onClickedOutside,
}: ClickOutsideProps) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickedOutside();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
};
