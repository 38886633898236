import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import HTMLSafe from '@/react/components/html-safe.component';
import { useProductPage } from '../state';
import { ProductPageAsyncResources } from '../state/interfaces';
import { InstitutionLogo, Section, SkillsList, Title } from '../components';
import { CourseDetailsHelp } from './course-details-help.component';

const Styled = {
  HTMLSafe: styled(HTMLSafe)`
    & > p {
      margin-bottom: ${({ theme }) => theme.spacing(2)};
    }
  `,
};

export const CourseDetailsContent = () => {
  const { course, institution, loading: isFetching } = useProductPage();

  const loading = isFetching.includes(ProductPageAsyncResources.course);

  return (
    <>
      {(loading || course?.about) && (
        <Section>
          <Stack spacing={2}>
            {loading ? (
              <Typography variant="h5">
                <Skeleton width="35%" />
              </Typography>
            ) : (
              <Title>Sobre o curso</Title>
            )}
            <Typography
              color="neutral.900"
              variant="body1"
              sx={{ whiteSpace: 'pre-line', fontSize: '1rem' }}
            >
              {loading ? (
                <Skeleton animation="wave" height={200} variant="rounded" />
              ) : (
                <Styled.HTMLSafe content={course.about} />
              )}
            </Typography>
          </Stack>
        </Section>
      )}
      {course?.skills.length > 0 && (
        <Section>
          <Box
            p={3}
            sx={{
              backgroundColor: (theme) => theme.palette.neutral[50],
            }}
          >
            <Title>Habilidades desenvolvidas</Title>
            <Box mt={-2}>
              <SkillsList skills={course.skills} />
            </Box>
          </Box>
        </Section>
      )}
      {institution && (
        <Section>
          <Box sx={{ height: 80 }}>
            <InstitutionLogo institution={institution} />
          </Box>
        </Section>
      )}
      {institution?.about && (
        <Section>
          <Stack spacing={2}>
            <Title>Sobre a {institution.name}</Title>
            <Typography
              color="neutral.900"
              variant="body1"
              sx={{ whiteSpace: 'pre-line', fontSize: '1rem' }}
            >
              <Styled.HTMLSafe content={institution.about} />
            </Typography>
          </Stack>
        </Section>
      )}
      <CourseDetailsHelp />
    </>
  );
};
