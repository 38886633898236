import {
  Component,
  forwardRef,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  UntypedFormControl,
  ValidatorFn,
} from '@angular/forms';
@Component({
  selector: 'component-input',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit, ControlValueAccessor {
  formControl: UntypedFormControl;

  @Input()
  validators: ValidatorFn[];

  @Input()
  error: boolean;

  @Input()
  errorMessage: string;

  @Input()
  errorPosition: 'top' | 'bottom' = 'top';

  @Input()
  placeholder: string = '';

  @Input()
  label: string;

  @Input()
  description: string;

  @Input()
  autocomplete: 'on' | 'off' = 'off';

  @Input()
  maxLength: string = '255';

  @Input()
  type: string = 'text';

  @Input()
  isInput: boolean = true;

  @Input()
  successIcon: boolean;

  @Input('disabled')
  set _disabled(value: boolean) {
    this.disabled = value;
    if (this.formControl) {
      if (value) {
        this.formControl.disable();
      } else {
        this.formControl.enable();
      }
    }
  }

  @Input('value')
  set _value(value: string) {
    this.value = value;
    if (this.formControl) {
      if (value) {
        this.formControl.setValue(this.value);
      } else {
        this.formControl.setValue('');
      }
    }
  }

  @Output()
  onBlur: EventEmitter<any> = new EventEmitter();

  @Input()
  tabOnEnter: boolean = false;

  disabled: boolean = false;

  value: string;

  @Input()
  formatter: (value: string) => string;

  @Output()
  inputed = new EventEmitter();

  private onChange: (value) => void;
  private onTouched: () => void;

  constructor() {
    this.onChange = (_: any) => {};
    this.onTouched = () => {};
  }

  ngOnInit(): void {
    this.formControl = new UntypedFormControl(null, this.validators);
    if (this.disabled) {
      this.formControl.disable();
    }

    this.formControl.setValue(this.value);
  }

  writeValue(obj: any): void {
    this.formControl.setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onInput(el) {
    const formatted = this.formatter
      ? this.formatter(el.target.value)
      : el.target.value;

    this.onChange(formatted);

    if (formatted !== el.target.value) {
      this.formControl.setValue(formatted);
    }

    this.inputed.emit(formatted);
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.formControl?.disable();
    } else {
      this.formControl?.enable();
    }
  }

  onInputBlur() {
    this.onTouched();
    this.onBlur.emit(this.formControl.value);
  }

  onEnterPressed() {
    if (this.tabOnEnter) {
      const inputs = document.getElementsByTagName('input');
      for (var i = 0; i < inputs.length; i++) {
        if (document.activeElement === inputs[i]) {
          const nextIndex = i < inputs.length - 1 ? i + 1 : 1;
          inputs[nextIndex].focus();
          break;
        }
      }
    }
  }
}
