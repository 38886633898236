import { Gift } from './gift.model';

export class Order {
  id?: number;
  address?: string;
  bairro?: string;
  birth_date?: string;
  cep?: string;
  city?: string;
  complement?: string;
  course_id?: number;
  cpf?: string;
  created_at?: string;
  cupom?: string;
  discount?: number;
  email?: string;
  is_refunded?: boolean;
  name?: string;
  number?: string;
  payment_id?: string;
  payment_url?: string;
  phone?: string;
  refund_code?: string;
  status?: string;
  total_order_value?: number;
  uf?: string;
  updated_at?: string;
  user_id?: number;
  closed?: boolean;
  gift?: Gift;

  static STATUS = {
    PENDING: 'PENDING',
    PAID: 'PAID',
  };
}
