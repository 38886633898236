import { RegistrationComponent } from './registration/registration.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ProductPageComponentReact } from './product-page/product-page.component';
import { HomeComponent } from './home/home.component';
import { CourseThemesPage } from './course-themes/home.component';
import { Routes as CursosRoutes } from './cursos.routes';
import { CustomCategoryComponent } from './custom-category/custom-category.component';
import { CourseGaleryComponent } from './course-galery/course-galery.component';
import { PlanGaleryComponent } from './plan-galery/plan-galery.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: CursosRoutes.COURSE_THEMES,
    component: CourseThemesPage,
  },
  {
    path: CursosRoutes.CUSTOM_CATEGORY,
    component: CustomCategoryComponent,
  },
  {
    path: CursosRoutes.NOVO_CATALOGO,
    component: CourseGaleryComponent,
  },
  {
    path: CursosRoutes.PLAN_GALERY,
    component: PlanGaleryComponent,
  },
  {
    path: ':id',
    component: ProductPageComponentReact,
  },
  {
    path: `:id/${CursosRoutes.INSCRICAO}`,
    component: RegistrationComponent,
  },
  {
    path: `:id/${CursosRoutes.CHECKOUT}`,
    loadChildren: () =>
      import('./checkout/checkout.module').then((p) => p.CheckoutModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CursosRoutingModule {}
