import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CONST } from 'src/app/constants';

import { getHeaders } from '@/utils/apiHeaders';

const baseApiUrl = 'zoom_lms';

@Injectable({
  providedIn: 'root',
})
export class ZoomLMSService {
  constructor(private http: HttpClient) {}

  getRedirectUrl() {
    return new Promise<string>((resolve, reject) => {
      this.http
        .get(`${CONST.API_ENDPOINT}/${baseApiUrl}/sso_redirect`, {
          headers: getHeaders(),
        })
        .subscribe((response) => {
          const redirectUrl = response['data']['redirectUrl'];
          return redirectUrl ? resolve(redirectUrl) : reject();
        }, reject);
    });
  }
}
