export const environment = {
  env: 'staging',
  production: false,
  BACKEND_URL: 'https://staging-api.galena.com',
  FALCON_URL: 'https://staging-falcon.galena.com',
  LEGACY_BACKEND_URL: 'https://staging-api.edupass.io',
  NEW_API_ENDPOINT: 'https://staging-api2.edupass.io',
  FRONTEND_URL: 'https://staging-plataforma.galena.com',
  registry_limit: 100,
  WHATSAPP_NUMERO: '5511945588466',
  MEDIA_URL: 'https://staging-media.edupass.io',
  PURPLE_METRICS_COMPANY_ID: '137f281d-fda9-461c-bcec-5eeb1a2dd518',
};
