import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PDFSource } from 'ng2-pdf-viewer';

import { CONST } from 'src/app/constants';
import { Course } from 'src/app/models-new/media/course.model';
import { Lesson } from 'src/app/models-new/media/lesson.model';
import { MediaService } from 'src/app/services/media.service';
import { TranslatedComponent } from 'src/app/utils/translated.component';
import { downloadFile } from 'src/app/utils/download-file';
import Player from 'src/app/libs/lms.lib';
import { RouterService, BreadCrumb } from 'src/app/services/router.service';
import { LayoutService } from 'src/app/services/layout.service';

import { CorporateUniverstyRoutes } from '../corporate-university.routes';
import { clampProgress } from '@/utils/helpers';

@Component({
  selector: 'app-corporate-university-videos',
  templateUrl: './corporate-university-videos.component.html',
  styleUrls: ['./corporate-university-videos.component.scss'],
})
export class CorporateUniversityVideosComponent
  extends TranslatedComponent
  implements OnInit, OnDestroy
{
  readonly translationKey: string =
    'CORPORATE_UNIVERSITY.CORPORATE_UNIVERSITY_VIDEOS';

  @ViewChild('videoContainer', { static: true })
  videoContainer: ElementRef<HTMLDivElement>;

  course: Course;

  player: Player;
  currentLesson: Lesson;
  videoToken: string;
  isLoadingVideo: boolean = false;
  updatingLesson: boolean = false;
  npsModal: boolean = false;

  pdfFile: PDFSource;
  isLoadingPdf: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private mediaService: MediaService,
    private routerService: RouterService,
    private layoutService: LayoutService
  ) {
    super();
  }

  ngOnInit(): void {
    this.player = Player.getInstance();

    this.activatedRoute.params.subscribe((params) => {
      const { id } = params;
      this.loadCourse(id);
    });

    this.player.on('pause', ({ duration, percent, seconds }) => {
      this.updateProgress(percent * 100);
    });
  }

  ngOnDestroy(): void {
    this.player.destroy();
  }

  async loadCourse(id: string) {
    try {
      this.course = await this.mediaService.getCourseById(id);

      const breadcrumbs: BreadCrumb[] = this.layoutService.breadcrumbs
        ? this.layoutService.breadcrumbs.concat([
            {
              label: this.course.name,
            },
          ])
        : [
            {
              label: 'Home',
              route: ['/'],
            },
            {
              label: 'Meu aprendizado',
              route: [
                CorporateUniverstyRoutes.CORPORATE_UNIVERSITY,
                CorporateUniverstyRoutes.TRAILS,
              ],
            },
            {
              label: this.course.name,
            },
          ];

      this.layoutService.updateBreadCrumb(breadcrumbs);

      if (this.course.lessons?.length === 0) {
        this.routerService.home();
      }

      this.changeLesson(
        this.course.getCurrentLesson() || this.course.lessons[0]
      );
    } catch (e) {
      this.routerService.home();
    }
  }

  async updateProgress(percent: number) {
    const clampedPercent = Math.ceil(clampProgress(percent));

    if (!this.isLoadingVideo && !this.isLoadingPdf) {
      await this.mediaService.updateLessonProgress(
        this.currentLesson.id,
        clampedPercent
      );
      this.currentLesson.progress = clampedPercent;
      this.currentLesson.isCompleted = clampedPercent === 100;

      await this.checkCompletedCourse();
      this.updatingLesson = false;
    }
  }

  async loadVideo(lesson: Lesson) {
    this.pdfFile = undefined;

    this.isLoadingVideo = true;

    await this.player.loadVideo(
      this.videoContainer.nativeElement,
      lesson.id,
      this.mediaService
    );
    this.isLoadingVideo = false;
  }

  async loadPdf() {
    this.isLoadingPdf = true;

    if (this.videoContainer.nativeElement.firstChild) {
      this.videoContainer.nativeElement.removeChild(
        this.videoContainer.nativeElement.firstChild
      );
    }

    this.pdfFile = `${CONST.NEW_MEDIA_ENDPOINT}/lessons/${this.currentLesson.id}/file`;
  }

  async changeLesson(lesson: Lesson) {
    if (
      this.currentLesson !== lesson &&
      !this.isLoadingVideo &&
      !this.isLoadingPdf
    ) {
      this.currentLesson = lesson;
      if (lesson.type === 'video') {
        this.loadVideo(lesson);
      } else {
        this.loadPdf();
      }
    }
  }

  downloadCertificado(): void {
    this.mediaService.downloadCertificate(this.course.id);
  }

  openExam(): void {
    window.open(this.course.exameUrl);
  }

  async checkCompletedCourse(): Promise<void> {
    const completedLessons: number = this.course.lessons.reduce(
      (previousCount: number, currentLesson: Lesson) => {
        const isCompleted = currentLesson.isCompleted ? 1 : 0;
        return previousCount + isCompleted;
      },
      0
    );

    const previousProgress = this.course.progress;

    const progress = Math.ceil(
      (completedLessons / this.course.lessons.length) * 100
    );

    const newProgress = await this.mediaService.updateCourseProgress(
      this.course.id,
      progress
    );

    this.course.progress = progress;
    this.course.isCompleted = progress === 100;

    if (this.course.isCompleted && previousProgress < 100) {
      this.routerService.navigate(
        ['./', CorporateUniverstyRoutes.COMPLETED_COURSE],
        { relativeTo: this.activatedRoute }
      );
    } else if (progress >= 50 && !this.course.npsHalf) {
      this.npsModal = true;
    }
  }

  getHeight(): string {
    if (this.videoContainer) {
      return `${
        (200 / 355.56) * this.videoContainer.nativeElement.clientWidth
      }px`;
    }

    return '200px';
  }

  downloadContents(): void {
    downloadFile(
      `${CONST.API_ENDPOINT}/cursos/${this.course.backendId}/get-files`,
      `Material complementar - ${this.course.name}`
    );
  }

  onPdfLoadComplete() {
    this.isLoadingPdf = false;

    const elements = document.getElementsByClassName(
      'ng2-pdf-viewer-container'
    );

    const divContainer = <HTMLDivElement>elements[0];

    divContainer.scrollTo({ top: 0 });

    const onScroll = (event: any) => {
      const element: HTMLDivElement = event.target;

      const progress =
        ((element.scrollTop + element.clientHeight) / element.scrollHeight) *
        100;

      if (
        progress > 70 &&
        this.currentLesson.progress !== 100 &&
        !this.updatingLesson &&
        !this.isLoadingPdf
      ) {
        this.updatingLesson = true;
        this.updateProgress(100);
      }
    };

    divContainer.onscroll = onScroll;
  }

  async closeNpsModal() {
    this.npsModal = false;
    this.course.npsHalf = true;
    await this.mediaService.npsModalHalf(this.course.id);
  }

  getProgress(lesson: Lesson) {
    return lesson.progress ? Math.floor(lesson.progress) : 0;
  }
}
