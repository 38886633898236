export function parseMoney(valor: string): string {
  const numero = parseInt(valor, 10);
  const inteiros = parseInt(valor, 10);
  let centavos = ((parseFloat(valor) - inteiros) * 100).toString();
  if (centavos.length < 2) {
    centavos = '0' + centavos;
  }
  let numeroMilhar = '';
  let sobraString = numero.toString();
  while (sobraString.length > 3) {
    if (numeroMilhar !== '') {
      numeroMilhar =
        sobraString.substr(sobraString.length - 3) + '.' + numeroMilhar;
    } else {
      numeroMilhar = sobraString.substr(sobraString.length - 3);
    }
    sobraString = sobraString.substr(0, sobraString.length - 3);
  }
  numeroMilhar = `${sobraString}${numeroMilhar ? '.' : ''}${numeroMilhar}`;
  return 'R$ ' + numeroMilhar + ',' + centavos;
}

export function extractInteger(value: string): number {
  const intValue = parseInt(value.replace(/^\D+/g, ''));
  if (value && !isNaN(intValue)) {
    return intValue;
  }
  return 0;
}

export function extractDouble(value: string): number {
  if (!value) {
    return 0;
  }

  const splited: string[] = value.split(',').join('.').split('.');

  const integer: number = extractInteger(splited[0]);

  let decimal: string = splited[1] ? splited[1].substring(0, 2) : '0';

  if (decimal.length < 2) {
    decimal = `${decimal}0`;
  }

  const formatedDecimal = extractInteger(decimal);

  return parseFloat(
    `${integer}.${formatedDecimal < 10 ? '0' : ''}${formatedDecimal}`
  );
}

export function formatMoney(value: string | number): string {
  const doubleValue: number =
    typeof value === 'string' ? extractDouble(value) : value;
  return doubleValue.toLocaleString('pt-br', {
    currency: 'BRL',
    style: 'currency',
    minimumFractionDigits: 2,
  });
}

export function unFormatMoney(value: string): number {
  return Number((value || 'R$ 0,00').replace(/[^\d,]/g, '').replace(',', '.'));
}
