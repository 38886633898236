import CONST from '@/constants';
import { CompanyPlansResponse } from '@/models/responses/companyPlans.model';
import { User } from '@/models/user.model';
import { ColaboradorRoutes } from '@/pages/colaborador/colaborador.routes';
import { LayoutService } from '@/services/layout.service';
import { ZoomLMSService } from '@/services/zoom-lms.service';
import { CorporateUniverstyRoutes } from 'src/app/pages/corporate-university/corporate-university.routes';
import { RouterService } from 'src/app/services/router.service';
import { TranslationService } from 'src/app/services/translation.service';
import { goToDependents } from '../layout.component';
import { SubsidiesService } from '@/services/api/subsidies.service';
import { UnibradPosService } from '@/services/unibrad_pos/unibrad.service';

export interface SubmenuType {
  label: string;
  route: string[];
  blocked: boolean;
}

export interface MenuType {
  badge?: string;
  label?: string;
  id?: string;
  icon?: string;
  onClick?: () => void;
  route?: string[];
  submenus?: SubmenuType[];
  blocked?: boolean;
}

const getTranslation = (key: string) => `LAYOUT.APPBAR.${key}`;

export const Menus: (
  user: User,
  translate: TranslationService,
  plan: CompanyPlansResponse,
  layoutService: LayoutService,
  zoomLMSService: ZoomLMSService,
  unibradPosService: UnibradPosService
) => MenuType[] = (
  user: User,
  translate: TranslationService,
  plan: CompanyPlansResponse,
  layoutService: LayoutService,
  zoomLMSService: ZoomLMSService,
  unibradPosService: UnibradPosService
) => {
  const colaboradorMenu: MenuType[] = [
    {
      label: translate.getTranslation(getTranslation('labelInicio')),
      route: [''],
    },
    {
      label: translate.getTranslation(getTranslation('labelCategorias')),
      submenus: [],
    },
    {
      label: translate.getTranslation(getTranslation('labelAprendizado')),
      route: user.hasLMS()
        ? [
            CorporateUniverstyRoutes.CORPORATE_UNIVERSITY,
            CorporateUniverstyRoutes.CATALOG,
          ]
        : ['eduplay', 'cursos_andamento'],
    },
  ];

  if (plan?.features.includes('zoom_lms')) {
    const zoomLMSLabel = plan?.zoom_config?.label || 'Acessar LMS';
    colaboradorMenu.push({
      label: zoomLMSLabel,
      icon: 'external',
      onClick: async () => {
        try {
          const redirectUrl = await zoomLMSService.getRedirectUrl();

          if (redirectUrl) window.location.assign(redirectUrl);
        } catch (e) {
          layoutService.error(e.error?.data?.message);
        }
      },
    });
  }

  if (plan?.features.includes('unibrad_pos')) {
    colaboradorMenu.push({
      label: 'Unibrad',
      icon: 'external',
      onClick: async () => {
        window.location.assign(`${plan?.promo_landing_page.url}`);
      },
    });
  }

  return colaboradorMenu;
};

export const UserMenu: (
  user: User,
  translate: TranslationService,
  router: RouterService,
  plan: CompanyPlansResponse,
  subsidiesService: SubsidiesService,
  layoutService: LayoutService
) => Array<MenuType[]> = (
  user: User,
  translate: TranslationService,
  router: RouterService,
  plan: CompanyPlansResponse,
  subsidiesService: SubsidiesService,
  layoutService: LayoutService
) => {
  const commonSection = (user: User, translate: TranslationService) => {
    const section: MenuType[] = [];

    const checkEligibleForSubsidies = async () => {
      try {
        return await subsidiesService.fetchEligibilityForSubsidies();
      } catch (error) {
        layoutService.error(error.error?.data?.message);
        return false;
      }
    };

    if (
      plan?.features.includes('dependents_included') &&
      !user.colaborador.isDependent()
    ) {
      section.push({
        label: translate.getTranslation(getTranslation('dependents.manage')),
        route: [ColaboradorRoutes.MANAGE_DEPENDENTS],
      });
    } else if (user.hasDependent() && !user.colaborador.isDependent()) {
      section.push({
        onClick: () => goToDependents(),
        label: 'Cadastrar dependentes',
      });
    }

    if (user.refundEnabled() && plan?.features.includes('refund_legacy')) {
      section.push({
        label: translate.getTranslation(
          getTranslation('solicitacoes.submenus.acompanharSolicitacao')
        ),
        route: [ColaboradorRoutes.MY_REFUNDS, ColaboradorRoutes.OVERVIEW],
        blocked: false,
      });
    }

    if (plan?.features.includes('subsidies') && checkEligibleForSubsidies()) {
      section.push({
        label: translate.getTranslation(
          getTranslation('subsidies.mySubsidies')
        ),
        route: [ColaboradorRoutes.MY_SUBSIDIES],
        blocked: false,
      });
    }

    section.push({
      label: translate.getTranslation(getTranslation('labelSenha')),
      route: ['alterar_senha'],
    });
    return section;
  };

  const RHSection = (user: User, translate: TranslationService) => {
    if (!(user.isAdmin() || user.isHR() || user.isRefundManager())) return null;

    const section: MenuType[] = [];

    if (user.isHR) {
      section.push(
        {
          badge: 'Novo',
          label: translate.getTranslation(getTranslation('labelRelatorios')),
          route: ['empresa/dashboard'],
        },
        {
          label: translate.getTranslation(getTranslation('employees.manage')),
          route: ['empresa', 'employees'],
        }
      );

      if (user.hasLMS()) {
        section.push({
          label: translate.getTranslation(
            'EMPRESA.RELATORIOS.REPORTS_NAVIGATION.lms'
          ),
          route: ['empresa/relatorios/lms_report'],
        });
      }
    }

    if (plan?.features.includes('refund_legacy') && user.isRefundManager()) {
      section.push({
        label: translate.getTranslation(getTranslation('solicitacaoReembolso')),
        route: ['empresa/refund/refund_pending'],
      });
    }

    return section;
  };

  const FinancialSection = (user: User, translate: TranslationService) => {
    if (
      !(user.isAdmin() || user.isFinancial()) ||
      !plan?.features.includes('refund_legacy')
    )
      return null;

    const section: MenuType[] = [
      {
        label: translate.getTranslation(getTranslation('analiseComprovantes')),
        route: ['empresa/refund_receipt/refund_receipt_approval'],
      },
    ];

    return section;
  };

  const LogoutSection = (
    translate: TranslationService,
    router: RouterService
  ) => {
    const section: MenuType[] = [
      {
        onClick: () => {
          localStorage.removeItem('token');
          localStorage.removeItem(CONST.TOKEN_KEY);
          router.navigate(['login']);
        },
        label: translate.getTranslation(getTranslation('logout')),
        route: ['login'],
      },
    ];

    return section;
  };

  return [
    commonSection(user, translate),
    RHSection(user, translate),
    FinancialSection(user, translate),
    LogoutSection(translate, router),
  ].filter((section) => !!section);
};
