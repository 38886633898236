import { PlansHome } from '@/react/components/idp/home';
import { Component } from '@angular/core';

@Component({
  selector: 'app-idp-plans',
  templateUrl: './plans.component.html',
})
export class IdpPlansComponent {
  IdpPlansComponent = PlansHome;
}
