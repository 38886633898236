import { FiltersSchema } from '@/react/components/filters/state/interfaces';

const mountPeriodsOptions = (periods) => {
  return periods.map((period) => ({
    label: period.label,
    value: period.type,
  }));
};

const mountHubsOptions = (hubs) => {
  return hubs.map((hub) => ({
    label: hub.name,
    value: String(hub.id),
  }));
};

const mountLocationOptions = ({ cities, states }) => {
  const statesOptions = states.map((state) => ({
    label: `${state.name} (${state.abbreviation})`,
    value: state.abbreviation,
  }));

  const citiesOptionsByState = states.reduce((acc, state) => {
    acc[state.abbreviation] = cities.map((city) => ({
      label: city,
      value: city,
    }));

    return acc;
  }, {});

  return {
    statesOptions,
    citiesOptionsByState,
  };
};

export const createSchema = (filtersData): FiltersSchema => {
  const { cities, hubs, periods, states } = filtersData.filters;
  const locationOptions = mountLocationOptions({ cities, states });
  const hubsOptions = hubs ? mountHubsOptions(hubs) : [];
  const periodsOptions = periods ? mountPeriodsOptions(periods) : [];

  const schema: FiltersSchema = [
    {
      id: 'location',
      props: {
        multiple: false,
        options: locationOptions,
        title: 'Localização',
      },
      saveValue: true,
      type: 'location',
    },
    {
      id: 'hub',
      props: {
        disabled: true,
        multiple: false,
        options: hubsOptions,
        placeholder: 'Selecione um polo',
        title: 'Polo',
      },
      saveValue: true,
      type: 'select',
    },
    {
      id: 'period',
      props: {
        disabled: true,
        options: periodsOptions,
        placeholder: 'Selecione um turno',
        title: 'Turno',
      },
      saveValue: true,
      type: 'select',
    },
  ];

  return schema;
};
