import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CorporateUniversityGuard } from 'src/app/services/guards/corporate-university.guard';
import { ConclusaoCursoComponent } from './conclusao-curso/conclusao-curso.component';
import { CursosPainelComponent } from './cursos-painel/cursos-painel.component';
import { VideoAulasComponent } from './video-aulas/video-aulas.component';

const routes: Routes = [
  {
    path: '',

    children: [
      {
        path: '',
        canActivate: [CorporateUniversityGuard],
        data: { isLMS: false },
        children: [
          {
            path: 'cursos_andamento',
            component: CursosPainelComponent,
          },
          {
            path: 'cursos_concluidos',
            component: CursosPainelComponent,
          },
          {
            path: ':id/conclusao_curso',
            component: ConclusaoCursoComponent,
          },
        ],
      },
      {
        path: ':id/curso',
        component: VideoAulasComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EduplayRoutingModule {}
