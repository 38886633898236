import { restClient } from '@/react/utils/fetch';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as Sentry from '@sentry/browser';
import * as React from 'react';
import { toastSuccess, toastError } from '@/react/utils/custom-toast';

const ResetEmployeePassword = ({ employee, open, toggleOpen }) => {
  const [loading, setLoading] = React.useState(false);

  const handlePasswordReset = async () => {
    setLoading(true);

    try {
      const response = await restClient.post(
        `/employees/${employee.id}/reset-password`
      );

      const messageToast =
        response?.data?.data?.message || 'Email alterado com sucesso.';

      toastSuccess({ messageToast, handleCallback: toggleOpen() });
    } catch (error) {
      const messageToast = error?.message ?? 'Erro ao alterar email!';

      toastError({ messageToast, handleCallback: toggleOpen() });

      setLoading(false);
      Sentry.captureException(error);
    }

    setLoading(false);
  };

  return (
    <Dialog onClose={toggleOpen} fullWidth open={open}>
      <DialogTitle>Redefinir senha</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="body2">
            Ao confirmar, enviaremos um email para{' '}
            <strong>'{employee?.email}'</strong> definir uma nova senha.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleOpen} variant="outlined" color="primary">
          Cancelar
        </Button>
        <LoadingButton
          loading={loading}
          onClick={handlePasswordReset}
          type="submit"
          variant="contained"
        >
          Redefinir senha
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ResetEmployeePassword;
