import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import { useFilters } from '@/react/components/filters/state';
import { useScreenSizes } from '@/react/utils/useScreenSizes';

interface ExtraProps {
  isMobile: boolean;
}

const Styled = {
  Switch: styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-thumb': {
      border: '1px solid',
      borderColor: alpha(theme.palette.common.black, 0.23),
      boxShadow: theme.shadows[2],
    },
  })),
  Wrapper: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isMobile',
  })<ExtraProps>(({ theme, isMobile }) => ({
    backgroundColor: isMobile
      ? theme.palette.neutral[200]
      : theme.palette.neutral[50],
    borderRadius: theme.shape.borderRadius,
    border: '1px solid',
    borderColor: alpha(theme.palette.common.black, 0.23),
    padding: `${theme.spacing(1)} ${theme.spacing(2.5)} 0 0`,

    '& .MuiChip-deleteIcon': {
      color: theme.palette.neutral[600],
    },
  })),
};

export const FilterSwitch = ({
  id,
  label,
  title,
  type,
}: {
  id: string;
  label: string;
  title?: string;
  type: string;
}) => {
  const { actions, state } = useFilters();
  const { isSmallScreen } = useScreenSizes();

  const handleChange = (e) => {
    actions.toggleById(id, e.target.checked);
    actions.setPage(1);
  };

  const checked = !!state[id].values[0];

  return (
    <FormControl data-filter-type={type}>
      {title && <FormLabel htmlFor={id}>{title}</FormLabel>}
      <Styled.Wrapper isMobile={isSmallScreen}>
        <FormControlLabel
          control={
            <Styled.Switch
              checked={checked}
              color="primary"
              onClick={handleChange}
              size="small"
              value="checked"
            />
          }
          label={<Typography variant="body3">{label}</Typography>}
          labelPlacement="start"
          sx={{ justifyContent: 'space-between', width: '100%' }}
        />
      </Styled.Wrapper>
    </FormControl>
  );
};
