import { buttonSizes } from '../tokens';

const MuiButton = {
  defaultProps: {
    disableElevation: true,
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => {
      const { color = 'primary', variant = 'contained' } = ownerState;
      const { palette } = theme;

      const fixedHoverColor = (() => {
        if (variant === 'contained') {
          return palette[color]?.contrastText;
        }
        return palette[color]?.main;
      })();

      const minHeights = {
        small: buttonSizes.small,
        medium: buttonSizes.medium,
        large: buttonSizes.large,
      };

      const styles = {
        ...{
          minHeight: minHeights[ownerState.size],
          textTransform: 'none' as const,

          // Fix anchors. Bootstrap has a global a:hover style that
          // conflits with our theme, so we need to override it
          '&:is(a)': {
            '&:hover': {
              color: fixedHoverColor,
            },
          },
        },
      };

      return styles;
    },
  },
};

export default MuiButton;
