import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import CardLink from '@/react/components/card-link.component';

// SVG Icons
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import AdfScannerOutlinedIcon from '@mui/icons-material/AdfScannerOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import ArchitectureOutlinedIcon from '@mui/icons-material/ArchitectureOutlined';
import BiotechOutlinedIcon from '@mui/icons-material/BiotechOutlined';
import BoltRoundedIcon from '@mui/icons-material/BoltRounded';
import BubbleChartOutlinedIcon from '@mui/icons-material/BubbleChartOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import MenuBookIconIcon from '@mui/icons-material/MenuBook';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import ForestOutlinedIcon from '@mui/icons-material/ForestOutlined';
import GavelRoundedIcon from '@mui/icons-material/GavelRounded';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import HailRoundedIcon from '@mui/icons-material/HailRounded';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import LandscapeOutlinedIcon from '@mui/icons-material/LandscapeOutlined';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import SchemaOutlinedIcon from '@mui/icons-material/SchemaOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import SettingsAccessibilityOutlinedIcon from '@mui/icons-material/SettingsAccessibilityOutlined';
import SignpostOutlinedIcon from '@mui/icons-material/SignpostOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import TranslateIcon from '@mui/icons-material/Translate';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';

const Icon = ({ identifier }) => {
  const iconMap = {
    1: AdfScannerOutlinedIcon,
    2: ForestOutlinedIcon,
    3: AnalyticsOutlinedIcon,
    4: ArchitectureOutlinedIcon,
    5: BusinessCenterOutlinedIcon,
    6: HeadsetMicOutlinedIcon,
    7: SignpostOutlinedIcon,
    8: LocalLibraryOutlinedIcon,
    9: MenuBookIconIcon,
    10: BoltRoundedIcon,
    11: EngineeringOutlinedIcon,
    12: LandscapeOutlinedIcon,
    13: AccountBalanceOutlinedIcon,
    14: MedicalServicesOutlinedIcon,
    15: ApartmentRoundedIcon,
    16: GroupsOutlinedIcon,
    17: IntegrationInstructionsOutlinedIcon,
    18: GavelRoundedIcon,
    19: ConstructionRoundedIcon,
    20: PrecisionManufacturingOutlinedIcon,
    21: HailRoundedIcon,
    22: CampaignOutlinedIcon,
    23: BubbleChartOutlinedIcon,
    24: SettingsAccessibilityOutlinedIcon,
    25: SchemaOutlinedIcon,
    26: HomeWorkOutlinedIcon,
    27: SecurityOutlinedIcon,
    28: StorefrontOutlinedIcon,
    29: BiotechOutlinedIcon,
    30: HandshakeOutlinedIcon,
    31: LocalShippingOutlinedIcon,
    32: SupervisedUserCircleOutlinedIcon,
    33: TranslateIcon,
    34: CategoryOutlinedIcon,
    35: WorkspacePremiumOutlinedIcon,
  };

  const IconComponent = iconMap[identifier];

  return (
    IconComponent && (
      <IconComponent
        sx={(theme) => ({
          color: theme.palette.secondary[700],
        })}
      />
    )
  );
};

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  borderTopWidth: 0,
  borderLeftWidth: 0,
  [theme.breakpoints.up('md')]: {
    gridAutoRows: `minmax(${124}px, auto)`,
  },
}));

const KnowledgeAreaCard = ({ data }) => (
  <StyledCard variant="outlined" square>
    <CardActionArea
      component={CardLink}
      href={data.link}
      sx={{
        alignItems: 'start',
        border: 0,
        display: 'flex',
        height: '100%',
        justifyContent: { xs: 'start', md: 'center' },
      }}
    >
      <CardContent sx={{ p: { xs: 2, md: 3 } }}>
        <Box display="flex" justifyContent="center">
          <Grid
            container
            alignItems="center"
            direction={{ xs: 'row', md: 'column' }}
            flexWrap="nowrap"
            spacing={{ xs: 3, md: 1 }}
          >
            <Grid item>
              <Icon identifier={data.id} />
            </Grid>
            <Grid item>
              <Typography
                variant="body3"
                textAlign={{ xs: 'left', md: 'center' }}
              >
                {data.description_pt_br}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </CardActionArea>
  </StyledCard>
);

export default KnowledgeAreaCard;
