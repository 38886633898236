import * as React from 'react';
import DOMPurify from 'dompurify';

const DEFAULT_CLASS_NAME = 'html-safe';
const DOMPURIFY_CONFIG = {
  FORBID_ATTR: ['style'], // remove inline styles
};

const HTMLSafe = ({
  className = '',
  content,
  elementType: ElementType = 'div',
}: {
  className?: string;
  content: string;
  elementType?: keyof React.JSX.IntrinsicElements;
}) => {
  const classes = `${DEFAULT_CLASS_NAME} ${className}`.trim();
  const cleanContent = DOMPurify.sanitize(content, DOMPURIFY_CONFIG);

  return (
    <ElementType
      className={classes}
      dangerouslySetInnerHTML={{ __html: cleanContent }}
    />
  );
};

export default HTMLSafe;
