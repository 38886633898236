export function enumerate<T extends string>(
  values: ReadonlyArray<T>
): Enumerator<T> {
  type Type = (typeof values)[number];

  const set = new Set(values);

  const is = (value: any): value is Type => {
    return set.has(value);
  };

  const record = Object.create(null) as { readonly [K in Type]: K };
  // @ts-ignore
  values.forEach((k) => (record[k] = k));
  Object.freeze(record);

  return Object.freeze({
    is,
    set,
    infer: null as unknown as (typeof values)[number],
    value: record,
    values,
  });
}

export type Enumerator<T extends string> = Readonly<{
  is(value: any): value is T;
  set: Set<T>;
  infer: T;
  value: { readonly [K in T]: K };
  values: readonly T[];
}>;
