import * as z from 'zod';
import { isCPFLike, isValidEmail } from '../../../utils/string';

export const formValidationSchema = z
  .object({
    name: z.string(),
    email: z.string(),
    cpf: z.string(),
    internal_employee_id: z.string().optional(),
    type_collaborator: z.string().optional(),
    position_company: z.string().optional(),
    department: z.string().optional(),
    team: z.string().optional(),
    manager_email: z.string().optional(),
    building: z.string().optional(),
    region: z.string().optional(),
    state: z.string().optional(),
    city: z.string().optional(),
    company: z.string().optional(),
    cost_center: z.string().optional(),
    is_admin: z.boolean(),
    type_registration: z.string().optional(),
  })
  .superRefine((data, ctx) => {
    if (!data.name) {
      ctx.addIssue({
        path: ['name'],
        code: z.ZodIssueCode.invalid_string,
        type: 'string',
        inclusive: true,
        message: 'Nome é obrigatório',
      });
    }

    if (data.name.length < 5) {
      ctx.addIssue({
        path: ['name'],
        code: z.ZodIssueCode.invalid_string,
        type: 'string',
        inclusive: true,
        message: 'O Nome deve ter pelo menos 5 caracteres.',
      });
    }

    if (data.name.length > 255) {
      ctx.addIssue({
        path: ['name'],
        code: z.ZodIssueCode.invalid_string,
        type: 'string',
        inclusive: true,
        message: 'O Nome não pode ter mais de 255 caracteres.',
      });
    }

    if (data.type_registration === 'email' && !data.email) {
      ctx.addIssue({
        path: ['email'],
        code: z.ZodIssueCode.invalid_string,
        type: 'string',
        inclusive: true,
        message: 'Email é obrigatório',
      });
    }

    if (data.type_registration === 'email' && !isValidEmail(data.email)) {
      ctx.addIssue({
        path: ['email'],
        code: z.ZodIssueCode.invalid_string,
        type: 'string',
        inclusive: true,
        message: 'Informe um email valido!',
      });
    }
    if (data.type_registration === 'cpf' && !isCPFLike(data.cpf)) {
      ctx.addIssue({
        path: ['cpf'],
        code: z.ZodIssueCode.invalid_type,
        minimum: 11,
        type: 'string',
        inclusive: true,
        message: 'Informe um CPF valido',
      });
    }

    if (data.type_registration === 'cpf' && data.cpf.length > 14) {
      ctx.addIssue({
        path: ['cpf'],
        code: z.ZodIssueCode.invalid_type,
        minimum: 11,
        maximum: 14,
        type: 'string',
        inclusive: true,
        message: 'CPF é obrigatório',
      });
    }
  });

export const emailValidationSchema = z
  .object({
    email: z.string(),
  })
  .superRefine((data, ctx) => {
    if (data.email.length < 1) {
      ctx.addIssue({
        path: ['email'],
        code: z.ZodIssueCode.invalid_string,
        type: 'string',
        inclusive: true,
        message: 'Email é obrigatório',
      });
    }
    if (!data.email || data.email.length < 1) {
      ctx.addIssue({
        path: ['email'],
        code: z.ZodIssueCode.invalid_string,
        type: 'string',
        inclusive: true,
        message: 'Email é obrigatório',
      });
    }

    if (!isValidEmail(data.email)) {
      ctx.addIssue({
        path: ['email'],
        code: z.ZodIssueCode.invalid_string,
        type: 'string',
        inclusive: true,
        message: 'Informe um email valido!',
      });
    }
  });
