import { extractNumber } from './number';
import { AbstractControl, ValidationErrors } from '@angular/forms';

export function cepMask(value: string = '') {
  let cep = extractNumber(value);

  if (cep) {
    cep = cep.replace(/\D/g, '');
    cep = cep.substring(0, 8);
    cep = cep.replace(/(\d)(\d{3})$/, '$1-$2');
    return cep;
  }
  return '';
}

export function cepValidation(cep: string = ''): boolean {
  return cep === '' || cep.length === 8;
}

export function postalCodeValidation(postal_code: string = ''): boolean {
  return cepValidation(postal_code);
}

export function postalCodeValidator(control: AbstractControl): ValidationErrors | null {
  const numberPhone = extractNumber(control.value);

  if (!cepValidation(numberPhone)) {
    return { postal_code: 'CEP inválido' };
  }
}
