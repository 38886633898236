export enum PriceTypes {
  one_off = 'one_off',
  tuition = 'tuition',
}

export enum PeriodTypes {
  AFTERNOON = 'afternoon',
  EVENING = 'evening',
  FLEX = 'flex',
  FULL_TIME = 'full_time',
  MORNING = 'morning',
  UPON_REQUEST = 'upon_request',
}

export enum PeriodLabels {
  AFTERNOON = 'Tarde',
  EVENING = 'Noite',
  FLEX = 'Flexível',
  FULL_TIME = 'Integral',
  MORNING = 'Manhã',
  UPON_REQUEST = 'Sob consulta',
}

export enum DurationTypes {
  semester = 'semester',
  hour = 'hour',
  day = 'day',
  month = 'month',
  year = 'year',
}

export enum Modalities {
  ONLINE = 'online',
  LIVE_ONLINE = 'live_online',
  HYBRID = 'hybrid',
  PRESENTIAL = 'presential',
  PRIVATE_CLASS = 'private_class',
}

export enum CourseTypes {
  FREE = 'free',
  GRADUATION = 'graduation',
  LANGUAGES = 'languages',
  POSTGRADUATE = 'postgraduate',
  TECHNICAL = 'technical',
}

export interface CheckoutConfig {
  charging_amount?: number;
  charging_percentage?: number;
  has_extra_payment: boolean;
}

export interface Institution {
  id: number;
  name: string;
  about: string | null;
  logo_url: string | null;
  logo_background_color: string | null;
  checkout_config?: CheckoutConfig;
}

export interface ProductCourse {
  id: number;
  name: string;
  about: string | null;
  course_type: {
    type: string;
    label: string;
  };
  duration: {
    type: DurationTypes;
    quantity: number;
  };
  modality: {
    type: string;
    label: string;
  };
  skills: string[];
  offers_quantity: number;
  knowledge_areas?: string[];
}

export interface Installment {
  fee: number;
  min: number;
  max: number;
}

export interface Offer {
  hash_key: string;
  id: number;
  period: {
    type: PeriodTypes;
    label: string;
  };
  duration: {
    type: DurationTypes;
    quantity: number;
  };
  regressive_discount?: {
    month: number;
    discount: number;
    sale_price: number;
  };
  checkout_config?: CheckoutConfig;
  is_full_included: boolean;
  sale_type: PriceTypes;
  sale_price: number;
  discount_percentage: number;
  original_price: number;
  pricing_description: {
    type: PriceTypes;
    original_price: number | null;
    discount_percentage: number | null;
    sale_price: number | null;
    payment_conditions: {
      installments?: Installment[];
    };
  };
}

export interface OfferWithDetails extends Offer {
  institution: Institution;
  course: ProductCourse;
  checkout_pricing: {
    calculated_amount: number;
    coupon_discount_amount: number;
    original_amount: number;
    installments?: {
      amount: number;
      count: number;
    }[];
  };
}

export interface CatalogFilters {
  ids?: number[];
  terms?: string;
  course_types?: string[];
  knowledge_areas?: number[];
  institutions?: number[];
  modalities?: string[];
  features?: string[];
  states?: string[];
  city?: string;
  budget?: {
    min: number;
    max: number;
  };
}

export enum CatalogSort {
  NONE = 'none',
  RELEVANCE = 'relevance',
  PRICE_ASC = 'price_asc',
  PRICE_DESC = 'price_desc',
}

export interface CatalogSearchRequest {
  filters?: CatalogFilters;
  sort?: CatalogSort;
  page?: number;
  page_size?: number;
}

export interface CatalogCourse {
  id: number;
  offer_city: string;
  offer_hub: number;
  offer_id: number;
  offer_period: string;
  offer_state: string;
  offers_quantity: number;
  institution_name: string | null;
  institution: {
    name: string;
    logo_url: string;
    logo_background_color: string;
  };
  course_name: string;
  course_type: {
    type: string;
    label: string;
  };
  duration: {
    type: string;
    quantity: number;
  };
  discount_percentage: number;
  price_with_discount: number;
  original_price: number;
  price_type: string;
  installments: number;
  modalities: [
    {
      type: string;
      label: string;
    },
  ];
  knowledge_areas?: string[];
}

export type State = {
  name: string;
  abbreviation: string;
};

export type Hub = {
  id: number;
  name: string;
};

export type Period = {
  type: string;
  label: string;
};

export interface ProductCourseSearchResult {
  data: {
    course: ProductCourse;
    institution: Institution;
  };
}

export interface ProductOfferSearchResult {
  data: {
    filters: {
      cities: string[];
      hubs: Hub[];
      periods: Period[];
      states: State[];
    };
    offers_quantity: number;
    cheapest_offer: Offer;
  };
}

export interface CatalogSearchResponse {
  data: {
    items: CatalogCourse[];
    showcase: CatalogCourse[];
    pagination: {
      total: number;
      total_pages: number;
      page: number;
      page_size: number;
    };
  };
}
