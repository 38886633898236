import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'component-collapsible',
  templateUrl: './collapsible.component.html',
  styleUrls: ['./collapsible.component.scss'],
})
export class CollapsibleComponent implements OnInit {
  @Input()
  label: string;

  @ViewChild('container', { static: true })
  container: ElementRef;

  expanded: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  onCollapse(element) {
    this.expanded = !this.expanded;

    const panel = this.container.nativeElement;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px';
    }
  }
}
