import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { CatalogClient } from '@/react/data/catalog/catalog-client';

import { ProductOfferSearchResult } from './interfaces';

interface useOfferFiltersReturn {
  data: ProductOfferSearchResult['data'];
  error: AxiosError['response'] | undefined;
  isFetching: boolean;
}

export function useOfferFilters({
  courseId,
  enabled = true,
  queryParams,
}: {
  courseId: number;
  enabled?: boolean;
  queryParams: string;
}) {
  const result = useQuery<ProductOfferSearchResult>({
    enabled,
    queryKey: ['useOfferFilters', queryParams],
    refetchOnMount: false,
    retry: false,
    queryFn: async () => {
      return CatalogClient.getCourseFiltersById({ courseId, queryParams });
    },
  });

  return React.useMemo(() => {
    const error = result.error as AxiosError;
    const data = result.data?.data;

    return {
      data,
      error: error?.response,
      isFetching: result.isFetching,
    };
  }, [result.data, result.isFetching]) as useOfferFiltersReturn;
}
