import { Component, OnInit } from '@angular/core';

import { LayoutService } from 'src/app/services/layout.service';
import { ApiService } from 'src/app/services/api.service';
import { PasswordUtil } from 'src/app/utils/password';
import { TranslationService } from 'src/app/services/translation.service';
import { RouterService } from 'src/app/services/router.service';

@Component({
  selector: 'app-alterar-senha',
  templateUrl: './alterar-senha.component.html',
  styleUrls: ['./alterar-senha.component.scss'],
})
export class AlterarSenhaComponent implements OnInit {
  formData = {
    senha: '',
    novaSenha: '',
    confirmarSenha: '',
  };

  erros = {
    senhaIncorreta: false,
    senhaFraca: false,
    senhaDiferente: false,
    campoEmBranco: false,
    mesmaSenha: false,
  };

  submiting: boolean = false;

  constructor(
    private apiService: ApiService,
    private layoutService: LayoutService,
    private routerService: RouterService,
    private translate: TranslationService
  ) {}

  ngOnInit(): void {
    this.layoutService.updateBreadCrumb([
      {
        label: 'Home',
        route: ['/'],
      },
      {
        label: 'Minha conta',
      },
      {
        label: 'Alterar senha',
      },
    ]);
  }

  clearForm() {
    this.formData.confirmarSenha = '';
    this.formData.senha = '';
    this.formData.novaSenha = '';
  }

  onSalvar(): void {
    if (
      !this.formData.senha ||
      !this.formData.confirmarSenha ||
      !this.formData.novaSenha
    ) {
      this.erros.campoEmBranco = true;
      return;
    }

    if (this.formData.senha === this.formData.novaSenha) {
      this.erros.mesmaSenha = true;
      return;
    }

    if (!PasswordUtil.validate(this.formData.novaSenha)) {
      this.erros.senhaFraca = true;
      return;
    }

    if (this.formData.novaSenha !== this.formData.confirmarSenha) {
      this.erros.senhaDiferente = true;
      return;
    }

    this.submiting = true;

    this.apiService
      .post('alterar_senha', {
        senha: this.formData.senha,
        novaSenha: this.formData.novaSenha,
      })
      .subscribe(
        () => {
          this.layoutService.success(this.getTranslation('sucesso'));
          this.submiting = false;
          this.clearForm();
          this.routerService.home();
        },
        (e) => {
          if (e.error.statusCode === 401) {
            this.erros.senhaIncorreta = true;
          } else {
            this.layoutService.error(e.error.data.message);
          }
          this.submiting = false;
        }
      );
  }

  limparErros() {
    Object.keys(this.erros).forEach((key) => {
      this.erros[key] = false;
    });
  }

  erroSenhaMensagem() {
    return this.erros.mesmaSenha
      ? this.getTranslation('errorSamePassword')
      : this.getTranslation('errorWeakPassword');
  }

  getTranslation(key: string) {
    return this.translate.getTranslation(`COLABORADOR.ALTERAR_SENHA.${key}`);
  }
}
