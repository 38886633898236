import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { calculateRefundableValueForPolicy } from '@/react/data/subsidy/calculateRefundableValueForPolicy';
import { useFilters } from '@/react/components/filters/state';
import { useGtm } from '@/react/components/state/useGtm';

import { OfferFilters } from '../offer-filters';
import { OfferPrices, Refund, RegressiveDiscount } from '../offer-prices';
import { OfferDetailsEnrollment } from './offer-details-enrollment.component';
import { InstitutionLogo } from '../components';
import { useProductPage } from '../state';
import { ProductPageAsyncResources } from '../state/interfaces';
import { SubscriptionStatusCodes, SubscriptionStatusPendingCodes } from '@/react/data/course/interfaces';

import CONST from '@/constants';

import enrollmentInProgressImage from '@/react/assets/images/enrollment-in-progress.svg';
import enrollmentSuccessImage from '@/react/assets/images/enrollment-success.svg';
import { PlayArrow } from '@mui/icons-material';

const Styled = {
  Content: styled(Box)``,
  Card: styled(Box)`
    display: flex;
    flex: 1;
    flex-direction: column;
  `,
  Header: styled(Box)`
    align-items: center;
    border: 1px solid ${({ theme }) => theme.palette.common.white};
    background-color: ${({ theme }) => theme.palette.common.white};
    display: flex;
    flex: 0 0 80px;
    justify-content: center;
  `,
  Wrapper: styled(Box)`
    align-items: flex-start;
    flex: 1;
    display: flex;
  `,
};

const requiredFilters = ['location', 'hub', 'period'];

const buildSubsidyRequestParams = (courseId, course, offer) => {
  if (!course || !offer) return null;

  return {
    course: {
      checkout_type: 'internal',
      course_type: course?.course_type.type,
      duration_type: course?.duration.type,
      duration: course?.duration.quantity,
      id: +courseId,
      price: offer?.pricing_description.sale_price,
      type: offer?.pricing_description.type,
    },
  };
};

export const OfferDetails = () => {
  const {
    course,
    courseId,
    institution,
    loading,
    offer,
    subscriptions,
    updateState: updateProductPageState,
  } = useProductPage();
  const { values: filtersValues } = useFilters();
  const gtm = useGtm();

  const subsidyRequestParams = buildSubsidyRequestParams(
    courseId,
    course,
    offer
  );

  const { data: subsidyPolicy, isFetching: subsidyLoading } =
    calculateRefundableValueForPolicy(subsidyRequestParams);

  const [subscriptionStatus, setSubscriptionStatus] = React.useState(null);

  React.useEffect(() => {
    if (subsidyLoading) {
      updateProductPageState((state) => ({
        ...state,
        loading: [...state.loading, ProductPageAsyncResources.subsidy],
      }));
    } else {
      updateProductPageState((state) => ({
        ...state,
        loading: state.loading.filter(
          (key) => key !== ProductPageAsyncResources.subsidy
        ),
        subsidy: subsidyPolicy,
      }));

      if (course && offer) {
        gtm.trackCourseEvent(course, offer, institution);
      }
    }
  }, [subsidyLoading]);

  React.useEffect(() => {
    setSubscriptionStatus(getCourseSubscriptionStatus());
  }, [subscriptions]);
  
  const renderHeaderContent = () => {
    if (loading.includes(ProductPageAsyncResources.course)) {
      return (
        <Skeleton
          animation="wave"
          variant="rounded"
          sx={{ height: 'calc(100% - 48px)', width: 'calc(100% - 48px)' }}
        />
      );
    }

    if (institution) {
      return <InstitutionLogo institution={institution} />;
    }

    return null;
  };

  const isAllSelected = React.useMemo(
    () =>
      requiredFilters.every((filter) =>
        Object.keys(filtersValues).includes(filter)
      ),
    [filtersValues]
  );

  const getCourseSubscriptionStatus = () => {
    if (!subscriptions) return null;

    for (let subscription of subscriptions) {
      if (subscription.status === SubscriptionStatusCodes.registered) {
        return 'completed';
      }

      if (SubscriptionStatusPendingCodes.has(subscription.status)) {
        return 'pending';
      }

      if (subscription.status === SubscriptionStatusCodes.directed_ip) {
        return 'directed';
      }
    }

    return null;
  }

  const SubscriptionNew = () => {
    return(
      <Typography
        component='div'
        variant='body3'
        mt={1}
      >
        Se deseja realizar uma nova matrícula,
        <Button 
        sx={{
          color: 'primary.700',
          fontWeight: (theme) => theme.typography.fontWeightMedium,
          textDecoration: 'underline',
          minHeight: 0,
          padding: 0,
          mt: '-5px'
        }}
        onClick={() => setSubscriptionStatus(null)}> clique aqui.</Button>
      </Typography>
    )
  }

  const SubscriptionInProgress = () => {
    return (
      <Wrapper>
        <Box sx={{ textAlign: 'center' }}>
          <Box mb={1}> 
            <img className="w-80" src={enrollmentInProgressImage} />
          </Box>
          <Typography
            variant='h6'
          >
            Sua matrícula está em andamento...
          </Typography>
          <Typography
            component='div'
            variant='body3'
            mt={1}
          >
            Se tiver dúvidas ou precisar alterar sua matrícula, entre em contato com nosso atendimento via
            <Link href='https://api.whatsapp.com/send/?phone=5511987566079&text&type=phone_number&app_absent=0'> whatsapp</Link>.
          </Typography>
          { subscriptionStatus == 'directed' && <SubscriptionNew />}
        </Box>
      </Wrapper>
    )
  }

  const SubscriptionCompleted = () => {
    return (
      <Wrapper>
        <Box sx={{ textAlign: 'center' }}>
          <Box mb={1}> 
            <img className="w-80" src={enrollmentSuccessImage} />
          </Box>
          <Typography
            variant='h6'
          >
            Matrícula realizada!
          </Typography>
          <Typography
            component='div'
            variant='body3'
            mt={1}
          >
            Se tiver dúvidas ou precisar alterar sua matrícula, entre em contato com o nosso atendimento via 
            <Link href='https://api.whatsapp.com/send/?phone=5511987566079&text&type=phone_number&app_absent=0'> whatsapp</Link>.
          </Typography>
          <SubscriptionNew />
        </Box>
      </Wrapper>
    )
  }
  
  const AccessCourse = () => {
    const handleClick = () => {
      const route = offer?.id ? `eduplay/${offer.id}/curso` : null;
      if (!route) return;
  
      window.location.assign(route);
    }

    return (
      <Wrapper>
        <Box sx={{ textAlign: 'center' }}>
          <Box mb={1}> 
            <img className="w-80" src={enrollmentSuccessImage} />
          </Box>
          <Typography
            variant='h6'
            mb={2}
          >
            Matrícula realizada!
          </Typography>
          <Button
            color="primary"
            fullWidth
            onClick={handleClick}
            variant="contained"
            size="large"
          >
            <PlayArrow sx={{mr: 1}} /> Acessar o curso
          </Button>
        </Box>
      </Wrapper>
    )
  }

  const OfferDetailsContent = () => {
    if (subscriptionStatus === 'pending' || subscriptionStatus === 'directed') {
      return <SubscriptionInProgress />
    }

    if (subscriptionStatus === 'completed') {
      const isEduplay = institution?.id === CONST.EDUPLAY_ID;
      return isEduplay ? <AccessCourse /> : <SubscriptionCompleted />
    }

    return(
      <Stack spacing={2}>
        <OfferPrices />
        <Divider />
        {offer?.regressive_discount && (
          <>
            <RegressiveDiscount
              regressiveDiscount={offer.regressive_discount}
            />
            <Divider />
          </>
        )}
        <Refund />
        <OfferFilters />
        <OfferDetailsEnrollment enable={isAllSelected} />
        <Button
          onClick={() =>
            window.open(CONST.GALENA_SERVICE_URL, '_blank').focus()
          }
          sx={{
            color: 'primary.900',
            fontWeight: (theme) => theme.typography.fontWeightMedium,
            textDecoration: 'underline',
          }}
          variant="text"
        >
          Preciso de ajuda
        </Button>
      </Stack>
    )
  }

  const Wrapper = ({ children }) => (
    <Box
      p={2}
      sx={(theme) => ({ backgroundColor: theme.palette.neutral['200'] })}
    >
      {children}
    </Box>
  );

  const offerIsLoading = loading.includes(ProductPageAsyncResources.offer) ||
    loading.includes(ProductPageAsyncResources.subscriptions);

  return (
    <Styled.Wrapper className="sticky-card">
      <Styled.Card>
        <Styled.Header>{renderHeaderContent()}</Styled.Header>
        <Styled.Content>
          <Card elevation={2} square sx={{ borderWidth: 0, p: 3 }}>
          { offerIsLoading ? 
            <Skeleton
              animation="wave"
              variant="rounded" 
              sx={{ height: '300px' }}/>
            : <OfferDetailsContent /> }
          </Card>
        </Styled.Content>
      </Styled.Card>
    </Styled.Wrapper>
  );
};
