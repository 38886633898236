import { EventEmitter } from '@angular/core';
import { CONST } from 'src/app/constants';

type CallbackMap = {
  [key: string]: {
    successCallback: Function;
    errorCallback: Function;
    removeAfterExecution: boolean;
  }[];
};

type PlayedFragment = {
  start: number;
  end: number;
};

export default class Player {
  static instance: Player;

  private callbackMap: CallbackMap = {};
  private isLoading: boolean = false;

  private playedFragments: PlayedFragment[] = [];
  private playingStart: number = 0;
  private duration: number;
  private forcedAdvance = false;

  private containerElement: HTMLDivElement;
  private iframeElement: HTMLIFrameElement;

  private readonly origin: string = '*';

  readonly eventEmitter = new EventEmitter();

  static getInstance() {
    if (!Player.instance) {
      Player.instance = new Player();
    }
    return Player.instance;
  }

  private setPlayngStart(value: number) {
    this.playingStart = Math.floor(value);
  }

  private setFragment(pauseSeconds: number, duration: number) {
    this.duration = duration;
    const integerSeconds = Math.min(Math.ceil(pauseSeconds), duration);

    const newFragment: PlayedFragment = {
      start: this.playingStart,
      end: integerSeconds,
    };

    const newFragments: PlayedFragment[] = [newFragment];

    this.playedFragments.forEach((fragment) => {
      if (newFragment.end < fragment.start) {
        newFragments.push(fragment);
      } else if (newFragment.start <= fragment.end) {
        newFragment.start = Math.min(newFragment.start, fragment.start);
        newFragment.end = Math.max(newFragment.end, fragment.end);
      } else {
        newFragments.unshift(fragment);
      }
    });

    this.playedFragments = newFragments;
  }

  private constructor() {
    window.addEventListener('message', this.onMessage);
    this.on('pause', ({ seconds, duration, percent }) => {
      if (this.forcedAdvance) {
        this.setPlayngStart(seconds);
        this.play();
      } else {
        this.setFragment(seconds, duration);
      }
    });
    this.on('play', (data) => {
      if (!data) {
        if (!this.forcedAdvance) {
          this.pause();
        }
        this.forcedAdvance = !this.forcedAdvance;
      } else if (!this.forcedAdvance) {
        this.setPlayngStart(data.seconds);
      }
    });
  }

  private onMessage = (event) => {
    if (this.iframeElement?.contentWindow !== event.source) {
      return;
    }

    const data = this.parseMessageData(event.data);

    if (data.event === 'ready') {
      this.createListeners();
    }

    this.processData(data);
  };

  private parseMessageData(data: any) {
    if (typeof data === 'string') {
      try {
        data = JSON.parse(data);
      } catch (error) {
        console.error('parseMessageDataError', error);
        return {};
      }
    }

    return data;
  }

  private async processData(data: any) {
    if (data.event) {
      if (data.event === 'error') {
        return await this.processDataItem(
          data.data.method,
          true,
          data.data.name
        );
      }

      return await this.processDataItem(data.event, false, data.data);
    } else if (data.method) {
      return await this.processDataItem(data.method, false, data.value);
    }
  }

  private async processDataItem(
    key: string,
    isError: boolean,
    params?: string
  ) {
    this.callbackMap[key].forEach(({ successCallback, errorCallback }) => {
      if (isError) {
        if (errorCallback) {
          errorCallback(params);
        }
      } else {
        successCallback(params);
      }
    });

    this.callbackMap[key] = this.callbackMap[key].filter(
      (mapList) => !mapList.removeAfterExecution
    );
  }

  private createEmbed(html: string): HTMLIFrameElement {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.firstChild as HTMLIFrameElement;
  }

  private createListeners() {
    Object.keys(this.callbackMap).forEach((key) => {
      this.postMessage('addEventListener', key);
    });
  }

  private postMessage(method: string, params?: string) {
    if (
      !this.iframeElement.contentWindow ||
      !this.iframeElement.contentWindow.postMessage
    ) {
      return;
    }

    let message: any = {
      method,
    };

    if (params) {
      message.value = params;
    }

    const ieVersion = parseFloat(
      navigator.userAgent.toLowerCase().replace(/^.*msie (\d+).*$/, '$1')
    );
    if (ieVersion >= 8 && ieVersion < 10) {
      message = JSON.stringify(message);
    }

    this.iframeElement.contentWindow.postMessage(message, this.origin);
  }

  private resetProgress() {
    this.playedFragments = [];
    this.playingStart = 0;
  }

  destroy() {
    window.removeEventListener('message', this.onMessage);
    this.callbackMap = {};
    Player.instance = null;
  }

  getProgress(): number {
    if (!this.duration) {
      return 0;
    }

    const watchedTime: number = this.playedFragments.reduce(
      (cumulated, fragment) => {
        return cumulated + (fragment.end - fragment.start);
      },
      0
    );

    return (watchedTime / this.duration) * 100;
  }

  on(
    eventName: string,
    successCallback: Function,
    errorCallback?: Function,
    removeAfterExecution: boolean = false
  ) {
    if (!this.callbackMap[eventName]) {
      this.callbackMap[eventName] = [];
    }

    this.callbackMap[eventName].push({
      successCallback,
      errorCallback,
      removeAfterExecution,
    });
  }

  loadVideo(containerElement: HTMLDivElement, vimeoUrl: string) {
    return new Promise<void>((resolve, reject) => {
      if (this.isLoading) {
        return reject();
      }

      this.isLoading = true;

      this.on(
        'ready',
        () => {
          this.isLoading = false;
          this.resetProgress();
          resolve();
        },
        () => {
          this.isLoading = false;
          this.resetProgress();
          reject();
        },

        true
      );

      this.containerElement = containerElement;

      const html = `<iframe src="${vimeoUrl}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%" title="Aula1.mp4"></iframe>`;

      const newIframe: HTMLIFrameElement = this.createEmbed(html);

      if (this.containerElement.firstChild) {
        containerElement.removeChild(this.containerElement.firstChild);
      }

      this.iframeElement = newIframe;

      containerElement.appendChild(newIframe);
    });
  }

  play() {
    this.postMessage('play');
  }

  pause() {
    this.postMessage('pause');
  }
}
