import * as React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Portal from '@mui/material/Portal';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';

import {
  getKinshipLabelFromDependent,
  getStatusFromDependent,
} from '@/react/utils/dependent.utils';
import DependentMenu from './dependent-menu.component';
import EmptyList from './empty-list.component';

import { useManageDependents } from './manage-dependents.context';

const DependentsList = () => {
  const {
    state: { dependents, alert },
    dispatch,
  } = useManageDependents();

  const handleClose = (_, reason) => {
    if (reason !== 'clickaway') dispatch({ type: 'CLOSE_ALERT' });
  };

  const isEmpty = !Boolean(dependents?.length);
  const isInitializing = !Boolean(dependents);

  return (
    <>
      <Portal>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={8000}
          ClickAwayListenerProps={{ onClickAway: () => null }}
          onClose={handleClose}
          open={alert.open}
        >
          <Alert onClose={handleClose} severity={alert.severity}>
            {alert.title && <AlertTitle>{alert.title}</AlertTitle>}
            {alert.message}
          </Alert>
        </Snackbar>
      </Portal>
      {isInitializing && <LinearProgress />}
      {!isInitializing && isEmpty && <EmptyList />}
      {!isEmpty && (
        <Paper elevation={0} sx={{ my: 2 }}>
          {dependents?.map((dependent, index) => {
            const status = getStatusFromDependent(dependent);

            return (
              <div key={dependent.id}>
                <Grid container py={2} px={3} role="row">
                  <Grid item xs>
                    <Grid container>
                      <Grid item xs={12} sm={8}>
                        <Typography
                          gutterBottom
                          variant="body3"
                          fontWeight={700}
                        >
                          {dependent.user.nome}
                        </Typography>
                        <Typography gutterBottom variant="body3">
                          {getKinshipLabelFromDependent(dependent)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm>
                        <Typography
                          gutterBottom
                          variant="body3"
                          fontWeight={700}
                          sx={{ display: { xs: 'none', sm: 'block' } }}
                        >
                          Status
                        </Typography>
                        <Chip label={status.label} variant={status.variant} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs="auto">
                    <DependentMenu dependent={dependent} />
                  </Grid>
                </Grid>
                {index < dependents?.length - 1 && <Divider />}
              </div>
            );
          })}
        </Paper>
      )}
    </>
  );
};

export default DependentsList;
