export class DbLessonProgress {
  id: string;
  user_id: number;
  course_id: string;
  lesson_id: string;
  progress: number;
  is_completed: boolean;
  created_at: string;
  updated_at: string;

  deleted_at?: string;
}

export class DbLesson {
  id: string;
  title: string;
  course_id: string;
  video: string;
  order: number;
  id_backend: number;
  type: 'video' | 'pdf';
  created_at: string;
  updated_at: string;

  deleted_at?: string;
  lessons_progresses?: DbLessonProgress[];
}

export class Lesson {
  id: string;
  title: string;
  video: string;
  progress: number;
  isCompleted: boolean;
  type: 'video' | 'pdf';

  static getFromDb(dbLesson: DbLesson): Lesson {
    const lesson = new Lesson();
    lesson.id = dbLesson.id;
    lesson.title = dbLesson.title;
    lesson.video = dbLesson.video;
    lesson.type = dbLesson.type;
    const lessonProgress =
      dbLesson.lessons_progresses && dbLesson.lessons_progresses[0];
    lesson.progress = lessonProgress?.progress || 0;
    lesson.isCompleted = lessonProgress?.is_completed || false;
    return lesson;
  }
}
