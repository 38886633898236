import * as React from 'react';
import Box from '@mui/material/Box';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import Fab from '@mui/material/Fab';
import Stack from '@mui/material/Stack';

export interface InlineEditInputProps {
  onAccept: (value: string) => void;
  onCancel?: () => void;
  value: string;
}

const Modes = {
  editing: 'editing',
  pending: 'pending',
  readOnly: 'readOnly',
} as const;

type ModeTypes = keyof typeof Modes;

export const InlineEditInput = React.forwardRef<
  HTMLInputElement,
  InlineEditInputProps & InputBaseProps & { children?: React.ReactNode }
>(({ children, onCancel, onChange, onAccept, value, ...props }, ref) => {
  const [mode, setMode] = React.useState<ModeTypes>(Modes.readOnly);
  const [internalValue, setInternalValue] = React.useState(value);

  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const wrapperRef = React.useRef<HTMLDivElement | null>(null);

  React.useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

  React.useEffect(() => {
    if (mode === Modes.readOnly) {
      setInternalValue(value);
    }
  }, [value]);

  React.useEffect(() => {
    if (mode === Modes.editing) {
      const handleClickOutside = (event) => {
        if (mode !== Modes.editing) return;

        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          handleCancel();
        }
      };
      window.addEventListener('mousedown', handleClickOutside);

      return () => {
        window.removeEventListener('mousedown', handleClickOutside);
      };
    }

    if (mode === Modes.readOnly) {
      setInternalValue(value);
    }
  }, [mode]);

  const handleEdit = () => {
    if (mode === Modes.pending) return inputRef.current.blur();

    setMode(Modes.editing);

    inputRef.current.focus();
  };

  const handleCancel = () => {
    if (mode !== Modes.editing) return;

    setMode(Modes.readOnly);
    inputRef.current.blur();

    onCancel && onCancel();
  };

  const handleAccept = async () => {
    if (mode !== Modes.editing) return;

    inputRef.current.blur();
    setMode(Modes.pending);
    await onAccept(internalValue);
    setMode(Modes.readOnly);
  };

  const handleKeyDown = (e) => {
    switch (e.key) {
      case 'Enter':
        e.preventDefault();
        if (mode === Modes.readOnly) handleEdit();
        if (mode === Modes.editing) handleAccept();
        break;
      case 'Escape':
      case 'Tab':
        e.stopPropagation();
        handleCancel();
        break;
      default:
        break;
    }
  };

  const displayValue = mode === Modes.readOnly ? value : internalValue;

  return (
    <Box
      ref={wrapperRef}
      sx={(theme) => ({
        position: 'relative',
        '&:hover': { backgroundColor: theme.palette.neutral[200] },
      })}
    >
      <InputBase
        {...props}
        className={''}
        fullWidth
        inputProps={{ className: props.className }}
        inputRef={inputRef}
        multiline
        onChange={(e) => {
          setInternalValue(e.target.value);
        }}
        onClick={handleEdit}
        onKeyDown={handleKeyDown}
        readOnly={mode !== Modes.editing}
        sx={(theme) => ({
          '& .MuiInputBase-input': {
            '&:focus': {
              boxShadow: `inset 0px 0px 0px 1px ${theme.palette.secondary.main}`,
            },
          },
        })}
        value={displayValue}
      />
      {mode === Modes.editing && (
        <Stack
          sx={{
            bottom: -22,
            position: 'absolute',
            right: 2,
          }}
          direction="row"
          spacing={1}
        >
          <Fab
            aria-label="accept changes"
            color="secondary"
            onClick={handleAccept}
            size="small"
          >
            <CheckRoundedIcon />
          </Fab>
          <Fab aria-label="cancel changes" onClick={handleCancel} size="small">
            <CloseRoundedIcon />
          </Fab>
        </Stack>
      )}
    </Box>
  );
});
