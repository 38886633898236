import * as React from 'react';
import { createPortal } from 'react-dom';

interface PrettyDebugProps {
  data: { [key: string]: any };
}

const MAX_WIDTH = 350;
const RIGHT_MARGIN = 25;
const TOP_MARGIN = 75;

const PrettyDebug: React.FC<PrettyDebugProps> = ({ data }) => {
  const sectionsClosedInitialState = Object.keys(data).reduce(
    (acc, key) => ({ ...acc, [key]: false }),
    {}
  );
  const [sectionClosed, setSectionClosed] = React.useState(
    sectionsClosedInitialState
  );
  const [isDragging, setIsDragging] = React.useState(false);
  const [position, setPosition] = React.useState({
    x: window.innerWidth - (MAX_WIDTH + RIGHT_MARGIN),
    y: TOP_MARGIN,
  });
  const dragRef: React.MutableRefObject<{
    startX: number;
    startY: number;
    initialX: number;
    initialY: number;
  } | null> = React.useRef(null);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setIsDragging(true);
    dragRef.current = {
      startX: e.clientX,
      startY: e.clientY,
      initialX: position.x,
      initialY: position.y,
    };
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!isDragging || !dragRef.current) return;
    const { startX, startY, initialX, initialY } = dragRef.current;
    const newX = initialX + e.clientX - startX;
    const newY = initialY + e.clientY - startY;
    setPosition({ x: newX, y: newY });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  React.useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  const renderDebugData = (json) => (
    <pre style={{ margin: 0 }}>{JSON.stringify(json, null, 2)}</pre>
  );

  const maxSectionHeight = (() => {
    const openedSections = Object.values(sectionClosed).filter(
      (closed) => !closed
    ).length;

    return `calc((100vh - ${position.y}px - 120px) / ${openedSections})`;
  })();

  return (
    <div
      style={{
        background: 'white',
        border: '1px solid #ccc',
        borderTop: 'none',
        fontSize: 12,
        left: position.x,
        lineHeight: '12px',
        position: 'fixed',
        top: position.y,
        width: MAX_WIDTH,
        zIndex: 99999999,
      }}
    >
      {Object.entries(data).map(([key, value], index) => {
        const isOpen = !sectionClosed[key];

        return (
          <div className="section" key={`${key.replace(/\s/g, '')}${index}`}>
            <div
              className="header"
              style={{
                backgroundColor: '#ebebeb',
                borderTop: '1px solid #ccc',
                cursor: 'grab',
                display: 'flex',
                justifyContent: 'space-between',
                padding: 5,
                position: 'relative',
                userSelect: 'none',
              }}
              onMouseDown={handleMouseDown}
            >
              <div
                className="title"
                style={{ fontSize: 14, lineHeight: '18px' }}
              >
                {key} ({typeof value})
              </div>
              <div className="actions">
                <div
                  style={{
                    alignContent: 'center',
                    border: '1px solid #ccc',
                    cursor: 'pointer',
                    display: 'flex',
                    fontFamily: 'monospace',
                    fontSize: 18,
                    justifyContent: 'center',
                    padding: '3px',
                  }}
                  onClick={() =>
                    setSectionClosed({
                      ...sectionClosed,
                      [key]: !sectionClosed[key],
                    })
                  }
                >
                  {isOpen ? '-' : '+'}
                </div>
              </div>
            </div>
            {isOpen && (
              <div
                className="content"
                style={{
                  borderTop: '1px solid #ccc',
                  maxHeight: maxSectionHeight,
                  overflow: 'auto',
                  padding: 10,
                }}
              >
                {renderDebugData(value)}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default function (props: PrettyDebugProps) {
  return createPortal(<PrettyDebug {...props} />, document.body);
}
