<component-banner
  *ngIf="bannerHeader"
  [header]="bannerHeader"
></component-banner>
<div class="nav-container">
  <div class="top-nav w-100">
    <a
      *ngFor="let navItem of navItems"
      class="top-nav-item"
      [ngClass]="{ active: menu == navItem.name }"
      (click)="navigate(navItem.route)"
    >
      <span> {{ navItem.label | translate }} </span>
    </a>
  </div>
</div>
