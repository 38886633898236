import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/browser';

import { restClient } from '@/react/utils/fetch';
import { useManageDependents } from './manage-dependents.context';
import SharedDialogContent, {
  formValidationSchema,
} from './add-or-edit-dialog-content.component';

const createInitialValues = (dependent) => ({
  name: dependent.user.nome,
  email: dependent.user.email,
  kinship_degree: dependent.kinship_degree,
});

const EditDependentDialog = ({ dependent, open, toggleOpen }) => {
  const [loading, setLoading] = React.useState(false);
  const { control, handleSubmit, reset } = useForm({
    defaultValues: createInitialValues(dependent),
    resolver: zodResolver(formValidationSchema),
  });
  const { dispatch } = useManageDependents();

  React.useEffect(() => {
    reset(createInitialValues(dependent));
  }, [dependent]);

  const handleUpdate = async (data) => {
    const payload = { ...data };

    setLoading(true);

    try {
      const updated = await restClient.post(
        `/dependents/${dependent.id}`,
        payload
      );
      dispatch({
        type: 'OPEN_ALERT',
        payload: {
          message: 'As informações do dependente foram atualizadas.',
          severity: 'success',
        },
      });
      dispatch({
        type: 'UPDATE_DEPENDENT',
        payload: updated.data.data,
      });
      handleClose();
    } catch (error) {
      dispatch({
        type: 'OPEN_ALERT',
        payload: {
          message: 'Erro ao alterar dependente.',
          severity: 'error',
        },
      });
      setLoading(false);
      Sentry.captureException(error);
    }

    setLoading(false);
  };

  const handleClose = () => {
    toggleOpen();
  };

  return (
    <Dialog
      onClose={handleClose}
      fullWidth
      open={open}
      TransitionProps={{
        onExited: () => {
          reset();
        },
      }}
    >
      <form onSubmit={handleSubmit(handleUpdate)}>
        <DialogTitle>Editar dependente</DialogTitle>
        <DialogContent>
          <SharedDialogContent formControl={control} />
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button onClick={handleClose} variant="contained" color="tertiary">
            Cancelar
          </Button>
          <LoadingButton
            color="primary"
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveAltIcon />}
            type="submit"
            variant="contained"
          >
            Salvar alterações
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditDependentDialog;
