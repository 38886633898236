import React from 'react';

const ManageEmployeesContext = React.createContext(null);

export function ManageEmployeesProvider({ children, employees }) {
  const initialState = {
    employees,
    alert: {
      open: false,
      message: '',
      severity: 'info',
      title: '',
    },
  };
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    dispatch({
      type: 'INIT',
      payload: initialState,
    });
  }, [JSON.stringify(initialState)]);

  const value = { state, dispatch };

  return (
    <ManageEmployeesContext.Provider value={value}>
      {children}
    </ManageEmployeesContext.Provider>
  );
}

export function useManageEmployees() {
  return React.useContext(ManageEmployeesContext);
}

function reducer(state, action) {
  switch (action.type) {
    case 'OPEN_ALERT': {
      const alert = {
        message: action.payload.message,
        open: true,
        severity: action.payload.severity,
        filled: 'filled',
      };

      if (action.payload.title) {
        alert.title = action.payload.title;
      }

      return {
        ...state,
        alert,
      };
    }
    case 'CLOSE_ALERT': {
      return { ...state, alert: { ...state.alert, open: false } };
    }
    case 'ADD_DEPENDENT': {
      const newData = [action.payload, ...state.employees];
      return { ...state, employees: newData };
    }
    case 'UPDATE_DEPENDENT': {
      const nextData = [...state.employees];
      const index = nextData.findIndex((d) => d.id == action.payload.id);

      nextData[index] = action.payload;

      return { ...state, employees: nextData };
    }
    case 'REMOVE_EMPLOYEE': {
      const nextData = [...state.employees];
      const index = nextData.findIndex((d) => d.id == action.payload.id);

      nextData.splice(index, 1);

      return { ...state, employees: nextData };
    }
    case 'INIT': {
      return { ...action.payload };
    }
  }
}
