import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import {
  Activity,
  ActivityStatuses,
  ActivityStatusLabel,
} from '@/react/data/idp/interfaces';
import { DropdownButton } from './dropdown-button';
import { IdpClient } from '@/react/data/idp/idp-client';
import * as Sentry from '@sentry/browser';
import CardActionArea from '@mui/material/CardActionArea';

export interface ActivityCardProps {
  activity: Activity;
  planId: string;
}

const statusOptions = Object.entries(ActivityStatusLabel).map(
  ([value, label]) => ({
    value,
    label,
  })
);

const ActivityCard = ({ planId, activity }: ActivityCardProps) => {
  const [activityStatus, setActivityStatus] = React.useState<ActivityStatuses>(
    activity.status
  );

  const handleChangeStatus = async (status: ActivityStatuses) => {
    try {
      await IdpClient.updateActivityStatus({
        activityId: activity.id,
        planId,
        status,
      });
      setActivityStatus(status);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <Grid item xs={12} height={'100%'}>
      <Card sx={{ overflow: 'visible', borderRadius: 0, height: '100%' }}>
        <CardContent>
          <CardActionArea
            href={`/pdi/planos/${activity.planId}/atividades/${activity.id}`}
          >
            <DropdownButton
              options={statusOptions}
              onOptionSelected={handleChangeStatus}
              selectedOption={ActivityStatusLabel[activityStatus]}
            />

            <Typography fontSize={16} variant="h5">
              {activity.title}
            </Typography>
            <Typography fontSize={14}>{activity.description}</Typography>
          </CardActionArea>

          {/*
        // TODO: Implement activity resource
        <Box paddingTop={2}>
          <Card>
            <CardContent>
              <Typography fontSize={14}>
                [Nome da instituição]
              </Typography>
              <Typography fontSize={16} variant="h5">[Nome do curso]</Typography>
            </CardContent>
          </Card>
        </Box> */}
        </CardContent>
      </Card>
    </Grid>
  );
};

export { ActivityCard };
