import * as React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/browser';
import { restClient } from '@/react/utils/fetch';
import { emailValidationSchema } from '../schemas';
import {
  toastSuccess,
  toastWarning,
  toastError,
} from '@/react/utils/custom-toast';

export function useEmployeeEdit({ employee, onClose, handleUpdateEmail }) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm({
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: { email: employee?.email },
    resolver: zodResolver(emailValidationSchema),
  });

  const [loading, setLoading] = React.useState(false);
  const [emailValue, setEmailValue] = React.useState('');
  const [openDialog, setOpenDialog] = React.useState('');

  React.useEffect(() => {
    setEmailValue(employee?.email);
  }, [employee?.email]);

  const handleChangeEmail = async () => {
    const { email } = getValues();

    if (email === employee?.email) {
      const messageToast = 'O novo email deve ser diferente do atual.';
      toastWarning({ messageToast });
      return;
    }
    const payload = { email };

    setLoading(true);

    try {
      const response = await restClient.post(
        `/employees/${employee?.id}/change-email`,
        payload
      );

      const messageToast =
        response?.data?.data?.message || 'Email alterado com sucesso.';

      toastSuccess({ messageToast, handleCallback: onCloseDialog() });
      handleUpdateEmail(getValues('email'));
    } catch (error) {
      const messageToast = error?.message ?? 'Erro ao alterar email!';

      toastError({ messageToast });
      setLoading(false);
      Sentry.captureException(error);
    }

    setLoading(false);
  };

  const onCloseDialog = () => {
    setValue('email', getValues('email'));
    setOpenDialog(false);
    onClose();
  };

  return {
    loading,
    control,
    errors,
    setValue,
    getValues,
    reset,
    openDialog,
    handleSubmit,
    onCloseDialog,
    handleChangeEmail,
    email: emailValue,
  };
}
