<div class="container">
  <div class="row my-6">
    <p>
      Tem dúvidas ou precisa de ajuda com progresso ou certificados?
      <a class="link" target="_blank" [href]="supportURL"
        >Fale com nosso time</a
      >
    </p>
  </div>
</div>
