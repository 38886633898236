import { LayoutService } from 'src/app/services/layout.service';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { UsersService } from 'src/app/services/users.service';
import { clampProgress, getAsset } from 'src/app/utils/helpers';
import { Aula } from 'src/app/models/aula.model';
import { downloadFile } from 'src/app/utils/download-file';
import { CorporateUniverstyRoutes as CorporateUniversityRoutes } from 'src/app/pages/corporate-university/corporate-university.routes';
import Player from 'src/app/libs/vimeo';
import { CursoHorario } from 'src/app/models/curso-horario.model';
import { RouterService } from 'src/app/services/router.service';
import { MediaService as LMSService } from 'src/app/services/media.service';
import { MediaService } from 'src/app/services/media/media.service';
import { CONST } from 'src/app/constants';

@Component({
  selector: 'app-video-aulas',
  templateUrl: './video-aulas.component.html',
  styleUrls: ['./video-aulas.component.scss'],
})
export class VideoAulasComponent implements OnInit, OnDestroy {
  getAsset = getAsset;

  aulas: Aula[];

  firstVideoLoaded: boolean = false;
  isLoading: boolean = false;
  npsModalHalf: boolean = false;

  selectedAula: Aula;

  @ViewChild('videoContainer')
  videoContainer: ElementRef<HTMLDivElement>;

  @ViewChild('videoElement')
  videoElement: ElementRef<HTMLVideoElement>;

  @ViewChild('bannerComponent')
  bannerComponent: ElementRef<HTMLVideoElement>;

  videoToken: string;

  cursoHorarioId: number;

  cursoCompleto: boolean = false;

  curso: CursoHorario;

  token: string;

  player: Player;

  constructor(
    private activatedRoute: ActivatedRoute,
    private routerService: RouterService,
    private mediaService: MediaService,
    private lmsService: LMSService,
    private userService: UsersService,
    private layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    if (this.userService.user.hasLMS()) {
      this.redirectToLmsCourse();
      return;
    }

    this.player = Player.getInstance();

    this.player.on('pause', ({ seconds, duration }) => {
      this.updateProgress(this.player.getProgress(), seconds === duration);
    });

    this.activatedRoute.params.subscribe((params) => {
      const { id } = params;
      this.cursoHorarioId = id;
      this.loadCursoAulas(id);
    });
  }

  async redirectToLmsCourse() {
    const { id } = this.activatedRoute.snapshot.params;
    try {
      const course = await this.lmsService.getCourseByBackendId(id);

      this.routerService.navigate([
        CorporateUniversityRoutes.CORPORATE_UNIVERSITY,
        CorporateUniversityRoutes.COURSES,
        course.id,
      ]);
    } catch (e) {
      this.routerService.home();
    }
  }

  ngOnDestroy(): void {
    if (this.player) {
      this.player.destroy();
    }
  }

  async updateProgress(percent: number, endVideo: boolean = false) {
    const lessonProgress = this.selectedAula.aula_alunos[0];

    const clampedPercent = clampProgress(percent);

    const progress = Math.min(
      Math.max(lessonProgress?.progresso || 0, Math.ceil(clampedPercent)),
      100
    );

    if (lessonProgress) {
      lessonProgress.progresso = progress;
      lessonProgress.is_completed = progress >= 95;
    }

    const newProgress = await this.mediaService.updateAulaProgresso(
      this.selectedAula.id,
      progress,
      this.videoToken,
      this.curso.id
    );

    if (!lessonProgress) {
      this.selectedAula.aula_alunos.push(newProgress);
    }

    if (percent >= 95 && endVideo) {
      this.onProximo(true);
      await this.checkCompletedCourse();
    }
  }

  async loadCursoAulas(id: number) {
    try {
      this.curso = await this.mediaService.getAulasByCursoId(id);

      this.layoutService.updateBreadCrumb([
        {
          label: 'Home',
          route: ['/'],
        },
        {
          label: this.curso.curso_detalhe.curso.curso,
          route: ['eduplay', this.curso.id.toString()],
        },
        {
          label: 'Curso',
        },
      ]);

      this.aulas = this.curso.aulas;

      if (this.curso.curso_alunos[0]?.is_completed) {
        this.cursoCompleto = true;
      }

      this.changeAula(
        this.aulas.find(
          (_aula) => _aula.id === this.curso.curso_alunos[0]?.aula_atual
        ) || this.aulas[0]
      );
    } catch (e) {
      this.routerService.home();
    }
  }

  async checkCompletedCourse() {
    let completedLessons = 0;

    this.aulas.forEach((aula) => {
      const progressedLesson = aula.aula_alunos[0];

      if (progressedLesson && progressedLesson.is_completed) {
        completedLessons += 1;
      }
    });

    const progress = Math.ceil((completedLessons / this.aulas.length) * 100);

    const previousProgress = this.curso.curso_alunos[0].progresso;

    this.curso.curso_alunos[0].progresso = progress;
    this.curso.curso_alunos[0].is_completed = progress === 100;

    this.cursoCompleto = progress === 100;

    if (this.cursoCompleto && previousProgress < 100) {
      this.showCertificado();
    } else if (progress >= 50 && !this.curso.curso_alunos[0].nps_half) {
      this.curso.curso_alunos[0].nps_half = true;
      this.npsModalHalf = true;
    }
  }

  getVideoContainerHeight() {
    return `${this.videoContainer?.nativeElement.offsetHeight}px` || 'auto';
  }

  async changeAula(aula: Aula, autoPlay: boolean = false) {
    if (this.selectedAula !== aula && !this.isLoading) {
      this.selectedAula = aula;
      this.isLoading = true;

      await this.mediaService.updateAulaAtual(this.cursoHorarioId, aula.id);

      const vimeoUrl = await this.mediaService.getVimeoURL(aula.id);

      await this.player.loadVideo(this.videoContainer.nativeElement, vimeoUrl);

      if (autoPlay) {
        this.player.play();
      }

      this.isLoading = false;
    }
  }

  downloadContents() {
    downloadFile(
      `${CONST.API_ENDPOINT}/cursos/${this.curso.id}/get-files`,
      `Material complementar - ${this.curso.curso_detalhe.curso.curso}`
    );
  }

  onProximo(autoplay: boolean = false) {
    if (!this.isLastAula()) {
      const aulaIndex = this.aulas.findIndex(
        (_aula) => _aula.id === this.selectedAula.id
      );

      this.changeAula(this.aulas[aulaIndex + 1], autoplay);
    }
  }

  onAnterior() {
    if (!this.isFirstAula()) {
      const aulaIndex = this.aulas.findIndex(
        (_aula) => _aula.id === this.selectedAula.id
      );
      this.changeAula(this.aulas[aulaIndex - 1]);
    }
  }

  isLastAula() {
    return this.selectedAula === this.aulas[this.aulas.length - 1];
  }

  isFirstAula() {
    return this.selectedAula === this.aulas[0];
  }

  getProgresso(aula: Aula) {
    return aula.aula_alunos.length > 0
      ? Math.floor(aula.aula_alunos[0].progresso)
      : 0;
  }

  showCertificado() {
    this.routerService.navigate([
      'eduplay',
      this.cursoHorarioId,
      'conclusao_curso',
    ]);
  }

  getCourseName() {
    return this.curso?.curso_detalhe.curso.curso || '';
  }

  getHeight() {
    if (this.videoContainer) {
      return `${
        (200 / 355.56) * this.videoContainer.nativeElement.clientWidth
      }px`;
    }

    return '200px';
  }

  getTranslation(key: string): string {
    return `EDUPLAY.VIDEO_AULAS.${key}`;
  }

  async onCloseNpsHalf() {
    this.npsModalHalf = false;
    try {
      await this.mediaService.npsHalf(this.cursoHorarioId);
    } catch (e) {
      console.error('Error on update modal status', e);
    }
  }
}
