import { Component, OnInit } from '@angular/core';
import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { SelectOption } from '@/components/form/select/select.component';
import { CourseProgress } from '@/models-new/media/course-progress';
import { LayoutService } from '@/services/layout.service';
import { MediaService } from '@/services/media.service';
import { TablePage } from '@/shared/table-default/table-default.component';
import { TranslatedComponent } from '@/utils/translated.component';
import { extractDuration, formatDate } from '@/utils/date-parser';
import { CSVHandler } from '@/utils/csv.handler';

/* Select para o tipo de filtro */

export const statusOptions: SelectOption[] = [
  {
    value: 0,
    label: 'Selecione aqui',
  },
  {
    value: 1,
    label: 'Nome do curso',
  },
  {
    value: 2,
    label: 'Nome do colaborador',
  },
  {
    value: 3,
    label: 'Data de conclusão',
  },
];

export const statusMap = {
  COURSE: 'Nome do curso',
  COLAB: 'Nome do colaborador',
  DATE: 'Data de conclusão',
};

@Component({
  selector: 'app-lms-report',
  templateUrl: './lms-report.component.html',
  styleUrls: ['./lms-report.component.scss'],
})
export class LmsReportComponent extends TranslatedComponent implements OnInit {
  statusOptions: SelectOption[] = statusOptions;

  statusFilter: SelectOption = this.statusOptions[0];

  colaboradorFilter: string = '';
  courseFilter: string = '';
  dateFilters: { from: string; to: string };

  translationKey: string = 'EMPRESA.RELATORIOS.LMS';

  onFilterSelected(option: SelectOption) {
    this.statusFilter = option;
  }

  tablePages: TablePage[] = [
    {
      columns: [
        {
          label: 'Nome do colaborador',
          getValue: (item: CourseProgress) => item.userName,
          type: 'text',
        },
        {
          label: 'CPF',
          getValue: (item: CourseProgress) => item.userCpf || '-',
          type: 'text',
        },
        {
          label: 'Email',
          getValue: (item: CourseProgress) => item.userEmail || '-',
          type: 'text',
        },
        {
          label: 'Nome do curso',
          getValue: (item: CourseProgress) => item.courseName,
          type: 'text',
        },
        {
          label: 'Progresso',
          getValue: (item: CourseProgress) => `${item.progress}%`,
          type: 'text',
        },
        {
          label: 'Data de início',
          getValue: (item: CourseProgress) => formatDate(item.startDate),
          type: 'text',
        },
        {
          label: 'Data de conclusão',
          getValue: (item: CourseProgress) =>
            item.endDate ? formatDate(item.endDate) : '-',
          type: 'text',
        },
        {
          label: 'Carga horária',
          getValue: (item: CourseProgress) => extractDuration(item.duration),
          type: 'text',
        },
      ],
    },
  ];

  corporateCourses: CourseProgress[] = [];
  displayCorporateCourses: CourseProgress[] = [];

  constructor(
    private ngbDateFormatter: NgbDateParserFormatter,
    private mediaService: MediaService,
    private csvHandler: CSVHandler,
    private layoutService: LayoutService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadCourses();
  }

  onStatusSelected(option: any) {
    this.onLimpar();
    this.statusFilter = option;
  }

  async loadCourses() {
    this.corporateCourses = await this.mediaService.getLMSReport();
    this.displayCorporateCourses = this.corporateCourses;
  }

  onDateSelected(date: { fromDate: NgbDate; toDate: NgbDate }) {
    this.dateFilters = {
      from: this.ngbDateFormatter.format(date.fromDate),
      to: this.ngbDateFormatter.format(date.toDate),
    };
  }

  exportData() {
    this.csvHandler.generate(this.displayCorporateCourses, {
      collumns: [
        {
          header: 'Nome do colaborador',
          getValue(item: CourseProgress) {
            return item.userName;
          },
        },
        {
          header: 'CPF',
          getValue(item: CourseProgress) {
            return item.userCpf || '-';
          },
        },
        {
          header: 'E-mail',
          getValue(item: CourseProgress) {
            return item.userEmail || '-';
          },
        },

        {
          header: 'Nome do curso',
          getValue(item: CourseProgress) {
            return item.courseName;
          },
        },
        {
          header: 'Progresso',
          getValue(item: CourseProgress) {
            return `${item.progress}%`;
          },
        },
        {
          header: 'Data de início',
          getValue(item: CourseProgress) {
            return formatDate(item.startDate);
          },
        },
        {
          header: 'Data de conclusão',
          getValue(item: CourseProgress) {
            return item.endDate ? formatDate(item.endDate) : '-';
          },
        },
        {
          header: 'Carga horária',
          getValue(item: CourseProgress) {
            return extractDuration(item.duration);
          },
        },
      ],
    });
  }

  calculateHours(corporateCourses: CourseProgress[]): string {
    return corporateCourses
      .reduce((previousValue: number, course: CourseProgress) => {
        const splitedDuration = course.duration.split(':');

        const hours = parseInt(splitedDuration[0]);
        const minutes = parseInt(splitedDuration[1]) / 60;
        const seconds = parseInt(splitedDuration[2]) / (60 * 60);

        return (
          previousValue + (hours + minutes + seconds) * (course.progress / 100)
        );
      }, 0)
      .toFixed(2);
  }

  completedFilter(corporateCourse: CourseProgress) {
    return !!corporateCourse.endDate;
  }

  sumTotalHours(): string {
    return this.calculateHours(this.displayCorporateCourses);
  }

  sumCompletedHours(): string {
    return this.calculateHours(
      this.displayCorporateCourses.filter(this.completedFilter)
    );
  }

  sumTotalSubscriptions(): number {
    return this.displayCorporateCourses.length;
  }

  sumCompletedSubscriptions(): number {
    return this.displayCorporateCourses.filter(this.completedFilter).length;
  }

  onBuscar() {
    const filters = [
      () => {
        return this.corporateCourses;
      },
      () => {
        return this.corporateCourses.filter(
          (corporateCourse) =>
            corporateCourse.courseName
              .toLocaleLowerCase()
              .search(this.courseFilter.toLocaleLowerCase()) > -1
        );
      },
      () => {
        return this.corporateCourses.filter(
          (corporateCourse) =>
            corporateCourse.userName
              .toLocaleLowerCase()
              .search(this.colaboradorFilter.toLocaleLowerCase()) > -1
        );
      },
      () => {
        if (!this.dateFilters.from || !this.dateFilters.to) {
          return this.displayCorporateCourses;
        }

        return this.corporateCourses.filter((corporateCourse) => {
          if (!corporateCourse.endDate) {
            return false;
          }

          const from = new Date(this.dateFilters.from);
          const to = new Date(this.dateFilters.to);
          const courseEndDate = new Date(corporateCourse.endDate);

          return courseEndDate < to && courseEndDate > from;
        });
      },
    ];
    this.displayCorporateCourses = filters[this.statusFilter.value]();
  }

  onLimpar() {
    this.colaboradorFilter = '';
    this.courseFilter = '';
    this.dateFilters = undefined;

    this.statusFilter = this.statusOptions[0];
    this.displayCorporateCourses = this.corporateCourses;
  }
}
