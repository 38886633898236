import { Component } from '@angular/core';
import type { ComponentProps } from 'react';

import ReactManageRefunds from '@/react/components/refund/manage-refunds.component';

@Component({
  selector: 'component-refounds',
  templateUrl: './manage-refunds.component.html',
})
export class ManageRefundsComponent {
  ManageRefundsPageComponent = ReactManageRefunds;
  reactManageRefundsProps: ComponentProps<typeof ReactManageRefunds> = {};
}
