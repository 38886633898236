import { Box, Button, Grid, Slide, styled } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import logoGalenaEdupass from '@/react/assets/galena.svg';

import { EmailInput } from './email-input.component';
import { PasswordInput } from './password-input.component';
import { GtmContext } from '../state/context';

const loginBg = 'https://api.edupass.io/api/contents/assets/new_login_bg.png';

enum LoginSteps {
  email = 'Email',
  password = 'Password',
}

const StyledGrid = styled(Grid)(() => ({
  minHeight: '100vh',
  backgroundColor: '#FFFFFF',
}));

const StyledImageSection = styled('div')(() => ({
  backgroundImage: `url('${loginBg}')`,
  width: '100%',
  height: '100%',
  backgroundSize: 'cover',
}));

const StyledLogo = styled('img')(({ theme }) => ({
  width: '157px',
}));

const BackButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  left: theme.spacing(2),
}));

const LoginComponentReact = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [transitionStep, setTransitionStep] = useState<LoginSteps>(
    LoginSteps.email
  );
  const [loginStep, setLoginStep] = useState<LoginSteps>(LoginSteps.email);
  const [validEmail, setValidEmail] = useState<string>('');

  const containerRef = useRef<HTMLElement>(null);

  const onPasswordStep = (email: string) => {
    setValidEmail(email);
    setLoginStep(LoginSteps.password);
    setTransitionStep(null);
  };

  const onCompletedTransition = () => {
    setTransitionStep(loginStep);
  };

  const handleBackToEmail = () => {
    setLoginStep(LoginSteps.email);
    setTransitionStep(null);
  };

  return (
    <GtmContext>
      <StyledGrid container>
        <Grid item xs={12} md={6} justifyContent={'center'} display={'flex'}>
          <Box
            component={Grid}
            container
            width={'100%'}
            maxWidth={'384px'}
            direction={'column'}
            rowGap={4}
            justifyContent={'center'}
            ref={containerRef}
            sx={{
              marginX: isMobile ? '24px' : 0,
              marginY: 0,
            }}
          >
            <StyledLogo src={logoGalenaEdupass} />

            <Slide
              direction="right"
              in={transitionStep === LoginSteps.email}
              appear={false}
              mountOnEnter
              unmountOnExit
              container={containerRef.current}
              onExited={onCompletedTransition}
            >
              <Box container component={Grid} rowGap={4} direction={'column'}>
                <EmailInput nextStep={onPasswordStep} />
              </Box>
            </Slide>

            <Slide
              direction="left"
              in={transitionStep === LoginSteps.password}
              mountOnEnter
              unmountOnExit
              container={containerRef.current}
              onExited={onCompletedTransition}
            >
              <Box container component={Grid} rowGap={4} direction={'column'}>
                <PasswordInput login={validEmail} />
              </Box>
            </Slide>
          </Box>
        </Grid>
        <Grid item md={6} display={{ xs: 'none', md: 'flex' }}>
          <StyledImageSection />
        </Grid>
        {loginStep === LoginSteps.password && (
          <BackButton
            variant="outlined"
            onClick={handleBackToEmail}
            sx={{
              border: 'none',
              paddingLeft: 0,
              '&:hover': { backgroundColor: 'transparent', border: 'none' },
            }}
          >
            <ArrowBackIcon sx={{ fontSize: 'small' }} /> voltar
          </BackButton>
        )}
      </StyledGrid>
    </GtmContext>
  );
};

export { LoginComponentReact };
