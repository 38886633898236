import palette from '../palette';

const MuiFormLabel = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...{
        ...theme.typography.body3,
        color: palette.neutral[800],
        fontWeight: theme.typography.fontWeightBold,
      },
    }),
  },
};

export default MuiFormLabel;
