import CONST from '@/constants';
import { Component } from '@angular/core';

@Component({
  selector: 'app-certificate-help',
  templateUrl: './certificate-help.component.html',
  styleUrls: ['./certificate-help.component.scss'],
})
export class CertificateHelpComponent {
  supportURL: string = CONST.SUPPORT_URL;
}
