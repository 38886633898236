// Embla doesn't have a way to set the slide width directly (via props),
// so we have to do it with CSS.
const baseRecommendationCarouselStype = ({ theme }) => ({
  '& .embla__container': {
    marginLeft: `calc(${theme.spacing(4)} * -1)`,

    [theme.breakpoints.between('xs', 'sm')]: {
      marginLeft: `calc(${theme.spacing(2)} * -1)`,
    },
  },

  '& .embla__slide': {
    flex: '0 0 85%',
    paddingLeft: theme.spacing(4),

    [theme.breakpoints.between('xs', 'sm')]: {
      flex: '0 0 80%',
      paddingLeft: theme.spacing(2),
    },
    [theme.breakpoints.between('sm', 'md')]: {
      flex: '0 0 66%',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      flex: '0 0 37%',
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      flex: '0 0 29%',
    },
    [theme.breakpoints.up('xl')]: {
      flex: '0 0 22%',
    },
  },
});

export default baseRecommendationCarouselStype;
