import * as React from 'react';

import { buildStateFromSchema, buildStateActions } from './state';
import { FiltersSchema, State } from './interfaces';

export const Context = React.createContext(null);

export function FiltersContext({
  initialValues,
  schema,
  setSchema,
  children,
}: {
  initialValues?: Record<string, string[]>;
  schema: FiltersSchema;
  setSchema: React.Dispatch<React.SetStateAction<FiltersSchema>>;
  children: React.ReactNode;
}) {
  if (schema === undefined || !(schema instanceof Array)) {
    throw new Error('FiltersContext requires a schema (even if empty)');
  }

  const { state } = buildStateFromSchema({
    schema,
    initialValues,
  });

  const [filtersState, setFiltersState] = React.useState<State>(state);

  React.useEffect(() => {
    const { state: newState } = buildStateFromSchema({
      schema,
      initialValues,
    });

    setFiltersState(newState);
  }, [schema]);

  const actions = buildStateActions([filtersState, setFiltersState, setSchema]);

  return (
    <Context.Provider value={[actions, filtersState, schema]}>
      {children}
    </Context.Provider>
  );
}
