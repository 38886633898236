import { User } from 'src/app/models/user.model';
import { RefundType } from 'src/app/models/refund-type';
import { RefundReceipt } from './refund-receipt';
import { RefundApproval } from './refund-approval';
import { SelectOption } from '@/components/form/select/select.component';

export enum RefundStatus {
  COURSE_PENDING = 'COURSE_PENDING',
  COURSE_APPROVED = 'COURSE_APPROVED',
  REFUND_PENDING = 'REFUND_PENDING',
  REFUND_APPROVED = 'REFUND_APPROVED',
  COURSE_REPROVED = 'COURSE_REPROVED',
  REFUND_REPROVED = 'REFUND_REPROVED',
  CANCELLED = 'CANCELLED',
}

export const statusMap = {
  [RefundStatus.COURSE_PENDING]: 'labelPendente',
  [RefundStatus.COURSE_APPROVED]: 'labelAprovado',
  [RefundStatus.REFUND_PENDING]: 'labelRefundPending',
  [RefundStatus.REFUND_APPROVED]: 'labelRefundApproved',
  [RefundStatus.COURSE_REPROVED]: 'labelCourseReproved',
  [RefundStatus.REFUND_REPROVED]: 'labelRefundReproved',
  [RefundStatus.CANCELLED]: 'labelCancelado',
};

export const statusOptions: SelectOption[] = [
  {
    value: undefined,
    label: 'REFUND_STATUS.labelTodos',
  },

  ...Object.entries(statusMap).map(([key, value]) => ({
    value: key,
    label: `REFUND_STATUS.${value}`,
  })),
];
export class Refund {
  approved_by_financial: boolean;
  approved_by_hr: boolean;
  approved_by_manager: boolean;
  course_name: string;
  created_at: string;
  employee_department: string;
  full_refund_amount: number;
  refund_usage: number;
  cancel_value: number;
  id: number;
  installments_number: number;
  institution_name: string;
  is_canceled: boolean;
  is_cash: boolean;
  is_edupass_enrollment: boolean;
  is_installments: boolean;
  manager_email: string;
  reason?: string;
  refund_approvals: RefundApproval[];
  refund_percentage: number;
  refund_receipts: RefundReceipt[];
  refund_type?: RefundType;
  refund_type_id?: number;
  status: RefundStatus;
  total_course_value: number;
  user: User;
  user_id: number;
  bank_ag?: string;
  bank_cc?: string;
  bank_name?: string;
  bank_num?: string;
  name?: string;
  document?: string;
  pix_key?: string;
  payment_method?: string;
  approval_date: string;
}
