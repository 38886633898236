import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'component-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
  @Input()
  header: string;

  @Input()
  image: string;

  @Input()
  bgColor: string = '#fefefe';

  constructor() {}

  ngOnInit() {}

  getBG() {
    if (this.image) {
      return { 'background-image': 'url(' + this.image + ')', height: '200px' };
    } else {
      return { 'background-color': this.bgColor };
    }
  }
}
