import React, { useCallback, useEffect, useState } from 'react';
import { alpha, styled } from '@mui/material/styles';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';

const Styled = {
  NavigationButton: styled(IconButton)`
    &:hover {
      background-color: ${({ theme }) =>
        alpha(theme.palette.neutral[700], 0.4)};
    }
    background-color: ${({ theme }) => alpha(theme.palette.neutral[700], 0.3)};
  `,
};

export const useNavigation = (emblaApi) => {
  const [prevButtonDisabled, setPrevButtontnDisabled] = useState(true);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback((emblaApi) => {
    if (!emblaApi) return;

    setPrevButtontnDisabled(!emblaApi.canScrollPrev());
    setNextButtonDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on('reInit', onSelect);
    emblaApi.on('select', onSelect);
  }, [emblaApi, onSelect]);

  return {
    prevButtonDisabled,
    nextButtonDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  };
};

const classesFromProps = (props) => {
  const classes = [];
  if (props.disabled) {
    classes.push('embla__navigation-button--disabled');
  }

  return classes.join(' ');
};

export const PrevButton = (props) => {
  const { children, ...restProps } = props;

  return (
    <div
      className={`embla__navigation-button embla__navigation-prev-button ${classesFromProps(
        restProps
      )}`}
    >
      <Styled.NavigationButton {...restProps}>
        <ChevronLeft sx={{ color: (theme) => theme.palette.neutral[50] }} />
      </Styled.NavigationButton>
    </div>
  );
};

export const NextButton = (props) => {
  const { children, ...restProps } = props;

  return (
    <div
      className={`embla__navigation-button embla__navigation-next-button ${classesFromProps(
        restProps
      )}`}
    >
      <Styled.NavigationButton {...restProps}>
        <ChevronRight sx={{ color: (theme) => theme.palette.neutral[50] }} />
      </Styled.NavigationButton>
    </div>
  );
};
