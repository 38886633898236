<component-modal>
  <div class="d-flex flex-column mt-2 px-6">
    <p class="Subtitle-2 d-flex justify-content-center mx-6">
      {{ getTranslation('education') | translate }}
    </p>
    <p class="Subtitle-2 d-flex justify-content-center">
      {{ getTranslation('name') | translate }}
    </p>
    <div class="d-flex justify-content-center">
      <img class="mt-4" [src]="getSvg('sso_user_name.svg')" />
    </div>
    <div class="BodyText-1 d-flex justify-content-center text-center mt-4 row">
      {{ getTranslation('called') | translate }}
    </div>

    <div class="mx-6 mt-4">
      <component-input
        placeholder="Por favor insira o seu nome aqui"
        [(ngModel)]="userName"
      ></component-input>
    </div>
  </div>
  <div class="d-flex justify-content-center px-3 pt-6">
    <component-botao
      (onClick)="onClose()"
      class="mt-1 mr-4 mb-4"
      type="secondary"
    >
      Agora não
    </component-botao>
    <component-botao
      class="mt-1 ml-1 mb-4"
      type="primary"
      [disabled]="!userName"
      (onClick)="onSend()"
    >
      Confirmar
    </component-botao>
  </div>
</component-modal>
