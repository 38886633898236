import React from 'react';

import {
  State,
  StateActions,
} from '@/react/components/refund/accordion/state/interfaces';

export const Context = React.createContext(null);

export function AccordionProvider({
  children,
  initialState,
}: {
  children: React.ReactNode;
  initialState: State;
}) {
  React.useEffect(() => {
    dispatch({
      type: 'INIT',
      payload: initialState,
    });
  }, [JSON.stringify(initialState)]);

  const [state, dispatch] = React.useReducer(reducer, initialState);

  const actions = {
    close: (accordionId) => {
      dispatch({
        type: 'CLOSE',
        payload: accordionId,
      });
    },
    open: (accordionId) => {
      dispatch({
        type: 'OPEN',
        payload: accordionId,
      });
    },
  } as StateActions;

  const value = [state, actions];

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

function reducer(state: State, action): State {
  switch (action.type) {
    case 'CLOSE': {
      const step = {
        ...state.steps[action.payload],
        expanded: false,
      };

      return {
        ...state,
        steps: {
          ...state.steps,
          [action.payload]: step,
        },
      };
    }
    case 'INIT': {
      return { ...action.payload };
    }
    case 'OPEN': {
      const step = {
        ...state.steps[action.payload],
        expanded: true,
      };

      return {
        ...state,
        steps: {
          ...state.steps,
          [action.payload]: step,
        },
      };
    }
  }
}
