import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Controller } from 'react-hook-form';
import * as z from 'zod';

import { dependentTypes } from '@/react/utils/dependent.utils';

export const formValidationSchema = z.object({
  name: z.string().nonempty(),
  email: z.string().nonempty().email(),
  kinship_degree: z.string().nonempty(),
});

const AddOrEditDialogContent = ({ formControl }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormLabel>Nome Completo*</FormLabel>
        <Controller
          control={formControl}
          name="name"
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              fullWidth
              label="Nome completo do dependente"
              variant="outlined"
              helperText={error?.message || ' '}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <FormLabel>E-mail*</FormLabel>
        <Controller
          control={formControl}
          name="email"
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              fullWidth
              label="E-mail do dependente"
              variant="outlined"
              helperText={error?.message || ' '}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={formControl}
          name="kinship_degree"
          render={({ field, fieldState: { error } }) => (
            <FormControl error={!!error}>
              <FormLabel>Vínculo/Parentesco*</FormLabel>
              <RadioGroup {...field} row={!isSmallScreen}>
                {Object.entries(dependentTypes).map(([key, value]) => (
                  <FormControlLabel
                    control={<Radio size="small" />}
                    key={key}
                    label={<Typography variant="body2">{value}</Typography>}
                    size="small"
                    value={key}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          )}
          rules={{ required: true }}
        />
      </Grid>
    </Grid>
  );
};

export default AddOrEditDialogContent;
