import Stack from '@mui/material/Stack';
import React from 'react';
import { QuestionWrapper } from './question-wrapper';
import { uniq } from 'powership';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';

const Styled = {
  SelectedChip: styled(Chip)(({ theme }) => ({
    color: theme.palette.primary[700],
    borderColor: theme.palette.primary[700],
    backgroundColor: theme.palette.primary[100],
    '&&:hover': {
      backgroundColor: theme.palette.primary[100],
    },
    '& .MuiChip-deleteIcon *': {
      color: theme.palette.primary[700],
    },
  })),
  SelectionHelper: styled(Stack)`
    flex-direction: row;
    justify-content: space-between;
    top: -20px;
    height: 20px;
    margin-bottom: 10px;
    background: white;
    display: flex;
    width: 100%;
  `,
  Content: styled(Stack)`
    overflow: hidden;
    overflow-y: auto;
  `,
};

export function QuestionTypeTagSelection() {
  return (
    <QuestionWrapper
      kind={'tag_selection'}
      render={({
        current,
        props: {
          options,
          validation: { maxSelectedCount },
        },
        setValue,
        value: selected,
      }) => {
        return (
          <>
            <Styled.SelectionHelper>
              <Typography variant="body2">
                Selecione até {maxSelectedCount} opções.
              </Typography>
              <Typography variant="body2">
                {selected.length}/{maxSelectedCount}
              </Typography>
            </Styled.SelectionHelper>

            <Styled.Content
              direction="row"
              flexWrap={'wrap'}
              spacing={2}
              useFlexGap
              width={'100%'}
              maxHeight={{ xs: '315px', md: '348px' }}
            >
              {options.map((option) => {
                const checked = selected.includes(option.value);

                const onClick = () => {
                  const maxSelectionReached =
                    selected.length >= maxSelectedCount;

                  if (maxSelectionReached && !checked) return;

                  const oldValues = current().draftState.value;
                  const prevChecked = oldValues.includes(option.value);

                  const next = prevChecked
                    ? oldValues.filter((el) => el !== option.value)
                    : uniq([...oldValues, option.value]);

                  setValue(next);
                };

                const StyledChip = checked ? Styled.SelectedChip : Chip;

                return (
                  <StyledChip
                    key={option.label}
                    label={option.label}
                    onClick={onClick}
                    variant={'outlined'}
                    onDelete={checked ? onClick : null}
                    deleteIcon={checked ? <CancelIcon color="primary" /> : null}
                  />
                );
              })}
            </Styled.Content>
          </>
        );
      }}
    />
  );
}
