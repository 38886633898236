import * as React from 'react';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

const StepActions = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <Divider sx={{ my: 3 }} />
      <Stack
        direction="row"
        justifyContent={{ xs: 'space-between', sm: 'right' }}
        spacing={2}
      >
        {children}
      </Stack>
    </>
  );
};

export default StepActions;
