import { z } from 'zod';
import {
  Button,
  Grid,
  InputLabel,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { ErrorOutline, CheckCircleOutline } from '@mui/icons-material';
import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm, Controller, type SubmitHandler } from 'react-hook-form';

import { constants } from '@/react/constants';
import { zodResolver } from '@hookform/resolvers/zod';
import { restClient } from '@/react/utils/fetch';

const loginValidationSchema = z.object({
  login: z.string().email('Email inválido'),
});

type LoginValidationData = z.infer<typeof loginValidationSchema>;

export type AccessDataProps = {
  nextStep: (login: string) => void;
};

const resetPassword = async ({ login }) => {
  const postData = { access: login };

  const response = await restClient.post(
    `${constants.API_ENDPOINT}/auth/login/forgot-password`,
    postData
  );

  return response?.data;
};

export const AccessData = () => {
  const [loading, setLoading] = React.useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState(null);
  const [isFocused, setIsFocused] = React.useState(false);

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      login: '',
    },
    resolver: zodResolver(loginValidationSchema),
  });

  const onSubmit: SubmitHandler<LoginValidationData> = (event) => {
    setLoading(true);
    resetPassword({ login: event.login }).then(
      (response) => {
        setTimeout(() => {
          setIsSuccess(true);
          setMessageSuccess(response.data.message);
          setLoading(false);
        }, 1000);
      },
      (error) => {
        setError('login', {
          type: 'server',
          message: error.response.data.error.message,
        });
        setIsSuccess(false);
        setLoading(false);
      }
    );
  };

  const handleGoToPageLogin = () => {
    window.location.assign('');
  };

  return (
    <>
      <Typography variant="h5">Esqueceu sua senha?</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid item>
          <InputLabel htmlFor="login">
            <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
              Vamos enviar um link para você criar uma nova senha.
            </Typography>
          </InputLabel>

          <Grid container columnGap={2}>
            <Grid
              item
              xs={12}
              md={10}
              sx={{ marginTop: '16px', marginBottom: '16px' }}
            >
              <Controller
                name="login"
                control={control}
                render={({ field }) => (
                  <TextField
                    placeholder="Digite seu email"
                    variant="outlined"
                    error={Boolean(errors.login)}
                    color={
                      isFocused && isSuccess && messageSuccess
                        ? 'success'
                        : undefined
                    }
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    fullWidth
                    helperText={
                      errors?.login?.message ?? (isSuccess && messageSuccess)
                    }
                    sx={{
                      '& .MuiFormHelperText-root': {
                        color: isSuccess && messageSuccess ? 'green' : 'red',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {Boolean(errors.login) ? (
                            <IconButton edge="end">
                              <ErrorOutline color="error" />
                            </IconButton>
                          ) : isSuccess && messageSuccess ? (
                            <IconButton edge="end">
                              <CheckCircleOutline color="success" />
                            </IconButton>
                          ) : null}
                        </InputAdornment>
                      ),
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>

            {isSuccess && (
              <Grid item xs={12} md={10}>
                <Button
                  size="large"
                  sx={{ height: '100%', width: '100%' }}
                  variant="contained"
                  style={{
                    marginRight: '10px',
                  }}
                  onClick={handleGoToPageLogin}
                >
                  Voltar para login
                </Button>
              </Grid>
            )}

            {!isSuccess && (
              <Grid item xs={12} md={10}>
                <LoadingButton
                  size="large"
                  sx={{ height: '100%', width: '100%', textAlign: 'center' }}
                  loading={loading}
                  loadingPosition="start"
                  startIcon={loading && <CircularProgress size={24} />}
                  type="submit"
                  variant="contained"
                >
                  Enviar
                </LoadingButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </form>
    </>
  );
};
