import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { getSvg, getAsset } from 'src/app/utils/helpers';
@Component({
  selector: 'component-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Input()
  closeButton: boolean = false;

  @Output()
  fechar: EventEmitter<undefined> = new EventEmitter();

  @Input()
  sidebarHighlight: boolean;

  @Input()
  navbarHighlight: boolean;

  constructor() {}

  getSvg = getSvg;

  getAsset = getAsset;

  firstArrow: boolean;

  ngOnInit(): void {}

  onFechar() {
    this.fechar.emit();
  }
}
