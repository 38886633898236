import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ExternalRefundForm from './external-refund-form/external-refund-form.component';
import CatalogRefundForm from './catalog-refund-form/catalog-refund-form.component';
import RefundSummary from './refund-summary/refund-summary.component';
import { ResourceTypes } from '@/react/components/refund/refund-request/interfaces';
import { WizardStepIds } from '@/react/components/refund/refund-request/description-step/interfaces';
import { WizardSteps } from '@/react/components/refund/wizard/interfaces';
import { WizardProvider } from '@/react/components/refund/wizard/state/context';
import Wizard from '@/react/components/refund/wizard/wizard.component';
import { State as WizardState } from '@/react/components/refund/wizard/state/interfaces';
import { useWizard } from '@/react/components/refund/wizard/state/useWizard';
import { useRefundRequest } from '@/react/components/refund/refund-request/state/useRefundRequest';
import { EligibleResourceType, ResourceApproval } from '@/react/data/subsidy/interfaces';
import ItemSourceForm from './item-source-form/item-source-form';
import { ResourceCourseSources } from '@/react/components/refund/refund-request/interfaces';
import ResourceTypeForm from './resource-type-form/resource-type-form.component';

const Title = ({ children }) => {
  return (
    <Box>
      <Typography variant="h6" color="primary.900">{children}</Typography>
    </Box>
  );
};

const courseSteps: WizardSteps = {
  [WizardStepIds.RESOURCE_TYPE]: {
    component: ResourceTypeForm,
  },
  [WizardStepIds.ITEM_SOURCE_FORM]: {
    component: ItemSourceForm,
  },
  [WizardStepIds.CATALOG_FORM]: {
    component: CatalogRefundForm,
  },
  [WizardStepIds.EXTERNAL_FORM]: {
    component: ExternalRefundForm,
  },
  [WizardStepIds.SUMMARY]: {
    component: RefundSummary,
    titleElement: <Title>Reembolso disponível</Title>,
  },
};

const createSteps = ({
  resourceType,
  subsidyPolicyType,
}: {
  resourceType: ResourceTypes;
  subsidyPolicyType: EligibleResourceType;
}) => {
  const steps = {
    ...courseSteps,
  };

  if (subsidyPolicyType === EligibleResourceType.CATALOG) {
    delete steps[WizardStepIds.ITEM_SOURCE_FORM];
  }

  switch (resourceType) {
    case ResourceTypes.COURSE:
      return steps;
    default:
      // TODO: how will we handle  different resource types?
      // For now, we're just returning courseSteps for any resource type
      return steps;
  }
};

export const DescriptionStep = () => {
  const { values: wizardValues } = useWizard();
  const { subsidyPolicy } = useRefundRequest();

  const { resourceType } = wizardValues;
  const subsidyPolicyType =
    subsidyPolicy?.configuration?.eligible_resource_type;

  const steps = createSteps({ resourceType, subsidyPolicyType }); // Declare the 'steps' variable

  return (
    <>
      <Wizard steps={steps} />
    </>
  );
};

const createInitialState = ({ subsidyPolicyType }) => {
  const wizardStepId =
    subsidyPolicyType === EligibleResourceType.CATALOG
      ? WizardStepIds.RESOURCE_TYPE
      : WizardStepIds.ITEM_SOURCE_FORM;

  const state: WizardState = {
    currentStepId: wizardStepId,
    values: {
      [WizardStepIds.RESOURCE_TYPE]: ResourceTypes.COURSE,
    },
  };

  const itemSource =
    subsidyPolicyType === EligibleResourceType.CATALOG
      ? ResourceCourseSources.CATALOG
      : null;

  if (itemSource) {
    state.values[WizardStepIds.ITEM_SOURCE_FORM] = itemSource;
  }

  return state;
};

const DescriptionStepWithContext = () => {
  const { subsidyPolicy } = useRefundRequest();

  const subsidyPolicyType =
    subsidyPolicy?.configuration?.eligible_resource_type;

  const initialState = createInitialState({ subsidyPolicyType });

  return (
    <WizardProvider initialState={initialState}>
      <DescriptionStep />
    </WizardProvider>
  );
};

export default DescriptionStepWithContext;
