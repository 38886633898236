import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TranslatedComponent } from 'src/app/utils/translated.component';
import { User } from 'src/app/models/user.model';
import { UsersService } from 'src/app/services/users.service';
import { getSvg } from 'src/app/utils/helpers';
import { RouterService } from 'src/app/services/router.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-users-name',
  templateUrl: './users-name.component.html',
  styleUrls: ['./users-name.component.scss'],
})
export class UsersName extends TranslatedComponent implements OnInit {
  getSvg = getSvg;

  translationKey: string = 'CURSOS.HOME';

  user: User;
  userName: string;

  @Output()
  close: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private userService: UsersService) {
    super();
  }

  ngOnInit(): void {
    this.user = this.userService.user;
  }

  onClose() {
    this.close.emit();
  }

  async onSend() {
    await this.userService.onUserName(this.userName);
    this.user.nome = this.userName;
    this.close.emit();
  }
}
