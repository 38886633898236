import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';

export const CursosSection = ({
  children,
  subtitle,
  title,
}: {
  children: ReactNode;
  subtitle?: ReactNode;
  title?: ReactNode;
}) => {
  return (
    <Box mb={4} className={'CursosSection'}>
      <Box mb={2}>
        {title && (
          <Box py={1}>
            <Typography variant="h5">{title}</Typography>
          </Box>
        )}
        {subtitle && (
          <Box pb={1}>
            <Typography variant="body2">{subtitle}</Typography>
          </Box>
        )}
      </Box>
      <Box>{children}</Box>
    </Box>
  );
};
