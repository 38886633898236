import { DateTime } from 'luxon';

export function formatDate(date?: string) {
  const newDate = date ? new Date(date) : new Date();
  const day = newDate.getUTCDate().toString().padStart(2, '0');
  const month = (newDate.getUTCMonth() + 1).toString().padStart(2, '0');
  const year = newDate.getUTCFullYear();

  return `${day}/${month}/${year}`;
}

export function formatTime(date?: string) {
  const newDate = date ? new Date(date) : new Date();

  const hour = newDate.getHours().toString().padStart(2, '0');
  const minutes = newDate.getMinutes().toString().padStart(2, '0');

  return `${hour}:${minutes}`;
}

export function extractDuration(duration: string) {
  const splited = duration.split(':');
  const hours = parseInt(splited[0]);
  const minutes = parseInt(splited[1]);
  const seconds = parseInt(splited[2]);

  if (hours > 0) {
    return `${hours}${
      minutes > 0 ? ':' + minutes.toString().padStart(2, '0') : ''
    } hora${hours > 1 || minutes > 0 ? 's' : ''}`;
  }

  if (minutes > 0) {
    return `${minutes}${
      seconds > 0 ? ':' + seconds.toString().padStart(2, '0') : ''
    } minuto${minutes > 1 || seconds > 0 ? 's' : ''}`;
  }
  return `${seconds} segundo${seconds > 1 ? 's' : ''}`;
}

export function lastYearMonths() {
  const date = DateTime.now();
  const months = [];
  for (let i = 0; i < 12; i += 1) {
    const newDate = date.minus({
      month: i,
    });

    const month = newDate.toFormat('MMM', {
      locale: 'pt-br',
    });

    months.unshift(
      `${month.substring(0, 1).toLocaleUpperCase()}${month.substring(1, 3)}`
    );
  }

  return months;
}
