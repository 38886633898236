<div class="container">
  <div class="row">
    <div class="col-12 mt-4">
      <table class="table-container">
        <ng-container *ngFor="let tablePage of tablePages; let i = index">
          <ng-container *ngIf="i === page">
            <thead>
              <tr>
                <th *ngIf="page > 0">
                  <button class="proximo-button" (click)="page = page - 1">
                    <span class="material-icons-outlined notranslate"
                      >arrow_back</span
                    >
                  </button>
                </th>
                <th *ngFor="let column of tablePage.columns">
                  {{ column.label | translate }}
                </th>

                <th *ngIf="page < tablePages.length - 1">
                  <button class="proximo-button" (click)="page = page + 1">
                    <span class="material-icons-outlined notranslate"
                      >arrow_forward</span
                    >
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="_data.length === 0">
                <td *ngIf="page > 0"></td>
                <td class="no-item" [attr.colspan]="tablePage.columns.length">
                  {{ getTranslation('nenhumItem') | translate }}
                </td>
                <td *ngIf="page < tablePages.length - 1"></td>
              </tr>

              <ng-container
                *ngFor="let item of displayingData; let rowIndex = index"
              >
                <tr
                  [ngClass]="{
                    'bg-gray': rowIndexOdd(rowIndex),
                    'no-border': textArea[rowIndex]
                  }"
                >
                  <td *ngIf="page > 0"></td>
                  <td *ngFor="let column of tablePage.columns">
                    <ng-container *ngIf="column.type === 'text'">
                      {{ column.getValue(item).toString() | translate }}
                    </ng-container>
                    <component-radio
                      *ngIf="
                        column.type === 'radio' || column.type === 'radio-text'
                      "
                      [checked]="isItemChecked(column, offsetIndex(rowIndex))"
                      (onClick)="radioCheck(column, offsetIndex(rowIndex))"
                    >
                      {{ column.getValue(item) }}
                    </component-radio>

                    <button
                      class="button-download"
                      *ngIf="column.type === 'download'"
                      (click)="onView(column, item)"
                    >
                      <span class="material-icons-outlined notranslate"
                        >visibility</span
                      >
                    </button>
                    <button
                      class="button-download"
                      *ngIf="column.type === 'download'"
                      (click)="onDownload(column, item)"
                    >
                      <span class="material-icons-outlined notranslate"
                        >download</span
                      >
                    </button>

                    <button
                      class="button-download"
                      *ngIf="column.type === 'actions'"
                      (click)="openActions(offsetIndex(rowIndex))"
                    >
                      <span class="material-icons-outlined notranslate"
                        >menu</span
                      >
                    </button>

                    <div
                      class="actions-container"
                      *ngIf="
                        openedActions === offsetIndex(rowIndex) &&
                        column.type === 'actions'
                      "
                    >
                      <button
                        *ngFor="let action of column.getActions"
                        (click)="action.callBack(item)"
                      >
                        {{ action.label }}
                      </button>
                    </div>
                  </td>

                  <td *ngIf="page < tablePages.length - 1"></td>
                </tr>

                <tr
                  *ngIf="textArea[offsetIndex(rowIndex)]"
                  [ngClass]="{ 'bg-gray': rowIndexOdd(rowIndex) }"
                >
                  <td [attr.colspan]="countColumns(tablePage.columns)">
                    <component-input
                      label="Justificativa"
                      [isInput]="false"
                      (inputed)="onTextAreaInput(offsetIndex(rowIndex), $event)"
                      [maxLength]="maxLength"
                    ></component-input>
                  </td>
                </tr>
              </ng-container>

              <tr
                [ngClass]="{ 'bg-gray': emptyRowIndexOdd(rowIndex) }"
                class="empty-row"
                *ngFor="let emptyRow of emptyRows; let rowIndex = index"
              >
                <td *ngIf="page > 0"></td>
                <td [attr.colspan]="tablePage.columns.length">&nbsp;</td>
                <td *ngIf="page < tablePages.length - 1"></td>
              </tr>

              <tr *ngIf="!hideSaveButton">
                <td *ngIf="page > 0"></td>

                <td
                  class="align-right"
                  [attr.colspan]="tablePage.columns.length"
                >
                  <component-botao
                    (onClick)="onSave()"
                    [disabled]="!salvarButton"
                    innerClass="ml-auto"
                    >{{
                      getTranslation('btnSalvar') | translate
                    }}</component-botao
                  >
                </td>
                <td *ngIf="page < tablePages.length - 1"></td>
              </tr>
            </tbody>
          </ng-container>
        </ng-container>
      </table>
    </div>
  </div>

  <div class="row mt-3" *ngIf="_data.length > items_per_page">
    <div class="col-12">
      <app-table-pagination
        [currentPage]="currentPage"
        [maxPages]="getMaxPages()"
        (next)="nextDataPage()"
        (previous)="previousDataPage()"
        (goto)="goToPage($event)"
      ></app-table-pagination>
    </div>
  </div>
</div>

<app-modal
  *ngIf="displayFiles"
  [alerta]="false"
  header="Comprovante"
  [closeButton]="true"
  (closeClick)="displayFiles = undefined"
>
  <button (click)="onPreviousPage()" class="navigation-button start-0">
    <span class="material-icons-outlined notranslate">chevron_left</span>
  </button>

  <div
    class="d-flex justify-content-center align-items-center align-self-center image-container"
  >
    <pdf-viewer
      *ngIf="isPdf(displayFiles[currentDisplay])"
      [src]="getUrl(displayFiles[currentDisplay])"
      [render-text]="true"
      [original-size]="false"
      class="pdf-viewer"
    ></pdf-viewer>

    <img
      *ngIf="!isPdf(displayFiles[currentDisplay])"
      [src]="getUrl(displayFiles[currentDisplay])"
    />
  </div>

  <button (click)="onNextPage()" class="navigation-button end-0">
    <span class="material-icons-outlined notranslate">chevron_right</span>
  </button>
</app-modal>
