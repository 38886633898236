export enum SubscriptionStatusCodes {
  completed = 'completed',
  registrationStarted = 'registration_started',
  pending = 'pending',
  failed = 'failed',
  paid = 'paid',
  registered = 'registered',
  canceled = 'canceled',
  dropout = 'dropout',
  duplicateregistration = 'duplicate_registration',
  testregistration = 'test_registration',
  invalidContact = 'invalid_contact',
  registration = 'registration',
  directed_ip= 'directed_ip',
}

export const SubscriptionStatusPendingCodes = new Set([
  SubscriptionStatusCodes.registrationStarted,
  SubscriptionStatusCodes.pending,
  SubscriptionStatusCodes.failed,
  SubscriptionStatusCodes.paid,
  SubscriptionStatusCodes.registration
]);

export interface Subscription {
  subscription_id: number;
  offer_id: number;
  status: SubscriptionStatusCodes;
}