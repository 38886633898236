import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

type Address = {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
};

type Error = {
  erro: string;
};


@Injectable({
  providedIn: 'root',
})
export class CepService {
  constructor(private http: HttpClient) {}

  // TODO: We should use return either Address or Error this method
  async getCepData(cep: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`https://viacep.com.br/ws/${cep}/json`)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
}
