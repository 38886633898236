import * as React from 'react';
import Box from '@mui/material/Box';

const AccordionColumnWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>{children}</Box>;
};

export default AccordionColumnWrapper;
