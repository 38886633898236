import useToggle from '@/react/hooks/useToggle';
import { restClient } from '@/react/utils/fetch';
import { zodResolver } from '@hookform/resolvers/zod';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import * as Sentry from '@sentry/browser';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import AutocompleteStatesAndCities from '../autocomplete-states-cities.component';
import { formValidationSchema } from '../schemas';
import ChangeEmailDialog from './change-email-dialog.component';
import RemovetDialog from './remove-dialog.component';
import ResetPasswordDialog from './reset-password-dialog.component';
import { useEmployeeEdit } from './useEmployeeEdit';
import styled from '@emotion/styled';
import { toastError } from '@/react/utils/custom-toast';

const CustonInfoOutLinedIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
  color: theme.palette.neutral[500],
  marginRight: '8px',
  fontSize: 'small',
  height: '20px',
  width: '40px',
}));

const customInputStyle = {
  fontFamily: 'Assistant',
  fontSize: '1rem',
  fontWeight: 500,
  lineHeight: '1.5rem',
};

const Tooltips = {
  COLABORADOR: 'colaborador',
  CAMPOS_OPCIONAIS: 'campos_opcionais',
  EMPRESA: 'empresa',
  ADMINISTRADOR: 'administrador',
  TIPO_ACESSO: 'tipo_acesso',
};

const fetchEmployeeData = async (id) => {
  try {
    const response = await restClient(`/employees/${id}`);
    const data = await response?.data?.data;

    return data;
  } catch (error) {
    console.error('Error fetching employee data', error);
    Sentry.captureException(error);
    throw error;
  }
};

const getIdUrl = () => {
  const url = new URL(window.location.href);
  const path = url.pathname;
  const parameters = path.split('/');
  const id = parameters[parameters.length - 1];
  return id;
};

const EditEmployeeComponent = () => {
  const { control, handleSubmit, getValues, setValue } = useForm({
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: '',
    resolver: zodResolver(formValidationSchema),
  });

  const [idUSer, setIdUser] = React.useState(false);
  const [employee, setEmployee] = React.useState(null);

  const url = new URL(window.location.href);
  const path = url.pathname;
  const parameters = path.split('/');
  const id = parameters[parameters.length - 1];

  React.useEffect(() => {
    const loadEmployeeData = async () => {
      try {
        const employeeData = await fetchEmployeeData(id);
        const employee = employeeData?.employee[0];

        setValue('name', employee.nome || '');
        setValue('email', employee?.email ? employee?.email : ' ');
        setValue('cpf', employee.cpf ? employee.cpf : ' ');
        setValue(
          'internal_employee_id',
          employee?.colaborador?.corporate_data?.internal_employee_id || ''
        );
        setValue(
          'type_collaborator',
          employee?.colaborador?.corporate_data?.type_collaborator || ''
        );
        setValue(
          'position_company',
          employee?.colaborador?.corporate_data?.position_company || ''
        );
        setValue(
          'department',
          employee?.colaborador?.corporate_data?.department || ''
        );
        setValue('team', employee?.colaborador?.corporate_data?.team || '');
        setValue(
          'manager_email',
          employee?.colaborador?.corporate_data?.manager_email || ''
        );
        setValue(
          'building',
          employee?.colaborador?.corporate_data?.building || ''
        );
        setValue('region', employee?.colaborador?.corporate_data?.region || '');
        setValue('state', employee?.colaborador?.corporate_data?.state || '');
        setValue('city', employee?.colaborador?.corporate_data?.city || '');
        setValue(
          'company',
          employee?.colaborador?.corporate_data?.company || ''
        );
        setValue(
          'cost_center',
          employee?.colaborador?.corporate_data?.cost_center || ''
        );
        setValue('is_admin', employeeData?.isAdmin || false);

        setEmployee(employee);
      } catch (error) {}
    };

    loadEmployeeData();
  }, [setValue, id]);

  const {
    open: showAddOrEditEmployeeDialog,
    toggle: toggleAddOrEditEmployeeDialog,
  } = useToggle({ startOpen: false });

  const { open: showRemoveEmployeeDialog, toggle: toggleRemoveEmployeeDialog } =
    useToggle({ startOpen: false });

  const {
    open: showResetEmployeePasswordDialog,
    toggle: toggleResetEmployeePasswordDialog,
  } = useToggle({ startOpen: false });

  const [valueEmailUpdate, setValueEmailUpdate] = React.useState('');

  const handleUpdateEmail = (email) => {
    setValueEmailUpdate(email);
    setValue('email', email);
    toggleAddOrEditEmployeeDialog();
  };

  const editState = useEmployeeEdit({
    employee,
    onClose: () => {
      toggleAddOrEditEmployeeDialog();
    },
    handleUpdateEmail,
  });

  const handleEdit = () => {
    toggleAddOrEditEmployeeDialog();
  };

  const handleRemove = () => {
    toggleRemoveEmployeeDialog();
  };

  const handleResetPassword = () => {
    toggleResetEmployeePasswordDialog();
  };

  const [stateSelected, setstateSelected] = React.useState(
    employee?.colaborador?.corporate_data?.state
  );
  const [citySelected, setcitySelected] = React.useState(
    employee?.colaborador?.corporate_data?.city
  );

  React.useEffect(() => {
    if (employee?.colaborador?.corporate_data?.state) {
      setstateSelected(employee?.colaborador?.corporate_data?.state);
    }
    if (employee?.colaborador?.corporate_data?.city) {
      setcitySelected(employee?.colaborador?.corporate_data?.city);
    }
  }, [
    employee?.colaborador?.corporate_data?.state,
    employee?.colaborador?.corporate_data?.city,
  ]);

  const handleUpdateStateAndCity = (estado, cidade) => {
    setstateSelected(estado);
    setcitySelected(cidade);
  };

  const [cpf, email] = [employee?.cpf, employee?.email];
  const showCPF = cpf ? true : false;
  const showEmail = email ? true : false;

  React.useEffect(() => {
    if (showCPF) {
      setValue('type_registration', 'cpf');
    } else if (showEmail) {
      setValue('type_registration', 'email');
    }
  }, [showCPF, showEmail, cpf, email]);

  React.useEffect(() => {
    setIdUser(idUSer);
  }, [idUSer]);

  const [loading, setLoading] = React.useState(false);
  const [openTooltip, setOpenTooltip] = React.useState(null);

  const handleTooltipOpen = (tooltipId) => {
    setOpenTooltip((prevActiveTooltip) =>
      prevActiveTooltip === tooltipId ? null : tooltipId
    );
  };

  const handleAdd = async (data) => {
    const id = getIdUrl();
    const payload = {
      ...data,
      corporate_data: {
        internal_employee_id: data.internal_employee_id,
        type_collaborator: data.type_collaborator,
        position_company: data.position_company,
        department: data.department,
        team: data.team,
        manager_email: data.manager_email,
        building: data.building,
        region: data.region,
        company: data.company,
        cost_center: data.cost_center,
        state: stateSelected,
        city: citySelected,
      },
    };

    setLoading(true);

    try {
      await restClient.put(`/employees/${id}`, payload);
      window.location.assign('/empresa/employees');
    } catch (error) {
      const messageToast = error.message || 'Erro ao criar Colaborador.';

      toastError({ messageToast });
      setLoading(false);
      Sentry.captureException(error);
    }

    setLoading(false);
  };

  const emailValueRef = React.useRef(editState.email || '');

  emailValueRef.current = editState.email;

  React.useEffect(() => {
    const initialEmail = editState.email ?? getValues('email');

    setValueEmailUpdate(initialEmail);
    setValue('email', initialEmail);
  }, [setValue, editState.email, employee?.email]);

  const handleCancelAddEmployee = () => {
    window.location.assign('/empresa/employees');
  };

  return (
    <form onSubmit={handleSubmit(handleAdd)}>
      <Container>
        <Paper elevation={5} sx={{ my: 2 }} style={{ padding: '32px' }}>
          <Grid
            container
            spacing={2}
            my={2}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              '& > *': {
                m: 1,
              },
            }}
          >
            <Grid item sm={6} xs={12}>
              <Typography
                gutterBottom
                variant="h4"
                fullWidth
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              >
                Editar colaborador
              </Typography>
            </Grid>

            <Grid item sm={2} xs={12} fullWidth>
              <Button
                onClick={handleRemove}
                fullWidth
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingLeft: '16px',
                  paddingRight: '16px',
                }}
                variant="outlined"
              >
                Redefinir senha
              </Button>
            </Grid>

            {employee?.email !== null && (
              <Grid item sm={2} xs={12} fullWidth>
                <Button
                  onClick={handleEdit}
                  fullWidth
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                  }}
                  variant="outlined"
                >
                  Alterar email
                </Button>
              </Grid>
            )}

            {employee?.deleted_at === null && (
              <Grid item sm={2} xs={12} fullWidth>
                <Button
                  onClick={handleResetPassword}
                  fullWidth
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                  }}
                  variant="outlined"
                  color="error"
                >
                  Excluir usuário
                </Button>
              </Grid>
            )}

            <ChangeEmailDialog
              emailValueRef={valueEmailUpdate}
              editState={editState}
              open={showAddOrEditEmployeeDialog}
              toggleOpen={toggleAddOrEditEmployeeDialog}
            />
            <ResetPasswordDialog
              employee={employee}
              open={showRemoveEmployeeDialog}
              toggleOpen={toggleRemoveEmployeeDialog}
            />
            <RemovetDialog
              employee={employee}
              open={showResetEmployeePasswordDialog}
              toggleOpen={toggleResetEmployeePasswordDialog}
            />
          </Grid>

          {showEmail && (
            <Grid item xs={12} md={6}>
              <FormLabel>E-mail*</FormLabel>
              <Controller
                control={control}
                name="email"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    value={valueEmailUpdate}
                    fullWidth
                    placeholder="colaborador@nomeempresa.com"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          )}

          {showCPF && (
            <Grid item xs={12} md={6}>
              <FormLabel>CPF*</FormLabel>
              <Controller
                control={control}
                name="cpf"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    InputProps={{
                      readOnly: true,
                      style: customInputStyle,
                    }}
                    {...field}
                    error={!!error}
                    fullWidth
                    placeholder="123.456.789.10"
                    variant="outlined"
                    helperText={error?.message || ' '}
                  />
                )}
              />
            </Grid>
          )}

          <Grid
            item
            xs={12}
            sx={{
              marginTop: '17px',
            }}
          >
            <FormLabel>Nome Completo*</FormLabel>
            <Controller
              control={control}
              name="name"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error}
                  fullWidth
                  placeholder="Insira o nome completo do colaborador"
                  variant="outlined"
                  helperText={error?.message || ' '}
                  InputProps={{ style: customInputStyle }}
                />
              )}
            />
          </Grid>

          <Grid container spacing={2} my={2}>
            <Grid item sm={12} xs={12}>
              <Typography gutterBottom variant="h4" severity="info">
                Campos opcionais
                <Tooltip
                  TransitionComponent={Zoom}
                  PopperProps={{
                    disablePortal: true,
                  }}
                  open={openTooltip === Tooltips.CAMPOS_OPCIONAIS}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        whiteSpace: 'pre-wrap',
                        fontFamily: 'Assistant',
                        fontSize: '0.9rem',
                        fontWeight: 500,
                        lineHeight: '1.5rem',
                      },
                    },
                  }}
                  title="Os campos opcionais podem ser usados como filtros no relatório, para análises mais profundas de utilização."
                  onClick={() => handleTooltipOpen(Tooltips.CAMPOS_OPCIONAIS)}
                >
                  <CustonInfoOutLinedIcon sx={{ marginLeft: '7px' }} />
                </Tooltip>
              </Typography>
            </Grid>

            <Grid item sm={6} xs={12} sx={{ paddingRight: { md: '20px' } }}>
              <FormLabel>ID interno</FormLabel>
              <Controller
                control={control}
                name="internal_employee_id"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    fullWidth
                    placeholder="ex.: número de matrícula, chave interna"
                    variant="outlined"
                    helperText={error?.message || ' '}
                    InputProps={{ style: customInputStyle }}
                  />
                )}
              />
            </Grid>

            <Grid
              item
              sm={6}
              xs={12}
              sx={{ paddingLeft: { md: '20px' } }}
            >
              <FormLabel>Tipo de colaborador</FormLabel>
              <Controller
                control={control}
                name="type_collaborator"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    fullWidth
                    placeholder="ex.: CLT, externo, prestador de serviço"
                    variant="outlined"
                    helperText={error?.message || ' '}
                    InputProps={{ style: customInputStyle }}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6} xs={12} sx={{ paddingRight: { md: '20px' } }}>
              <FormLabel>Cargo</FormLabel>
              <Controller
                control={control}
                name="position_company"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    fullWidth
                    placeholder="ex.: analista, coordenador"
                    variant="outlined"
                    helperText={error?.message || ' '}
                    InputProps={{ style: customInputStyle }}
                  />
                )}
              />
            </Grid>

            <Grid
              item
              sm={6}
              xs={12}
              sx={{ paddingLeft: { md: '20px' } }}
            >
              <FormLabel>Equipe</FormLabel>
              <Controller
                control={control}
                name="team"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    fullWidth
                    placeholder="ex.: atração de talentos, desenvolvimento"
                    variant="outlined"
                    helperText={error?.message || ' '}
                    InputProps={{ style: customInputStyle }}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6} xs={12} sx={{ paddingRight: { md: '20px' } }}>
              <FormLabel>Departamento</FormLabel>
              <Controller
                control={control}
                name="department"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    fullWidth
                    placeholder="ex.: recursos humanos, marketing"
                    variant="outlined"
                    helperText={error?.message || ' '}
                    InputProps={{ style: customInputStyle }}
                  />
                )}
              />
            </Grid>

            <Grid
              item
              sm={6}
              xs={12}
              sx={{ paddingLeft: { md: '20px' } }}
            >
              <FormLabel>Centro de custo</FormLabel>
              <Controller
                control={control}
                name="cost_center"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    fullWidth
                    placeholder="ex.: produtos digitais"
                    variant="outlined"
                    helperText={error?.message || ' '}
                    InputProps={{ style: customInputStyle }}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6} xs={12} sx={{ paddingRight: { md: '20px' } }}>
              <FormLabel>Gestor</FormLabel>
              <Controller
                control={control}
                name="manager_email"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    fullWidth
                    placeholder="Insira o email"
                    variant="outlined"
                    helperText={error?.message || ' '}
                    InputProps={{ style: customInputStyle }}
                  />
                )}
              />
            </Grid>

            <AutocompleteStatesAndCities
              handleUpdateStateAndCity={handleUpdateStateAndCity}
              stateAndCity={{
                state: employee?.colaborador?.corporate_data?.state,
                city: employee?.colaborador?.corporate_data?.city,
              }}
            />

            <Grid
              item
              sm={6}
              xs={12}
              sx={{ paddingLeft: { md: '20px' } }}
            >
              <FormLabel>Região</FormLabel>
              <Controller
                control={control}
                name="region"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    fullWidth
                    placeholder="ex.: filial, região de atuação"
                    variant="outlined"
                    helperText={error?.message || ' '}
                    InputProps={{ style: customInputStyle }}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6} xs={12} sx={{ paddingRight: { md: '20px' } }}>
              <FormLabel>Prédio</FormLabel>
              <Controller
                control={control}
                name="building"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    fullWidth
                    placeholder="ex.: nome do edifício, campus ou andar"
                    variant="outlined"
                    helperText={error?.message || ' '}
                    InputProps={{ style: customInputStyle }}
                  />
                )}
              />
            </Grid>

            <Grid
              item
              sm={6}
              xs={12}
              sx={{ paddingLeft: { md: '20px' } }}
            >
              <FormLabel>
                Empresa
                <Tooltip
                  placement="right-end"
                  TransitionComponent={Zoom}
                  PopperProps={{
                    disablePortal: true,
                  }}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  open={openTooltip === Tooltips.EMPRESA}
                  title="Campo para holding que possuem diversas empresas."
                  onClick={() => handleTooltipOpen(Tooltips.EMPRESA)}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        whiteSpace: 'pre-wrap',
                        fontFamily: 'Assistant',
                        fontSize: '0.9rem',
                        fontWeight: 500,
                        lineHeight: '1.5rem',
                        width: '350px',
                      },
                    },
                  }}
                >
                  <CustonInfoOutLinedIcon sx={{ marginLeft: '7px' }} />
                </Tooltip>
              </FormLabel>
              <Controller
                control={control}
                name="company"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    fullWidth
                    placeholder="ex.: empresa pertencente a uma holding"
                    variant="outlined"
                    helperText={error?.message || ' '}
                    InputProps={{ style: customInputStyle }}
                  />
                )}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                '& > *': {
                  m: 1,
                },
              }}
            >
              <FormGroup
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  margin: '0px',
                }}
              >
                <Controller
                  name="is_admin"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                      }
                      label={
                        <Typography variant="h6" sx={{ margin: '0px' }}>
                          Administrador
                        </Typography>
                      }
                    />
                  )}
                />
                <Tooltip
                  placement="bottom-end"
                  TransitionComponent={Zoom}
                  PopperProps={{
                    disablePortal: true,
                  }}
                  open={openTooltip === Tooltips.ADMINISTRADOR}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        whiteSpace: 'pre-wrap',
                        fontFamily: 'Assistant',
                        fontSize: '0.9rem',
                        fontWeight: 500,
                        lineHeight: '1.5rem',
                      },
                    },
                  }}
                  title="Dá acesso à gestão de usuários, relatórios e outras configurações da plataforma."
                  onClick={() => handleTooltipOpen(Tooltips.ADMINISTRADOR)}
                >
                  <CustonInfoOutLinedIcon sx={{ marginLeft: '7px' }} />
                </Tooltip>
              </FormGroup>
            </Grid>
          </Grid>

          <Divider sx={{ my: 3 }} />

          <Grid
            item
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              '& > *': {
                m: 1,
              },
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              style={{
                marginRight: '10px',
              }}
              onClick={handleCancelAddEmployee}
            >
              Cancelar
            </Button>
            <LoadingButton
              color="primary"
              loading={loading}
              loadingPosition="start"
              type="submit"
              variant="contained"
            >
              Salvar
            </LoadingButton>
          </Grid>
        </Paper>
      </Container>
    </form>
  );
};

export default EditEmployeeComponent;
