<component-modal (fechar)="closeModal()">
  <h2 class="Subtitle-2 d-flex text-center title">
    Você já cadastrou dependentes na plataforma Galena?
  </h2>
  <div class="d-flex justify-content-center">
    <img [src]="getSvg('marketing_promotion.svg')" />
  </div>

  <p class="BodyText-1 my-4 d-flex text-center">
    Ao cadastrar seus dependentes eles têm acesso aos mesmos descontos
    exclusivos que você! Para cadastrar, é só clicar no botão abaixo.
  </p>
  <div class="d-flex justify-content-center my-4">
    <component-botao type="secondary" class="mr-2" (onClick)="closeModal()"
      >Agora não</component-botao
    >
    <component-botao (onClick)="onDependent()"
      >Cadastrar dependentes</component-botao
    >
  </div>
</component-modal>
