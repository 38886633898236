<component-modal (fechar)="closeModal()">
  <div class="d-flex flex-column modal-container align-items-center">
    <h2 class="text-center Subtitle-2 mt-6">Você sabia?</h2>
    <img [src]="getSvg('leroy_illustration.svg')" />
    <p class="BodyText-1 text-center mb-0 mx-5">
      A Leroy Merlin possui uma política de incentivo educacional,
      <strong
        >onde todos os colaboradores possuem +30% de desconto em cursos que
        custam até R$1000,00 mensais</strong
      >
      na plataforma Galena.
    </p>
    <p class="BodyText-1 text-center mb-0 mx-5">
      Com esse descontão, não tem mais motivos para adiar aquele curso que você
      sempre sonhou em fazer, não é mesmo?!
    </p>
    <p class="BodyText-1 text-center mb-0 mx-5">
      Para garantir o seu desconto é muito simples! Matricule-se agora no curso
      que você sonha em fazer, o resto é com a gente! (;
    </p>
    <div class="d-flex justify-content-center mt-2">
      <component-botao class="mr-2" (onClick)="closeModal()"
        >Entendi</component-botao
      >
    </div>
  </div>
</component-modal>
