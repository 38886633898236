<div class="nav-container">
  <div class="top-nav w-100">
    <a
      class="top-nav-item active"
      [ngClass]="{ active: menu == 'cursos_andamento' }"
      (click)="navigate('cursos_andamento')"
    >
      <span> {{ getTranslation('andamento') | translate }} </span>
    </a>
    <a
      class="top-nav-item active"
      [ngClass]="{ active: menu == 'cursos_concluidos' }"
      (click)="navigate('cursos_concluidos')"
    >
      <span> {{ getTranslation('finalizados') | translate }} </span>
    </a>
  </div>
</div>
