import { Box, Grid, Slide, styled, Button } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AccessData } from './access-input.component';
import logoGalenaEdupass from '@/react/assets/galena.svg';

const loginBg = 'https://api.edupass.io/api/contents/assets/new_login_bg.png';

enum ForgotPassword {
  access = 'Access',
}

const PropsAccessData = (props) => (
  <Box sx={{ maxWidth: '100%' }}>
    <AccessData {...props} />
  </Box>
);

const StyledGrid = styled(Grid)(() => ({
  minHeight: '100vh',
  backgroundColor: '#FFFFFF',
}));

const StyledImageSection = styled('div')(() => ({
  backgroundImage: `url('${loginBg}')`,
  width: '100%',
  height: '100%',
  backgroundSize: 'cover',
}));

const StyledLogo = styled('img')(({ theme }) => ({
  width: '157px',
}));

const BackButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  left: theme.spacing(2),
}));

const ForgotPasswordComponentReact = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [transitionStep, setTransitionStep] = useState<ForgotPassword>(
    ForgotPassword.access
  );
  const [loginStep, setLoginStep] = useState<ForgotPassword>(
    ForgotPassword.access
  );

  const containerRef = useRef<HTMLElement>(null);

  const onCompletedTransition = () => {
    setTransitionStep(loginStep);
  };

  const handleBackToEmail = () => {
    window.location.assign('/login/new-login');
  };

  return (
    <>
      <StyledGrid container>
        <Grid item xs={12} md={6} justifyContent={'center'} display={'flex'}>
          <Box
            component={Grid}
            container
            width={'100%'}
            maxWidth={'384px'}
            direction={'column'}
            rowGap={4}
            justifyContent={'center'}
            ref={containerRef}
            sx={{
              marginX: isMobile ? '24px' : 0,
              marginY: 0,
            }}
          >
            {loginStep === ForgotPassword.access && (
              <Grid item mb={2} mt={2}>
                <BackButton
                  variant="outlined"
                  sx={{
                    border: 'none',
                    paddingLeft: 0,
                    '&:hover': {
                      backgroundColor: 'transparent',
                      border: 'none',
                    },
                  }}
                  onClick={handleBackToEmail}
                >
                  <ArrowBackIcon sx={{ fontSize: 'small' }} /> voltar
                </BackButton>
              </Grid>
            )}
            <StyledLogo src={logoGalenaEdupass} />

            <Slide
              direction="right"
              in={transitionStep === ForgotPassword.access}
              appear={false}
              mountOnEnter
              unmountOnExit
              container={containerRef.current}
              onExited={onCompletedTransition}
            >
              <Box container component={Grid} rowGap={4} direction={'column'}>
                <PropsAccessData />
              </Box>
            </Slide>
          </Box>
        </Grid>
        <Grid item md={6} display={{ xs: 'none', md: 'flex' }}>
          <StyledImageSection />
        </Grid>
      </StyledGrid>
    </>
  );
};
export { ForgotPasswordComponentReact };
