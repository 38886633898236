import * as React from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { getSvg } from '@/react/utils/assets';

const EmptyList = () => (
  <Grid container justifyContent="center" spacing={3}>
    <Grid item xs={12} textAlign="center">
      <img src={getSvg('nps_half.svg')} role="img" />
    </Grid>
    <Grid item xs={12} md={6}>
      <Stack spacing={2}>
        <Typography variant="h4" textAlign="center">
          Compartilhe a Galena com seus dependentes.
        </Typography>
        <Typography variant="body2" textAlign="center">
          Dê acesso aos recursos da Galena para cônjuge e filhos. Não há
          limite para filhos e os descontos permanecem mesmo que o acesso a
          Galena termine.
        </Typography>
      </Stack>
    </Grid>
  </Grid>
);

export default EmptyList;
