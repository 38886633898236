import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';

export const FullDiscountChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.success[500],
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.neutral[50],
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.caption.fontSize,
}));
