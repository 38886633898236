import React, { useCallback, useMemo } from 'react';
import { AnyFunction } from 'powership';

export function useThrottle<Func extends AnyFunction>(
  timeoutMs: number,
  execute: Func
) {
  const ref = React.useRef<ReturnType<typeof setTimeout> | null>(null);

  const abort = useCallback(() => {
    clearTimeout(ref.current);
  }, [ref, ref.current]);

  const throttled = useMemo(() => {
    return (...args: Parameters<Func>) => {
      abort();
      ref.current = setTimeout(() => {
        execute(...args);
      }, timeoutMs);
    };
  }, [execute, timeoutMs]);

  return [throttled, abort];
}
