import Autocomplete from '@mui/material/Autocomplete';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React from 'react';

const AutocompleteStatesAndCities = ({
  handleUpdateStateAndCity,
  ...props
}) => {
  const [selectedState, setSelectedState] = React.useState('');
  const [selectedCity, setSelectedCity] = React.useState('');
  const [resetStateAndCity, setResetStateAndCity] = React.useState('');
  const [cityDisabled, setCityDisabled] = React.useState(true);

  const [{ statesOptions, cities }, setStatesData] = React.useState({
    statesOptions: [],
    cities: {},
  });

  React.useEffect(() => {
    if (
      !resetStateAndCity &&
      props?.stateAndCity?.state &&
      props?.stateAndCity?.city
    ) {
      if (props?.stateAndCity?.state && props?.stateAndCity?.city) {
        setSelectedState(props?.stateAndCity?.state);
        setSelectedCity(props?.stateAndCity?.city);
      }

      if (!selectedState && !selectedCity) {
        setSelectedState(props?.stateAndCity?.state);
        setSelectedCity(props?.stateAndCity?.city);
        handleUpdateStateAndCity(
          props?.stateAndCity?.state,
          props?.stateAndCity?.city
        );
        if (props?.stateAndCity?.state) {
          setCityDisabled(false);
        }
      }

      setResetStateAndCity(true);
    }
  }, [
    selectedState,
    selectedCity,
    resetStateAndCity,
    handleUpdateStateAndCity,
  ]);

  const handleClear = () => {
    setSelectedState('');
    setSelectedCity('');
  };

  (async () => {
    const states = await import('@/data/estados.json');
    if (!statesOptions.length) {
      const statesOptions = states.default.map((state) => {
        return state.nome;
      });
      const cities = {};
      for (const el of states.default) {
        cities[el.nome] = el.cidades;
      }
      setStatesData({ statesOptions, cities });
    }
  })();

  const handleStateChange = (event, newValue) => {
    if (newValue === null || newValue === '') {
      handleClear();
    } else {
      setSelectedState(newValue);
      setSelectedCity('');
    }

    if (newValue) {
      setCityDisabled(false);
    } else {
      setCityDisabled(true);
    }
    handleUpdateStateAndCity(newValue, '');
  };

  const handleCityChange = (event, newValue) => {
    setSelectedCity(newValue);
    handleUpdateStateAndCity(selectedState, newValue);
  };

  return (
    <>
      <Grid item sm={6} xs={12} sx={{ paddingLeft: { md: '20px' } }}>
        <FormLabel>Estado</FormLabel>
        <Autocomplete
          options={statesOptions}
          value={selectedState}
          onChange={handleStateChange}
          renderInput={(params) => (
            <TextField {...params} placeholder="Selecione" variant="outlined" />
          )}
        />
      </Grid>
      <Grid item sm={6} xs={12} sx={{ paddingRight: { md: '20px' } }}>
        <FormLabel>Cidade</FormLabel>
        <Autocomplete
          options={cities[selectedState] || []}
          value={selectedCity}
          onChange={handleCityChange}
          disabled={cityDisabled}
          renderInput={(params) => (
            <TextField {...params} placeholder="Selecione" variant="outlined" />
          )}
        />
      </Grid>
    </>
  );
};

export default AutocompleteStatesAndCities;
