import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';

import { ComponentsModule } from './../components/components.module';
import { CardComponent } from './card/card.component';
import { InfoCardComponent } from './info-card/info-card.component';
import { CampoPesquisarComponent } from './campo-pesquisar/campo-pesquisar.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { InputRadioComponent } from './form/input-radio/input-radio.component';
import { ModalComponent } from './modal/modal.component';
import { LoadingComponent } from './loading/loading.component';
import { BannerComponent } from './banner/banner.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CursosDuvidasComponent } from './cursos-duvidas/cursos-duvidas.component';
import { InputTextComponent as InputText2Component } from './input-text/input-text.component';
import { CheckboxComponent as CheckboxComponent2 } from './checkbox/checkbox.component';
import { CursoCardComponent } from './curso-card/curso-card.component';
import { TableNewComponent } from './table-new/table-new.component';
import { RouterModule } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { CursoInfoCardComponent } from './curso-info-card/curso-info-card.component';
import { CardCursoAndamentoComponent } from './card-curso-andamento/card-curso-andamento.component';
import { CardCursosConcluidosComponent } from './card-cursos-concluidos/card-cursos-concluidos.component';
import { TableDefaultComponent } from './table-default/table-default.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { BannerEduplayComponent } from './banner-eduplay/banner-eduplay.component';
import { TablePaginationComponent } from './table-default/table-pagination/table-pagination.component';
import { NpsEduplayHalfComponent } from './modals/nps-eduplay-half/nps-eduplay-half.component';
import { EduplayCompletePageComponent } from './eduplay-complete-page/eduplay-complete-page.component';
import { CertificateHelpComponent } from './certificate-help/certificate-help.component';

const components = [
  CardComponent,
  InfoCardComponent,
  CampoPesquisarComponent,
  InputRadioComponent,
  ModalComponent,
  LoadingComponent,
  BannerComponent,
  CursosDuvidasComponent,
  InputText2Component,
  CheckboxComponent2,
  CursoCardComponent,
  TableNewComponent,
  CursoInfoCardComponent,
  CardCursoAndamentoComponent,
  CardCursosConcluidosComponent,
  TableDefaultComponent,
  BannerEduplayComponent,
  TablePaginationComponent,
  NpsEduplayHalfComponent,
  EduplayCompletePageComponent,
  CertificateHelpComponent,
];

@NgModule({
  declarations: components,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    ImageCropperModule,
    RouterModule,
    CommonModule,
    ComponentsModule,
    MatSliderModule,
    MatDividerModule,
    PdfViewerModule,
  ],
  exports: [...components, ComponentsModule, MatDividerModule, PdfViewerModule],
})
export class SharedModule {}
