import React from 'react';
import { QuestionWrapper } from './question-wrapper';
import Stack from '@mui/material/Stack';
import { NL2BR } from '@/react/components/quiz/utils/NL2BR';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import { EmailRegex } from 'powership';
import { FormControlLabel } from '@mui/material';

export const FLAG_ACCEPT_EMAIL_MKT = 'accept_communication';

export function QuestionTypeEmailContact() {
  return (
    <QuestionWrapper
      kind={'email_contact'}
      render={({ value, props, setErrors, setValue, errors }) => {
        const { header, placeholder } = props;

        const hasError = !!errors.length;

        const [emailValue = '', checkboxValue = FLAG_ACCEPT_EMAIL_MKT] = value;

        return (
          <Stack gap={3}>
            <NL2BR>{header}</NL2BR>

            <TextField
              placeholder={placeholder}
              error={hasError}
              value={emailValue}
              onChange={(ev) => {
                const txt = ev.target.value;
                const isValid = EmailRegex.test(txt);

                if (isValid && hasError) {
                  setErrors([]);
                } else if (!isValid && !hasError) {
                  setErrors(['Email inválido']);
                }

                setValue([txt, checkboxValue]);
              }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxValue === FLAG_ACCEPT_EMAIL_MKT}
                  defaultChecked
                  onChange={(_, checked) => {
                    setValue([
                      emailValue,
                      checked ? FLAG_ACCEPT_EMAIL_MKT : '',
                    ]);
                  }}
                />
              }
              label="Aceito receber comunicações nesse e-mail."
            />
          </Stack>
        );
      }}
    />
  );
}
