import { Box, Typography } from '@mui/material';
import React from 'react';
import {
  PlanItem,
  planItemLinkTo,
} from '@/react/components/idp/home/components/planItem';
import Link from '@mui/material/Link';
import { Activity, Plan } from '@/react/data/idp/interfaces';
import { ActivityCard } from '@/react/components/idp/shared/activity-card.component';

export const PlanItemInProgress = ({
  plan,
  activities,
}: {
  plan: Plan;
  activities: Activity[];
}) => {
  return (
    <PlanItem plan={plan}>
      <Typography
        color="black"
        variant="h5"
        fontWeight={500}
        marginBottom="20px"
        data-testid="planItemInProgressDates"
      >
        {plan.startedAt} - {plan.estimatedEndsAt}
      </Typography>

      <Typography color="black" variant="h5" fontWeight={500} fontSize={14}>
        Atividades
      </Typography>

      <Box display="flex" overflow="auto" marginX={-1} marginBottom={2}>
        {activities.map((activity) => (
          <Box
            key={activity.id}
            padding={1}
            width={270}
            display="flex"
            flexShrink={0}
          >
            <ActivityCard planId={plan.id} activity={activity} />
          </Box>
        ))}
      </Box>

      <Link href={planItemLinkTo(plan)} underline="none" margin="24px">
        Ver Plano
      </Link>
    </PlanItem>
  );
};
