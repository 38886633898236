<div class="vp-modal align-itens-center justify-contents-center">
  <div class="container">
    <div class="modal-container">
      <div class="modal-image"></div>
      <div class="modal-body">
        <h4>
          {{ getTranslation('acesso') | translate }}
          <br />
          <strong> {{ getTranslation('gratuito') | translate }} </strong>
          {{ getTranslation('eduplay') | translate }}
        </h4>
        <ul>
          <li>{{ getTranslation('cursos') | translate }}</li>
          <li>
            {{ getTranslation('temas1') | translate }}
            <br />
            {{ getTranslation('temas2') | translate }}
          </li>
          <li>{{ getTranslation('certificado') | translate }}</li>
        </ul>
        <p class="mt-2">{{ getTranslation('login') | translate }}</p>
        <p>Senha: edupass2020</p>
        <div class="acesse-aqui">
          <component-botao (onClick)="eduplay()">
            {{ getTranslation('acesse') | translate }}
          </component-botao>
        </div>
      </div>
      <div class="modal-close">
        <button (click)="closeModal()">X</button>
      </div>
    </div>
  </div>
</div>
