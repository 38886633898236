import axios from 'axios';
import { Profile } from '@/react/data/recommendation/interfaces';
import { JobSearchResultItem } from '@/react/data/jobs/interfaces';
import { ResultUseBootstrapData } from '@/react/data/bootstrap/useBootstrapData';
import CONST from '@/constants';
import { withAuthorization } from '@/react/config/axios';
import { RecommendationResponse } from '@/react/data/bootstrap/recomendation-interfaces';

export class RecommendationClient {
  static BASE_URL = `${CONST.FALCON_API_URL}/recommendation/api`;

  constructor() {}

  static client = withAuthorization(
    axios.create({
      baseURL: RecommendationClient.BASE_URL,
    })
  );

  static getProfile = async (
    options: {
      profile?: Partial<Profile>;
      signal?: AbortSignal;
      forceResetProfile?: boolean;
    } = {}
  ): Promise<Partial<Profile>> => {
    const { profile, signal, forceResetProfile } = options;

    const url = forceResetProfile
      ? `/profiles?reset_profile=true`
      : '/profiles';

    const res = await RecommendationClient.client.post(
      url,
      { profile: { ...profile } },
      { signal }
    ).catch((e) => {
      console.error(e);
      return e;
    });

    return res.data;
  };

  static getRecommendations = async (
    profileId: number | string,
    signal?: AbortSignal
  ): Promise<RecommendationResponse> => {
    const url = `/profiles/${profileId}/course_recommendations`;

    const res = await RecommendationClient.client.get(url, { signal });

    return res.data;
  };

  static patchProfile = async (
    profile: Partial<Profile> & { id: number },
    signal?: AbortSignal
  ): Promise<Partial<Profile>> => {
    //
    const res = await RecommendationClient.client.patch(
      `/profiles/${profile.id}`,
      {
        profile,
      },
      { signal }
    );

    return res.data;
  };

  static saveAsComplete = async (input: {
    profile: Partial<Profile>;
    signal?: AbortSignal;
    appBootstrapData: ResultUseBootstrapData;
  }): Promise<Partial<Profile>> => {
    const { profile, signal, appBootstrapData } = input;

    const res = await RecommendationClient.client.post(
      `/profiles/${appBootstrapData.id}/complete`,
      {
        profile,
      },
      { signal }
    );

    return res.data;
  };

  static findJobs = async (
    term: string,
    signal?: AbortSignal
  ): Promise<JobSearchResultItem[]> => {
    if (!term) return [];

    const promise = RecommendationClient.client.get(
      `/jobs/search?term=${term}&limit=50`,
      { signal }
    );

    const value = (async () => {
      const res = await promise;
      return res?.data || [];
    })();

    return (await value).slice(0, 100);
  };
}
