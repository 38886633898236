import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { CorporateUniversityRoutingModule } from './corporate-university-routing.module';
import { CorporateUniversityPanelComponent } from './corporate-university-panel/corporate-university-panel.component';
import { CorporateUniversityCoursesComponent } from './corporate-university-courses/corporate-university-courses.component';
import { CorporateUniversityBannerComponent } from './corporate-university-banner/corporate-university-banner.component';
import { CorporateUniversityNavigationComponent } from './corporate-university-navigation/corporate-university-navigation.component';
import { CorporateUniversityCardComponent } from './corporate-university-card/corporate-university-card.component';
import { CorporateUniversityVideosComponent } from './corporate-university-videos/corporate-university-videos.component';
import { CatalogComponent } from './corporate-university-panel/catalog/catalog.component';
import { PendingComponent } from './corporate-university-panel/pending/pending.component';
import { CompleteComponent } from './corporate-university-panel/complete/complete.component';
import { CompletedCourseComponent } from './completed-course/completed-course.component';

@NgModule({
  declarations: [
    CorporateUniversityPanelComponent,
    CorporateUniversityCoursesComponent,
    CorporateUniversityBannerComponent,
    CorporateUniversityNavigationComponent,
    CorporateUniversityCardComponent,
    CorporateUniversityVideosComponent,
    CatalogComponent,
    PendingComponent,
    CompleteComponent,
    CompletedCourseComponent,
  ],
  imports: [CommonModule, CorporateUniversityRoutingModule, SharedModule],
})
export class CorporateUniversityModule {}
