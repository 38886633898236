import {
  FiltersSchema,
  FiltersSchemaValue,
  State,
  StateActions,
} from './interfaces';
import { normalizeValue } from '@/react/utils/normalizeValue';
import { updateUrlQueryParams } from './';

// 'select' and 'checkbox' are the only types that have options,
// so we need to validate the values to make sure they are valid options.
const validations = {
  checkbox: (values, options) => {
    return values.filter((value) =>
      options?.map(({ value }) => value).includes(value)
    );
  },
  select: (values, options) => {
    return values.filter((value) =>
      options?.map(({ value }) => value).includes(value)
    );
  },
  location: (values, options) => {
    const [state, city] = values;

    const validatedState = options?.statesOptions
      .map(({ value }) => value)
      .filter((v) => v === state);
    const citiesOptionsByState = options?.citiesOptionsByState[state] || [];

    const validatedCity = citiesOptionsByState
      ? citiesOptionsByState.map(({ value }) => value).filter((v:string) => 
        normalizeValue(v) === normalizeValue(city))
      : [];

    return [...validatedState, ...validatedCity];
  },
};

export const buildStateActions = ([state, updateState, updateSchema]: [
  State,
  React.Dispatch<React.SetStateAction<State>>,
  React.Dispatch<React.SetStateAction<FiltersSchema>>
]) => {
  const actions: StateActions = {
    getAllValues: () => {
      return Object.entries(state).reduce((acc, [key, field]) => {
        if (field.saveValue && field.values.length > 0) {
          acc[key] = field.values;
        }
        return acc;
      }, {});
    },
    reset: (filterIds) => {
      updateState((prevState: State) => {
        const resetState = Object.entries(prevState).reduce(
          (acc, [key, field]) => {
            if (!filterIds.includes(key)) {
              return { ...acc, [key]: field };
            }

            acc[key] = {
              ...field,
              touched: false,
              values: [],
            };

            updateUrlQueryParams(key, '');

            return acc;
          },
          {}
        );

        return resetState;
      });
    },
    clearFieldValues: (id) => {
      updateState((prevState: State) => {
        const field = prevState[id];
        const updatedState = { ...field, values: [] };

        updateUrlQueryParams(id, '');

        return {
          ...prevState,
          [id]: updatedState,
        };
      });
    },
    setCheckboxSelectionById: (id, value) => {
      updateState((prevState: State) => {
        const field = prevState[id];
        const updatedState = { ...field, touched: true };

        const add = !updatedState.values.includes(value);

        if (add) {
          updatedState.values = [...field.values, value];
        } else {
          updatedState.values = field.values.filter((v) => v !== value);
        }

        updateUrlQueryParams(id, updatedState.values.join(','));

        return {
          ...prevState,
          [id]: updatedState,
        };
      });
    },
    setValueById: (id, value) => {
      updateState((prevState: State) => {
        const field = prevState[id];
        const values = value
          ? Array.isArray(value)
            ? [...value]
            : [value]
          : [];
        const updatedState = { ...field, touched: true, values };

        updateUrlQueryParams(id, updatedState.values.join(','));

        return {
          ...prevState,
          [id]: updatedState,
        };
      });
    },
    setPage: (page) => {
      updateState((prevState: State) => {
        const field = prevState.page;
        const updatedState = { ...field, values: [page], touched: true };

        updateUrlQueryParams('page', page.toString());

        return {
          ...prevState,
          page: updatedState,
        };
      });
    },
    setSchema: (schema) => {
      updateSchema(schema);
    },
    toggleById: (id, bool) => {
      updateState((prevState: State) => {
        const field = prevState[id];

        const updatedState = { ...field, touched: true };

        updatedState.values = bool ? [bool] : [];

        updateUrlQueryParams(id, updatedState.values.join(','));

        return {
          ...prevState,
          [id]: updatedState,
        };
      });
    },
  };

  return actions;
};

const buildStateNode = ({
  filter,
  filterInitialValues,
}: {
  filter: FiltersSchemaValue;
  filterInitialValues: string[];
}) => {
  const validatedValues = [];
  const initialValues = filterInitialValues || filter.props?.defaultValues;

  if (initialValues) {
    if (filter.type === 'checkbox') {
      const validValues = validations.checkbox(
        initialValues,
        filter.props.options
      );
      validatedValues.push(...validValues);
    } else if (filter.type === 'select') {
      const validValues = validations.select(
        initialValues,
        filter.props.options
      );
      validatedValues.push(...validValues);
    } else if (filter.type === 'location') {
      const validValues = validations.location(
        initialValues,
        filter.props.options
      );
      validatedValues.push(...validValues);
    } else {
      validatedValues.push(...initialValues);
    }
  }

  return {
    ...filter,
    errors: [],
    touched: false,
    values: validatedValues,
  };
};

export function buildStateFromSchema({
  initialValues = {},
  schema,
}: {
  initialValues: Partial<FiltersSchemaValue>;
  schema: FiltersSchema;
}) {
  // reduce filters to object with id as key
  const state = schema.reduce((acc, filter) => {
    acc[filter.id] = buildStateNode({
      filter,
      filterInitialValues: initialValues[filter.id],
    });
    return acc;
  }, {});

  return { schema, state };
}
