import { MediaService } from 'src/app/services/media.service';

export default class Player {
  static instance: Player;

  containerElement: HTMLDivElement;
  iframeElement: HTMLIFrameElement;
  eventEmitters = [];
  callbackMap: { [key: string]: any[] } = {};

  readyPromise;
  _onMessage;
  origin: string = '*';

  static getInstance() {
    if (!Player.instance) {
      Player.instance = new Player();
    }
    return Player.instance;
  }

  private constructor() {
    this.readyPromise = new Promise((resolve, reject) => {
      this._onMessage = (event) => {
        if (this.iframeElement?.contentWindow !== event.source) {
          return;
        }

        const data = this.parseMessageData(event.data);
        if (data.event === 'ready') {
          this.createListeners();
        }
        const isError = data && data.event === 'error';
        const isReadyError =
          isError && data.data && data.data.method === 'ready';

        if (isReadyError) {
          const error = new Error(data.data.message);
          error.name = data.data.name;
          reject(error);
          return;
        }

        const isReadyEvent = data && data.event === 'ready';
        const isPingResponse = data && data.method === 'ping';

        if (isReadyEvent || isPingResponse) {
          this.containerElement.setAttribute('data-ready', 'true');
          resolve(null);
          return;
        }
        this.processData(data);
      };
    });
    window.addEventListener('message', this._onMessage);
  }

  private parseMessageData(data: any) {
    if (typeof data === 'string') {
      try {
        data = JSON.parse(data);
      } catch (error) {
        return {};
      }
    }

    return data;
  }

  private getCallbacks(key: string) {
    return this.callbackMap[key];
  }

  private processData(data) {
    let callbacks = [];
    let param;

    if (data.event) {
      if (data.event === 'error') {
        const promises = this.getCallbacks(data.data.method);

        promises.forEach((promise) => {
          const error = new Error(data.data.message);
          error.name = data.data.name;

          promise.reject(error);
        });
      }

      callbacks = this.getCallbacks(`event:${data.event}`);

      param = data.data;
    } else if (data.method) {
      const callback = this.callbackMap[data.method];
      if (callback) {
        callbacks.push(callback);
        param = data.value;
      }
    }

    callbacks.forEach((callback) => {
      try {
        if (typeof callback === 'function') {
          callback(param);
          return;
        }

        callback.resolve(param);
      } catch (e) {
        console.error(e);
      }
    });
  }

  private createEmbed(html): HTMLIFrameElement {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.firstChild as HTMLIFrameElement;
  }

  private createListeners() {
    Object.keys(this.callbackMap).forEach((key) => {
      const splited = key.split('event:');
      if (splited.length === 2) {
        this.postMessage('addEventListener', splited[1]);
      }
    });
  }

  async loadVideo(
    containerElement: HTMLDivElement,
    lessonId: number | string,
    mediaService: MediaService
  ) {
    this.containerElement = containerElement;

    const vimeoUrl = await mediaService.getVimeoUrl(lessonId);

    const html = `<iframe src="${vimeoUrl}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%" title="Aula1.mp4"></iframe>`;

    const newIframe: HTMLIFrameElement = this.createEmbed(html);

    if (this.containerElement.firstChild) {
      containerElement.removeChild(this.containerElement.firstChild);
    }

    this.iframeElement = newIframe;
    containerElement.appendChild(newIframe);

    try {
      await this.readyPromise;
    } catch (e) {
      console.error('Error on loading', e);
    }
  }

  destroy() {
    window.removeEventListener('message', this._onMessage);
    Player.instance = null;
  }

  private postMessage(method, params?) {
    if (
      !this.iframeElement.contentWindow ||
      !this.iframeElement.contentWindow.postMessage
    ) {
      return;
    }

    let message: any = {
      method,
    };

    if (params) {
      message.value = params;
    }

    const ieVersion = parseFloat(
      navigator.userAgent.toLowerCase().replace(/^.*msie (\d+).*$/, '$1')
    );
    if (ieVersion >= 8 && ieVersion < 10) {
      message = JSON.stringify(message);
    }

    this.iframeElement.contentWindow.postMessage(message, this.origin);
  }

  private storeCallback(name: string, callback: any) {
    if (!this.callbackMap[name]) {
      this.callbackMap[name] = [];
    }

    this.callbackMap[name].push(callback);
  }

  on(eventName: string, callback: Function) {
    this.storeCallback(`event:${eventName}`, callback);
  }
}
