import { Component, OnInit } from '@angular/core';
import type { ComponentProps } from 'react';
import { default as ReactAppFooter } from '@/react/components/footer';

@Component({
  selector: 'component-react-appfooter',
  templateUrl: './appfooter.component.html',
})
export class ReactAppFooterComponent implements OnInit {
  ReactAppFooter = ReactAppFooter;
  reactAppFooterProps: ComponentProps<typeof ReactAppFooter> = {};

  constructor() {}
  ngOnInit() {}
}
