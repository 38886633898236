import bankJson from 'src/app/data/banks.json';

const banks = bankJson.reduce((acc, bank) => {
  const bankOptions = {
    label: bank.label,
    value: bank.value,
  };

  acc.push(bankOptions);

  return acc;
}, []);

export const banksSelectOptions = {
  banks,
};
