import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-table-pagination',
  templateUrl: './table-pagination.component.html',
  styleUrls: ['./table-pagination.component.scss'],
})
export class TablePaginationComponent implements OnInit {
  @Input()
  currentPage: number;

  @Input()
  maxPages: number;

  @Output()
  next = new EventEmitter();

  @Output()
  previous = new EventEmitter();

  @Output()
  goto = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  previousPage() {
    this.previous.emit();
  }

  nextPage() {
    this.next.emit();
  }

  goToPage(pageNumber: number) {
    this.goto.emit(pageNumber);
  }
}
