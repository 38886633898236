<component-react-appbar></component-react-appbar>

<div #scrollableContainer class="scrollable-container">
  <div #pageContainer class="page-container">
    <router-outlet></router-outlet>
  </div>

  <component-react-appfooter></component-react-appfooter>
</div>

<app-eduplay-modal
  *ngIf="eduplayModal"
  [eduplayUrl]="eduplayUrl()"
  (onClose)="onCloseModal()"
>
</app-eduplay-modal>

<app-dependents
  *ngIf="modalDependent"
  (close)="closeModalDependent()"
></app-dependents>

<app-leroy-modal *ngIf="checkLeroyModal()" (close)="closeLeroyModal()">
</app-leroy-modal>

<react-global-fragment></react-global-fragment>
