import * as React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

interface CustomProps {
  onChange: (event: { target: { name: string; value: number | null } }) => void;
  value: number | null;
  name: string;
}

export const NumberField = React.forwardRef<NumericFormatProps, CustomProps>(
  (props, ref) => {
    const { name, onChange, value, ...inputProps } = props;

    return (
      <NumericFormat
        {...inputProps}
        allowNegative={false}
        defaultValue={value}
        decimalScale={0}
        getInputRef={ref}
        onValueChange={(values) => {
          const { formattedValue } = values;
          const value = formattedValue ? Number.parseInt(formattedValue) : null;

          onChange({
            target: {
              name,
              value,
            },
          });
        }}
        valueIsNumericString
        value={value}
      />
    );
  }
);
