<app-refund-receipt-navigation
  menu="receipts-approval"
></app-refund-receipt-navigation>

<app-table-default
  *ngIf="!isLoading"
  [tablePages]="tablePages"
  [data]="pendingRefunds"
  [salvarButton]="salvarButton"
  (save)="onSave($event)"
></app-table-default>

<component-skeleton-card *ngIf="isLoading"></component-skeleton-card>
