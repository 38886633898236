import { CreateActivity } from '@/react/components/idp/create-activity';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentProps } from 'react';

@Component({
  selector: 'app-create-activity',
  templateUrl: './create-activity.component.html',
})
export class IdpCreateActivityComponent {
  IdpCreateActivityComponent = CreateActivity;
  createActivityProps: ComponentProps<typeof CreateActivity> = {
    planId: undefined,
  };

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.createActivityProps = {
        planId: params.id,
      };
    });
  }
}
