import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const breakpoints = {
  xl: { alias: 'xl', level: 3 },
  lg: { alias: 'lg', level: 2 },
  md: { alias: 'md', level: 1 },
  sm: { alias: 'sm', level: 0 },
} as const;

export function useScreenSizes() {
  const theme = useTheme();

  const upXL = useMediaQuery(theme.breakpoints.up('xl'));
  const upLG = useMediaQuery(theme.breakpoints.up('lg'));
  const upMD = useMediaQuery(theme.breakpoints.up('md'));

  const breakpoint = ((): keyof typeof breakpoints => {
    if (upXL) return 'xl';
    if (upLG) return 'lg';
    if (upMD) return 'md';
    return 'sm';
  })();

  const isSmallScreen = breakpoint === 'sm';
  const isMediumScreen = breakpoint === 'md';
  const isLargeScreen = breakpoint === 'lg';
  const isXLargeScreen = breakpoint === 'xl';

  const desktopSize = breakpoints[breakpoint].level > breakpoints.sm.level;
  const notDesktopSize = !desktopSize;

  return {
    isSmallScreen,
    isLargeScreen,
    isMediumScreen,
    isXLargeScreen,
    breakpoint: breakpoints[breakpoint],
    breakpoints,
    desktopSize,
    notDesktopSize,
  };
}
