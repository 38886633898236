import React from 'react';
import { styled } from '@mui/material/styles';
import { ResourceCourseSources } from '@/react/components/refund/refund-request/interfaces';
import { WizardStepIds } from '@/react/components/refund/refund-request/description-step/interfaces';
import { useWizard } from '@/react/components/refund/wizard/state/useWizard';
import { Button, Skeleton, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import StepActions from '@/react/components/refund/wizard/step-actions.component';
import { useRefundableAmount } from '@/react/data/subsidy/useRefundableAmount';
import { ResourceApproval } from '@/react/data/subsidy/interfaces';
import { useRefundRequest } from '@/react/components/refund/refund-request/state/useRefundRequest';

const Styled = {
  Button: styled(Button)(({ theme: { palette, spacing } }) => ({
    alignItems: 'flex-start',
    backgroundColor: palette.neutral[100],
    border: `1px solid ${palette.neutral[300]}`,
    borderRadius: 0,
    color: palette.neutral[800],
    display: 'flex',
    flexDirection: 'column',
    marginBottom: spacing(2),
    padding: spacing(3),
    width: '100%',

    '&.active': {
      backgroundColor: palette.primary[100],
      border: `1px solid ${palette.primary[900]}`,
      color: palette.neutral[900],
    },
  })),
};

const stepId = WizardStepIds.CATALOG_FORM;

const ResourceTypeForm = () => {
  const { actions, values: wizardValues } = useWizard();
  const [showAll, setShowAll] = React.useState(false);
  const { isFetching, availableRefundableList } = useRefundableAmount();
  const { subsidyPolicy } = useRefundRequest();
  const [selected, setSelected] = React.useState(null);

  const visibleItems = showAll
    ? availableRefundableList
    : availableRefundableList.slice(0, 3);

  const handleSelection = (
    source: ResourceCourseSources,
    availableRefundable?: ResourceApproval
  ) => {
    setSelected(availableRefundable || source);
    if (availableRefundable) {
      actions.updateValues({ [stepId]: availableRefundable });
    } else {
      actions.updateValues({ [stepId]: source });
    }
  };

  const handleNext = async () => {
    if (selected && selected !== ResourceCourseSources.CATALOG) {
      actions.updateValues({ subsidyPolicy, selected });
      window.location.href = `/meus-reembolsos/solicitacao/${selected.uuid}/comprovante`;
    } else {
      actions.navigate(WizardStepIds.CATALOG_FORM);
    }
  };

  const setActiveClass = (item) => {
    return selected === item ? 'active' : '';
  };

  return (
    <div data-testid={WizardStepIds.RESOURCE_TYPE}>
      <Typography variant="h6" color="primary.900" mb={2}>
        Selecione uma opção para sua solicitação:
      </Typography>
      <Styled.Button
        className={setActiveClass(ResourceCourseSources.CATALOG)}
        onClick={() => handleSelection(ResourceCourseSources.CATALOG)}
      >
        <Typography variant="body3" fontWeight={600}>
          Novo item
        </Typography>
      </Styled.Button>
      {availableRefundableList.length !== 0 && isFetching && (
        <>
          <Skeleton variant="rectangular" height={68} />
        </>
      )}
      {availableRefundableList.length > 0 &&
        visibleItems.map((availableRefundable: ResourceApproval) => (
          <React.Fragment key={availableRefundable?.uuid}>
            <Typography
              variant="body3"
              fontWeight={600}
              color="primary.900"
              mb={2}
            >
              Itens aprovados
            </Typography>
            <Styled.Button
              className={setActiveClass(availableRefundable)}
              onClick={() =>
                handleSelection(
                  ResourceCourseSources.CATALOG,
                  availableRefundable
                )
              }
            >
              <Typography variant="body3" fontWeight={600}>
                {availableRefundable?.name}
              </Typography>
            </Styled.Button>
            {availableRefundableList.length > 3 && !showAll ? (
              <Button
                variant="text"
                onClick={() => setShowAll(true)}
                sx={({ palette }) => ({
                  borderRadius: 0,
                  alignSelf: 'flex-end',
                  textDecoration: 'underline',
                  ':hover': {
                    textDecoration: 'underline',
                    color: palette.primary[900],
                    backgroundColor: palette.primary[300],
                  },
                })}
              >
                {`Ver todos (${availableRefundableList.length})`}
              </Button>
            ) : (
              <Button
                variant="text"
                onClick={() => setShowAll(false)}
                sx={({ palette }) => ({
                  borderRadius: 0,
                  alignSelf: 'flex-end',
                  textDecoration: 'underline',
                  ':hover': {
                    textDecoration: 'underline',
                    color: palette.primary[900],
                    backgroundColor: palette.primary[300],
                  },
                })}
              >
                {`Ver menos`}
              </Button>
            )}
          </React.Fragment>
        ))}
      <StepActions>
        <LoadingButton
          color="primary"
          onClick={() => {
            window.location.href = '/meus-reembolsos';
          }}
          variant="text"
        >
          Voltar
        </LoadingButton>
        <LoadingButton
          color="primary"
          disabled={!selected}
          onClick={handleNext}
          type="submit"
          variant="contained"
        >
          Próximo
        </LoadingButton>
      </StepActions>
    </div>
  );
};

export default ResourceTypeForm;
