import { ColaboradorRoutes } from '@/pages/colaborador/colaborador.routes';
import { PlansService } from '@/services/api/plans.service';
import { RouterService } from '@/services/router.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { getSvg } from '@/utils/helpers';
import { goToDependents } from '@/layout/layout.component';

@Component({
  selector: 'app-dependents',
  templateUrl: './dependents.component.html',
  styleUrls: ['./dependents.component.scss'],
})
export class DependentsComponent implements OnInit {
  @Output()
  close = new EventEmitter();

  getSvg = getSvg;
  constructor(
    private plansService: PlansService,
    private routerService: RouterService
  ) {}

  ngOnInit(): void {}

  closeModal() {
    this.close.emit();
  }

  onDependent() {
    if (this.plansService.isLoaded) {
      this.resolveDependentRedirect(this.plansService.plan);
    } else {
      this.plansService.planChange.subscribe(() => {
        this.resolveDependentRedirect(this.plansService.plan);
      });
    }

    this.closeModal();
  }

  resolveDependentRedirect(plan) {
    if (plan?.features.includes('dependents_included')) {
      return this.routerService.navigate([ColaboradorRoutes.MANAGE_DEPENDENTS]);
    }

    goToDependents();
  }
}
