import axios, { AxiosInstance } from 'axios';
import { constants } from '@/react/constants';

import { getHeaders } from '@/utils/apiHeaders';

const instance = axios.create({
  baseURL: constants.API_ENDPOINT,
  headers: getHeaders(),
});

export default withAuthorization(instance);

export function withZodErrorHandling(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      // express-zod-api returns error in a nested object
      // (response.data.data). We use axios interceptors
      // return a simpler error object.
      const response = error.response?.data?.data || error;
      return Promise.reject(response);
    }
  );

  return axiosInstance;
}

export function withAuthorization(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.request.use((request) => {
    const headers = getHeaders();
    const isLogged = headers['Authorization'];

    if (isLogged) {
      Object.entries(headers).forEach(([key, value]) => {
        request.headers.set(key, value);
      });
    } else {
      const isLoginUrl = request.url?.match('/login');

      if (!isLoginUrl) {
        const destination = '/';
        if (window.location.pathname !== destination) {
          window.location.href = destination;
        }
      }
    }

    return request;
  });

  return axiosInstance;
}
