import React from 'react';
import Box from '@mui/material/Box';
import { Icon, Link, Stack, Typography } from '@mui/material';
import footerContent from './footer-content';

type FooterColumnProps = {
  title: string;
  links: {
    name: string;
    href: string;
  }[]
};

const FooterColumn = (props: FooterColumnProps) => {
  const { title, links } = props;
  return (
    <Box sx={{ pt: { xs: 3, sm: 0 }, textAlign: { sm: 'center', md: 'left' }}}>
      <Typography variant='body3' sx={{ fontWeight: 'bold', mb: 1}}>
        {title}
      </Typography>
      <Box sx={{ 
        display: 'flex',
        flexDirection: { xs: 'column', xl: 'row'},
        rowGap: 1,
        columnGap: 2,
      }}>
        {links.map((link) => (
          <Link 
            color='primary.main'
            href={link.href} 
            key={link.href}
            target='_blank'
            rel='noopener'
            > 
              <Typography variant='body3'>{link.name}</Typography>
          </Link>
        ))}
      </Box>
    </Box>
  )
};

const Footer = () => {
  return (
    <Box bgcolor='neutral.50'>
      <Stack 
        direction={{ xs: 'column', sm: 'row' }}
        sx={{ 
          p: 2, 
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          margin: 'auto',
          maxWidth:'xl',
          width: '90%',
        }}
      >
        <FooterColumn {...footerContent.help} />
        <FooterColumn {...footerContent.terms} />
        <Box sx={{
          mt: { xs: 4, md: 0},
          mb: 2,
          width: { sm: 1, md: 'auto' },
          textAlign: { sm: 'center', md: 'left' }
        }}>
          <Typography fontSize='small'> {footerContent.version} </Typography>
          <Box display='flex' justifyContent={{ sm: 'center', md: 'flex-start' }} mt={0.5}>
            <Icon sx={{ 
              fontSize: 'medium', 
              marginTop: { xs: 0.25, md: 1, lg: 0.25 },
              marginRight: 1,
            }}>
              copyright
            </Icon>
            <Box sx={{ display:'flex', flexDirection: { md: 'column', lg: 'row' }}}>
              <Typography variant='body3'> {footerContent.name} </Typography>
              <Typography variant='body3' display={{ md: 'none', lg: 'inline' }} mr={0.5} ml={0.5}> | </Typography>
              <Typography variant='body3'> {footerContent.copy} </Typography>
            </Box>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default Footer;