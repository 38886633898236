<app-banner header="{{ getTranslation('header') | translate }}"></app-banner>

<div class="mx-4">
  <div class="container">
    <div class="row mt-3">
      <div class="col-md-3 col-sm-12 my-4 text-center">
        <app-card>
          <p class="BodyText-2">Carga horária</p>
          <p class="BodyText-2">(total)</p>
          <p class="pt-2 Subtitle-2">{{ sumTotalHours() }} horas</p>
        </app-card>
      </div>
      <div class="destaque col-md-3 col-sm-12 my-4 text-center">
        <app-card>
          <p class="BodyText-2 destaque">Carga horária</p>
          <p class="BodyText-2 destaque">(cursos concluídos)</p>
          <p class="pt-2 Subtitle-2 destaque">
            {{ sumCompletedHours() }} horas
          </p>
        </app-card>
      </div>
      <div class="col-md-3 col-sm-12 my-4 text-center">
        <app-card>
          <p class="BodyText-2">Total de matrículas</p>
          <p class="BodyText-2">(realizadas)</p>
          <p class="pt-2 Subtitle-2">{{ sumTotalSubscriptions() }}</p>
        </app-card>
      </div>
      <div class="col-md-3 col-sm-12 my-4 text-center">
        <app-card>
          <p class="BodyText-2 destaque">Total de matrículas</p>
          <p class="BodyText-2 destaque">(cursos concluídos)</p>
          <p class="pt-2 Subtitle-2 destaque">
            {{ sumCompletedSubscriptions() }}
          </p>
        </app-card>
      </div>
    </div>

    <div class="row mt-3 align-items-center">
      <div class="col-md-3 my-1">
        <component-select
          [value]="statusFilter"
          [options]="statusOptions"
          label="{{ getTranslation('labelStatus') | translate }}"
          (valueChange)="onStatusSelected($event)"
        ></component-select>
      </div>

      <div *ngIf="statusFilter.value === 1" class="col-md-4 my-1">
        <component-input
          [(ngModel)]="courseFilter"
          label="{{ getTranslation('labelBusqueCurso') | translate }}"
        ></component-input>
      </div>

      <div *ngIf="statusFilter.value === 2" class="col-md-4 my-1">
        <component-input
          [(ngModel)]="colaboradorFilter"
          label="{{ getTranslation('labelBusqueColaborador') | translate }}"
        ></component-input>
      </div>

      <div *ngIf="statusFilter.value === 3" class="col-md-4 my-1">
        <component-date-picker
          label="{{ getTranslation('labelPeriodo') | translate }}"
          (onChange)="onDateSelected($event)"
        ></component-date-picker>
      </div>

      <div *ngIf="statusFilter.value !== 0" class="col-md-3 mt-auto mb-1">
        <div class="d-flex">
          <component-botao (click)="onBuscar()" type="primary">
            {{ getTranslation('btnBuscar') | translate }}
          </component-botao>

          <component-botao class="ml-2" (click)="onLimpar()" type="secondary">
            {{ getTranslation('btnLimpar') | translate }}
          </component-botao>
        </div>
      </div>

      <div class="ml-auto d-flex justify-content-end col-md-2 mt-auto mb-1">
        <component-botao (onClick)="exportData()" type="secondary">
          {{
            getTranslation(
              statusFilter.value !== 0 ? 'btnExportarSelection' : 'btnExportar'
            ) | translate
          }}
        </component-botao>
      </div>
    </div>
  </div>

  <div class="mb-4">
    <app-table-default
      [tablePages]="tablePages"
      [data]="displayCorporateCourses"
      [hideSaveButton]="true"
    ></app-table-default>
  </div>
</div>
