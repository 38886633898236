import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// icons
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';

import { CatalogCourse } from '@/react/data/catalog/interfaces';
import CourseCardLink from '@/react/components/course-card-link.component';
import { formatDuration } from '@/react/utils/time';

import { Price, FullDiscountChip, PartialDiscountChip } from './components';

const CardContentChip = styled(Chip)((ownerState) => {
  return {
    borderWidth: 0,
    '& .MuiChip-icon': {
      ...(ownerState.size === 'small' && {
        marginLeft: 0,
        marginRight: -1,
      }),
    },
  };
});

const CardTag = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.success[700],
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  color: theme.palette.neutral[50],
  display: 'flex',
  height: 24,
  justifyContent: 'center',
  padding: theme.spacing('0', 1),
  width: '100%',
}));

const InstitutionLogo = styled('img')(() => ({
  height: 70,
  width: 180,
}));

const CardPrices = ({
  discountPercentage,
  installments,
  offersQuantity,
  originalPrice,
  priceType,
  priceWithDiscount,
}) => {
  const DiscountChip =
    discountPercentage === 100 ? FullDiscountChip : PartialDiscountChip;

  const formattedDiscount = () => {
    const discountLabel =
      discountPercentage === 100 || offersQuantity === 1
        ? Math.floor(discountPercentage)
        : `até ${Math.floor(discountPercentage)}`;

    return <span>{discountLabel}% OFF</span>;
  };

  const hasKnownPrice = React.useMemo(() => {
    return !!(
      priceType !== 'upon_request' &&
      priceWithDiscount &&
      /\d+/.test(`${priceWithDiscount}`)
    );
  }, [priceWithDiscount]);

  const originalPriceValue = installments && priceType === 'installments'
    ? originalPrice / installments
    : originalPrice;

  return (
    <Box
      sx={{
        marginTop: 'auto',
      }}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'end',
          marginTop: theme.spacing(2),
        })}
      >
        <Box sx={{ flex: 1 }}>
          {hasKnownPrice && offersQuantity > 1 && (
            <Typography component="div" variant="caption">
              A partir de
            </Typography>
          )}
          <Typography component="div" variant="h6">
            <Price
              installments={installments}
              priceType={priceType}
              value={priceWithDiscount}
            />
          </Typography>
          {discountPercentage > 0 && priceType !== 'upon_request' && (
            <Typography
              component="div"
              variant="caption"
              sx={(theme) => ({
                textDecoration: 'line-through',
                '& *': {
                  fontWeight: theme.typography.fontWeightRegular, // override bold from Price component
                },
              })}
            >
              <Price
                installments={installments}
                priceType={priceType}
                value={originalPriceValue}
              />
            </Typography>
          )}
        </Box>
        {discountPercentage > 0 && priceType !== 'upon_request' && (
          <DiscountChip label={formattedDiscount()} size="small" />
        )}
      </Box>
    </Box>
  );
};

const CardInfo = ({
  courseName,
  courseType,
  duration,
  institutionName,
  modalities,
  offersQuantity,
}) => {
  const formattedDuration = () => {
    const { type, quantity } = duration;

    const localizedDuration = formatDuration(type, quantity);
    const humanizedValue =
      offersQuantity > 1 ? `até ${localizedDuration}` : `${localizedDuration}`;

    return humanizedValue;
  };

  return (
    <>
      <Stack alignItems="start" spacing={0.5}>
        <Typography
          variant="caption"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          {institutionName}
        </Typography>
        <Typography variant="h6">{courseName}</Typography>
      </Stack>
      <Stack alignItems="start" spacing={0.5} mt={2}>
        <CardContentChip
          icon={<AccessTimeIcon />}
          label={formattedDuration()}
          size="small"
          variant="outlined"
        />
        <CardContentChip
          icon={<LocationOnIcon />}
          label={ modalities.map(modality => modality.label).join(', ') }
          size="small"
          variant="outlined"
        />
        <CardContentChip
          icon={<SchoolOutlinedIcon />}
          label={courseType.label}
          size="small"
          variant="outlined"
        />
      </Stack>
    </>
  );
};

const buildProductPageSearchParams = (
  urlParams: Record<string, string>
): string => {
  const productPageSearchParams = new URLSearchParams();

  Object.entries(urlParams).forEach(([key, value]) => {
    productPageSearchParams.set(key, value);
  });

  const decodedParams = decodeURIComponent(productPageSearchParams.toString());
  return decodedParams ? `?${decodedParams}` : '';
};

const buildProductPageLink = ({
  courseId,
  urlParams,
}: {
  courseId: number;
  urlParams: string;
}): string => {
  const productPage = `/cursos/${courseId}`;

  return `${productPage}${urlParams}`;
};

const CourseCard = ({
  data,
  ignoreShowcaseMargin = false,
  showcase = false,
  urlParams,
}: {
  data: CatalogCourse;
  ignoreShowcaseMargin?: boolean;
  showcase?: boolean;
  urlParams?: Record<string, string>;
}) => {
  const producPageUrlParams = {} as Record<string, string>;

  if (data.offer_state && data.offer_city)
    producPageUrlParams.location = `${data.offer_state},${data.offer_city}`;
  if (data.offer_hub) producPageUrlParams.hub = `${data.offer_hub}`;
  if (data.offer_period) producPageUrlParams.period = `${data.offer_period}`;

  const processedUrlParams = buildProductPageSearchParams({
    ...urlParams,
    ...producPageUrlParams,
  });

  const productPageLink = buildProductPageLink({
    courseId: data.id,
    urlParams: processedUrlParams,
  });

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {showcase && (
        <CardTag>
          <Typography
            textAlign="center"
            variant="caption"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: '100%',
            }}
          >
            EM DESTAQUE
          </Typography>
        </CardTag>
      )}
      <Card
        variant="outlined"
        sx={(theme) => ({
          borderColor: showcase ? theme.palette.success[700] : null,
          borderTopLeftRadius: showcase ? 0 : theme.shape.borderRadius,
          borderTopRightRadius: showcase ? 0 : theme.shape.borderRadius,
          height: '100%',
          marginTop: {
            xs: '0',
            sm: showcase || ignoreShowcaseMargin ? '0' : theme.spacing(3),
          },
        })}
      >
        <CardActionArea
          component={CourseCardLink}
          href={productPageLink}
          course={data}
          sx={{
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'flex-start',
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: data.institution.logo_background_color,
              display: 'flex',
              flex: '0 0 96px',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <InstitutionLogo src={data.institution.logo_url} />
          </Box>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              minWidth: 0,
              width: '100%',
            }}
          >
            <CardInfo
              courseName={data.course_name}
              courseType={data.course_type}
              duration={data.duration}
              institutionName={data.institution.name}
              modalities={data.modalities}
              offersQuantity={data.offers_quantity}
            />
            <CardPrices
              discountPercentage={data.discount_percentage}
              installments={data.installments}
              offersQuantity={data.offers_quantity}
              originalPrice={data.original_price}
              priceType={data.price_type}
              priceWithDiscount={data.price_with_discount}
            />
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
};

export default CourseCard;
