import * as React from 'react';
import { styled } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { useFilters } from '@/react/components/filters/state';

const Styled = {
  TextField: styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.neutral[50],
    },
  })),
};

export const FilterLocation = ({
  id,
  options,
  title,
  type,
}: {
  id: string;
  options: {
    statesOptions: { label: string; value: string }[];
    citiesOptionsByState: { [K: string]: { name: string; value: string }[] };
  };
  title?: string;
  type: string;
}) => {
  const { actions, state } = useFilters();

  const fieldState = state[id];
  const [stateValue = '', cityValue = ''] = fieldState.values;
  const { statesOptions, citiesOptionsByState } = options;
  const citiesOptions = citiesOptionsByState[stateValue] || [];

  const handleChangeState = (_, option, reason) => {
    if (reason === 'clear') {
      actions.clearFieldValues(id);
    } else {
      const value = option?.value;
      actions.setValueById(id, [value, null]);
    }

    actions.setPage(1);
  };

  const handleChangeCity = (_, option) => {
    const value = option?.value;
    actions.setValueById(id, [stateValue, value]);
    actions.setPage(1);
    actions.reset(['hub', 'period'])
  };

  const selectedState =
    statesOptions.find((option) => option.value === stateValue) || null;

  const selectedCity =
    citiesOptions.find((option) => option.value === cityValue) || null;

  return (
    <FormControl data-filter-type={type}>
      {title && <FormLabel>{title}</FormLabel>}
      <Stack spacing={1}>
        <Autocomplete
          id="state"
          options={statesOptions}
          fullWidth
          onChange={handleChangeState}
          getOptionKey={(option) => option.value}
          renderInput={(params) => (
            <Styled.TextField {...params} placeholder="Estado" />
          )}
          renderOption={(props, option) => (
            <MenuItem {...props} dense>
              <Typography variant="body2">{option.label}</Typography>
            </MenuItem>
          )}
          size="small"
          value={selectedState}
        />
        <Autocomplete
          disabled={!stateValue}
          id="city"
          options={citiesOptions}
          fullWidth
          onChange={handleChangeCity}
          getOptionKey={(option) => option.value}
          renderInput={(params) => (
            <Styled.TextField {...params} placeholder="Cidade" />
          )}
          renderOption={(props, option) => (
            <MenuItem {...props} dense>
              <Typography variant="body2">{option.label}</Typography>
            </MenuItem>
          )}
          size="small"
          value={selectedCity}
        />
      </Stack>
    </FormControl>
  );
};
