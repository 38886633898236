import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React from 'react';
import { CatalogClient } from '@/react/data/catalog/catalog-client';
import { Institution, ProductCourse } from './interfaces';

interface UseCourseDetailsResult {
  data: ProductCourse | undefined;
  error: AxiosError['response'] | undefined;
  institution: Institution | undefined;
  isFetching: boolean;
}

export function useCourseDetails(id: number): UseCourseDetailsResult {
  const result = useQuery({
    queryKey: ['useCourseDetails', id],
    refetchOnMount: false,
    queryFn: async () => {
      return CatalogClient.getCourseById(id);
    },
  });

  return React.useMemo(() => {
    const error = result.error as AxiosError;
    const data = result.data?.data;

    return {
      data: data?.course,
      error: error?.response,
      institution: data?.institution,
      isFetching: result.isFetching,
    };
  }, [result.data, result.isFetching]);
}
