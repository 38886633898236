import React from 'react';
import WarningAmberSharpIcon from '@mui/icons-material/WarningAmberSharp';
import ErrorOutlineSharpIcon from '@mui/icons-material/ErrorOutlineSharp';
import TaskAltSharpIcon from '@mui/icons-material/TaskAltSharp';
import { toast } from 'react-toastify';

export const toastWarning = ({ messageToast, handleCallback = () => {} }) => {
  toast.warning(messageToast, {
    position: 'top-center',
    autoClose: 2000,
    style: {
      backgroundColor: '#fffaeb',
      color: '#665216',
    },
    icon: <WarningAmberSharpIcon sx={{ color: '#FFC107' }} />,
    onClose: () => {
      handleCallback();
    },
  });
};

export const toastSuccess = ({ messageToast, handleCallback = () => {} }) => {
  toast.success(messageToast, {
    position: 'top-center',
    autoClose: 2000,
    style: {
      backgroundColor: '#EdF7Ed',
      color: '#1E4620',
    },
    icon: <TaskAltSharpIcon sx={{ color: '#2F9C60' }} />,
    onClose: () => {
      handleCallback();
    },
  });
};

export const toastError = ({ messageToast, handleCallback = () => {} }) => {
  toast.error(messageToast, {
    position: 'top-center',
    autoClose: 3000,
    style: {
      backgroundColor: '#fceaf2',
      color: '#5c1432',
    },
    icon: <ErrorOutlineSharpIcon sx={{ color: '#E3005F' }} />,
    onClose: () => {
      handleCallback();
    },
  });
};
