import * as React from 'react';

import { useFilters } from '@/react/components/filters/state';

const ignoreFilterKeys = ['page', 'sort'];

export const AppliedFiltersCounter = () => {
  const { values } = useFilters();

  const filteredValues = { ...values };
  ignoreFilterKeys.forEach((field) => {
    delete filteredValues[field];
  });

  let appliedFilters = Object.entries(filteredValues).reduce(
    (acc, [_, values]) => {
      const nonBlankValues = values.filter(Boolean);
      return [...acc, ...nonBlankValues];
    },
    []
  );

  const counterText = appliedFilters.length
    ? ` (${appliedFilters.length})`
    : '';

  return <>{counterText}</>;
};
