<div class="input-container BodyText-1">
  <button
    (click)="onClicked()"
    (keydown.enter)="onClicked()"
    class="input-radio"
    [ngClass]="{ checked: checked }"
  >
    <span class="marker">&nbsp; </span>
  </button>

  <ng-content></ng-content>
</div>
