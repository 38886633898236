import React, { useState, useRef } from 'react';
import {
  Button,
  IconButton,
  Typography,
  Grid,
  FormHelperText,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

interface FileUploadProps {
  onFilesChange: (files: any) => void;
  required?: boolean;
  error: boolean;
  helperText: any;
  id: string;
  defaultFiles?: File[];
}

const FileUpload: React.FC<FileUploadProps> = ({
  onFilesChange,
  required = false,
  error,
  helperText,
  id,
  defaultFiles = [],
}) => {
  const [files, setFiles] = useState<File[]>(defaultFiles);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    setFiles(defaultFiles);
  }, [defaultFiles]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files || []);
    const updatedFiles = [...files, ...selectedFiles];
    setFiles(updatedFiles);
    onFilesChange(updatedFiles);
    event.target.value = null;
  };

  const handleRemoveFile = (index: number) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
    onFilesChange(updatedFiles);
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div>
      <input
        accept="image/*,.pdf"
        style={{ display: 'none' }}
        id={id}
        multiple
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      <Button
        variant="contained"
        component="span"
        startIcon={<CloudUploadIcon />}
        color={error ? 'error' : 'primary'}
        onClick={handleButtonClick}
      >
        {required
          ? 'Selecionar Arquivos (Obrigatório)'
          : 'Selecionar Arquivos (Opcional)'}
      </Button>

      <Grid container spacing={2} style={{ marginTop: 16 }}>
        {files.map((file, index) => {
          const fileSizeInMB = file.size / 1024 ** 2; // Convertendo para MB
          const isFileTooLarge = fileSizeInMB > 2; // Verificando se é maior que 2MB
          const fileName = file instanceof File ? file.name : file; // Se o arquivo vier do backend (string)

          return (
            <Grid item key={index} xs={12} style={{ paddingTop: 5 }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  paddingTop: 0,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    flexWrap: 'nowrap',
                    paddingTop: 0,
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{ flexGrow: 1, paddingTop: 0 }}
                  >
                    {fileName}
                  </Typography>
                  <IconButton
                    color="primary"
                    onClick={() => handleRemoveFile(index)}
                    style={{
                      marginLeft: 'auto',
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </div>
                {isFileTooLarge && (
                  <Typography variant="caption" color="error">
                    (Arquivo excede 2MB e não pode ser anexado)
                  </Typography>
                )}
              </div>
            </Grid>
          );
        })}
      </Grid>

      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </div>
  );
};

export default FileUpload;
