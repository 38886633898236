import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { useFilters } from '@/react/components/filters/state';
import { Typography } from '@mui/material';

const Styled = {
  TextField: styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.neutral[50],

      '& input[type=number]': {
        appearance: 'textfield',
      },
      '& input[type=number]::-webkit-outer-spin-button': {
        appearance: 'none',
        margin: 0,
      },
      '& input[type=number]::-webkit-inner-spin-button': {
        appearance: 'none',
        margin: 0,
      },
    },
  })),
};

const validations = {
  min: (minValue, maxValue) =>
    !minValue ||
    (!maxValue && !!minValue) ||
    (!!maxValue && +minValue < +maxValue),
  max: (minValue, maxValue) =>
    !maxValue ||
    (!minValue && !!maxValue) ||
    (!!minValue && +minValue < +maxValue),
};

export const FilterPriceRange = ({
  id,
  title,
  subtitle,
  type,
}: {
  id: string;
  title?: string;
  subtitle?: string;
  type: string;
}) => {
  const { actions, state } = useFilters();
  const filter = state[id];
  const stateValues = filter.values;
  const [[minValue = '', maxValue = ''], setValue] =
    React.useState(stateValues);

  const handleChangeMin = (e) => {
    const newMinValue = e.target.value;

    setValue([newMinValue, maxValue]);
  };

  const handleChangeMax = (e) => {
    const newMaxValue = e.target.value;

    setValue([minValue, newMaxValue]);
  };

  const handleApplyFilter = () => {
    const value = !minValue && !maxValue ? [] : [minValue, maxValue];

    actions.setValueById(id, value);
    actions.setPage(1);
  };

  const invalid = React.useMemo(
    () =>
      Object.values(validations).some(
        (validate) => !validate(minValue, maxValue)
      ),
    [minValue, maxValue]
  );

  return (
    <FormControl data-filter-type={type}>
      {title && <FormLabel>{title}</FormLabel>}
      {subtitle && (
        <Typography variant="caption" mt={-1} mb={1}>
          {subtitle}
        </Typography>
      )}
      <Stack direction="row" spacing={2}>
        <Stack direction="row" spacing={1} flex={1}>
          <Styled.TextField
            error={!validations.max(minValue, maxValue)}
            fullWidth
            inputProps={{ min: 0 }}
            onChange={handleChangeMax}
            placeholder="Máx"
            size="small"
            type="number"
            value={maxValue}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography variant="caption">R$</Typography>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <Button
          disabled={invalid}
          onClick={handleApplyFilter}
          size="small"
          variant="contained"
        >
          Aplicar
        </Button>
      </Stack>
    </FormControl>
  );
};
