import React from 'react';
import { Context } from './context';
import { GoogleTagManager } from '@/libs/googleTagManager';

export function useGtm(): GoogleTagManager {
  const gtm = React.useContext(Context);

  if (!gtm) {
    throw new Error('useGtm must be used within a GtmContext');
  }

  return gtm;
}
