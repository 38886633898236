import { Component, OnInit } from '@angular/core';
import Hotjar from '@hotjar/browser';
import { TranslationService } from '@/services/translation.service';
import { UsersService } from '@/services/users.service';
import { initializeSentry } from '@/config/sentry';
import { environment } from 'src/environments/environment.development';
import { initializePurpleMetrics } from '@/utils/purple-metrics';

declare let gtag: (...args) => void;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Galena';

  constructor(
    private translate: TranslationService,
    private usersService: UsersService
  ) {}

  ngOnInit() {
    this.translate.translation();

    if (environment.env === 'production') {
      this.initializeHotjar();
      this.initializeClarity();
      this.initializeSiteBlindado();
    }

    initializePurpleMetrics();

    if (environment.env !== 'development') {
      initializeSentry();
    }
  }

  initializeHotjar() {
    const hotjarVersion = 6;

    Hotjar.init(HOTJAR_SITE_ID, hotjarVersion);

    this.usersService.loggedIn.subscribe((user) => {
      const company = user.empresa();

      Hotjar.identify(String(user.id), {
        company_id: company.id,
        company_name: company.empresa,
      });
    });
  }

  initializeClarity() {
    ((c, l, a, r, i, t, y) => {
      c[a] =
        c[a] ||
        (() => {
          (c[a].q = c[a].q || []).push(arguments);
        });
      t = l.createElement(r);
      t.async = 1;
      t.src = 'https://www.clarity.ms/tag/' + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, 'clarity', 'script', 'dyvi2afqnz');
  }

  initializeSiteBlindado() {
    document.head.appendChild(document.createElement('script')).src =
      'https://cdn.siteblindado.com/aw.js';
  }
}
