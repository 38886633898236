import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as Sentry from '@sentry/browser';

import { useManageDependents } from './manage-dependents.context';
import { restClient } from '@/react/utils/fetch';

const ResetDependentPasswordDialog = ({ dependent, open, toggleOpen }) => {
  const [loading, setLoading] = React.useState(false);
  const { dispatch } = useManageDependents();

  const handlePasswordReset = async () => {
    setLoading(true);

    try {
      await restClient.post(`/dependents/${dependent.id}/reset-password`);
      dispatch({
        type: 'OPEN_ALERT',
        payload: {
          message: `Reenviamos um novo convite para: '${dependent.user.email}', com os dados de acesso à plataforma.`,
          severity: 'success',
        },
      });
      toggleOpen();
    } catch (error) {
      dispatch({
        type: 'OPEN_ALERT',
        payload: {
          message: `Erro ao redefinir senha.`,
          severity: 'error',
        },
      });
      setLoading(false);
      Sentry.captureException(error);
    }

    setLoading(false);
  };

  return (
    <Dialog onClose={toggleOpen} fullWidth open={open}>
      <DialogTitle>Redefinir senha</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography gutterBottom variant="body2">
            Seu dependente ainda não se cadastrou na Galena ou precisa de uma
            nova senha?
          </Typography>
          <Typography variant="body2">
            Enviaremos um novo convite para:{' '}
            <strong>'{dependent.user.email}'</strong>, com os dados de acesso à
            plataforma.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleOpen} variant="contained" color="tertiary">
          Cancelar
        </Button>
        <LoadingButton
          loading={loading}
          onClick={handlePasswordReset}
          type="submit"
          variant="contained"
        >
          Redefinir senha
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ResetDependentPasswordDialog;
