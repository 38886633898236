import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IdpClient } from '@/react/data/idp/idp-client';
import * as Sentry from '@sentry/browser';

import { SectionHeader } from '../shared/section-header.component';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { createActivitySchema } from '@/react/data/idp/schemas';
import {
  CreateActivityInterface,
  PlanStatuses,
} from '@/react/data/idp/interfaces';

const navigateBack = (planId: string) => {
  window.location.href = `/pdi/planos/${planId}`;
};

export const CreateActivity = ({ planId }) => {
  const [plan, setPlan] = useState(null);

  const fetchPlan = async () => {
    try {
      const fetchedPlan = await IdpClient.showPlan(planId);
      if (fetchedPlan.status === PlanStatuses.CLOSED) {
        window.location.href = `/pdi/planos/${planId}`;
      }
      setPlan(fetchedPlan);
    } catch (error) {
      Sentry.captureException(error);
      window.location.href = '/pdi/planos';
    }
  };

  const { control, handleSubmit } = useForm({
    resolver: zodResolver(createActivitySchema),
    defaultValues: {
      title: '',
      description: '',
      course_link: '',
    },
  });

  const handleActivityForm = handleSubmit(
    async (data: CreateActivityInterface) => {
      try {
        await IdpClient.createActivity(planId, data);
        navigateBack(planId);
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    (errors) => {
      console.error('Errors', errors);
    }
  );

  useEffect(() => {
    fetchPlan();
  }, []);

  return (
    plan && (
      <Grid container spacing={2} padding={3}>
        <Grid item xs={12}>
          <SectionHeader
            title="Nova atividade"
            onBackClicked={() => navigateBack(plan.id)}
          />
        </Grid>
        <Grid item xs={12}>
          <Box paddingY={2}>
            <Divider sx={{ opacity: 0.6 }} flexItem />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="title"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <FormControl fullWidth>
                  <FormLabel htmlFor="title">Nome da atividade</FormLabel>
                  <TextField
                    {...field}
                    id="title"
                    placeholder="[Título da atividade pré-preenchida]"
                    variant="outlined"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="description"
            control={control}
            render={({ field }) => {
              return (
                <FormControl fullWidth>
                  <FormLabel htmlFor="description">
                    Descrição (opcional)
                  </FormLabel>
                  <TextField
                    {...field}
                    id="description"
                    placeholder="[Descrição da atividade]"
                    variant="outlined"
                    multiline
                  />
                </FormControl>
              );
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="course_link"
            control={control}
            render={({ field }) => {
              return (
                <FormControl fullWidth>
                  <FormLabel htmlFor="course_link">
                    Link do curso (opcional)
                  </FormLabel>
                  <TextField
                    {...field}
                    id="course_link"
                    placeholder="Link do curso"
                    variant="outlined"
                  />
                </FormControl>
              );
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Box paddingTop={2}>
            <Divider sx={{ opacity: 0.6 }} flexItem />
          </Box>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Button onClick={handleActivityForm} variant="contained">
            Adicionar atividade
          </Button>
        </Grid>
      </Grid>
    )
  );
};
