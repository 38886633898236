<button
  (click)="onCollapse($event)"
  class="collapsible-button"
  [ngClass]="{ expanded: expanded }"
>
  <span>
    {{ label }}
  </span>
  <span class="material-icons-outlined notranslate arrow-icon">
    expand_more
  </span>
</button>
<div #container class="collapsible-container">
  <ng-content></ng-content>
</div>
