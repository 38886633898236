import { Injectable, EventEmitter } from '@angular/core';

import { BreadCrumb } from './router.service';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  simplified: boolean;
  breadcrumbs: BreadCrumb[];

  constructor() {}

  onLoading = new EventEmitter();
  onError = new EventEmitter<string>();
  onSuccess = new EventEmitter<string>();
  onSidebarCollapse = new EventEmitter<boolean>();
  onFreemiumModalChange = new EventEmitter<boolean>();
  onOpenModalDependent = new EventEmitter<void>();
  onScrollContainerTop = new EventEmitter<void>();
  onBreadcrumbUpdate = new EventEmitter<BreadCrumb[]>();
  onLeroyFlagDisplay = new EventEmitter<boolean>();
  onPaymentTopbar = new EventEmitter<boolean>();

  loading(loading: boolean) {
    this.onLoading.emit(loading);
  }

  stopLoading() {
    this.onLoading.emit(false);
  }

  error(mensagem: string) {
    this.onError.emit(mensagem);
  }

  success(mensagem: string) {
    this.onSuccess.emit(mensagem);
  }

  freemiumModal(display: boolean) {
    this.onFreemiumModalChange.emit(display);
  }

  openDependentModal() {
    this.onOpenModalDependent.emit();
  }

  updateBreadCrumb(breadCrumbs: BreadCrumb[]) {
    this.breadcrumbs = breadCrumbs;
    this.onBreadcrumbUpdate.emit(this.breadcrumbs);
  }

  displayPaymentTopbar(shouldDisplay: boolean) {
    this.onPaymentTopbar.emit(shouldDisplay);
  }
}
