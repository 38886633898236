import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LayoutComponent } from './layout/layout.component';
import { UserGuard } from './services/guards/user.guard';
import { CorporateUniverstyRoutes as CorporateUniversityRoutes } from './pages/corporate-university/corporate-university.routes';
import { Routes as DevRoutes } from './pages/dev/dev.routes';
import { Routes as CursosRoutes } from './pages/cursos/cursos.routes';
import { TermsGuard } from './services/guards/terms.guard';
import { RoutePaths as IdpRoutes } from './pages/idp/idp-routing.module';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((p) => p.LoginModule),
  },

  {
    path: 'landing_pages',
    loadChildren: () =>
      import('./pages/landing-pages/landing-pages.module').then(
        (p) => p.LandingPagesModule
      ),
  },
  {
    path: 'promos',
    loadChildren: () =>
      import('./pages/promos/promos.module').then((p) => p.PromosModule),
  },

  {
    path: '',
    component: LayoutComponent,
    canActivate: [UserGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/colaborador/colaborador.module').then(
            (p) => p.ColaboradorModule
          ),
      },
      {
        path: 'parcerias',
        loadChildren: () =>
          import('./pages/parcerias/parcerias.module').then(
            (p) => p.ParceriasModule
          ),
      },
      {
        path: IdpRoutes.BASE,
        loadChildren: () =>
          import('./pages/idp/idp.module').then((p) => p.IdpModule),
      },
      {
        path: DevRoutes.ROOT,
        loadChildren: () =>
          import('./pages/dev/dev.module').then((p) => p.DevModule),
      },

      {
        path: '',
        canActivate: [TermsGuard],
        children: [
          {
            path: CorporateUniversityRoutes.CORPORATE_UNIVERSITY,
            loadChildren: () =>
              import(
                './pages/corporate-university/corporate-university.module'
              ).then((p) => p.CorporateUniversityModule),
          },
          {
            path: 'empresa',
            loadChildren: () =>
              import('./pages/empresa/empresa.module').then(
                (p) => p.EmpresaModule
              ),
          },
          {
            path: CursosRoutes.CURSOS,
            loadChildren: () =>
              import('./pages/cursos/cursos.module').then(
                (p) => p.CursosModule
              ),
          },
          {
            path: 'eduplay',
            loadChildren: () =>
              import('./pages/eduplay/eduplay.module').then(
                (p) => p.EduplayModule
              ),
          },
        ],
      },
    ],
  },

  {
    path: 'cpflogin',
    redirectTo: '/login',
  },

  {
    path: '**',
    redirectTo: '/cursos',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
