import React from 'react';
import { Box, Typography } from '@mui/material';
import { IdpDialogBody } from './styles';
import { ConfirmDialog } from '@/react/components/dialog/confirm-dialog.component';

export interface ModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  image?: string;
  title: string;
  subtitle: string;
  description: string;
  actionLabels: {
    confirm: string;
    cancel: string;
  };
}

export const IdpDialog = ({
  open,
  onClose,
  onConfirm,
  title,
  subtitle,
  description,
  image,
  actionLabels,
}: ModalProps) => {
  return (
    <ConfirmDialog
      onClose={onClose}
      onConfirm={onConfirm}
      open={open}
      actionLabels={actionLabels}
    >
      <IdpDialogBody>
        <Box
          component="img"
          src={image}
          sx={{ height: 128, width: 128, margin: '0 auto 8px' }}
        />
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {subtitle}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {description}
        </Typography>
      </IdpDialogBody>
    </ConfirmDialog>
  );
};
