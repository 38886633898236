import * as React from 'react';
import { formatDate } from '@/react/utils/date';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputBase from '@mui/material/InputBase';

import { DataGrid } from '@mui/x-data-grid';

import EmployeerMenu from './employee-menu.component';

import { Button, FormControl, Grid, InputLabel } from '@mui/material';

import { restClient } from '@/react/utils/fetch';

const SearchWrapper = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.common.white,
  borderColor: theme.palette.neutral[300],
  borderRadius: theme.spacing(8),
  borderStyle: 'solid',
  borderWidth: 1,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    fontSize: theme.typography.body3.fontSize,
    padding: theme.spacing(1, 0, 1, 2),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '32ch',
    },
  },
}));

const customLocaleText = {
  columnMenuFilter: 'Filtrar',
  columnMenuSelectAll: 'Selecionar todos',
  columnMenuUnselectAll: 'Desmarcar todos',
  columnMenuItemsPerPage: 'Itens por página:',
  columnMenuSortAsc: 'Ordenar crescente',
  columnMenuSortDesc: 'Ordenar decrescente',
  columnMenuHideColumn: 'Ocultar coluna',
  columnMenuManageColumn: 'Gerenciar colunas',
  noRowsLabel: 'Nenhum usuário encontrado',
};

const CustomChip = styled(Chip)({
  '&.Inativo': {
    backgroundColor: '#E3FAFF',
    color: '#004959',
  },
  '&.Cadastrado': {
    backgroundColor: '#E3F7EC',
    color: '#0F331F',
  },
  '&.Excluido': {
    backgroundColor: '#FFEFF5',
    color: '#65002B',
  },
});

const fetchDataSet = async ({
  companyID,
  pageState,
  searchTerm = false,
  searchSelectedStatus = false,
  export_csv = false,
}) => {
  const urlSearchParam = {
    company_id: companyID,
    page: pageState.page,
    limit: pageState.pageSize,
    searchTerm: searchTerm,
    searchSelectedStatus: searchSelectedStatus,
    export_csv,
  };

  const res = await restClient.get(
    `/employees?${new URLSearchParams(urlSearchParam)}`
  );

  return res.data?.data?.employees || [];
};

const EmployeeList = ({
  updateEmployee,
  companyIDByUSer,
  companyIdSelected,
}) => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [columnFilterSelected, setColumnFilterSelected] = React.useState('all');
  const companyID = companyIdSelected ? companyIdSelected : companyIDByUSer;

  const statusUser = (user) => {
    if (user?.deleted_at !== null) {
      return 'Excluido';
    }

    if (user?.bloqueado === 'true') {
      return 'Bloqueado';
    }

    return 'Cadastrado';
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Nome',
      width: 350,
      valueGetter: (params) => {
        return params.row.nome;
      },
    },
    {
      field: 'email',
      headerName: 'E-mail',
      width: 350,
      valueGetter: (params) => {
        return params.row.email;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => (
        <CustomChip
          label={statusUser(params.row)}
          className={statusUser(params.row)}
        />
      ),
    },
    {
      field: 'last_login',
      headerName: 'Último login',
      description: 'último acesso',
      sortable: false,
      width: 150,
      align: 'center',
      headerAlign: 'left',
      valueGetter: (params) => {
        if (params.row.ultimo_acesso === null) {
          return '--';
        }
        return formatDate(params.row.ultimo_acesso) || '--';
      },
    },
    {
      field: 'user',
      headerName: 'Ação',
      sortable: false,
      renderCell: (params) => (
        <EmployeerMenu
          employeer={params.row}
          onUpdateEmployee={updateEmployee}
        />
      ),
      width: 50,
    },
  ];

  const [pageState, setPageState] = React.useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });

  React.useEffect(() => {
    if (!companyID) {
      return;
    }

    const fetchData = async () => {
      setPageState((old) => ({ ...old, isLoading: true }));
      const response = await fetchDataSet({ companyID, pageState });
      const { count, rows } = response;
      setColumnFilterSelected('all');
      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: rows || [],
        total: count,
      }));
    };
    fetchData();
  }, [companyID, pageState.page, pageState.pageSize]);

  const handleSearch = async () => {
    setPageState((old) => ({ ...old, isLoading: true }));
    const response = await fetchDataSet({ companyID, pageState, searchTerm });
    const { count, rows } = response;
    setColumnFilterSelected('all');
    setPageState((old) => ({
      ...old,
      isLoading: false,
      data: rows || [],
      total: count,
    }));
  };

  const handleStatusFilterChange = async (event) => {
    const searchSelectedStatus = event.target.value;
    setColumnFilterSelected(searchSelectedStatus);

    setPageState((old) => ({ ...old, isLoading: true }));
    const response = await fetchDataSet({
      companyID,
      pageState,
      searchSelectedStatus,
    });
    const { count, rows } = response;
    setPageState((old) => ({
      ...old,
      isLoading: false,
      data: rows || [],
      total: count,
    }));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      handleSearch();
    }
  };

  return (
    <>
      {pageState.isLoading && <LinearProgress />}

      {
        <Grid container spacing={2} my={2} alignItems="center">
          <Grid
            item
            sm={8}
            xs={12}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <SearchWrapper sx={{ marginRight: '20px' }}>
                <StyledInputBase
                  placeholder="Pesquise por nome, email ou CPF"
                  inputProps={{ 'aria-label': 'search' }}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={handleKeyDown}
                  value={searchTerm}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="search" onClick={handleSearch}>
                        <SearchIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </SearchWrapper>

              <Button
                color="primary"
                size="small"
                variant="contained"
                onClick={handleSearch}
                sx={{ marginRight: '10px' }}
              >
                Pesquisar
              </Button>

              <Button
                color="primary"
                href="/empresa/dashboard"
                size="small"
                variant="outlined"
              >
                Acessar relatório
              </Button>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <div
              style={{
                display: 'flex',
              }}
            >
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="select-status-label">Status</InputLabel>
                <Select
                  labelId="select-status-label"
                  id="select-status"
                  label="Status"
                  value={columnFilterSelected}
                  onChange={handleStatusFilterChange}
                >
                  <MenuItem value="all" selected={true}>
                    Todos
                  </MenuItem>
                  <MenuItem value="registered">Cadastrados</MenuItem>
                  <MenuItem value="deleted">Excluídos</MenuItem>
                </Select>
              </FormControl>
            </div>
          </Grid>
        </Grid>
      }

      <Box sx={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={pageState.data}
          rowCount={pageState.total}
          loading={pageState.isLoading}
          pagination
          page={pageState.page - 1}
          pageSize={pageState.pageSize}
          paginationMode="server"
          rowsPerPageOptions={[]}
          onPaginationModelChange={(newPage) => {
            setPageState((old) => ({ ...old, page: newPage.page + 1 }));
          }}
          onRowSelectionModelChange={(newPageSize) =>
            setPageState((old) => ({ ...old, pageSize: newPageSize }))
          }
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          localeText={customLocaleText}
          columns={columns}
          disableRowSelectionOnClick
        />
      </Box>
    </>
  );
};

export default EmployeeList;
