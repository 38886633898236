import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { EduplayModalComponent } from './campaigns/eduplay-modal/eduplay-modal.component';
import { DependentsComponent } from './campaigns/dependents/dependents.component';
import { LeroyModalComponent } from './leroy-modal/leroy-modal.component';
import { ReactAppbarComponent } from './appbar/appbar.component';
import { ReactGlobalFragmentComponent } from '@/layout/react-global-fragment/react-global-fragment';
import { ReactAppFooterComponent } from './appfooter/appfooter.component';

@NgModule({
  declarations: [
    LayoutComponent,
    ReactAppFooterComponent,
    EduplayModalComponent,
    DependentsComponent,
    LeroyModalComponent,
    ReactAppbarComponent,
    ReactGlobalFragmentComponent,
  ],
  imports: [CommonModule, SharedModule, FormsModule, RouterModule],
})
export class LayoutNewModule {}
