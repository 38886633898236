import { useQuery } from '@tanstack/react-query';
import { RefundOrderResponse } from '@/react/data/subsidy/interfaces';
import { RefundUuids } from '@/react/components/refund/refund-request/interfaces';
import { SubsidyClient } from './subsidy-client';

export function useRefundOrder(requestParams: RefundUuids) {
  const result = useQuery<RefundOrderResponse>({
    // This query should be disabled if the resource approval uuid is not present.
    // This means that the refund order is not created yet.
    enabled: !!requestParams.resourceApprovalUuid,
    queryKey: ['useRefundOrder', requestParams],
    queryFn: async ({ signal }) => {
      return SubsidyClient.getRefundRequest({ requestParams, signal });
    },
    refetchOnMount: false,
  });

  const refundOrder = result.data?.resource_approval || null;

  return {
    error: !!result.error,
    isFetching: result.isFetching,
    refundOrder,
  };
}
