import { Component, OnInit } from '@angular/core';

import { getAsset } from 'src/app/utils/helpers';
import { CONST } from 'src/app/constants';
import { Course } from 'src/app/models-new/media/course.model';
import { CorporateUniverstyRoutes } from 'src/app/pages/corporate-university/corporate-university.routes';
import { MediaService } from 'src/app/services/media.service';
import { RouterService } from 'src/app/services/router.service';
import { LayoutService } from 'src/app/services/layout.service';

@Component({
  selector: 'app-pending',
  templateUrl: './pending.component.html',
  styleUrls: ['./pending.component.scss'],
})
export class PendingComponent implements OnInit {
  cursos: Course[] = [];

  defaultImageUrl = `${CONST.API_ENDPOINT}/contents/eduplay/course_placeholder.png`;

  getAsset = getAsset;

  constructor(
    private routerService: RouterService,
    private mediaService: MediaService,
    private layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.layoutService.updateBreadCrumb([
      {
        label: 'Home',
        route: ['/'],
      },
      {
        label: 'Meu aprendizado',
      },
      {
        label: 'Cursos em andamento',
        route: [
          CorporateUniverstyRoutes.CORPORATE_UNIVERSITY,
          CorporateUniverstyRoutes.PENDING,
        ],
      },
    ]);
    this.loadPendingCourses();
  }

  async loadPendingCourses() {
    this.cursos = await this.mediaService.getOngoingCourses();
  }

  getProgresso(course: Course) {
    return course.progress;
  }

  getCourseName(course: Course) {
    return course.name;
  }

  getImagemCurso(curso: Course): string {
    return `${CONST.API_ENDPOINT}/contents/eduplay/${curso.image}`;
  }

  onImageError(element) {
    element.target.src = this.defaultImageUrl;
  }

  onCatalog() {
    this.routerService.navigate([
      CorporateUniverstyRoutes.CORPORATE_UNIVERSITY,
      CorporateUniverstyRoutes.CATALOG,
    ]);
  }

  onAcessar(course: Course) {
    this.routerService.navigate([
      CorporateUniverstyRoutes.CORPORATE_UNIVERSITY,
      CorporateUniverstyRoutes.COURSES,
      course.id,
    ]);
  }

  getTranslation(key: string): string {
    return `CORPORATE_UNIVERSITY.CORPORATE_UNIVERSITY_PANEL.PENDING.${key}`;
  }
}
