export class Permissao {
  id: number;
  nome: string;

  static TYPES = {
    ADMIN: 'Admin',
    FINANCIAL: 'Financial',
    MANAGER: 'Manager',
    HR: 'H.R.',
    REFUND_REQUEST_MANAGER: 'refund_request_manager',
  };
}
