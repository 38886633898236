import { CourseProgress } from 'src/app/models-new/media/course-progress';
import { DbCourse, DbCourseProgress } from './../models-new/media/course.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CONST } from '../constants';

import { Trail } from 'src/app/models-new/media/trails.model';
import { Course } from 'src/app/models-new/media/course.model';
import { DbLessonProgress, Lesson } from '../models-new/media/lesson.model';
import { downloadFile } from '../utils/download-file';

import { getHeaders } from '@/utils/apiHeaders';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  constructor(private http: HttpClient) {}

  private getQueryParams(queryParams: { [key: string]: string }): string {
    const query = Object.keys(queryParams)
      .map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(
          queryParams[key]
        )}`;
      })
      .join('&');

    return `?${query}`;
  }

  private get(
    route: string,
    queryParams?: { [key: string]: string },
    options: any = {}
  ): Observable<any> {
    const query = queryParams ? this.getQueryParams(queryParams) : '';
    return this.http.get(`${CONST.NEW_MEDIA_ENDPOINT}/${route}${query}`, {
      ...options,
      headers: getHeaders(),
    });
  }

  private patch(route: string, body: any = {}): Observable<any> {
    return this.http.patch(`${CONST.NEW_MEDIA_ENDPOINT}/${route}`, body, {
      headers: getHeaders(),
    });
  }

  private post(route: string, body: any = {}): Observable<any> {
    return this.http.post(`${CONST.NEW_MEDIA_ENDPOINT}/${route}`, body, {
      headers: getHeaders(),
    });
  }

  private postFile(route: string, body: any = {}) {
    return this.http.post(`${CONST.NEW_MEDIA_ENDPOINT}/${route}`, body, {
      headers: getHeaders(),
      responseType: 'blob',
    });
  }

  getTrails(): Promise<Trail[]> {
    return new Promise((resolve, reject) => {
      this.get('trails').subscribe((response) => {
        resolve(response.map(Trail.getFromDb));
      }, reject);
    });
  }

  getCoursesByTrail(id: string): Promise<Trail> {
    return new Promise((resolve, reject) => {
      this.get(`trails/${id}`).subscribe((response) => {
        resolve(Trail.getFromDb(response));
      }, reject);
    });
  }

  updateLessonProgress(
    lessonId: string,
    percent: number
  ): Promise<DbLessonProgress> {
    return new Promise((resolve, reject) => {
      this.patch(`lessons/${lessonId}/update_progress`, {
        progress: percent,
      }).subscribe((response) => {
        resolve(response);
      }, reject);
    });
  }

  updateCourseProgress(
    courseId: string,
    progress: number
  ): Promise<DbCourseProgress> {
    return new Promise((resolve, reject) => {
      this.patch(`courses/${courseId}/update_progress`, {
        progress,
      }).subscribe((response) => {
        resolve(response);
      }, reject);
    });
  }

  getCourseById(id: string): Promise<Course> {
    return new Promise((resolve, reject) => {
      this.get(`courses/${id}`).subscribe((response) => {
        resolve(Course.getFromDbCourse(response));
      }, reject);
    });
  }

  downloadCertificate(courseId: string) {
    this.postFile(`courses/${courseId}/certificate`).subscribe(
      (response: Blob) => {
        const downloadURL = URL.createObjectURL(response);
        downloadFile(downloadURL, 'certificado.pdf');
      }
    );
  }

  getCompletedCourses(): Promise<Course[]> {
    return new Promise((resolve, reject) => {
      this.get('courses/completed_courses').subscribe((response) => {
        resolve(response.map(Course.getFromDbCourse));
      }, reject);
    });
  }

  getOngoingCourses(): Promise<Course[]> {
    return new Promise((resolve, reject) => {
      this.get('courses/ongoing_courses').subscribe((response) => {
        resolve(response.map(Course.getFromDbCourse));
      }, reject);
    });
  }

  getLMSReport(): Promise<CourseProgress[]> {
    return new Promise((resolve, reject) => {
      this.get(`courses/report`).subscribe((response: any) => {
        resolve(response.map(CourseProgress.getCourseProgressFromDb));
      }, reject);
    });
  }

  getCourseByBackendId(id: number): Promise<DbCourse> {
    return new Promise((resolve, reject) => {
      this.get(`courses/${id}/redirect_course`).subscribe((response: any) => {
        resolve(response);
      }, reject);
    });
  }

  getPdf(lesson: Lesson): Promise<Blob> {
    return new Promise((resolve, reject) => {
      this.get(`lessons/${lesson.id}/file`, undefined, {
        // responseType: 'blob',
      }).subscribe((response: any) => {
        resolve(response);
      }, reject);
    });
  }

  npsModalHalf(courseId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.post(`courses/${courseId}/nps_half`).subscribe(resolve, reject);
    });
  }

  getVimeoUrl(lessonId: number | string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.get(`lessons/${lessonId}/video`).subscribe(
        (response) => resolve(response.url),
        reject
      );
    });
  }
}
