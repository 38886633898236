import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CONST } from 'src/app/constants';
import { getAsset, getSvg } from 'src/app/utils/helpers';

@Component({
  selector: 'app-banner-eduplay',
  templateUrl: './banner-eduplay.component.html',
  styleUrls: ['./banner-eduplay.component.scss'],
})
export class BannerEduplayComponent implements OnInit {
  getAsset = getAsset;

  getSvg = getSvg;

  @Input()
  curso: string;

  @Input()
  logo: boolean = true;

  @Input()
  subtitulo: string;

  @Input()
  label: string;

  @Input()
  loading: boolean = true;

  @Input()
  botao: boolean = false;

  @Output()
  acessar = new EventEmitter();

  @Input()
  disabled: boolean = false;

  @Input()
  icon: string;

  LOGO_EDUPLAY: string = CONST.LOGO_EDUPLAY;

  constructor() {}

  ngOnInit(): void {}

  onAcessar() {
    this.acessar.emit();
  }
}
