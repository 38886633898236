export enum NAVIGATION_ROUTES {
  CATALOG = 'CATALOG',
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
}

export const CorporateUniverstyRoutes = {
  CORPORATE_UNIVERSITY: 'corporate_university',
  CORPORATE_UNIVERSITY_PANEL: 'corporate_university_panel',
  TRAILS: 'trilhas',
  COURSES: 'cursos',
  [NAVIGATION_ROUTES.CATALOG]: 'trilhas',
  [NAVIGATION_ROUTES.PENDING]: 'cursos_andamento',
  [NAVIGATION_ROUTES.COMPLETE]: 'cursos_concluidos',
  COMPLETED_COURSE: 'completed_course',
};
