import * as React from 'react';
import Box from '@mui/material/Box';

import { AccordionStep } from '@/react/components/refund/accordion/interfaces';
import AccordionColumnWrapper from '@/react/components/refund/accordion/accordion-column-wrapper.component';

const AccordionContent = ({
  children,
  status,
}: {
  children?: React.ReactNode;
  status?: AccordionStep['status'];
}) => {
  return (
    <AccordionColumnWrapper>
      {!!status && (
        <Box
          sx={{
            minWidth: ({ spacing }) => spacing(3),
          }}
        ></Box>
      )}
      <Box sx={{ flexGrow: 1 }}>{children}</Box>
    </AccordionColumnWrapper>
  );
};

export default AccordionContent;
