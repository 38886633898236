import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { Refund, RefundStatus, statusMap } from '@/models/refund.model';
import { TranslatedComponent } from '@/utils/translated.component';

@Component({
  selector: 'app-refund-card',
  templateUrl: './refund-card.component.html',
  styleUrls: ['./refund-card.component.scss'],
})
export class CardComponent extends TranslatedComponent implements OnInit {
  translationKey = 'COLABORADOR.MY_REFUNDS.REFUND_CARD';

  @Input()
  refund: Refund;

  @Output()
  onCancel = new EventEmitter<void>();

  @Output()
  onSendInvoice = new EventEmitter<File[]>();

  filesLoaded: File[];
  fileNames: string[];

  ngOnInit(): void {
    this.filesLoaded = [];
    this.fileNames = [];
  }

  getStatus(status: RefundStatus) {
    return `REFUND_STATUS.${statusMap[status]}`;
  }

  onLoadFiles(event) {
    const fileList: FileList = event.target.files;
    for (let i = 0; i < fileList.length; i++) {
      this.filesLoaded.push(fileList[i]);
      this.fileNames.push(fileList[i].name);
    }
  }

  removeFile(fileIndex: number) {
    this.filesLoaded.splice(fileIndex, 1);
    this.fileNames.splice(fileIndex, 1);
  }

  sendInvoices() {
    this.onSendInvoice.emit(this.filesLoaded);
    this.fileNames = [];
  }

  isInvoicePending() {
    return this.refund.status === 'COURSE_APPROVED';
  }

  isCancealable() {
    return (
      this.refund.status === 'COURSE_APPROVED' ||
      this.refund.status === 'COURSE_PENDING'
    );
  }
}
