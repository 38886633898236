import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ApiService } from 'src/app/services/api.service';
import { RouterService } from 'src/app/services/router.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-eduplay-modal',
  templateUrl: './eduplay-modal.component.html',
  styleUrls: ['./eduplay-modal.component.scss'],
})
export class EduplayModalComponent implements OnInit {
  @Input()
  eduplayUrl: string;

  @Output()
  onClose: EventEmitter<void> = new EventEmitter();

  constructor(
    private userService: UsersService,
    private apiService: ApiService,
    private routerService: RouterService
  ) {}

  ngOnInit(): void {}

  eduplay() {
    this.routerService.navigate(['cursos', 'pesquisa'], {
      queryParams: { curso: 'eduplay' },
    });
    this.closeModal();
  }

  closeModal() {
    this.apiService.post('pesquisas/eduplay_modal').subscribe(() => {
      this.userService.user.form_inicial = 7;
      this.onClose.emit();
    });
  }

  getTranslation(key: string): string {
    return `LAYOUT.EDUPLAY_MODAL.${key}`;
  }
}
