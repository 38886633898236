import { Component, OnDestroy, OnInit } from '@angular/core';
import { ComponentProps } from 'react';

import { LoginComponentReact } from '@/react/components/login/login.component';
import {
  displayPurpleMetricsModal,
  hidePurpleMetricsModal,
} from '@/utils/purple-metrics';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit, OnDestroy {
  LoginPageComponent = LoginComponentReact;
  reactLoginPageProps: ComponentProps<typeof LoginComponentReact> = {};

  ngOnInit(): void {
    hidePurpleMetricsModal();
  }

  ngOnDestroy() {
    displayPurpleMetricsModal();
  }
}
