import React, { useState } from 'react';
import { Add } from '@mui/icons-material';
import {
  Button,
  Container,
  Divider,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { RefundListProvider } from '@/react/components/refund/refund-list/state';
import { RefundList } from '@/react/components/refund/refund-list';
import ShowPoliciesComponent from '@/react/components/refund/refund-policies/show-policies.component';

import HowRefundWorksDialog, { localStorageKey } from '@/react/components/refund/how-refund-works-dialog.component';

const ManageRefunds = () => {
  const [openHowRefundWorksDialog, setOpenHowRefundWorksDialog] = useState(false);
    const [showContinueButton, setShowContinueButton] = useState(false);

  const handleShowPolicies = () => {
    window.location.assign('/meus-reembolsos/minhas-politicas');
  };

  const handleOpenModal = (withContinueButton) => {
    setOpenHowRefundWorksDialog(true);
    setShowContinueButton(withContinueButton);
  };

  return (
    <Container>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        style={{ marginTop: '50px' }}
      >
        <Grid item xs={12} sm={8} md={9} marginBottom={1}>
          <Typography gutterBottom color="palette.200" variant="subtitle1">
            Reembolso
          </Typography>
          <Typography color="neutral.800" variant="body3" >
            Crie e gerencie solicitações de reembolso.{' '}
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setOpenHowRefundWorksDialog(true);
                setShowContinueButton(false);
              }}
              variant="body3"
            >
              Veja como funciona
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <ShowPoliciesComponent onclick={handleShowPolicies} />
        </Grid>
      </Grid>
      <Divider sx={{ my: 3 }} />
      <Grid item xs={12} md={3}>
      <Button
        color="primary"
        size="large"
        startIcon={<Add />}
        sx={{ borderRadius: 0, width: { xs: '100%', md: 'auto' } }}
        variant="contained"
        onClick={() => {
          const bypassOpen = !!localStorage.getItem(localStorageKey);
          if (bypassOpen) {
            window.location.assign('/meus-reembolsos/novo');
          } else {
            setOpenHowRefundWorksDialog(true);
            setShowContinueButton(true);
          }
        }}
      >
        Novo Reembolso
      </Button>
      </Grid>
      <Grid item xs={12}>
        <RefundListProvider>
          <RefundList />
        </RefundListProvider>
      </Grid>
      <HowRefundWorksDialog
        open={openHowRefundWorksDialog}
        onClose={() => {
          setOpenHowRefundWorksDialog(false);
          setShowContinueButton(false);
        }}
        showContinueButton={showContinueButton}
      />
    </Container>
  );
};

export default ManageRefunds;
