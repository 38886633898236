<div class=" select-container {{ myId }}">
  <label *ngIf="label" class="BodyText-1-SemiBold">
    {{ label | translate }}
  </label>
  <p *ngIf="description" class="description">
    {{ description | translate }}
  </p>
  <div *ngIf="multioptions && _multivalue.length > 0" class="options-container">
    <span *ngFor="let option of _multivalue; let i = index" class="option-item">
      {{ option.label | translate }}
      <button
        (click)="onRemoveOption(option)"
        class="material-icons-outlined notranslate"
      >
        close
      </button>
    </span>
  </div>
  <p class="error-message" *ngIf="errorMessage && error">
    {{ errorMessage | translate }}
  </p>
  <button
    (click)="onInputClicked()"
    class="input {{ myId }}"
    [ngClass]="{ error: error, disabled: disabled }"
  >
    <span *ngIf="!multioptions && _value" class="label {{ myId }} BodyText-1">
      {{ _value.label | translate }}
    </span>

    <span
      *ngIf="multioptions || !_value"
      class="label placeholder {{ myId }} BodyText-1"
    >
      {{ placeholder | translate }}
    </span>

    <span
      class="material-icons-outlined notranslate {{ myId }}"
      [ngClass]="{ expanded: dropdownDisplay }"
      >arrow_drop_down</span
    >
  </button>

  <div
    *ngIf="dropdownDisplay"
    class="dropdown {{ myId }}"
    (clickOutside)="onClickOutside($event)"
  >
    <span class="nenhum-item {{ myId }}" *ngIf="options.length === 0"
      >Nenhum item disponível</span
    >
    <button
      (click)="optionSelected(option)"
      *ngFor="let option of options"
      class="option {{ myId }}"
    >
      {{ option.label | translate }}
    </button>
  </div>
</div>
