import * as React from 'react';
import Container from '@mui/material/Container';
import { useQuery } from '@tanstack/react-query';
import { constants } from '@/react/constants';

import { CursosSection } from './section.component';
import CourseTypeList from './course-type-list.component';
import CourseTypeListLoading from './course-type-list-loading.component';
import KnowledgeAreaList from './knowledge-area-list.component';
import KnowledgeAreaListLoading from './knowledge-area-list-loading.component';
import { Carousel, CarouselSlide } from '@/react/components/carousel';

import ContentContainer from '@/react/components/layout/content-container.component';
import { restClient } from '@/react/utils/fetch';
import CONST from '@/constants';
import { User } from '@/models/user.model';
import { BootstrapData, CourseType } from './home.interfaces';
import { HomeRecommendations } from './home-recommendations';
import { GtmContext } from '../state/context';
import axios from 'axios';

// FIXME: This should consider production/staging/development envs
const baseUrl = 'https://assets.galena.com/edupass-api/public/home-banners';

const slides: CarouselSlide[] = [
  {
    backgroundColor: '#eef3fa',
    objectFit: 'cover',
    src: `${baseUrl}/banner-1-P.png`,
    link: 'https://plataforma.galena.com/cursos/novo_catalogo?modalities=online&page=1&institutions=4%2C156&utm_source=plataforma&utm_medium=banner-home&utm_campaign=graduacao-ead&utm_term=01',
    target: '_blank',
    md: {
      src: `${baseUrl}/banner-1-M.png`,
    },
    lg: {
      src: `${baseUrl}/banner-1-G.png`,
    },
    title: `Banner promocional dizendo: 'Transforme sua carreira com  Conquer'`,
  },
  {
    backgroundColor: '#eef3fa',
    objectFit: 'cover',
    src: `${baseUrl}/Banner-1-P_20241001.png`,
    link: 'https://plataforma.galena.com/cursos/novo_catalogo?institutions=249&page=1&utm_source=plataforma&utm_medium=banner-home&utm_campaign=conquer&utm_term=01',
    target: '_blank',
    md: {
      src: `${baseUrl}/Banner-1-M_20241001.png`,
    },
    lg: {
      src: `${baseUrl}/Banner-1-G_20241001.png`,
    },
    title: `Banner promocional dizendo: 'Transforme sua carreira com a Conquer'`,
  },
  {
    backgroundColor: '#eef3fa',
    objectFit: 'cover',
    src: `${baseUrl}/Banner 2 - P_20241001.png`,
    link: 'https://promo.galena.com/trilha-de-inteligencia-emocional?utm_source=plataforma&utm_medium=banner-home&utm_campaign=trilha-inteligencia-emocional&utm_term=02',
    target: '_blank',
    md: {
      src: `${baseUrl}/Banner 2 - M_20241001.png`,
    },
    lg: {
      src: `${baseUrl}/Banner 2 - G_20241001.png`,
    },
    title: `Banner promocional dizendo: 'Trilha de inteligência emocional'`,
  },
  {
    backgroundColor: '#eef3fa',
    objectFit: 'cover',
    src: `${baseUrl}/Banner-3-P_20241001.png`,
    link: 'https://plataforma.galena.com/cursos/novo_catalogo?course_types=languages&utm_source=plataforma&utm_medium=banner-home&utm_campaign=idiomas&utm_term=03',
    md: {
      src: `${baseUrl}/Banner-3-M_20241001.png`,
    },
    lg: {
      src: `${baseUrl}/Banner-3-G_20241001.png`,
    },
    title: `Banner promocional dizendo: 'Domine um novo Idioma!'`,
  },
  {
    backgroundColor: '#eef3fa',
    objectFit: 'cover',
    src: `${baseUrl}/Banner-4-P_20241001.png`,
    link: 'https://plataforma.galena.com/cursos/novo_catalogo?utm_source=plataforma&utm_medium=banner-home&utm_campaign=impulsione-sua-carreira&utm_term=04',
    md: {
      src: `${baseUrl}/Banner-4-M_20241001.png`,
    },
    lg: {
      src: `${baseUrl}/Banner-4-G_20241001.png`,
    },
    title: `Banner promocional dizendo: 'Impulsione sua carreira!'`,
  },
];

const CursosHome = (props: { user: User | null }) => {
  const { user } = props;

  const { isPending: isBootstrapDataPending, data: bootstrapData } =
    useQuery<BootstrapData>({
      queryKey: ['bootstrapData'],
      queryFn: () =>
        restClient.get('/users/bootstrap-data').then((res) => res.data.data),
    });

  const { info, plan, taxonomy } = bootstrapData || {};
  const courseTypes = taxonomy?.course_types || [];
  const knowledgeAreas = taxonomy?.knowledge_areas || [];

  const landingPageFeature = plan?.features.filter(
    (item) => item.name === 'unibrad_pos'
  );

  if (landingPageFeature && landingPageFeature.length > 0) {
    axios.get(plan?.promo_landing_page.url, {
      headers: {
        'Authorization': `${plan?.promo_landing_page.token}`,
      }
    })

    slides.unshift({
      backgroundColor: '#CC092F',
      objectFit: 'cover',
      src: `${baseUrl}/banner-unialpha-P.png`,
      link: `${plan?.promo_landing_page.url}`,
      target: '_blank',
      md: {
        src: `${baseUrl}/banner-unialpha-M.png`,
      },
      lg: {
        src: `${baseUrl}/banner-unialpha-G.png`,
      },
      title: `Unibrad - Pós-graduação por R$32,50`,
    });
  }

  const fullIncluded = plan?.features?.some(
    (el) => el.name === 'full_included'
  );

  const customCategory = info?.colaborador.empresa.custom_category;

  const universityCard = ((): CourseType | null => {
    const label = user?.colaborador?.empresa?.corporate_university_name;
    const logo = user?.colaborador?.empresa?.logo;

    return label && logo
      ? {
          label,
          link: '/corporate_university/trilhas',
          image: `${CONST.API_ENDPOINT}/contents/categorias/${logo}`,
          description: '',
          type: 'university',
        }
      : null;
  })();

  const courseTypesWithLink = (() => {
    const cards = [];

    if (landingPageFeature && landingPageFeature.length > 0) {
      cards.unshift({
        description: 'Pós-graduação por R$32,50',
        image: `${baseUrl}/card-unialpha-M.png`,
        label: 'Unibrad',
        link: `${plan?.promo_landing_page.url}`,
        type: 'custom-category',
      });
    }

    if (universityCard) {
      cards.push(universityCard);
    }

    if (customCategory) {
      cards.push({
        description: 'Recomendados pela sua empresa',
        image: `${constants.API_ENDPOINT}/contents/categorias/${info.colaborador.empresa.logo}`,
        label: customCategory,
        link: '/cursos/custom-category',
        type: 'custom-category',
      });
    }

    if (fullIncluded) {
      cards.push({
        label: 'Inclusos no seu plano',
        link: 'cursos/novo_catalogo?feature_full=true',
        type: 'full',
      });
    }

    courseTypes.forEach((courseType) => {
      cards.push({
        ...courseType,
        link: `cursos/novo_catalogo?course_types=${courseType.type}`,
      });
    });

    return cards;
  })();

  const knowledgeAreasWithLink = knowledgeAreas.map((knowledgeArea) => ({
    ...knowledgeArea,
    link: `cursos/novo_catalogo?knowledge_areas=${knowledgeArea.id}`,
  }));

  return (
    <Container fixed maxWidth="xl">
      <ContentContainer>
        <CursosSection>
          <Carousel infinite={slides.length > 1} slides={slides} />
        </CursosSection>

        <GtmContext>
          <HomeRecommendations />
        </GtmContext>

        <CursosSection title="Encontre o curso ideal para você">
          {isBootstrapDataPending && <CourseTypeListLoading />}
          {!isBootstrapDataPending && courseTypesWithLink.length > 0 && (
            <CourseTypeList data={courseTypesWithLink} />
          )}
        </CursosSection>

        <CursosSection title="Explore cursos por Temas">
          {isBootstrapDataPending && <KnowledgeAreaListLoading />}
          {!isBootstrapDataPending && knowledgeAreasWithLink.length > 0 && (
            <KnowledgeAreaList data={knowledgeAreasWithLink} />
          )}
        </CursosSection>
      </ContentContainer>
    </Container>
  );
};

export default CursosHome;
