export const primary = {
  900: '#450645',
  700: '#7E0B80',
  500: '#C933CC',
  300: '#FACDFB',
  200: '#380048',
  100: '#FDEBFD',
};

export const secondary = {
  900: '#020764',
  700: '#0008A3',
  500: '#343DFA',
  300: '#8086FC',
  100: '#D8D9FE',
};

export const red = {
  100: '#FFEFF5',
  500: '#E3005F',
  900: '#65002B',
};

export const yellow = {
  100: '#FFF6E0',
  300: '#FDD986',
  500: '#FFC107',
  600: '#F9FB5E',
  900: '#886400',
};

export const success = {
  100: '#E3F7EC',
  300: '#AAE5C4',
  500: '#2F9C60',
  700: '#1E643D',
  900: '#0F331F',
};

export const black = {
  500: '#414141',
};

export const white = {
  500: '#FFFFFF',
};

export const neutral = {
  50: '#FFFFFF',
  100: '#F8FAFE',
  200: '#F2F2F2',
  300: '#DEDEDE',
  400: '#C0C0C0',
  500: '#939393',
  600: '#737373',
  700: '#595959',
  800: '#414141',
  900: '#333333',
};

export const danger = {
  100: '#FFEFF5',
  300: '#FF85B8',
  500: '#E3005F',
  700: '#9D0042',
  900: '#65002B',
};

export default {
  black,
  danger,
  neutral,
  primary,
  red,
  secondary,
  success,
  white,
  yellow,
};
