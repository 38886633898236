import { RouterService } from './../router.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
} from '@angular/router';
import { UsersService } from '../users.service';
import { ApiService } from '../api.service';
import CONST from '@/constants';

const ssoEndpoints = {
  intercement: true,
};

type CanActivateType = Promise<any>;

@Injectable({
  providedIn: 'root',
})
export class UserGuard implements CanActivate {
  constructor(
    private routerService: RouterService,
    private usuarioService: UsersService,
    private apiService: ApiService
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): CanActivateType {
    const token = localStorage.getItem(CONST.TOKEN_KEY);

    if (!token) {
      // fix infinite redirect on user guard when the
      // user has the old token (named as "token")
      localStorage.removeItem('token');
      localStorage.removeItem('raw_token');
    }

    const { sso_provider, origin } = route.queryParams;

    if (!token) {
      if (sso_provider && ssoEndpoints[sso_provider]) {
        localStorage.setItem('redirect_after_login', state.url.split('?')[0]);
        window.location.href = `${CONST.BACKEND_URL}/sso/${sso_provider}/signin`;
        return;
      }

      if (origin === 'ticket_vantagens') {
        localStorage.setItem('redirect_after_login', state.url.split('?')[0]);
        this.routerService.navigate(['login', 'novos_registros'], {
          queryParams: {
            origin: 'U2FsdGVkX19M063HBhAodQj5ouYrNfl5vNVK2z2jvts%3D',
          },
        });
        return;
      }

      this.routerService.navigate(['login'], {
        queryParams: { redirectUrl: window.location.href },
      });
    } else {
      const user = await this.usuarioService.fetchUser();

      if (user) {
        return true;
      } else {
        localStorage.removeItem(CONST.TOKEN_KEY);
        localStorage.removeItem('token');

        this.routerService.navigate(['login'], {
          queryParams: { redirectUrl: window.location.href },
        });

        return;
      }
    }
  }
}
