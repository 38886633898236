import * as React from 'react';
import { nanoid } from 'nanoid';
import Skeleton from '@mui/material/Skeleton';

import { useScreenSizes } from '@/react/utils/useScreenSizes';
import { CustomGrid } from './knowledge-area-list.component';

const KnowledgeAreaListLoading = () => {
  const { isLargeScreen, isMediumScreen, isSmallScreen, isXLargeScreen } =
    useScreenSizes();

  const numberOfItems = () => {
    if (isSmallScreen) return 2;
    if (isMediumScreen) return 4;
    if (isLargeScreen) return 6;
    if (isXLargeScreen) return 8;
  };

  return (
    <CustomGrid>
      {Array.from(Array(numberOfItems())).map(() => (
        <Skeleton
          sx={{ height: { xs: 50, md: 124 } }}
          animation="wave"
          variant="rectangular"
          key={nanoid()}
        />
      ))}
    </CustomGrid>
  );
};

export default KnowledgeAreaListLoading;
