import { environment } from 'src/environments/environment.development';

export function initializePurpleMetrics() {
  const purpleMetricsScript = document.createElement('script');
  window['_pmSettings'] = {
    companyId: environment.PURPLE_METRICS_COMPANY_ID,
    segment: 290,
    zIndex: 200,
    zIndexClosed: 200,
    isDebug: !environment.production,
  };

  purpleMetricsScript.defer = true;
  purpleMetricsScript.src =
    'https://dashboard.purplemetrics.com.br/widget/js/widget.js';
  purpleMetricsScript.type = 'module';
  document.head.appendChild(purpleMetricsScript);
}

export function hidePurpleMetricsModal() {
  setTimeout(() => {
    const purpleMetrics = document.getElementById('com-purple-metrics');
    if (!purpleMetrics) {
      return hidePurpleMetricsModal();
    }
    purpleMetrics.classList.add('hide');
  }, 100);
}

export function displayPurpleMetricsModal() {
  setTimeout(() => {
    const purpleMetrics = document.getElementById('com-purple-metrics');
    if (!purpleMetrics) {
      displayPurpleMetricsModal();
    }
    purpleMetrics.classList.remove('hide');
  }, 100);
}
