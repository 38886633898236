import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import type { ComponentProps } from 'react';

import ReactRefundRequestComponent from '@/react/components/refund/refund-request';

@Component({
  selector: 'component-refund-request',
  templateUrl: './refund-request.component.html',
})
export class RefundRequestComponent implements OnInit {
  ReactRefundRequestComponent = ReactRefundRequestComponent;
  props: ComponentProps<typeof ReactRefundRequestComponent> = {
    refundRequestUuid: undefined,
    resourceApprovalUuid: undefined,
  };

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    const { refundRequestUuid, resourceApprovalUuid } =
      this.route.snapshot.params;

    this.props = {
      ...this.props,
      refundRequestUuid,
      resourceApprovalUuid,
    };
  }
}
