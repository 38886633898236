import { useQuery } from '@tanstack/react-query';
import { restClient } from '@/react/utils/fetch';
import { BootstrapData } from './interfaces';
import { ms } from 'powership';
import { RecommendationClient } from '@/react/data/recommendation/recommendation-client';
import { Profile } from '@/react/data/recommendation/interfaces';
import { IS_LOCALHOST } from '@/utils/ENV';
import { HomeMainRecommendation } from '@/react/data/bootstrap/recomendation-interfaces';

const DEFAULT_TTL = ms('1min');

export type ResultUseBootstrapData = BootstrapData &
  Partial<Profile> & {
    profile: Partial<Profile>;
    isNewcomer: boolean;
    completed: boolean;
    isGalenaUser: boolean;
    recommendations: HomeMainRecommendation | null;
  };

export function useBootstrapData(ttl = DEFAULT_TTL) {
  const { data } = useQuery<ResultUseBootstrapData>({
    queryKey: ['useBootstrapData_key'],
    refetchOnMount: false,
    staleTime: ttl,
    queryFn: async ({ signal }) => {
      return loadBootstrapData({ signal });
    },
  });

  return data;
}

export type LoadBootstrapDataOptions = {
  signal?: AbortSignal;
  forceResetProfile?: boolean;
  includeRecommendations?: boolean;
  forceProfileForm?: boolean;
};
export async function loadBootstrapData(
  options: LoadBootstrapDataOptions = {}
) {
  const { signal, includeRecommendations } = options;
  const res = await restClient.get('/users/bootstrap-data', { signal });
  const bootstrap: BootstrapData = res.data.data;

  let profile: any = null;

  profile = await RecommendationClient.getProfile(options);

  const completed = !!profile?.completed_at;

  const recommendations = await (async () => {
    if (includeRecommendations && completed) {
      return await loadRecommendations(profile.id);
    } else {
      return null;
    }
  })();

  const companyId = bootstrap.info.colaborador.empresa_id;
  const isGalena = companyId === 178;

  const isNewcomer = (() => {
    if (completed) return false;
    return !profile?.current_step;
  })();

  const data: ResultUseBootstrapData = {
    ...bootstrap,
    ...profile,
    isNewcomer,
    completed,
    profile,
    isGalenaUser: IS_LOCALHOST || isGalena,
    recommendations,
  };

  return data;
}

export async function loadRecommendations(
  profileId: number,
  signal?: AbortSignal
) {
  const recommendationResponse = await RecommendationClient.getRecommendations(
    profileId,
    signal
  );

  const homeCarouselKey = 'home/main';
  const recommendations = recommendationResponse[homeCarouselKey];

  if (!recommendations) {
    return null;
  }

  const { courses } = recommendations;

  return {
    ...recommendations,
    courses,
  };
}
