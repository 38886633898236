import palette from "../palette";


const MuiDialogTitle = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...{
        ...theme.typography.h5,
        color: palette.neutral[800],
        padding: theme.spacing(3),
      },
    }),
  },
};

export default MuiDialogTitle;
