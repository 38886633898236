import { current, isDraft } from 'immer';

export function fromDraft<T>(payload: T): T {
  if (!payload) return payload;
  if (typeof payload !== 'object') return payload;
  if (Array.isArray(payload)) return payload.map((el) => fromDraft(el)) as any;
  payload = isDraft(payload) ? current(payload) : payload;
  Object.entries(payload).forEach(([key, value]) => {
    payload[key] = fromDraft(value);
  });
  return payload;
}
