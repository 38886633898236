<app-corporate-university-banner></app-corporate-university-banner>
<div *ngIf="trail" class="container py-4">
  <div class="btn-row row">
    <component-button-icon
      (onClick)="onCatalog()"
      type="secondary"
      icon="arrow_back"
    >
    </component-button-icon>
    <p class="back-text" (click)="onCatalog()">
      {{ getTranslation('back') | translate }}
    </p>
  </div>
  <div class="row">
    <div
      *ngFor="let course of trail.courses"
      class="col-sm-6 col-lg-4 col-xl-3"
    >
      <div class="card">
        <div class="crop">
          <img
            class="imagem-curso"
            [src]="getImage(course.image)"
            (error)="onImageError($event)"
            alt=""
          />
        </div>
        <div class="card-body">
          <h4>{{ course.name }}</h4>
          <div class="card-content mt-4 d-flex justify-content-center">
            <component-botao type="secondary" (onClick)="onAcessar(course)"
              >Ir para curso</component-botao
            >
          </div>
        </div>
      </div>
    </div>

    <div class="col-12" *ngIf="trail.courses.length === 0">
      <p class="title text-center">
        {{ getTranslation('nenhumCurso') | translate }}
      </p>
      <div class="NoOngoingCourse row">
        <p>{{ getTranslation('explore') | translate }}</p>
        <component-botao (click)="onCatalog()" type="primary">
          {{ getTranslation('catalogo') | translate }}
        </component-botao>
        <img
          class="NoOngoingImage"
          [src]="getAsset('noResultsOnGoingCourses.png')"
          alt=""
        />
      </div>
    </div>
  </div>
</div>
