import React, { JSX, useMemo } from 'react';

// Renders "\n" to <br />
export function NL2BR(props: {
  children: string;
  renderLink?: (value: { text: string; href: string }) => JSX.Element;
}) {
  const { children, renderLink } = props;

  const txt = useMemo(() => {
    const parts = children.split('\n');
    const lastIDX = parts.length - 1;

    return React.Children.map(parts, (el, idx) => {
      return (
        <React.Fragment key={idx + el}>
          {el ? (
            <span className={'nl2item'}>
              {<Links renderLink={renderLink}>{el}</Links>}
            </span>
          ) : null}
          {idx !== lastIDX ? <br /> : null}
        </React.Fragment>
      );
    });
  }, [children]);

  return <span className={'nl2br'}>{txt}</span>;
}

function Links(props: {
  children: string;
  renderLink?: (value: { text: string; href: string }) => JSX.Element;
}) {
  const { children, renderLink } = props;

  const txt = useMemo(() => {
    const links = children
      .replace(
        /\[([^]*)]\(([^)]*)\)/gm, //
        '::▽▽▽▽$1▽▽$2::▽▽'
      )
      .split('::▽▽');

    if (!links) return children;

    return React.Children.map(links, (el, idx) => {
      const isLink = el.startsWith('▽▽');

      return (
        <React.Fragment key={idx + el}>
          {isLink
            ? (() => {
                const [, text, href] = el.split('▽▽');
                if (renderLink) return renderLink({ text, href });
                return <a href={href}>{text}</a>;
              })()
            : el}
        </React.Fragment>
      );
    });
  }, [children]);

  return <>{txt}</>;
}
