import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { formatMoney } from '@/react/utils/money';

export const Price = ({ installments, priceType, value }) => {
  const Wrapper = ({ children }) => (
    <Box
      component="span"
      sx={(theme) => ({
        fontWeight: theme.typography.fontWeightRegular,
      })}
    >
      {children}
    </Box>
  );

  const PriceComponent = (
    <Box
      component="span"
      sx={(theme) => ({
        fontWeight: theme.typography.fontWeightBold,
      })}
    >
      {formatMoney(value)}
    </Box>
  );

  if (priceType === 'upon_request')
    return (
      <Typography component="div" variant="h6">
        Preço sob consulta
      </Typography>
    );

  if (priceType === 'monthly')
    return (
      <Wrapper>
        <Box component="span">{PriceComponent}/mês</Box>
      </Wrapper>
    );

  if (priceType === 'installments' && value > 0)
    return (
      <Wrapper>
        <Box component="span">
          {installments}x {PriceComponent}
        </Box>
      </Wrapper>
    );

  return PriceComponent;
};
