import * as React from 'react';
import { nanoid } from 'nanoid';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

import { useScreenSizes } from '@/react/utils/useScreenSizes';

const CourseTypeListLoading = () => {
  const { desktopSize, isMediumScreen, isSmallScreen } = useScreenSizes();

  const numberOfItems = () => {
    if (isSmallScreen) return 2;
    if (isMediumScreen) return 3;
    if (desktopSize) return 4;
  };

  return (
    <Grid container spacing={{ xs: 2, sm: 4 }}>
      {Array.from(Array(numberOfItems())).map(() => (
        <Grid item xs={12} sm={6} md={4} xl={3} key={nanoid()}>
          <Skeleton
            sx={{ height: { xs: 100, sm: 200 } }}
            animation="wave"
            variant="rounded"
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default CourseTypeListLoading;
