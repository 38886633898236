import { ToastrModule } from 'ngx-toastr';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import {
  TranslateModule,
  TranslateLoader,
  TranslatePipe,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';

import { LOCALE_ID } from '@angular/core';
import {
  registerLocaleData,
  CurrencyPipe,
  CommonModule,
} from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { CSVHandler } from './utils/csv.handler';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutNewModule } from './layout/layout.module';
import { MarkdownModule } from 'ngx-markdown';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EduplayModule } from './pages/eduplay/eduplay.module';
import { ComponentsModule } from './components/components.module';
import { ColaboradorModule } from './pages/colaborador/colaborador.module';
import { LoginModule } from './pages/login/login.module';
import { CursosModule } from './pages/cursos/cursos.module';
import { EmpresaModule } from './pages/empresa/empresa.module';
import { CorporateUniversityModule } from './pages/corporate-university/corporate-university.module';
import { DevModule } from './pages/dev/dev.module';

registerLocaleData(localePt);

export function createRootTranslateLoader(httpclient: HttpClient) {
  return new TranslateHttpLoader(httpclient, './assets/i18n/', '.ts');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    LayoutNewModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    MarkdownModule.forRoot(),
    NgbModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    EduplayModule,
    ComponentsModule,
    ColaboradorModule,
    LoginModule,
    CursosModule,
    EmpresaModule,
    HttpClientModule,
    CorporateUniversityModule,
    DevModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createRootTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [TranslateModule],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt' },
    CurrencyPipe,
    CSVHandler,
    TranslatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
