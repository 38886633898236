import {
  Institution,
  Offer,
  ProductCourse,
} from '@/react/data/catalog/interfaces';
import { Subscription } from '@/react/data/course/interfaces';
import { Subsidy } from '@/react/data/subsidy/interfaces';

export enum ProductPageAsyncResources {
  course = 'course',
  offer = 'offer',
  subsidy = 'subsidy',
  subscriptions = 'subscriptions',
}

export interface State {
  course: ProductCourse | undefined;
  courseId: string;
  institution: Institution | undefined;
  isSingleOffer: boolean | undefined;
  loading: Array<ProductPageAsyncResources>;
  offer: Offer | undefined;
  updateState?: React.Dispatch<React.SetStateAction<State>>;
  subsidy: Subsidy | undefined;
  subscriptions: Array<Subscription> | undefined;
}

export { ProductCourse, Institution, Offer };
