import * as React from 'react';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { useFilters } from '@/react/components/filters/state';

export const FilterInlineSelect = ({
  id,
  options,
  title,
  type,
}: {
  id: string;
  options: { label: string; value: string }[];
  title?: string;
  type: string;
}) => {
  const { actions, state } = useFilters();
  const fieldState = state[id];
  const value = fieldState.values[0];

  const handleChange = (e) => {
    actions.setValueById(id, e.target.value);
    actions.setPage(1);
  };

  const optionsById = React.useMemo(() => {
    return options.reduce((acc, option) => {
      acc[option.value] = option;
      return acc;
    }, {});
  }, [options]);

  const selectedValue = optionsById[value].value;

  return (
    <FormControl
      data-filter-type={type}
      sx={{ alignItems: 'center', flexDirection: 'row' }}
      variant="standard"
    >
      {title && (
        <Typography variant="body3" sx={{ margin: '2px 4px 4px 0' }}>
          {title}
        </Typography>
      )}
      <Box>
        <Select
          id={id}
          fullWidth
          onChange={handleChange}
          size="medium"
          value={selectedValue || null}
        >
          {options.map((option) => {
            return (
              <MenuItem dense key={option.value} value={option.value}>
                <Typography variant="body3">{option.label}</Typography>
              </MenuItem>
            );
          })}
        </Select>
      </Box>
    </FormControl>
  );
};
