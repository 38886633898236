import * as assets from './assets';
import { labelsToOptions } from '@/react/utils/labelsToOptions';
import type { QuestionNode } from '@/react/components/quiz/quiz.interfaces';
import type { BootstrapInterfaces } from '@/react/data/bootstrap';
import { Profile } from '@/react/data/recommendation/interfaces';
import { filterNull, IS_BROWSER } from 'powership';
import { ResultUseBootstrapData } from '@/react/data/bootstrap/useBootstrapData';
import { enumerate } from '@/react/components/quiz/utils/enumerate';

export const QUESTIONS_ENUM = enumerate([
  'presentation_welcome',
  'presentation_welcome_step_2',
  'presentation_update_preferences',
  'location_info',
  'current_job',
  'scholarity',
  'seniority',
  'presentation_objective_selection',
  'goals',
  'desired_job',
  'presentation_learn_your_way',
  'select_course_interests',
  'select_most_interesting_themes',
  'select_interesting_languages',
  'select_best_learning_modality',
  'select_monthly_budget',
  'time_available_per_week',
  'input_contact_email',
  'presentation_finished',
  'presentation_ask_before_close',
]);

export const QuestionNames = QUESTIONS_ENUM.value;
export type QuestionId = typeof QUESTIONS_ENUM.infer;

export const GOALS = enumerate([
  'career_development',
  'career_change',
  'personal_development',
]);

preloadAssets();

export function createProfileQuizQuestions(
  props: ResultUseBootstrapData
): QuestionNode[] {
  const { isNewcomer, taxonomy, profile } = props;
  const { knowledge_areas } = taxonomy;

  const isEdit = !isNewcomer;

  const knowledgeAreasOptions = mountKnowledgeAreasOptions(knowledge_areas);
  const courseInterestOptions = mountInterestOptions(taxonomy);
  const budgetOptions = mountBudgetOptions(profile);

  return filterNull<QuestionNode>([
    isNewcomer && {
      id: QuestionNames.presentation_welcome,
      title: 'Boas vindas!',
      touched: false,
      value: [],
      errors: [],
      draftState: { value: [] },
      kind: 'presentation',
      props: {
        slides: [
          {
            title: 'Boas vindas!',
            img: assets.setupUser,
            subtitle:
              'A Galena é uma plataforma completa para seu desenvolvimento.\n' +
              '\n' +
              'Aqui você vai encontrar os recursos que te impulsionarão para o próximo passo da sua carreira!',
          },
        ],
        buttons: [
          {
            kind: 'contained',
            label: 'Vamos lá',
            action: 'continue_quiz',
          },
        ],
      },
      showFooterActions: false,
      required: false,
    },

    isNewcomer && {
      id: QuestionNames.presentation_welcome_step_2,
      title: 'Personalize sua experiência',
      touched: false,
      value: [],
      errors: [],
      draftState: { value: [] },
      kind: 'presentation',
      props: {
        slides: [
          {
            title: 'Personalize sua experiência',
            img: assets.setupUserSlide2,
            subtitle:
              'Para recomendarmos os conteúdos certos para você alcançar seus objetivos, precisamos que responda algumas perguntas.\n' +
              '\n' +
              'Demora apenas 1 minuto!',
          },
        ],
        buttons: [],
      },
      showFooterActions: true,
      required: false,
    },

    isEdit && {
      id: QuestionNames.presentation_update_preferences,
      title: 'Atualize as suas preferências',

      touched: false,
      value: [],
      errors: [],
      draftState: { value: [] },
      kind: 'presentation',
      props: {
        slides: [
          {
            title: 'Atualize as suas preferências',
            img: assets.updateUser,
            subtitle:
              'Revise seus Objetivos e Interesses para receber recomendações personalizadas para o seu desenvolvimento.',
          },
        ],

        buttons: [
          {
            kind: 'contained',
            label: 'Vamos lá',
            action: 'continue_quiz',
          },
        ],
      },
      required: false,
      showFooterActions: false,
    },

    {
      id: QuestionNames.location_info,
      title: 'Onde você mora?',
      touched: false,
      value: [],
      errors: [],
      draftState: {
        value: [
          profile.address_state, //
          profile.address_city,
        ],
      },
      kind: 'address',
      props: {},
      required: true,
    },

    {
      id: QuestionNames.scholarity,
      title: 'Qual é a sua formação atual?',
      touched: false,
      value: [],
      errors: [],
      draftState: {
        value: [profile.scholarity],
      },
      kind: 'radio',

      props: {
        options: [
          {
            label: 'Ensino Médio incompleto',
            value: 'high_school_incomplete',
          },
          {
            label: 'Ensino Médio completo',
            value: 'high_school_complete',
          },
          {
            label: 'Ensino Superior incompleto',
            value: 'undergraduate_incomplete',
          },
          {
            label: 'Ensino Superior completo',
            value: 'undergraduate_complete',
          },
          {
            label: 'Pós-Graduação/Mestrado/MBA',
            value: 'postgraduate',
          },
          {
            label: 'Doutorado/Pós-Doutorado',
            value: 'doctoral',
          },
        ],
      },
      required: true,
    },

    {
      id: QuestionNames.current_job,
      title: 'Qual é o seu cargo atual?',
      touched: false,
      value: [],
      errors: [],
      draftState: { value: [profile.current_job?.id?.toString()] },
      meta: {
        jobReloadedFromProfile: profile.current_job,
      },
      kind: 'job_selection',
      props: {
        searchPlaceholder: 'Procure o seu cargo atual',
        searchHelperText:
          'Selecione a opção que mais se adequa ao seu cargo atual.',
        allowMarkAsOptionNotFound: false,
        optionNotFoundText: 'Não encontrei o meu cargo.',
      },
      required: true,
    },

    {
      id: QuestionNames.seniority,
      title: 'Qual nível de senioridade você ocupa hoje?',
      touched: false,
      value: [],
      errors: [],
      draftState: { value: [profile?.seniority] },
      kind: 'radio',
      props: {
        options: [
          { label: 'Jovem aprendiz', value: 'apprentice' },
          { label: 'Estagiário', value: 'intern' },
          { label: 'Atendente', value: 'attendant' },
          { label: 'Operador', value: 'operator' },
          { label: 'Técnico', value: 'technician' },
          { label: 'Auxiliar', value: 'auxiliary' },
          { label: 'Assistente', value: 'assistant' },
          { label: 'Analista', value: 'analyst' },
          { label: 'Supervisor', value: 'supervisor' },
          { label: 'Coordenador', value: 'coordinator' },
          { label: 'Especialista', value: 'specialist' },
          { label: 'Gerente', value: 'manager' },
          { label: 'Diretor', value: 'director' },
          { label: 'Vice presidente/Presidente', value: 'vice_president' },
        ],
      },
      required: true,
    },

    {
      title: 'Defina seu objetivo',
      id: QuestionNames.presentation_objective_selection,
      touched: false,
      value: [],
      errors: [],
      draftState: { value: [] },
      kind: 'presentation',
      props: {
        slides: [
          {
            title: 'Defina seu objetivo',
            img: assets.objectiveMountain,
            subtitle:
              'Aonde você quer chegar? Defina seu objetivo e aspiração ' +
              'profissional para receber recomendações mais precisas.',
          },
        ],
        buttons: [
          {
            kind: 'contained',
            label: 'Continuar',
            action: 'continue_quiz',
          },
        ],
      },
      required: false,
    },

    {
      id: QuestionNames.goals,
      title:
        'Das opções a seguir, qual melhor define seu objetivo de desenvolvimento?',
      touched: false,
      value: [],
      errors: [],
      draftState: { value: [profile.goal] },
      kind: 'radio',
      props: {
        // if values change, should change the
        // condition in the `job_selection` node
        options: [
          {
            label: 'Evoluir na carreira',
            value: GOALS.value['career_development'],
            icon: assets.floor,
          },
          {
            label: 'Mudança de carreira',
            value: GOALS.value['career_change'],
            icon: assets.travel,
          },
          {
            label: 'Desenvolvimento pessoal',
            value: GOALS.value['personal_development'],
            icon: assets.psychology,
          },
        ],
      },
      required: true,
    },

    {
      id: QuestionNames.desired_job,
      title: 'Qual cargo você deseja alcançar?',
      kind: 'job_selection',
      countAsStep: false,
      conditions: {
        visible: {
          /**
           * Condicional:
           * Se o objetivo de desenvolvimento for Mudança de carreira Essa questão é habilitada.
           * https://www.figma.com/file/0bYmSUL1u1MUA5nOROaXLN/%5BColab%5D-Mapeamento-de-Interesses?node-id=1355%3A34255&mode=dev
           */
          id: QuestionNames.goals,
          value: GOALS.value['career_change'],
        },
      },
      props: {
        allowMarkAsOptionNotFound: false,
        optionNotFoundText: 'Não encontrei o cargo quero alcançar.',
        searchHelperText:
          'Selecione a opção que mais se adequa ao seu cargo desejado.',
        searchPlaceholder: 'Procure o cargo desejado',
      },
      touched: false,
      value: [],
      errors: [],
      draftState: { value: [profile.desired_job?.id?.toString()] },
      meta: {
        jobReloadedFromProfile: profile.desired_job,
      },
      required: true,
    },

    {
      id: QuestionNames.presentation_learn_your_way,
      title: 'Aprenda do seu jeito',
      touched: false,
      value: [],
      errors: [],
      draftState: { value: [] },
      kind: 'presentation',
      props: {
        slides: [
          {
            title: 'Aprenda do seu jeito',
            img: assets.learnYourWay,
            subtitle:
              'Selecione as categorias de cursos e os temas que mais te interessam para receber sugestões de conteúdos mais relevantes para você.',
          },
        ],
        buttons: [],
      },
      required: false,
    },

    {
      id: QuestionNames.select_course_interests,
      title: 'Quais categorias de curso você tem interesse?',
      touched: false,
      value: [],
      errors: [],
      draftState: { value: profile.course_types || [] },
      kind: 'box_selection',
      props: {
        options: courseInterestOptions,
        validation: {
          maxSelectedCount: courseInterestOptions.length,
          minSelectedCount: 1,
        },
      },
      required: true,
    },

    {
      id: QuestionNames.select_most_interesting_themes,
      conditions: {
        visible: {
          id: QuestionNames.select_course_interests,
          value: {
            $in: courseInterestOptions
              .filter((el) => el.thisOptionAllowsSelectThemes === true)
              .map((el) => el.value),
          },
        },
      },
      title: 'E quais temas mais te interessam?',
      touched: false,
      value: [],
      errors: [],
      draftState: {
        value: profile.knowledge_areas?.map((el) => el.id?.toString()) || [],
      },
      kind: 'tag_selection',
      dialogProps: {
        maxWidth: 'sm',
      },
      props: {
        options: knowledgeAreasOptions,
        validation: {
          maxSelectedCount: 5,
          minSelectedCount: 0,
        },
      },
      required: false,
    },

    {
      id: QuestionNames.select_interesting_languages,
      title: 'Quais idiomas você tem mais interesse?',
      conditions: {
        visible: {
          id: QuestionNames.select_course_interests,
          value: {
            $in: courseInterestOptions
              .filter((el) => el.thisOptionAllowsSelectLanguages === true)
              .map((el) => el.value),
          },
        },
      },
      touched: false,
      value: [],
      errors: [],
      draftState: { value: profile.languages || [] },
      kind: 'tag_selection',
      props: {
        options: labelsToOptions([
          'Francês',
          'Inglês',
          'Mandarim',
          'Espanhol',
          'Italiano',
          'Japonês',
          'Alemão',
          'Coreano',
          'Holandês',
          'Português para Estrangeiros',
        ]),
        validation: {
          maxSelectedCount: 5,
          minSelectedCount: 0,
        },
      },
      required: false,
    },

    {
      id: QuestionNames.select_best_learning_modality,
      title: 'Qual modalidade de ensino se adequa melhor à sua rotina?',
      touched: false,
      value: [],
      errors: [],
      draftState: { value: profile.modalities || [] },
      kind: 'box_selection',
      props: {
        options: labelsToOptions([
          { label: 'Presencial', icon: assets.apartment },
          { label: 'Híbrido', icon: assets.homeWork },
          { label: 'Curso a distância', icon: assets.interactiveSpace },
        ]),
        validation: {
          maxSelectedCount: 3,
          minSelectedCount: 0,
        },
      },
      required: false,
    },

    {
      id: QuestionNames.select_monthly_budget,
      title:
        'Qual é o seu orçamento mensal para investir em seu desenvolvimento?',
      touched: false,
      value: [],
      errors: [],
      kind: 'radio',
      required: false,
      props: { options: budgetOptions.options },
      draftState: {
        value: budgetOptions.initialValue ? [budgetOptions.initialValue] : [],
      },
    },

    {
      id: QuestionNames.time_available_per_week,
      title:
        'Quanto tempo você pretende investir no seu desenvolvimento por semana?',
      touched: false,
      value: [],
      errors: [],
      kind: 'radio',
      draftState: {
        value: (() => {
          if (profile.max_hours_available_per_week === 2) return ['0-2'];
          if (profile.max_hours_available_per_week === 5) return ['0-5'];
          if (profile.max_hours_available_per_week === 10) return ['0-10'];
          if (profile.min_hours_available_per_week === 10) return ['10-'];
          return [];
        })(),
      },
      props: {
        options: [
          { label: 'Até 2 horas', value: '0-2' },
          { label: 'Até 5 horas', value: '0-5' },
          { label: 'Até 10 horas', value: '0-10' },
          { label: 'Mais de 10 horas', value: '10-' },
        ],
      },
      required: false,
    },

    {
      id: QuestionNames.input_contact_email,
      title: 'Vamos manter contato?',
      touched: false,
      value: [],
      errors: [],
      draftState: { value: profile.email ? [profile.email] : [] },
      kind: 'email_contact',
      props: {
        header:
          'Nos diga seu e-mail preferido para receber informações e novidades sobre a Galena!\n\n' +
          'É para ele que enviaremos as comunicações e instruções sobre suas matrículas e cursos, além de promoções especiais.',
        placeholder: 'Digite o seu e-mail',
      },
      required: false,
    },

    {
      title: 'Mapeamos seus interesses!',
      id: QuestionNames.presentation_finished,
      kind: 'presentation',
      value: [],
      countAsStep: false,
      errors: [],
      required: false,
      showFooterActions: false,
      draftState: { value: [] },
      touched: false,
      props: {
        slides: [
          {
            title: 'Mapeamos seus interesses!',
            img: assets.mapInterests,
            subtitle:
              'Agora a sua experiência na Galena será ainda mais personalizada!',
          },
        ],

        buttons: [
          {
            label: 'Explorar a plataforma',
            action: 'exit_quiz',
            kind: 'contained',
          },
        ],
      },
    },

    {
      title: 'Tem certeza que deseja parar de responder ?',
      id: QuestionNames.presentation_ask_before_close,
      conditions: {
        // vai substituir o nó atual quando a pessoa tentar fechar o quiz
        replaceActive: {
          flags: { $elemMatch: 'willClose' }, // verifica se esta tentando fechar
          previousActiveNodeId: {
            $ne: QuestionNames.presentation_finished,
          },
        },
      },
      kind: 'presentation',
      value: [],
      countAsStep: false,
      errors: [],
      required: false,
      draftState: { value: [] },
      touched: false,
      showFooterActions: false,
      props: {
        slides: [
          {
            title: 'Tem certeza que deseja parar de responder ?',
            img: assets.megaphone,
            subtitle:
              'Tem certeza que deseja parar de responder?\n' +
              'Falta pouco para terminar e suas respostas são importantes para fazermos recomendações mais precisas!',
          },
        ],
        buttons: [
          {
            label: 'Continuar respondendo',
            action: 'go_back',
            kind: 'contained',
          },
          {
            label: 'Parar de responder',
            action: 'force_close',
            kind: 'text',
          },
        ],
      },
    },
  ]);
}

function mountKnowledgeAreasOptions(
  knowledgeAreas: BootstrapInterfaces['knowledgeArea'][]
) {
  return knowledgeAreas.map((el) => ({
    label: el.description_pt_br,
    value: String(el.id),
  }));
}

function mountBudgetOptions(profile: Partial<Profile>) {
  // in `quizStateToProfile` we split
  // the value by `-` and get the min/max
  const options = [
    {
      label: 'Até R$ 50',
      value: '0-50.0',
      selected:
        profile.min_budget_per_month === '0' &&
        profile.max_budget_per_month == '50.0',
    },
    {
      label: 'Até R$ 100',
      value: '0-100.0',
      selected:
        profile.min_budget_per_month === '0' &&
        profile.max_budget_per_month == '100.0',
    },
    {
      label: 'Até R$300',
      value: '0-300.0',
      selected:
        profile.min_budget_per_month === '0' &&
        profile.max_budget_per_month == '300.0',
    },
    {
      label: 'Até R$1000',
      value: '0-1000.0',
      selected:
        profile.min_budget_per_month === '0' &&
        profile.max_budget_per_month == '1000.0',
    },
    {
      label: 'Mais de R$1000',
      value: '1000.0-',
      selected:
        profile.min_budget_per_month === '0' &&
        profile.max_budget_per_month == '1000.0',
    },
  ];

  const initialValue = options.find((el) => el.selected)?.value;

  return { options, initialValue };
}

function mountInterestOptions(taxonomy: BootstrapInterfaces['taxonomy']) {
  const extraInfo = {
    languages: { icon: assets.globe, thisOptionAllowsSelectLanguages: true },
    graduation: { icon: assets.graduation, thisOptionAllowsSelectThemes: true },
    postgraduate: { icon: assets.travel, thisOptionAllowsSelectThemes: true },
    free: { icon: assets.autoStories, thisOptionAllowsSelectThemes: true },
    technical: { icon: assets.accountTree, thisOptionAllowsSelectThemes: true },
  };

  return taxonomy.course_types.map((el) => ({
    label: el.label,
    value: el.type,
    ...extraInfo[el.type],
  }));
}

function preloadAssets() {
  if (!IS_BROWSER) return;
  Object.values(assets).forEach((url) => {
    const img = new Image();
    img.src = url;
  });
}
