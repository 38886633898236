import { ActivatedRoute } from "@angular/router";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
	FormControl,
	FormGroup,
} from "@angular/forms";

import { ApiService } from "src/app/services/api.service";
import { UsersService } from "src/app/services/users.service";
import { RouterService } from "src/app/services/router.service";
import { getAsset, getSvg } from "src/app/utils/helpers";
import { Empresa } from "src/app/models/empresa.model";
import CONST from "@/constants";

interface RegisterForm {
	name: FormControl<string>;
	email: FormControl<string>;
	company: FormControl<string>;
	employee_code: FormControl<string>;
}

@Component({
	selector: "app-new-register-page",
	templateUrl: "./new-register-page.component.html",
	styleUrls: ["./new-register-page.component.scss"],
})
export class NewRegisterPageComponent implements OnInit {
	getSvg = getSvg;
	getAsset = getAsset;

	@ViewChild("formRegisterElement", { static: true })
	formRegisterElement: ElementRef;

	@ViewChild("formLoginElement", { static: true })
	formLoginElement: ElementRef;

	loginForm: UntypedFormGroup;
	registerForm: FormGroup<RegisterForm>;

	company: Empresa;

	submiting = false;

	loginError = false;
	registerError: string;
	invalidEmployeeCode = false;

	companyCode: string;

	expanded: number;

	constructor(
		private apiService: ApiService,
		private usersService: UsersService,
		private routerService: RouterService,
		private activatedRoute: ActivatedRoute,
	) {}

	ngOnInit(): void {
		this.activatedRoute.queryParams.subscribe(({ origin }) => {
			if (!origin) {
				this.routerService.home();
			}
			this.companyCode = origin;
		});

		this.registerForm = new FormGroup({
			name: new FormControl(null, Validators.required),
			email: new FormControl(null, [Validators.required, Validators.email]),
			company: new FormControl(null, Validators.required),
			employee_code: new FormControl(null),
		});

		this.loginForm = new UntypedFormGroup({
			email: new UntypedFormControl(null, Validators.required),
			password: new UntypedFormControl(null, Validators.required),
		});

		this.checkCompanyCode();
	}

	checkCompanyCode() {
		this.apiService
			.getAll("get_company_by_code", { code: this.companyCode })
			.subscribe((response) => {
				this.company = response.data;

				if (this.company.check_employee_code) {
					this.registerForm.controls.employee_code.addValidators(
						Validators.required,
					);
				}
			});
	}

	onRegister() {
		if (!this.submiting) {
			this.registerError = undefined;
			this.submiting = true;

			this.apiService
				.post("new_register", {
					...this.registerForm.value,
					registerCompany: this.companyCode,
				})
				.subscribe(
					(response) => {
						this.submiting = false;
						this.registerForm.reset();
						localStorage.setItem("token", response.data);
						localStorage.setItem(CONST.TOKEN_KEY, response.data);
						this.checkRedirectAfterLogin();

						this.routerService.home();
					},
					(error) => {
						if (error.status === 404) {
							this.routerService.navigate(["login"]);
						} else {
							this.registerError = error.error.data.message;
							this.submiting = false;
						}
					},
				);
		}
	}

	checkRedirectAfterLogin() {
		const redirectAfterLogin = localStorage.getItem("redirect_after_login");
		if (redirectAfterLogin) {
			localStorage.removeItem("redirect_after_login");
			window.location.href = redirectAfterLogin;
		}
	}

	onLogin() {
		if (!this.submiting) {
			this.loginError = false;
			this.submiting = true;

			this.apiService.post("login", this.loginForm.value).subscribe(
				(response) => {
					this.usersService.store(response.data);
					this.checkRedirectAfterLogin();
					this.routerService.home();
					this.submiting = false;
				},
				(e) => {
					this.loginError = true;
					this.submiting = false;
				},
			);
		}
	}

	onForgorPassword() {
		this.routerService.navigate(["login", "forgot-password"]);
	}

	onExpand(index: number) {
		this.expanded = index;

		const containers: ElementRef[] = [
			this.formRegisterElement,
			this.formLoginElement,
		];

		containers.forEach((container, _index) => {
			const panel = container?.nativeElement;

			if (_index === index) {
				panel.style.maxHeight = "600px";
			} else {
				panel.style.maxHeight = null;
			}
		});
	}

	getBackground() {
		return `url('${getAsset("new-register-logo.png")}')`;
	}
}
