import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/shared/shared.module';
import { ManageDependentsComponent } from './dependents/manage-dependents.component';
import { RefundFormComponent } from './refund-form/refund-form.component';
import { MyRefundsNavigationComponent } from './my-refunds/navigation/navigation.component';
import { MyRefundsPendingComponent } from './my-refunds/refunds-pending/refunds-pending.component';
import { AlterarSenhaComponent } from './alterar-senha/alterar-senha.component';
import { ColaboradorRoutingModule } from './colaborador-routing.module';
import { TermsComponent } from './terms/terms.component';
import { DeloitteTermsComponent } from './terms/deloitte-terms/deloitte-terms.component';
import { OverviewComponent } from './my-refunds/overview/overview.component';
import { CardComponent } from './my-refunds/refund-card/refund-card.component';
import { ManageRefundsComponent } from './manage-refunds/manage-refunds.component';
import { RefundRequestComponent } from './refund-request/refund-request.component';
import { RefundPoliciesComponent } from './refund-policies/refund-policies.component';
@NgModule({
  declarations: [
    ManageDependentsComponent,
    RefundFormComponent,
    MyRefundsNavigationComponent,
    MyRefundsPendingComponent,
    AlterarSenhaComponent,
    TermsComponent,
    DeloitteTermsComponent,
    OverviewComponent,
    CardComponent,
    ManageRefundsComponent,
    RefundRequestComponent,
    RefundPoliciesComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ColaboradorRoutingModule,
    NgbTooltipModule,
  ],
})
export class ColaboradorModule {}
