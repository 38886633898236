import { DurationTypes } from '../data/catalog/interfaces';

/**
 * @desc Converts a duration of time into a humanized format
 * @param {string} unit - The unit of time to be formatted (day, week, month, etc).
 * @param {number} value - The value of time to be formatted
 * @param {string} locale - The locale to be used, defaults to 'pt-BR'.
 * @returns {string} The formatted time.
 * @example
 * // duration is '1 mês'
 * formatDuration('month', 1);
 */
export const formatDuration = (unit, value, locale = 'pt-BR') => {
  const durationLabel = getDurationLabel(unit, Number(value), locale);

  return `${value} ${durationLabel}`;
};

export const getDurationLabel = (unit, value, locale = 'pt-BR') => {
  if (unit === 'semester') {
    return Number(value) > 1 ? 'semestres' : 'semestre';
  }

  const rtf = new Intl.RelativeTimeFormat(locale, { numeric: 'always' });
  const parts = rtf.formatToParts(Number(value), unit);
  const durationLiteral = parts.filter((part) => part.type == 'literal').pop();

  return durationLiteral.value.trim();
};

export const durationInMonths = ({
  type,
  quantity,
}: {
  type: DurationTypes;
  quantity: number;
}) => {
  switch (type) {
    case DurationTypes.semester:
      return quantity * 6;
    case DurationTypes.year:
      return quantity * 12;
    default:
      return quantity;
  }
};

export const toLocaleDateString = (date: string): string => {
  return new Date(date).toLocaleDateString('pt-BR', {
    timeZone: 'America/Sao_Paulo',
  });
}
