import { alpha } from '@mui/material/styles';

const MuiSwitch = {
  styleOverrides: {
    thumb: ({ theme }) => ({
      boxShadow: theme.shadows[3],
    }),
  },
};

export default MuiSwitch;
