import { useQuery } from '@tanstack/react-query';
import { InstitutionsSearchResult } from './interfaces';
import { UseQueryOptions } from '@tanstack/react-query/src/types';
import React from 'react';
import { InstitutionsClient } from '@/react/data/institutions/institutions-client';

export function useInstitutionsSearch(
  otherOptions?: UseQueryOptions<InstitutionsSearchResult>
) {
  const result = useQuery<InstitutionsSearchResult>({
    queryKey: ['useInstitutionsSearch'],
    refetchOnMount: false,
    ...otherOptions,
    queryFn: async ({ signal }) => {
      return InstitutionsClient.getInstitutions({ signal });
    },
  });

  return React.useMemo(() => {
    const institutionsSortedByName =
      result.data?.data.items.sort((a, b) => a.name.localeCompare(b.name)) ||
      [];

    return {
      institutions: institutionsSortedByName,
      isFetching: result.isFetching,
    };
  }, [result.data, result.isFetching]);
}
