import * as React from 'react';
import { Grid, Typography, Link } from '@mui/material';
import { formatMoney } from '@/react/utils/money.js';
import { formatDate } from '@/react/utils/date';
import { PriceTypes } from '@/react/data/catalog/interfaces';
import { Months } from '@/react/components/refund/refund-request/invoice-step/interfaces';
import { ResourceApproval } from '@/react/data/subsidy/interfaces';

type useRefundRequest = {
  resourceApproval: ResourceApproval;
};

export const ShowReceiptsInfo: React.FC<useRefundRequest> = ({
  resourceApproval,
}) => {
  return (
    <Grid container rowSpacing={{ xs: 1, md: 2 }}>
      <Grid item xs={12} md={3}>
        <Typography variant="body3" fontWeight={600}>
          Data do pagamento
        </Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        <Typography variant="body3">
          {formatDate(resourceApproval?.refund_request?.payment_date) || '--'}
        </Typography>
      </Grid>

      <Grid item xs={12} md={3}>
        <Typography variant="body3" fontWeight={600}>
          Comprovante de pagamento
        </Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        <Typography variant="body3">
          <Link
            href={
              resourceApproval.refund_request?.receipts[1]?.attachments?.url
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {
              resourceApproval.refund_request?.receipts[1]?.attachments?.content
                ?.filename
            }
          </Link>
        </Typography>
      </Grid>

      <Grid item xs={12} md={3}>
        <Typography variant="body3" fontWeight={600}>
          Valor da mensalidade (R$)
        </Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        <Typography variant="body3">
          {formatMoney(resourceApproval.refund_request?.purchase_amount)}
        </Typography>
      </Grid>
      {resourceApproval.sale_type === PriceTypes.tuition && (
        <>
          <Grid item xs={12} md={3}>
            <Typography variant="body3" fontWeight={600}>
              Mês de referência
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography variant="body3">
              {Months[resourceApproval.refund_request?.reference_month]}
            </Typography>
          </Grid>
        </>
      )}

      <Grid item xs={12} md={3}>
        <Typography variant="body3" fontWeight={600}>
          Nome do fornecedor
        </Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        <Typography variant="body3">
          {resourceApproval.refund_request.supplier_name}
        </Typography>
      </Grid>

      <Grid item xs={12} md={3}>
        <Typography variant="body3" fontWeight={600}>
          CPF
        </Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        <Typography variant="body3">
          {resourceApproval.refund_request.receiver_fiscal_document}
        </Typography>
      </Grid>

      <Grid item xs={12} md={3}>
        <Typography variant="body3" fontWeight={600}>
          Data da compra
        </Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        <Typography variant="body3">
          {formatDate(resourceApproval.refund_request?.purchase_date) || '--'}
        </Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        <Typography variant="body3" fontWeight={600}>
          Valor
        </Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        <Typography variant="body3">
          {formatMoney(resourceApproval.refund_request?.amount)}
        </Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        <Typography variant="body3" fontWeight={600}>
          Comprovante de compra
        </Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        <Typography variant="body3">
          <Link
            href={
              resourceApproval.refund_request?.receipts[0]?.attachments?.url
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {
              resourceApproval.refund_request?.receipts[0]?.attachments?.content
                ?.filename
            }
          </Link>
        </Typography>
      </Grid>

      {resourceApproval.refund_request?.banking_info?.pix_key ? (
        <>
          <Grid item xs={12} md={3}>
            <Typography variant="body3" fontWeight={600}>
              Chave PIX
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography variant="body3">
              {resourceApproval.refund_request?.banking_info?.pix_key}
            </Typography>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} md={3}>
            <Typography variant="body3" fontWeight={600}>
              Número da conta
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography variant="body3">
              {resourceApproval.refund_request?.banking_info?.account_number}
            </Typography>
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography variant="body3" fontWeight={600}>
              Banco
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography variant="body3">
              {resourceApproval.refund_request?.banking_info?.bank}
            </Typography>
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography variant="body3" fontWeight={600}>
              Agência
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography variant="body3">
              {resourceApproval.refund_request?.banking_info?.agency}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};
