import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';

// categories icons
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import CardTravelRoundedIcon from '@mui/icons-material/CardTravelRounded';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';

// modalities icons
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import ComputerRoundedIcon from '@mui/icons-material/ComputerRounded';
import DuoOutlinedIcon from '@mui/icons-material/DuoOutlined';
import HomeWorkRoundedIcon from '@mui/icons-material/HomeWorkRounded';
import LiveTvRoundedIcon from '@mui/icons-material/LiveTvRounded';

import { formatDuration } from '@/react/utils/time';
import { useProductPage } from '../state';
import { ProductPageAsyncResources } from '../state/interfaces';
import { CourseDetailsHeaderCard } from './course-details-header-card.component';

export const CourseDetailsHeader = () => {
  const { course, institution, loading: isFetching } = useProductPage();

  const loading = isFetching.includes(ProductPageAsyncResources.course);

  const renderModalityIconComponent = (type) => {
    switch (type) {
      case 'online':
        return <ComputerRoundedIcon sx={{ fontSize: 24 }} />;
      case 'live_online':
        return <LiveTvRoundedIcon sx={{ fontSize: 24 }} />;
      case 'hybrid':
        return <HomeWorkRoundedIcon sx={{ fontSize: 24 }} />;
      case 'presential':
        return <ApartmentRoundedIcon sx={{ fontSize: 24 }} />;
      case 'private_class':
        return <DuoOutlinedIcon sx={{ fontSize: 24 }} />;
      default:
        return null;
    }
  };

  const renderCategoryIconComponent = (type) => {
    switch (type) {
      case 'free':
        return <AutoStoriesOutlinedIcon sx={{ fontSize: 24 }} />;
      case 'languages':
        return <PublicOutlinedIcon sx={{ fontSize: 24 }} />;
      case 'technical':
        return <AccountTreeOutlinedIcon sx={{ fontSize: 24 }} />;
      case 'graduation':
        return <SchoolOutlinedIcon sx={{ fontSize: 24 }} />;
      case 'postgraduate':
        return <CardTravelRoundedIcon sx={{ fontSize: 24 }} />;
      case 'basic':
        return <CategoryOutlinedIcon sx={{ fontSize: 24 }} />;
      default:
        return null;
    }
  };

  const DurationCard = ({
    duration,
  }: {
    duration: { type: string; quantity: number };
  }) => {
    const localizedDuration = () => {
      if (!duration) return '';

      const { type, quantity } = duration;
      return formatDuration(type, quantity);
    };

    return (
      <CourseDetailsHeaderCard
        iconComponent={<AccessTimeRoundedIcon sx={{ fontSize: 24 }} />}
        subtitle={localizedDuration()}
        title={'Duração'}
      ></CourseDetailsHeaderCard>
    );
  };

  if (loading || (course && institution)) {
    return (
      <>
        <Stack spacing={1}>
          <Typography
            color="primary.100"
            // @ts-ignore fixme: add typings for variants
            variant="body3"
          >
            {loading ? <Skeleton width="25%" /> : institution.name}
          </Typography>
          <Typography color="primary.100" variant="h4">
            {loading ? <Skeleton width="40%" /> : course.name}
          </Typography>
        </Stack>
        <Box mt={4}>
          <Grid container spacing={{ xs: 2, sm: 4 }}>
            <Grid item xs={12} md={4}>
              {loading ? (
                <Skeleton animation="wave" height={60} variant="rounded" />
              ) : (
                <CourseDetailsHeaderCard
                  iconComponent={renderCategoryIconComponent(
                    course.course_type.type
                  )}
                  subtitle={course.course_type.label}
                  title={'Categoria'}
                />
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              {loading ? (
                <Skeleton animation="wave" height={60} variant="rounded" />
              ) : (
                <CourseDetailsHeaderCard
                  iconComponent={renderModalityIconComponent(
                    course.modality.type
                  )}
                  subtitle={course.modality.label}
                  title={'Modalidade'}
                />
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              {loading ? (
                <Skeleton animation="wave" height={60} variant="rounded" />
              ) : (
                <DurationCard duration={course.duration} />
              )}
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }

  return null;
};
