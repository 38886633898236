import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'component-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  @Input()
  message: string = '';

  @Input()
  icon: string = 'info';

  @ViewChild('tooltipText')
  tooltipText: ElementRef<HTMLSpanElement>;
}
