<div class="d-flex flex-wrap my-auto">
  <div
    *ngFor="let categoria of categorias"
    class="d-flex flex-column col-sm-12 col-md-6 col-lg-3 categoria-card"
    (click)="onNavigate(categoria)"
  >
    <app-corporate-university-card
      [categoria]="categoria"
    ></app-corporate-university-card>
  </div>
</div>
