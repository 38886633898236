import { CONST } from 'src/app/constants';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { getHeaders } from '@/utils/apiHeaders';

@Injectable({
  providedIn: 'root',
})
export class FalconApiService {
  constructor(private http: HttpClient) {}

  get(url: string, params = {}): Observable<any> {
    const queryParams = Object.keys(params).map((key) => {
      return `${key}=${params[key]}`;
    });

    const query = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

    return this.http.get(`${CONST.FALCON_API_URL}/${url}${query}`, {
      headers: getHeaders(),
    });
  }
}
