import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { SelectOption } from '@/components/form/select/select.component';
import {
  MAX_ITEMS_LOADED,
  PAGE_LIMIT,
  RefundsService,
} from '@/services/api/refunds.service';
import { TablePage } from '@/shared/table-default/table-default.component';
import { CSVHandler } from '@/utils/csv.handler';
import { formatMoney } from '@/utils/money-parser';
import { TranslatedComponent } from '@/utils/translated.component';
import {
  Refund,
  RefundStatus,
  statusMap,
  statusOptions,
} from '@/models/refund.model';
import { DatePickerComponent } from '@/components/date-picker/date-picker.component';
import { formatDate } from '@/utils/date-parser';
import { TranslationService } from '@/services/translation.service';
import { UsersService } from '@/services/users.service';
import { ReceiptFile } from '@/models/receipt-file.model';
import CONST from '@/constants';

@Component({
  selector: 'app-refund-report-collaborator',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class RefundReceiptReportComponent
  extends TranslatedComponent
  implements OnInit
{
  @ViewChild('datepicker', { static: true })
  datePicker: DatePickerComponent;

  translationKey = 'EMPRESA.RELATORIOS.REFUND_REPORT';

  tablePages: TablePage[] = [
    {
      columns: [
        {
          label: 'Data de solicitação',
          getValue: (item: Refund) => formatDate(item.created_at),
          type: 'text',
        },
        {
          label: 'Nome',
          getValue: (item: Refund) => item.user.nome,
          type: 'text',
        },
        {
          label: 'E-mail',
          getValue: (item: Refund) => item.user.email,
          type: 'text',
        },

        {
          label: 'Valor do reembolso',
          getValue: (item: Refund) =>
            formatMoney(item.full_refund_amount.toString()),
          type: 'text',
        },
        {
          label: 'Reembolso (%)',
          getValue: (item: Refund) => `${item.refund_percentage}%`,
          type: 'text',
        },
        {
          label: 'Status',
          getValue: (item: Refund) => `REFUND_STATUS.${statusMap[item.status]}`,
          type: 'text',
        },
        {
          label: 'Comprovante',
          getValue: (item: ReceiptFile) =>
            `${CONST.API_ENDPOINT}/refunds/invoices/file/${item.url}`,
          getFiles: (item: Refund) =>
            item.refund_receipts[item.refund_receipts.length - 1].receipt_files,
          type: 'download',
        },
      ],
    },
    {
      columns: [
        {
          label: 'Instituição',
          getValue: (item: Refund) => item.institution_name,
          type: 'text',
        },
        {
          label: 'Curso',
          getValue: (item: Refund) => item.course_name,
          type: 'text',
        },

        {
          label: 'Matrícula com Galena',
          getValue: (item: Refund) =>
            item.is_edupass_enrollment ? 'Sim' : 'Não',
          type: 'text',
        },
        {
          label: 'Data da aprovação',
          getValue: (item: Refund) =>
            item.approval_date ? formatDate(item.approval_date) : '',
          type: 'text',
        },
      ],
    },
  ];

  statusOptions: SelectOption[] = statusOptions
    .slice()
    .filter(
      (option) =>
        !option.value ||
        option.value === RefundStatus.REFUND_APPROVED ||
        option.value === RefundStatus.REFUND_PENDING ||
        option.value === RefundStatus.REFUND_REPROVED
    );

  refunds: Refund[] = [];

  dateFilters: { from: string; to: string };
  colaboradorFilter: string;
  statusFilter: SelectOption = this.statusOptions[0];
  emptyFilters = true;

  isLoading = false;
  shouldLoadDelayed = false;

  constructor(
    private ngbDateFormatter: NgbDateParserFormatter,
    private refundsService: RefundsService,
    private csvHandler: CSVHandler,
    private translate: TranslationService,
    private userService: UsersService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadRefunds();
  }

  async loadRefunds() {
    this.isLoading = true;
    this.shouldLoadDelayed = false;
    this.refunds = [];
    this.refunds = await this.getPaginatedRefunds();
    this.isLoading = false;
    if (this.shouldLoadDelayed) {
      this.loadRefunds();
    }
  }

  async getPaginatedRefunds(page = 1, loadedRefunds: Refund[] = []) {
    const refunds = await this.refundsService.listInvoices({
      dateFilter: this.dateFilters,
      colaborador: this.colaboradorFilter,
      status: RefundStatus[this.statusFilter.value],
      page,
    });

    if (this.shouldLoadDelayed) {
      this.loadRefunds();
      return [];
    }

    const mergedRefunds = loadedRefunds.concat(refunds);

    if (
      refunds.length === PAGE_LIMIT &&
      mergedRefunds.length < MAX_ITEMS_LOADED
    ) {
      return await this.getPaginatedRefunds(page + 1, mergedRefunds);
    }
    return mergedRefunds;
  }

  onSearchColaborador(colaborador: string) {
    this.emptyFilters = false;
    this.colaboradorFilter = colaborador;
    if (this.isLoading) {
      this.shouldLoadDelayed = true;
      return;
    }
    this.loadRefunds();
  }

  onDateSelected(date: { fromDate: NgbDate; toDate: NgbDate }) {
    this.emptyFilters = false;
    this.dateFilters = {
      from: this.ngbDateFormatter.format(date.fromDate),
      to: this.ngbDateFormatter.format(date.toDate),
    };

    if (this.isLoading) {
      this.shouldLoadDelayed = true;
      return;
    }

    this.loadRefunds();
  }

  onExportar() {
    const collumns = [
      {
        header: this.translate.getTranslation(
          this.getTranslation('idSolicitacao')
        ),
        getValue: (item: Refund) => item.id,
      },
      {
        header: this.translate.getTranslation(
          this.getTranslation('labelDataSolicitacao')
        ),
        getValue: (item: Refund) => formatDate(item.created_at),
      },

      {
        header: this.translate.getTranslation(
          this.getTranslation('labelColaborador')
        ),
        getValue: (item: Refund) => item.user.nome,
      },
      {
        header: this.translate.getTranslation(
          this.getTranslation('labelEmailColaborador')
        ),
        getValue: (item: Refund) => item.user.email,
      },
      {
        header: this.translate.getTranslation(
          this.getTranslation('labelValorTotalReembolso')
        ),
        getValue: (item: Refund) =>
          formatMoney(item.full_refund_amount.toString()),
      },
      {
        header: this.translate.getTranslation(
          this.getTranslation('labelPercentualReembolso')
        ),
        getValue: (item: Refund) => `${item.refund_percentage}%`,
      },
      {
        header: this.translate.getTranslation(this.getTranslation('status')),
        getValue: (item: Refund) =>
          this.translate.getTranslation(
            `REFUND_STATUS.${statusMap[item.status]}`
          ),
      },
      {
        header: this.translate.getTranslation(
          this.getTranslation('labelInstituicao')
        ),
        getValue: (item: Refund) => item.institution_name,
      },
      {
        header: 'Curso',
        getValue: (item: Refund) => item.course_name.split('#').join('N'),
      },
      {
        header: this.translate.getTranslation(
          this.getTranslation('labelMatriculaEdupass')
        ),
        getValue: (item: Refund) =>
          item.is_edupass_enrollment ? 'Sim' : 'Não',
      },
      {
        header: 'Comprovante',
        getValue: (item: Refund) =>
          item.refund_receipts[item.refund_receipts.length - 1].receipt_files
            .map(
              (item: ReceiptFile) =>
                `${CONST.API_ENDPOINT}/refunds/invoices/file/${item.url}`
            )
            .join('\n'),
      },

      {
        header: this.translate.getTranslation(
          this.getTranslation('labelRefundName')
        ),
        getValue: (item: Refund) => item.name,
      },
      {
        header: this.translate.getTranslation(this.getTranslation('labelCpf')),
        getValue: (item: Refund) => item.document,
      },
      {
        header: this.translate.getTranslation(
          this.getTranslation('labelPaymentMethod')
        ),
        getValue: (item: Refund) => item.payment_method,
      },
      {
        header: this.translate.getTranslation(this.getTranslation('labelPix')),
        getValue: (item: Refund) => item.pix_key || '',
      },

      {
        header: this.translate.getTranslation(this.getTranslation('bankNum')),
        getValue: (item: Refund) => item.bank_num,
      },
      {
        header: this.translate.getTranslation(this.getTranslation('bankName')),
        getValue: (item: Refund) => item.bank_name,
      },
      {
        header: this.translate.getTranslation(
          this.getTranslation('bankAgency')
        ),
        getValue: (item: Refund) => item.bank_ag,
      },
      {
        header: this.translate.getTranslation(
          this.getTranslation('bankAccount')
        ),
        getValue: (item: Refund) => item.bank_cc,
      },

      {
        header: 'Data da aprovação',
        getValue: (item: Refund) =>
          item.approval_date ? formatDate(item.approval_date) : '',
      },
    ];

    this.csvHandler.generate(this.refunds, {
      collumns,
    });
  }

  onStatusSelected(option: SelectOption | SelectOption[]) {
    this.emptyFilters = false;
    this.statusFilter = option as SelectOption;
    if (this.isLoading) {
      this.shouldLoadDelayed = true;
      return;
    }
    this.loadRefunds();
  }

  clearFilters() {
    this.emptyFilters = true;
    this.dateFilters = undefined;
    this.colaboradorFilter = '';
    this.statusFilter = this.statusOptions[0];
    this.datePicker.clearDates();
    this.loadRefunds();
  }
}
