import React from 'react';
import { Typography, Grid } from '@mui/material';
import { Plan, PlanStatuses } from '@/react/data/idp/interfaces';

interface DateDisplayProps {
  title: string;
  date: string;
}

const DateDisplay = ({ title, date }: DateDisplayProps) => (
  <Grid item xs={12}>
    <Typography variant="h6" fontSize={18} fontWeight={600}>
      {title}
    </Typography>
    <Typography variant="body2" fontSize={14}>
      {date}
    </Typography>
  </Grid>
);

export const StartedAt = ({ plan }: { plan: Plan }) =>
  plan.status === PlanStatuses.DRAFT ? null : (
    <DateDisplay title="Iniciou em" date={plan.startedAt} />
  );

export const EstimatedEndsAt = ({ plan }: { plan: Plan }) =>
  plan.status === PlanStatuses.DRAFT ? null : (
    <DateDisplay title="Fim (calculado/estimado)" date={plan.estimatedEndsAt} />
  );
