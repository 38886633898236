import { Component, OnInit } from '@angular/core';

import { Refund, RefundStatus } from '@/models/refund.model';
import {
  MAX_ITEMS_LOADED,
  PAGE_LIMIT,
  RefundsService,
} from '@/services/api/refunds.service';
import { RouterService } from '@/services/router.service';
import { TablePage } from '@/shared/table-default/table-default.component';
import { formatDate } from '@/utils/date-parser';
import { formatMoney } from '@/utils/money-parser';
import { getAsset } from '@/utils/helpers';
import { TranslatedComponent } from '@/utils/translated.component';

@Component({
  selector: 'app-refund-pending',
  templateUrl: './pending.component.html',
  styleUrls: ['./pending.component.scss'],
})
export class RefundPendingComponent
  extends TranslatedComponent
  implements OnInit
{
  translationKey = 'EMPRESA.REFUND.REFUND_PENDING';

  pendingRefunds: Refund[] = [];

  approvingRefunds = {};
  rejectingRequests = {};
  reasons = {};

  salvarButton = false;
  rejectWarning = false;
  completePage = false;
  isLoading = false;

  getAsset = getAsset;

  tablePages: TablePage[] = [
    {
      columns: [
        {
          label: 'Ações',
          getValue: () => 'Aprovar',
          itemChecked: (rowIndex) => this.approvingRefunds[rowIndex],
          checkItem: (rowIndex) => {
            this.salvarButton = true;
            this.approvingRefunds[rowIndex] = !this.approvingRefunds[rowIndex];
            this.rejectingRequests[rowIndex] = false;
          },
          type: 'radio',
        },
        {
          label: '',
          getValue: () => 'Reprovar',
          itemChecked: (rowIndex) => this.rejectingRequests[rowIndex],
          checkItem: (rowIndex) => {
            this.salvarButton = true;
            this.approvingRefunds[rowIndex] = false;
            this.rejectingRequests[rowIndex] =
              !this.rejectingRequests[rowIndex];
          },
          type: 'radio-text',
        },
        {
          label: 'Data de solicitação',
          getValue: (item: Refund) => formatDate(item.created_at),
          type: 'text',
        },
        {
          label: 'Nome',
          getValue: (item: Refund) => item.user.nome,
          type: 'text',
        },
        {
          label: 'E-mail',
          getValue: (item: Refund) => item.user.email,
          type: 'text',
        },
        {
          label: 'Valor do Reembolso',
          getValue: (item: Refund) =>
            formatMoney(item.full_refund_amount.toString()),
          type: 'text',
        },
        {
          label: 'Reembolso (%)',
          getValue: (item: Refund) => `${item.refund_percentage}%`,
          type: 'text',
        },
      ],
    },
    {
      columns: [
        {
          label: 'Instituição',
          getValue: (item: Refund) => item.institution_name,
          type: 'text',
        },
        {
          label: 'Curso',
          getValue: (item: Refund) => item.course_name,
          type: 'text',
        },
        {
          label: 'Matrícula com Galena',
          getValue: (item: Refund) =>
            item.is_edupass_enrollment ? 'Sim' : 'Não',
          type: 'text',
        },

        {
          label: 'Justificativa',
          getValue: (item: Refund) => item.reason || '',
          type: 'text',
        },
      ],
    },
  ];

  constructor(
    private refundService: RefundsService,
    private routerService: RouterService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadPendingRefunds();
  }

  async getPaginatedRefunds(page = 1, cachedRefunds: Refund[] = []) {
    try {
      const refunds = await this.refundService.listRefunds({
        status: RefundStatus.COURSE_PENDING,
        page,
      });

      const mergedRefunds = cachedRefunds.concat(refunds);

      if (
        refunds.length === PAGE_LIMIT &&
        mergedRefunds.length < MAX_ITEMS_LOADED
      ) {
        return await this.getPaginatedRefunds(page + 1, mergedRefunds);
      }
      return mergedRefunds;
    } catch (e) {
      console.error(e);
    }
  }

  async loadPendingRefunds() {
    this.isLoading = true;
    this.pendingRefunds = [];
    this.pendingRefunds = await this.getPaginatedRefunds();
    this.isLoading = false;
  }

  onSave(reasons) {
    this.reasons = reasons;

    const isRejecting = Object.keys(this.rejectingRequests).some(
      (rejectingIndex) => this.rejectingRequests[rejectingIndex]
    );

    if (isRejecting) {
      this.rejectWarning = true;
    } else {
      this.onConfirmReject();
    }
  }

  getApprovingIds(): number[] {
    const filteredApprovingItems = Object.entries(this.approvingRefunds).filter(
      ([_key, value]) => value
    );

    return filteredApprovingItems.map(([key, _value]) => {
      return this.pendingRefunds[key].id;
    });
  }

  getReprovingRequests() {
    const filteredRejectingItems = Object.entries(
      this.rejectingRequests
    ).filter(([_key, value]) => value);

    return filteredRejectingItems.map(([key, _value]) => {
      return {
        id: this.pendingRefunds[key].id,
        reason: this.reasons[key],
      };
    });
  }

  async onConfirmReject() {
    this.rejectWarning = false;
    this.completePage = true;

    await this.refundService.reviewCourseRequests({
      approvingRequestsIds: this.getApprovingIds(),
      rejectingRequests: this.getReprovingRequests(),
    });

    this.rejectingRequests = {};
    this.approvingRefunds = {};

    this.loadPendingRefunds();
  }

  onGoToActive() {
    this.routerService.navigate(['/empresa/refund/refund_report']);
  }
}
