import React from 'react';
import { ArrowForward } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { ShowPoliciesProps } from './interfaces';

const ShowPolicies:React.FC<ShowPoliciesProps> = ({onclick}) => {

  return (
    <>
      <Box sx={({palette}) => ({
        maxHeight: '80px',
        width: { xs: '100%', md: 'auto' },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: palette.neutral[50],
        border:`1px solid ${palette.neutral[300]}`,
        p: 2,
      })}>
        <Button
          color="primary"
          size="small"
          endIcon={<ArrowForward />}
          variant="text"
          onClick={onclick}
          sx={({palette})=>({
            borderRadius: 0,
            alignSelf: 'flex-end',
            textDecoration: 'underline',
            ':hover':{
              textDecoration: 'underline',
              color: palette.primary[900],
              backgroundColor: palette.primary[300],
            }
        })}>
          Ver regras de reembolso
        </Button>
      </Box>
    </>
  );
};
export default ShowPolicies;
