import React from 'react';
import { Grid, Typography } from '@mui/material';
import { formatDate } from '@/react/utils/date';
import { useRefundRequest } from '@/react/components/refund/refund-request/state/useRefundRequest';
import { ResourceApproval } from '@/react/data/subsidy/interfaces';

type useRefundRequest = {
  resourceApproval: ResourceApproval;
};

export const ShowReceiptsDetails: React.FC<useRefundRequest> = ({
  resourceApproval,
}) => {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        justifyContent="center"
        sx={({ palette, spacing }) => ({
          color: palette.neutral[800],
          backgroundColor: 'transparent',
          marginBottom: spacing(2),
          paddingLeft: spacing(3),
          paddingRight: spacing(3),
          paddingBottom: spacing('0.5'),
          paddingTop: spacing('0.5'),
        })}
      >
        <Typography variant="body3">
          {`Enviado em: ${formatDate(resourceApproval?.refund_request?.created_at)}`}
        </Typography>
      </Grid>
    </Grid>
  );
};
