import * as React from 'react';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';

import { useFilters } from '@/react/components/filters/state';

const Styled = {
  FormControlLabel: styled(FormControlLabel)(({ theme }) => ({
    alignItems: 'flex-start',

    '& .checkboxLabel': {
      padding: `${theme.spacing(1)} 0`,
    },
  })),
};

export const FilterCheckbox = ({
  id,
  options,
  title,
  type,
}: {
  id: string;
  options: { label: string; value: string }[];
  title?: string;
  type: string;
}) => {
  const { actions, state } = useFilters();

  const handleChange = (e) => {
    actions.setCheckboxSelectionById(id, e.target.value);
    actions.setPage(1);
  };

  return (
    <FormControl component="fieldset" margin="dense" data-filter-type={type}>
      <FormLabel component="legend">{title}</FormLabel>
      <FormGroup>
        {options.map((option) => {
          return (
            <Styled.FormControlLabel
              key={option.value}
              control={
                <Checkbox
                  checked={state[id].values.includes(option.value)}
                  size="small"
                  onClick={handleChange}
                  value={option.value}
                />
              }
              label={
                <Typography className="checkboxLabel" variant="body3">
                  {option.label}
                </Typography>
              }
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
};
