import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { Course } from 'src/app/models-new/media/course.model';
import { MediaService } from 'src/app/services/media.service';
import { RouterService } from 'src/app/services/router.service';

@Component({
  selector: 'app-completed-course',
  templateUrl: './completed-course.component.html',
  styleUrls: ['./completed-course.component.scss'],
})
export class CompletedCourseComponent implements OnInit {
  course: Course;

  constructor(
    private mediaService: MediaService,
    private activatedRoute: ActivatedRoute,
    private routerService: RouterService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(({ id: courseId }) => {
      this.loadCourse(courseId);
    });
  }

  async loadCourse(courseId: string) {
    this.course = await this.mediaService.getCourseById(courseId);
    if (!this.course.isCompleted) {
      this.routerService.navigate(['../'], { relativeTo: this.activatedRoute });
    }
  }

  downloadCertificate() {
    this.mediaService.downloadCertificate(this.course.id);
  }
}
