import * as React from 'react';
import { AlertProps } from '@mui/material/Alert';
import { useSnackbar, SnackbarContent, CustomContentProps } from 'notistack';
import { Toast } from '@/react/components/notification/toast.component';
import { Typography } from '@mui/material';

declare module 'notistack' {
  interface VariantOverrides {
    toast: {
      title?: React.ReactNode | string;
      type: AlertProps['severity'];
    };
  }
}

interface ToastExtraProps {
  title?: React.ReactNode | string;
  type: AlertProps['severity'];
}

export interface NotistackToastProps
  extends ToastExtraProps,
    CustomContentProps {}

export const NotistackToast = React.forwardRef<
  HTMLDivElement,
  NotistackToastProps
>((props, ref) => {
  const { closeSnackbar } = useSnackbar();

  const { id, message, style, title, type } = props;

  return (
    <SnackbarContent
      data-toast-id={`toast-${id}`}
      data-toast-type={`toast-${type}`}
      data-testid={id}
      ref={ref}
      role="alert"
      style={style}
    >
      <Toast
        content={<Typography variant="body2">{message}</Typography>}
        onClose={() => closeSnackbar(id)}
        title={title}
        type={type}
      />
    </SnackbarContent>
  );
});
