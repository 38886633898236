import { createState } from '@/react/components/quiz/utils/state';
import { DevMenuItem, DevMenuStateValue } from './dev-menu.interfaces';
import { ReactNode, useEffect, useState } from 'react';

const state = createState<DevMenuStateValue>({
  isOpen: false,
  byId: {},
  items: [],
});

// functions can't be passed to immer, so we keep it external
export const components: {
  [K: string]: (state: DevMenuStateValue, action: DevMenuState) => ReactNode;
} = {};

function syncComponents(item: DevMenuItem) {
  components[item.id] = item.render;
  components[item.id] = item.render;
  if (item.children?.length) {
    Object.values(item.children).forEach((el) => syncComponents(el));
  }
}

state.addMiddleware(({ draft }) => {
  draft.items = Object.values(draft.byId);
});

export const DevMenuState = state.withMethods({
  open(state) {
    state.isOpen = true;
  },
  close(state) {
    state.isOpen = false;
  },
  toggle(state) {
    state.isOpen = !state.isOpen;
  },
  addItems(state, items: DevMenuItem[]) {
    items.forEach((item) => {
      state.byId[item.id] = item;
      syncComponents(item);
    });
  },
  removeItems(state, ids: string[]) {
    ids.forEach((el) => {
      delete state.byId[el];
    });
  },
});

export type DevMenuState = typeof DevMenuState;

export function useDevMenu(): [DevMenuStateValue, DevMenuState] {
  const [state, setState] = useState(() => DevMenuState.current());

  useEffect(() => {
    return DevMenuState.observe(
      (state) => state,
      ({ next }) => {
        setState(next);
      }
    );
  }, [setState]);

  return [state, DevMenuState];
}
