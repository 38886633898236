import * as React from 'react';
import { Box, Divider, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { useRefundRequest } from '@/react/components/refund/refund-request/state/useRefundRequest';
import { formatMoney } from '@/react/utils/money.js';
import { formatDuration } from '@/react/utils/time';
import { CourseTypes, PeriodLabels, Modalities } from '@/react/data/catalog/interfaces';
import { formatDate } from '@/react/utils/date';
import { useRefundStatus } from '@/react/components/refund/utils/useRefundStatus';
import { calculateRefundableValueForPolicy } from '@/react/data/subsidy/calculateRefundableValueForPolicy';
import { RefundableValue } from '@/react/components/refund/refund-request/description-step/refund-summary/refund-summary.component';

export const ExtensiveOverview: React.FC = () => {
  const { resourceApproval, loading, subsidyPolicy } = useRefundRequest();
  const { course } = resourceApproval?.resource || {};
  const { isFetching: isFetchingRefundableValue,  data: refundableValueResponse } =
    calculateRefundableValueForPolicy({
      course: {
        checkout_type: 'internal',
        course_type: course?.course_type,
        duration_type: course?.duration_type,
        duration: course?.duration,
        id: Number(course?.id),
        price: parseFloat(resourceApproval?.amount),
        type: resourceApproval?.sale_type,
      }
    });

  const refundableValue = refundableValueResponse?.refundable_value?.amount ?? 0

  const courseTypeMapping = {
    [CourseTypes.FREE]: 'Livre',
    [CourseTypes.GRADUATION]: 'Graduação',
    [CourseTypes.LANGUAGES]: 'Idiomas',
    [CourseTypes.POSTGRADUATE]: 'Pós-graduação',
    [CourseTypes.TECHNICAL]: 'Técnico',
  };

  const getCourseType = ( type ) => {
    const translatedType = courseTypeMapping[type];
    return translatedType;
  };

  const periodMapping = {
    [PeriodLabels.MORNING]: 'Manhã',
    [PeriodLabels.AFTERNOON]: 'Tarde',
    [PeriodLabels.EVENING]: 'Noite',
    [PeriodLabels.FLEX]: 'Flexível',
    [PeriodLabels.FULL_TIME]: 'Integral',
    [PeriodLabels.UPON_REQUEST]: 'Sob consulta',
  };

  const getPeriod = (period) => {
    return periodMapping[period];
  };

  const modalityMapping = {
    [Modalities.ONLINE]: 'Online',
    [Modalities.LIVE_ONLINE]: 'Online ao vivo',
    [Modalities.HYBRID]: 'Híbrido',
    [Modalities.PRESENTIAL]: 'Presencial',
    [Modalities.PRIVATE_CLASS]: 'Aula particular',
  };

  const getModality = (modality) => {
    return modalityMapping[modality];
  };

  const { mappedStatus } = useRefundStatus(resourceApproval);

  return (
    <>
    { mappedStatus && (mappedStatus === 'canceledResourceApprovalStepOne') && (
      <Grid container>
        <Grid item xs={12} md={4} justifyContent="center">
          <Box sx={({palette, spacing}) => ({
            color: palette.error[900],
            backgroundColor: palette.error[100],
            padding: '4px  12px',
            marginBottom: spacing(2),
            paddingLeft: spacing(3),
            paddingRight: spacing(3),
            paddingBottom: spacing('0.5'),
            paddingTop: spacing('0.5'),
          })}>
            <Typography variant="body3">
              { `Solicitação cancelada em: ${formatDate(resourceApproval?.decided_at)}`}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    )}
      <Grid container px={2} rowSpacing={{ xs: 1, md: 2}}>
        <Grid item xs={12} md={2}>
          <Typography variant="body3" fontWeight={600}>
            Catálogo
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Typography variant="body3">
            { course?.source === 'catalog' ? 'Pataforma Galena' : 'Fora da Galena'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant="body3" fontWeight={600}>
            Tipo de item
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Typography variant="body3">{resourceApproval?.category}</Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant="body3" fontWeight={600}>
            Instituição
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Typography variant="body3">{course?.institution_name}</Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant="body3" fontWeight={600}>
            Curso
          </Typography>
        </Grid>
        { (course?.source === 'external') ? (
            <Grid item xs={12} md={10}>
              <Typography variant="body3">{resourceApproval?.name}</Typography>
            </Grid>
          ) : (
            <Grid item xs={12} md={10}>
                <Typography variant="body3">{resourceApproval?.name ? resourceApproval.name.split(',')[0] : []}</Typography>
            </Grid>
          )
        }
        <Grid item xs={12} md={2}>
          <Typography variant="body3" fontWeight={600}>
            Categoria
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Typography variant="body3">{getCourseType(course?.course_type)}</Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant="body3" fontWeight={600}>
            Modalidade
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
            <Typography variant="body3">{getModality(course?.modality)}</Typography>
        </Grid>
        { (course?.modality === 'presential' || course?.modality === 'hybrid' ) && (
          <>
            <Grid item xs={12} md={2}>
              <Typography variant="body3" fontWeight={600}>
                Estado
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <Typography variant="body3">{course?.address.state}</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography variant="body3" fontWeight={600}>
                Cidade
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <Typography variant="body3">{course?.address.city}</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography variant="body3" fontWeight={600}>
                Polo
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <Typography variant="body3">{course?.address.campus}</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography variant="body3" fontWeight={600}>
                Turno
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              { course?.period && (
                <Typography variant="body3">{getPeriod(course?.period)}</Typography>
              )}
            </Grid>
          </>
        )
      }
        <Grid item xs={12} md={2}>
          <Typography variant="body3" fontWeight={600}>
            Carga horária
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Typography variant="body3">{course?.workload}</Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant="body3" fontWeight={600}>
            Duração
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          { course?.duration_type && Number.isFinite(course?.duration) && (
            <Typography variant="body3">{formatDuration(course?.duration_type, course?.duration)}</Typography>
          )}
        </Grid>

        <Grid item xs={12} md={2}>
          <Typography variant="body3" fontWeight={600}>
            Tipo de pagamento
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Typography variant="body3">{resourceApproval?.sale_type === 'one_off' ? 'Único' : 'Mensal'}</Typography>
        </Grid>

        <Grid item xs={12} md={2}>
          <Typography variant="body3" fontWeight={600}>
            Valor
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Typography variant="body3">
            { formatMoney(Number(resourceApproval?.amount))}
          </Typography>
        </Grid>

        <Grid item xs={12} md={2}>
          <Typography variant="body3" fontWeight={600}>
            Link
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Typography variant="body3">{course?.link || ''}</Typography>
        </Grid>

        <Grid item xs={12} md={2}>
          <Typography variant="body3" fontWeight={600}>
            Motivo
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Typography variant="body3">
            { resourceApproval?.decision_comment }
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ my: 3 }} />
      <Grid item xs={12}>
        <Box
          sx={{
            px: 2,
            my: 2,
          }}
        >
          <Stack spacing={2}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 0, sm: 3 }}
            >
              <Box flexGrow={1}>
                <Typography
                  component="div"
                  sx={(theme) => ({
                    fontWeight: theme.typography.fontWeightBold,
                  })}
                  variant="body3"
                >
                  Custo total do item
                </Typography>
              </Box>
              <Box>
                <Typography
                  component="div"
                  display="flex"
                  justifyContent={{ xs: 'left', sm: 'right' }}
                  variant="body3"
                >
                  { formatMoney(parseFloat(resourceApproval?.amount)) }
                </Typography>
              </Box>
            </Stack>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 4 }}
            >
              <Box flexGrow={1}>
                <Typography
                  component="div"
                  sx={(theme) => ({
                    fontWeight: theme.typography.fontWeightBold,
                  })}
                  variant="body3"
                >
                  Valor máximo reembolsável
                </Typography>
              </Box>
              <Box>
                <Typography
                  component="div"
                  display="flex"
                  justifyContent={{ xs: 'left', sm: 'right' }}
                  variant="body3"
                >
                  {isFetchingRefundableValue ? (
                  <Skeleton width={60} />
                ) : (
                  <RefundableValue
                    isValid={!!refundableValue}
                    value={refundableValue}
                  />
                )}
                </Typography>
              </Box>
            </Stack>
          </Stack>
          <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 0, sm: 3 }}
              mt={2}
            >
            <Typography component="p" variant="body3">
              Calculado de acordo com as regras de reembolso da sua
              política. O reembolso final depende também do seu saldo
              disponível.
            </Typography>
          </Stack>
        </Box>
      </Grid>
    </>
  );
};

export default ExtensiveOverview;
