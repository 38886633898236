import { CONST } from 'src/app/constants';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AulaAluno } from 'src/app/models/aula-aluno.model';
import { CursoHorario } from 'src/app/models/curso-horario.model';
import { CursoAluno } from 'src/app/models/curso-aluno';
import { downloadFile } from 'src/app/utils/download-file';

import { getHeaders } from '@/utils/apiHeaders';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  constructor(private http: HttpClient) {}

  private getAll(url: string) {
    return this.http.get(`${CONST.MEDIA_ENDPOINT}/${url}`, {
      headers: getHeaders(),
    });
  }

  private post(url: string, data: any = {}) {
    return this.http.post(`${CONST.MEDIA_ENDPOINT}/${url}`, data, {
      headers: getHeaders(),
    });
  }

  private postFile(url: string, data: any) {
    return this.http.post(`${CONST.MEDIA_ENDPOINT}/${url}`, data, {
      headers: getHeaders(),
      responseType: 'blob',
    });
  }

  private put(url: string, data: any) {
    return this.http.put(`${CONST.MEDIA_ENDPOINT}/${url}`, data, {
      headers: getHeaders(),
    });
  }

  getVimeoURL(aulaId: number): Promise<string> {
    return new Promise((resolve, reject) => {
      this.getAll(`aula/${aulaId}/video`).subscribe(
        (response: any) => {
          console.log('Response', response);
          resolve(response.url);
        },
        (reason) => {
          console.log('Reason', reason);

          reject(reason);
        }
      );
    });
  }

  updateAulaProgresso(
    aulaId: number,
    progress: number,
    videoToken: string,
    cursosHorariosId: number
  ): Promise<AulaAluno> {
    return new Promise((resolve, reject) => {
      this.put(`aula/${aulaId}/progresso`, {
        progress,
        videoToken,
        cursosHorariosId,
      }).subscribe((response: any) => {
        resolve(response.data);
      }, reject);
    });
  }

  getAulasByCursoId(id: number): Promise<CursoHorario> {
    return new Promise((resolve, reject) => {
      this.getAll(`cursos/${id}/aulas`).subscribe(resolve, reject);
    });
  }

  updateAulaAtual(cursoHorarioId, aula_atual): Promise<CursoAluno> {
    return new Promise((resolve, reject) => {
      this.put(`cursos/${cursoHorarioId}/aula_atual`, { aula_atual }).subscribe(
        resolve,
        reject
      );
    });
  }

  downloadCertificado(cursoHorarioId: number): void {
    this.postFile(`cursos/${cursoHorarioId}/certificado`, {}).subscribe(
      (response: Blob) => {
        const downloadURL = URL.createObjectURL(response);
        downloadFile(downloadURL, 'certificado.pdf');
      }
    );
  }

  getCursoCompletoById(cursoHorarioId: number): Promise<CursoAluno> {
    return new Promise((resolve, reject) => {
      this.getAll(`cursos/${cursoHorarioId}/concluido`).subscribe(
        resolve,
        reject
      );
    });
  }

  getMeusCursos(): Promise<CursoAluno[]> {
    return new Promise((resolve, reject) => {
      this.getAll('meus-cursos').subscribe(resolve, reject);
    });
  }

  npsHalf(courseId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.post(`nps_half/${courseId}`).subscribe(() => resolve(), reject);
    });
  }
}
