<app-my-refunds-navigation menu="overview"></app-my-refunds-navigation>

<div class="container">
  <div class="row mt-4">
    <div class="col-12">
      <component-botao (onClick)="goToForm()" [disabled]="budget === 0">
        {{ getTranslation('newRefund') | translate }}
      </component-botao>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-md-6 col-lg-3 py-2">
      <component-select
        [value]="statusFilter"
        [options]="statusOptions"
        label="{{ getTranslation('labelStatus') | translate }}"
        (valueChange)="onStatusSelected($event)"
      ></component-select>
    </div>
    <div class="col-md-6 col-lg-3 py-2">
      <component-date-picker
        #datepicker
        label="{{ getTranslation('labelPeriodo') | translate }}"
        (onChange)="onDateSelected($event)"
      ></component-date-picker>
    </div>
    <div class="col-md-6 col-lg-3 py-2 d-flex align-items-end">
      <component-botao
        [disabled]="emptyFilters"
        (click)="clearFilters()"
        type="secondary"
      >
        {{ getTranslation('btnClearFilters') | translate }}
      </component-botao>
    </div>

    <div
      class="col-md-6 col-lg-3 py-2 d-flex flex-column justify-content-end align-self-end ml-auto"
    >
      <div class="d-flex justify-content-center bg-white p-3">
        <span class="BodyText-2">
          {{ getTranslation('budget') | translate }}</span
        >
        <span class="BodyText-2-SemiBold ml-3">{{
          budget | currency : 'BRL' : 'R$' : '1.2-2' : 'pt-BR'
        }}</span>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="w-100">
      <app-table-default
        *ngIf="!isLoading"
        [tablePages]="tablePages"
        [data]="refunds"
        [hideSaveButton]="true"
      ></app-table-default>

      <component-skeleton-card *ngIf="isLoading"></component-skeleton-card>
    </div>
  </div>
</div>
