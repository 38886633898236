import { RouterService } from 'src/app/services/router.service';
import { Component, Input, OnInit } from '@angular/core';

export type NavItem = {
  label: string;
  route: string;
  name: string;
};

@Component({
  selector: 'component-banner-navigation',
  templateUrl: './banner-navigation.component.html',
  styleUrls: ['./banner-navigation.component.scss'],
})
export class BannerNavigationComponent implements OnInit {
  @Input()
  navItems: NavItem[] = [];

  @Input()
  menu: string;

  @Input()
  bannerHeader: string;

  constructor(private routerService: RouterService) {}

  ngOnInit(): void {}

  navigate(route: string) {
    this.routerService.navigate([route]);
  }
}
