import { RouterService } from './../router.service';
import { UsersService } from 'src/app/services/users.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CorporateUniversityGuard implements CanActivate {
  constructor(
    private usersService: UsersService,
    private routerService: RouterService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const routeLms = route.data.isLMS !== undefined ? route.data.isLMS : true;

    if (
      routeLms
        ? !this.usersService.user.hasLMS()
        : this.usersService.user.hasLMS()
    ) {
      this.routerService.home();
    }

    return true;
  }
}
