import { MenuItem } from '@/react/components/mega-menu';
import { useMemo } from 'react';
import { createSimpleRouter, filterNull, keyBy } from 'powership';
import { BootstrapInterfaces } from '@/react/data/bootstrap/interfaces';
import { useBootstrapData } from '@/react/data/bootstrap/useBootstrapData';

const routes = createSimpleRouter({
  catalogo: {
    path: `/cursos/novo_catalogo`,
    query: {
      knowledge_areas: 'int?',
      course_types: 'string?',
      feature_full: { literal: 'true', optional: true },
    },
  },
  courseThemes: {
    path: `/cursos/temas`,
  },
} as const);

export type MenuData = {
  menu: {
    knowledge_areas_menus: MenuItem[];
    course_types_menus: MenuItem[];
    byId: { [K: string]: MenuItem | null };
  };
  serverData: {
    course_types: BootstrapInterfaces['courseType'][];
    knowledge_areas: BootstrapInterfaces['knowledgeArea'][];
    plan: BootstrapInterfaces['plan'];
  } | null;
};

function _mount(init: {
  course_types: BootstrapInterfaces['courseType'][];
  knowledge_areas: BootstrapInterfaces['knowledgeArea'][];
  plan: BootstrapInterfaces['plan'] | null;
  legacyLMS: boolean;
}) {
  const { course_types, knowledge_areas, plan, legacyLMS } = init;

  // idiomas, graduação, etc.
  const course_types_menus = course_types.map((item, idx): MenuItem => {
    return {
      label: item.label,
      id: `course_types_${item.type}_${idx}`,
      route: routes.catalogo.mount({
        query: {
          knowledge_areas: undefined,
          course_types: item.type,
          feature_full: undefined,
        },
      }),
    };
  });

  // adm, ambiente, engenharia, etc.
  const knowledge_areas_menus = knowledge_areas.map((item, idx): MenuItem => {
    return {
      label: item.description_pt_br,
      id: `knowledge_areas_${item.id}_${idx}`,
      route: routes.catalogo.mount({
        query: {
          knowledge_areas: item.id,
          course_types: undefined,
          feature_full: undefined,
        },
      }),
    };
  });

  const tree = filterNull<MenuItem>([
    {
      id: 'start',
      label: 'Início',
      route: '/',
    },

    //
    {
      id: 'courses',
      label: 'Cursos',
    },

    //
    {
      id: 'learning',
      route: legacyLMS
        ? '/corporate_university/trilhas'
        : '/eduplay/cursos_andamento',
      label: 'Meu aprendizado',
    },

    // inclusos no plano
    (() => {
      const features = plan?.features;
      const fullIncluded = features?.some((el) => el.name === 'full_included');

      if (fullIncluded) {
        return {
          route: routes.catalogo.mount({
            query: {
              feature_full: 'true',
              knowledge_areas: undefined,
              course_types: undefined,
            },
          }),
          label: 'Inclusos no seu plano',
          id: 'included_in_your_plan',
        };
      }

      return null;
    })(),
  ]);

  const result: MenuData = {
    menu: {
      course_types_menus,
      knowledge_areas_menus,
      byId: keyBy(tree, (el) => el.id),
    },
    serverData: {
      course_types,
      knowledge_areas,
      plan,
    },
  };

  return result;
}

export function useMenuItems() {
  const bootstrapData = useBootstrapData();

  return useMemo((): MenuData => {
    const course_types = bootstrapData?.taxonomy?.course_types;
    const knowledge_areas = bootstrapData?.taxonomy?.knowledge_areas;
    const plan = bootstrapData?.plan || null;
    const legacyLMS =
      bootstrapData?.info?.colaborador?.empresa?.feature_lms || false;

    if (!knowledge_areas || !course_types) {
      return {
        menu: {
          byId: {},
          course_types_menus: [],
          knowledge_areas_menus: [],
        },
        serverData: null,
      };
    }

    return _mount({ knowledge_areas, course_types, plan, legacyLMS });
  }, [bootstrapData]);
}
