import { ColorPartial } from '@mui/material/styles/createPalette';
import { createTheme } from '@mui/material/styles';
import * as components from './components';
import palette from './palette';
import { createSpacing } from './spacing';
import shadows from './shadows';
import shape from './shape';
import typography from './typography';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }

  interface TypographyVariants {
    subtitle3: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    subtitle3?: React.CSSProperties;
  }

  interface Palette {
    neutral: ColorPartial;
  }

  interface PaletteOptions {
    neutral?: ColorPartial;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    subtitle3: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    planFamily: true;
    planFull: true;
    planStart: true;
    statusActive: true;
    statusPending: true;
    statusRemoved: true;
    waitingForRevision: true;
    negativeRequestResponse: true;
    actionNeededAfterRevision: true;
    waitingForConclusion: true;
    processConcludedSuccessfully: true;
  }
}

const theme = createTheme({
  components: {
    ...components,
  },
  palette,
  shadows,
  spacing: createSpacing,
  shape,
  typography,
});

export default theme;
