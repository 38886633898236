<div
  class="container-fluid bg-edupass"
  [ngStyle]="{ backgroundImage: getBackground() }"
>
  <div class="container">
    <div class="row justify-content-center p-4">
      <div class="col-12 text-center">
        <img
          class="m-4 logo-edupass"
          [src]="getAsset('new-register-galena-logo.svg')"
        />
      </div>

      <div class="col-lg-6 p-2 my-4 d-flex flex-column align-items-center">
        <app-card class="w-100" padding="0">
          <div class="d-flex flex-column align-items-center p-5">
            <p class="Subtitle-2-Regular mb-4">É seu primeiro acesso?</p>
            <component-botao
              (onClick)="onExpand(0)"
              *ngIf="expanded !== 0"
              type="secondary"
              icon="expand_more"
              >Cadastre-se aqui</component-botao
            >
            <form class="form" [formGroup]="registerForm" #formRegisterElement>
              <p class="BodyText-1 text-center text-lg-left">
                Caso queira fazer o seu cadastro, por favor preencha as
                informações abaixo
              </p>

              <div
                *ngIf="registerError"
                class="alert alert-danger mt-2"
                role="alert"
              >
                {{ registerError }}
              </div>
              <div class="mt-4">
                <component-input
                  label="Qual é o seu nome completo"
                  type="text"
                  placeholder="Nome Sobrenome"
                  formControlName="name"
                  [error]="
                    !registerForm.controls.name.valid &&
                    registerForm.controls.name.touched
                  "
                  errorMessage="Campo obrigatório"
                ></component-input>
              </div>
              <div class="mt-4">
                <component-input
                  label="Qual e-mail você mais utiliza?"
                  type="e-mail"
                  placeholder="nome@exemplo.com.br"
                  formControlName="email"
                  [error]="
                    !registerForm.controls.email.valid &&
                    registerForm.controls.email.touched
                  "
                  errorMessage="Email inválido"
                ></component-input>
              </div>
              <div class="mt-4">
                <component-input
                  label="Em qual empresa você trabalha?"
                  type="text"
                  placeholder="Ex: Galena"
                  formControlName="company"
                  [error]="
                    !registerForm.controls.company.valid &&
                    registerForm.controls.company.touched
                  "
                  errorMessage="Campo obrigatório"
                ></component-input>
              </div>

              <div *ngIf="company?.check_employee_code" class="mt-4">
                <component-input
                  label="Qual é o seu nºde matrícula?"
                  type="text"
                  placeholder="Insira aqui o número da sua matrícula"
                  formControlName="employee_code"
                  [error]="invalidEmployeeCode"
                  errorMessage="Número de matrícula inválido"
                ></component-input>
              </div>

              <div class="mt-4 d-flex justify-content-center">
                <component-botao
                  (onClick)="onRegister()"
                  [disabled]="!registerForm.valid"
                  type="primary"
                  >Cadastrar</component-botao
                >
              </div>
            </form>
          </div>
        </app-card>
      </div>

      <div class="col-lg-6 p-2 my-4 d-flex flex-column align-items-center">
        <app-card class="w-100" padding="0">
          <div class="d-flex flex-column align-items-center p-5">
            <p class="Subtitle-2-Regular mb-4 text-center">
              Já possui uma conta?
            </p>

            <component-botao
              (onClick)="onExpand(1)"
              type="secondary"
              *ngIf="expanded !== 1"
              icon="expand_more"
              >Acessar agora</component-botao
            >

            <form class="form" [formGroup]="loginForm" #formLoginElement>
              <p class="BodyText-1 text-center text-lg-left">
                Se você já se cadastrou em algum momento, preencha as
                informações abaixo e acesse o seu benefício.
              </p>

              <p class="BodyText-1 text-center text-lg-left mt-4">
                Caso tenha esquecido a sua senha, clique em “esqueci minha
                senha” para redefini-la (:
              </p>

              <div
                *ngIf="loginError"
                class="alert alert-danger mt-2"
                role="alert"
              >
                Usuário ou senha inválidos
              </div>

              <div class="mt-4">
                <component-input
                  type="text"
                  label="E-mail ou CPF"
                  placeholder="Insira seu e-mail ou seu cpf"
                  formControlName="email"
                  [error]="
                    !loginForm.controls.email.valid &&
                    loginForm.controls.email.touched
                  "
                  errorMessage="Email inválido"
                ></component-input>
              </div>
              <div class="mt-4">
                <component-input
                  type="password"
                  label="Senha"
                  placeholder="Insira a senha aqui"
                  formControlName="password"
                  [error]="
                    !loginForm.controls.password.valid &&
                    loginForm.controls.password.touched
                  "
                  errorMessage="Campo obrigatório"
                ></component-input>
              </div>
              <div
                class="d-flex justify-content-center w-100 mt-4 flex-wrap-reverse"
              >
                <component-botao (onClick)="onForgorPassword()" type="text"
                  >Esqueci minha senha</component-botao
                >
                <component-botao
                  [disabled]="!this.loginForm.valid"
                  (onClick)="onLogin()"
                  class="ml-sm-4 mb-xs-4"
                  type="primary"
                  >Entrar</component-botao
                >
              </div>
            </form>
          </div>
        </app-card>
      </div>

      <div class="col-12 text-center mt-4">
        <p class="BodyText-1-SemiBold text-white">Dúvidas?</p>
        <p class="BodyText-1-SemiBold text-white">Fale conosco</p>
        <a href="https://wa.me/5511987566079" target="_blank"
          ><img class="m-4" [src]="getSvg('logo-whatsapp.svg')"
        /></a>
      </div>

      <div class="col-12 text-center mt-4 mb-4">
        <p class="BodyText-1-SemiBold text-white">Saiba mais</p>
        <a
          href="https://www.linkedin.com/company/plataformagalena/mycompany/"
          target="_blank"
          ><img class="m-4" [src]="getSvg('logo-linkedin.svg')"
        /></a>
        <a href="https://www.instagram.com/plataformagalena/?igsh=dW1hNG9scDFkODFi" target="_blank"
          ><img class="m-4" [src]="getSvg('logo-instagram.svg')"
        /></a>
        <a href="https://galena.com/" target="_blank"
          ><img class="m-4" [src]="getSvg('globe-outline.svg')"
        /></a>
      </div>
    </div>
  </div>
</div>
