<h3 class="mb-4">
  <strong>Aviso de Privacidade </strong>
</h3>
<p>Aviso de Privacidade publicado em: 04 de maio de 2021.</p>
<p>
  A Deloitte Brasil, firma-membro da DTTL no Brasil (também referida abaixo como
  "nós" ou "nosso") é uma organização global consciente da importância da
  privacidade e proteção de dados pessoais. Este aviso de privacidade e proteção
  de dados pessoais expressa nosso compromisso com o tratamento de seus dados
  pessoais de modo responsável, ético, em linha com nossos princípios e valores
  e, especialmente, de acordo com as regras da Lei nº 13.709/2018 (Lei Geral de
  Proteção de Dados Pessoais - “LGPD”).
</p>
<p>
  Ao utilizar esta plataforma, você concorda com o uso de suas informações como
  está descrito neste aviso de privacidade e proteção de dados pessoais.
</p>
<p>
  Este aviso de privacidade se aplica a todas as informações pessoais
  necessárias à utilização da plataforma, conforme a seguir: (1) páginas da web
  de site ou aplicativo em que este aviso é publicado ou vinculado. (2)
  aplicativos acessados de dispositivos móveis em que este aviso é publicado ou
  vinculado (“aplicativo móvel”), ou (3) qualquer outro modo de interação com
  você em relação à plataforma, que fizer referência a este aviso de
  privacidade. Este aviso descreve os tipos de dados pessoais que coletamos, as
  finalidades para as quais os coletamos e tratamos, os terceiros com os quais
  poderemos compartilhá-los, as medidas que adotamos para protegê-los e como
  você pode acessar seus direitos enquanto titular de dados pessoais.
</p>
<p>
  <strong>Quais dados pessoais são coletados? </strong>Os tipos de dados que
  coletamos podem variar dependendo da natureza dos serviços que fornecemos a
  você ou ao nosso cliente, ou como você utiliza nossa plataforma. Dentre os
  dados coletados estão (mas não se limita a): Dados pessoais, relacionados à
  pessoa natural identificada, ou identificável, como nome, telefone, números de
  documentos (CPF, RG, CTPS, PIS, etc.), endereço residencial ou comercial,
  e-mail, data de nascimento, estado civil, nacionalidade, sexo, nome do
  empregador e cargo profissional, dados do cônjuge/dependentes, dados de
  localização, informações de conexão (cookies).
</p>
<p>
  Normalmente, não buscamos coletar informações pessoais sensíveis, dados
  relacionados à raça ou origem étnica, crenças religiosas ou filosóficas,
  filiação a sindicatos, opiniões políticas, condições médicas ou de saúde ou
  informações especificando a vida sexual ou orientação sexual de um usuário, e
  exceções ocorrerão no seu melhor interesse, nos termos da legislação vigente.
</p>
<p>
  Entendemos a importância de proteger a privacidade e dados pessoais das
  crianças e adolescentes. Nossa plataforma e nossos serviços não são projetados
  ou intencionalmente direcionados a crianças e adolescentes. Não é nossa
  política coletar ou armazenar intencionalmente informações sobre este tipo de
  público, todavia, em eventuais situações em que a coleta e o uso desses tipos
  de dados pessoais se fizerem necessários, como por exemplo na prestação de
  serviços que envolvem análise de dados pessoais de dependentes menores de
  idade, o tratamento ocorrerá no melhor interesse da criança e/ou do
  adolescente, nos termos da legislação vigente.
</p>
<p>
  <strong>Como coletamos os seus dados pessoais? </strong>Coletamos e tratamos
  dados pessoais sobre você ou a sua empresa, se aplicável, conforme abaixo:
</p>
<p>
  • <strong>Advindos da sua interação com a Deloitte: </strong>podemos coletar
  ou obter seus dados pessoais porque observamos ou inferimos esses dados sobre
  você, para fornecer informações que acreditamos ser de seu interesse;
</p>
<p>
  • <strong>Advindos do titular: </strong>podemos coletar ou obter tais dados
  porque você nos fornece ou porque estão publicamente disponíveis;
</p>
<p>
  • <strong>Advindos da prestação de serviços: </strong>podemos coletar dados
  pessoais no decorrer da prestação de serviços a você ou ao nosso cliente;
</p>
<p>
  • <strong>Advindos dos nossos clientes: </strong>quando seus dados pessoais
  nos são fornecidos pelo nosso cliente, tomamos medidas para garantir que o
  cliente tenha cumprido as leis e os regulamentos de privacidade e proteção de
  dados.
</p>
<p>
  <strong>Quais as finalidades de tratamento? </strong>Seus dados pessoais serão
  tratados para atender as seguintes finalidades, mas não se limitando a:
</p>
<p>
  • para verificar sua identidade cada vez que você entrar na nossa plataforma;
  • o gerenciar sua conta e melhorar nossa plataforma;
</p>
<p>• o gerenciar e promover a colaboração e a comunicação;</p>
<p>• fornecer e documentar treinamento e qualificações;</p>
<p>
  • adaptar o conteúdo da nossa plataforma para fornecer-lhe uma forma mais
  personalizada experiência; e
</p>
<p>
  • gerenciar nosso relacionamento com você e responder a qualquer solicitação
  que você enviar através da nossa plataforma.
</p>
<p>
  Também podemos usar seus dados pessoais para outras atividades que fazem parte
  da operação de nossos negócios ou em conexão com:
</p>
<p>• Requisitos legais ou regulamentares aplicáveis;</p>
<p>• Pedidos e comunicações de autoridades competentes;</p>
<p>
  • Fins de relacionamento com o cliente, que podem envolver: (i) envio de
  conteúdos de eminência ou detalhes sobre nossos produtos e serviços que
  acreditamos que possam ser de interesse para você; (ii) entrar em contato com
  você para receber feedback sobre os serviços; e (iii) entrar em contato com
  você para outros fins de mercado ou pesquisa;
</p>
<p>
  • Fins de recrutamento ou desenvolvimento de negócios (por exemplo,
  depoimentos de funcionários de um cliente podem ser usados como parte de
  nossos materiais de recrutamento e desenvolvimento de negócios, com a devida
  permissão desse funcionário); • Proteção de nossos direitos e os de nossos
  clientes.
</p>
<p>
  O acesso ao site pode estar sujeito a revisão, monitoramento e gravação a
  qualquer momento para nos ajudar a confirmar a aderência com os termos de uso
  aplicáveis e que apenas partes autorizadas estão acessando o site usando seu
  login de usuário e senha.
</p>
<p>
  <strong
    >Quais os fundamentos legais que usamos para tratar os seus dados pessoais? </strong
  >Somos requeridos por lei a estabelecer neste documento quais os fundamentos
  legais para tratamento dos seus dados pessoais, principalmente relacionados às
  hipóteses legais definidas pela legislação vigente. Como resultado, os seus
  dados pessoais poderão ser tratados segundo as seguintes hipóteses:
</p>
<p>
  • Mediante o fornecimento do seu consentimento para tratamento de seus dados,
  como por exemplo, para lhe conceder acesso a alguma plataforma mantida pela
  Deloitte ou para ofertar nossos produtos e serviços, os quais acreditamos que
  possam ser de interesse para você.
</p>
<p>
  • Quando existentes legítimos interesses da Deloitte no oferecimento e entrega
  de nossos serviços para você ou para nosso cliente, bem como para o
  funcionamento eficaz e lícito dos
</p>
<p>
  nossos negócios, desde que tais interesses não sejam superados pelos seus
  interesses, direitos e liberdades fundamentais.
</p>
<p>
  • Para executar eventual contrato ou procedimentos relacionados a contrato,
  bem como para fornecer nossos serviços a você ou nosso cliente.
</p>
<p>
  • Para exercer regularmente nossos direitos, como por exemplo, nosso direito
  de defesa em algum processo judicial ou administrativo.
</p>
<p>• Para o cumprimento de obrigação legal ou regulatória.</p>
<p>
  • Para a tutela da saúde ou proteção da vida / incolumidade física do titular.
  • Para a proteção do crédito, se aplicável, inclusive quanto ao disposto na
  legislação pertinente.
</p>
<p>
  <strong>Com quem seus dados são compartilhados? </strong>Seus dados pessoais
  poderão ser compartilhados com outros membros da Rede Deloitte; terceiros que
  nos prestam serviços e/ou à Rede Deloitte; autoridades competentes (incluindo
  tribunais e autoridades que nos regulam ou a outro membro da Rede Deloitte);
  empresas de tecnologia responsáveis pelo armazenamento e garantia de segurança
  no tratamento de seus dados; seu empregador e/ou seus assessores;
</p>
<p>
  Também podemos precisar divulgar seus dados pessoais, se exigido por lei,
  regulador ou durante processos judiciais. Podemos compartilhar informações não
  pessoais, não identificadas e agregadas com terceiros para diversos fins,
  incluindo análise de dados, pesquisas, contribuições, conteúdos de eminência e
  fins promocionais.
</p>
<p>
  Seus dados pessoais também poderão ser compartilhados com organizações
  terceiras e empresas do grupo Deloitte estabelecidas em locais fora do
  território nacional para atingir finalidades relacionadas às descritas neste
  aviso de privacidade, garantindo-se, em todo caso, a adoção de salvaguardas
  para proteção de seus dados pessoais e tratamento dentro dos limites legais.
</p>
<p>
  <strong>Por quanto tempos seus dados são armazenados? </strong>Seus dados
  pessoais serão armazenados pelo período necessário para atingimento das
  finalidades para as quais foram coletados ou pelo período necessário para
  cumprir políticas internas, obrigações contratuais, legais e/ou regulatórias.
</p>
<p>
  <strong>Como protegemos os seus dados pessoais? </strong>Usamos uma série de
  medidas físicas, eletrônicas e gerenciais para garantir que seus dados
  pessoais se mantenham seguros, precisos e atualizados. Essas medidas podem
  incluir:
</p>
<p>
  • Educação e treinamento da equipe responsável para que eles estejam cientes
  de nossas obrigações de privacidade e proteção de dados ao lidar com dados
  pessoais; • Controles administrativos e técnicos para restringir o acesso a
  dados pessoais, condicionados à necessidade de conhecimento;
</p>
<p>
  • Medidas tecnológicas de segurança, que podem incluir firewalls, criptografia
  e software antivírus;
</p>
<p>
  • Medidas de segurança física, tais como passes de segurança de empregados
  para acessar nossas instalações.
</p>
<p>
  Apesar de usarmos medidas de segurança apropriadas, uma vez que recebemos seus
  dados pessoais, a transmissão de dados pela internet (inclusive por e-mail)
  nunca é completamente segura. Nós nos esforçamos para proteger os seus dados
  pessoais, mas não podemos garantir a segurança dos dados transmitidos para nós
  ou por nós.
</p>
<p>
  <strong>Quais os seus direitos? </strong>Você possui direitos em relação aos
  seus dados pessoais, os quais podem ser exercidos a qualquer tempo, de forma
  gratuita, através de solicitação registrada no seguinte link [<strong
    ><a href="https://www2.deloitte.com/br/pt/footerlinks/contact-us.html"
      >Contate-nos</a
    > </strong
  >]; dentre eles:
</p>
<p>
  • Obter a confirmação de que estamos processando suas informações pessoais e
  solicitar uma cópia das informações pessoais que mantemos sobre você; e
</p>
<p>
  • Pedir que atualizemos as informações pessoais que mantemos sobre você, ou
  corrigir as informações que você acha que são imprecisas ou incompletas.
</p>
<p>
  • Pedir que excluamos as informações pessoais que mantemos sobre você, caso
  tenham sido obtidas por consentimento, ou restringir a maneira como usamos
  essas informações; e • Retirar o consentimento para o nosso processamento das
  suas informações pessoais (na medida em que o nosso processamento se baseie no
  seu consentimento).
</p>
<p>
  <strong
    >Alterações neste aviso de privacidade e proteção de dados pessoais</strong
  >: Podemos modificar ou alterar este aviso de privacidade e proteção de dados
  pessoais de tempos em tempos. Para informá lo quando fizermos alterações neste
  aviso, alteraremos a data de revisão na parte superior desta página. O novo
  aviso de privacidade modificado ou alterado será aplicado a partir dessa data
  de revisão. Portanto, incentivamos você a periodicamente revisar este aviso
  para ser informado sobre como estamos protegendo suas informações.
</p>
<p>
  Ao fornecer informações por meio desta plataforma, você concorda com as
  divulgações descritas acima.
</p>
<p>
  Para maiores informações sobre o uso dos seus dados pessoais pela Rede
  Deloitte, consulte nossa
  <span style="text-decoration: underline">
    <a
      href="https://resources.deloitte.com/sites/br/administration/politicas/Documents/DBR-QRM-PC003-Politica-de-Privacidade-e-Protecao-de-Dados.pdf?cid=3aad13f9-aeaf-4c94-96a9-102e24194a79"
    >
      Política de Privacidade e Proteção de Dados
    </a> </span
  >.
</p>
