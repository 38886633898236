import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'component-button-icon',
  templateUrl: './botao-icon.component.html',
  styleUrls: ['./botao-icon.component.scss'],
})
export class BotaoIconComponent implements OnInit {
  @Input()
  type: 'primary' | 'secondary' | 'attention' = 'primary';

  @Input()
  disabled: boolean;

  @Input()
  icon: string;

  @Output()
  onClick: EventEmitter<undefined> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  clicked() {
    if (!this.disabled) {
      this.onClick.emit();
    }
  }
}
