<div class="container d-flex flex-column p-3 flex-grow-1">
  <div class="row">
    <div
      #videoContainer
      [ngStyle]="{ height: getHeight() }"
      class="col-lg-8 d-flex flex-column video-container flex-grow-1"
    ></div>
    <div class="navigation-container col-lg-4">
      <app-card
        class="w-100 d-flex h-100 flex-column"
        innerClass="d-flex flex-column w-100 h-100 overflow-auto"
      >
        <div>
          <img
            class="eduplay-logo"
            [src]="getAsset('eduplay_purple.png')"
            alt="eduplay logo"
          />
        </div>
        <p class="title">{{ curso.curso_detalhe.curso.curso }}</p>
        <p class="visao-geral">{{ getTranslation('visao') | translate }}</p>
        <div class="navigation-list">
          <ng-container *ngIf="selectedAula">
            <button
              *ngFor="let aula of aulas"
              class="navigation-item"
              (click)="changeAula(aula)"
              [ngClass]="{ active: selectedAula === aula }"
            >
              <div class="d-flex justify-content-between w-100">
                <span class="aula-titulo">
                  {{ aula.titulo }}
                </span>
                <span class="material-icons-outlined notranslate">
                  play_circle_outline
                </span>
              </div>

              <p>
                {{ getTranslation('progresso') | translate }}
                {{ getProgresso(aula) }}%
              </p>

              <div class="progress-bar">
                <span
                  [ngStyle]="{ width: getProgresso(aula) + '%' }"
                  class="progress-meter"
                  >&nbsp;</span
                >
              </div>
            </button>
          </ng-container>
        </div>
      </app-card>
    </div>
  </div>
  <div *ngIf="cursoCompleto" class="row mt-4">
    <div class="col-12">
      <component-botao type="primary" (onClick)="showCertificado()">{{
        getTranslation('btnVerCertificado') | translate
      }}</component-botao>
    </div>
  </div>
  <div class="mt-4">
    <component-botao *ngIf="curso.has_files" (onClick)="downloadContents()">{{
      getTranslation('downloadContent') | translate
    }}</component-botao>
  </div>
</div>

<app-nps-eduplay-half
  *ngIf="npsModalHalf"
  (onClose)="onCloseNpsHalf()"
></app-nps-eduplay-half>
