import * as React from 'react';
import Grid from '@mui/material/Grid';

import CourseCard from './course-card.component';

const CourseList = ({ items, showcaseItems }) => (
  <Grid container spacing={{ xs: 2, sm: 4 }}>
    {showcaseItems.map((course) => (
      <Grid item xs={12} md={6} lg={4} key={course.id}>
        <CourseCard data={course} showcase />
      </Grid>
    ))}
    {items.map((course) => (
      <Grid item xs={12} md={6} lg={4} key={course.id}>
        <CourseCard data={course} />
      </Grid>
    ))}
  </Grid>
);

export default CourseList;
