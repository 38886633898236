import { Component, EventEmitter, Input, Output } from '@angular/core';

import { getSvg } from 'src/app/utils/helpers';

@Component({
  selector: 'app-eduplay-complete-page',
  templateUrl: './eduplay-complete-page.component.html',
  styleUrls: ['./eduplay-complete-page.component.scss'],
})
export class EduplayCompletePageComponent {
  @Input()
  courseName: string;

  @Output()
  onDownload = new EventEmitter<void>();

  getSvg = getSvg;

  downloadCertificate() {
    this.onDownload.emit();
  }
}
