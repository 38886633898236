import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import { useFilters } from '@/react/components/filters/state';

export const FilterResetButton = ({
  buttonText,
  filterIdsToReset,
  type,
}: {
  buttonText: string;
  filterIdsToReset: string[];
  type: string;
}) => {
  const { actions } = useFilters();

  return (
    <Box data-filter-type={type}>
      <Link
        component="button"
        onClick={() => {
          actions.reset(filterIdsToReset);
          actions.setPage(1);
        }}
        variant="caption"
      >
        {buttonText}
      </Link>
    </Box>
  );
};
