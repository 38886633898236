import * as React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import conclusionJourney from '@/react/assets/images/conclusion-journey.svg';

const RefundRequestCreatedDialog = ({
  open: externalOpen,
}: {
  open: boolean;
}) => {
  const [open, setOpen] = React.useState(externalOpen);

  React.useEffect(() => {
    setOpen(externalOpen);
  }, [externalOpen]);

  const handleClose = () => {
    window.location.assign('/meus-reembolsos/');
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle />
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          color: ({ palette }) => palette.grey[500],
          position: 'absolute',
          right: ({ spacing }) => spacing(2),
          top: ({ spacing }) => spacing(2.5),
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack spacing={3}>
          <Box component="img" src={conclusionJourney} sx={{ width: 128 }} />
          <Typography variant="h6" sx={{ mb: 1 }}>
            Os comprovantes foram enviados!
          </Typography>
        </Stack>
        <Stack spacing={2}>
          <Typography component="p" variant="body3">
            Agora é só aguardar enquanto fazemos a conferência dos documentos e informações enviadas.
          </Typography>
          <Typography component="p" variant="body3">
            Assim que tivermos a resposta enviaremos um email informando. Você também pode conferir o andamento na página de reembolsos.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={handleClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RefundRequestCreatedDialog;
