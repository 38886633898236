import axios from 'axios';
import CONST from '@/constants';
import { withAuthorization } from '@/react/config/axios';
import {
  CalculateCourseRefundableAmountRequest,
  CalculateCourseRefundableAmountResponse,
  RefundableAmountResponse,
  RefundOrderResponse,
  RequestFilteredRefundsList,
  RequestListResponse,
} from './interfaces';
import { RefundUuids } from '@/react/components/refund/refund-request/interfaces';
import {
  ResourceCourseAddress,
  ResourceCourseSources,
  ResourceTypes,
} from '@/react/components/refund/refund-request/interfaces';
import {
  CourseTypes,
  DurationTypes,
  Modalities,
  PriceTypes,
} from '@/react/data/catalog/interfaces';

type CreateResourceApprovalCourse = {
  address?: ResourceCourseAddress; // required for [presential, hybrid] modalities only
  course_type: CourseTypes;
  duration_type?: DurationTypes;
  duration?: string;
  id?: string; // required for [catalog] courses only
  institution_id?: number;
  institution_name?: string;
  modality: Modalities;
  offer_identifier?: string; // required for [catalog] courses only
  period?: string; // required for [presential, hybrid] modalities only
  source: ResourceCourseSources;
  workload?: number;
};

type CreateResourceApprovalRequest = {
  policy: {
    uuid: string;
  };
  resource: {
    amount: string;
    data: {
      course: CreateResourceApprovalCourse;
    };
    link?: string;
    name: string;
    request_reason?: string;
    sale_type: PriceTypes;
    type: ResourceTypes;
  };
};

type CreateRefundRequest = {
  resource_approval: { uuid: string };
  refund_request: {
    supplier_type: string;
    supplier_document: string;
    supplier_name: string;
    receiver_fiscal_document: string;
    purchase_date: string;
    purchase_amount: string;
    payment_date: string;
    payment_amount: string;
    reference_month: string;
    banking_info: {
      holder_name: string;
      bank: string;
      agency: string;
      account_number: string;
      pix_key: string;
      pix_type: string;
    };
  };
  receipts: any;
};

type UpdateRefundRequest = {
  id: string;
} & CreateRefundRequest;

export class SubsidyClient {
  static BASE_URL = `${CONST.FALCON_API_URL}/subsidy/api`;

  constructor() {}

  static client = withAuthorization(
    axios.create({
      baseURL: SubsidyClient.BASE_URL,
    })
  );

  static fetchRefundableAmount = async (input: {
    requestParams: CalculateCourseRefundableAmountRequest;
    signal?: AbortSignal;
  }): Promise<CalculateCourseRefundableAmountResponse> => {
    const { requestParams, signal } = input;

    const res = await SubsidyClient.client.post(
      '/policies/calculate_course_refundable_amount',
      {
        ...requestParams,
      },
      { signal }
    );

    return res.data;
  };

  static createRefundRequest = async (
    requestParams: CreateRefundRequest
  ): Promise<any> => {
    const res = await SubsidyClient.client.post(
      '/refund_requests',
      {
        ...requestParams,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return res.data;
  };

  static updateRefundRequest = async (
    requestParams: UpdateRefundRequest
  ): Promise<any> => {
    const res = await SubsidyClient.client.put(
      `/refund_requests/${requestParams.id}`,
      {
        ...requestParams,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return res.data;
  };

  static createResourceApproval = async (
    requestParams: CreateResourceApprovalRequest
  ): Promise<any> => {
    const res = await SubsidyClient.client.post('/resource_approvals', {
      ...requestParams,
    });

    return res.data;
  };

  static getResourceApprovalsList = async (
    options: {
      requestParams?: RequestFilteredRefundsList;
      signal?: AbortSignal;
    } = {}
  ): Promise<RequestListResponse> => {
    const { requestParams } = options;
    const url = `/resource_approvals?status=${requestParams?.status}`;
    const res = await SubsidyClient.client.get(url);
    return res.data;
  };

  static getAllPolicies = async (): Promise<any> => {
    const url = `/policies`;
    const res = await SubsidyClient.client.get(url);

    return res.data;
  };

  static getRefundableAmount = async (
    payment_amount: string,
    resource_approval_uuid: string,
    uuid_policy: string
  ): Promise<any> => {
    const url = `/policies/${uuid_policy}/resource_approvals/${resource_approval_uuid}/refundable_amount`;
    const res = await SubsidyClient.client.post(url, {
      refund_request: {
        payment_amount,
      },
    });

    return res.data;
  };

  static getRefundRequest = async (
    options: {
      requestParams?: RefundUuids;
      signal?: AbortSignal;
    } = {}
  ): Promise<RefundOrderResponse> => {
    const { requestParams } = options;

    let urlSearchParam = {};
    if (requestParams?.refundRequestUuid) {
      urlSearchParam = { refund_request_uuid: requestParams.refundRequestUuid };
    }
    const url = `/resource_approvals/${requestParams.resourceApprovalUuid}?${new URLSearchParams(urlSearchParam)}`;
    const res = await SubsidyClient.client.get(url);

    return res.data;
  };

  static cancelRefundRequest = async (
    refundRequestUuid: string
  ): Promise<any> => {
    const url = `/refund_requests/${refundRequestUuid}/cancel`;
    const res = await SubsidyClient.client.patch(url);

    return {
      status_code: res.status,
      data: res.data,
    };
  };

  static cancelResourceApproval = async (
    resourceApprovalUuid: string
  ): Promise<any> => {
    const url = `/resource_approvals/${resourceApprovalUuid}/cancel`;
    const res = await SubsidyClient.client.patch(url);

    return {
      status_code: res.status,
      data: res.data,
    };
  };

  static getAvaiableRefundableAmount = async (): Promise<RefundableAmountResponse> => {
    const url = `/resource_approvals/avaiable_refundable_amount`;
    const res = await SubsidyClient.client.get(url);
    return res.data;
  };



}
