import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';

import { QuizState, QuizStateValue } from '../state';

const Context = createContext<QuizState>(null as any);

export function QuizContext(
  props: PropsWithChildren<{ value: QuizState }>
) {
  return (
    <Context.Provider value={props.value}>{props.children}</Context.Provider>
  );
}

// overload with selector
export function useQuiz<Value>(
  selector: (state: QuizStateValue) => Value
): [Value, QuizState];

// overload without selector
export function useQuiz(): [null, QuizState];

// implementation
export function useQuiz<
  Value,
  Selector extends (state: QuizStateValue) => Value
>(selector?: Selector): [Value, QuizState] {
  const context = useContext(Context);

  if (!context?.current) {
    throw new Error(`QuizContext is missing.`);
  }

  const [selected, setSelected] = useState(() => {
    if (!selector) return null;
    return selector(context.current());
  });

  useEffect(() => {
    if (!selector) {
      setSelected(null);
      return;
    }

    const unsubscribe = context.observe(selector, ({ next }) => {
      setSelected(next);
    });

    return () => unsubscribe();
  }, [context, selector]);

  return [selected, context];
}
