<div class="container py-6 px-12 bg-white">
  <div class="row">
    <div class="col-12">
      <form [formGroup]="form">
        <h1 class="Headline-2">
          {{ getTranslation('novaSolicitacao') | translate }}
        </h1>

        <h4 class="mt-5 edupass-purple">
          {{ getTranslation('courseData') | translate }}
        </h4>

        <div class="my-3">
          <label class="BodyText-1-SemiBold">{{
            getTranslation('isEdupass') | translate
          }}</label>
          <div class="d-flex">
            <component-radio
              [checked]="form.controls.is_edupass_enrollment.value"
              (onClick)="isEdupass(true)"
            >
              {{
                getTranslation('EDUPASS_OPTIONS.yes') | translate
              }}</component-radio
            >
            <component-radio
              [checked]="!form.controls.is_edupass_enrollment.value"
              (onClick)="form.controls.is_edupass_enrollment.setValue(false)"
              class="ml-3"
              (onClick)="isEdupass(false)"
            >
              {{
                getTranslation('EDUPASS_OPTIONS.no') | translate
              }}</component-radio
            >
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 col-lg-4 py-2">
            <component-input
              *ngIf="!form.controls.is_edupass_enrollment.value"
              [label]="getTranslation('perguntaNomeFornecedor')"
              formControlName="institution_name"
              [error]="!!formErrors['institution_name']"
              [errorMessage]="formErrors['institution_name']"
              errorPosition="bottom"
            ></component-input>

            <component-select
              *ngIf="form.controls.is_edupass_enrollment.value"
              [label]="getTranslation('perguntaNomeFornecedor')"
              [options]="institutionsOptions"
              (valueChange)="onInstitutionSelected($event)"
              [value]="selectedInstitution"
              [disabled]="institutionsOptions.length === 0"
              [error]="!!formErrors['institution_name']"
              [errorMessage]="formErrors['institution_name']"
              errorPosition="bottom"
            >
            </component-select>
          </div>
          <div class="col-md-6 col-lg-4 py-2">
            <component-input
              *ngIf="!form.controls.is_edupass_enrollment.value"
              [label]="getTranslation('perguntaNomeCurso')"
              formControlName="course_name"
              [error]="!!formErrors['course_name']"
              [errorMessage]="formErrors['course_name']"
              errorPosition="bottom"
            ></component-input>

            <component-select
              *ngIf="form.controls.is_edupass_enrollment.value"
              [label]="getTranslation('perguntaNomeCurso')"
              [options]="coursesOptions"
              (valueChange)="onCourseSelected($event)"
              [value]="selectedCourse"
              [disabled]="coursesOptions.length === 0"
              [error]="!!formErrors['course_name']"
              [errorMessage]="formErrors['course_name']"
              errorPosition="bottom"
            >
            </component-select>
          </div>

          <div class="d-none d-lg-flex col-lg-4 py-2"></div>

          <div class="col-md-6 col-lg-4 py-2">
            <component-input
              [label]="getTranslation('valorCurso')"
              formControlName="total_course_value"
              [disabled]="form.controls.is_edupass_enrollment.value"
              (onBlur)="onCourseValueBlur($event)"
              [error]="!!formErrors['total_course_value']"
              [errorMessage]="formErrors['total_course_value']"
              errorPosition="bottom"
            ></component-input>
          </div>
          <div class="col-md-6 col-lg-4 py-2">
            <component-input
              (onBlur)="onAmountBlur($event)"
              [label]="getTranslation('perguntaValorReembolso')"
              formControlName="full_refund_amount"
              [error]="!!formErrors['full_refund_amount']"
              [errorMessage]="formErrors['full_refund_amount']"
              errorPosition="bottom"
            ></component-input>
          </div>

          <div class="col-lg-4 py-2 d-flex align-items-end">
            <div
              class="d-flex w-100 justify-content-around align-items-center budget"
            >
              <span class="BodyText-1">Saldo disponível</span>
              <span class="BodyText-1-Bold">{{
                budget | currency : 'BRL' : 'R$' : '1.2-2' : 'pt-BR'
              }}</span>
            </div>
          </div>

          <div class="col-lg-8 py-2">
            <component-input
              [isInput]="false"
              [label]="getTranslation('justificativa')"
              formControlName="reason"
              [error]="!!formErrors['reason']"
              [errorMessage]="formErrors['reason']"
              [placeholder]="getTranslation('reasonPlaceholder') | translate"
              errorPosition="bottom"
            ></component-input>
          </div>
        </div>

        <h4 class="mt-3 edupass-purple">
          {{ getTranslation('paymentInfo') | translate }}
        </h4>

        <div class="row">
          <div class="col-md-6 col-lg-4 py-2">
            <component-select
              [label]="getTranslation('perguntaFormaPagamento')"
              [value]="selectedPaymentMethod"
              (valueChange)="onPaymentMethodSelected($event)"
              [options]="paymentMethodsOptions"
              errorPosition="bottom"
            ></component-select>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 col-lg-4 py-2">
            <component-input
              [label]="getTranslation('perguntaNome')"
              formControlName="name"
              [error]="!!formErrors['name']"
              [errorMessage]="formErrors['name']"
              errorPosition="bottom"
            ></component-input>
          </div>
          <div class="col-md-6 col-lg-4 py-2">
            <component-input
              [label]="getTranslation('perguntaCPF')"
              formControlName="document"
              [error]="!!formErrors['document']"
              [errorMessage]="formErrors['document']"
              errorPosition="bottom"
            ></component-input>
          </div>

          <div
            *ngIf="form.controls.payment_method.value === 'PIX'"
            class="col-md-6 col-lg-4 py-2"
          >
            <component-input
              [label]="getTranslation('perguntaPix')"
              formControlName="pix_key"
              [error]="!!formErrors['pix_key']"
              [errorMessage]="formErrors['pix_key']"
              errorPosition="bottom"
            ></component-input>
          </div>

          <ng-container *ngIf="form.controls.payment_method.value === 'TED'">
            <div class="d-none d-lg-flex col-lg-4 py-2"></div>

            <div class="col-md-6 col-lg-4 py-2 position-relative">
              <component-input
                [label]="getTranslation('bank')"
                (inputed)="onInputBank($event)"
                formControlName="bank_num_name"
                [error]="!!formErrors['bank_num_name']"
                [errorMessage]="formErrors['bank_num_name']"
                errorPosition="bottom"
              ></component-input>

              <div
                *ngIf="bankDropdownOpen"
                (clickOutside)="bankDropdownOpen = false"
                class="position-absolute bank-dropdown"
              >
                <button
                  class="bank-dropdown-item"
                  *ngFor="let bank of searchingBanks"
                  (click)="onBankSelected(bank)"
                >
                  {{ bank.label }}
                </button>
              </div>
            </div>

            <div class="col-md-6 col-lg-4 py-2">
              <component-input
                [label]="getTranslation('agencia')"
                formControlName="bank_ag"
                [error]="!!formErrors['bank_ag']"
                [errorMessage]="formErrors['bank_ag']"
                errorPosition="bottom"
              ></component-input>
            </div>
            <div class="col-md-6 col-lg-4 py-2">
              <component-input
                [label]="getTranslation('numeroConta')"
                formControlName="bank_cc"
                [error]="!!formErrors['bank_cc']"
                [errorMessage]="formErrors['bank_cc']"
                errorPosition="bottom"
              ></component-input>
            </div>
          </ng-container>
        </div>

        <div class="row justify-content-center mt-3">
          <app-loading [small]="true" *ngIf="isCreating"></app-loading>

          <component-botao
            *ngIf="!isCreating"
            (onClick)="onSubmit()"
            [disabled]="isCreating"
            >{{ getTranslation('btnEnviar') | translate }}
          </component-botao>
        </div>
      </form>
    </div>
  </div>
</div>
