import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export type ButtonType =
  | 'primary'
  | 'secondary'
  | 'text'
  | 'attention'
  | 'warning';
@Component({
  selector: 'component-botao',
  templateUrl: './botao.component.html',
  styleUrls: ['./botao.component.scss'],
})
export class BotaoComponent implements OnInit {
  @Input()
  disabled: boolean;

  @Input()
  skeleton: boolean;

  @Input('type')
  set Type(type: ButtonType) {
    this.type = type || 'primary';
  }

  @Input()
  icon: string;

  @Input()
  innerClass: string = '';

  @Input()
  expanded: boolean = false;

  @Output()
  onClick: EventEmitter<undefined> = new EventEmitter();

  @Input()
  fullWidth: boolean = false;

  type: ButtonType = 'primary';

  constructor() {}

  ngOnInit(): void {}

  clicked() {
    if (!this.disabled && !this.skeleton) {
      this.onClick.emit();
    }
  }
}
