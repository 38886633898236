import * as React from 'react';
import { Context } from './context';

import { State } from './interfaces';

export function useCatalog(): useCatalogType {
  const context = React.useContext(Context);

  if (!context) {
    throw new Error('useCatalog must be used within a CatalogContext');
  }

  const [state, setState] = context;

  return {
    currentPage: state.pagination.page,
    itemsCount: state.pagination.total,
    loading: state.loading,
    updateState: setState,
  };
}

type useCatalogType = {
  currentPage: number;
  itemsCount: number;
  loading: boolean;
  updateState: React.Dispatch<React.SetStateAction<State>>;
};
