import * as React from 'react';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { BaseTextFieldProps } from '@mui/material/TextField';

export type InlineEditTextFieldProps = {
  onAccept: (value: string) => Promise<boolean>;
  onCancel?: () => void;
  value: string;
} & BaseTextFieldProps;

const Modes = {
  editing: 'editing',
  pending: 'pending',
  readOnly: 'readOnly',
} as const;

const Actions = {
  changeMode: 'changeMode',
  updateInternalValue: 'updateInternalValue',
} as const;

type ModeTypes = keyof typeof Modes;
type ActionTypes = keyof typeof Actions;

interface InternalState {
  internalValue: string;
  mode: ModeTypes;
}

export const InlineEditTextField = React.forwardRef<
  HTMLInputElement,
  InlineEditTextFieldProps
>((props, ref) => {
  const { onAccept, onCancel, value, ...inputProps } = props;

  const initialState: InternalState = {
    internalValue: value,
    mode: Modes.readOnly,
  };

  const [state, dispatch] = React.useReducer<
    React.Reducer<InternalState, { type: ActionTypes; payload: any }>
  >((state: InternalState, action): InternalState => {
    switch (action.type) {
      case Actions.changeMode:
        return { ...state, mode: action.payload };
      case Actions.updateInternalValue:
        return { ...state, internalValue: action.payload };
      default:
        return state;
    }
  }, initialState);

  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const wrapperRef = React.useRef<HTMLDivElement | null>(null);
  const { mode, internalValue } = state;

  React.useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

  const addSelection = () => {
    inputRef.current.focus();
    inputRef.current.select();
  };

  const handleEdit = () => {
    if (mode === Modes.pending) return;

    dispatch({
      type: Actions.changeMode,
      payload: Modes.editing,
    });

    addSelection();
  };

  const removeSelection = () => {
    if (window.getSelection) {
      window.getSelection()?.removeAllRanges();
    }
  };

  const handleAccept = async () => {
    dispatch({ type: Actions.changeMode, payload: Modes.pending });
    const success = await onAccept(internalValue);

    if (success) {
      dispatch({ type: Actions.changeMode, payload: Modes.readOnly });
      removeSelection();
    } else {
      addSelection();
      dispatch({ type: Actions.changeMode, payload: Modes.editing });
    }
  };

  const handleCancel = () => {
    dispatch({ type: Actions.changeMode, payload: Modes.readOnly });
    dispatch({ type: Actions.updateInternalValue, payload: value });

    removeSelection();

    if (onCancel) onCancel();
  };

  React.useEffect(() => {
    if (mode === Modes.readOnly) {
      dispatch({ type: Actions.updateInternalValue, payload: value });
    }

    const handleClickOutside = (event) => {
      if (mode !== Modes.editing) return;

      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        handleCancel();
      }
    };
    window.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [mode]);

  React.useEffect(() => {
    dispatch({ type: Actions.updateInternalValue, payload: value });
  }, [value]);

  return (
    <TextField
      {...inputProps}
      hiddenLabel
      inputRef={inputRef}
      InputProps={{
        onChange: (e) => {
          dispatch({
            type: Actions.updateInternalValue,
            payload: e.target.value,
          });
        },
        onKeyDown: (e) => {
          if (e.key === 'Enter') {
            handleAccept();
          } else if (e.key === 'Escape') {
            handleCancel();
          }
        },
        endAdornment: (
          <InputAdornment
            position="end"
            sx={{
              height: 'auto',
            }}
          >
            {mode === Modes.editing && (
              <>
                <IconButton aria-label="accept changes" onClick={handleAccept}>
                  <CheckCircleRoundedIcon />
                </IconButton>
                <IconButton aria-label="cancel changes" onClick={handleCancel}>
                  <CancelRoundedIcon />
                </IconButton>
              </>
            )}
            {mode !== Modes.editing && (
              <IconButton aria-label="toggle editing mode" onClick={handleEdit}>
                <EditRoundedIcon />
              </IconButton>
            )}
          </InputAdornment>
        ),
        readOnly: mode === Modes.readOnly,
        sx: {
          ...(props.multiline && {
            alignItems: 'flex-start',
          }),
        },
      }}
      ref={wrapperRef}
      sx={(theme) => ({
        ...(mode !== Modes.editing && {
          '& .MuiInputBase-input': {
            paddingLeft: 0,
            cursor: 'default',
          },
          '& .MuiInputBase-root': {
            cursor: 'default',
            '&:hover': {
              backgroundColor: theme.palette.neutral[200],
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }),
      })}
      value={mode === Modes.readOnly ? value : internalValue}
    />
  );
});
