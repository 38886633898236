import { Component, Input, OnInit } from '@angular/core';
import { TableSettings } from './table-new.settings';

@Component({
  selector: 'app-table-new',
  templateUrl: './table-new.component.html',
  styleUrls: ['./table-new.component.scss'],
})
export class TableNewComponent implements OnInit {
  @Input()
  collumns: TableSettings[];

  @Input()
  rowClick: (item: any) => any;

  @Input('data')
  set _data(data: any[]) {
    this.data = data;
    this.totalPages = Math.ceil(this.data.length / this.pageSize) || 1;
    if (this.page > this.totalPages) {
      this.page = 1;
    }
    this.renderNavigation();
    this.renderData();
  }

  @Input()
  pageSize: number = 20;

  data: any[] = [];
  displayingData: any[] = [];

  page: number = 1;
  totalPages: number = 1;

  navigationPages: number[] = [];

  constructor() {}

  ngOnInit(): void {}

  renderNavigation() {
    this.navigationPages = [];

    const startPage = Math.max(1, this.page - 2);
    const endPage = Math.min(this.totalPages, this.page + 2);

    for (let i = startPage; i <= endPage; i += 1) {
      this.navigationPages.push(i);
    }
  }

  renderData() {
    this.displayingData = this.data.slice(
      (this.page - 1) * this.pageSize,
      this.pageSize * this.page
    );
  }

  onRowClick(item: any) {
    if (this.rowClick) {
      this.rowClick(item);
    }
  }

  isLastPage() {
    return this.totalPages === this.page;
  }

  nextPage() {
    if (this.page < this.totalPages) {
      this.page += 1;
      this.renderData();
      this.renderNavigation();
    }
  }

  previousPage() {
    if (this.page > 1) {
      this.page -= 1;
      this.renderData();
      this.renderNavigation();
    }
  }

  gotToPage(page: number) {
    if (page > 0 && page <= this.totalPages && page !== this.page) {
      this.page = page;
      this.renderData();
      this.renderNavigation();
    }
  }

  getStyle(collumn: TableSettings, dataItem: any) {
    if (collumn.getStyle) {
      return collumn.getStyle(dataItem);
    }
  }

  getIcon(collumn: TableSettings, dataItem: any) {
    if (collumn.getIcon) {
      return collumn.getIcon(dataItem);
    }
  }

  getTooltip(collumn: TableSettings, dataItem: any) {
    if (collumn.getTooltip) {
      return collumn.getTooltip(dataItem);
    }
  }
}
