import { Empresa } from './empresa.model';
import { Cargo } from './cargo.model';
import { Inscricao } from './inscricao.model';
import { User } from './user.model';
import { Area } from './area.model';

export class Colaborador {
  public static readonly STATUS_BLOQUEADO = 'Bloqueado';
  public static readonly STATUS_ATIVO = 'Ativo';
  public static readonly STATUS_INATIVO = 'Inativo';

  id: number;
  admissao: string;

  empresa: Empresa;
  empresaId: number;

  cargo: Cargo;
  cargoId: number;

  area: Area;
  areaId: number;

  inscricoes: Inscricao[];

  userId: number;
  user: User;
  city: string;

  employee_id: number;
  employee_level_id: number;

  created_at: string;

  isDependent() {
    return this.employee_id !== null;
  }

  static fromJson(colaborador: Colaborador) {
    const instance = new Colaborador();
    Object.keys(colaborador).forEach((key) => {
      instance[key] = colaborador[key];
    });

    return instance;
  }

  static getStatus(colaborador: Colaborador) {
    if (colaborador.user.bloqueado) {
      return Colaborador.STATUS_BLOQUEADO;
    }
    return colaborador.user.ativo
      ? Colaborador.STATUS_ATIVO
      : Colaborador.STATUS_INATIVO;
  }
}
