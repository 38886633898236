import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { DevRoutingModule } from './dev-routing.module';
import { ReactWrapperComponent } from './react-wrapper.component';

@NgModule({
  declarations: [ReactWrapperComponent],
  imports: [CommonModule, DevRoutingModule, SharedModule],
})
export class DevModule {}
