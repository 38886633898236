<div class="input-wrapper">
  <label *ngIf="label" class="BodyText-1-SemiBold">
    {{ label | translate }}
  </label>
  <p *ngIf="description" class="description">
    {{ description | translate }}
  </p>

  <div class="FeedbackText error-message" *ngIf="error && errorMessage && errorPosition === 'top'">
    {{ errorMessage | translate }}
  </div>

  <div class="input-container">
    <input
      *ngIf="isInput"
      [formControl]="formControl"
      [autocomplete]="autocomplete"
      (input)="onInput($event)"
      [maxLength]="maxLength"
      [type]="type"
      [ngClass]="{ error: error, success: successIcon }"
      [value]="value"
      placeholder="{{ placeholder | translate }}"
      (blur)="onInputBlur()"
      (keydown.enter)="onEnterPressed()"
    />
    <textarea
      *ngIf="!isInput"
      [formControl]="formControl"
      [autocomplete]="autocomplete"
      (input)="onInput($event)"
      [maxLength]="maxLength"
      [ngClass]="{ error: error }"
      [value]="value"
      placeholder="{{ placeholder | translate }}"
      (blur)="onInputBlur()"
    >
    </textarea>

    <span *ngIf="error" class="material-icons-outlined error-icon notranslate"
      >error_outline</span
    >
    <span
      *ngIf="successIcon"
      class="material-icons-outlined success-icon notranslate"
      >done</span
    >
  </div>

  <div class="FeedbackText error-message" *ngIf="error && errorMessage && errorPosition === 'bottom'">
    {{ errorMessage | translate }}
  </div>
</div>
