<app-certificate-help></app-certificate-help>

<div class="container">
  <div class="row">
    <div *ngFor="let curso of cursos" class="col-sm-6 col-lg-4 col-xl-3">
      <div class="card my-4">
        <img
          class="imagem-curso"
          [src]="getImagemCurso(curso)"
          (error)="onImageError($event)"
          alt="pessoas dando as mãos para simbolizar trabalho em equipe"
        />
        <div class="card-body">
          <h4>{{ getCourseName(curso) }}</h4>

          <div class="d-flex justify-content-center mt-4">
            <component-botao type="secondary" (onClick)="onAcessar(curso)">{{
              getTranslation('accessCertificate') | translate
            }}</component-botao>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="cursos.length === 0">
      <img [src]="getAsset('noResults.png')" alt="" />
      <p>
        {{ getTranslation('nenhumCursoConcluido') | translate }}
      </p>
      <div class="no-courses BodyText-1 my-4">
        <p>{{ getTranslation('explore') | translate }}</p>

        <component-botao (click)="onCatalog()" type="primary">
          {{ getTranslation('catalogo') | translate }}
        </component-botao>
      </div>
    </div>
  </div>
</div>
