import * as React from 'react';
import { State } from '@/react/components/refund/wizard/state/interfaces';
import { WizardStepIds } from '@/react/components/refund/refund-request/description-step/interfaces';
import { WizardSteps } from '@/react/components/refund/wizard/interfaces';
import { WizardProvider } from '@/react/components/refund/wizard/state/context';
import Wizard from '@/react/components/refund/wizard/wizard.component';
import PaymentResume from './payment-resume/payment-resume.component';

const createCourseSteps = (orderUuid): WizardSteps => {
  return {
    [WizardStepIds.PAYMENT_RESUME]: {
      component: (props) => <PaymentResume {...props} orderUuid={orderUuid} />,
    },
  };
};

const initialState = {
  currentStepId: WizardStepIds.PAYMENT_RESUME,
} as State;

export const PaymentResumeStep = ({ steps }: { steps: WizardSteps }) => {
  return <Wizard steps={steps} />;
};

const PaymentResumeStepWithContext = ({ orderUuid }) => {
  const courseSteps = createCourseSteps(orderUuid);

  return (
    <WizardProvider initialState={initialState}>
      <PaymentResumeStep steps={courseSteps} />
    </WizardProvider>
  );
};

export default PaymentResumeStepWithContext;
