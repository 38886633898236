import { RouterService } from './../../services/router.service';
import { Component, Input, OnInit } from '@angular/core';
import { CursoAluno } from 'src/app/models/curso-aluno';
import { getAsset } from 'src/app/utils/helpers';
import { CONST } from 'src/app/constants';

@Component({
  selector: 'app-card-curso-andamento',
  templateUrl: './card-curso-andamento.component.html',
  styleUrls: ['./card-curso-andamento.component.scss'],
})
export class CardCursoAndamentoComponent implements OnInit {
  @Input()
  cursos: CursoAluno[];

  getAsset = getAsset;

  defaultImageUrl = `${CONST.API_ENDPOINT}/contents/eduplay/course_placeholder.png`;

  getImagemCurso(curso: CursoAluno): string {
    return `${CONST.API_ENDPOINT}/contents/eduplay/${
      curso.curso_horario?.curso_detalhe?.curso?.imagem || 'default.png'
    }`;
  }

  constructor(private routerService: RouterService) {}

  ngOnInit(): void {}

  onAcessar(curso: CursoAluno) {
    this.routerService.navigate(['eduplay', curso.curso_horario.id, 'curso']);
  }

  getProgresso(curso: CursoAluno) {
    return curso.progresso;
  }

  onNavigate() {
    this.routerService.navigate([`colaborador/cursos/lista/livres`]);
  }

  onImageError(element) {
    element.target.src = this.defaultImageUrl;
  }

  getTranslation(key: string): string {
    return `SHARED.CARD_CURSO_ANDAMENTO.${key}`;
  }
}
