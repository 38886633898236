import { Component, OnInit } from '@angular/core';
import { ReactGlobalFragment } from './react-global-fragment.component';

@Component({
  selector: 'react-global-fragment',
  templateUrl: './react-global-fragment.html',
})
export class ReactGlobalFragmentComponent implements OnInit {
  ReactGlobalFragment = ReactGlobalFragment;
  reactGlobalFragmentProps = {};
  constructor() {}
  ngOnInit(): void {}
}
