import { CorporateCourse } from '../../models/corporate_course.model';
import { Course, DbTrailCourse } from './course.model';

export class DbTrail {
  id: string;
  created_at: string;
  updated_at: string;
  eduplay_free: boolean;
  image: string;
  name: string;

  _count?: {
    courses?: number;
  };

  courses?: DbTrailCourse[];
  company_id?: number;
  deleted_at?: string;
}
export class Trail {
  id: string;
  name: string;
  image: string;
  countCourses: number;

  courses?: Course[];

  static getFromDb(dbTrail: DbTrail): Trail {
    const trail = new Trail();

    trail.id = dbTrail.id;
    trail.name = dbTrail.name;
    trail.image = dbTrail.image;
    trail.countCourses = dbTrail._count?.courses || 0;

    trail.courses =
      dbTrail.courses && dbTrail.courses.map(Course.getFromDbTrailCourse);

    return trail;
  }
}
