<div class="container h-100 p-0">
  <div class="row edupass-container">
    <img
      class="galena-logo"
      [src]="getAsset('logo-galena-emails.png')"
      alt="logo da Galena"
    />
  </div>

  <div class="form-content">
    <div class="row">
      <div class="content">
        <div class="title">
          <p>{{ getTranslation('definaSenha') | translate }}</p>
        </div>

        <component-input
          label="{{ getTranslation('labelSenha') | translate }}"
          [(ngModel)]="form.senha"
          class="form-input"
          type="password"
          [error]="errors.password"
          [errorMessage]="getPasswordError()"
        ></component-input>

        <component-input
          label="{{ getTranslation('confirmaSenha') | translate }}"
          [(ngModel)]="form.confirmPassword"
          class="form-input"
          type="password"
          [error]="errors.confirmPassword"
          [errorMessage]="getConfirmPasswordError()"
        ></component-input>

        <div class="save-btn">
          <component-botao class="mt-4 mb-2" (click)="onSubmit()">
            {{ getTranslation('btnSalvar') | translate }}
          </component-botao>
        </div>
      </div>
    </div>
  </div>
</div>
