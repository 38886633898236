import { useState } from 'react';

const useToggle = ({ startOpen = false }) => {
  const [open, setOpen] = useState(startOpen);

  function toggle() {
    setOpen(!open);
  }

  return {
    open,
    toggle,
  };
};

export default useToggle;
