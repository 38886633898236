import * as React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';

import conclusionJourney from '@/react/assets/images/conclusion-journey.svg';

export type ResourceApprovalCreatedDialogProps = {
  actionsVisibility?: {
    sendInvoice?: boolean;
    waitApproval?: boolean;
  };
  open: boolean;
  resourceApprovalUUID?: string;
};

const ResourceApprovalCreatedDialog: React.FC<
  ResourceApprovalCreatedDialogProps
> = ({ actionsVisibility = {}, open: externalOpen, resourceApprovalUUID }) => {
  const { sendInvoice = true, waitApproval = true } = actionsVisibility;
  const [open, setOpen] = React.useState(externalOpen);

  React.useEffect(() => {
    setOpen(externalOpen);
  }, [externalOpen]);

  const handleRedirectToReceipts = () => {
    window.location.assign(`/meus-reembolsos/solicitacao/${resourceApprovalUUID}/comprovante`);
    setOpen(false);
  };

  return (
    <Dialog open={open}>
      <DialogTitle />
      <DialogContent>
        <Stack spacing={3}>
          <Box component="img" src={conclusionJourney} sx={{ width: 128 }} />
          <Typography variant="h6" sx={{ mb: 1 }}>
            O item foi enviado para aprovação!
          </Typography>
        </Stack>
        <Stack spacing={2}>
          <Typography component="p" variant="body3">
            Para solicitar reembolso deste item, você precisa da aprovação da
            sua empresa. O pedido já foi enviado, então agora é só aguardar.
          </Typography>
          <Typography component="p" variant="body3">
            Assim que tivermos a resposta enviaremos um email informando. Você
            também pode conferir o andamento na página de reembolsos.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        {sendInvoice && (
          <Button
            color="primary"
            onClick={handleRedirectToReceipts}
            variant="contained"
          >
            Enviar Comprovantes
          </Button>
        )}
        {waitApproval && (
          <Button color="primary" href="/meus-reembolsos/" variant="contained">
            Aguardar Aprovação
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ResourceApprovalCreatedDialog;
