import { environment } from '../environments/environment.development';
import pjson from '../../package.json';

const API_ENDPOINT = `${environment.BACKEND_URL}/api`;
const FALCON_API_URL = `${environment.FALCON_URL}`;

export const CONST = {
  FALCON_API_URL,
  API_ENDPOINT,
  FRONTEND_ENDPOINT: environment.FRONTEND_URL,
  VERSION: pjson.version,
  EDUPLAY_ID: 75,
  EDUPLAY_URL: 'https://eduplay.eadbox.com/login',
  LOGO_EDUPLAY: `${API_ENDPOINT}/contents/instituicoes/eduplay_cropped.png`,
  MEDIA_ENDPOINT: `${environment.BACKEND_URL}/eduplay`,
  BACKEND_URL: environment.BACKEND_URL,
  LEGACY_BACKEND_URL: environment.LEGACY_BACKEND_URL,
  NEW_API_ENDPOINT: environment.NEW_API_ENDPOINT,
  NEW_MEDIA_ENDPOINT: environment.MEDIA_URL,
  MAXIMUM_LEROY_DISCOUNT_IN_BRL: 300,
  LEROY_SUBSIDY_PERCENTAGE: 0.3,
  VIEWPORT_BREAKPOINT_XXL: 1400,
  VIEWPORT_BREAKPOINT_XL: 1200,
  VIEWPORT_BREAKPOINT_LG: 992,
  VIEWPORT_BREAKPOINT_MD: 768,
  VIEWPORT_BREAKPOINT_SM: 576,
  VIEWPORT_BREAKPOINT_XS: 360,
  SUPPORT_URL:
    'https://api.whatsapp.com/send/?phone=5511987566079&text&type=phone_number&app_absent=0',
  TOKEN_KEY: 'raw_token',
  USER_KEY: 'user_id',
  GALENA_SERVICE_URL: 'https://atendimento.galena.com/hc/pt-br',
  GALENA_SERVICE_REQUEST_URL: 'https://atendimento.galena.com/hc/pt-br/requests/new',
  GALENA_WEBSITE_URL: 'https://galena.com',
  LOOKER_DASHBOARD_URL: 'https://lookerstudio.google.com/embed/reporting/8fd4e9c0-b6a0-4b5f-8fa5-2f51f68b93a1/page/',
};

export default CONST;
