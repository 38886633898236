import { Component, Input, OnInit } from '@angular/core';

import { NavItem } from '@/components/banner-navigation/banner-navigation.component';
import { TranslatedComponent } from '@/utils/translated.component';

@Component({
  selector: 'app-refund-receipt-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class RefundReceiptNavigationComponent
  extends TranslatedComponent
  implements OnInit
{
  translationKey = 'EMPRESA.REFUND_RECEIPT.REFUND_RECEIPT_NAVIGATION';

  @Input()
  menu = 'receipts-approval';

  navItems: NavItem[] = [
    {
      label: this.getTranslation('pending'),
      name: 'receipts-approval',
      route: 'empresa/refund_receipt/refund_receipt_approval',
    },

    {
      label: this.getTranslation('report'),
      name: 'report',
      route: 'empresa/refund_receipt/refund_report',
    },
  ];

  ngOnInit(): void {}
}
