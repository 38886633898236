import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { SubsidyClient } from '@/react/data/subsidy/subsidy-client';
import { useWizard } from '@/react/components/refund/wizard/state/useWizard';
import { useAccordion } from '@/react/components/refund/accordion/state/useAccordion';
import { RefundRequestSteps } from '@/react/components/refund/refund-request/interfaces';
import { WizardStepIds } from '@/react/components/refund/refund-request/description-step/interfaces';
import { useRefundRequest } from '@/react/components/refund/refund-request/state/useRefundRequest';
import { formatMoney } from '@/utils/money-parser';
import StepActions from '@/react/components/refund/wizard/step-actions.component';
import RefundRequestCreatedDialog from '../../refund-request-created-dialog.component';
('@react/components/refund/refund-request/refund/refund-request-created-dialog.component');
import { useRequestRefundableAmount } from '@/react/data/subsidy/useRequestRefundableAmount';
import { RefundRequestStatus } from '@/react/data/subsidy/interfaces';

const RefundableValue = ({
  isValid,
  value,
}: {
  isValid: boolean;
  value: number;
}) => {
  return (
    <Box
      component="span"
      sx={({ palette }) => ({
        color: !isValid ? palette.error.main : 'inherit',
      })}
    >
      {formatMoney(value)}
    </Box>
  );
};

const formatCreateRefundRequest = ({ formValues }) => ({
  resource_approval: { uuid: formValues.resource_approval_uuid },
  id: formValues.id,
  refund_request: {
    supplier_type: formValues.supplierType,
    supplier_name: formValues.supplierName,
    supplier_document: formValues.supplierDocument.toString(),
    payment_date: formValues.profOfPaymentPaymentDate,
    payment_amount: formValues.paymentAmount,
    purchase_date: formValues.paymentDate,
    purchase_amount: formValues.profOfPaymentAmountPaid,
    receiver_fiscal_document: formValues.documentEmployeer,
    reference_month: formValues?.referenceMonth,
    banking_info: {
      holder_name: formValues?.holderName,
      bank: formValues?.bank,
      agency: formValues?.agency,
      account_number: formValues?.accountNumber,
      pix_key: formValues?.pixKey,
      pix_type: formValues?.pixType,
    },
  },
  receipts: [...formValues.receipts],
});

const initialState = {
  isSubmitting: false,
  resourceApprovalCreated: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_CREATED':
      return { ...state, resourceApprovalCreated: action.payload };
    case 'SET_SUBMITTING':
      return { ...state, isSubmitting: action.payload };
    default:
      return state;
  }
};

const SendingReceiptsSummary = () => {
  const { actions: wizardActions, values: wizardValues } = useWizard();
  const { actions: accordionActions } = useAccordion();
  const formValues = wizardValues[WizardStepIds.SENDING_RECEIPTS_FORM];
  const { loading, subsidyPolicy, resourceApproval } = useRefundRequest();

  if (resourceApproval.status === RefundRequestStatus.revision_required) {
    formValues.id = resourceApproval.refund_request.uuid;
  }

  const { isFetching: isFetchingRefundableAmount, requestRefundableAmount } =
    useRequestRefundableAmount({
      payment_amount: formValues.paymentAmount,
      resource_approval_uuid: formValues.resource_approval_uuid,
      uuid_policy: subsidyPolicy?.uuid,
    });

  const [state, dispatch] = React.useReducer(reducer, initialState);

  const totalValueParsed = parseFloat(formValues.paymentValue ?? '0');
  const refundableValueParsed = parseFloat(
    requestRefundableAmount?.refund?.refundable_amount ?? '0'
  );
  const valid = !!subsidyPolicy && refundableValueParsed > totalValueParsed;

  const handleCreateRefund = async () => {
    if (!valid) {
      return;
    }

    const requestPayload = formatCreateRefundRequest({
      formValues,
    });

    dispatch({ type: 'SET_SUBMITTING', payload: true });
    dispatch({ type: 'SET_CREATED', payload: false });

    try {
      let resource = null;
      if (resourceApproval.status === RefundRequestStatus.revision_required) {
        resource = await SubsidyClient.updateRefundRequest(requestPayload);
      } else {
        resource = await SubsidyClient.createRefundRequest(requestPayload);
      }

      if (!!resource) {
        dispatch({ type: 'SET_SUBMITTING', payload: false });
        dispatch({ type: 'SET_CREATED', payload: true });
      }

      accordionActions.close(RefundRequestSteps.INVOICE_ATTACHMENT);
    } catch (error) {
      console.error('Error creating refund request:', error);
      dispatch({ type: 'SET_SUBMITTING', payload: false });
    }
  };

  return (
    <>
      <Box
        sx={{
          borderColor: 'neutral.300',
          borderStyle: 'solid',
          borderWidth: 1,
          p: 2,
          mt: 3,
        }}
      >
        <Stack spacing={2}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 0, sm: 3 }}
          >
            <Box flexGrow={1}>
              <Typography
                component="div"
                sx={(theme) => ({
                  fontWeight: theme.typography.fontWeightBold,
                })}
                variant="body3"
              >
                {/* Saldo disponível */}
              </Typography>
            </Box>
            <Box>
              <Typography
                component="div"
                display="flex"
                justifyContent={{ xs: 'left', sm: 'right' }}
                variant="body3"
              >
                {/* PARA LEROY ESSE VALOR NÃO EXISTE */}
              </Typography>
            </Box>
          </Stack>

          <Box>
            <Divider />
          </Box>

          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 0, sm: 3 }}
          >
            <Box flexGrow={1}>
              <Typography
                component="div"
                sx={(theme) => ({
                  fontWeight: theme.typography.fontWeightBold,
                })}
                variant="body3"
              >
                Custo total do item
              </Typography>
            </Box>
            <Box>
              <Typography
                component="div"
                display="flex"
                justifyContent={{ xs: 'left', sm: 'right' }}
                variant="body3"
              >
                {formValues.paymentAmount &&
                  formatMoney(formValues.paymentAmount)}
              </Typography>
            </Box>
          </Stack>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 0, sm: 3 }}
          >
            <Box flexGrow={1}>
              <Typography
                component="div"
                sx={(theme) => ({
                  fontWeight: theme.typography.fontWeightBold,
                })}
                variant="body3"
              >
                Valor pago
              </Typography>
              <Typography component="div" variant="caption">
                De acordo com o comprovante anexado
              </Typography>
            </Box>
            <Box>
              <Typography
                component="div"
                display="flex"
                justifyContent={{ xs: 'left', sm: 'right' }}
                variant="body3"
              >
                {!formValues.profOfPaymentAmountPaid ? (
                  <Skeleton width={60} />
                ) : (
                  <RefundableValue
                    isValid={valid}
                    value={formValues.profOfPaymentAmountPaid}
                  />
                )}
              </Typography>
            </Box>
          </Stack>

          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 0, sm: 3 }}
          >
            <Box flexGrow={1}>
              <Typography
                component="div"
                sx={(theme) => ({
                  fontWeight: theme.typography.fontWeightBold,
                })}
                variant="body3"
              >
                Valor máximo reembolsável
              </Typography>
              <Typography component="div" variant="caption">
                Calculado de acordo com as regras de reembolso da sua política.
              </Typography>
            </Box>
            <Box>
              <Typography
                component="div"
                display="flex"
                justifyContent={{ xs: 'left', sm: 'right' }}
                variant="body3"
              >
                {isFetchingRefundableAmount ? (
                  <Skeleton width={60} />
                ) : (
                  <RefundableValue
                    isValid={valid}
                    value={refundableValueParsed}
                  />
                )}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Box>
      <Box
        sx={{
          borderColor: 'neutral.300',
          borderStyle: 'solid',
          borderWidth: 1,
          borderTop: 0,
          backgroundColor: 'neutral.200',
          marginTop: '0px',
          p: 2,
        }}
      >
        <Stack spacing={2}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 0, sm: 3 }}
          >
            <Box flexGrow={1}>
              <Typography
                component="div"
                sx={(theme) => ({
                  fontWeight: theme.typography.fontWeightBold,
                })}
                variant="body3"
              >
                Reembolso final
              </Typography>
              <Typography component="div" variant="caption">
                O cálculo considera o saldo disponível e valor máximo
                reembolsável.
              </Typography>
            </Box>
            <Box>
              <Typography
                component="div"
                display="flex"
                justifyContent={{ xs: 'left', sm: 'right' }}
                variant="body3"
                fontWeight={700}
                color="success.700"
              >
                {loading ? (
                  <Skeleton width={60} />
                ) : (
                  <RefundableValue
                    isValid={valid}
                    value={refundableValueParsed}
                  />
                )}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Box>
      <StepActions>
        <LoadingButton
          color="primary"
          onClick={() =>
            wizardActions.navigate(WizardStepIds.SENDING_RECEIPTS_FORM)
          }
          variant="text"
        >
          Voltar
        </LoadingButton>
        <LoadingButton
          color="primary"
          disabled={!valid}
          loading={state.isSubmitting}
          onClick={() => handleCreateRefund()}
          variant="contained"
        >
          {resourceApproval.status === RefundRequestStatus.revision_required
            ? 'Atualizar'
            : 'Enviar'}
        </LoadingButton>
      </StepActions>
      <RefundRequestCreatedDialog open={state.resourceApprovalCreated} />
    </>
  );
};

export default SendingReceiptsSummary;
