import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Filters } from '@/react/components/filters';
import { useProductPage } from '../state';
import { ProductPageAsyncResources } from '../state/interfaces';

const Loading = () => (
  <Stack spacing={3}>
    <Stack spacing={1}>
      <Typography component="div" variant="body3" width="100%">
        <Skeleton width="20%" />
      </Typography>
      <Skeleton height={38} variant="rounded" width="100%" />
      <Skeleton height={38} variant="rounded" width="100%" />
    </Stack>
    <Stack spacing={1}>
      <Typography component="div" variant="body3" width="100%">
        <Skeleton width="10%" />
      </Typography>
      <Skeleton height={38} variant="rounded" width="100%" />
    </Stack>
    <Stack spacing={1}>
      <Typography component="div" variant="body3" width="100%">
        <Skeleton width="12%" />
      </Typography>
      <Skeleton height={38} variant="rounded" width="100%" />
    </Stack>
  </Stack>
);

export const OfferFilters = () => {
  const { loading } = useProductPage();

  if (loading.includes(ProductPageAsyncResources.offer)) {
    return <Loading />;
  }

  return <Filters />;
};
