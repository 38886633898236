import { MiniState, StateUpdateContext } from './interfaces';
import { _MiniStateImpl } from './state-impl';

export type { MiniState, StateUpdateContext };

export function createState<State extends object>(
  initialState: State
): MiniState<State> {
  return _MiniStateImpl.create(initialState);
}
