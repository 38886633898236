import { createDraft, Draft, isDraft } from 'immer';

import { areEqual, pick, setByPath, simpleObjectClone } from 'powership';

import type {
  MethodExecutionContext,
  StateChangeMiddleware,
  StatePieceListener,
  StateUpdateContext,
} from './interfaces';

// Creates a mini state management system using Immer.
// See ./interfaces.ts for more details
export class _MiniStateImpl<State extends object, Methods extends object = {}> {
  private currentState: State;
  private initial: State;
  private listeners = new Map<
    StatePieceListener<any>,
    { picker: (state: State) => any }
  >();
  private middlewares = new Set<StateChangeMiddleware<State, Methods>>();
  methods = {} as Methods;

  constructor(initial: State) {
    this.currentState = initial;
    this.initial = simpleObjectClone(initial);
    Object.defineProperty(this, 'name', { value: 'MiniState' });
  }

  observe = (pickerOrPath, onChange): any => {
    const key = onChange;

    const picker =
      typeof pickerOrPath === 'function'
        ? pickerOrPath
        : (state: State) => pick(state, pickerOrPath);

    this.listeners.set(key, { picker });
    return () => this.listeners.delete(key);
  };

  update(...args: any[]): State {
    //
    const sanitizedArgs = (() => {
      if (typeof args[0] === 'string') {
        const [path, value, context] = args;

        return {
          context,
          update: (draft: Draft<State>) => {
            setByPath(draft, path, value);
          },
        };
      }

      if (typeof args[0] === 'function') {
        const [update, context] = args;
        return {
          context,
          update,
        };
      }

      throw new Error('invalid updater');
    })();

    if (!sanitizedArgs.context) {
      sanitizedArgs.context = {
        method: '__STATE.UPDATE__',
        payload: undefined,
      };
    }

    return this._update(sanitizedArgs);
  }

  private _update(config: {
    update: (draft: Draft<State>) => void | State | Draft<State>;
    context: StateUpdateContext;
  }): State {
    const previous = this.currentState;
    const { update, context } = config;

    let draft = createDraft(this.currentState);
    let updateResult = update(draft);

    if (updateResult) {
      draft = ensureDraft(updateResult);
    }

    this.middlewares.forEach((middleware) => {
      const result = middleware({
        context: context as MethodExecutionContext<Methods>,
        draft,
        previous,
      });

      if (result) {
        draft = ensureDraft(result);
      }
    });

    const nextState = JSON.parse(JSON.stringify(draft)) as State;

    this.listeners.forEach((item, onChange) => {
      const { picker } = item;

      const nextPiece = picker(nextState);
      const previousPiece = picker(previous);

      if (!areEqual(previousPiece, nextPiece)) {
        onChange({
          previous: previousPiece,
          next: nextPiece,
        });
      }
    });

    if (!context.method?.startsWith('@INTERNAL')) {
      this._logMethod(context, nextState);
    }

    this.currentState = nextState;
    return nextState;
  }

  withMethods = <
    Actions extends {
      [K: string]: (current: State, ...args: unknown[]) => any;
    }
  >(
    methods: Actions
  ) => {
    const self: any = this;

    self.methods = Object.entries(methods).reduce((acc, [key, fn]) => {
      const run = (payload: any) => {
        return this._update({
          update: (draft: any) => {
            // injecting the current state draft alongside the action payload
            try {
              fn(draft, payload);
            } catch (e: any) {
              throw new StateMethodError(e, key);
            }
          },
          context: {
            method: key,
            payload,
          },
        });
      };

      if (!(key in self)) {
        Object.defineProperty(self, key, { value: run });
      }

      return {
        ...acc,
        [key]: run,
      };
    }, {} as any);

    return self;
  };

  current = () => {
    return this.currentState;
  };

  addMiddleware = (...items: StateChangeMiddleware<State, Methods>[]) => {
    items.forEach((middleware) => {
      this.middlewares.add(middleware);
    });
    return this;
  };

  static create = <State extends object>(initial: State) => {
    return new _MiniStateImpl(initial);
  };

  private _logMethod = (context: StateUpdateContext, next: State) => {
    this._devTool?.send(
      { type: `@method/${context.method}`, payload: context.payload },
      next
    );
  };

  private _devTool?: ReduxDevTools | undefined;

  connectDevTools = (name: string = 'MiniState') => {
    this._devTool = (() => {
      let devTools = devToolsMap.get(name);

      if (devTools) {
        devTools.init(this.current());
        return devTools;
      }

      if (window.__REDUX_DEVTOOLS_EXTENSION__) {
        devTools = window.__REDUX_DEVTOOLS_EXTENSION__.connect({
          name: name,
        });
        devTools.init(this.current());
        devToolsMap.set(name, devTools);

        devTools.subscribe((event) => {
          const type = event.payload?.type;

          const reset = () => {
            const str = localStorage.getItem('STATE_COMMIT') || event.state;
            if (!str) return;
            const state = JSON.parse(str);
            this.update(() => {
              return state;
            });
          };

          switch (type) {
            case 'JUMP_TO_ACTION': {
              this._update({
                update: () => JSON.parse(event.state),
                context: {
                  method: '@INTERNAL/DEVTOOLS/JUMP_TO_ACTION',
                  payload: event.payload,
                },
              });
              break;
            }

            case 'IMPORT_STATE': {
              const states: any[] =
                event.payload.nextLiftedState.computedStates;
              const last = states[states.length - 1].state;
              this.update(() => {
                return last;
              });
              break;
            }

            case 'COMMIT': {
              const state = JSON.stringify(this.current());
              localStorage.setItem('STATE_COMMIT', state);
              break;
            }

            case 'RESET': {
              return reset();
            }

            case 'REVERT': {
              return reset();
            }

            case 'ROLLBACK': {
              return reset();
            }

            default: {
              console.info(event);
            }
          }
        });

        return devTools;
      }
    })();
  };
}

const devToolsMap = new Map<string, ReduxDevTools | null>();

class StateMethodError extends Error {
  constructor(error: Error, method: string) {
    super(`Failed to execute method ${method}\n${error.message || ''}`);
    this.stack = (error.stack || '').split('\n').slice(1).join('\n');
  }
}

function ensureDraft<T>(value: T | Draft<T>): Draft<T> {
  return (isDraft(value) ? value : createDraft(value)) as Draft<T>;
}

type ReduxDevTools = {
  send(event: { type: string; [K: string]: any }, state: object): void;
  init: <S extends object>(state: S, liftedData?: any) => void;
  subscribe: (listener: (message: any) => void) => (() => void) | undefined;
  unsubscribe: () => void;
  error: (payload: string) => void;
};

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__:
      | null
      | undefined
      | { connect: (preConfig: object) => ReduxDevTools };
  }
}
