import * as React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

interface ExtraProps {
  underline: boolean;
  spacing: number;
}

const Styled = {
  Title: styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'underline' && prop !== 'spacing',
  })<ExtraProps>`
    color: ${({ theme }) => theme.palette.primary[900]};
    font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
    margin-bottom: ${({ theme, spacing }) => theme.spacing(spacing)};

    span {
      border-bottom: ${({ theme, underline }) =>
        underline ? `2px solid ${theme.palette.primary[900]}` : 'none'};
      display: inline-block;
    }
  `,
};

export const Title = ({
  underline = true,
  spacing = 3,
  children,
}: {
  underline?: boolean;
  children: React.ReactNode;
  spacing?: number;
}) => {
  return (
    <Styled.Title underline={underline} variant="h5" spacing={spacing}>
      <span>{children}</span>
    </Styled.Title>
  );
};
