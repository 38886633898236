import { SsoComponent } from './sso/sso.component';
import { NotLoggedGuard } from './../../services/guards/not-logged.guard';
import { NgModule } from '@angular/core';
import { type Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login.component';
import { RegistrarComponent } from './registrar/registrar.component';
import { TrocasenhaComponent } from './trocasenha/trocasenha.component';
import { NewRegisterPageComponent } from './new-register-page/new-register-page.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [NotLoggedGuard],
    children: [
      {
        path: '',
        component: LoginComponent,
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
      },
      {
        path: 'novos_registros',
        component: NewRegisterPageComponent,
      },

      {
        path: 'registrar',
        component: RegistrarComponent,
      },

      {
        path: 'trocarsenha',
        component: TrocasenhaComponent,
      },
      {
        path: 'sso',
        component: SsoComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoginRoutingModule {}
