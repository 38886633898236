import { Injectable } from '@angular/core';
import { Coupon } from 'src/app/models-new/coupon.model';
import { Api2Service } from '../api2.service';

@Injectable({
  providedIn: 'root',
})
export class CouponService extends Api2Service {
  readonly router: string = 'coupons';

  async checkCoupon(code: string, courseId: number) {
    const dbCoupon = await this.get(`${code}/course/${courseId}`);
    return Coupon.getFromDb(dbCoupon);
  }
}
