import { Component } from '@angular/core';
import type { ComponentProps } from 'react';

import ReactManageEmployees from '@/react/components/employer/manage';

@Component({
  selector: 'component-employees',
  templateUrl: './manage-employees.component.html',
})
export class ManageEmployeesComponent {
  ReactManageEmployees = ReactManageEmployees;
  reactManageEmployeesProps: ComponentProps<typeof ReactManageEmployees> = {};
}
