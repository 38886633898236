import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'component-radio',
  templateUrl: './input-radio.component.html',
  styleUrls: ['./input-radio.component.scss'],
})
export class InputRadioComponent implements OnInit {
  @Input()
  checked: boolean = false;

  @Output()
  onClick = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onClicked() {
    this.onClick.emit();
  }
}
