<p class="card-title Headline-2 px-4 pb-3 pt-6">
  {{ getCustomCategoryName() }}
</p>
<p class="card-title Subtitle-2 pl-4">
  {{ getTranslation('selected') | translate }}
</p>

<div>
  <div class="row mx-2">
    <div *ngFor="let course of courses" class="col-lg-4 col-xl-3 pb-4 mt-6">
      <app-curso-card [curso]="course" [eduplayPremium]="isEduplayPremium()">
        <component-botao class="d-block mx-auto" type="primary"
          >SAIBA MAIS
        </component-botao>
      </app-curso-card>
    </div>
  </div>
</div>
