import { Injectable } from '@angular/core';
import { downloadCSV, downloadFile } from './download-file';
interface CSVSettings {
  fields: {
    title: string;
    name: string;
  }[];
}

interface CSVOptions {
  collumns: { header: string; getValue: (item: any) => string }[];
}
@Injectable()
export class CSVHandler {
  export(data: any[], settings: CSVSettings) {
    let csvContent = 'data:text/csv;charset=utf-8,';
    settings.fields.forEach((field, index) => {
      if (index > 0) {
        csvContent += ',';
      }
      csvContent += field.title;
    });
    csvContent += '\r\n';
    data.forEach((item) => {
      settings.fields.forEach((field, index) => {
        if (index > 0) {
          csvContent += ',';
        }
        csvContent += '"' + item[field.name] + '"';
      });
      csvContent += '\r\n';
    });
    downloadFile(csvContent, 'export.csv');
  }

  generate(data: any[], options: CSVOptions) {
    const rows = [];

    const mountRow = (items: string[]) => {
      rows.push(items.map((item) => `"${item}"`).join(','));
    };

    mountRow(options.collumns.map((collumn) => collumn.header));

    data.forEach((item) => {
      mountRow(options.collumns.map((collumn) => collumn.getValue(item)));
    });

    downloadCSV(`${rows.join('\n')}`, 'report.csv');
  }
}
