import { z } from 'zod';

import { unFormatMoney } from '@/utils/money-parser';

const requiredField = {
  errorMap: () => ({ message: 'Campo obrigatório' }),
};

const minCharacter = (min: number) => `Mínimo de ${min} caracteres`;
const invalidFormat = 'Formato inválido';

const baseRefundSchema = z.object({
  is_edupass_enrollment: z.coerce.boolean(),
  institution_name: z.string(requiredField).min(3, minCharacter(3)),
  course_name: z.string(requiredField).min(3, minCharacter(3)),
  total_course_value: z
    .string(requiredField)
    .regex(
      /^R\$(| | )((?=.*[1-9]|0)(?:\d{1,3}))((?=.*\d)(?:\.\d{3})?)*((?=.*\d)(?:\,\d\d){1}?){0,1}$/,
      invalidFormat
    )
    .transform(unFormatMoney),
  full_refund_amount: z
    .string(requiredField)
    .regex(
      /^R\$(| | )((?=.*[1-9]|0)(?:\d{1,3}))((?=.*\d)(?:\.\d{3})?)*((?=.*\d)(?:\,\d\d){1}?){0,1}$/,
      invalidFormat
    )
    .transform(unFormatMoney),
  name: z.string(requiredField).min(3, minCharacter(3)),
  document: z
    .string(requiredField)
    .regex(/^(\d){3}\.?(\d){3}\.?(\d){3}\-?(\d){2}$/, invalidFormat),
  reason: z.string().min(3, minCharacter(3)).optional().nullable(),
});

export const createRefundSchemaRequest = z
  .discriminatedUnion('payment_method', [
    z
      .object({
        payment_method: z.literal('PIX'),
        pix_key: z.string(requiredField),
      })
      .merge(baseRefundSchema),
    z
      .object({
        payment_method: z.literal('TED'),
        bank_num_name: z.string(requiredField),
        bank_ag: z.string(requiredField),
        bank_cc: z.string(requiredField),
      })
      .merge(baseRefundSchema),
  ])

  .refine(
    ({ full_refund_amount, total_course_value }) =>
      total_course_value >= full_refund_amount,
    {
      path: ['full_refund_amount'],
      message:
        'Valor do reembolso não pode ser maior que o valor total do curso',
    }
  );

export type RefundRequest = z.infer<typeof createRefundSchemaRequest>;
