import { radiuses } from '../tokens';

const MuiDialog = {
  styleOverrides: {
    paper: {
      borderRadius: radiuses.medium,
    },
  },
};

export default MuiDialog;
