import { Component, OnInit } from '@angular/core';

import { UsersService } from 'src/app/services/users.service';
import { CONST } from 'src/app/constants';
import { getAsset } from 'src/app/utils/helpers';

@Component({
  selector: 'app-corporate-university-banner',
  templateUrl: './corporate-university-banner.component.html',
  styleUrls: ['./corporate-university-banner.component.scss'],
})
export class CorporateUniversityBannerComponent implements OnInit {
  lmsName: string;
  logo: string;
  backgroundBanner: string;

  getAsset = getAsset;

  constructor(private userService: UsersService) {}

  ngOnInit(): void {
    this.lmsName =
      this.userService.user.colaborador.empresa.corporate_university_name;

    this.logo = `${CONST.API_ENDPOINT}/contents/categorias/${this.userService.user.colaborador.empresa.logo}`;

    this.backgroundBanner =
      this.userService.user.colaborador.empresa.banner_background;
  }

  getBannerBg(): string {
    return `linear-gradient(to left, ${this.backgroundBanner})`;
  }
}
