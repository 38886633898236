import * as React from 'react';
import { useAccordion } from '@/react/components/refund/accordion/state/useAccordion';

import AccordionItem from '@/react/components/refund/accordion/accordion-item.component';

const Accordion = () => {
  const { steps } = useAccordion();

  return (
    <>
      {Object.entries(steps).map(([key, step]) => {
        return <AccordionItem key={key} id={key} step={step} />;
      })}
    </>
  );
};

export default Accordion;
