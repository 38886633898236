import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { ConfirmStartPlanDialog } from '@/react/components/idp/dialogs/confirm-start-plan-dialog.component';
import { ConfirmFinishPlanDialog } from '@/react/components/idp/dialogs/confirm-finish-plan-dialog.component';

const ShowPlanActionButton = ({
  onClick,
  text,
}: {
  onClick: () => void;
  text: string;
}) => (
  <Grid item xs={12} marginTop={2}>
    <Button variant="contained" fullWidth onClick={onClick}>
      {text}
    </Button>
  </Grid>
);

const ShowPlanActionInfo = ({ text }: { text: string }) => (
  <Grid item xs={12}>
    <Typography fontSize={12}>{text}</Typography>
  </Grid>
);

export const ShowPlanAction = ({ plan }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  return plan.status === 'draft' ? (
    <>
      <ShowPlanActionButton
        onClick={() => setIsModalOpen(true)}
        text="Iniciar plano"
      />

      <ConfirmStartPlanDialog
        plan={plan}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <ShowPlanActionInfo text="Inicie o plano para poder atualizar e acompanhar o progresso das atividades. Não se preocupe, você ainda poderá editar o plano como quiser." />
    </>
  ) : (
    <>
      <ShowPlanActionButton
        onClick={() => setIsModalOpen(true)}
        text="Concluir Plano"
      />

      <ConfirmFinishPlanDialog
        plan={plan}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <ShowPlanActionInfo text="Conclua o plano para sinalizar que você alcançou seus objetivos e está pronto para encerrar esta jornada!" />
    </>
  );
};
