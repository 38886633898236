import * as React from 'react';
import { styled } from '@mui/material/styles';

import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { useCatalog } from '@/react/components/catalog/state';
import { useFilters } from '@/react/components/filters/state';

const Styled = {
  TextContainer: styled('div')`
    align-items: baseline;
    display: flex;
    flex-wrap: wrap;
  `,
  TextNode: styled('div')`
    flex: 0 1 auto;
  `,
};

const updateDocumentTitle = (filterTitle: string) => {
  const baseDocumnentTitle = `Galena Edupass`;

  document.title = `${filterTitle} | ${baseDocumnentTitle}`;
};

export const HeaderTitle = () => {
  const { state, values } = useFilters();
  const { loading, itemsCount } = useCatalog();

  const filterTitle = (() => {
    if (values['terms']?.length > 0) {
      return `${values['terms']}`;
    }

    if (
      values['feature_full']?.length &&
      !values['knowledge_areas']?.length &&
      !values['course_types']?.length
    ) {
      return 'Cursos inclusos no plano';
    }

    if (
      values['course_types']?.length === 1 &&
      !values['knowledge_areas']?.length
    ) {
      return state['course_types'].props.options.find(
        (option) => option.value === values['course_types'].join()
      )?.label;
    }

    if (
      values['knowledge_areas']?.length === 1 &&
      !values['course_types']?.length
    ) {
      return state['knowledge_areas'].props.options.find(
        (option) => option.value === values['knowledge_areas'].join()
      )?.label;
    }

    return `Resultados da busca`;
  })();

  updateDocumentTitle(filterTitle);

  return (
    <Styled.TextContainer>
      <Styled.TextNode>
        <Typography variant="h5" color="neutral.800" mr={1}>
          {filterTitle}
        </Typography>
      </Styled.TextNode>
      <Styled.TextNode>
        <Typography variant="caption">
          {loading ? (
            <Skeleton
              animation="wave"
              width={60}
              sx={{ display: 'inline-block', verticalAlign: 'middle' }}
            />
          ) : (
            `${itemsCount} resultados`
          )}
        </Typography>
      </Styled.TextNode>
    </Styled.TextContainer>
  );
};
