import * as React from 'react';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import WatchLaterRoundedIcon from '@mui/icons-material/WatchLaterRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import {
  AccordionStep,
  AccordionStepStatus,
} from '@/react/components/refund/accordion/interfaces';
import { colors } from '@/react/@mui/theme';
import palette from '@/react/@mui/theme/palette';

const baseIconConfiguration = {
  fontSize: 24,
};

const SuccessIcon = () => (
  <CheckCircleRoundedIcon
    sx={{
      ...baseIconConfiguration,
      color: ({ palette }) => palette.success[700],
    }}
  />
);

const PendingIcon = () => (
  <WatchLaterRoundedIcon
    sx={{
      ...baseIconConfiguration,
      color: ({ palette }) => palette.neutral[500],
    }}
  />
);

const WarningIcon = () => (
  <WarningRoundedIcon
    sx={{
      ...baseIconConfiguration,
      color: palette.attention.contrastText,
    }}
  />
);

const ErrorIcon = () => (
  <CancelRoundedIcon
    sx={{
      ...baseIconConfiguration,
      color: palette.error.main,
    }}
  />
);

const StatusIcon = ({
  status,
}: {
  status: AccordionStep['status'];
}): React.ReactElement => {
  switch (status) {
    case AccordionStepStatus.success:
      return <SuccessIcon />;
    case AccordionStepStatus.pending:
      return <PendingIcon />;
    case AccordionStepStatus.warning:
      return <WarningIcon />;
    case AccordionStepStatus.error:
      return <ErrorIcon />;
    default:
      return null;
  }
};

export default StatusIcon;
