import * as React from 'react';
import { Context } from './context';

import { FiltersSchema, StateActions, StateValue } from './interfaces';

export function useFilters(): UseFiltersType {
  const context = React.useContext(Context);

  if (!context) {
    throw new Error('useFilters must be used within a FiltersContext');
  }

  const [actions, state, schema] = context;

  return {
    actions,
    schema,
    state,
    values: actions.getAllValues(),
  };
}

type UseFiltersType = {
  actions: StateActions;
  schema: FiltersSchema;
  state: Record<string, StateValue>;
  values: Record<string, string[]>;
};
