import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

import { ReceiptFile } from '@/models/receipt-file.model';
import { downloadImage } from '@/utils/download-file';
import { getAsset } from '@/utils/helpers';

export type TableColum = {
  label: string;
  getValue?: (item: any) => string | number;
  onClick?: (item: any) => void;
  getFiles?: (item: any) => any[];
  itemChecked?: (rowIndex: number) => boolean;
  checkItem?: (rowIndex: number) => void;
  type: 'text' | 'radio' | 'radio-text' | 'download' | 'actions';
  headChecked?: () => boolean;
  headClicked?: () => void;
  getActions?: {
    label: string;
    callBack: (item: any) => void;
  }[];
};

export type TablePage = {
  columns: TableColum[];
};

@Component({
  selector: 'app-table-default',
  templateUrl: './table-default.component.html',
  styleUrls: ['./table-default.component.scss'],
})
export class TableDefaultComponent implements OnInit {
  @Input()
  tablePages: TablePage[];

  @Input()
  set data(data: any[]) {
    this._data = data;
    this.changeDisplayData();
  }

  textArea = {};
  textAreaValues = {};

  _data: any;

  @Input()
  salvarButton: boolean;

  @Input()
  hideSaveButton = false;

  @Input()
  maxLength: string = '255';

  @Output()
  save = new EventEmitter();

  openedActions: number;

  page = 0;
  items_per_page = 8;

  approveAll = false;
  rejectAll = false;

  rejectWarning = false;
  completePage = false;

  displayingData: any[];
  emptyRows: any[] = [];

  displayFiles: ReceiptFile[];
  currentDisplay = 0;
  currentPage = 0;

  getUrl: (item: any) => string;

  getAsset = getAsset;

  ngOnInit(): void {}

  updateApproval() {
    const newItems = [];

    this._data.forEach((item) => {
      if (!item.aprovar && !item.recusar) {
        newItems.push(item);
      }
    });

    this._data = newItems;
  }

  onSave() {
    this.save.emit(this.textAreaValues);
  }

  async onDownload(column: TableColum, item) {
    const files = column.getFiles(item) || [];
    const downloadFile = async (index = 0) => {
      if (index >= files.length) return;
      const file = files[index];
      await downloadImage(String(column.getValue(file)));
      await downloadFile(index + 1);
    };
    await downloadFile();
  }

  onView(column: TableColum, item: any) {
    this.currentDisplay = 0;
    this.displayFiles = column.getFiles(item);
    this.getUrl = <any>column.getValue;
  }

  getTranslation(key: string) {
    return `SHARED.TABLE_DEFAULT.${key}`;
  }

  isPdf(item: ReceiptFile): boolean {
    const splited = item.url.split('.');
    return splited[splited.length - 1] === 'pdf';
  }

  onNextPage() {
    if (this.currentDisplay === this.displayFiles.length - 1) {
      this.currentDisplay = 0;
    } else {
      this.currentDisplay += 1;
    }
  }

  onPreviousPage() {
    if (this.currentDisplay === 0) {
      this.currentDisplay = this.displayFiles.length - 1;
    } else {
      this.currentDisplay -= 1;
    }
  }

  openActions(rowIndex: number) {
    if (rowIndex === this.openedActions) {
      this.openedActions = undefined;
    } else {
      this.openedActions = rowIndex;
    }
  }

  changeDisplayData() {
    this.displayingData = this._data.slice(
      this.currentPage * this.items_per_page,
      this.currentPage * this.items_per_page + this.items_per_page
    );

    const emptyRowsCount = this.items_per_page - this.displayingData.length;
    this.emptyRows = [];
    for (let i = 0; i < emptyRowsCount; i++) {
      this.emptyRows.push(true);
    }
  }

  getMaxPages() {
    return Math.ceil(this._data.length / this.items_per_page);
  }

  nextDataPage() {
    this.currentPage += 1;
    this.changeDisplayData();
  }

  previousDataPage() {
    this.currentPage -= 1;
    this.changeDisplayData();
  }

  goToPage(pageNumber: number) {
    this.currentPage = pageNumber;
    this.changeDisplayData();
  }

  radioCheck(column: TableColum, rowIndex: number) {
    column.checkItem(rowIndex);
    this.textArea[rowIndex] = column.type === 'radio-text';
  }

  countColumns(columns: TableColum[]) {
    let columnCount = columns.length;
    if (this.page + 0) {
      columnCount += 1;
    }
    if (this.page < this.tablePages.length - 1) {
      columnCount += 1;
    }
    return columnCount;
  }

  rowIndexOdd(rowIndex: number) {
    return rowIndex % 2 !== 0;
  }

  isItemChecked(column: TableColum, rowIndex: number) {
    const isChecked = column.itemChecked(rowIndex);
    if (!isChecked && column.type === 'radio-text') {
      this.textArea[rowIndex] = false;
      this.textAreaValues[rowIndex] = '';
    }
    return isChecked;
  }

  emptyRowIndexOdd(rowIndex: number) {
    return (this.displayingData.length + rowIndex) % 2 !== 0;
  }

  onTextAreaInput(rowIndex: number, value: string) {
    this.textAreaValues[rowIndex] = value;
  }

  offsetIndex(rowIndex: number) {
    return rowIndex + this.currentPage * this.items_per_page;
  }
}
