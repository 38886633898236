import { NgModule } from '@angular/core';
import { VideoAulasComponent } from './video-aulas/video-aulas.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ConclusaoCursoComponent } from './conclusao-curso/conclusao-curso.component';
import { CursosNavigationComponent } from './cursos-navigation/cursos-navigation.component';
import { CursosPainelComponent } from './cursos-painel/cursos-painel.component';
import { EduplayRoutingModule } from './eduplay-routing.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    VideoAulasComponent,
    ConclusaoCursoComponent,
    CursosNavigationComponent,
    CursosPainelComponent,
  ],
  imports: [CommonModule, SharedModule, EduplayRoutingModule],
})
export class EduplayModule {}
