import React from 'react';

import { IdpClient } from '@/react/data/idp/idp-client';
import { useNotification } from '@/react/components/notification';
import * as Sentry from '@sentry/browser';
import { IdpDialog } from '@/react/components/idp/dialogs/idp-dialog';
import { Plan } from '@/react/data/idp/plans';

export interface ConfirmFinishPlanDialogProps {
  open: boolean;
  onClose: () => void;
  plan: Partial<Plan>;
}

const handleFinishPlan = async ({ plan, onSuccess, onError }) => {
  try {
    await IdpClient.finishPlan(plan.id);
    onSuccess();
  } catch (error) {
    onError();
    Sentry.captureException(error);
  }
};

export const ConfirmFinishPlanDialog = ({
  open,
  onClose,
  plan,
}: ConfirmFinishPlanDialogProps) => {
  const { toast } = useNotification();

  const listeners = {
    onError: () => {
      toast({
        content:
          'Ocorreu um erro ao tentar finalizar o plano. Tente novamente mais tarde',
        type: 'error',
      });
      onClose();
    },
    onSuccess: () => {
      toast({
        content: 'Plano finalizado com sucesso!',
        type: 'success',
      });
      window.location.href = '/pdi/planos';
    },
  };

  return (
    <IdpDialog
      open={open}
      onClose={onClose}
      onConfirm={() => handleFinishPlan({ plan, ...listeners })}
      actionLabels={{
        confirm: 'Concluir Plano',
        cancel: 'Voltar',
      }}
      title="Deseja concluir este plano?"
      subtitle="Conclua o plano para sinalizar que você alcançou seus objetivos e está pronto para encerrar esta jornada!"
      description="Você pode rever planos concluídos quando quiser, na seção de Planos de Desenvolvimento."
    />
  );
};
