import { Component, OnInit } from '@angular/core';

import {
  MAX_ITEMS_LOADED,
  PAGE_LIMIT,
  RefundsService,
} from '@/services/api/refunds.service';
import { TablePage } from '@/shared/table-default/table-default.component';
import { formatMoney } from '@/utils/money-parser';
import { Refund, RefundStatus } from '@/models/refund.model';
import { getAsset } from '@/utils/helpers';
import { TranslatedComponent } from '@/utils/translated.component';
import { RouterService } from '@/services/router.service';
import { ReceiptFile } from '@/models/receipt-file.model';
import CONST from '@/constants';
import { LayoutService } from '@/services/layout.service';
@Component({
  selector: 'app-refund-receipt-approval',
  templateUrl: './approval.component.html',
  styleUrls: ['./approval.component.scss'],
})
export class RefundReceiptApprovalComponent
  extends TranslatedComponent
  implements OnInit
{
  translationKey = 'EMPRESA.REFUND.REFUND_PENDING';

  pendingRefunds: Refund[] = [];

  approvingRefunds = {};
  rejectingRequests = {};
  reasons = {};

  salvarButton = false;
  isLoading = false;

  getAsset = getAsset;

  tablePages: TablePage[] = [
    {
      columns: [
        {
          label: 'Ações',
          getValue: () => 'Aprovar',
          itemChecked: (rowIndex) => this.approvingRefunds[rowIndex],
          checkItem: (rowIndex) => {
            this.salvarButton = true;
            this.approvingRefunds[rowIndex] = !this.approvingRefunds[rowIndex];
            this.rejectingRequests[rowIndex] = false;
          },
          type: 'radio',
        },
        {
          label: '',
          getValue: () => 'Reprovar',
          itemChecked: (rowIndex) => this.rejectingRequests[rowIndex],
          checkItem: (rowIndex) => {
            this.salvarButton = true;
            this.approvingRefunds[rowIndex] = false;
            this.rejectingRequests[rowIndex] =
              !this.rejectingRequests[rowIndex];
          },
          type: 'radio-text',
        },
        {
          label: 'Comprovante',
          getValue: (item: ReceiptFile) =>
            `${CONST.API_ENDPOINT}/refunds/invoices/file/${item.url}`,
          getFiles: (item: Refund) =>
            item.refund_receipts[item.refund_receipts.length - 1].receipt_files,
          type: 'download',
        },
        {
          label: 'Nome',
          getValue: (item: Refund) => item.user.nome,
          type: 'text',
        },
        {
          label: 'E-mail',
          getValue: (item: Refund) => item.user.email,
          type: 'text',
        },
        {
          label: 'Valor do Reembolso',
          getValue: (item: Refund) =>
            formatMoney(item.full_refund_amount.toString()),
          type: 'text',
        },
        {
          label: 'Reembolso (%)',
          getValue: (item: Refund) => `${item.refund_percentage}%`,
          type: 'text',
        },
      ],
    },
    {
      columns: [
        {
          label: 'Instituição',
          getValue: (item: Refund) => item.institution_name,
          type: 'text',
        },
        {
          label: 'Curso',
          getValue: (item: Refund) => item.course_name,
          type: 'text',
        },
        {
          label: 'Matrícula com Galena',
          getValue: (item: Refund) =>
            item.is_edupass_enrollment ? 'Sim' : 'Não',
          type: 'text',
        },

        {
          label: 'Justificativa',
          getValue: (item: Refund) => item.reason || '',
          type: 'text',
        },
      ],
    },
  ];

  constructor(
    private refundService: RefundsService,
    private routerService: RouterService,
    private layoutService: LayoutService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadPendingRefunds();
  }

  async getPaginatedRefunds(page = 1, cachedRefunds: Refund[] = []) {
    try {
      const refunds = await this.refundService.listInvoices({
        status: RefundStatus.REFUND_PENDING,
        page,
      });

      const mergedRefunds = cachedRefunds.concat(refunds);

      if (
        refunds.length === PAGE_LIMIT &&
        mergedRefunds.length < MAX_ITEMS_LOADED
      ) {
        return await this.getPaginatedRefunds(page + 1, mergedRefunds);
      }
      return mergedRefunds;
    } catch (e) {
      console.error(e);
    }
  }

  async loadPendingRefunds() {
    this.isLoading = true;
    this.pendingRefunds = [];
    this.pendingRefunds = await this.getPaginatedRefunds();
    this.isLoading = false;
  }

  async onSave(reasons) {
    this.reasons = reasons;

    await this.refundService.reviewInvoicesRequests({
      approvingRequestsIds: this.getApprovingIds(),
      rejectingRequests: this.getReprovingRequests(),
    });

    this.layoutService.success('Reembolsos aprovados/reprovados com sucesso');

    this.rejectingRequests = {};
    this.approvingRefunds = {};

    this.loadPendingRefunds();
  }

  getApprovingIds(): number[] {
    const filteredApprovingItems = Object.entries(this.approvingRefunds).filter(
      ([_key, value]) => value
    );

    return filteredApprovingItems.map(([key, _value]) => {
      return this.pendingRefunds[key].id;
    });
  }

  getReprovingRequests() {
    const filteredRejectingItems = Object.entries(
      this.rejectingRequests
    ).filter(([_key, value]) => value);

    return filteredRejectingItems.map(([key, _value]) => {
      return {
        id: this.pendingRefunds[key].id,
        reason: this.reasons[key],
      };
    });
  }

  onGoToActive() {
    this.routerService.navigate(['/empresa/refund/refund_approved']);
  }
}
