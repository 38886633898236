import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { SafeParseError } from 'zod';

import { SelectOption } from '@/components/form/select/select.component';
import banksData from '@/data/banks.json';
import {
  RefundsService,
  SubscribedCourse,
  SubscribedInstitution,
} from '@/services/api/refunds.service';
import { formatMoney, unFormatMoney } from '@/utils/money-parser';
import { TranslatedComponent } from '@/utils/translated.component';

import { createRefundSchemaRequest, RefundRequest } from './refund-form.schema';
import { ColaboradorRoutes } from '../colaborador.routes';
import { Router } from '@angular/router';

@Component({
  selector: 'app-refund-form',
  templateUrl: './refund-form.component.html',
  styleUrls: ['./refund-form.component.scss'],
})
export class RefundFormComponent extends TranslatedComponent implements OnInit {
  translationKey = 'COLABORADOR.REFUND_FORM';

  banksOptions: SelectOption[] = banksData;

  selectedBank: SelectOption;
  bankDropdownOpen = false;
  searchingBanks: SelectOption[] = [];

  institutionsOptions: SelectOption[] = [];
  selectedInstitution: SelectOption;

  coursesOptions: SelectOption[] = [];
  selectedCourse: SelectOption;

  budget = 0;
  initialBudget = 0;

  paymentMethodsOptions: SelectOption[] = [
    {
      label: 'PIX',
      value: 'PIX',
    },
    {
      label: 'TED',
      value: 'TED',
    },
  ];
  selectedPaymentMethod: SelectOption = this.paymentMethodsOptions[0];

  subscribedInstitutions: SubscribedInstitution[] = [];
  subscribedCourses: SubscribedCourse[] = [];

  isCreating = false;

  form = new FormGroup({
    is_edupass_enrollment: new FormControl(false),
    institution_name: new FormControl(),
    course_name: new FormControl(),
    total_course_value: new FormControl(),
    full_refund_amount: new FormControl(),
    payment_method: new FormControl('PIX'),
    name: new FormControl(),
    document: new FormControl(),
    bank_num_name: new FormControl(),
    bank_ag: new FormControl(),
    bank_cc: new FormControl(),
    pix_key: new FormControl(),
    reason: new FormControl(),
  });

  formErrors: Record<string, string | null>;

  constructor(private refundService: RefundsService, private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.loadSubscriptions();
    this.createFormErrorsBase();
    this.loadBudget();
  }

  async loadBudget() {
    this.initialBudget = await this.refundService.loadBudget();
    this.budget = this.initialBudget;

    if (this.initialBudget === 0) {
      this.router.navigate([
        ColaboradorRoutes.MY_REFUNDS,
        ColaboradorRoutes.OVERVIEW,
      ]);
    }
  }

  createFormErrorsBase() {
    const formErrors = {};

    for (const controlKey in Object.keys(this.form.controls)) {
      formErrors[controlKey] = null;
    }

    this.formErrors = formErrors;
  }

  async loadSubscriptions() {
    this.subscribedInstitutions = await this.refundService.loadSubscriptions();
    this.institutionsOptions = this.subscribedInstitutions.map(
      (institution) => {
        return {
          label: institution.instituicao,
          value: institution.id,
        };
      }
    );
  }

  mountCourseOptions(institutionId?: number) {
    this.onCourseSelected();

    this.subscribedCourses = institutionId
      ? this.subscribedInstitutions.find(
          (institution) => institution.id === institutionId
        ).cursos
      : [];

    this.coursesOptions = this.subscribedCourses.map((course) => ({
      label: course.curso,
      value: course.id,
    }));
  }

  onInstitutionSelected(option?: SelectOption | SelectOption[]) {
    this.selectedInstitution = option as SelectOption;

    this.form.controls.institution_name.setValue(
      this.selectedInstitution?.label || ''
    );

    this.mountCourseOptions(this.selectedInstitution?.value as number);
  }

  calculateCoursePrice(subscriberCourse: SubscribedCourse): string {
    return formatMoney(
      ((100 - subscriberCourse.desconto) / 100) *
        subscriberCourse.valor_original
    );
  }

  onCourseSelected(option?: SelectOption | SelectOption[]) {
    this.selectedCourse = option as SelectOption;

    if (!this.selectedCourse) {
      this.form.controls.course_name.setValue(undefined);
      this.form.controls.total_course_value.setValue(undefined);
      return;
    }

    const subscribedCourse = this.subscribedCourses.find(
      (course) => course.id === this.selectedCourse.value
    );

    this.form.controls.course_name.setValue(subscribedCourse.curso);
    this.form.controls.total_course_value.setValue(
      this.calculateCoursePrice(subscribedCourse)
    );
  }

  onAmountBlur(value: string) {
    this.form.controls.full_refund_amount.setValue(formatMoney(value));
    this.budget = this.initialBudget - unFormatMoney(value);
  }

  onCourseValueBlur(value: string) {
    this.form.controls.total_course_value.setValue(formatMoney(value));
  }

  onPaymentMethodSelected(option: SelectOption | SelectOption[]) {
    if (option === this.selectedPaymentMethod) {
      return;
    }

    this.selectedPaymentMethod = option as SelectOption;

    this.form.controls.payment_method.setValue(
      this.selectedPaymentMethod.label
    );
    this.form.controls.bank_num_name.setValue(null);
    this.form.controls.bank_ag.setValue(null);
    this.form.controls.bank_cc.setValue(null);
    this.form.controls.pix_key.setValue(null);
  }

  onBankSelected(option: SelectOption | SelectOption[]) {
    this.selectedBank = option as SelectOption;
    this.form.controls.bank_num_name.setValue(this.selectedBank.label);
    this.bankDropdownOpen = false;
  }

  cleanErrors() {
    for (const errorKey in Object.keys(this.formErrors)) {
      this.formErrors[errorKey] = null;
    }
  }

  checkRefundAmount() {
    const totalCourseValue = unFormatMoney(
      this.form.controls.total_course_value.value
    );
    const fullRefundAmount = unFormatMoney(
      this.form.controls.full_refund_amount.value
    );
    if (totalCourseValue < fullRefundAmount) {
      this.formErrors.full_refund_amount =
        'Valor do reembolso não pode ser maior que o valor total do curso';
      return;
    }
    if (fullRefundAmount > this.initialBudget) {
      this.formErrors.full_refund_amount =
        'Valor do reembolso acima do limite disponível';
      return;
    }
  }

  async onSubmit() {
    if (!this.isCreating) {
      this.isCreating = true;

      this.cleanErrors();

      const parsedForm = createRefundSchemaRequest.safeParse(
        this.form.getRawValue()
      );

      if (!parsedForm.success) {
        const parseError = parsedForm as SafeParseError<RefundRequest>;

        parseError.error.issues.map((error) => {
          for (const path of error.path) {
            this.formErrors[path] = error.message;
          }
        });

        if (!this.formErrors.full_refund_amount) {
          this.checkRefundAmount();
        }

        document.getElementsByClassName(
          'scrollable-container'
        )[0].scrollTop = 0;

        this.isCreating = false;
        return;
      }

      try {
        await this.refundService.createRefund(parsedForm.data);
        this.form.reset();
        this.isCreating = false;
        this.router.navigate([
          ColaboradorRoutes.MY_REFUNDS,
          ColaboradorRoutes.PENDING,
        ]);
      } catch (e) {
        if (e.status === 400) {
          this.formErrors.full_refund_amount = e.error.data.message;

          document.getElementsByClassName(
            'scrollable-container'
          )[0].scrollTop = 0;
        }

        this.isCreating = false;
      }
    }
  }

  isEdupass(edupassEnrollment: boolean) {
    this.form.controls.course_name.setValue('');
    this.form.controls.total_course_value.setValue('');

    this.onInstitutionSelected();

    this.form.controls.is_edupass_enrollment.setValue(edupassEnrollment);
  }

  onInputBank(value: string) {
    if (!value) {
      return;
    }
    const banks = this.banksOptions.filter((bank) =>
      bank.label.toLowerCase().includes(value.toLowerCase())
    );
    this.searchingBanks = banks;
    this.bankDropdownOpen = true;
  }
}
