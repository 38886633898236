import { LayoutService } from 'src/app/services/layout.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { CursoAluno } from 'src/app/models/curso-aluno';
import { MediaService } from 'src/app/services/media/media.service';
import { RouterService } from 'src/app/services/router.service';

@Component({
  selector: 'app-cursos-painel',
  templateUrl: './cursos-painel.component.html',
  styleUrls: ['./cursos-painel.component.scss'],
})
export class CursosPainelComponent implements OnInit {
  menu: string = 'curso-andamento';

  cursosConcluidos: CursoAluno[];
  cursosAndamento: CursoAluno[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private mediaService: MediaService,
    private routerService: RouterService,
    private layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.cursosConcluidos = [];
    this.cursosAndamento = [];

    this.activatedRoute.url.subscribe((url) => {
      this.menu = url[0].path;
      this.layoutService.updateBreadCrumb([
        {
          label: 'Home',
          route: ['/'],
        },
        {
          label: 'Meu aprendizado',
        },
        {
          label:
            this.menu === 'cursos_andamento'
              ? 'Cursos em andamento'
              : 'Cursos concluídos',
        },
      ]);
    });
    this.getMeusCursos();
  }

  async getMeusCursos() {
    try {
      const meusCursos = await this.mediaService.getMeusCursos();

      meusCursos.forEach((cursoAluno) => {
        if (cursoAluno.is_completed) {
          this.cursosConcluidos.push(cursoAluno);
        } else {
          this.cursosAndamento.push(cursoAluno);
        }
      });
    } catch (e) {
      this.routerService.home();
    }
  }

  getBannerLabel(): string {
    return this.menu === 'cursos_andamento'
      ? this.getTranslation('andamento')
      : this.getTranslation('concluido');
  }

  getTranslation(key: string): string {
    return `EDUPLAY.CURSOS_PAINEL.${key}`;
  }
}
