<div class="d-flex mb-4 checkout-steps" #responsiveSteps>
  <div
    *ngFor="let step of steps; let i = index"
    class="d-flex flex-column flex-grow-1 align-items-center justify-content-between"
  >
    <p
      class="BodyText-1 edupass-purple"
      [ngClass]="{ active: i === stepIndex, disabled: i > stepIndex }"
    >
      {{ step }}
    </p>
    <div class="d-flex w-100 align-items-center">
      <span class="line" [ngClass]="{ active: i <= stepIndex }"></span>
      <span class="marker" [ngClass]="{ active: i <= stepIndex }"></span>
      <span class="line" [ngClass]="{ active: i < stepIndex }"></span>
    </div>
  </div>
</div>
