import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { getSvg } from '@/react/utils/assets';

const EmptyState = () => (
  <Stack
    spacing={4}
    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
  >
    <Typography variant="h4" align="center">
      Ainda não temos ofertas disponíveis para sua pesquisa! :(
    </Typography>
    <img src={getSvg('empty_state_catalog.svg')} role="img" />
    <Typography variant="body2" align="center">
      Altere os filtros para encontrar as ofertas disponíveis.
    </Typography>
  </Stack>
);

export default EmptyState;
