import { styled } from '@mui/material';
import React from 'react';
import cx from 'clsx';

const StyledUnstyledLink = styled('a')`
  &,
  &:visited,
  &:hover,
  &:active {
    font-style: inherit;
    color: inherit;
    background-color: transparent;
    font-size: inherit;
    text-decoration: none;
    font-variant: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-family: inherit;
  }
  display: flex;
`;

export function UnstyledLink(props: UnstyledLinkProps) {
  return (
    <StyledUnstyledLink
      {...props}
      className={cx(props.className, 'UnstyledLink', {
        'has-link': !!props.href,
        'has-no-link': !props.href,
      })}
    />
  );
}

export type UnstyledLinkProps = Parameters<
  typeof StyledUnstyledLink
>[0] extends infer Props
  ? ({ [K in keyof Props]: Props[K] } & {}) & {}
  : never;
