import * as React from 'react';
import { Grid, Box, LinearProgress } from '@mui/material';
import { nanoid } from 'nanoid';
import { RefundCard } from './refund-card.component';
import { EmptyList } from '@/react/components/refund/empty-list.component';
import FilterTabs from '@/react/components/filter-tabs/filter-tabs.component';
import Pagination from '@/react/components/pagination/pagination.component';
import { ResourceApproval } from '@/react/data/subsidy/interfaces';
import { useResourceApprovalsList } from '@/react/data/subsidy/useResourceApprovalsList';
import { useRefundList } from './state';

export const RefundList = () => {
  const {
    currentLabel,
    handlePageChange,
    handleTabChange,
    page,
    status,
    value,
  } = useRefundList();

  const itemsPerPage = 4;

  const labels = ['Todos', 'Abertos', 'Finalizados'];

  const requestParams = {
    status: status,
  };

  const { requestResourceApprovalsList, isFetching, error } =
    useResourceApprovalsList(requestParams);

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedList = requestResourceApprovalsList.slice(
    startIndex,
    endIndex
  );
  const totalPages = Math.ceil(
    requestResourceApprovalsList.length / itemsPerPage
  );

  return (
    <FilterTabs value={value} onChange={handleTabChange} labels={labels}>
      {labels.map((label, index) => (
        <Grid item xs={12} key={index + nanoid()}>
          {currentLabel.includes(label) && (
            <>
              {isFetching && !error && <LinearProgress />}
              {!isFetching && (paginatedList.length === 0 || error) && (
                <EmptyList />
              )}
              {paginatedList.map(
                (refundItem: ResourceApproval, index: Number) => (
                  <Box key={index + nanoid()}>
                    <RefundCard refundItem={refundItem} />
                  </Box>
                )
              )}
              {paginatedList.length > 0 && (
                <Box display="flex" justifyContent="flex-end" my={2}>
                  <Pagination
                    currentPage={page}
                    pageChange={handlePageChange}
                    totalPages={totalPages}
                  />
                </Box>
              )}
            </>
          )}
        </Grid>
      ))}
    </FilterTabs>
  );
};
