import { restClient } from '@/react/utils/fetch';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { toastError } from '@/react/utils/custom-toast';

import AutocompleteCompanies from './autocomplete-companye';
import EmployeeList from './listing/employee-list.component';
import { ManageEmployeesProvider } from './manage-employees.context';

const fetchCompanies = async (companyId = '') => {
  try {
    let urlSearchParam = {
      page: 1,
      limit: 100,
    };
    if (companyId) {
      urlSearchParam = { company_id: companyId, page: 1, limit: 100 };
    } else {
      urlSearchParam = {};
    }

    const res = await restClient.get(
      `/employees?${new URLSearchParams(urlSearchParam)}`
    );

    return {
      employees: res?.data?.data?.employees,
      companies: res?.data?.data?.empresas,
    };
  } catch (error) {
    console.error('Erro ao buscar empresas:', error);
    return [];
  }
};

const ManageEmployee = () => {
  const { data: fetchedData = {} } = useQuery({
    queryKey: ['bootstrapData'],
    queryFn: () =>
      restClient.get('/users/bootstrap-data').then((res) => res.data.data),
  });

  const permissions = fetchedData?.info?.permissoes ?? [];
  const companyIDByUSer = fetchedData?.info?.colaborador?.empresa?.id;
  const permissionsBuyUser = permissions.map((permission) => permission.nome);
  const isPermissionToManager = permissionsBuyUser.includes('Admin');

  React.useEffect(() => {
    if (!isPermissionToManager) {
      return;
    }
  }, [isPermissionToManager]);

  const [employees, setEmployees] = React.useState([]);
  const [companies, setCompanies] = React.useState([]);
  const [companySelected, setCompanySelected] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      try {
        const companyID =
          !isPermissionToManager && companyIDByUSer ? companyIDByUSer : '';

        const data = await fetchCompanies(companyID);
        setEmployees(data.employees);
        setCompanies(data.companies);
      } catch (error) {
        console.error('Erro ao buscar empresas:', error);
      }
    };

    fetchData();
    setIsLoading(false);
  }, []);

  React.useEffect(() => {
    if (employees || companies) {
      return;
    }
  }, [employees, companies]);

  const handleCompanySelection = async (selectedCompany) => {
    setIsLoading(true);

    const companyId = selectedCompany?.id;
    setCompanySelected(companyId);

    try {
      const response = await fetchCompanies(companyId);

      setEmployees(response.employees);
    } catch (error) {
      console.error('Erro ao buscar empresas:', error);
    }
    setIsLoading(false);
  };

  const updateEmployee = async () => {
    setIsLoading(true);
    const response = await fetchCompanies(companySelected);
    setEmployees(response.employees);
    setIsLoading(false);
  };

  const handleSendToRegister = () => {
    let companyId = companySelected;
    if (!isPermissionToManager && !companyId && companyIDByUSer) {
      companyId = companyIDByUSer;
    }

    if (!companyId) {
      const messageToast =
        'Selecione uma empresa para adicionar um colaborador.';
      toastError({ messageToast });

      return;
    }

    const urlSearchParam = new URLSearchParams({ company_id: companyId });
    const redirectUrl = `/empresa/employees/register?${urlSearchParam}`;

    window.location.assign(redirectUrl);
  };

  return (
    <ManageEmployeesProvider employees={employees}>
      <Container>
        <Paper elevation={5} sx={{ my: 2 }} style={{ padding: '32px' }}>
          <Grid container spacing={2} my={2}>
            {isPermissionToManager && (
              <Grid item xs={12} md={4}>
                <Typography gutterBottom variant="h4">
                  Selecione a empresa abaixo
                </Typography>
                <AutocompleteCompanies
                  companyes={companies}
                  onCompanySelect={(selectedCompany) => {
                    handleCompanySelection(selectedCompany);
                  }}
                />
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2} my={2}>
            <Grid item sm xs={12}>
              <Typography gutterBottom variant="h4">
                Gestão de colaboradores
              </Typography>
              <Typography variant="body2">
                Visualize, adicione, edite e remova colaboradores.
              </Typography>
            </Grid>
            <Grid item sm="auto" xs={12}>
              <Button
                color="primary"
                fullWidth
                size="medium"
                startIcon={<AddIcon />}
                variant="contained"
                onClick={handleSendToRegister}
              >
                Adicionar colaborador
              </Button>
            </Grid>
          </Grid>
          <Divider sx={{ my: 3 }} />
          <EmployeeList
            employees={employees}
            updateEmployee={updateEmployee}
            isLoading={isLoading}
            companyIdSelected={companySelected}
            companyIDByUSer={companyIDByUSer}
          />
        </Paper>
      </Container>
    </ManageEmployeesProvider>
  );
};

export default ManageEmployee;
