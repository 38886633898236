const MuiInputBase = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontSize: theme.typography.body3.fontSize,
      '.MuiInputBase-input.Mui-disabled': {
        WebkitTextFillColor: theme.palette.neutral[800],
        color: theme.palette.neutral[800],
        backgroundColor: theme.palette.neutral[200],
      },
    }),
  },
};

export default MuiInputBase;
