import { z } from 'zod';

export const Event = z.object({
  event: z.string(),
  user_id: z.string(),
});

export const EcommerceFragment = z.object({
  item_name: z.string(),
  item_id: z.number(),
  price: z.number().nullable(),
  item_brand: z.string(),
  item_category: z.string().default('default'),
  item_category2: z.string().default('default'),
  item_category3: z.string().default('default'),
  item_category4: z.number(),
  item_variant: z.string(),
  item_list_name: z.string().default('default'),
  item_list_id: z.string().default('default'),
  index: z.number().default(0),
  quantity: z.number().default(1),
});

export const CourseEvent = Event.extend({
  event: z.enum(['view_item', 'view_item_list', 'select_item']),
  items: z.array(EcommerceFragment),
});

export const ClickEvent = Event.extend({
  event: z.enum(['login', 'add_to_cart']),
  acao: z.literal('click'),
  botao: z.enum(['login', 'iniciar_matricula', 'finalizar_inscricao']),
});

export const EnrollmentEvent = ClickEvent.extend({
  ecommerce: z.object({
    items: z.array(EcommerceFragment),
  }),
});

export const SubscriptionEvent = Event.extend({
  event: z.enum(['begin_checkout']),
  bolsa: z.number().default(0),
  journey: z.enum(['subscription']),
  ecommerce: z.object({
    items: z.array(EcommerceFragment),
  }),
});

export const FinishSubscriptionEvent = ClickEvent.extend({
  event: z.enum(['finish_subscription']),
  bolsa: z.number().default(0),
  journey: z.enum(['subscription']),
  ecommerce: z.object({
    items: z.array(EcommerceFragment),
  }),
});

export const PurchaseEvent = Event.extend({
  event: z.enum(['purchase']),
  comission: z.number().default(0),
  journey: z.enum(['subscription', 'checkout']),
  currency: z.string().default('BRL'),
  value: z.number(),
  tax: z.number().default(0),
  ecommerce: z.object({
    items: z.array(EcommerceFragment),
  }),
  payment_method: z.string().default('default'),
});

export const CheckoutEvent = Event.extend({
  event: z.enum(['begin_checkout', 'add_shipping_info', 'add_payment_info']),
  offer_id: z.number(),
  journey: z.enum(['checkout']),
  ecommerce: z.object({
    items: z.array(EcommerceFragment),
  }),
});

export const FinishCheckoutEvent = PurchaseEvent.extend({
  coupon: z.string().default('default'),
  offer_id: z.number(),
  transaction_id: z.number(),
});
