<div class="nav-container">
  <div class="top-nav w-100">
    <a
      *ngFor="let corporateMenu of menus"
      class="top-nav-item active"
      [ngClass]="{ active: isActive(corporateMenu) }"
      (click)="navigate(corporateMenu)"
      [ngStyle]="getBoxShadow(corporateMenu)"
    >
      <span> {{ getTranslation(corporateMenu) | translate }} </span>
    </a>
  </div>
</div>
