<table>
  <tr>
    <th *ngFor="let collumn of collumns">
      {{ collumn.header | translate }}
    </th>
  </tr>

  <tr
    *ngFor="let dataItem of displayingData"
    (click)="onRowClick(dataItem)"
    [ngClass]="{ cursorPointer: rowClick }"
  >
    <td
      *ngFor="let collumn of collumns"
      [ngStyle]="getStyle(collumn, dataItem)"
    >
      <span class="d-flex">
        {{ collumn.getData(dataItem) }}
        <span
          class="material-icons-outlined ml-2 reason-tooltip"
          data-toggle="tooltip"
          data-placement="right"
          [title]="getTooltip(collumn, dataItem)"
          *ngIf="getIcon(collumn, dataItem)"
        >
          {{ getIcon(collumn, dataItem) }}
        </span>
      </span>
    </td>
  </tr>
  <tr *ngIf="displayingData.length === 0">
    <td [attr.colspan]="collumns.length">Nenhum dado para exibir</td>
  </tr>
</table>
<div class="pagination">
  <div [ngClass]="{ selectable: page > 1 }" (click)="previousPage()">&lt;</div>
  <div *ngIf="page > 3" (click)="gotToPage(1)" class="selectable">1</div>
  <div *ngIf="page > 4">...</div>
  <div
    *ngFor="let pageNum of navigationPages"
    (click)="gotToPage(pageNum)"
    [ngClass]="{ selectable: page !== pageNum, active: page === pageNum }"
  >
    {{ pageNum }}
  </div>
  <div *ngIf="page + 3 < totalPages">...</div>
  <div
    *ngIf="page + 2 < totalPages"
    (click)="gotToPage(totalPages)"
    class="selectable"
  >
    {{ totalPages }}
  </div>

  <div [ngClass]="{ selectable: !isLastPage() }" (click)="nextPage()">></div>
</div>
