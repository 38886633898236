import { isBrowser } from 'powership';

export const IS_LOCALHOST =
  isBrowser() && window.location.hostname === 'localhost';

export const IS_STAGING_HOST =
  isBrowser() && window.location.hostname === 'staging-plataforma.galena.com';

export const IS_PRODUCTION_HOST =
  isBrowser() && window.location.hostname == 'plataforma.galena.com';

export const ENV = (() => {
  if (IS_LOCALHOST) return 'localhost';
  if (IS_STAGING_HOST) return 'staging';
  return 'production'; // defaults to the more restricting env.
})();

export type ENV = typeof ENV;

// just a helper to get config based on
// current hostname with type safety
export function configForCurrentHost<Config extends { [K in ENV]: unknown }>(
  config: Config
): Config[ENV] {
  return config[ENV];
}
