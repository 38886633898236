import React, { ReactNode, useEffect } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';

import { styledBoxes } from '../utils/styled-boxes';

import { QuizContext, QuizState, useQuiz } from '../state';
import { QuestionNode } from '../quiz.interfaces';

import Dialog from '@mui/material/Dialog';
import { QuestionTypeRadio } from './question-type-radio';
import { QuestionTypePresentation } from './question-type-presentation';
import { QuestionTypeAddress } from './question-type-address';
import { QuestionTypeBoxSelection } from './question-type-box-selection';
import { QuestionTypeEmailContact } from './question-type-email';
import { QuestionTypeJobSelection } from './question-type-job_selection';
import { QuestionTypeTagSelection } from './question-type-tag-selection';

import { QuestionNames } from '@/react/components/profile-quiz/profile-quiz.questions';

const Styled = styledBoxes('Quiz', {
  Wrapper: {
    width: '100%',
    height: '100%',
  },
  Placeholder: {},
});

const componentByKind: {
  [K in QuestionNode['kind']]: (props: any) => ReactNode;
} = {
  radio: QuestionTypeRadio,
  presentation: QuestionTypePresentation,
  address: QuestionTypeAddress,
  box_selection: QuestionTypeBoxSelection,
  email_contact: QuestionTypeEmailContact,
  job_selection: QuestionTypeJobSelection,
  tag_selection: QuestionTypeTagSelection,
};

export function Quiz(props: QuestionProps) {
  let { className, state, variation, onFinish } = props;

  const [isExploding, setIsExploding] = React.useState(false);

  useEffect(() => {
    if (!state) return;

    return state.observe(
      (s) => {
        return s.activeNodeId;
      },
      async ({ previous, next }) => {
        const finished =
          previous === QuestionNames.presentation_finished && next === null;

        if (finished) {
          setIsExploding(true);
          onFinish();
        }
      }
    );
  }, [state]);

  return (
    <>
      {isExploding && <ConfettiExplosion />}

      {state && (
        <QuizContext value={state}>
          <Styled.Wrapper className={className}>
            <QuestionActive variation={variation} />
          </Styled.Wrapper>
        </QuizContext>
      )}
    </>
  );
}

function QuestionActive(props: { variation: QuizComponentVariation }) {
  let { variation } = props;

  const [{ kind, open }] = useQuiz((state) => {
    const node = state.nodeById[state.activeNodeId];

    return {
      kind: node?.kind,
      open: !!node,
    };
  });

  const children = () => {
    if (!open) return <Styled.Placeholder />;
    // @ts-ignore
    return React.createElement(componentByKind[kind]);
  };

  return variation === 'modal' ? (
    <>
      <Dialog onClose={console.log} open={open}>
        {children()}
      </Dialog>
    </>
  ) : (
    children()
  );
}

export type QuizComponentVariation = 'modal' | 'inline';

export type QuestionProps = {
  className?: string;
  state?: QuizState | null | undefined;
  variation: QuizComponentVariation;
  onFinish(): void;
};
