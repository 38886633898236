import * as React from 'react';
import { Context } from './refund-request.context';

import { StateActions } from '@/react/components/refund/refund-request/state/interfaces';
import {
  ResourceApproval,
  SubsidyPolicy,
} from '@/react/data/subsidy/interfaces';

export function useRefundRequest(): useRefundRequest {
  const context = React.useContext(Context);

  if (!context) {
    throw new Error('useRefundRequest must be used within a WizardContext');
  }

  const [state, actions] = context;
  const {
    loading = false,
    resourceApproval = null,
    subsidyPolicy = null,
  } = state;

  return {
    actions,
    loading,
    resourceApproval,
    subsidyPolicy,
  };
}

type useRefundRequest = {
  actions: StateActions;
  loading: boolean;
  resourceApproval: ResourceApproval;
  subsidyPolicy: SubsidyPolicy;
};
