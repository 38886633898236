import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

export interface SelectOption {
  value: string | number;
  label: string;
}
@Component({
  selector: 'component-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit {
  @Input()
  label: string;

  @Input()
  description: string;

  @Input()
  set value(value: SelectOption | SelectOption[]) {
    if (this.multioptions) {
      this._multivalue = value as SelectOption[];
    } else {
      this._value = value as SelectOption;
    }
  }

  _value: SelectOption;

  _multivalue: SelectOption[] = [];

  @Output()
  valueChange = new EventEmitter<SelectOption | SelectOption[]>();

  @Input()
  options: SelectOption[] = [];

  @Input()
  multioptions: boolean = false;

  @Input()
  placeholder: string = '';

  @Input()
  error: boolean = false;

  @Input()
  errorMessage: string;

  @Input()
  disabled: boolean = false;

  static selectId = 0;

  dropdownDisplay: boolean = false;
  myId: string;

  constructor() {}

  ngOnInit(): void {
    this.myId = `select-${SelectComponent.selectId}`;
    SelectComponent.selectId++;
  }

  onClickOutside(e) {
    if (e.target.className.split(' ').indexOf(this.myId) === -1) {
      this.dropdownDisplay = false;
    }
  }

  optionSelected(option: SelectOption) {
    if (!this.multioptions) {
      this.dropdownDisplay = false;
      this.valueChange.emit(option);
    } else {
      this._multivalue.push(option);
      this.valueChange.emit(this._multivalue);

      this.options = this.options.filter(
        (optionItem) => optionItem.value != option.value
      );
      if (this.options.length === 0) {
        this.dropdownDisplay = false;
      }
    }
  }

  onRemoveOption(option: SelectOption) {
    this.options.push(option);
    this.valueChange.emit(
      (this.value as SelectOption[])
        .filter((filtering) => filtering.value !== option.value)
        .slice()
    );
  }

  onInputClicked() {
    if (!this.disabled) {
      this.dropdownDisplay = !this.dropdownDisplay;
    }
  }
}
