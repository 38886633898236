import axios from 'axios';
import CONST from '@/constants';
import { withAuthorization } from '@/react/config/axios';

export class CourseClient {
  static BASE_URL = CONST.BACKEND_URL;

  constructor() {}

  static client = withAuthorization(
    axios.create({
      baseURL: CourseClient.BASE_URL,
    })
  );

  static enroll = async (input: {
    signal?: AbortSignal;
    offerId: number;
  }): Promise<any> => {
    const { signal, offerId } = input;

    const res = await CourseClient.client.post(
      `/eduplay/cursos/${offerId}/inscrever`,
      { signal }
    );

    return res.data;
  };

  static getSubscriptionsByCourseId = async (courseId: number): Promise<any> => {
    const res = await CourseClient.client.get(`/api/cursos/${courseId}/registration`);

    return res.data;
  };
}