import { Component } from '@angular/core';
import type { ComponentProps } from 'react';
import { CourseThemesPageComponent } from '@/react/components/cursos/course-themes.component';

@Component({
  templateUrl: './home.component.html',
})
export class CourseThemesPage {
  CourseThemesPageComponent = CourseThemesPageComponent;
  reactCourseThemesPageProps: ComponentProps<typeof CourseThemesPageComponent> =
    {};

  constructor() {}
}
