const MuiCard = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderColor: theme.palette.neutral[300],
      borderStyle: 'solid',
      borderWidth: 1,
    }),
  },
};

export default MuiCard;
