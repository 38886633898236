import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneMaskDirective } from './phone-mask.directive';
import { IfDevelopmentDirective } from './if-development.directive';

@NgModule({
  declarations: [PhoneMaskDirective, IfDevelopmentDirective],
  imports: [CommonModule],
  exports: [PhoneMaskDirective, IfDevelopmentDirective],
})
export class DirectivesModule {}
