import * as React from 'react';
import { useMemo } from 'react';

import { styled } from '@mui/material/styles';
import { filterNull, RouteUtils } from 'powership';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ChevronRight from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import { MegaMenu, MenuItem } from '../mega-menu';
import { UnstyledLink } from '../mega-menu/UnstyledLink';

import Logo from './logo.component';
import { MobileSearchInput, SearchInput } from './searchbar';
import UserMenu from './user-menu.component';

import type { User } from '@/models/user.model';
import cx from 'clsx';
import { useMenuItems } from '@/react/components/appbar/useMenuItems';
import { ZoomLMSService } from '@/services/zoom-lms.service';
import { useScreenSizes } from '@/react/utils/useScreenSizes';
import { UnibradPosService } from '@/services/unibrad_pos/unibrad.service';

type FIXME = any;

interface AppbarProps {
  currentUrl: string;
  onMenuClick: (menu) => void;
  plan: never; // { code: string; features: string[] };
  user: User;
  userMenuGroups: FIXME[];
  window?: () => Window;
  zoomLMSService: ZoomLMSService;
  unibradPosService: UnibradPosService;
}

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  borderBottomStyle: 'solid',
  borderBottomWidth: '1px',
  borderBottomColor: (theme.palette as FIXME).neutral[300],
  marginTop: '-1px',
  '&.MuiPaper-root': {
    backgroundColor: (theme.palette as FIXME).neutral[50],
  },
  zIndex: 1,
}));

const AdjustedBarContainer = styled(Container)`
  // adjusting menu breaks in medium screens specially below 1200px
  &.isMediumScreen {
    max-width: 100vw;
    &&& a.UnstyledLink {
      margin-left: 0;
      button {
        font-size: min(14px, 1.2vw);
      }
    }

    @media screen and (max-width: 1100px) {
      .menu-item-included_in_your_plan {
        display: none !important;
      }
    }
  }
`;

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  display: 'block',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
  '& .MuiDrawer-paper': {
    backgroundColor: (theme.palette as FIXME).neutral[50],
    boxSizing: 'border-box',
    width: '85%',
  },
}));

const MainMenuLink = styled(LoadingButton)(({ theme }) => ({
  color: (theme.palette as FIXME).text.primary,
  borderRadius: theme.spacing(1),
  fontSize: (theme.typography as FIXME).body3.fontSize,
  padding: theme.spacing(1),
  fontWeight: 400,
  '&.MuiButton-contained': {
    color: (theme.palette as FIXME).primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    fontWeight: 600,
    '&:focus': {
      fontWeight: 600,
    },
  },
  '&:disabled': {
    color: theme.palette.primary.contrastText,
    opacity: theme.palette.action.disabledOpacity,
  },
}));

const MainMenuDropdown = styled(Menu)`
  margin-left: -120px;
  @media screen and (min-width: 1024px) {
    margin-left: -130px;
  }

  & .MuiPaper-root {
    background-color: ${({ theme }) => theme.palette.background.paper};
  }
`;

const RowMiddle = styled(Box)`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  text-transform: uppercase;
  opacity: 0.7;
  line-height: 1;
  align-items: center;
  font-weight: bold;
  svg {
    width: 14px;
    height: 14px;
    margin-left: 3px;
    margin-top: 3px;
  }
`;

/**
 * The main layout app bar
 * Notes:
 * -  (FIXME) some parts are using the data (via props) from src/app/layout/appbar/appbar.component.ts
 *      and others are using data from the (useQuery hook) bootstrap-data api -
 *      the ideal is to use bootstrap-data as a single source of truth.
 * -  On mobile all links are rendered by the MegaMenu component
 *    but on large screens the horizontal menu bar is rendered by the previous
 *    rendering logic here - we can move all logic to MegaMenu.
 * @param props
 * @constructor
 */
const Appbar = (props: AppbarProps) => {
  const { currentUrl, user, userMenuGroups, zoomLMSService, unibradPosService } = props;

  const WINDOW = (() => {
    if (props.window) return props.window();
    return typeof window !== 'undefined' ? window : undefined;
  })();

  const { serverData, menu } = useMenuItems();
  const plan = serverData?.plan;
  const { notDesktopSize, isMediumScreen } = useScreenSizes();
  const viewAsHamburger = notDesktopSize;

  const items = useMemo((): MenuItem[] => {
    const hasZoomLMS = plan?.features?.some((el) => el.name === 'zoom_lms');
    const hasFeatureUniBrad = plan?.features?.some((feat) => feat.name === 'unibrad_pos');
    const hasCustomCategory = user.hasCustomCategory();

    return filterNull<MenuItem>([
      menu.byId.start,
      menu.byId.courses
        ? {
            ...menu.byId.courses,
            subItems: filterNull([
              {
                id: 'categories',
                label: (() => {
                  if (viewAsHamburger) return 'Temas';
                  // "Categorias" is used here only as a "fake" title
                  // over the knowledge_areas_menus
                  return 'Categorias';
                })(),

                subItems: menu.knowledge_areas_menus.slice(0, 26).concat({
                  id: 'all_knowledge_areas',
                  route: '/cursos/temas',
                  label: (
                    <RowMiddle className={'RowMiddle'}>
                      <span> Ver todas</span>
                      <ChevronRight />
                    </RowMiddle>
                  ),
                }),
              },

              (() => {
                const label =
                  user?.colaborador?.empresa?.corporate_university_name;
                const logo = user?.colaborador?.empresa?.logo;

                return label && logo
                  ? {
                      id: 'university',
                      label,
                      route: '/corporate_university/trilhas',
                    }
                  : null;
              })(),

              menu.byId.included_in_your_plan,

              ...menu.course_types_menus,
            ]),
          }
        : null,

      menu.byId.learning,

      menu.byId.included_in_your_plan,

      hasZoomLMS
        ? {
            id: 'lms_login',
            label: plan?.zoom_config?.label ?? 'Acessar LMS',
            onClick: async () => {
              try {
                const redirectUrl = await zoomLMSService.getRedirectUrl();
                if (redirectUrl) window.location.assign(redirectUrl);
              } catch (e) {
                console.error(e);
                alert(e.error?.data?.message || 'Falha ao logar');
              }
            },
          }
        : null,

      hasFeatureUniBrad
        ? {
            id: 'unibrad',
            label: 'UniBrad',
            onClick: async () => {
              window.location.assign(`${plan?.promo_landing_page.url}`)
            },
          }
        : null,

      hasCustomCategory
        ? {
            id: 'custom',
            route: '/cursos/custom-category',
            label: user?.colaborador?.empresa?.custom_category || '',
          }
        : null,
    ]);
  }, [menu, serverData, viewAsHamburger]);

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openMenus, setOpenMenus] = React.useState<Record<string, any>>({});

  const handleMenuOpen = (menuId, event) => {
    setOpenMenus((prevState) => ({
      ...prevState,
      [menuId]: event.currentTarget,
    }));
  };

  const handleMenuClose = (menuId: string) => {
    setOpenMenus((prevState) => ({
      ...prevState,
      [menuId]: null,
    }));
  };

  const closeAllMenus = () => {
    setOpenMenus({});
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const AccordionIcon = ({ open, selected }) => {
    const IconComponent = open ? ExpandLess : ExpandMore;
    const color = selected ? 'inherit' : 'primary';

    return <IconComponent color={color} />;
  };

  const container = WINDOW?.document.body;

  React.useEffect(() => {
    if (notDesktopSize) {
      closeAllMenus();
    }
  }, [notDesktopSize]);

  return (
    <Box>
      <StyledAppBar
        // @ts-ignore
        component="nav"
        elevation={0}
        position="relative"
      >
        <AdjustedBarContainer
          fixed
          maxWidth="xl"
          className={cx({ isMediumScreen })}
        >
          <Toolbar
            disableGutters
            sx={{ minHeight: (theme) => theme.spacing(7) }}
          >
            {/* mobile */}
            <Box sx={{ display: { md: 'none' } }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
              >
                <MenuIcon color="primary" />
              </IconButton>
            </Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                flexGrow: { xs: 1, md: 0 },
              }}
            >
              <Logo />
              <Box sx={{ display: { md: 'none' } }}>
                <MobileSearchInput currentUrl={currentUrl} />
              </Box>
            </Box>
            {/* desktop */}
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <Stack spacing={1} direction="row">
                {items.map((menu) => {
                  const menuElement = openMenus[menu.id];
                  const isOpen = !!menuElement;

                  const isActive = (() => {
                    // custom condition because the menu custos is already active
                    if (menu.id === 'included_in_your_plan') return;

                    if (menu.route?.length) {
                      return matchRoute(menu.route, currentUrl);
                    }

                    return menu.subItems?.some((el) => {
                      if (!el.route?.length) return;
                      return matchRoute(el.route, currentUrl);
                    });
                  })();

                  return (
                    <React.Fragment key={menu.id}>
                      <UnstyledLink
                        href={menu.route}
                        onClick={(event) => {
                          if (menu.route) {
                            if (event?.ctrlKey || event?.metaKey) {
                              return;
                            }
                          }

                          if (menu.subItems) {
                            handleMenuOpen(menu.id, event);
                          } else if (menu.route) {
                            closeAllMenus();
                          }

                          menu.onClick?.(event);
                        }}
                      >
                        <MainMenuLink
                          endIcon={
                            menu.subItems ? (
                              <AccordionIcon
                                open={isOpen}
                                selected={isActive}
                              />
                            ) : null
                          }
                          className={`menu-item-${menu.id}`}
                          variant={isActive ? 'contained' : 'text'}
                          disableElevation
                          loadingIndicator={
                            <CircularProgress size={20} color="primary" />
                          }
                          loadingPosition="center"
                        >
                          {menu.label}
                        </MainMenuLink>
                      </UnstyledLink>

                      {menu.subItems && (
                        <MainMenuDropdown
                          anchorEl={menuElement}
                          elevation={2}
                          onClose={() => handleMenuClose(menu.id)}
                          open={Boolean(menuElement)}
                          slotProps={{
                            paper: {
                              style: {
                                backgroundColor: 'transparent',
                                maxHeight: '100vh',
                                boxShadow: 'none',
                              },
                            },
                          }}
                        >
                          <Stack spacing={1}>
                            <MegaMenu
                              viewMode={'floatingMenu'}
                              alwaysOpenMenuId={'categories'}
                              menuItems={menu.subItems}
                            />
                          </Stack>
                        </MainMenuDropdown>
                      )}
                    </React.Fragment>
                  );
                })}
              </Stack>
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <SearchInput currentUrl={currentUrl} />
            </Box>
            <Box pl={{ xs: 2, md: 4 }}>
              <UserMenu
                handleMenuClick={(menu) => {
                  menu.onClick?.();
                  props.onMenuClick(menu);
                }}
                plan={plan}
                user={user}
                userMenuGroups={userMenuGroups}
              />
            </Box>
          </Toolbar>
        </AdjustedBarContainer>
      </StyledAppBar>
      <Box component="nav">
        <StyledDrawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <Box>
            <Box
              sx={(theme) => ({
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                height: theme.spacing(7),
              })}
            >
              <Box
                sx={{ position: 'absolute', left: (theme) => theme.spacing(1) }}
              >
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <Logo />
            </Box>

            {viewAsHamburger ? (
              <Stack spacing={1}>
                <MegaMenu viewMode={'sidebar'} menuItems={items} />
              </Stack>
            ) : null}
          </Box>
        </StyledDrawer>
      </Box>
    </Box>
  );
};

function matchRoute(route?: string, currentUrl?: string) {
  const a = '/' + RouteUtils.normalizePath(route ?? '/NOOP');
  const b = '/' + RouteUtils.normalizePath(currentUrl ?? '/NOOP');
  return RouteUtils.parseURL(a).pathname === RouteUtils.parseURL(b).pathname;
}

export default Appbar;
