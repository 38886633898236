import React, { useEffect, useState } from 'react';

import { Quiz } from '@/react/components/quiz';

import { GlobalProfileStateEmitter } from './global-profile-emitter';
import { DevMenuItem } from '@/react/components/dev/dev-menu/dev-menu.interfaces';
import { useDevMenu } from '@/react/components/dev/dev-menu';

// remove Modal Dependents when profile quiz is visible
const hotfixRemoveModalDependents = (
  <style
    dangerouslySetInnerHTML={{
      __html: `app-dependents {display: none!important}`,
    }}
  />
);

export function ProfileQuiz() {
  const data = GlobalProfileStateEmitter.useQuizState(
    ({ completed }) => {
      return { completed };
    }
  );

  useDevQuizDevMenu();
  const { instance: instance } = data;
  const { completed } = data.value || {};

  if (!instance || completed) {
    return null;
  }

  return (
    <>
      {hotfixRemoveModalDependents}
      <Quiz
        variation={'modal'}
        state={instance}
        onFinish={async () => {
          await GlobalProfileStateEmitter.startRecommendationsLongPolling();
        }}
      />
    </>
  );
}

function useDevQuizDevMenu() {
  const [, devMenu] = useDevMenu();

  useEffect(() => {
    devMenu.addItems([
      {
        id: 'reset_profile',
        render() {
          return <DevMenuItem />;
        },
      },
      {
        id: 'FillStateDevMenuItem',
        render() {
          return <FillStateDevMenuItem />;
        },
      },
    ]);
  }, [devMenu]);
}

// used for testing, shown in the floating dev menu (D-Burger)
function DevMenuItem() {
  const [loading, setLoading] = useState(false);

  return (
    <button
      onClick={async () => {
        setLoading(true);
        try {
          await GlobalProfileStateEmitter.load({
            forceResetProfile: true,
          });
        } catch (e) {
          alert(e.message);
        }
        setLoading(false);
      }}
    >
      {loading ? 'loading...' : '🔄 Limpar Perfil'}
    </button>
  );
}

// used for testing, shown in the floating dev menu (D-Burger)
function FillStateDevMenuItem() {
  const [loading, setLoading] = useState(false);

  const data = GlobalProfileStateEmitter.useQuizState(
    ({ isGalenaUser, completed }) => {
      return { isGalenaUser, completed };
    }
  );

  const { instance } = data;

  return (
    <button
      onClick={async () => {
        setLoading(true);

        const { default: nodeById } = await import('./_dev-mock-state.json');

        instance.update((current) => {
          // @ts-ignore
          current.nodeById = nodeById;
          current.activeNodeId = 'input_contact_email';
        });

        setLoading(false);
      }}
    >
      {loading ? 'loading...' : '😗Fill State'}
    </button>
  );
}
