import { RouterService } from './../../services/router.service';
import { Component, Input, OnInit } from '@angular/core';
import { CONST } from 'src/app/constants';
import { CursoAluno } from 'src/app/models/curso-aluno';
import { getAsset } from 'src/app/utils/helpers';

@Component({
  selector: 'app-card-cursos-concluidos',
  templateUrl: './card-cursos-concluidos.component.html',
  styleUrls: ['./card-cursos-concluidos.component.scss'],
})
export class CardCursosConcluidosComponent implements OnInit {
  @Input()
  cursos: CursoAluno[];

  defaultImageUrl = `${CONST.API_ENDPOINT}/contents/eduplay/course_placeholder.png`;

  getImagemCurso(curso: CursoAluno): string {
    return `${CONST.API_ENDPOINT}/contents/eduplay/${
      curso.curso_horario?.curso_detalhe?.curso?.imagem || 'default.png'
    }`;
  }

  constructor(private routerService: RouterService) {}

  getAsset = getAsset;

  ngOnInit(): void {}

  onAcessar(curso: CursoAluno) {
    this.routerService.navigate([
      'eduplay',
      curso.curso_horario.id,
      'conclusao_curso',
    ]);
  }

  cursoDetalhes(curso: CursoAluno) {
    this.routerService.navigate([
      'cursos',
      curso.curso_horario.curso_detalhe_id,
    ]);
  }

  onNavigate() {
    this.routerService.navigate([`colaborador/cursos/lista/livres`]);
  }

  onImageError(element) {
    element.target.src = this.defaultImageUrl;
  }

  getTranslation(key: string): string {
    return `SHARED.CARD_CURSOS_CONCLUIDOS.${key}`;
  }
}
