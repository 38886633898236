<div class="vp-modal align-itens-center justify-contents-center">
  <div class="container">
    <div
      (clickOutside)="onClickOutside($event)"
      class="card"
      [ngStyle]="cardStyle"
    >
      <div class="p-3 d-flex justify-content-between">
        <h5 class="secao flex-grow-1" [ngClass]="{ alerta: alerta }">
          {{ header }}
        </h5>
        <button
          *ngIf="closeButton"
          (click)="onCloseClick($event)"
          class="close-button"
        >
          <span class="material-icons-outlined notranslate">close</span>
        </button>
      </div>
      <ng-content></ng-content>
    </div>
  </div>
</div>
