import { Lesson, DbLesson } from './lesson.model';

export class DbTrailCourse {
  trail_id: string;
  course_id: string;
  created_at: string;
  updated_at: string;

  course?: DbCourse;
  deleted_at?: string;
}

export class DbCourse {
  id: string;
  name: string;
  image: string;
  duration: string;
  company_id: number;
  eduplay_free: boolean;
  has_files: boolean;
  id_backend: number;
  created_at: string;
  updated_at: string;

  exame_url?: string;
  lessons?: DbLesson[];
  courses_progresses?: DbCourseProgress[];
  deleted_at?: string;
}

export class DbCourseProgress {
  id: string;
  user_id: number;
  company_id: number;
  course_id: string;
  progress: number;
  is_completed: boolean;
  is_approved: boolean;
  nps_half: boolean;

  created_at: string;
  updated_at: string;

  current_lesson?: string;
  finished_time?: string;
  deleted_at?: string;
}

export class Course {
  id: string;
  name: string;
  image: string;
  progress: number;
  isCompleted: boolean;
  isApproved: boolean;
  hasFiles: boolean;
  exameUrl: string;
  backendId: number;
  npsHalf: boolean;

  currentLesson?: string;
  lessons?: Lesson[];

  static getFromDbTrailCourse(dbTrailCourse: DbTrailCourse): Course {
    return Course.getFromDbCourse(dbTrailCourse.course);
  }

  static getFromDbCourse(dbCourse: DbCourse): Course {
    const course = new Course();
    course.id = dbCourse.id;
    course.name = dbCourse.name;
    course.image = dbCourse.image;
    course.hasFiles = dbCourse.has_files;
    course.exameUrl = dbCourse.exame_url;
    course.backendId = dbCourse.id_backend;

    course.lessons = dbCourse.lessons && dbCourse.lessons.map(Lesson.getFromDb);

    const courseProgress =
      dbCourse.courses_progresses && dbCourse.courses_progresses[0];

    course.currentLesson = courseProgress?.current_lesson;
    course.progress = courseProgress?.progress || 0;
    course.isCompleted = courseProgress?.is_completed || false;
    course.isApproved = courseProgress?.is_approved || false;
    course.npsHalf = courseProgress?.nps_half || false;

    return course;
  }

  getCurrentLesson(): Lesson | undefined {
    if (this.currentLesson && this.lessons) {
      return this.lessons.find((lesson) => lesson.id === this.currentLesson);
    }
    return undefined;
  }
}
