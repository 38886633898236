<div class="page-container">
  <div class="form-container">
    <div class="form-wrapper">
      <div class="title">
        {{ getTranslation('title') }}
      </div>
      <div class="error" *ngIf="erros.campoEmBranco">
        {{ getTranslation('emptyFields') }}
      </div>

      <component-input
        [label]="getTranslation('currentPassword')"
        type="password"
        [(ngModel)]="formData.senha"
        [error]="erros.senhaIncorreta"
        (inputed)="limparErros()"
        [errorMessage]="getTranslation('errorIncorrect')"
      >
      </component-input>
      <component-input
        [label]="getTranslation('newPassword')"
        type="password"
        [(ngModel)]="formData.novaSenha"
        [error]="erros.senhaFraca || erros.mesmaSenha"
        (inputed)="limparErros()"
        [errorMessage]="erroSenhaMensagem()"
      >
      </component-input>
      <component-input
        [label]="getTranslation('confirmNewPassword')"
        type="password"
        [(ngModel)]="formData.confirmarSenha"
        [error]="erros.senhaDiferente"
        (inputed)="limparErros()"
        [errorMessage]="getTranslation('errorConfirmPassword')"
      >
      </component-input>
    </div>
    <component-botao
      class="save-btn mt-4"
      type="primary"
      [disabled]="submiting"
      (onClick)="onSalvar()"
      >{{ getTranslation('btnSave') }}</component-botao
    >
  </div>
</div>
