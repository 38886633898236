import { z } from 'zod';
import { createActivitySchema } from '@/react/data/idp/schemas';

type CreateActivityInterface = z.infer<typeof createActivitySchema>;

enum ActivityStatuses {
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
}

const ActivityStatusLabel = {
  [ActivityStatuses.PENDING]: 'A fazer',
  [ActivityStatuses.IN_PROGRESS]: 'Em andamento',
  [ActivityStatuses.COMPLETED]: 'Feito',
};

type ActivityResponseType = {
  id: string;
  plan_id: string;
  title: string;
  description: string;
  status: ActivityStatuses;
};

type ActivityType = Omit<ActivityResponseType, 'plan_id'> & {
  planId: string;
};

type ActivitiesResponseType = {
  activities: ActivityResponseType[];
};

export { ActivityStatuses, ActivityResponseType, ActivityType, ActivitiesResponseType, CreateActivityInterface, ActivityStatusLabel }
