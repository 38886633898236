import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { DatePickerComponent } from '@/components/date-picker/date-picker.component';
import { SelectOption } from '@/components/form/select/select.component';
import {
  Refund,
  RefundStatus,
  statusMap,
  statusOptions,
} from '@/models/refund.model';
import {
  MAX_ITEMS_LOADED,
  PAGE_LIMIT,
  RefundsService,
} from '@/services/api/refunds.service';
import { TranslationService } from '@/services/translation.service';
import { TablePage } from '@/shared/table-default/table-default.component';
import { CSVHandler } from '@/utils/csv.handler';
import { formatDate } from '@/utils/date-parser';
import { formatMoney } from '@/utils/money-parser';
import { TranslatedComponent } from '@/utils/translated.component';
import { RouterService } from '@/services/router.service';
import { ColaboradorRoutes } from '../../colaborador.routes';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent extends TranslatedComponent implements OnInit {
  translationKey = 'COLABORADOR.MY_REFUNDS.OVERVIEW';

  @ViewChild('datepicker', { static: true })
  datePicker: DatePickerComponent;

  statusOptions = statusOptions.slice();

  tablePages: TablePage[] = [
    {
      columns: [
        {
          label: this.getTranslation('tableDate'),
          getValue: (item: Refund) => formatDate(item.created_at),
          type: 'text',
        },
        {
          label: this.getTranslation('tableCourse'),
          getValue: (item: Refund) => item.course_name || 'Não informado',
          type: 'text',
        },
        {
          label: this.getTranslation('tableCourseAmount'),
          getValue: (item: Refund) => formatMoney(item.total_course_value),
          type: 'text',
        },
        {
          label: this.getTranslation('tableRefundAmount'),
          getValue: (item: Refund) => formatMoney(item.full_refund_amount),
          type: 'text',
        },
        {
          label: this.getTranslation('tableStatus'),
          getValue: (item: Refund) => `REFUND_STATUS.${statusMap[item.status]}`,
          type: 'text',
        },
      ],
    },
  ];

  statusFilter: SelectOption = {
    label: 'Todos',
    value: undefined,
  };

  dateFilter: { from: string; to: string };

  refunds: Refund[];
  budget = 0;

  emptyFilters = true;

  isLoading = false;
  shouldLoadDelayed = false;

  constructor(
    private refundService: RefundsService,
    private ngbDateFormatter: NgbDateParserFormatter,
    private translate: TranslationService,
    private csvHandler: CSVHandler,
    private routerService: RouterService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadRefunds();
    this.loadBudget();
  }

  async loadBudget() {
    const budget = await this.refundService.loadBudget();
    this.budget = budget;
  }

  async loadRefunds() {
    this.isLoading = true;
    this.shouldLoadDelayed = false;
    this.refunds = [];
    await this.getPaginatedRefunds();
    this.isLoading = false;
    if (this.shouldLoadDelayed) {
      this.loadRefunds();
    }
  }

  async getPaginatedRefunds(page = 1) {
    const userData = await this.refundService.listUserRefunds({
      page,
      status: RefundStatus[this.statusFilter.value],
      dateFilter: this.dateFilter,
    });

    const paginatedRefunds = userData.rows;

    if (this.shouldLoadDelayed) {
      return [];
    }
    this.refunds = this.refunds.concat(paginatedRefunds);
    if (
      paginatedRefunds.length === PAGE_LIMIT &&
      this.refunds.length < MAX_ITEMS_LOADED
    ) {
      await this.getPaginatedRefunds(page + 1);
    }
  }

  onStatusSelected(option: SelectOption | SelectOption[]) {
    this.emptyFilters = false;
    this.statusFilter = option as SelectOption;

    if (this.isLoading) {
      this.shouldLoadDelayed = true;
      return;
    }

    this.loadRefunds();
  }

  clearFilters() {
    this.emptyFilters = true;
    this.dateFilter = undefined;
    this.statusFilter = this.statusOptions[0];
    this.datePicker.clearDates();
    this.loadRefunds();
  }

  onExportar() {
    const collumns = [
      {
        header: this.translate.getTranslation(this.getTranslation('tableDate')),
        getValue: (item: Refund) => formatDate(item.created_at),
      },
      {
        header: this.translate.getTranslation(
          this.getTranslation('tableCourse')
        ),
        getValue: (item: Refund) => item.course_name.split('#').join('N'),
      },
      {
        header: this.translate.getTranslation(
          this.getTranslation('tableCourseAmount')
        ),
        getValue: (item: Refund) =>
          formatMoney(item.total_course_value.toString()),
      },
      {
        header: this.translate.getTranslation(
          this.getTranslation('tableRefundAmount')
        ),
        getValue: (item: Refund) =>
          formatMoney(item.full_refund_amount.toString()),
      },
      {
        header: this.translate.getTranslation(
          this.getTranslation('tableStatus')
        ),
        getValue: (item: Refund) =>
          this.translate.getTranslation(
            `REFUND_STATUS.${statusMap[item.status]}`
          ),
      },
    ];

    this.csvHandler.generate(this.refunds, {
      collumns,
    });
  }

  onDateSelected(date: { fromDate: NgbDate; toDate: NgbDate }) {
    this.emptyFilters = false;
    this.dateFilter = {
      from: this.ngbDateFormatter.format(date.fromDate),
      to: this.ngbDateFormatter.format(date.toDate),
    };

    if (this.isLoading) {
      this.shouldLoadDelayed = true;
      return;
    }

    this.loadRefunds();
  }

  goToForm() {
    this.routerService.navigate([ColaboradorRoutes.REFUND_FORM]);
  }
}
