import { IdpClient } from '@/react/data/idp/idp-client';
import { Button, Grid, Typography } from '@mui/material';
import React, { useState, useCallback, useEffect } from 'react';
import { ListPlans } from '@/react/components/idp/home/components/listPlans';
import Divider from '@mui/material/Divider';
import { Plan, PlanStatuses } from '@/react/data/idp/interfaces';
import * as Sentry from '@sentry/browser';

export const PlansHome = () => {
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [plans, setPlans] = useState<Plan[]>([]);

  const createNewPlan = async () => {
    setDisabledButton(true);
    try {
      const plan = await IdpClient.createPlan({ title: null });
      window.location.href = `/pdi/planos/${plan.id}`;
    } catch (error) {
      Sentry.captureException(error);
      setDisabledButton(false);
    }
  };

  const checkForDraft = useCallback(async () => {
    const data = await IdpClient.plans();
    setPlans(data);
    const hasDraft = data.some((plan) => plan.status === PlanStatuses.DRAFT);
    setDisabledButton(hasDraft);
  }, []);



  useEffect(() => {
    checkForDraft();
  }, []);

  return (
    <Grid container spacing={2} padding={3}>
      <Grid xs={12} item>
        <Typography color="primary.200" variant="h5" fontWeight={600}>
          Plano de desenvolvimento
        </Typography>
      </Grid>
      <Grid xs={12} item>
        <Typography color="primary.200" variant="body3">
          Acelere seu crescimento profissional com um plano de desenvolvimento
          personalizado para alcançar seus objetivos. Crie o seu plano ou use as
          recomendações da nossa inteligência artificial.{' '}
          <a>Veja como funciona</a>
        </Typography>
      </Grid>
      <Grid xs={12} item>
        <Button
          variant="contained"
          fullWidth
          onClick={createNewPlan}
          disabled={disabledButton}
        >
          Novo plano
        </Button>
      </Grid>

      <Grid xs={12} item>
        <Divider sx={{ opacity: 0.6 }} flexItem />
      </Grid>

      <ListPlans plans={plans} />
    </Grid>
  );
};
