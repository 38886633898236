import { Component, EventEmitter, Output } from '@angular/core';

import { getSvg } from 'src/app/utils/helpers';

@Component({
  selector: 'app-nps-eduplay-half',
  templateUrl: './nps-eduplay-half.component.html',
  styleUrls: ['./nps-eduplay-half.component.scss'],
})
export class NpsEduplayHalfComponent {
  @Output()
  onClose = new EventEmitter<void>();

  constructor() {}

  getSvg = getSvg;

  closeModal() {
    this.onClose.emit();
  }

  openNpsForm() {
    window.open(
      'https://docs.google.com/forms/d/1nfWb1PN52qOqanGG3TPfW69GD0n2QexmttXcUIHinkA/edit'
    );
    this.closeModal();
  }
}
