import * as React from 'react';

import { FiltersContext } from '@/react/components/filters/state/context';
import { FiltersSchema } from '@/react/components/filters/state/interfaces';
import { getFilterValuesFromUrl } from '@/react/components/filters/state';

import { ProductPageContext } from './state/context';
import { ProductPage } from './page.component';
import { GtmContext } from '../../state/context';

export const ProductPageWrapper = ({ courseId }: { courseId: number }) => {
  const [filtersSchema, setFiltersSchema] = React.useState<FiltersSchema>([]);
  const initialValues = getFilterValuesFromUrl();

  return (
    <FiltersContext
      schema={filtersSchema}
      initialValues={initialValues}
      setSchema={setFiltersSchema}
    >
      <GtmContext>
        <ProductPageContext courseId={courseId}>
          <ProductPage courseId={courseId} />
        </ProductPageContext>
      </GtmContext>
    </FiltersContext>
  );
};
