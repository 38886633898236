import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { QuestionWrapper } from './question-wrapper';
import Stack from '@mui/material/Stack';

type StatesOptions = string[];
type CitiesByState = { [K: string]: string[] };

const requiredFieldMessages = {
  UF: 'Selecione um estado',
  city: 'Selecione uma cidade',
};

export function QuestionTypeAddress() {
  const [stateDropdownOpen, setStateDropdownOpen] = React.useState(false);
  const [cityDropdownOpen, setCityDropdownOpen] = React.useState(false);
  const [{ statesOptions, citiesByState }, setStatesData] = React.useState<{
    statesOptions: StatesOptions;
    citiesByState: CitiesByState;
  }>({ statesOptions: [], citiesByState: {} });

  const loading =
    (stateDropdownOpen || cityDropdownOpen) && statesOptions.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      import('@/data/estados.json').then((states) => {
        if (active) {
          const statesOptions = states.default.map((state) => state.sigla);
          const citiesByState: CitiesByState = {};
          states.default.forEach(
            (el) => (citiesByState[el.sigla] = el.cidades)
          );
          setStatesData({ statesOptions, citiesByState });
        }
      });
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  return (
    <QuestionWrapper
      kind="address"
      render={({
        errors,
        required,
        setErrors,
        setValue,
        touched,
        value: localValue,
      }) => {
        const [UF = '', city = ''] = localValue;
        const [UFError, cityError] = errors;

        React.useEffect(() => {
          if (!touched) {
            return;
          }

          if (!required) {
            if (UF && !city) {
              setErrors([null, requiredFieldMessages['city']]);
            } else {
              setErrors([]);
            }
          }

          if (required) {
            const UFError = (!UF && requiredFieldMessages['UF']) || '';
            const cityError = (!city && requiredFieldMessages['city']) || '';

            if (!UFError && !cityError) {
              setErrors([]);
            } else {
              setErrors([UFError, cityError]);
            }
          }
        }, [UF, city]);

        return (
          <Stack width={'100%'} gap={2}>
            <Autocomplete
              clearOnEscape
              fullWidth
              isOptionEqualToValue={(a, b) => a === b}
              value={UF || null}
              onChange={(_, option) => {
                setValue([option, '']);
              }}
              onClose={() => {
                setStateDropdownOpen(false);
              }}
              onOpen={() => {
                setStateDropdownOpen(true);
              }}
              open={stateDropdownOpen}
              options={statesOptions}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    error={!!UFError}
                    helperText={UFError}
                    label="Estado"
                  />
                );
              }}
            />

            {UF && (
              <Autocomplete
                clearOnEscape
                fullWidth
                isOptionEqualToValue={(a, b) => a === b}
                value={city || null}
                onChange={(_, option) => {
                  setValue([UF, option]);
                }}
                onClose={() => {
                  setCityDropdownOpen(false);
                }}
                onOpen={() => {
                  setCityDropdownOpen(true);
                }}
                open={cityDropdownOpen}
                options={citiesByState[UF] || []}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      error={!!cityError}
                      helperText={cityError}
                      label="Cidade"
                    />
                  );
                }}
              />
            )}
          </Stack>
        );
      }}
    />
  );
}
