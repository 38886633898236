import axios from 'axios';

import CONST from '@/constants';
import { withAuthorization } from '@/react/config/axios';

import { InstitutionsSearchResult } from './interfaces';

export class InstitutionsClient {
  static BASE_URL = CONST.API_ENDPOINT;

  constructor() {}

  static client = withAuthorization(
    axios.create({
      baseURL: InstitutionsClient.BASE_URL,
    })
  );

  static getInstitutions = async (
    options: {
      signal?: AbortSignal;
    } = {}
  ): Promise<InstitutionsSearchResult> => {
    const { signal } = options;
    const url = `/institutions/search`;
    const res = await InstitutionsClient.client.get(url, { signal });

    return res.data;
  };
}
