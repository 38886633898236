import { Component, OnInit } from '@angular/core';
import type { ComponentProps } from 'react';

import { User } from 'src/app/models/user.model';
import { LayoutService } from 'src/app/services/layout.service';
import { UsersService } from 'src/app/services/users.service';

import { default as ReactCursosHome } from '@/react/components/cursos/home.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  ReactCursosHome = ReactCursosHome;
  reactCursosHomeProps: ComponentProps<typeof ReactCursosHome> = {
    user: null,
  };

  user: User;

  onboarding: boolean;
  nameModal: boolean;

  constructor(
    private userService: UsersService,
    private layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.user = this.userService.user;

    if (this.user.nome === this.user.email) {
      this.nameModal = true;
    }

    this.reactCursosHomeProps.user = this.user;

    this.layoutService.updateBreadCrumb([
      {
        label: 'Home',
      },
    ]);
  }

  onCloseUsersName() {
    this.nameModal = false;
  }
}
