export const formatOfferFiltersParams = ({
  courseId,
  hub,
  location,
  period,
}: {
  courseId?: number;
  hub?: string[];
  location?: string[];
  period?: string[];
}) => {
  let useOfferFiltersRequest = {} as any;

  if (courseId) useOfferFiltersRequest.id = courseId;
  if (hub) useOfferFiltersRequest.hub_id = +hub.join();
  if (location) {
    const [state, city] = location;

    if (state) useOfferFiltersRequest.state = state;
    if (city) useOfferFiltersRequest.city = city;
  }
  if (period) useOfferFiltersRequest.period = period.join();

  const queryParams = new URLSearchParams(useOfferFiltersRequest).toString();

  return `?${queryParams}`;
};
