<app-refund-navigation
  *ngIf="!rejectWarning && !completePage"
  menu="pendentes"
></app-refund-navigation>

<app-table-default
  *ngIf="!rejectWarning && !completePage && !isLoading"
  [tablePages]="tablePages"
  [data]="pendingRefunds"
  [salvarButton]="salvarButton"
  (save)="onSave($event)"
  maxLength="1020"
></app-table-default>

<component-skeleton-card *ngIf="isLoading"></component-skeleton-card>

<ng-container *ngIf="rejectWarning">
  <div class="container">
    <div class="row">
      <div class="col-12 d-flex align-items-center flex-column">
        <div class="alert-container">
          <p class="atencao">
            {{ this.getTranslation('atencao') | translate }}
          </p>
          <p class="subtitle">
            {{ this.getTranslation('voceSelecionou') | translate }}
          </p>

          <div class="d-flex justify-content-center">
            <component-botao
              class="button"
              type="secondary"
              (onClick)="rejectWarning = false"
              >{{
                this.getTranslation('btnCancelar') | translate
              }}</component-botao
            >
            <component-botao type="warning" (onClick)="onConfirmReject()">{{
              this.getTranslation('btnSalvar') | translate
            }}</component-botao>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="completePage">
  <div class="container">
    <div class="row">
      <div class="col-12 d-flex align-items-center flex-column">
        <div class="success-container">
          <p>
            {{ this.getTranslation('operacaoRealizadaSucesso') | translate }}
          </p>
        </div>

        <img class="illustration" [src]="getAsset('approved.png')" />

        <div *ngIf="pendingRefunds.length > 0">
          <p class="importante">
            {{ this.getTranslation('importante') | translate }} <br />
            <strong>{{
              this.getTranslation('solicitacoesPendentes') | translate
            }}</strong
            >.
          </p>
          <p (click)="completePage = false" class="pendentes-link">
            {{ this.getTranslation('verSolicitacoesPendentes') | translate }}
          </p>
        </div>

        <div *ngIf="pendingRefunds.length === 0">
          <p class="importante">
            {{ this.getTranslation('voceNaoTem') | translate }} <br />
            <strong>{{
              this.getTranslation('solicitacoesPendentes') | translate
            }}</strong
            >.
          </p>
          <p (click)="onGoToActive()" class="pendentes-link">
            {{ this.getTranslation('verSolicitacoesAprovadas') | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>
</ng-container>
