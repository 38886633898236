import * as React from 'react';
import { styled } from '@mui/material/styles';
import cx from 'clsx';
import {
  components,
  useDevMenu
} from './dev-menu.state';
import { createPortal } from 'react-dom';
import { useBootstrapData } from '@/react/data/bootstrap';
import { useEffect } from 'react';
import { IS_PRODUCTION_HOST } from '@/utils/ENV';

const DevMenuWrapper = styled('div')`
  position: fixed;
  z-index: 9999999;
  right: 0;
  bottom: 0;
  @media screen and (min-width: 768px) {
    right: 3px;
    bottom: 5px;
  }
`;

const Menu = styled('div')`
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto;
  flex-basis: 100%;
  max-width: 100%;
  transform: translate3d(0, 0, 0);
  box-shadow: #7d7d7d1f -2px 1px 12px 0;
  border: solid 0.5px #ececec;
  background: white;
  transition: 50ms ease-in-out;

  &.isOpen {
    width: min(98vw, 410px);
    min-height: 500px;
    padding: 0 10px;
    overflow: hidden;
    overflow-y: auto;
    opacity: 1;
    border-radius: 32px;
  }

  &:not(.isOpen) {
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 767px) {
      transform: scale(0.5);
      transform-origin: right bottom;
    }

    &:not(:hover) {
      background: transparent;
      border-color: rgba(236, 236, 236, 0);
    }
  }

  .icon-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    .label {
      font-size: 10px;
      width: 100%;
      text-align: center;
      margin-top: -10px;
    }
  }
`;

const Item = styled('a')`
  width: 100%;
  height: 50px;
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto;
  flex-basis: 100%;
  max-width: 100%;
  cursor: pointer;
  padding: 5px;
  text-align: center;
  justify-content: center;
  align-items: center;
  background: #f0f0f0;
  border-radius: 6px;
  border: solid 2px #d4d4d4;
  transition: ease-in-out 50ms;
  text-decoration: none;

  &:hover {
    background: #f0f0f0;
    text-decoration: none;
  }

  &:active {
    transform: scale(0.997);
    transform-origin: center;
  }

  > button {
    background: transparent;
    border: none;
    color: inherit;
  }

  > * {
    width: 100%;
  }
`;

const ItemsWrapper = styled('div')`
  min-width: 300px;
  min-height: 500px;
  border-radius: 16px;
  padding: 10px;
  gap: 18px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto;
  flex-basis: 100%;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
`;

const Header = styled('header')`
  width: 100%;
  max-width: 100%;
  margin: 5px 10px;
  height: 50px;
  border-bottom: solid 0.5px #d9d9d9;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  .title {
    color: black;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    width: 100%;
  }
`;

let timeout: ReturnType<typeof setTimeout> = null;

export function BaseDevMenu() {
  const [state, actions] = useDevMenu();
  const { items, isOpen } = state;

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <DevMenuWrapper className={cx('DevMenu', { isOpen })}>
      <Menu
        className={cx({ isOpen })}
        onMouseEnter={() => {
          if (isOpen) return;
          actions.open();
        }}
        onMouseMove={() => {
          clearTimeout(timeout);
        }}
        onMouseLeave={() => {
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            if (!actions.current().isOpen) return;
            actions.close();
          }, 500);
        }}
      >
        {(() => {
          if (isOpen) {
            return (
              <>
                <Header>
                  <span className={'title'}>🍔 D-Burger</span>
                </Header>

                <ItemsWrapper>
                  <>
                    {items.map((item, idx) => {
                      return (
                        <Item key={item.id + idx}>
                          {components[item.id](state, actions)}
                        </Item>
                      );
                    })}
                  </>
                </ItemsWrapper>
              </>
            );
          }

          return (
            <span
              className={'icon-wrapper'}
              onMouseMove={() => {
                clearTimeout(timeout);
              }}
            >
              <span className={'icon'}> 🍔</span>
              {/*<span className={'label'}>D-Burger</span>*/}
            </span>
          );
        })()}
      </Menu>
    </DevMenuWrapper>
  );
}

export function DevMenu() {
  const bootstrapData = useBootstrapData();
  if (!bootstrapData?.isGalenaUser || IS_PRODUCTION_HOST) return null;
  return createPortal(<BaseDevMenu />, document.body);
}
