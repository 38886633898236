import * as React from 'react';

import { State } from './interfaces';

export const Context = React.createContext(null);

export function ProductPageContext({ courseId, children }) {
  const initialState = {
    course: undefined,
    courseId,
    institution: undefined,
    isSingleOffer: undefined,
    loading: [],
    offer: undefined,
    subsidy: undefined,
  } as State;

  const [state, setState] = React.useState<State>(initialState);

  return (
    <Context.Provider value={[state, setState]}>{children}</Context.Provider>
  );
}
