import * as React from 'react';
import { styled } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';

import { useFilters } from '@/react/components/filters/state';
import { Typography } from '@mui/material';

const Styled = {
  TextField: styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.neutral[50],
    },
  })),
};

export const FilterSelect = ({
  disabled,
  id,
  multiple,
  options,
  placeholder,
  title,
  type,
}: {
  disabled?: boolean;
  id: string;
  multiple: boolean;
  options: { label: string; value: string }[];
  placeholder: string;
  title?: string;
  type: string;
}) => {
  const { actions, state } = useFilters();
  const fieldState = state[id];

  const resetFilters = (id: string) => {
    if (id === 'hub') {
      actions.reset(['period']);
    }
  }

  const handleChange = (_, value, reason) => {
    if (reason === 'clear') {
      actions.clearFieldValues(id);
    } else {
      const newValues = value instanceof Array ? value : [value];

      const values = newValues.map(({ value }) => value);
      actions.setValueById(id, values);
    }

    actions.setPage(1);
    resetFilters(id)
  };

  const optionsById = React.useMemo(() => {
    return options.reduce((acc, option) => {
      acc[option.value] = option;
      return acc;
    }, {});
  }, [options]);

  const selectedValues = multiple
    ? fieldState.values.map((value) => optionsById[value])
    : optionsById[fieldState.values[0]];

  return (
    <FormControl data-filter-type={type}>
      {title && <FormLabel>{title}</FormLabel>}
      <Autocomplete
        disabled={disabled}
        multiple={multiple}
        id={id}
        options={options}
        disableCloseOnSelect={multiple}
        fullWidth
        onChange={handleChange}
        getOptionKey={(option) => option.value}
        renderOption={(props, option, { selected }) => {
          return (
            <ListItem
              {...props}
              sx={(theme) => ({
                '&&': {
                  alignItems: 'start',
                  p: multiple
                    ? `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(
                        1
                      )} 0`
                    : `${theme.spacing(1)} ${theme.spacing(2)}`,
                },
              })}
            >
              {multiple && <Checkbox checked={selected} size="small" />}
              <Typography variant="body3" sx={{ py: 1 }}>
                {option.label}
              </Typography>
            </ListItem>
          );
        }}
        renderInput={(params) => (
          <Styled.TextField {...params} placeholder={placeholder} />
        )}
        size="small"
        value={selectedValues || null}
      />
    </FormControl>
  );
};
