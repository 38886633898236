import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { getSvg } from '@/react/utils/assets';

export const EmptyList = () => {
  return (
    <Grid
      container
      justifyContent="center"
      spacing={3}
      xs={12}
      sx={({ palette }) => ({
        backgroundColor: `${palette.neutral[50]}`,
        borderRadius: '8px',
        border: `1px solid ${palette.neutral[300]}`,
        margin: '20px auto',
        mb: {
          xs: 2,
        },
      })}
    >
      <Grid item xs={12} textAlign="center">
        <img src={getSvg('empty_refund_list.svg')} role="img" />
      </Grid>
      <Grid item xs={12} margin={'0 auto'} paddingBottom={2}>
        <Typography
          color="primary.200"
          variant={'h6'}
          textAlign="center"
          fontWeight={500}
        >
          Você ainda não possui solicitações
        </Typography>
        <Typography
          color="neutral.800"
          variant={'body3'}
          textAlign="center"
          sx={{ letterSpacing: '0.2px', marginTop: 1 }}
        >
          Quando fizer solicitações, elas aparecerão aqui.
        </Typography>
      </Grid>
    </Grid>
  );
};
