import React from 'react';
import { useGtm } from './state/useGtm';
import CardLink from './card-link.component';

const CourseCardLink = (props) => {
  const gtm = useGtm(); 
  
  return <CardLink {...props} onClick={() => gtm.trackSelectCourseEvent(props.course)}/>
}

export default CourseCardLink;
