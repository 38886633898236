import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';
import { useScreenSizes } from '@/react/utils/useScreenSizes';
import { useRefundRequest } from '../refund-request/state/useRefundRequest';
import { SubsidyClient } from '@/react/data/subsidy/subsidy-client';
import CancelRefundConfirmationDialog from '../refund-request/cancel-refund-confirmation-dialog.component';

const extractRefundRequestData = (data) => {
  return {
    resource_approval_uuid: data.uuid,
    refund_request: !!data?.refund_request || null,
    status: data.status,
    refund_request_uuid: data?.refund_request?.uuid,
  };
};

const CustomizedMenus = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { isSmallScreen } = useScreenSizes();
  const [showDialog, setShowDialog] = React.useState(false);

  const { resourceApproval } = useRefundRequest();

  if (!resourceApproval) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancelDialogClose = () => {
    setShowDialog(false);
  }

  const showConfirmationDialog = () => {
    setShowDialog(true);
  }

  const handleCancelRequest = async () => {
    const data = extractRefundRequestData(resourceApproval);

    let path = null;
    try {
      if (data.status === 'refund_request_pending_approval') {
        path = await SubsidyClient.cancelRefundRequest(
          data.refund_request_uuid
        );
      }

      if (
        data.status === 'resource_approval_approved' &&
        data.refund_request !== true
      ) {
        path = await SubsidyClient.cancelResourceApproval(
          data.resource_approval_uuid
        );
      }

      handleClose();
      window.location.assign('/meus-reembolsos');
    } catch (error) {
      console.log('ERROR PATH', error);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '10px',
        marginRight: '10px',
      }}
    >
      {isSmallScreen ? (
        <IconButton onClick={handleClick} size="large">
          <MoreVertIcon />
        </IconButton>
      ) : (
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Opções
        </Button>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'customized-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={showConfirmationDialog}>Cancelar solicitação</MenuItem>
        <CancelRefundConfirmationDialog
          open={showDialog}
          onClose={handleCancelDialogClose}
          onConfirm={handleCancelRequest}
        />
      </Menu>
    </div>
  );
};

export default CustomizedMenus;
