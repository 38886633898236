import { ActivityResponseType, ActivityStatuses, ActivityType } from '@/react/data/idp/activities/interfaces';

export default class Activity implements ActivityType {
  id: string;
  planId: string;
  title: string;
  description: string;
  status: ActivityStatuses;

  constructor(args: ActivityType) {
    const { id, planId, title, description, status } = args;

    this.id = id;
    this.planId = planId;
    this.title = title;
    this.description = description;
    this.status = status;
  }

  static fromServerPayload(payload: ActivityResponseType): Activity {
    return new Activity({
      id: payload.id,
      planId: payload.plan_id,
      title: payload.title,
      description: payload.description,
      status: payload.status,
    });
  }
}
