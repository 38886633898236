import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslatedComponent } from 'src/app/utils/translated.component';

import { CourseCard } from '@/models-new/course_card.model';
import { CursoHorario } from '@/models/curso-horario.model';
import { User } from '@/models/user.model';
import { RouterService } from '@/services/router.service';
import { UsersService } from '@/services/users.service';
import { priceDisplay, getDiscountDisplay } from '@/utils/course-card';
@Component({
  selector: 'app-curso-card',
  templateUrl: './curso-card.component.html',
  styleUrls: ['./curso-card.component.scss'],
})
export class CursoCardComponent extends TranslatedComponent implements OnInit {
  translationKey: string = 'SHARED.CURSO_CARD';

  @Input()
  curso: CursoHorario;

  courseCard: CourseCard;

  @Input()
  eduplayPremium: boolean;

  @Input()
  isClickable = true;

  @Input()
  detailsButton = true;

  @Output()
  onClick = new EventEmitter();

  cidade: boolean;

  descontoEspecifico: number;
  leroyUser: boolean;

  user: User;

  constructor(
    private userService: UsersService,
    private routerService: RouterService
  ) {
    super();
  }

  ngOnInit(): void {
    this.user = this.userService.user;
    this.courseCard = CourseCard.getCourseFromDb(
      this.curso,
      this.userService.user.isLeroy()
    );
  }

  cursoDetalhes(): void {
    this.routerService.navigate(['cursos', this.curso.curso_detalhe_id]);
  }

  onCardClick() {
    if (this.isClickable) {
      this.onButtonClick();
    }
  }

  onButtonClick() {
    if (this.detailsButton) {
      this.cursoDetalhes();
    } else {
      this.onClick.emit();
    }
  }

  // TODO move this to feature
  checkEduplayPrice() {
    return this.user.isPremium() &&
      this.courseCard.isEduplay() &&
      !this.courseCard.marketable
      ? 0
      : this.courseCard.getFullPrice();
  }

  getPriceOrCourseFree() {
    return priceDisplay(
      this.checkEduplayPrice(),
      this.courseCard.getFormattedInstallmentPrice()
    );
  }

  getDiscount(): number {
    return getDiscountDisplay(
      this.checkEduplayPrice(),
      this.courseCard.getDiscount(),
      this.courseCard.priceUponRequest
    );
  }
}
