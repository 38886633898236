import { Component, OnInit } from '@angular/core';
import { UsersService } from '@/services/users.service';
import ReactDashboardComponent from '@/react/components/dashboard';
import type { ComponentProps } from 'react';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
  ReactDashboardComponent = ReactDashboardComponent;
  props: ComponentProps<typeof ReactDashboardComponent> = {
    companyUuid: null,
  };

  constructor(private userService: UsersService) {}

  ngOnInit(): void {
    this.props.companyUuid = this.userService.user.empresa().uuid;
  }
}
