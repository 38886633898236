import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { faker } from '@faker-js/faker/locale/pt_BR';

import { InlineEditTextField } from '@/react/components/form/inline-edit-textfield.component';
import { InlineEditInput } from '@/react/components/form/inline-edit-input.component';
import { useNotification } from '../notification';

const simulatedAsyncRequest = async (
  value: string,
  type: 'success' | 'error'
): Promise<string> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (type === 'success') {
        resolve(`Saved ${value}`);
      } else {
        reject(`Error saving ${value}`);
      }
    }, 1300);
  });
};

const Components = () => {
  const defaultValues = {
    normal1: faker.lorem.words(3),
    normal2: faker.lorem.words(3),
    editableTextfield1: faker.lorem.words(3),
    editableTextfield2: faker.lorem.words(3),
    editableTextfield3: faker.lorem.words(3),
    editableTextfield4: faker.lorem.sentences(4),
    editableInput1: `h1: ${faker.lorem.words(3)}`,
    editableInput2: `h2: ${faker.lorem.words(3)}`,
    editableInput3: `h3: ${faker.lorem.words(3)}`,
    editableInput4: `h4: ${faker.lorem.words(3)}`,
    editableInput5: `h5: ${faker.lorem.words(3)}`,
    editableInput6: `h6: ${faker.lorem.words(3)}`,
    editableInput7: `unstyled: ${faker.lorem.words(3)}`,
  };

  const mainValidationSchema = z.object({
    normal1: z.string().min(1, 'Valor obrigatório'),
    normal2: z.string().min(1, 'Valor obrigatório'),
    editableTextfield1: z.string().min(1, 'Valor obrigatório'),
    editableTextfield2: z.string().min(1, 'Valor obrigatório'),
    editableTextfield3: z.string().min(1, 'Valor obrigatório'),
    editableTextfield4: z.string().min(1, 'Valor obrigatório'),
    editableInput1: z.string().min(1, 'Valor obrigatório'),
    editableInput2: z.string().min(1, 'Valor obrigatório'),
    editableInput3: z.string().min(1, 'Valor obrigatório'),
    editableInput4: z.string().min(1, 'Valor obrigatório'),
    editableInput5: z.string().min(1, 'Valor obrigatório'),
    editableInput6: z.string().min(1, 'Valor obrigatório'),
    editableInput7: z.string().min(1, 'Valor obrigatório'),
  });

  const { toast } = useNotification();
  const { control, handleSubmit, watch } = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(mainValidationSchema),
  });

  const handleErrormessage = (error) => {
    return error ? error.message : '';
  };

  const handleNext = (data) => {
    const formData = { ...data };
    Object.keys(formData).forEach((key) => {
      return formData[key] === undefined && delete formData[key];
    });

    const payload = {
      ...defaultValues,
      ...formData,
    };

    window.alert(JSON.stringify(payload, null, 2));
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleNext)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">Editable Typography</Typography>
            <Divider sx={{ mb: 2 }} />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="editableInput1"
              render={({ field, fieldState: { error } }) => {
                const { onChange } = field;
                return (
                  <FormControl error={!!error} fullWidth>
                    <Stack spacing={1}>
                      <Typography
                        {...field}
                        component={InlineEditInput}
                        fullWidth
                        onAccept={async (value) => {
                          toast({
                            content: `Saving ${value}...`,
                            type: 'info',
                          });

                          const response = await simulatedAsyncRequest(
                            value,
                            'success'
                          );

                          onChange(value);
                          toast({
                            content: response,
                            type: 'success',
                          });
                        }}
                        onCancel={() => {
                          toast({
                            content: `Cancelled`,
                            type: 'info',
                          });
                        }}
                        variant="h1"
                      />
                    </Stack>
                  </FormControl>
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="editableInput2"
              render={({ field, fieldState: { error } }) => {
                const { onChange } = field;
                return (
                  <FormControl error={!!error} fullWidth>
                    <Stack spacing={1}>
                      <Typography
                        {...field}
                        component={InlineEditInput}
                        fullWidth
                        onAccept={async (value) => {
                          toast({
                            content: `Saving ${value}...`,
                            type: 'info',
                          });

                          const response = await simulatedAsyncRequest(
                            value,
                            'success'
                          );

                          onChange(value);
                          toast({
                            content: response,
                            type: 'success',
                          });
                        }}
                        onCancel={() => {
                          toast({
                            content: `Cancelled`,
                            type: 'info',
                          });
                        }}
                        variant="h2"
                      />
                    </Stack>
                  </FormControl>
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="editableInput3"
              render={({ field, fieldState: { error } }) => {
                const { onChange } = field;
                return (
                  <FormControl error={!!error} fullWidth>
                    <Stack spacing={1}>
                      <Typography
                        {...field}
                        component={InlineEditInput}
                        fullWidth
                        onAccept={async (value) => {
                          toast({
                            content: `Saving ${value}...`,
                            type: 'info',
                          });

                          const response = await simulatedAsyncRequest(
                            value,
                            'success'
                          );

                          onChange(value);
                          toast({
                            content: response,
                            type: 'success',
                          });
                        }}
                        onCancel={() => {
                          toast({
                            content: `Cancelled`,
                            type: 'info',
                          });
                        }}
                        variant="h3"
                      />
                    </Stack>
                  </FormControl>
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="editableInput4"
              render={({ field, fieldState: { error } }) => {
                const { onChange } = field;
                return (
                  <FormControl error={!!error} fullWidth>
                    <Stack spacing={1}>
                      <Typography
                        {...field}
                        component={InlineEditInput}
                        fullWidth
                        onAccept={async (value) => {
                          toast({
                            content: `Saving ${value}...`,
                            type: 'info',
                          });

                          const response = await simulatedAsyncRequest(
                            value,
                            'success'
                          );

                          onChange(value);
                          toast({
                            content: response,
                            type: 'success',
                          });
                        }}
                        onCancel={() => {
                          toast({
                            content: `Cancelled`,
                            type: 'info',
                          });
                        }}
                        variant="h4"
                      />
                    </Stack>
                  </FormControl>
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="editableInput5"
              render={({ field, fieldState: { error } }) => {
                const { onChange } = field;
                return (
                  <FormControl error={!!error} fullWidth>
                    <Stack spacing={1}>
                      <Typography
                        {...field}
                        component={InlineEditInput}
                        fullWidth
                        onAccept={async (value) => {
                          toast({
                            content: `Saving ${value}...`,
                            type: 'info',
                          });

                          const response = await simulatedAsyncRequest(
                            value,
                            'success'
                          );

                          onChange(value);
                          toast({
                            content: response,
                            type: 'success',
                          });
                        }}
                        onCancel={() => {
                          toast({
                            content: `Cancelled`,
                            type: 'info',
                          });
                        }}
                        variant="h5"
                      />
                    </Stack>
                  </FormControl>
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="editableInput6"
              render={({ field, fieldState: { error } }) => {
                const { onChange } = field;
                return (
                  <FormControl error={!!error} fullWidth>
                    <Stack spacing={1}>
                      <Typography
                        {...field}
                        component={InlineEditInput}
                        fullWidth
                        onAccept={async (value) => {
                          toast({
                            content: `Saving ${value}...`,
                            type: 'info',
                          });

                          const response = await simulatedAsyncRequest(
                            value,
                            'success'
                          );

                          onChange(value);
                          toast({
                            content: response,
                            type: 'success',
                          });
                        }}
                        onCancel={() => {
                          toast({
                            content: `Cancelled`,
                            type: 'info',
                          });
                        }}
                        variant="h6"
                      />
                    </Stack>
                  </FormControl>
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="editableInput7"
              render={({ field, fieldState: { error } }) => {
                const { onChange } = field;
                return (
                  <FormControl error={!!error} fullWidth>
                    <Stack spacing={1}>
                      <InlineEditInput
                        {...field}
                        onAccept={async (value) => {
                          toast({
                            content: `Saving ${value}...`,
                            type: 'info',
                          });

                          const response = await simulatedAsyncRequest(
                            value,
                            'success'
                          );

                          onChange(value);
                          toast({
                            content: response,
                            type: 'success',
                          });
                        }}
                        onCancel={() => {
                          toast({
                            content: `Cancelled`,
                            type: 'info',
                          });
                        }}
                      />
                    </Stack>
                  </FormControl>
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">Normal textfields</Typography>
            <Divider sx={{ mb: 2 }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="normal1"
              render={({ field, fieldState: { error } }) => {
                return (
                  <FormControl error={!!error} fullWidth>
                    <Stack spacing={1}>
                      <FormLabel htmlFor="normal1">Textfield</FormLabel>
                      <TextField
                        {...field}
                        error={!!error}
                        helperText={handleErrormessage(error)}
                        id="normal1"
                        inputProps={{ 'data-testid': 'normal1-input' }}
                        variant="outlined"
                      />
                    </Stack>
                  </FormControl>
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="normal2"
              render={({ field, fieldState: { error } }) => {
                return (
                  <FormControl error={!!error} fullWidth>
                    <Stack spacing={1}>
                      <FormLabel htmlFor="normal2">Textfield</FormLabel>
                      <TextField
                        {...field}
                        error={!!error}
                        helperText={handleErrormessage(error)}
                        id="normal2"
                        inputProps={{ 'data-testid': 'normal2-input' }}
                        variant="outlined"
                      />
                    </Stack>
                  </FormControl>
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">InlineEditTextfield</Typography>
            <Divider sx={{ mb: 2 }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="editableTextfield1"
              render={({ field, fieldState: { error } }) => {
                return (
                  <FormControl error={!!error} fullWidth>
                    <Stack spacing={1}>
                      <FormLabel htmlFor="editableTextfield1">
                        InlineEditTextField (async ❌)
                      </FormLabel>
                      <InlineEditTextField
                        {...field}
                        error={!!error}
                        helperText={handleErrormessage(error)}
                        id="editableTextfield1"
                        inputProps={{
                          'data-testid': 'editableTextfield1-input',
                        }}
                        onAccept={async (value) => {
                          toast({
                            content: `Saving ${value}...`,
                            type: 'info',
                          });

                          try {
                            await simulatedAsyncRequest(value, 'error');
                            return Promise.resolve(true);
                          } catch (error) {
                            toast({
                              content: error,
                              type: 'error',
                            });
                            return Promise.resolve(false);
                          }
                        }}
                        onCancel={() => {
                          toast({
                            content: `Cancelled`,
                            type: 'info',
                          });
                        }}
                        variant="outlined"
                      />
                    </Stack>
                  </FormControl>
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="editableTextfield2"
              render={({ field, fieldState: { error } }) => {
                return (
                  <FormControl error={!!error} fullWidth>
                    <Stack spacing={1}>
                      <FormLabel htmlFor="editableTextfield2">
                        InlineEditTextField (async ✅)
                      </FormLabel>
                      <InlineEditTextField
                        {...field}
                        error={!!error}
                        helperText={handleErrormessage(error)}
                        id="editableTextfield2"
                        inputProps={{
                          'data-testid': 'editableTextfield2-input',
                        }}
                        onAccept={async (value) => {
                          toast({
                            content: `Saving ${value}...`,
                            type: 'info',
                          });

                          const response = await simulatedAsyncRequest(
                            value,
                            'success'
                          );

                          field.onChange(value);
                          toast({
                            content: response,
                            type: 'success',
                          });

                          return Promise.resolve(true);
                        }}
                        onCancel={() => {
                          toast({
                            content: `Cancelled`,
                            type: 'info',
                          });
                        }}
                        variant="outlined"
                      />
                    </Stack>
                  </FormControl>
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="editableTextfield3"
              render={({ field, fieldState: { error } }) => {
                return (
                  <FormControl error={!!error} fullWidth>
                    <Stack spacing={1}>
                      <FormLabel htmlFor="editableTextfield3">
                        InlineEditTextField
                      </FormLabel>
                      <InlineEditTextField
                        {...field}
                        error={!!error}
                        helperText={handleErrormessage(error)}
                        id="editableTextfield3"
                        inputProps={{
                          'data-testid': 'editableTextfield3-input',
                        }}
                        onAccept={(value) => {
                          field.onChange(value);
                          toast({
                            content: `onChange: ${value}`,
                            type: 'success',
                          });
                          return Promise.resolve(true);
                        }}
                        onCancel={() => {
                          toast({
                            content: `Cancelled`,
                            type: 'info',
                          });
                        }}
                        variant="outlined"
                      />
                    </Stack>
                  </FormControl>
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="editableTextfield4"
              render={({ field, fieldState: { error } }) => {
                return (
                  <FormControl error={!!error} fullWidth>
                    <Stack spacing={1}>
                      <FormLabel htmlFor="editableTextfield4">
                        InlineEditTextfield (multiline)
                      </FormLabel>
                      <InlineEditTextField
                        {...field}
                        error={!!error}
                        helperText={handleErrormessage(error)}
                        id="editableTextfield4"
                        inputProps={{
                          'data-testid': 'editableTextfield4-input',
                        }}
                        onAccept={(value) => {
                          field.onChange(value);
                          toast({
                            content: `onChange: ${value}`,
                            type: 'success',
                          });
                          return Promise.resolve(true);
                        }}
                        onCancel={() => {
                          toast({
                            content: `Cancelled`,
                            type: 'info',
                          });
                        }}
                        multiline
                        rows={4}
                        variant="outlined"
                      />
                    </Stack>
                  </FormControl>
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
          </Grid>
          <Grid item xs={12}>
            <input type="submit" />
          </Grid>
        </Grid>
      </form>
      <Box component="pre" sx={{ overflow: 'auto' }}>
        {JSON.stringify(watch(), null, 2)}
      </Box>
    </>
  );
};

const Form = () => {
  const localStoragekey = 'inline-edit-example-form';
  const getPersistedData = () => {
    return JSON.parse(localStorage.getItem(localStoragekey)) || {};
  };
  const persistedData = getPersistedData();

  const defaultValues = {
    field1: persistedData['field1'] || 'John Doe',
    field2: persistedData['field2'] || faker.lorem.paragraphs(4, ''),
    field3: persistedData['field3'] || faker.lorem.paragraphs(2, ''),
  };

  const mainValidationSchema = z.object({
    field1: z.string().min(1, 'Valor obrigatório'),
    field2: z.string().min(1, 'Valor obrigatório'),
    field3: z.string().min(1, 'Valor obrigatório'),
  });

  const { toast } = useNotification();
  const { control, getValues } = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(mainValidationSchema),
  });

  const persistField = (field) => {
    const values = getValues();
    const data = {
      ...values,
      ...field,
    };
    localStorage.setItem(localStoragekey, JSON.stringify(data));
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="field1"
            render={({ field, fieldState: { error } }) => {
              const { onChange } = field;
              return (
                <FormControl error={!!error} fullWidth>
                  <Stack spacing={1}>
                    <Typography
                      {...field}
                      component={InlineEditInput}
                      fullWidth
                      onAccept={async (value) => {
                        toast({
                          content: `Saving ${value}...`,
                          type: 'info',
                        });

                        const response = await simulatedAsyncRequest(
                          value,
                          'success'
                        );

                        onChange(value);
                        persistField({ [field.name]: value });
                        toast({
                          content: response,
                          type: 'success',
                        });
                      }}
                      onCancel={() => {
                        toast({
                          content: `Cancelled`,
                          type: 'info',
                        });
                      }}
                      variant="h3"
                    />
                  </Stack>
                </FormControl>
              );
            }}
          />
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="field2"
            render={({ field, fieldState: { error } }) => {
              const { onChange } = field;
              return (
                <FormControl error={!!error} fullWidth>
                  <Stack spacing={1}>
                    <Typography variant="h4">Sobre mim</Typography>
                    <Typography
                      {...field}
                      component={InlineEditInput}
                      fullWidth
                      onAccept={async (value) => {
                        toast({
                          content: `Saving ${value}...`,
                          type: 'info',
                        });

                        const response = await simulatedAsyncRequest(
                          value,
                          'success'
                        );

                        onChange(value);
                        persistField({ [field.name]: value });
                        toast({
                          content: response,
                          type: 'success',
                        });
                      }}
                      onCancel={() => {
                        toast({
                          content: `Cancelled`,
                          type: 'info',
                        });
                      }}
                      variant="body1"
                    />
                  </Stack>
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="field3"
            render={({ field, fieldState: { error } }) => {
              const { onChange } = field;
              return (
                <FormControl error={!!error} fullWidth>
                  <Stack spacing={1}>
                    <Typography variant="h4">Onde quero chegar</Typography>
                    <Typography
                      {...field}
                      component={InlineEditInput}
                      fullWidth
                      onAccept={async (value) => {
                        toast({
                          content: `Saving ${value}...`,
                          type: 'info',
                        });

                        const response = await simulatedAsyncRequest(
                          value,
                          'success'
                        );

                        onChange(value);
                        persistField({ [field.name]: value });
                        toast({
                          content: response,
                          type: 'success',
                        });
                      }}
                      onCancel={() => {
                        toast({
                          content: `Cancelled`,
                          type: 'info',
                        });
                      }}
                      variant="body1"
                    />
                  </Stack>
                </FormControl>
              );
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

const Example = () => {
  const [tab, setTab] = React.useState('2');

  return (
    <Container fixed maxWidth="md">
      <Box
        p={3}
        m={3}
        sx={(theme) => ({
          backgroundColor: theme.palette.background.paper,
        })}
      >
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={(_, index) => setTab(index)}
              aria-label="lab API tabs example"
            >
              <Tab label="Components" value="1" />
              <Tab label="Example form" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Components />
          </TabPanel>
          <TabPanel value="2">
            <Form />
          </TabPanel>
        </TabContext>
      </Box>
    </Container>
  );
};

export default Example;
