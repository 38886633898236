import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FullDiscountChip } from './full-discount-chip.component';
import { PartialDiscountChip } from './partial-discount-chip.component';
import { Installment, PriceTypes } from '@/react/data/catalog/interfaces';
import { Offer } from '@/react/data/catalog/interfaces';
import { formatMoney } from '@/react/utils/money';
import { Link } from '@mui/material';

const priceLabels = {
  included: 'Incluso no plano',
  monthly: '/Mês',
  priceUponRequest: 'Preço sob consulta',
  withInterestRate: 'com juros',
  withoutInterestRate: 'sem juros',
  cashPayment: 'à vista',
};

const priceUponRequest = {
  text: `O desconto é aplicado sobre o valor do curso no momento da inscrição na instituição,
    onde você terá acesso a mais informações e condições para matrícula.
    Você pode saber mais detalhes sobre o processo`,
  link: 'https://atendimento.galena.com/hc/pt-br/articles/23938037836941-O-que-significa-valor-sob-consulta-na-plataforma-Galena',
  linkText: 'clicando aqui',
}

function getLowestInterestInstallment(
  installments: Installment[]
): Installment {
  return installments.reduce((acc, curr) => {
    if (acc.fee < curr.fee) {
      return acc;
    }
    return curr;
  });
}

function getHighestInstallment(installments: Installment[]): Installment {
  return installments.reduce((acc, curr) => {
    return acc.max > curr.max ? acc : curr;
  });
}

function getHighestInstallmentWithoutFee(installments: Installment[]): Installment {
  return installments.reduce((acc, curr) => {
    return acc.max > curr.max && curr.fee === 0 ? curr : acc;
  });
}

const MainPrice = ({ salePrice, included, children }) => {
  if (salePrice === null) {
    return (
      <Typography component="div" variant="h4">
        {priceLabels.priceUponRequest}
      </Typography>
    );
  }

  if (included) {
    return (
      <Typography component="div" variant="h4">
        {priceLabels.included}
      </Typography>
    );
  }

  return (
    <Typography component="span" variant="body3">
      {children}
    </Typography>
  );
};

const PriceUponRequest = ({ salePrice }) => {
  if (salePrice === null) {
    return(
      <Box
        p={2}
        sx={(theme) => ({
          backgroundColor: theme.palette.neutral['200'],
          width: '100%',
          marginTop: 1,
        })}
      >
        <Typography
          component="div"
          variant="body3"
        >
          { priceUponRequest.text}
          <Link href={priceUponRequest.link} target="_blank" ml={0.5}>
            { priceUponRequest.linkText }
          </Link>.
        </Typography>
      </Box>
    )
  }
}

export const Prices = ({
  checkoutConfig,
  included,
  isSingleOffer,
  originalPrice,
  paymentConditions,
  saleDiscountPercentage,
  salePrice,
  type,
}: {
  checkoutConfig: Offer['checkout_config'];
  included: boolean;
  isSingleOffer: boolean;
  originalPrice: number | null;
  paymentConditions: Offer['pricing_description']['payment_conditions'];
  saleDiscountPercentage: number;
  salePrice: number | null;
  type: PriceTypes;
}) => {
  const DiscountChip =
    saleDiscountPercentage === 100 ? FullDiscountChip : PartialDiscountChip;

  const formattedDiscount = () => {
    const discountLabel =
      saleDiscountPercentage === 100 || isSingleOffer
        ? Math.floor(saleDiscountPercentage)
        : `até ${Math.floor(saleDiscountPercentage)}`;

    return <span>{discountLabel}% OFF</span>;
  };

  const hasKnownPrice = React.useMemo(() => {
    return !!(salePrice && /\d+/.test(`${salePrice}`));
  }, [salePrice]);

  const installment = paymentConditions.installments
    ? getHighestInstallment(paymentConditions.installments)
    : null;

    const calculateInstallmentValue = (price: number, installment: Installment) => {
      if (installment.fee === 0) {
        return price / installment.max;
      } 
  
      return (price / installment.max) * ((100 + installment.fee) / 100);
    }

  const OriginalPrice = () => {
    if (hasKnownPrice && type === PriceTypes.one_off) {
      return (
        <Typography
          component="div"
          variant="caption"
          sx={(theme) => ({
            '&& *': {
              fontSize: theme.typography.caption.fontSize,
              lineHeight: theme.typography.caption.lineHeight,
            },
          })}
        >
          <Stack spacing={0.5} direction={'row'}>
            <span>De</span>
            <Typography
              component="span"
              variant="caption"
              sx={() => ({
                textDecoration: 'line-through',
              })}
            >
              <span>{formatMoney(originalPrice)} </span>
              {installment && (
                <>
                  <span>ou </span>
                  <span>{installment.max}x </span>
                  <span>
                    {formatMoney(
                      calculateInstallmentValue(originalPrice, installment)
                    )}
                  </span>
                </>
              )}
            </Typography>
          </Stack>
        </Typography>
      );
    }

    if (hasKnownPrice && type === PriceTypes.tuition) {
      return (
        <Typography
          component="div"
          variant="caption"
          sx={(theme) => ({
            textDecoration: 'line-through',
            '&& *': {
              fontSize: theme.typography.caption.fontSize,
              lineHeight: theme.typography.caption.lineHeight,
            },
          })}
        >
          {installment && <span>{installment.max}x </span>}
          <span>
            {installment
              ? formatMoney(
                  calculateInstallmentValue(originalPrice, installment)
                )
              : formatMoney(originalPrice)}
          </span>
          <span> {priceLabels.monthly}</span>
        </Typography>
      );
    }
  };

  const SalePrice = () => {
    return <>
      <Typography
        component="div"
        sx={(theme) => ({
          color: theme.palette.primary[900],
          '&& *': {
            fontWeight: theme.typography.fontWeightRegular,
          },
        })}
      >
        <MainPrice salePrice={salePrice} included={included}>
          <Stack spacing={0.5} direction={'row'} alignItems={'baseline'}>
            {installment && salePrice > 0 && (
              <Typography component="span" variant="body2">
                {installment.max}x
              </Typography>
            )}
            <Typography component="span" variant="h4">
              {installment
                ? formatMoney(calculateInstallmentValue(salePrice, installment))
                : formatMoney(salePrice)}
            </Typography>
            {type === PriceTypes.tuition && (
              <Typography component="span" variant="body3">
                {priceLabels.monthly}
              </Typography>
            )}
            {installment?.fee > 0 && (
              <Typography component="span" variant="caption">
                {priceLabels.withInterestRate}
              </Typography>
            )}
          </Stack>
        </MainPrice>
      </Typography>
      {installment && type === PriceTypes.one_off &&
        <Typography
          component="div"
          variant="caption"
          sx={(theme) => ({
            '&& *': {
              fontSize: theme.typography.caption.fontSize,
              lineHeight: theme.typography.caption.lineHeight,
            },
          })}
        >
          {formatMoney(salePrice)} {priceLabels.cashPayment}

          { installment.max !== getHighestInstallmentWithoutFee(paymentConditions.installments).max && 
            <>
              <span> ou em até </span>
              <span> {getHighestInstallmentWithoutFee(paymentConditions.installments).max}x </span>
              <span> {priceLabels.withoutInterestRate} </span>
            </>
          }
        </Typography>
      }
    </>
  };

  return (
    <Box
      sx={{
        marginTop: 'auto',
      }}
    >
      <Box
        sx={() => ({
          display: 'flex',
          alignItems: 'start',
        })}
      >
        <Stack spacing={1} sx={{ flex: 1 }}>
          {hasKnownPrice && !isSingleOffer && (
            <Typography component="div" variant="caption">
              A partir de
            </Typography>
          )}
          <OriginalPrice />
          <SalePrice />
        </Stack>
        {saleDiscountPercentage > 0 && (
          <DiscountChip label={formattedDiscount()} size="small" />
        )}
      </Box>
      <PriceUponRequest salePrice={salePrice} />
    </Box>
  );
};
