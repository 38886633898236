import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { Institution } from '@/react/data/catalog/interfaces';

interface ExtraProps {
  logoBackgroundColor: string;
}

const Styled = {
  InstitutionLogo: styled('img')`
    height: 70px;
    width: 180px;
  `,
  InstitutionBackground: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'logoBackgroundColor',
  })<ExtraProps>`
    align-items: center;
    background-color: ${({ logoBackgroundColor, theme }) =>
      logoBackgroundColor || theme.palette.neutral[400]};
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  `,
};

export const InstitutionLogo = ({
  institution,
}: {
  institution: Institution;
}) => (
  <Styled.InstitutionBackground
    logoBackgroundColor={institution?.logo_background_color}
  >
    <Styled.InstitutionLogo src={institution?.logo_url} />
  </Styled.InstitutionBackground>
);
