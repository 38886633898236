import * as React from 'react';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import CardLink from '@/react/components/card-link.component';

import bgLanguages from '@/react/assets/images/AdobeStock_408810925-1.webp';
import bgTechnical from '@/react/assets/images/AdobeStock_478817630-1.webp';
import bgBasic from '@/react/assets/images/AdobeStock_482482160-1.webp';
import bgFree from '@/react/assets/images/AdobeStock_612745191-1.webp';
import bgPostgraduate from '@/react/assets/images/AdobeStock_622768246-1.webp';
import bgGraduation from '@/react/assets/images/AdobeStock_641823174-1.webp';
import bgIncludedInPlan from '@/react/assets/images/included-in-plan-bg.png';

const courseTypesData = {
  full: {
    description: 'Veja os cursos sem custo para você',
    image: bgIncludedInPlan,
  },
  basic: {
    description:
      'Construa as fundações para que eles tenham um futuro brilhante.',
    image: bgBasic,
  },
  free: {
    description: 'Explore novos interesses e desenvolva habilidades únicas.',
    image: bgFree,
  },
  graduation: {
    description: 'Dê um passo importante para o seu futuro profissional.',
    image: bgGraduation,
  },
  languages: {
    description: 'Conecte-se com o mundo aprendendo novos idiomas.',
    image: bgLanguages,
  },
  postgraduate: {
    description: 'Aprofunde seu conhecimento e se destaque no mercado.',
    image: bgPostgraduate,
  },
  technical: {
    description: 'Adquira habilidades práticas para uma carreira de sucesso.',
    image: bgTechnical,
  },
};

export type CourseTypeCardProps = {
  data: {
    description?: string;
    image?: string;
    label: string;
    link: string;
    type: string;
  };
};

export const CourseTypeCard = ({ data }: CourseTypeCardProps) => {
  const { label, image, type, description } = data;

  const getImage = () => {
    if (image) return image;

    const courseTypesMetaData = courseTypesData[type];

    return courseTypesMetaData ? courseTypesMetaData.image : null;
  };

  const getDescription = () => {
    if (description) return description;

    const courseTypesMetaData = courseTypesData[type];

    return courseTypesMetaData ? courseTypesMetaData.description : null;
  };

  return (
    <Card variant="outlined" sx={{ height: '100%' }}>
      <CardActionArea
        component={CardLink}
        href={data.link}
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: { xs: 'row', sm: 'column' },
          height: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <CardMedia
          component="img"
          image={getImage()}
          sx={{
            flex: '0 0 100px',
            minHeight: { xs: '100%', sm: '128px' },
            objectPosition: { xs: 'center', sm: '0 30%' },
            overflow: 'hidden',
            borderRadius: 0,
            width: '100%',
          }}
        />

        <CardContent>
          <Typography gutterBottom variant="h6">
            {label}
          </Typography>
          <Typography variant="body3">{getDescription()}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
