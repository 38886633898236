import { Component } from '@angular/core';
import { default as ReactCatalogFilters } from '@/react/components/catalog-filters';

@Component({
  selector: 'app-course-galery',
  templateUrl: './course-galery.component.html',
})
export class CourseGaleryComponent {
  ReactCatalogFilters = ReactCatalogFilters;
}
