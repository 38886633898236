import * as React from 'react';
import { styled } from '@mui/material/styles';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

import { useFilters } from '@/react/components/filters/state';

const Styled = {
  OutlinedInput: styled(OutlinedInput)`
    background-color: ${({ theme }) => {
      return theme.palette.neutral[50];
    }};
  `,
};

export const FilterText = ({
  id,
  placeholder,
  title,
  type,
}: {
  id: string;
  placeholder: string;
  title?: string;
  type: string;
}) => {
  const { actions, state } = useFilters();
  const field = state[id];
  const stateValue = field.values[0];
  const [value = '', setValue] = React.useState(stateValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleApplyFilter = () => {
    actions.setValueById(id, value.trim());
    actions.setPage(1);
  };

  const handleClear = () => {
    setValue('');

    if (stateValue) {
      actions.clearFieldValues(id);
      actions.setPage(1);
    }
  };

  React.useEffect(() => {
    setValue(stateValue);
  }, [stateValue]);

  return (
    <FormControl data-filter-type={type}>
      {title && <FormLabel htmlFor={id}>{title}</FormLabel>}
      <Styled.OutlinedInput
        inputProps={{ 'aria-label': 'search' }}
        id={id}
        onChange={handleChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleApplyFilter();
          }
        }}
        placeholder={placeholder}
        endAdornment={
          <InputAdornment position="end">
            {value && (
              <IconButton onClick={handleClear} edge="end">
                <CloseRoundedIcon fontSize="small" />
              </IconButton>
            )}
            <IconButton onClick={handleApplyFilter} edge="end">
              <SearchRoundedIcon fontSize="small" />
            </IconButton>
          </InputAdornment>
        }
        size="small"
        value={value}
      />
    </FormControl>
  );
};
