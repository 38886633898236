import React from 'react';

import {
  State,
  StateActions,
} from '@/react/components/refund/refund-request/state/interfaces';

const defaultState = {
  loading: false,
  resourceApproval: null,
  subsidyPolicy: null,
} as State;

export const Context = React.createContext(null);

export function RefundRequestProvider({
  children,
  initialState,
}: {
  children: React.ReactNode;
  initialState: Partial<State>;
}) {
  React.useEffect(() => {
    const state: State = {
      ...defaultState,
      ...initialState,
    };

    dispatch({
      type: 'INIT',
      payload: state,
    });
  }, [JSON.stringify(initialState)]);

  const [state, dispatch] = React.useReducer(reducer, initialState);

  const actions = {
    setLoading: (loading) => {
      dispatch({
        type: 'SET_LOADING',
        payload: loading,
      });
    },
    updateValues: (values) => {
      dispatch({
        type: 'UPDATE_VALUES',
        payload: values,
      });
    },
  } as StateActions;

  const value = [state, actions];

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

function reducer(state: State, action): Partial<State> {
  switch (action.type) {
    case 'INIT': {
      return { ...action.payload };
    }
    case 'SET_LOADING': {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case 'UPDATE_VALUES': {
      return {
        ...state,
        ...action.payload,
      };
    }
  }
}
