import { Component, Output, EventEmitter } from '@angular/core';
import { getSvg } from 'src/app/utils/helpers';

@Component({
  selector: 'app-leroy-modal',
  templateUrl: './leroy-modal.component.html',
  styleUrls: ['./leroy-modal.component.scss'],
})
export class LeroyModalComponent {
  @Output()
  close = new EventEmitter();

  closeModal() {
    this.close.emit();
  }

  getSvg = getSvg;
}
