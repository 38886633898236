export const priceDisplay = (price: number, formattedPrice: string) => {
  return price === 0 ? 'Grátis' : formattedPrice;
};

export const getDiscountDisplay = (
  price: number,
  discount: number,
  priceUponRequest: boolean
) => {
  return price === 0 && !priceUponRequest ? 100 : discount;
};
