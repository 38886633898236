import { AbstractControl, ValidationErrors } from '@angular/forms';
import { z } from 'zod';

import { extractNumber } from './number';

export function monthValidation(value: string = '') {
  let number = parseInt(extractNumber(value));
  return value === '' || (!isNaN(number) && number > 0 && number < 13);
}

export function yearValidation(value: string = '') {
  const number = parseInt(extractNumber(value));
  //set year to 2 digits
  const year = parseInt(new Date().getFullYear().toString().slice(-2));
  return (
    value === '' ||
    (!isNaN(number) &&
      (value.length === 1 || value.length === 2) &&
      number >= year)
  );
}

export function dateValidator(
  control: AbstractControl
): ValidationErrors | null {
  const attrName = 'date';
  const dateSchema = z.coerce.date();
  const result = dateSchema.safeParse(control.value);

  let informedDate;

  if (result.success) {
    informedDate = result.data;
  } else {
    return { [attrName]: 'Data inválida' };
  }

  const today = new Date();

  if (informedDate.setUTCHours(0, 0, 0, 0) >= today.setUTCHours(0, 0, 0, 0)) {
    return { [attrName]: 'Deve ser no passado' };
  }
}
