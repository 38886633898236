import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const ContentContainer = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(3),
  paddingTop: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },

  '& > .MuiBox-root:last-child': {
    marginBottom: 0,
  },
}));

export default ContentContainer;
