import z from 'zod';

export const isValidEmail = (email: string) => {
  if (!email) {
    return false;
  }
  const parsedEmail = z.string().email().safeParse(email);
  return parsedEmail.success;
};

export const isCPFLike = (input: string) => {
  if (!input) {
    return false;
  }

  return /^\d{3}\.?\d{3}\.?\d{3}\-?\d{2}$/.test(input);
};
