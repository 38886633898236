import React from "react";
import { Grid, Typography } from "@mui/material";
import { useRefundRequest } from '@/react/components/refund/refund-request/state/useRefundRequest';
import { formatDate } from "@/react/utils/date";

export const BriefSummary = () => {
  const {resourceApproval } = useRefundRequest();

  return (
    <Grid container>
      <Grid item xs={12} justifyContent="center"
        sx={({palette, spacing}) => ({
          color: palette.neutral[800],
          marginBottom: spacing(2),
          paddingBottom: spacing('0.5'),
          paddingTop: spacing('0.5'),
        })}>
          <Typography variant="body3">
            { resourceApproval?.name.split(', ')[0] + ' | ' + resourceApproval?.resource.course.institution_name }
          </Typography>
          <Typography variant="body3">
            { resourceApproval?.decided_at && `Pedido aprovado em: ${formatDate(resourceApproval?.decided_at)}`}
          </Typography>
        </Grid>
    </Grid>
    );
};
