import { Component } from '@angular/core';

import ReactRefundPoliciesComponent from '@/react/components/refund/refund-policies/refund-policies.component';

@Component({
  selector: 'component-refund-policies',
  templateUrl: './refund-policies.component.html',
})
export class RefundPoliciesComponent {
  ReactRefundPoliciesComponent = ReactRefundPoliciesComponent;
}
