import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentProps } from 'react';
import { ShowActivity } from '@/react/components/idp/show-activity';

@Component({
  selector: 'app-show-activity',
  templateUrl: './show-activity.component.html',
})
export class IdpShowActivityComponent implements OnInit {
  IdpShowActivityComponent = ShowActivity;
  showActivityProps: ComponentProps<typeof ShowActivity> = {
    activityPlanId: undefined,
    activityId: undefined,
  };

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.showActivityProps = {
        activityPlanId: params.id,
        activityId: params.activityId,
      };
    });
  }
}
