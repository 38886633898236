import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import * as React from 'react';
import { toastSuccess, toastError } from '@/react/utils/custom-toast';
import { restClient as restClientWithAuthorization } from '@/react/utils/fetch';
import { withZodErrorHandling } from '@/react/config/axios';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import * as Sentry from '@sentry/browser';
import styled from '@emotion/styled';
import AutocompleteStatesAndCities from '../autocomplete-states-cities.component';
import { formValidationSchema } from '../schemas';

const restClient = withZodErrorHandling(restClientWithAuthorization);

const CustonInfoOutLinedIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
  color: theme.palette.neutral[500],
  marginRight: '8px',
  fontSize: 'small',
  height: '20px',
  width: '20px',
}));

const customInputStyle = {
  fontFamily: 'Assistant',
  fontSize: '1rem',
  fontWeight: 500,
  lineHeight: '1.5rem',
};

const intialValues = {
  name: '',
  email: '',
  cpf: '',
  internal_employee_id: '',
  type_collaborator: '',
  position_company: '',
  department: '',
  team: '',
  manager_email: '',
  building: '',
  region: '',
  state: '',
  state_id: '',
  city: '',
  city_id: '',
  company: '',
  cost_center: '',
  is_admin: false,
  type_registration: 'email',
};

const Tooltips = {
  COLABORADOR: 'colaborador',
  CAMPOS_OPCIONAIS: 'campos_opcionais',
  EMPRESA: 'empresa',
  ADMINISTRADOR: 'administrador',
  TIPO_ACESSO: 'tipo_acesso',
};

const getCompanieIDFromUrl = () => {
  try {
    const url = new URL(window.location.href);
    const clearUrlParameters = url.search.replace(/^\?/, '');

    const [key, value] = clearUrlParameters.split('=');
    const company = {
      [key]: value,
    };

    return company;
  } catch (error) {
    console.error('Erro ao descriptografar parâmetros @@@:', error);
  }
};

const RegisterEmployeeContent = () => {
  getCompanieIDFromUrl();
  const [selectedButton, setSelectedButton] = React.useState('Email');

  const [stateSelected, setstateSelected] = React.useState('');
  const [citySelected, setcitySelected] = React.useState('');

  const handleUpdateStateAndCity = (estado, cidade) => {
    setstateSelected(estado);
    setcitySelected(cidade);
  };

  const showCPF = selectedButton === 'CPF';
  const showEmail = !showCPF;

  const { control, handleSubmit, setValue } = useForm({
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: intialValues,
    resolver: zodResolver(formValidationSchema),
  });

  React.useEffect(() => {
    if (showCPF) {
      setValue('email', '');
      setValue('type_registration', 'cpf');
    } else if (showEmail) {
      setValue('cpf', '');
      setValue('type_registration', 'email');
    }
  }, [showCPF, showEmail, setValue]);

  const [loading, setLoading] = React.useState(false);

  const [openTooltip, setOpenTooltip] = React.useState(null);

  const handleTooltipOpen = (tooltipId) => {
    setOpenTooltip((prevActiveTooltip) =>
      prevActiveTooltip === tooltipId ? null : tooltipId
    );
  };

  const handleAdd = async (data) => {
    const company_id = getCompanieIDFromUrl();

    const payload = {
      ...data,
      state: stateSelected,
      city: citySelected,
      ...company_id,
    };

    setLoading(true);

    try {
      const response = await restClient.post('/employees', payload);
      const messageToast =
        response?.data?.data?.message ?? 'Colaborador criado com sucesso!';

      toastSuccess({ messageToast });
      window.location.assign('/empresa/employees');
    } catch (error) {
      const defaultErrorMessage = 'Erro ao criar Colaborador.';
      const errorMessage = error.message || defaultErrorMessage;

      toastError({ messageToast: errorMessage });

      setLoading(false);
      Sentry.captureException(error);
    }
  };

  const handleCancelAddEmployee = () => {
    window.location.assign('/empresa/employees');
  };

  return (
    <form onSubmit={handleSubmit(handleAdd)}>
      <Container>
        <Paper elevation={5} sx={{ my: 2 }} style={{ padding: '32px' }}>
          <Grid
            container
            spacing={2}
            my={2}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              '& > *': {
                m: 1,
              },
            }}
          >
            <Grid item sm={6} xs={12}>
              <Typography gutterBottom variant="h4">
                Adicionar Colaborador
              </Typography>
            </Grid>

            <Grid
              item
              sm={6}
              xs={12}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                <FormLabel
                  variant="body1"
                  severity="info"
                  style={{ marginRight: '20px' }}
                >
                  <Tooltip
                    TransitionComponent={Zoom}
                    PopperProps={{
                      disablePortal: true,
                    }}
                    open={openTooltip === Tooltips.COLABORADOR}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          whiteSpace: 'pre-wrap',
                          fontFamily: 'Assistant',
                          fontSize: '0.9rem',
                          fontWeight: 500,
                          lineHeight: '1.5rem',
                          width: '350px',
                        },
                      },
                    }}
                    title="Essa escolha define qual informação será usada para acessar a plataforma. É recomendado usar o email corporativo, se existente."
                    onClick={() => handleTooltipOpen(Tooltips.COLABORADOR)}
                  >
                    <CustonInfoOutLinedIcon />
                  </Tooltip>
                  Quero cadastrar por:
                </FormLabel>

                <Box>
                  <ButtonGroup variant="text" aria-label="text button group">
                    <Button
                      onClick={() => setSelectedButton('Email')}
                      variant={
                        selectedButton === 'Email' ? 'contained' : 'text'
                      }
                      style={{
                        border:
                          selectedButton === 'CPF'
                            ? '1px solid #7E0B80'
                            : 'none',
                      }}
                    >
                      Email
                    </Button>
                    <Button
                      onClick={() => setSelectedButton('CPF')}
                      variant={selectedButton === 'CPF' ? 'contained' : 'text'}
                      style={{
                        border:
                          selectedButton === 'Email'
                            ? '1px solid #7E0B80'
                            : 'none',
                      }}
                    >
                      CPF
                    </Button>
                  </ButtonGroup>
                </Box>
                <Controller
                  control={control}
                  name="type_registration"
                  defaultValue="email"
                  render={({ field }) => <input type="hidden" {...field} />}
                />
              </div>
            </Grid>
          </Grid>

          {showEmail && (
            <Grid item xs={12} md={6}>
              <FormLabel>E-mail*</FormLabel>
              <Controller
                control={control}
                name="email"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    fullWidth
                    variant="outlined"
                    helperText={error?.message || ' '}
                    placeholder="colaborador@nomeempresa.com"
                    InputProps={{ style: customInputStyle }}
                  />
                )}
              />
            </Grid>
          )}

          {showCPF && (
            <Grid item xs={12} md={6}>
              <FormLabel>CPF*</FormLabel>
              <Controller
                control={control}
                name="cpf"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    fullWidth
                    placeholder="123.456.789-10"
                    variant="outlined"
                    helperText={error?.message || ' '}
                    InputProps={{ style: customInputStyle }}
                  />
                )}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <FormLabel>Nome Completo*</FormLabel>
            <Controller
              control={control}
              name="name"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error}
                  fullWidth
                  placeholder="Insira o nome completo do colaborador"
                  variant="outlined"
                  helperText={error?.message || ' '}
                  InputProps={{ style: customInputStyle }}
                />
              )}
            />
          </Grid>

          <Grid container spacing={2} my={2}>
            <Grid item sm={12} xs={12}>
              <Typography gutterBottom variant="h4" severity="info">
                Campos opcionais
                <Tooltip
                  TransitionComponent={Zoom}
                  PopperProps={{
                    disablePortal: true,
                  }}
                  open={openTooltip === Tooltips.CAMPOS_OPCIONAIS}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        whiteSpace: 'pre-wrap',
                        fontFamily: 'Assistant',
                        fontSize: '0.9rem',
                        fontWeight: 500,
                        lineHeight: '1.5rem',
                      },
                    },
                  }}
                  title="Preencher os campos opcionais permite que eles se tornem filtro no relatório de colaboradores."
                  onClick={() => handleTooltipOpen(Tooltips.CAMPOS_OPCIONAIS)}
                >
                  <CustonInfoOutLinedIcon sx={{ marginLeft: '7px' }} />
                </Tooltip>
              </Typography>
            </Grid>

            <Grid item sm={6} xs={12} sx={{ paddingRight: { md: '20px' } }}>
              <FormLabel>ID interno</FormLabel>
              <Controller
                control={control}
                name="internal_employee_id"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    fullWidth
                    placeholder="ex.: número de matrícula, chave interna"
                    variant="outlined"
                    helperText={error?.message || ' '}
                    InputProps={{ style: customInputStyle }}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6} xs={12} sx={{ paddingLeft: { md: '20px' } }}>
              <FormLabel>Tipo de colaborador</FormLabel>
              <Controller
                control={control}
                name="type_collaborator"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    fullWidth
                    placeholder="ex.: CLT, externo, prestador de serviço"
                    variant="outlined"
                    helperText={error?.message || ' '}
                    InputProps={{ style: customInputStyle }}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6} xs={12} sx={{ paddingRight: { md: '20px' } }}>
              <FormLabel>Cargo</FormLabel>
              <Controller
                control={control}
                name="position_company"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    fullWidth
                    placeholder="ex.: analista, coordenador"
                    variant="outlined"
                    helperText={error?.message || ' '}
                    InputProps={{ style: customInputStyle }}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6} xs={12} sx={{ paddingLeft: { md: '20px' } }}>
              <FormLabel>Equipe</FormLabel>
              <Controller
                control={control}
                name="team"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    fullWidth
                    placeholder="ex.: atração de talentos, desenvolvimento"
                    variant="outlined"
                    helperText={error?.message || ' '}
                    InputProps={{ style: customInputStyle }}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6} xs={12} sx={{ paddingRight: { md: '20px' } }}>
              <FormLabel>Departamento</FormLabel>
              <Controller
                control={control}
                name="department"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    fullWidth
                    placeholder="ex.: recursos humanos, marketing"
                    variant="outlined"
                    helperText={error?.message || ' '}
                    InputProps={{ style: customInputStyle }}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6} xs={12} sx={{ paddingLeft: { md: '20px' } }}>
              <FormLabel>Centro de custo</FormLabel>
              <Controller
                control={control}
                name="cost_center"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    fullWidth
                    placeholder="ex.: produtos digitais"
                    variant="outlined"
                    helperText={error?.message || ' '}
                    InputProps={{ style: customInputStyle }}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6} xs={12} sx={{ paddingRight: { md: '20px' } }}>
              <FormLabel>Gestor</FormLabel>
              <Controller
                control={control}
                name="manager_email"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    fullWidth
                    placeholder="Insira o email"
                    variant="outlined"
                    helperText={error?.message || ' '}
                    InputProps={{ style: customInputStyle }}
                  />
                )}
              />
            </Grid>

            <AutocompleteStatesAndCities
              handleUpdateStateAndCity={handleUpdateStateAndCity}
              customInputStyle={customInputStyle}
            />

            <Grid item sm={6} xs={12} sx={{ paddingLeft: { md: '20px' } }}>
              <FormLabel>Região</FormLabel>
              <Controller
                control={control}
                name="region"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    fullWidth
                    placeholder="ex.: filial, região de atuação"
                    variant="outlined"
                    helperText={error?.message || ' '}
                    InputProps={{ style: customInputStyle }}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6} xs={12} sx={{ paddingRight: { md: '20px' } }}>
              <FormLabel>Prédio</FormLabel>
              <Controller
                control={control}
                name="building"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    fullWidth
                    placeholder="ex.: nome do edifício, campus ou andar"
                    variant="outlined"
                    helperText={error?.message || ' '}
                    InputProps={{ style: customInputStyle }}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6} xs={12} sx={{ paddingLeft: { md: '20px' } }}>
              <FormLabel>
                Empresa
                <Tooltip
                  placement="right-end"
                  TransitionComponent={Zoom}
                  PopperProps={{
                    disablePortal: true,
                  }}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  open={openTooltip === Tooltips.EMPRESA}
                  title="Campo para holding que possuem diversas empresas."
                  onClick={() => handleTooltipOpen(Tooltips.EMPRESA)}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        whiteSpace: 'pre-wrap',
                        fontFamily: 'Assistant',
                        fontSize: '0.9rem',
                        fontWeight: 500,
                        lineHeight: '1.5rem',
                        width: '350px',
                      },
                    },
                  }}
                >
                  <CustonInfoOutLinedIcon sx={{ marginLeft: '7px' }} />
                </Tooltip>
              </FormLabel>
              <Controller
                control={control}
                name="company"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    fullWidth
                    placeholder="ex.: empresa pertencente a uma holding"
                    variant="outlined"
                    helperText={error?.message || ' '}
                  />
                )}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                '& > *': {
                  m: 1,
                },
              }}
            >
              <FormGroup
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  margin: '0px',
                }}
              >
                <Controller
                  name="is_admin"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                      }
                      label={
                        <Typography variant="h6" sx={{ margin: '0px' }}>
                          Administrador
                        </Typography>
                      }
                    />
                  )}
                />
                <Tooltip
                  placement="bottom-end"
                  TransitionComponent={Zoom}
                  PopperProps={{
                    disablePortal: true,
                  }}
                  open={openTooltip === Tooltips.ADMINISTRADOR}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        whiteSpace: 'pre-wrap',
                        fontFamily: 'Assistant',
                        fontSize: '0.9rem',
                        fontWeight: 500,
                        lineHeight: '1.5rem',
                      },
                    },
                  }}
                  title="Dá acesso à gestão de usuários, relatórios e outras configurações da plataforma."
                  onClick={() => handleTooltipOpen(Tooltips.ADMINISTRADOR)}
                >
                  <CustonInfoOutLinedIcon sx={{ marginLeft: '7px' }} />
                </Tooltip>
              </FormGroup>
            </Grid>
          </Grid>

          <Divider sx={{ my: 2 }} />

          <Grid
            item
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              '& > *': {
                m: 1,
              },
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              style={{
                marginRight: '10px',
              }}
              onClick={handleCancelAddEmployee}
            >
              Cancelar
            </Button>
            <LoadingButton
              color="primary"
              loading={loading}
              loadingPosition="start"
              type="submit"
              variant="contained"
            >
              Adicionar colaborador
            </LoadingButton>
          </Grid>
        </Paper>
      </Container>
    </form>
  );
};

export default RegisterEmployeeContent;
