import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';

import { SearchInput } from './search-input.component';

const Styled = {
  SearchWrapper: styled('div')(({ theme }) => ({
    background: theme.palette.background.paper,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    minHeight: theme.spacing(7),
    paddingBottom: theme.spacing(1.5),
    paddingTop: theme.spacing(1.5),
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100%',
    zIndex: 1,
  })),
};

export const MobileSearchInput = ({ currentUrl = '' }) => {
  const [open, setOpen] = React.useState(false);

  const renderContent = () =>
    open ? (
      <Styled.SearchWrapper>
        <Stack spacing={1} direction="row" sx={{ flex: 1 }}>
          <IconButton onClick={() => setOpen(false)}>
            <ArrowBackIcon fontSize="medium" />
          </IconButton>
          <SearchInput currentUrl={currentUrl} />
        </Stack>
      </Styled.SearchWrapper>
    ) : (
      <IconButton onClick={() => setOpen(true)}>
        <SearchIcon fontSize="medium" />
      </IconButton>
    );

  return <>{renderContent()}</>;
};
