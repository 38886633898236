import * as React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import { useFilters } from '@/react/components/filters/state';
import { useCatalog } from '@/react/components/catalog/state';
import { useCatalogSearch } from '@/react/data/catalog/useCatalogSearch';

import { useGtm } from '../state/useGtm';
import CourseList from './course-list.component';
import CourseListLoading from './course-list-loading.component';
import EmptyState from './empty-state.component';
import Pagination from '@/react/components/pagination/pagination.component';

const DEFAULT_PAGE_SIZE = 50;

const filtersParamsParsers = [
  {
    field: 'knowledge_areas',
    validate: (value: string[]) => Array.isArray(value),
    parse: (input: string[], params) =>
      (params.knowledge_areas = input.map((i) => +i)),
  },
  {
    field: 'course_types',
    validate: (value: string[]) => Array.isArray(value),
    parse: (input: string[], params) => (params.course_types = input),
  },
  {
    field: 'modalities',
    validate: (value: string[]) => Array.isArray(value),
    parse: (input: string[], params) => (params.modalities = input),
  },
  {
    field: 'institutions',
    validate: (value: string[]) => Array.isArray(value),
    parse: (input: string[], params) =>
      (params.institutions = input.map((i) => +i)),
  },
  {
    field: 'location',
    validate: (value: string[]) => Array.isArray(value),
    parse: (input: string[], params) => {
      const [state, city] = input;

      if (state) params.states = [state];
      if (city) params.city = city;
    },
  },
  {
    field: 'price',
    validate: (value: string[]) => Array.isArray(value),
    parse: (input: string[], params) => {
      const [minPrice, maxPrice] = input || [];

      params.budget = {};
      if (minPrice && !isNaN(+minPrice)) params.budget.min = +minPrice;
      if (maxPrice && !isNaN(+maxPrice)) params.budget.max = +maxPrice;
    },
  },
  {
    field: 'feature_full',
    validate: (value: boolean[]) => Array.isArray(value),
    parse: (_, params) => {
      params.features = [...(params.features || []), 'full_included'];
    },
  },
  {
    field: 'terms',
    validate: (value: string[]) => Array.isArray(value),
    parse: (input: string[], params) => (params.terms = input.join()),
  },
];

const paginationParamsParsers = [
  {
    field: 'page',
    parse: (input: number, params) => (params.page = +input || 1),
  },
  {
    field: 'sort',
    parse: (input: string[], params) =>
      (params.sort = input?.join() || 'relevance'),
  },
];

const buildQueryFilters = (filters) => {
  const queryFilters = {};

  filtersParamsParsers.forEach((parser) => {
    const value = filters[parser.field];

    if (parser.validate(value)) {
      parser.parse(value, queryFilters);
    }
  });

  return queryFilters;
};

const buildPaginationParams = (filters) => {
  const paginationParams = {};

  paginationParamsParsers.forEach((parser) => {
    const value = filters[parser.field];

    parser.parse(value, paginationParams);
  });

  return paginationParams;
};

const buildQueryParams = (filters) => {
  const paginationParams = buildPaginationParams(filters);

  return {
    filters: buildQueryFilters(filters),
    page_size: DEFAULT_PAGE_SIZE,
    ...paginationParams,
  };
};

export const Catalog = () => {
  const { actions, values: filtersValues } = useFilters();
  const { updateState: updateCatalogState } = useCatalog();
  const gtm = useGtm();

  const queryParams = buildQueryParams(filtersValues);

  const { catalog: catalogData, isFetching } = useCatalogSearch(queryParams);

  React.useEffect(() => {
    if (isFetching) {
      updateCatalogState((prev) => ({ ...prev, loading: true }));
    } else {
      updateCatalogState((prev) => ({
        ...prev,
        loading: false,
        ...catalogData,
      }));

      gtm.trackCoursesEvent(catalogData.items);
    }
  }, [isFetching]);

  const handlePageChange = (page) => {
    actions.setPage(page);
  };

  return (
    <>
      {isFetching && (
        <>
          <Box mt={{ xs: 2, md: '0' }}>
            <CourseListLoading />
          </Box>
          <Box mt={2}>
            <Skeleton sx={{ height: 40 }} animation="wave" variant="rounded" />
          </Box>
        </>
      )}
      {!isFetching && (
        <>
          {catalogData.items.length === 0 &&
          catalogData.showcase.length === 0 ? (
            <EmptyState />
          ) : (
            <>
              <Box mt={{ xs: 2, md: '0' }}>
                <CourseList
                  items={catalogData.items}
                  showcaseItems={catalogData.showcase}
                />
              </Box>
              <Box display="flex" justifyContent="center" mt={2}>
                <Pagination
                  currentPage={catalogData.pagination.page}
                  pageChange={handlePageChange}
                  totalPages={catalogData.pagination.total_pages}
                />
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};
