import * as React from 'react';
import { Context } from './context';

import { StateActions } from '@/react/components/refund/accordion/state/interfaces';
import { AccordionStep } from '@/react/components/refund/accordion/interfaces';

export function useAccordion(): useAccordion {
  const context = React.useContext(Context);

  if (!context) {
    throw new Error('useAccordion must be used within a AccordionContext');
  }

  const [state, actions] = context;
  const { steps = {} } = state;

  return {
    actions,
    steps,
  };
}

type useAccordion = {
  actions: StateActions;
  steps: { [key: string]: AccordionStep };
};
