import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  NgbDatepickerModule,
  NgbCarouselModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgClickOutsideDirective } from 'ng-click-outside2';

import { ChartVerticalBarComponent } from './chart-vertical-bar/chart-vertical-bar.component';
import { HorizontalCatalogComponent } from './horizontal-catalog/horizontal-catalog.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { InputOutroComponent } from './form/input-outro/input-outro.component';
import { ScrollUpComponent } from './scroll-up/scroll-up.component';
import { SelectableCardComponent } from './selectable-card/selectable-card.component';
import { CalendarInputComponent } from './form/calendar-input/calendar-input.component';
import { BotaoComponent } from './botao/botao.component';
import { InputComponent } from './form/input/input.component';
import { SelectComponent } from './form/select/select.component';
import { SearchOptionsComponent } from './form/search-options/search-options.component';
import { ModalComponent } from './modal/modal.component';
import { InputRadioComponent } from './form/input-radio/input-radio.component';
import { BannerComponent } from './banner/banner.component';
import { CollapsibleComponent } from './collapsible/collapsible.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { BannerNavigationComponent } from './banner-navigation/banner-navigation.component';
import { CarouselComponent } from './carousel/carousel.component';
import { BotaoIconComponent } from './botao-icon/botao-icon.component';
import { CheckboxComponent } from './form/checkbox/checkbox.component';
import { CollapsibleListComponent } from './collapsible-list/collapsible-list.component';
import { StepIndicatorComponent } from './step-indicator/step-indicator.component';
import { CarouselIndicatorsComponent } from './carousel-indicators/carousel-indicators.component';
import { NewCardComponent } from './new-card/new-card.component';
import { BudgetFilterComponent } from './budget-filter/budget-filter.component';
import { DropdownFilterComponent } from './dropdown-filter/dropdown-filter.component';
import { TextFilterComponent } from './text-filter/text-filter.component';
import { ChartHorizontalBarComponent } from './chart-horizontal-bar/chart-horizontal-bar.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { SkeletonCardComponent } from './skeleton/skeleton-card/skeleton-card.component';
import { NgReactMuiComponent } from '@/react/angular-wrappers/ng-react-mui.component';
import { PlanGaleryComponent } from '@/pages/cursos/plan-galery/plan-galery.component';

const components = [
  BotaoComponent,
  InputComponent,
  SelectableCardComponent,
  ScrollUpComponent,
  SelectComponent,
  SearchOptionsComponent,
  InputOutroComponent,
  CalendarInputComponent,
  ModalComponent,
  InputRadioComponent,
  BannerNavigationComponent,
  BannerComponent,
  CollapsibleComponent,
  DatePickerComponent,
  CarouselComponent,
  LanguageSelectorComponent,
  HorizontalCatalogComponent,
  BotaoIconComponent,
  CheckboxComponent,
  CollapsibleListComponent,
  StepIndicatorComponent,
  CarouselIndicatorsComponent,
  NewCardComponent,
  DropdownFilterComponent,
  TextFilterComponent,
  BudgetFilterComponent,
  BotaoComponent,
  ChartHorizontalBarComponent,
  ChartVerticalBarComponent,
  TooltipComponent,
  SkeletonCardComponent,
  PlanGaleryComponent,
];

const reactComponents = [NgReactMuiComponent];

const allComponents = [...components, ...reactComponents];

export function createChildTranslateLoader(httpclient: HttpClient) {
  return new TranslateHttpLoader(httpclient, './assets/i18n/', '.ts');
}

@NgModule({
  declarations: allComponents,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbDatepickerModule,
    NgbCarouselModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createChildTranslateLoader,
        deps: [HttpClient],
      },
    }),
    MatSliderModule,
    NgClickOutsideDirective,
  ],
  exports: [...allComponents, TranslateModule, NgClickOutsideDirective],
})
export class ComponentsModule {}
