import * as React from 'react';

import { State } from './interfaces';

export const Context = React.createContext(null);

export function CatalogContext(props) {
  const state = {
    items: [],
    pagination: {
      total: undefined,
      total_pages: undefined,
      page: undefined,
      page_size: undefined,
    },
    loading: false,
    showcase: [],
  };

  const [catalogState, setCatalogState] = React.useState<State>(state);

  return (
    <Context.Provider value={[catalogState, setCatalogState]}>
      {props.children}
    </Context.Provider>
  );
}
