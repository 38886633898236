import { useQuery } from '@tanstack/react-query';
import { JobSearchResult, JobSearchResultItem } from './interfaces';
import { keyBy, MaybePromise, uniqBy } from 'powership';
import { UseQueryOptions } from '@tanstack/react-query/src/types';
import { useMemo, useState } from 'react';
import { useThrottle } from '@/react/components/quiz/utils/useThrottle';
import { RecommendationClient } from '@/react/data/recommendation/recommendation-client';

const cache = new Map<string, MaybePromise<JobSearchResultItem[]>>();

export function useJobSearch(otherOptions?: UseQueryOptions<JobSearchResult>) {
  const [term, _setTerm] = useState('');

  const result = useQuery<JobSearchResult>({
    queryKey: ['useJobSearch_key', term, cache],
    refetchOnMount: false,
    ...otherOptions,
    queryFn: async ({ signal }) => {
      return RecommendationClient.findJobs(term, signal);
    },
  });

  const [throttledSetTerm] = useThrottle(210, _setTerm);

  return useMemo(() => {
    const jobs = result.data || [];

    return {
      jobs,
      isLoading: result.isLoading,
      setTerm: throttledSetTerm,
      term,
    };
  }, [result.data, result.isLoading, throttledSetTerm, term]);
}
