import CONST from '@/constants';

export default {
  help: {
    title: 'Precisa de ajuda?',
    links: [
      { name: 'Central de ajuda', href: CONST.GALENA_SERVICE_URL },
      { name: 'Whatsapp', href: `${CONST.SUPPORT_URL}` },
    ]
  },
  terms: {
    title: 'Termos e condições',
    links: [
      { name: 'Termos de uso', href: `${CONST.GALENA_WEBSITE_URL}/termos-de-uso/` },
      { name: 'Política de privacidade', href: `${CONST.GALENA_WEBSITE_URL}/politica-de-privacidade/` },
    ]
  },
  version: `Versão ${CONST.VERSION}`,
  name: 'Galena 2024',
  copy: 'Todos os direitos reservados.'
};