export const getFilterValuesFromUrl = (): { [key: string]: string[] } => {
  const searchParams = new URLSearchParams(window.location.search);
  const stateFromUrl = {};

  for (const [key, value] of searchParams.entries()) {
    stateFromUrl[key] = value.split(',');
  }

  return stateFromUrl;
};

export const updateUrlQueryParams = (
  queryKey: string,
  queryValue: string
): string => {
  const searchParams = new URLSearchParams(window.location.search);

  if (queryValue) {
    searchParams.set(queryKey, queryValue);
  } else {
    searchParams.delete(queryKey);
  }

  const decodedSearchParams = decodeURIComponent(searchParams.toString());
  const newUrl = [window.location.pathname, decodedSearchParams]
    .filter(Boolean)
    .join('?');

  window.history.replaceState(null, '', newUrl);

  const urlSegment = searchParams.get(queryKey)
    ? `${queryKey}=${searchParams.get(queryKey)}`
    : '';

  return urlSegment;
};
