import * as React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import ContentContainer from '@/react/components/layout/content-container.component';
import { Filters } from '@/react/components/filters';

import { FiltersHeaderMobile } from './catalog-filters-mobile-header.component';
import { useCatalog } from '@/react/components/catalog/state';

import Catalog from '@/react/components/catalog';

export const CatalogFiltersMobile = () => {
  const [openFilters, setOpenFilters] = React.useState(false);
  const { loading: loadingCatalog, itemsCount: catalogCount } = useCatalog();

  const iOS =
    typeof navigator !== 'undefined' &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <>
      <FiltersHeaderMobile handleOpenFilters={setOpenFilters} />
      <SwipeableDrawer
        anchor="bottom"
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={openFilters}
        onClose={() => setOpenFilters(false)}
        onOpen={() => setOpenFilters(true)}
        variant="temporary"
        PaperProps={{
          sx: { maxHeight: '85vh' },
        }}
      >
        <DialogTitle sx={{ px: 2, py: 1 }}>
          <Button
            color="primary"
            onClick={() => setOpenFilters(false)}
            size="large"
            startIcon={<ArrowBackIcon />}
            variant="text"
          >
            Filtros
          </Button>
        </DialogTitle>
        <DialogContent sx={{ px: 2, py: 1 }}>
          <Filters />
        </DialogContent>
        <DialogActions sx={{ px: 2, py: 1 }}>
          <LoadingButton
            color="primary"
            fullWidth
            loading={loadingCatalog}
            onClick={() => setOpenFilters(false)}
            variant="contained"
          >
            Ver resultados ({catalogCount})
          </LoadingButton>
        </DialogActions>
      </SwipeableDrawer>
      <Container fixed maxWidth="xl">
        <ContentContainer sx={{ '&&': { pt: { xs: '0', md: 4 } } }}>
          <Catalog />
        </ContentContainer>
      </Container>
    </>
  );
};
