import { createTheme } from '@mui/material/styles';

const defaultShadows = createTheme().shadows;

const shadows = {
  ...defaultShadows,
  1: '0px 4px 8px 1px rgba(51,51,51,0.04)',
  2: '0px 6px 12px 1px rgba(51,51,51,0.06)',
};

export default shadows;
