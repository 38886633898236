import statesJson from 'src/app/data/estados.json';

import { Taxonomy } from '@/react/data/bootstrap/interfaces';
import { Institution } from '@/react/data/institutions/interfaces';
import { FiltersSchema } from '../filters/state/interfaces';

const mountCourseTypesOptions = (courseTypes) => {
  return courseTypes.map((el) => ({
    label: el.label,
    value: el.type,
  }));
};

const mountKnowledgeAreasOptions = (knowledgeAreas) => {
  return knowledgeAreas.map((el) => ({
    label: el.description_pt_br,
    value: String(el.id),
  }));
};

const mountInstitutionsOptions = (institutions) => {
  return institutions.map((el) => ({
    label: el.name,
    value: String(el.id),
  }));
};

const mountModalitiesOptions = (modalities) => {
  return modalities.map((modality) => ({
    label: modality.name,
    value: modality.value,
  }));
};

const mountLocationOptions = () => {
  const statesOptions = statesJson.reduce((acc, state) => {
    const stateOptions = {
      label: `${state.nome} (${state.sigla})`,
      value: state.sigla,
    };

    acc.push(stateOptions);

    return acc;
  }, []);

  const citiesOptionsByState = statesJson.reduce((acc, state) => {
    acc[state.sigla] = state.cidades.map((city) => ({
      label: city,
      value: city,
    }));

    return acc;
  }, {});

  return {
    statesOptions,
    citiesOptionsByState,
  };
};

export const createSchema = (
  taxonomyData: Taxonomy,
  institutions: Institution[]
): FiltersSchema => {
  if (!taxonomyData) {
    throw new Error(
      'Catalog filters schema requires taxonomy data to be provided'
    );
  }

  const courseTypesOptions = mountCourseTypesOptions(taxonomyData.course_types);
  const knowledgeAreasOptions = mountKnowledgeAreasOptions(
    taxonomyData.knowledge_areas
  );
  const modalitiesOptions = mountModalitiesOptions(taxonomyData.modalities);
  const locationOptions = mountLocationOptions();

  const schema: FiltersSchema = [
    {
      id: 'filter_cloud',
      props: {
        filterIdsToDisplay: [
          'course_types',
          'feature_full',
          'knowledge_areas',
          'location',
          'modalities',
          'price',
          'terms',
        ],
        filterIdsToReset: [
          'course_types',
          'feature_full',
          'knowledge_areas',
          'location',
          'modalities',
          'page',
          'price',
          'terms',
        ],
      },
      saveValue: false,
      type: 'tagCloud',
    },
    {
      id: 'sort',
      props: {
        defaultValues: ['relevance'],
        options: [
          { label: 'Relevância', value: 'relevance' },
          { label: 'Menor preço', value: 'price_asc' },
          { label: 'Maior preço', value: 'price_desc' },
        ],
        placeholder: 'Ordenação',
        title: 'Ordenar por',
      },
      saveValue: true,
      type: 'inlineSelect',
    },
    {
      id: 'divider',
      props: {},
      saveValue: false,
      type: 'divider',
    },
    {
      id: 'terms',
      props: {
        placeholder: 'Termo buscado',
        title: 'Buscar por',
      },
      saveValue: true,
      type: 'text',
    },
    {
      id: 'feature_full',
      props: {
        label: 'Apenas cursos inclusos no plano',
      },
      saveValue: true,
      type: 'switch',
    },
    {
      id: 'course_types',
      props: {
        options: courseTypesOptions,
        title: 'Categorias',
      },
      saveValue: true,
      type: 'checkbox',
    },
    {
      id: 'institutions',
      props: {
        multiple: true,
        options: mountInstitutionsOptions(institutions),
        placeholder: 'Selecione',
        title: 'Instituição',
      },
      saveValue: true,
      type: 'select',
    },
    {
      id: 'modalities',
      props: {
        options: modalitiesOptions,
        title: 'Modalidade',
      },
      saveValue: true,
      type: 'checkbox',
    },
    {
      id: 'location',
      props: {
        multiple: false,
        options: locationOptions,
        title: 'Localização',
      },
      saveValue: true,
      type: 'location',
    },
    {
      id: 'price',
      props: {
        title: 'Valor máximo por mês',
      },
      saveValue: true,
      type: 'price',
    },
    {
      id: 'knowledge_areas',
      props: {
        options: knowledgeAreasOptions,
        title: 'Temas',
      },
      saveValue: true,
      type: 'checkbox',
    },
    {
      id: 'page',
      props: {
        defaultValues: [1],
      },
      saveValue: true,
      type: 'internalValue',
    },
  ];

  return schema;
};
