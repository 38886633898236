<div *ngIf="!curso" class="d-flex flex-column justify-content-center" style="height: 70vh">
  <app-loading></app-loading>
</div>

<div *ngIf="curso" class="container-fluid page-expand d-flex">
  <div class="row">
    <div class="col-lg-8 pb-3 registration-form pt-6 pl-3">
      <form [formGroup]="form">
        <p class="Subtitle-2 mt-3">
          {{ getTranslation('title') | translate }}
        </p>
        <p class="BodyText-1 mb-0">Inclua abaixo os seus dados completos.</p>
        <p class="BodyText-1">
          <span class="BodyText-2-SemiBold">ATENÇÃO:</span> esses dados serão
          utilizados para posterior efetivação de sua matrícula, assim as
          informações precisam ser válidas e permitir que seja localizado.
        </p>

        <div class="row">
          <div class="pt-4 col-12 col-md-8">
            <component-input
              label="Nome completo (sem abreviações)"
              class="mt-4"
              formControlName="nome"
              errorPosition="bottom"
              [error]="showErrorForField('nome')"
              errorMessage="Campo obrigatório"
              [tabOnEnter]="true"
            />
          </div>
          <div class="pt-4 col-12 col-md-4">
            <component-input
              label="Data de nascimento"
              type="date"
              class="mt-4"
              formControlName="birth_date"
              errorPosition="bottom"
              [error]="showErrorForField('birth_date')"
              [errorMessage]="errorForField('birth_date')"
              [tabOnEnter]="true"
            />
          </div>
        </div>
        <div class="row">
          <div class="pt-4 col-12 col-md-3">
            <component-input
              label="CPF"
              maxLength="14"
              placeholder="000.000.000-00"
              formControlName="cpf"
              errorPosition="bottom"
              (onBlur)="onCpfBlur($event)"
              [error]="showErrorForField('cpf')"
              errorMessage="CPF inválido"
              [tabOnEnter]="true"
            />
          </div>
          <div class="pt-4 col-12 col-md-3">
            <component-input
              label="Número de celular"
              placeholder="(00) 00000-0000"
              maxLength="15"
              formControlName="telefone"
              errorPosition="bottom"
              (onBlur)="onPhoneBlur($event)"
              [error]="showErrorForField('telefone')"
              errorMessage="Telefone inválido"
              [tabOnEnter]="true"
            />
          </div>
          <div class="pt-4 col-12 col-md-6">
            <div class="tooltip-container">
              <component-tooltip
                message="Insira o e-mail por onde você gostaria de receber as comunicações e atualizações sobre o processo de matrícula."
              ></component-tooltip>
            </div>
            <component-input
              label="Seu e-mail"
              formControlName="email"
              [error]="showErrorForField('email')"
              errorPosition="bottom"
              errorMessage="Email inválido"
              [tabOnEnter]="true"
            />
          </div>
        </div>

        <!-- Endereço  -->
        <br />
        <div formGroupName="full_address">
          <p class="Subtitle-3 mt-3">Endereço</p>
          <div class="row">
            <div class="pt-4 col-12 col-md-4">
              <component-input
                label="CEP"
                placeholder="00000-000"
                formControlName="postal_code"
                [error]="showErrorForField('full_address.postal_code')"
                errorPosition="bottom"
                (onBlur)="onPostalCodeBlur()"
                [errorMessage]="errorForField('address.postal_code')"
                [tabOnEnter]="true"
              />
            </div>
            <div class="pt-4 col-12 col-md-6">
              <component-input
                label="Cidade"
                formControlName="city"
                errorPosition="bottom"
                [disabled]="true"
              />
            </div>
            <div class="pt-4 col-12 col-md-2">
              <component-input
                label="Estado"
                formControlName="state"
                [disabled]="true"
                errorPosition="bottom"
              />
            </div>
          </div>
          <div class="row">
            <div class="pt-4 col-12 col-md-3">
              <component-input
                label="Rua/Avenida"
                formControlName="street"
                [error]="showErrorForField('full_address.street')"
                errorPosition="bottom"
                errorMessage="Rua inválida"
                [tabOnEnter]="true"
              />
            </div>
            <div class="pt-4 col-12 col-md-3">
              <component-input
                label="Bairro"
                formControlName="neighborhood"
                [error]="showErrorForField('full_address.neighborhood')"
                errorPosition="bottom"
                errorMessage="Bairro inválido"
                [tabOnEnter]="true"
              />
            </div>
            <div class="pt-4 col-12 col-md-2">
              <component-input
                label="Número"
                formControlName="number"
                [error]="showErrorForField('full_address.number')"
                errorPosition="bottom"
                errorMessage="Número inválido"
                [tabOnEnter]="true"
              />
            </div>
            <div class="pt-4 col-12 col-md-4">
              <component-input
                label="Complemento (opcional)"
                placeholder=""
                formControlName="complement"
                errorPosition="bottom"
                [tabOnEnter]="true"
              />
            </div>
          </div>
          <br />
        </div>
        <!-- /Endereço  -->

        <p class="BodyText-1">
          <span class="BodyText-2-SemiBold">Observações:</span> O atendimento é
          realizado via whatsapp no telefone informado, e as informações de sua
          inscrição serão enviadas ao e-mail informado nesta tela.
        </p>
      </form>
    </div>

    <div class="col-lg-4 pl-2 py-3">
      <app-curso-card
        [detailsButton]="false"
        [isClickable]="false"
        [curso]="curso"
        (onClick)="onSubmit()"
      >
        <div
          *ngIf="!couponSection"
          class="d-flex flex-wrap mt-3 justify-content-center"
        >
          <div class="d-flex align-self-center">
            <component-botao
              *ngIf="!appliedCoupon"
              class="mb-3 d-flex col-12"
              (onClick)="couponSection = true"
              type="text"
              >{{ getTranslation('coupon') | translate }}</component-botao
            >
          </div>
          <div *ngIf="appliedCoupon" class="d-flex flex-column col-12">
            <p class="FeedbackText mb-1 align-self-center">
              {{ getTranslation('appliedCoupon') | translate
              }}{{ getCouponDiscount(appliedCoupon.discount)
              }}{{ getTranslation('appliedCoupon2') | translate }}
            </p>
            <div class="d-flex edupass-success align-self-center mb-4">
              <span class="material-icons-outlined notranslate"
                >check_circle_outline</span
              >
              <p class="BodyText-1 ml-1 edupass-success">
                {{ appliedCoupon.code }}
              </p>
            </div>
          </div>
        </div>
        <div
          *ngIf="couponSection"
          class="buttons d-flex mt-3 mb-6 align-items-end"
        >
          <component-input
            [(ngModel)]="coupon"
            [placeholder]="getTranslation('insertCoupon')"
            class="w-100"
            [error]="couponError"
            errorPosition="bottom"
            errorMessage="{{ getTranslation('invalidCoupon') | translate }}"
          />

          <component-botao
            type="secondary"
            class="mx-2"
            (onClick)="couponSection = false"
            [disabled]="checkingCoupon"
            >Cancelar</component-botao
          >
          <component-botao
            type="primary"
            (onClick)="checkCoupon()"
            [disabled]="checkingCoupon"
            >Enviar</component-botao
          >
        </div>
        <div class="col-12 d-flex flex-column align-items-center mb-4">
          <component-botao
            [disabled]="!validateForm() || user.isTrial()"
            type="primary"
            icon="edit"
            class="d-flex align-self-center"
            (onClick)="onSubmit()"
          >
            Finalizar inscrição
          </component-botao>
          <div
            *ngIf="user.isTrial()"
            class="d-flex align-items-center mt-2 button-width"
          >
            <span class="material-icons-outlined notranslate">info</span>
            <p class="BodyText-2 d-flex mb-0 ml-2">
              {{ getTranslation('trialUnavailable') | translate }}
            </p>
          </div>
        </div>
      </app-curso-card>
    </div>

    <component-modal *ngIf="showCongratiolationsModal">
      <div class="px-4 py-2 registration-modal d-flex flex-column text-center">
        <p class="Subtitle-2">
          {{ getTranslation('congratulationTitle') | translate }}
        </p>

        <img
          [src]="getSvg('journey.svg')"
          class="my-2 d-flex flex-column align-self-center"
        />

        <div>
          <p class="BodyText-1">
            "Você acabou de validar o seu desconto de
            <span class="BodyText-2-SemiBold">{{ getDesconto() }}% </span>
            no curso
            <span class="BodyText-2-SemiBold">
              {{ curso?.curso_detalhe?.curso?.curso }}
            </span>
            da
            <span class="BodyText-2-SemiBold">
              {{ curso.campus.instituicao.instituicao }}
            </span>
          </p>

          <p class="BodyText-1">
            Agora é só aguardar que em breve uma pessoa da equipe de atendimento
            irá entrar em contato com você para sinalizar os próximos passos.
          </p>

          <p class="BodyText-1">
            {{ getTranslation('satisfactionSurvey') | translate }}
          </p>
          <a
            href="https://forms.gle/vWeGobvGYRBLwoMo8"
            target="_blank"
            class="survey-link BodyText-1"
          >
            {{ getTranslation('surveyLink') | translate }}
          </a>
        </div>
        <div class="row justify-content-center mt-3">
          <component-botao (click)="closeCongratiolationsModal()">
            Fechar
          </component-botao>
        </div>
      </div>
    </component-modal>
  </div>
</div>
