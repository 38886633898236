import * as React from 'react';
import { SnackbarProvider } from 'notistack';

import { NotistackToast } from '@/react/components/notification';

export const NotificationProvider = ({ children }) => {
  return (
    <SnackbarProvider
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      autoHideDuration={7000}
      Components={{
        toast: NotistackToast,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};
