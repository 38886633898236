import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Avatar from '@mui/material/Avatar';

export const buildInitials = (name = '') => {
  const names = name.split(' ');
  const firstName = names.at(0);
  const lastName = names.length > 1 ? names.at(-1) : ' ';

  return `${firstName[0]}${lastName[0]}`;
};

const UserAvatar = ({ imageSource, user, variant }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const variants = {
    navbar: {
      fontSize: isMobile
        ? theme.typography.caption.fontSize
        : theme.typography.subtitle3.fontSize,
      size: isMobile ? '32px' : '40px',
    },
    menu: {
      fontSize: theme.typography.subtitle3.fontSize,
      size: '40px',
    },
  };

  const { fontSize, size } = variants[variant];

  const StyledAvatar = styled(Avatar)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    fontSize,
    height: size,
    textTransform: 'uppercase',
    width: size,
  }));

  return (
    <StyledAvatar alt={user.nome} src={imageSource}>
      {buildInitials(user.nome)}
    </StyledAvatar>
  );
};

export default UserAvatar;
