export const formatDate = (date) => {
  if (!date) {
    return '';
  }

  const newDate = new Date(date);

  if (isNaN(newDate.getTime())) {
    return '';
  }

  return new Intl.DateTimeFormat('pt-BR').format(newDate);
};
