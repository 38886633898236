<div
  class="banner row align-items-center flex-wrap-reverse"
  [ngStyle]="{ 'background-image': getBannerBg() }"
>
  <div class="col-md-6">
    <h1 class="Headline-1-Bold">{{ lmsName }}</h1>
  </div>

  <div class="col-md-6 justify-content-md-end mb-md-0 d-flex">
    <img class="corporate-logo" [src]="logo" alt="Logo empresa" />
  </div>
</div>
