import { AbstractControl, ValidationErrors } from '@angular/forms';

export function extractNumber(value: string): string {
  return value?.match(/\d+/gi)?.join('') || '';
}

export function numberValidator(
  control: AbstractControl
): ValidationErrors | null {
  const numberValue = parseInt(extractNumber(control.value));

  const isValid = !isNaN(numberValue);
  return isValid ? null : { number: { value: control.value } };
}
