import { Grid, Typography } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import { Plan } from '@/react/data/idp/interfaces';

type TPlanItem = {
  plan: Plan;
  children?: ReactNode
};

export const planItemLinkTo = (plan: Plan): string => {
  return `/pdi/planos/${plan.id}`;
};

export const PlanItem:FC<TPlanItem> = ({ plan, children }) => {
  return (
    <Grid padding="20px" gap="20px" borderRadius="5px" border="1px solid #DEDEDE" bgcolor="white" xs={12} item>
      <Typography color="black" fontWeight={500} marginBottom="20px">
        {plan.title}
      </Typography>

      <Typography color="black" fontWeight={500} marginBottom="20px">
        {plan.durationInMonths} meses
      </Typography>

      {children}
    </Grid>
  );
};
