import { DbCourse } from './course.model';

type MediaUser = { name: string; email: string; cpf: string };
export class DbReportCourse {
  id: string;
  user_id: number;
  company_id: number;
  course_id: string;
  current_lesson: string;
  progress: number;
  is_completed: boolean;
  finished_time: string;
  is_approved: boolean;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  course: DbCourse;
  user: MediaUser;
}

export class CourseProgress {
  id: string;
  userName: string;
  courseName: string;
  userEmail: string;
  userCpf: string;
  progress: number;
  startDate: string;
  endDate: string;
  duration: string;

  static getCourseProgressFromDb(dbReport: DbReportCourse): CourseProgress {
    const courseProgress = new CourseProgress();

    courseProgress.id = dbReport.id;
    courseProgress.userName = dbReport.user.name;
    courseProgress.userCpf = dbReport.user.cpf;
    courseProgress.userEmail = dbReport.user.email;
    courseProgress.courseName = dbReport.course.name;
    courseProgress.progress = dbReport.progress;
    courseProgress.startDate = dbReport.created_at;
    courseProgress.endDate = dbReport.finished_time;
    courseProgress.duration = dbReport.course.duration;

    return courseProgress;
  }
}
