import React from 'react';
import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';
import { useGtm } from './state/useGtm';

const CardLink = styled(Link)(() => ({
  '&:hover': {
    color: 'inherit',
    textDecoration: 'none',
  },
}));

export default CardLink;
