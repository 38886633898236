import { Component } from '@angular/core';
import type { ComponentProps } from 'react';

import ReactRegisterEmployees from '@/react/components/employer/manage/register';

@Component({
  selector: 'component-employees-register',
  templateUrl: './register-employees.component.html',
})
export class RegisterEmployeesComponent {
  ReactRegisterEmployees = ReactRegisterEmployees;
  reactRegisterEmployeesProps: ComponentProps<typeof ReactRegisterEmployees> =
    {};
}
