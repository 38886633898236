import { formatMoney } from '@/utils/money-parser';
import { Coupon } from '@/models-new/coupon.model';

import { CONST } from '@/constants';
import { Order } from '@/models/order.model';
import { CursoHorario } from '@/models/curso-horario.model';
import { SellableType } from '@/models/sellable_type.model';
import { Categoria } from '@/models/categoria';
import { formatDate } from '@/utils/date-parser';
import { FeatureAfiiliation } from './feature_affiliation.model';

enum PaymentType {
  MONTHLY = 'MONTHLY',
  INSTALLMENTS = 'INSTALLMENTS',
  FULL_PRICE = 'FULL_PRICE',
}

export class CourseCard {
  aboutInstitution: string;
  city: string;
  campusId: number;
  campus: string;
  cardHighlight: boolean;
  cardSubstitle: string;
  categories: Categoria[] | Partial<Categoria>[];
  categoryDetailed: string;
  chargeType: string;
  courseDetailId: number;
  details: string;
  discount: number;
  duration: string;
  featureAffiliations: FeatureAfiiliation[];
  hasBoletoPaymentOption: boolean;
  hasCardPaymentOption: boolean;
  hasPixPaymentOption: boolean;
  hasTicketPaymentOption: boolean;
  hasCertificate: boolean;
  id: number;
  instalments: number;
  institution: string;
  institutionId: number;
  institutionImage: string;
  institutionColor: string;
  isLeroyUser: boolean = false;
  lastUpdate: string;
  modality: string;
  modalityId: number;
  isMonthlyPrice: boolean;
  name: string;
  orders: any[];
  originalPrice: number;
  period: string;
  priceUponRequest: boolean;
  marketable: boolean;
  recurrence: string;
  scolarshipTerms: string;
  sellableType: SellableType;
  state: string;
  turn: string;
  workload: string;
  durationType: string;
  durationAmount: number;

  coupon: Coupon;

  getCategory(dbCourse: any) {
    const getFromType: () => Categoria[] = () => {
      return [
        {
          categoria: dbCourse.categoria,
          type: dbCourse.type,
          imagem: '',
        },
      ];
    };

    return dbCourse.curso_detalhe?.curso?.categorias || getFromType();
  }

  constructor(dbCourse: Partial<CursoHorario>, isLeroyUser: boolean) {
    this.id = dbCourse.id;
    this.originalPrice = dbCourse.valor_original;
    this.discount = dbCourse.desconto;
    this.name = dbCourse?.curso_detalhe?.name;
    this.institution = dbCourse?.campus?.instituicao?.instituicao;
    this.institutionImage = dbCourse?.campus?.instituicao?.logo_url;
    this.institutionColor =
      dbCourse?.campus?.instituicao?.logo_background_color;
    this.scolarshipTerms = dbCourse?.campus?.instituicao?.scolarship_terms;
    this.details = dbCourse?.curso_detalhe?.descricao;
    this.courseDetailId = dbCourse.curso_detalhe?.id;
    this.instalments = dbCourse?.parcelas;
    this.orders = dbCourse?.orders;
    this.sellableType = dbCourse.campus?.instituicao?.sellable_type;
    this.modality = dbCourse.modalidade?.modalidade;
    this.modalityId = dbCourse.modalidade?.id;
    this.duration = dbCourse.duration;
    this.state = dbCourse.campus?.uf;
    this.city = dbCourse.campus?.cidade;
    this.campus = dbCourse.campus?.campus;
    this.campusId = dbCourse.campus?.id;
    this.period = dbCourse.turno;
    this.priceUponRequest = dbCourse.price_upon_request;
    this.hasBoletoPaymentOption = dbCourse.payment_boleto;
    this.hasCardPaymentOption = dbCourse.payment_credit_card;
    this.hasPixPaymentOption = dbCourse.payment_pix;
    this.isMonthlyPrice = dbCourse.mensalidade;
    this.hasCertificate = dbCourse.certificate;
    this.aboutInstitution = dbCourse.campus?.instituicao?.sobre;
    this.categories = this.getCategory(dbCourse);
    this.isLeroyUser = isLeroyUser;
    this.workload = dbCourse.carga_horaria;
    this.cardHighlight = dbCourse.edupass_indica;
    this.cardSubstitle = dbCourse.marketing_description;
    this.marketable = dbCourse.marketable;
    this.lastUpdate = formatDate(dbCourse.updated_at);
    this.categoryDetailed = dbCourse.curso_detalhe?.categoria_especifica;
    this.turn = dbCourse.turno;
    this.durationType = dbCourse.duracao_tipo;
    this.durationAmount = dbCourse.duracao;
    this.featureAffiliations = dbCourse['FeatureAffiliations'];
  }

  static getCourseFromDb(
    dbCourse: Partial<CursoHorario>,
    isLeroyUser: boolean
  ): CourseCard {
    return new CourseCard(dbCourse, isLeroyUser);
  }

  applyCoupon(coupon: Coupon) {
    this.coupon = coupon;
  }

  getOriginalInstallmentPrice(): number {
    if (this.getPaymentType() === PaymentType.MONTHLY) {
      return this.originalPrice;
    }
    return this.originalPrice / this.instalments;
  }

  getFormattedOriginalInstallmentPrice(): string {
    return formatMoney(this.getOriginalInstallmentPrice());
  }

  getInstallmentPrice({
    ignoreSubsidy = false,
    ignoreCoupon = false,
  }: {
    ignoreSubsidy?: boolean;
    ignoreCoupon?: boolean;
  } = {}) {
    return (
      ((100 - this.calculatedDiscount({ ignoreSubsidy, ignoreCoupon })) / 100) *
      this.getOriginalInstallmentPrice()
    );
  }

  getFormattedInstallmentPrice(ignoreSubsidy: boolean = false): string {
    return formatMoney(this.getInstallmentPrice({ ignoreSubsidy }));
  }

  getOriginalFullPrice(): number {
    return this.getOriginalInstallmentPrice() * this.instalments;
  }

  getFormattedOriginalFullPrice(): string {
    return formatMoney(this.getOriginalFullPrice());
  }

  getFullPrice({
    ignoreSubsidy = false,
    ignoreCoupon = false,
  }: {
    ignoreSubsidy?: boolean;
    ignoreCoupon?: boolean;
  } = {}): number {
    return (
      this.getInstallmentPrice({ ignoreSubsidy, ignoreCoupon }) *
      this.instalments
    );
  }

  getFormattedFullPrice({
    ignoreSubsidy = false,
    ignoreCoupon = false,
  }: {
    ignoreSubsidy?: boolean;
    ignoreCoupon?: boolean;
  } = {}): string {
    return formatMoney(this.getFullPrice({ ignoreSubsidy, ignoreCoupon }));
  }

  hasCheckout(): boolean {
    return !!this.sellableType;
  }

  private getCouponDiscount(ignoreCoupon: boolean = false): number {
    if (this.coupon && !ignoreCoupon) {
      return this.coupon.isCumulative
        ? this.discount + this.coupon.discount
        : 100 - ((100 - this.discount) * (100 - this.coupon.discount)) / 100;
    }

    return this.discount;
  }

  private getMonthlyDuration(): number {
    const durationMap = {
      Ano: () => {
        return Math.floor(this.durationAmount * 12);
      },
      Semestre: () => {
        return Math.floor(this.durationAmount * 6);
      },
      Trimestre: () => {
        return Math.floor(this.durationAmount * 3);
      },
      Mês: () => {
        return Math.floor(this.durationAmount);
      },
    };

    const calculatedDuration = durationMap[this.durationType]
      ? durationMap[this.durationType]()
      : 1;

    return calculatedDuration;
  }

  private getLeroyLimit(): number {
    const baseLimit = CONST.MAXIMUM_LEROY_DISCOUNT_IN_BRL;
    const monthlyDuration = this.getMonthlyDuration();
    return this.isMonthlyPrice ? baseLimit : monthlyDuration * baseLimit;
  }

  private getLeroyDiscount(ignoreCoupon: boolean = false): number {
    const baseDiscount = this.getCouponDiscount(ignoreCoupon);

    const coursePrice = ((100 - baseDiscount) / 100) * this.originalPrice;

    const leroyDiscount = Math.min(
      this.getLeroyLimit(),
      coursePrice * CONST.LEROY_SUBSIDY_PERCENTAGE
    );

    const leroyPrice = coursePrice - leroyDiscount;

    return (1 - leroyPrice / this.originalPrice) * 100;
  }

  getEconomy(ignoreSubsidy: boolean = false): number {
    return this.getOriginalFullPrice() - this.getFullPrice({ ignoreSubsidy });
  }

  getFormattedEconomy(ignoreSubsidy: boolean = false): string {
    return formatMoney(this.getEconomy(ignoreSubsidy));
  }

  calculatedDiscount({
    ignoreCoupon = false,
    ignoreSubsidy = false,
  }: {
    ignoreSubsidy?: boolean;
    ignoreCoupon?: boolean;
  } = {}) {
    const baseDiscount = this.getCouponDiscount(ignoreCoupon);

    if (this.isLeroyUser && !ignoreSubsidy) {
      return this.getLeroyDiscount(ignoreCoupon);
    }

    return baseDiscount;
  }

  getDiscount(ignoreSubsidy: boolean = false): number {
    return Math.round(this.calculatedDiscount({ ignoreSubsidy }));
  }

  getLeroyDiscountValue(): number {
    return this.getFullPrice({ ignoreSubsidy: true }) - this.getFullPrice();
  }

  isAlreadyBoutght(): boolean {
    return (
      this.orders.length > 0 &&
      this.orders[this.orders.length - 1].status === Order.STATUS.PAID
    );
  }

  isEduplay(): boolean {
    return this.institution === 'Eduplay';
  }

  isGraduation(): boolean {
    return !!this.categories.find((_category) => {
      return (
        _category.type === 'graduacao' || _category.type === 'pos_graduacao'
      );
    });
  }

  getImage(): string {
    return this.institutionImage;
  }

  getInstitutionColor(): string {
    return this.institutionColor;
  }

  getPaymentType(): PaymentType {
    if (this.isMonthlyPrice) {
      return PaymentType.MONTHLY;
    }
    return this.instalments > 1
      ? PaymentType.INSTALLMENTS
      : PaymentType.FULL_PRICE;
  }

  isFree() {
    return this.getFullPrice() === 0;
  }
}
