import * as React from 'react';
import Alert, { AlertProps } from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

export interface ToastProps {
  content: React.ReactNode | string;
  onClose: () => void;
  title?: React.ReactNode | string;
  type: AlertProps['severity'];
}

export const Toast = React.forwardRef<HTMLDivElement, ToastProps>(
  (props, ref) => {
    const { content, onClose, title, type } = props;

    return (
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            onClick={onClose}
            sx={{ p: 0.5 }}
          >
            <CloseIcon />
          </IconButton>
        }
        ref={ref}
        severity={type}
        sx={{ width: '100%' }}
      >
        {title && <AlertTitle>{title}</AlertTitle>}
        {content}
      </Alert>
    );
  }
);
