<app-refund-receipt-navigation menu="report"></app-refund-receipt-navigation>

<div class="container">
  <div class="row mt-3">
    <div class="col-12">
      <div class="d-flex">
        <p class="descricao">
          {{ getTranslation('vejaReembolso') | translate }}
        </p>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-4">
      <component-input
        (inputed)="onSearchColaborador($event)"
        label="{{ getTranslation('labelBusqueColaborador') | translate }}"
        [value]="colaboradorFilter"
      ></component-input>
    </div>
    <div class="col-md-4">
      <component-select
        [value]="statusFilter"
        [options]="statusOptions"
        label="{{ getTranslation('labelStatus') | translate }}"
        (valueChange)="onStatusSelected($event)"
      ></component-select>
    </div>
    <div class="col-md-4">
      <component-date-picker
        #datepicker
        label="{{ getTranslation('labelPeriodo') | translate }}"
        (onChange)="onDateSelected($event)"
      ></component-date-picker>
    </div>
  </div>
  <div class="row mt-2 p-2">
    <div class="p-2">
      <component-botao (click)="onExportar()" type="primary">
        {{ getTranslation('btnExportar') | translate }}
      </component-botao>
    </div>
    <div class="p-2">
      <component-botao
        [disabled]="emptyFilters"
        (click)="clearFilters()"
        type="secondary"
      >
        {{ getTranslation('btnClearFilters') | translate }}
      </component-botao>
    </div>
  </div>

  <div class="row">
    <div class="w-100">
      <app-table-default
        *ngIf="!isLoading"
        [tablePages]="tablePages"
        [data]="refunds"
        [hideSaveButton]="true"
      ></app-table-default>

      <component-skeleton-card *ngIf="isLoading"></component-skeleton-card>
    </div>
  </div>
</div>
