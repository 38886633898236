import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { FilterTagCloud } from '@/react/components/filters/components/filter-tag-cloud';
import { useFilters } from '@/react/components/filters/state';
import { AppliedFiltersCounter } from '../applied-filters-counter.component';
import { HeaderTitle } from '../header-title.component';

const Styled = {
  Button: styled(Button)`
    white-space: nowrap;
    min-width: auto;
  `,
};

export const FiltersHeaderMobile = ({ handleOpenFilters }) => {
  const { state } = useFilters();

  return (
    <Card
      elevation={2}
      sx={({ spacing }) => ({
        marginBottom: spacing(3),
        position: 'sticky',
        margin: '-1px',
        top: '-1px',
        zIndex: 1,
      })}
      square
    >
      <Container fixed maxWidth="xl">
        <Box py={1.5}>
          <Stack spacing={1.5}>
            <Stack
              direction="row"
              sx={{ alignItems: 'center', justifyContent: 'space-between' }}
            >
              <HeaderTitle />
              <Styled.Button
                variant="contained"
                color="primary"
                onClick={() => handleOpenFilters(true)}
              >
                Filtrar <AppliedFiltersCounter />
              </Styled.Button>
            </Stack>
            <FilterTagCloud
              filterIdsToDisplay={
                state['filter_cloud'].props.filterIdsToDisplay
              }
              filterIdsToReset={state['filter_cloud'].props.filterIdsToReset}
              type="tagCloud"
              scrollable
            />
          </Stack>
        </Box>
      </Container>
    </Card>
  );
};
