import * as React from 'react';
import Box from '@mui/material/Box';

import { HeaderTitle } from '../header-title.component';

export const FiltersDesktopHeader = () => {
  return (
    <Box mb={2}>
      <HeaderTitle />
    </Box>
  );
};
