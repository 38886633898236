import { ArrowForward } from '@mui/icons-material';
import {
  Button,
  Grid,
  InputLabel,
  TextField,
  Typography,
  styled,
  InputAdornment,
  IconButton,
  Link,
} from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react';

import { constants } from '@/react/constants';
import CONST from '@/constants';

import { isValidEmail } from '@/react/utils/string';

import { useForm, Controller, type SubmitHandler } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { cpfMask } from '@/utils/form-mask/cpf';

const containsOnlyNumbers = (input: string): boolean => {
  const alphanumericString = input.replace(/[^a-zA-Z0-9]/g, '');
  return (
    /^[0-9]+$/.test(alphanumericString) && alphanumericString.length === 11
  );
};

const loginValidationSchema = z
  .object({
    login: z.string(),
  })
  .superRefine((data, ctx) => {
    if (!data.login || data.login.length < 1) {
      ctx.addIssue({
        path: ['login'],
        code: z.ZodIssueCode.custom,
        message: 'Digite seu e-mail.',
      });
      return;
    }

    if (!containsOnlyNumbers(data.login) && data.login.indexOf('@') === -1) {
      ctx.addIssue({
        path: ['login'],
        code: z.ZodIssueCode.custom,
        message: 'Email ou CPF inválidos.',
      });
      return;
    }

    if (!isValidEmail(data.login) && data.login.indexOf('@') !== -1) {
      ctx.addIssue({
        path: ['login'],
        code: z.ZodIssueCode.custom,
        message: 'E-mail inválido.',
      });
      return;
    }
  });

type LoginValidationData = z.infer<typeof loginValidationSchema>;

const StyledText = styled(Typography)(({ theme }) => ({
  fontSize: 20,
}));

export type EmailInputProps = {
  nextStep: (login: string) => void;
};

const fetchSsoAudit = async (login) => {
  const response = await axios.get(
    `${constants.API_ENDPOINT}/auth/check-email?${new URLSearchParams({
      login,
    })}`
  );

  return response.data;
};

export const EmailInput = ({ nextStep }: EmailInputProps) => {
  const [isCheckingLogin, setIsCheckingLogin] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    const resizeButton = () => {
      const inputHeight = inputRef.current?.offsetHeight;
      const button = document.querySelector(
        '.submitButton'
      ) as HTMLElement | null;
      if (inputHeight && button) {
        button.style.height = `${inputHeight}px`;
      }
    };
    window.addEventListener('resize', resizeButton);
    resizeButton();
    return () => window.removeEventListener('resize', resizeButton);
  }, []);

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      login: '',
    },
    resolver: zodResolver(loginValidationSchema),
  });

  const checkEmail = async (login) => {
    setIsCheckingLogin(true);

    if (login.trim() !== '') {
      const response = await fetchSsoAudit(login);

      if (response.data.login && response.data.sso?.ssoProvider) {
        const sso_provider = response.data.sso.value;
        localStorage.setItem(
          'redirect_after_login',
          window.location.href.split('?')[0]
        );
        window.location.href = `${CONST.LEGACY_BACKEND_URL}/sso/${sso_provider}/signin`;
        return;
      }

      if (!response.data.login) {
        setError('login', {
          type: 'server',
          message: response.data.message,
        });

        setIsCheckingLogin(false);
        return;
      }

      setIsCheckingLogin(false);
      nextStep(login);
    }

    setIsCheckingLogin(false);
  };

  const formatCPF = ({ cpf }) => {
    return cpfMask(cpf);
  };

  const handleInputChange = (e) => {
    let value = e.target.value;
    const containsValidCharacters = /^[\d.-]+$/.test(value);

    if (containsValidCharacters) {
      value = formatCPF({ cpf: value });
    }

    e.target.value = value;
  };

  const onSubmit: SubmitHandler<LoginValidationData> = (event) => {
    checkEmail(event.login);
  };

  const handleForgotPasswordClick = () => {
    window.location.assign('/login/forgot-password');
  };

  return (
    <>
      <StyledText>Boas-vindas à sua plataforma de desenvolvimento.</StyledText>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid item>
          <InputLabel htmlFor="login">
            <Typography variant="h6">Faça o seu login</Typography>
          </InputLabel>

          <Grid container columnGap={2}>
            <Grid item flexGrow={1}>
              <Controller
                name="login"
                control={control}
                render={({ field }) => (
                  <TextField
                    inputRef={inputRef}
                    placeholder="Entre com o seu e-mail ou CPF"
                    variant="outlined"
                    error={Boolean(errors.login)}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {Boolean(errors.login) ? (
                            <IconButton edge="end">
                              <ErrorOutline color="error" />
                            </IconButton>
                          ) : null}
                        </InputAdornment>
                      ),
                    }}
                    helperText={errors?.login?.message}
                    {...field}
                    onChange={(e) => {
                      handleInputChange(e);
                      field.onChange(e.target.value);
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item>
              <Button
                sx={{ height: '100%' }}
                className="submitButton"
                type="submit"
                disabled={isCheckingLogin}
                variant="contained"
              >
                <ArrowForward />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Link
            onClick={handleForgotPasswordClick}
            underline="none"
            variant="body3"
            sx={{
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'none',
              },
            }}
          >
            Esqueci minha senha
          </Link>
        </Grid>
      </form>
    </>
  );
};
