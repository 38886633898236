import React, { useState } from 'react';
import { CheckoutConfig, PriceTypes } from '@/react/data/catalog/interfaces';
import { formatMoney } from '@/utils/money-parser';
import { Box, Button, Link, Stack, Typography } from '@mui/material';

import { useProductPage } from '../state';
import { ProductPageAsyncResources } from '../state/interfaces';
import CONST from '@/constants';
import { CourseClient } from '@/react/data/course/course-client';
import { useGtm } from '@/react/components/state/useGtm';

const getChargeValue = (
  checkoutConfig: CheckoutConfig,
  price: number
): string | null => {
  const { has_extra_payment, charging_amount, charging_percentage } =
    checkoutConfig;

  if (!has_extra_payment) return null;

  if (charging_amount) return formatMoney(charging_amount);
  if (charging_percentage)
    return formatMoney((price * charging_percentage) / 100);
};

export const OfferDetailsEnrollment = ({ enable }) => {
  const { isSingleOffer, loading, offer, institution, course } = useProductPage();
  const [loadingEnrollment, setLoadingEnrollment] = useState(false);
  const gtm = useGtm();

  const checkoutConfig = offer?.checkout_config;
  const pricing = offer?.pricing_description;

  const enableContinue = React.useMemo(
    () => offer && !loading.includes(ProductPageAsyncResources.offer) && enable,
    [enable, isSingleOffer, loading, offer]
  );

  const eduplayEnrollment = async () => {
    setLoadingEnrollment(true);

    try {
      const result = await CourseClient.enroll({ offerId: offer.id });
      if (result.message === 'ok') {
        window.location.assign(`/eduplay/${offer.id}/curso`);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const isFreeEduplay = () => {
    const isEduplay = institution?.id === CONST.EDUPLAY_ID;
    const isFree = pricing?.discount_percentage === 100;

    if (isEduplay && isFree && offer.is_full_included) {
      return true
    }

    return false
  }

  const handleClick = () => {
    gtm.trackEnrollmentEvent(course, offer, institution)

    if (isFreeEduplay()) {
      return eduplayEnrollment();
    }

    const route = offer?.id ? `/cursos/${offer.id}/inscricao` : null;
    if (!route) return;

    window.location.assign(route);
  };

  const enrollmentText = isFreeEduplay() ? 'Acessar o curso' : 'Iniciar matrícula';

  const button = (
    <Button
      color="primary"
      disabled={!enableContinue || loadingEnrollment}
      fullWidth
      onClick={handleClick}
      variant="contained"
      size="large"
    >
      {loadingEnrollment? "Carregando..." : enrollmentText}
    </Button>
  );

  const Wrapper = ({ children }) => (
    <Box
      p={2}
      sx={(theme) => ({ backgroundColor: theme.palette.neutral['200'] })}
    >
      {children}
    </Box>
  );

  const salePrice = pricing?.sale_price;

  if (checkoutConfig && pricing?.type === PriceTypes.tuition && salePrice !== 0) {
    const chargeValue = getChargeValue(checkoutConfig, salePrice);
    
    const content = checkoutConfig?.has_extra_payment
      ? {
          title: 'Taxa de matrícula',
          description: `
            Para iniciar o processo de matrícula, será cobrada uma taxa 
            de ${chargeValue}. Esse valor não é descontado das mensalidades do curso.`,
          refund: `
            Caso desista de realizar a matrícula, você pode solicitar a devolução da 
            taxa de matrícula.`,
          refundLink: `${CONST.GALENA_SERVICE_URL}/articles/25040094299917-Qual-o-prazo-para-solicitar-um-cancelamento`,
          refundLinkTitle: 'Veja os prazos aqui',
        }
      : {
          title: 'Matrícula',
          description: `
            Para iniciar o processo de matrícula você paga o valor da primeira mensalidade do semestre agora. 
            A partir da segunda mensalidade, os pagamentos são feitos diretamente para a instituição de ensino.`,
          refund: `
            Caso desista de realizar a matrícula, você pode solicitar a devolução da primeira mensalidade, conforme
          `,
          refundLink: `${CONST.GALENA_SERVICE_URL}/articles/25040094299917-Qual-o-prazo-para-solicitar-um-cancelamento`,
          refundLinkTitle: 'condições e prazos',
        };

    return (
      <Wrapper>
        <Stack spacing={2}>
          <span>
            <Typography
              component="div"
              variant="body3"
              sx={(theme) => ({
                fontWeight: theme.typography.fontWeightBold,
              })}
            >
              {content.title}
            </Typography>
            <Typography component="div" variant="body3">
              {chargeValue}
            </Typography>
          </span>

          <>
            <Typography component="div" variant="body3">
              {content.description}
            </Typography>
            <Typography component="div" variant="body3">
              {content.refund}
              <Link href={content.refundLink} target="_blank">
                {content.refundLinkTitle}
              </Link>.
            </Typography>
          </>

          <div>{button}</div>
        </Stack>
      </Wrapper>
    );
  }

  return button;
};
