import React from "react";
import { useRefundStatus } from "@/react/components/refund/utils/useRefundStatus";
import { useRefundRequest } from '@/react/components/refund/refund-request/state/useRefundRequest';
import ExtensiveOverview from "./extensive-overview/extensive-overview.component";

export const RefundDetails = () => {
  const { resourceApproval } = useRefundRequest();

  const { mappedStatus } = useRefundStatus(resourceApproval);

  if (!resourceApproval) return null;

  return (
    <>
      { ['waitingForRevisionStepTwo','canceledResourceApprovalStepOne', 'pendingDocumentationStepThree', 'waitingForDocumentationRevisionStepFour', 'declinedRefundRequestStepFour', 'needsRefundRequestRevisionStepFour', 'pendingRefundPaymentStepFive', 'paidRefundRequestStepFive'].includes(mappedStatus) && <ExtensiveOverview /> }
    </>
  );
};
