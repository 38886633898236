<div class="card-container">
  <div class="card">
    <img class="category-img" [src]="getImage()" alt="Logo Tópico" />
    <div class="card-body">
      <p class="card-title">{{ categoria.name }}</p>
      <p class="card-text">
        {{ categoria.countCourses }} curso{{
          categoria.countCourses === 1 ? '' : 's'
        }}
      </p>
    </div>
  </div>
</div>
