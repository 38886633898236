import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import SendingReceiptsForm from './sending-receipts-form/sending-receipts-form.component';
import SendingReceiptsSummary from './sending-receipts-summary/sending-receipts-summary.component';
import { State } from '@/react/components/refund/wizard/state/interfaces';
import { WizardStepIds } from '@/react/components/refund/refund-request/description-step/interfaces';
import { WizardSteps } from '@/react/components/refund/wizard/interfaces';
import { WizardProvider } from '@/react/components/refund/wizard/state/context';
import Wizard from '@/react/components/refund/wizard/wizard.component';

const Title = ({ children }) => {
  return (
    <Box>
      <Typography variant="h6">{children}</Typography>
    </Box>
  );
};

const createCourseSteps = (): WizardSteps => {
  return {
    [WizardStepIds.SENDING_RECEIPTS_FORM]: {
      component: () => <SendingReceiptsForm />,
    },
    [WizardStepIds.SENDING_RECEIPTS_SUMMARY]: {
      component: SendingReceiptsSummary,
      titleElement: <Title>Cálculo do reembolso</Title>,
    },
  };
};

const initialState = {
  currentStepId: WizardStepIds.SENDING_RECEIPTS_FORM,
  values: {},
} as State;

export const SendingReceiptsStep = ({ steps }: { steps: WizardSteps }) => {
  return <Wizard steps={steps} />;
};

const SendingReceiptsStepWithContext = () => {
  const courseSteps = createCourseSteps();

  return (
    <WizardProvider initialState={initialState}>
      <SendingReceiptsStep steps={courseSteps} />
    </WizardProvider>
  );
};

export default SendingReceiptsStepWithContext;
