type DbCoupon = {
  discount: string;
  code: string;
  is_cumulative: boolean;
};

export class Coupon {
  discount: number;
  code: string;
  isCumulative: boolean;
  discount_amount?: number;

  static getFromDb(dbCoupon: DbCoupon): Coupon {
    const coupon = new Coupon();
    coupon.discount = parseFloat(dbCoupon.discount);
    coupon.code = dbCoupon.code;
    coupon.isCumulative = dbCoupon.is_cumulative;
    return coupon;
  }
}
