import * as React from 'react';
import { Context } from './context';

import { StateActions } from '@/react/components/refund/wizard/state/interfaces';

export function useWizard(): useWizard {
  const context = React.useContext(Context);

  if (!context) {
    throw new Error('useWizard must be used within a WizardContext');
  }

  const [state, actions] = context;
  const { currentStepId, values } = state;

  return {
    actions,
    currentStepId,
    values,
  };
}

type useWizard = {
  actions: StateActions;
  currentStepId: string;
  values: Record<string, any>;
};
