import { RouterService } from 'src/app/services/router.service';
import { Component } from '@angular/core';

import { TranslatedComponent } from 'src/app/utils/translated.component';
import { CursoHorario } from 'src/app/models/curso-horario.model';
import { UsersService } from 'src/app/services/users.service';
import { LayoutService } from 'src/app/services/layout.service';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
@Component({
  selector: 'app-custom-category',
  templateUrl: './custom-category.component.html',
  styleUrls: ['./custom-category.component.scss'],
})
export class CustomCategoryComponent extends TranslatedComponent {
  translationKey: string = 'CURSOS.CUSTOM_CATEGORY';

  courses: CursoHorario[] = [];
  user: User;

  constructor(
    private userService: UsersService,
    private layoutService: LayoutService,
    private apiService: ApiService,
    private routerService: RouterService
  ) {
    super();
  }

  getCustomCategoryName() {
    return this.userService.user.colaborador.empresa.custom_category;
  }

  ngOnInit(): void {
    this.user = this.userService.user;

    this.layoutService.updateBreadCrumb([
      {
        label: 'Home',
        route: ['/'],
      },
      {
        label: this.userService.user.colaborador.empresa.custom_category,
      },
    ]);

    this.apiService.getAll('cursos/custom-category-courses').subscribe(
      (response) => {
        this.courses = response.data;
      },
      (err) => {
        this.routerService.home();
      }
    );
  }

  isEduplayPremium() {
    return this.user.colaborador.empresa.eduplay_premium;
  }
}
