import type { GlobalStateConditions } from '@/react/components/quiz/quiz.interfaces';
import { RuntimeQuestionNode } from '@/react/components/quiz/state/interfaces';
import { keys } from 'powership';
import { objectMatch } from '@/react/components/quiz/utils/object-match';
import { ReduceQuizPayload } from '@/react/components/quiz/state/reducer';

type GlobalConditionKeys = Exclude<keyof GlobalStateConditions, `$${string}`>;

// Returns the node with the result values from the defined `node.conditions`.
// See the quiz.interfaces.ts for more details about conditional fields.
export function handleNodeConditions(data: {
  currentNode: RuntimeQuestionNode;
  index: number;
  allNodes: RuntimeQuestionNode[];
  flags: Set<string>;
  payload: ReduceQuizPayload;
}): Required<RuntimeQuestionNode> {
  const {
    allNodes,
    currentNode,
    flags,
    index,
    payload: { activeNodeId, previousActiveNodeId },
  } = data;

  const {
    countAsStep = currentNode.kind !== 'presentation' &&
      currentNode.countAsStep !== false,
  } = currentNode;

  const defaults = {
    visible: true,
    replaceActive: false,
    index,
    conditions: {},
    countAsStep,
    dialogProps: { maxWidth: 'xs' },
    showFooterActions: true,
    meta: undefined
  } as const;

  if (!currentNode.conditions) return { ...defaults, ...currentNode };

  const state: {
    [K in GlobalConditionKeys]: GlobalStateConditions[K];
  } = {
    previousActiveNodeId,
    activeNodeId,
    hasRequiredNodesEmpty: allNodes.some(
      (el) =>
        el.visible && el.required && (!el.value.length || el.errors.length)
    ),
    hasOptionalNodesEmpty: allNodes.some(
      (el) =>
        el.visible && !el.required && (!el.value.length || el.errors.length)
    ),
    flags: [...flags],
  };

  const nextNode: Required<RuntimeQuestionNode> = {
    ...defaults,
    ...currentNode,
  };

  keys(nextNode.conditions).forEach((conditionName) => {
    const condition = nextNode.conditions[conditionName];
    if (!condition) return;

    // every key in condition is also a key in the root node.
    nextNode[conditionName] = allNodes.some((node) => {
      const data = { ...state, ...defaults, ...node };
      return objectMatch(data, condition);
    });
  });

  return nextNode;
}
