<app-banner-eduplay
  curso="{{ getBannerLabel() | translate }}"
  subtitulo="{{ getTranslation('aprendizado') | translate }}"
  [loading]="false"
  label="{{ getTranslation('acessar') | translate }}"
></app-banner-eduplay>

<app-cursos-navigation [menu]="menu"></app-cursos-navigation>

<app-certificate-help></app-certificate-help>

<app-card-curso-andamento
  [cursos]="cursosAndamento"
  *ngIf="menu === 'cursos_andamento'"
></app-card-curso-andamento>

<app-card-cursos-concluidos
  [cursos]="cursosConcluidos"
  *ngIf="menu === 'cursos_concluidos'"
></app-card-cursos-concluidos>

<app-cursos-duvidas></app-cursos-duvidas>
