export const getChipVariantFromPlan = (plan) => {
  switch (plan.code) {
    case 'full':
      return 'planFull';
    case 'family':
      return 'planFamily';
    case 'start':
      return 'planStart';
    default:
      return 'default';
  }
};
