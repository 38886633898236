import { Empresa } from './empresa.model';
import { Colaborador } from './colaborador.model';
import { Permissao } from './permissao.model';
import { PesquisaPerfil } from './pesquisa-perfil.model';

export class User {
  id: number;
  nome: string;
  email: string;
  cpf: string;
  sexo: string;
  created_at: string;
  form_inicial: number;
  biografia: string;
  expiracao: string;
  ultimo_acesso: string;
  renovacao: boolean;
  acessos_count: number;
  ativo: boolean;
  bloqueado: boolean;
  terms_accepted: boolean;
  phone?: string;
  token?: string;
  raw_token?: string;
  empresas: Empresa[];
  colaborador: Colaborador;
  permissoes: Permissao[];
  pesquisa_perfils: PesquisaPerfil[];
  cadastroTipo: 'email' | 'cpf' = 'email';
  cadastro_tipo: 'email' | 'cpf' = 'email';
  register_date: string;

  static ASSOCIATIONS = {
    colaborador: Colaborador,
  };

  isPremium() {
    return !!this.colaborador?.empresa?.eduplay_premium;
  }

  getPrimeiroNome() {
    return this.nome?.split(' ')[0];
  }

  isFreemium() {
    return this.colaborador?.empresa?.is_freemium;
  }

  empresa() {
    return this.colaborador.empresa;
  }

  hasDependent() {
    return this.colaborador?.empresa?.plano_dependente;
  }

  isDependent() {
    return Boolean(this.colaborador?.employee_id);
  }

  isLeroy() {
    return (
      this.colaborador.empresaId === 145 || this.colaborador.empresaId === 149
    );
  }

  static fromJson(user: User) {
    const instance = new User();
    Object.keys(user).forEach((key) => {
      instance[key] = user[key];

      // Instantiate associations so we can use their methods.
      // This will allow things like user.colaborador.isDependent() to work.
      const associationClass = this.ASSOCIATIONS[key];
      if (associationClass) {
        const newAssociation = associationClass.fromJson(user[key]);
        instance[key] = newAssociation;
      }
    });

    return instance;
  }

  static readonly ROLES = {
    ADMIN: 'Admin',
    USER: 'User',
    FINANCIAL: 'Financial',
    MANAGER: 'Manager',
    HR: 'H.R.',
    EDUCATIONAL_MANAGER: 'educational_manager',
    CS_MANAGER: 'cs_manager',
  };

  static readonly FORM_STATUS = {
    PESQUISA_PERFIL: 9,
  };

  isAdmin(): boolean {
    return !!this.permissoes.find(
      (permissao) => permissao.nome === Permissao.TYPES.ADMIN
    );
  }

  isHR(): boolean {
    return !!this.permissoes.find(
      (permissao) => permissao.nome === Permissao.TYPES.HR
    );
  }

  isRefundManager(): boolean {
    return !!this.permissoes.find(
      (permissao) => permissao.nome === Permissao.TYPES.REFUND_REQUEST_MANAGER
    );
  }

  isFinancial(): boolean {
    return this.permissoes.some(
      (permissao) => permissao.nome === Permissao.TYPES.FINANCIAL
    );
  }

  checkRole(roles: string[]): boolean {
    let valid = false;

    roles.forEach((role) => {
      if (this.permissoes.find((userRole) => userRole.nome === role)) {
        valid = true;
      }
    });

    return valid;
  }

  hasLMS(): boolean {
    return (
      this.colaborador.empresa?.logo &&
      this.colaborador.empresa?.banner_background &&
      this.colaborador.empresa?.feature_lms &&
      this.colaborador.empresa?.corporate_university_name &&
      !!this.colaborador.empresa?.primary_color
    );
  }

  hasCustomCategory(): boolean {
    return !!this.colaborador.empresa.custom_category;
  }

  isIntercement(): boolean {
    return this.colaborador.empresa.empresa === 'Intercement';
  }
  isTrial(): boolean {
    return this.colaborador.empresa.empresa === 'Trial';
  }

  refundEnabled(): boolean {
    return (
      this.colaborador.empresa.feature_refund &&
      !!this.colaborador.employee_level_id
    );
  }
}
