import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import AddDependentDialog from './add-dependent-dialog.component';
import DependentsList from './dependents-list.component';
import useToggle from '@/react/hooks/useToggle';

import { ManageDependentsProvider } from './manage-dependents.context';

const ManageDependents = ({ dependents }) => {
  const {
    open: showAddOrEditDependentDialog,
    toggle: toggleAddOrEditDependentDialog,
  } = useToggle({ startOpen: false });

  return (
    <ManageDependentsProvider dependents={dependents}>
      <Container>
        <Grid container spacing={2} my={2}>
          <Grid item sm xs={12}>
            <Typography gutterBottom variant="h4">
              Meus dependentes
            </Typography>
            <Typography variant="body2">
              Adicione e gerencie dependentes para compartilhar seus benefícios!
            </Typography>
          </Grid>
          <Grid item sm="auto" xs={12}>
            <Button
              color="primary"
              fullWidth
              size="medium"
              startIcon={<AddIcon />}
              variant="contained"
              onClick={toggleAddOrEditDependentDialog}
            >
              Adicionar dependente
            </Button>
          </Grid>
        </Grid>
        <Divider sx={{ my: 3 }} />
        <DependentsList />
        <AddDependentDialog
          open={showAddOrEditDependentDialog}
          toggleOpen={toggleAddOrEditDependentDialog}
        />
      </Container>
    </ManageDependentsProvider>
  );
};

export default ManageDependents;
