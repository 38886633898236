import {
  createProfileQuizState,
  ProfileQuizStateInstance,
  ProfileQuizStateValue,
} from './profile-quiz.state';
import {
  loadBootstrapData,
  LoadBootstrapDataOptions,
} from '../../data/bootstrap/useBootstrapData';
import { useEffect, useState } from 'react';
import { isHomePage } from '@/react/components/quiz/utils/isHomePage';
import { HomeMainRecommendation } from '@/react/data/bootstrap/recomendation-interfaces';
import { delay } from 'powership';

/**
 * Provides a static interface to load and broadcast profile state updates.
 */
export class GlobalProfileStateEmitter {
  private static listeners = new Set<Listener>();

  static current: Payload = {
    error: null,
    instance: null,
    loading: null,
    recommendations: null,
    resetting: null,
    pollingRecommendations: null,
  };

  /**
   * Subscribes a callback to receive profile quiz state updates.
   * @param callback The callback to be invoked with the state update or an error.
   * @returns A function to unsubscribe the callback.
   */
  static _didLoad = false;
  public static subscribe(callback: Listener): Unsubscribe {
    this.listeners.add(callback);

    if (!this._didLoad) {
      this._didLoad = true;
      this.load().catch(console.error);
    }

    return () => this.listeners.delete(callback);
  }

  public static async load(
    options: LoadBootstrapDataOptions = {}
  ): Promise<Payload> {
    this.emit({ loading: true, resetting: options.forceResetProfile });

    if (!('includeRecommendations' in options)) {
      options.includeRecommendations = isHomePage();
    }

    try {
      const bootstrap = await loadBootstrapData(options);
      let instance: any = null;

      if ((bootstrap.profile && bootstrap.info?.terms_accepted) || options.forceProfileForm) {
        instance = createProfileQuizState(bootstrap);
      }

      this.emit({
        error: null,
        instance,
        loading: false,
        resetting: options.forceResetProfile,
        recommendations: bootstrap.recommendations || null,
      });
    } catch (error) {
      this.emit({ error, instance: null, loading: false, resetting: false });
      throw error;
    }
    return this.current;
  }

  // Emits the profile quiz state or error to all subscribers
  private static emit(event: Partial<Payload>) {
    const ev = { ...this.current, ...event };
    this.current = ev;
    this.listeners.forEach((callback) => {
      callback(ev);
    });
  }

  static async startRecommendationsLongPolling() {
    let delays = [
      3_000,
      3_000,
      5_000,
      7_000, // delay is increased after some retries
      10_000,
    ];

    type Result = ReturnType<typeof GlobalProfileStateEmitter.load>;

    const tryIt = async (): Result => {
      const nextDelay = delays.shift();

      if (!nextDelay) {
        throw new Error(`retry limit exceeded.`);
      }

      try {
        const res = await GlobalProfileStateEmitter.load({
          includeRecommendations: true,
        });

        const generated =
          res.recommendations?.processing_status === 'completed';

        // TODO
        //  today backend may return 'generated' status with no courses
        //  ...to be fixed
        const temp_isItReallyGenerated = !!res.recommendations?.courses?.length;

        if (generated && temp_isItReallyGenerated) {
          return res;
        }

        await delay(nextDelay);
        return tryIt();
      } catch (e) {
        if (!nextDelay) {
          // no more chances in the wild
          // you are over, baby!
          throw e;
        } else {
          console.error(e);
          await delay(nextDelay);
          return tryIt();
        }
      }
    };

    this.emit({ pollingRecommendations: true });

    try {
      const result = await tryIt();
      this.emit({ pollingRecommendations: false });
      return result;
    } catch (err) {
      this.emit({ pollingRecommendations: false });
      console.error(err);
    }
  }

  static useQuizState = useQuizState;
}

function useQuizState<Value>(
  select: (state: ProfileQuizStateValue, payload: Payload) => Value
): Payload & { value: Value | null } {
  function mountHookResult(): Payload & { value: Value | null } {
    const {
      pollingRecommendations,
      instance,
      loading,
      resetting,
      error,
      recommendations,
    } = GlobalProfileStateEmitter.current;

    const currentValue = instance?.current();

    return {
      instance,
      error,
      loading,
      pollingRecommendations,
      value: currentValue
        ? select(currentValue, GlobalProfileStateEmitter.current)
        : null,
      recommendations,
      resetting,
    };
  }

  const [data, setData] = useState(mountHookResult);

  useEffect(() => {
    let unsubscribeToSelectedValue: Unsubscribe;

    const unsubscribe = GlobalProfileStateEmitter.subscribe(({ instance }) => {
      setData(mountHookResult);

      unsubscribeToSelectedValue = instance?.observe(
        (value) => select(value, GlobalProfileStateEmitter.current),
        () => setData(mountHookResult)
      );
    });

    return () => {
      unsubscribeToSelectedValue?.();
      unsubscribe();
    };
  }, [select, setData]);

  return data;
}

type Payload = {
  error: Error | null;
  instance: ProfileQuizStateInstance | null;
  // true when we call the .load method and fetching the bootstrap data
  // to create the quiz instance was started
  loading: boolean;
  // true when the user clicked in to answer quiz again
  resetting: boolean;
  // it's true when the quiz was just completed, and we
  // are polling backend for new recommendations
  pollingRecommendations: boolean;
  recommendations: HomeMainRecommendation | null;
};

type Listener = (event: Payload) => void;
type Unsubscribe = () => void;
