import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { getAsset } from 'src/app/utils/helpers';
import { CONST } from 'src/app/constants';
import { MediaService } from 'src/app/services/media.service';
import { Course } from 'src/app/models-new/media/course.model';
import { Trail } from 'src/app/models-new/media/trails.model';
import { CorporateUniverstyRoutes } from 'src/app/pages/corporate-university/corporate-university.routes';
import { RouterService } from 'src/app/services/router.service';
import { LayoutService } from 'src/app/services/layout.service';
import { TranslatedComponent } from 'src/app/utils/translated.component';

@Component({
  selector: 'app-corporate-university-courses',
  templateUrl: './corporate-university-courses.component.html',
  styleUrls: ['./corporate-university-courses.component.scss'],
})
export class CorporateUniversityCoursesComponent
  extends TranslatedComponent
  implements OnInit
{
  readonly translationKey: string =
    'CORPORATE_UNIVERSITY.CORPORATE_UNIVERSITY_COURSES';

  trail: Trail;

  defaultImageUrl = `${CONST.API_ENDPOINT}/contents/eduplay/default.png`;

  getAsset = getAsset;

  constructor(
    private routerService: RouterService,
    private activatedRoute: ActivatedRoute,
    private mediaService: MediaService,
    private layoutService: LayoutService
  ) {
    super();
  }

  ngOnInit(): void {
    const { id } = this.activatedRoute.snapshot.params;
    this.loadTrailById(id);
  }

  getImage(courseImage: string) {
    return `${CONST.API_ENDPOINT}/contents/eduplay/${courseImage}`;
  }

  onAcessar(course: Course) {
    this.routerService.navigate([
      CorporateUniverstyRoutes.CORPORATE_UNIVERSITY,
      CorporateUniverstyRoutes.COURSES,
      course.id,
    ]);
  }

  onCatalog() {
    this.routerService.navigate([
      CorporateUniverstyRoutes.CORPORATE_UNIVERSITY,
      CorporateUniverstyRoutes.CATALOG,
    ]);
  }

  onImageError(element) {
    element.target.src = this.defaultImageUrl;
  }

  async loadTrailById(trailType: string) {
    try {
      this.trail = await this.mediaService.getCoursesByTrail(trailType);
      this.layoutService.updateBreadCrumb([
        {
          label: 'Home',
          route: ['/'],
        },
        {
          label: 'Trilhas',
          route: [
            CorporateUniverstyRoutes.CORPORATE_UNIVERSITY,
            CorporateUniverstyRoutes.TRAILS,
          ],
        },
        {
          label: this.trail.name,
          route: [
            CorporateUniverstyRoutes.CORPORATE_UNIVERSITY,
            CorporateUniverstyRoutes.TRAILS,
            this.trail.id,
          ],
        },
      ]);
    } catch (e) {
      this.routerService.home();
    }
  }
}
