import React, { useRef } from 'react';
import { Box, Button, styled } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { neutral, white } from '@/react/@mui/theme/tokens/colors';
import { useClickOutside } from '@/react/hooks/useClickOutside';

const StyledDropdownButton = styled(Button)(({ theme }) => ({
  backgroundColor: neutral[300],
  fontSize: 14,
  height: 24,
  minHeight: 24,
  borderRadius: 0,
  color: theme.palette.neutral[800],
  padding: 0,
  paddingLeft: 8,

  '&:hover': {
    backgroundColor: neutral[400],
  },
}));

const StyledDropdownOption = styled(Button)(({ theme }) => ({
  backgroundColor: white[500],
  borderRadius: 0,
  color: neutral[800],
  justifyContent: 'start',
  '&:hover': {
    backgroundColor: neutral[300],
  },
}));

const DropdownButton = ({ selectedOption, options, onOptionSelected }) => {
  const wrapperRef = useRef();

  const [openedDropdown, setOpenedDropdown] = React.useState(false);

  useClickOutside({
    ref: wrapperRef,
    onClickedOutside: () => {
      setOpenedDropdown(false);
    },
  });

  const handleDropdownClick = () => {
    setOpenedDropdown(!openedDropdown);
  };

  return (
    <div style={{ position: 'relative' }} ref={wrapperRef}>
      <StyledDropdownButton variant="contained" onClick={handleDropdownClick}>
        Status: {selectedOption}
        <ExpandMore sx={{ marginLeft: 1 }} />
      </StyledDropdownButton>

      {openedDropdown && (
        <Box
          border={`1px solid ${neutral[400]}`}
          width={150}
          position={'absolute'}
          display={'flex'}
          flexDirection={'column'}
          zIndex={100}
        >
          {options.map(({ label, value }) => (
            <StyledDropdownOption
              key={value}
              onClick={() => {
                onOptionSelected(value);
                setOpenedDropdown(false);
              }}
            >
              {label}
            </StyledDropdownOption>
          ))}
        </Box>
      )}
    </div>
  );
};

export { DropdownButton };
