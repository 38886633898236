import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  PlanItem,
  planItemLinkTo,
} from '@/react/components/idp/home/components/planItem';
import { PlanItemInProgress } from '@/react/components/idp/home/components/planItemInProgress';
import { Activity, Plan, PlanStatuses } from '@/react/data/idp/interfaces';
import Link from '@mui/material/Link';
import { IdpClient } from '@/react/data/idp/idp-client';
import * as Sentry from '@sentry/browser';

const PlanItemTitle = ({ title }: { title: string }) => {
  return (
    <Grid marginBottom="24px" xs={12} item>
      <Typography color="primary.200" variant="h5" fontWeight={600}>
        {title}
      </Typography>
    </Grid>
  );
};

const ClosedItems = ({ plans }: { plans: Plan[] }) => {
  return (
    <>
      {plans.map((plan: Plan) => (
        <PlanItem key={plan.id} plan={plan} data-testid="planItemClosedDates">
          <Typography
            color="black"
            variant="h5"
            fontWeight={500}
            marginBottom="20px"
          >
            {plan.startedAt} - {plan.endedAt}
          </Typography>

          <Link href={planItemLinkTo(plan)} underline="none" margin="24px">
            Ver Plano
          </Link>
        </PlanItem>
      ))}
    </>
  );
};

export const ListPlans = ({ plans }: { plans: Plan[] }) => {
  const [inProgressActivities, setInProgressActivities] = useState<Activity[]>([]);
  const inProgress = plans?.find(
    (p) => p.status === PlanStatuses.IN_PROGRESS
  );
  const draft = plans?.find((p) => p.status === PlanStatuses.DRAFT);
  const closed = plans?.filter((p) => p.status === PlanStatuses.CLOSED) || [];

  const fetchInProgressPlan = async () => {
    if (inProgress) {
      try {
        const activities = await IdpClient.listPlanActivities(inProgress.id);
        setInProgressActivities(activities);
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  };

  useEffect(() => {
    if (inProgress) {
      fetchInProgressPlan();
    }
  }, [inProgress]);

  return (
    plans.length > 0 && (
      <Grid xs={12} item data-testid="listPlans">
        {inProgress && (
          <Grid item>
            <PlanItemTitle title="Em Andamento" />
            <PlanItemInProgress plan={inProgress} activities={inProgressActivities} />
          </Grid>
        )}
        {draft && (
          <Grid marginTop="24px" item>
            <PlanItemTitle title="Rascunho" />
            <PlanItem plan={draft} data-testid="planItemDraft">
              <Link href={planItemLinkTo(draft)} underline="none" margin="24px">
                Ver Plano
              </Link>
            </PlanItem>
          </Grid>
        )}
        {closed.length > 0 && (
          <Grid marginTop="24px" item>
            <PlanItemTitle title="Finalizados" />
            <ClosedItems plans={closed} />
          </Grid>
        )}
      </Grid>
    )
  );
};
