import React from 'react';
import { ProfileQuiz } from '@/react/components/profile-quiz';
import { DevMenu } from '@/react/components/dev/dev-menu';

export function ReactGlobalFragment() {
  return (
    <>
      <ProfileQuiz />
      <DevMenu />
    </>
  );
}
