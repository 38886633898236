import CONST from '@/constants';

export const getHeaders = () => {
  const rawToken = localStorage.getItem(CONST.TOKEN_KEY);

  // TODO: Keep only X-Authorization, we're keeping Authorization for compatibility for a while

  if (!rawToken) return {};

  return {
    Authorization: `Bearer ${rawToken}`,
    'X-Authorization': `Bearer ${rawToken}`,
  };
};
