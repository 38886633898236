import { Injectable, EventEmitter } from '@angular/core';
import { User } from '../models/user.model';
import { ApiService } from './api.service';
import { restClient } from '@/react/utils/fetch';

import { getHeaders } from '@/utils/apiHeaders';
import CONST from '@/constants';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private http: ApiService) {}

  user: User;

  // EventEmitters
  loggedIn: EventEmitter<User> = new EventEmitter();
  notFound: EventEmitter<void> = new EventEmitter();

  // Needs to be logged in (uses a valid token in localstorage)
  // Take a look in ApiService.getAll() for more info
  async fetchUser() {
    try {
      const response = await this.http.getAll('user').toPromise();

      this.user = User.fromJson(response.data);
      this.loggedIn.emit(this.user);
    } catch (error) {
      this.user = null;
      this.notFound.emit();
    }

    return this.user;
  }

  store(user: User) {
    localStorage.setItem(CONST.TOKEN_KEY, user.raw_token);
    restClient.defaults.headers.common = getHeaders();
    this.user = User.fromJson(user);
  }

  onUserName(name: string) {
    return this.http.put(`user_name`, { name }).toPromise();
  }
}
