class PasswordUtil {
  static validate(senha: string): boolean {
    const digits = senha.match(/\d/g);
    const nonDigits = senha.match(/\D/g);
    if (senha.length < 8 || !digits || !nonDigits) {
      return false;
    }
    return true;
  }
}

export { PasswordUtil };
