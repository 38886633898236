import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Languages from '../utils/languages';
import { Categoria } from '../models/categoria';
import { locale as en } from './../../assets/i18n/en';
import { locale as pt } from './../../assets/i18n/pt';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  constructor(private translate: TranslateService) {}

  translation() {
    this.translate.setTranslation(en.lang, en.data, true);
    this.translate.setTranslation(pt.lang, pt.data, true);
    this.translate.addLangs(['en', 'pt']);
    this.translate.setDefaultLang('pt');

    const browserLang: any = this.translate.getBrowserLang();
    this.translate.use('pt');
  }

  switchLanguage(language: Languages) {
    this.translate.use(language);
  }

  getTranslation(key: string) {
    return this.translate.instant(key);
  }

  getdefaultLanguage() {
    return this.translate.getDefaultLang();
  }

  getTranslationList(key: string[]) {
    return this.translate.get(key);
  }

  onLangChange() {
    return this.translate.onLangChange;
  }

  getLang() {
    return this.translate.currentLang;
  }
}
