import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';

const Styled = {
  List: styled('ul')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: 0,
    overflow: 'auto',
    padding: 0,
  })),
  ListItem: styled('li')(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),

    '&& *': {
      fontWeight: theme.typography.fontWeightRegular,
    },

    '&.hidden': {
      display: 'none',
    },
  })),
  Chip: styled(Chip)(({ theme }) => ({
    backgroundColor: theme.palette.neutral[200],
    borderRadius: 0,
    height: 40,

    '& .MuiChip-label': {
      color: theme.palette.primary[900],
      fontSize: theme.typography.body3.fontSize,
    },
  })),
};

const MAX_VISIBLE_SKILLS = 10;

export const SkillsList = ({ skills }: { skills: string[] }) => {
  const [showAll, setShowAll] = React.useState(false);

  const handleHiddenClass = (index) => {
    if (showAll) {
      return '';
    }

    if (MAX_VISIBLE_SKILLS - index < 1) return 'hidden';
  };

  const handleButtonLabel = () =>
    showAll ? 'Mostrar menos habilidades' : 'Mostrar mais habilidades';

  return (
    <Styled.List>
      {skills.map((skill, i) => (
        <Styled.ListItem key={skill} className={handleHiddenClass(i)}>
          <Styled.Chip label={skill} />
        </Styled.ListItem>
      ))}
      {MAX_VISIBLE_SKILLS - skills.length < 0 && (
        <Styled.ListItem>
          <Button
            onClick={() => setShowAll(!showAll)}
            sx={{ textDecoration: 'underline' }}
            variant="text"
          >
            {handleButtonLabel()}
          </Button>
        </Styled.ListItem>
      )}
    </Styled.List>
  );
};
