import * as React from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';

import { useFilters } from '@/react/components/filters/state';
import { useScreenSizes } from '@/react/utils/useScreenSizes';
import { FilterResetButton } from './filter-reset-button';

interface ExtraChipProps {
  backgroundColor: 'light' | 'dark';
}

interface ExtraListProps {
  scrollable: boolean;
}

const Styled = {
  List: styled('ul', {
    shouldForwardProp: (prop) => prop !== 'scrollable',
  })<ExtraListProps>(({ scrollable, theme }) => ({
    display: 'flex',
    flexWrap: scrollable ? 'nowrap' : 'wrap',
    listStyle: 'none',
    margin: 0,
    overflow: 'auto',
    padding: 0,
    '&&': {
      marginBottom: scrollable ? '0px' : theme.spacing(-1),
    },

    '& > li': {
      marginBottom: scrollable ? '0px' : theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  })),
  ListItem: styled('li')(({ theme }) => ({
    maxWidth: `calc(100% - ${theme.spacing(1)})`,
  })),
  Chip: styled(Chip, {
    shouldForwardProp: (prop) => prop !== 'backgroundColor',
  })<ExtraChipProps>(({ theme, backgroundColor }) => ({
    backgroundColor:
      backgroundColor === 'dark'
        ? theme.palette.neutral[200]
        : theme.palette.neutral[50],
    borderRadius: theme.shape.borderRadius,

    '& .MuiChip-label': {
      fontSize: theme.typography.caption.fontSize,
    },

    '& .MuiChip-deleteIcon': {
      color: theme.palette.neutral[600],
    },
  })),
};

export const FilterTagCloud = ({
  filterIdsToDisplay,
  filterIdsToReset,
  scrollable = false,
  type,
  variant,
}: {
  filterIdsToDisplay: string[];
  filterIdsToReset: string[];
  scrollable?: boolean;
  type: string;
  variant?: 'light' | 'dark';
}) => {
  const { actions, state } = useFilters();
  const { isSmallScreen } = useScreenSizes();

  const getLabelFromField = (field, value) => {
    const { type, props } = field;

    switch (type) {
      case 'checkbox':
        return props.options.find((option) => option.value === value)?.label;
      case 'switch':
        return props.label;
      case 'location':
        return props.options.statesOptions.find(
          (option) => option.value === value
        )?.label;
      case 'select':
        return props.options.find((option) => option.value === value)?.label;
      case 'price':
        const [min, max] = value;

        if (min && !max) {
          return `A partir de R$ ${min}`;
        } else if (!min && max) {
          return `Até R$ ${max}`;
        } else if (min && max) {
          return `R$ ${min} - R$ ${max}`;
        }

        return;
      default:
        return value;
    }
  };

  const createChipData = (field) => {
    const acc = [];

    // for price, we need to merge both values into a single object
    if (field.type === 'price') {
      const label = getLabelFromField(field, field.values);

      if (label) {
        acc.push({
          key: field.id,
          label,
          type: field.type,
          value: field.values.join(','),
        });
      }

      return acc;
    }

    if (field.type === 'location') {
      if (field.values.length > 0) {
        const [state, city] = field.values;

        if (state) {
          acc.push({
            key: 'location',
            label: getLabelFromField(field, state),
            type: 'location_state',
            value: state,
          });
        }

        if (city) {
          acc.push({
            key: 'location',
            label: city,
            type: 'location_city',
            value: city,
          });
        }
      }

      return acc;
    }

    field.values.forEach((value) => {
      const label = getLabelFromField(field, value);
      acc.push({ key: field.id, label, type: field.type, value });
    });

    return acc;
  };

  const chipsData = Object.entries(state)
    .reduce((acc, [key, field]) => {
      if (filterIdsToDisplay.includes(key) && field.values.length > 0) {
        const chipData = createChipData(field);
        acc.push(...chipData);
      }

      return acc;
    }, [])
    .sort((a, b) => a.label?.localeCompare(b.label));

  const handleDelete = (chip) => () => {
    if (['checkbox', 'location_city'].includes(chip.type)) {
      actions.setCheckboxSelectionById(chip.key, chip.value);
      actions.setPage(1);
    }
    if (
      [
        'location_state',
        'location',
        'price',
        'select',
        'switch',
        'text',
      ].includes(chip.type)
    ) {
      actions.clearFieldValues(chip.key);
      actions.setPage(1);
    }
  };

  const backgroundColor = () => {
    if (variant) {
      return variant;
    }

    return isSmallScreen ? 'dark' : 'light';
  };

  return chipsData.length > 0 ? (
    <>
      <Styled.List data-filter-type={type} scrollable={scrollable}>
        {chipsData.map((data) => {
          return (
            <Styled.ListItem key={`${data.key}${data.label}`}>
              <Styled.Chip
                backgroundColor={backgroundColor()}
                deleteIcon={<CloseIcon />}
                label={data.label}
                onDelete={handleDelete(data)}
                size="small"
              />
            </Styled.ListItem>
          );
        })}
        <Styled.ListItem>
          <FilterResetButton
            buttonText="Limpar"
            filterIdsToReset={filterIdsToReset}
            type="reset-button"
          />
        </Styled.ListItem>
      </Styled.List>
    </>
  ) : null;
};
