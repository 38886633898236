import { Component, OnInit } from '@angular/core';
import type { ComponentProps } from 'react';

import { TranslationService } from '@/services/translation.service';
import ReactManageDependents from '@/react/components/dependents/manage';
import { User } from '@sentry/browser';
import { UsersService } from '@/services/users.service';
import { ApiService } from '@/services/api.service';

@Component({
  selector: 'component-dependents',
  templateUrl: './manage-dependents.component.html',
})
export class ManageDependentsComponent implements OnInit {
  ReactManageDependents = ReactManageDependents;
  reactManageDependentsProps: ComponentProps<typeof ReactManageDependents> = {
    dependents: null,
  };
  user: User;

  constructor(
    private translate: TranslationService,
    private userService: UsersService,
    private apiService: ApiService
  ) {}

  ngOnInit(): void {
    this.user = this.userService.user;
    this.apiService
      .getAll(`colaboradores/${this.user.colaborador.id}/dependents`)
      .subscribe(
        (response) => {
          this.reactManageDependentsProps = {
            ...this.reactManageDependentsProps,
            dependents: response.data,
          };
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getTranslation(key: string) {
    return this.translate.getTranslation(`COLABORADOR.DEPENDENTS.${key}`);
  }
}
