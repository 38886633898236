import { faker } from '@faker-js/faker/locale/pt_BR';

const createDependent = () => {
  const id = faker.number.int(1000);
  const kinship_degree =
    Object.keys(dependentTypes)[
      faker.helpers.rangeToNumber({ min: 0, max: 2 })
    ];

  return {
    id,
    kinship_degree,
    user: {
      id,
      nome: faker.person.fullName(),
      email: faker.internet.email(),
      ultimo_acesso: null,
    },
  };
};

export const DependentFactory = {
  build: (quantity = 4) => Array.from({ length: quantity }, createDependent),
};

export const dependentStatusLabels = {
  active: 'Ativo',
  pending: 'Convite enviado',
  removed: 'Removido',
};

export const dependentStatusVariants = {
  active: 'statusActive',
  pending: 'statusPending',
  removed: 'statusRemoved',
};

export const dependentTypes = {
  child: 'Filho ou Filha',
  spouse: 'Marido ou Esposa',
  parent: 'Pai ou Mãe',
};

// TODO: what's the logic for a removed user?
export const getStatusFromDependent = (dependent) => {
  const status = {
    label: dependentStatusLabels['pending'],
    variant: dependentStatusVariants['pending'],
  };

  if (dependent.user.ultimo_acesso) {
    status.label = dependentStatusLabels['active'];
    status.variant = dependentStatusVariants['active'];
  }

  return status;
};

export const getKinshipLabelFromDependent = (dependent) => {
  switch (dependent.kinship_degree) {
    case 'parent':
      return dependentTypes['parent'];
    case 'spouse':
      return dependentTypes['spouse'];
    case 'child':
      return dependentTypes['child'];
    default:
      return '';
  }
};
