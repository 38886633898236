import * as React from 'react';
import Stack from '@mui/material/Stack';

import { FilterCheckbox } from './components/filter-checkbox';
import { FilterDivider } from './components/filter-divider';
import { FilterInlineSelect } from './components/filter-inline-select';
import { FilterLocation } from './components/filter-location';
import { FilterPriceRange } from './components/filter-price-range';
import { FilterSelect } from './components/filter-select';
import { FilterSwitch } from './components/filter-switch';
import { FilterTagCloud } from './components/filter-tag-cloud';
import { FilterText } from './components/filter-text';
import { useFilters } from './state';

const componentsByType = {
  checkbox: FilterCheckbox,
  divider: FilterDivider,
  inlineSelect: FilterInlineSelect,
  internalValue: null,
  location: FilterLocation,
  price: FilterPriceRange,
  select: FilterSelect,
  switch: FilterSwitch,
  tagCloud: FilterTagCloud,
  text: FilterText,
};

export const Filters = () => {
  const { state } = useFilters();

  const renderContent = () =>
    Object.values(state).map((filter) => {
      const FilterComponent = componentsByType[filter.type];

      if (!FilterComponent) {
        return null;
      }

      return (
        <FilterComponent
          key={filter.id}
          id={filter.id}
          type={filter.type}
          {...filter.props}
        />
      );
    });

  return (
    <>
      <Stack spacing={3}>{renderContent()}</Stack>
    </>
  );
};
