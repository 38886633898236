import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input()
  header: string;

  @Input()
  alerta = true;

  @Input()
  cardStyle: {
    [klass: string]: any;
  };

  @Input()
  closeButton: boolean = false;

  @Output()
  outsideClick = new EventEmitter();

  @Output()
  closeClick = new EventEmitter();

  constructor() {}

  ngOnInit() {
    document.body.classList.add('modal-open');
  }

  ngOnDestroy() {
    document.body.classList.remove('modal-open');
  }

  onClickOutside(event) {
    if (event.target.id !== 'feedback-button') {
      this.outsideClick.emit();
    }
  }

  onCloseClick(event) {
    this.onClickOutside(event);
    this.closeClick.emit();
  }
}
