import { pxToRem } from '../utils';
import fontWeights from './font-weights';

const typography = {
  h1: {
    fontSize: pxToRem(64),
    lineHeight: pxToRem(80),
    fontWeight: fontWeights.fontWeightBold,
  },
  h2: {
    fontSize: pxToRem(48),
    lineHeight: pxToRem(60),
    fontWeight: fontWeights.fontWeightBold,
  },
  h3: {
    fontSize: pxToRem(32),
    lineHeight: pxToRem(40),
    fontWeight: fontWeights.fontWeightBold,
  },
  h4: {
    fontSize: pxToRem(24),
    lineHeight: pxToRem(32),
    fontWeight: fontWeights.fontWeightBold,
  },
  h5: {
    fontSize: pxToRem(20),
    lineHeight: pxToRem(32),
    fontWeight: fontWeights.fontWeightBold,
  },
  h6: {
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    fontWeight: fontWeights.fontWeightBold,
  },
  subtitle1: {
    fontSize: pxToRem(32),
    lineHeight: pxToRem(48),
    fontWeight: fontWeights.fontWeightRegular,
  },
  subtitle2: {
    fontSize: pxToRem(24),
    lineHeight: pxToRem(32),
    fontWeight: fontWeights.fontWeightRegular,
  },
  subtitle3: {
    fontSize: pxToRem(20),
    lineHeight: pxToRem(28),
    fontWeight: fontWeights.fontWeightRegular,
  },
  body1: {
    fontSize: pxToRem(18),
    lineHeight: pxToRem(24),
    fontWeight: fontWeights.fontWeightRegular,
  },
  body2: {
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    fontWeight: fontWeights.fontWeightRegular,
  },
  body3: {
    fontSize: pxToRem(14),
    lineHeight: pxToRem(20),
    fontWeight: fontWeights.fontWeightRegular,
  },
  button: {
    fontSize: pxToRem(14),
    lineHeight: pxToRem(20),
    fontWeight: fontWeights.fontWeightBold,
  },
  button1: {
    fontSize: pxToRem(24),
    lineHeight: pxToRem(32),
    fontWeight: fontWeights.fontWeightBold,
  },
  button2: {
    fontSize: pxToRem(14),
    lineHeight: pxToRem(20),
    fontWeight: fontWeights.fontWeightRegular,
  },
  caption: {
    fontSize: pxToRem(12),
    lineHeight: pxToRem(16),
    fontWeight: fontWeights.fontWeightRegular,
  },
  ...fontWeights,
};

export default typography;
