import { AbstractControl, ValidationErrors } from '@angular/forms';
import { extractNumber } from './number';

export function phoneMask(number: string = '') {
  let phoneNumber = extractNumber(number);

  if (phoneNumber) {
    phoneNumber = phoneNumber.replace(/\D/g, '');
    phoneNumber = phoneNumber.substring(0, 11);
    phoneNumber = phoneNumber.replace(/^(\d{2})(\d)/g, '($1) $2');
    phoneNumber = phoneNumber.replace(/(\d)(\d{4})$/, '$1-$2');
    return phoneNumber;
  }
  return '';
}

export function phoneValidation(phoneNumber: string = ''): boolean {
  return (
    phoneNumber === '' || (phoneNumber.length > 9 && phoneNumber.length < 12)
  );
}

export function phoneValidator(
  control: AbstractControl
): ValidationErrors | null {
  const numberPhone = extractNumber(control.value);
  const isValid = phoneValidation(numberPhone);
  return isValid ? null : { telefone: { value: control.value } };
}
