import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import UserAvatar from './user-avatar.component';
import { getChipVariantFromPlan } from '@/react/utils/plan.utils';

const MenuDropdown = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.neutral[50],
  },
}));

const DividerWithSpacings = () => (
  <Box px={2} py={1}>
    <Divider />
  </Box>
);

const UserMenu = ({ handleMenuClick, plan, user, userMenuGroups }) => {
  const [openMenu, setOpenMenu] = React.useState(null);

  return (
    <>
      <IconButton
        onClick={(event) => setOpenMenu(event.currentTarget)}
        aria-label="open user menu"
        sx={{ p: '0' }}
      >
        <UserAvatar user={user} variant="navbar" />
      </IconButton>
      <MenuDropdown
        anchorEl={openMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        elevation={1}
        onClose={() => setOpenMenu(null)}
        open={Boolean(openMenu)}
        slotProps={{
          paper: {
            style: {
              width: 250,
            },
          },
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <CardHeader
          avatar={<UserAvatar user={user} variant="menu" />}
          title={<Typography variant="h6">Minha conta</Typography>}
          subheader={<Typography variant="body3">{user.nome}</Typography>}
          disableTypography={true}
        />

        {plan && plan.code && plan.name && (
          <Box px={2} py={1}>
            <Chip label={plan.name} variant={getChipVariantFromPlan(plan)} />
          </Box>
        )}

        {/*
                FIXME: This Divider is here in a box so we can control the margin of the first element
                of the dropdown menu. This is a workaround for the fact that the first element of the
                dropdown menu list is a Divider which is not following the theme.spacing(1) margin.
              */}

        <DividerWithSpacings />

        {userMenuGroups.map((group, index) => [
          group.map((menu) => (
            <MenuItem onClick={(e) => handleMenuClick(menu, e)}>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: (theme) => theme.typography.body3.fontSize,
                }}
              >
                {menu.label}
              </ListItemText>
              {menu.badge && <Chip color="primary" label={menu.badge} />}
            </MenuItem>
          )),
          // FIXME: This logic is here because of the same reason as the Divider above.
          userMenuGroups.length > index + 1 && <DividerWithSpacings />,
        ])}
      </MenuDropdown>
    </>
  );
};

export default UserMenu;
