import {
  Component,
  ElementRef,
  Input,
  ViewChild,
  AfterViewInit,
} from '@angular/core';

@Component({
  selector: 'component-step-indicator',
  templateUrl: './step-indicator.component.html',
  styleUrls: ['./step-indicator.component.scss'],
})
export class StepIndicatorComponent implements AfterViewInit {
  @Input('stepIndex')
  set _stepIndex(stepIndex: number) {
    this.stepIndex = stepIndex;
    if (this.responsiveSteps) {
      this.checkMobile();
    }
  }

  stepIndex: number = 0;

  @Input()
  steps: string[] = [];

  @ViewChild('responsiveSteps') responsiveSteps: ElementRef<HTMLDivElement>;

  ngAfterViewInit() {
    this.checkMobile();
  }

  checkMobile() {
    if (this.responsiveSteps.nativeElement && window.screen.width <= 576) {
      if (this.stepIndex === 0) {
        this.responsiveSteps.nativeElement.scroll({ left: 0 });
      } else {
        const divWidth = this.responsiveSteps.nativeElement.clientWidth;
        this.responsiveSteps.nativeElement.scroll({
          left: this.stepIndex * 188 + 94 - divWidth / 2,
        });
      }
    }
  }
}
