export enum Routes {
  CURSOS = 'cursos',
  COURSE_THEMES = 'temas',
  CURSOS_V2 = 'v2',
  INSCRICAO = 'inscricao',
  CHECKOUT = 'checkout',
  LISTA = 'lista',
  CUSTOM_CATEGORY = 'custom-category',
  NOVO_CATALOGO = 'novo_catalogo', // apenas para teste, não fazer commit
  PLAN_GALERY = 'plan-galery',
}
