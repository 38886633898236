import { RouterService } from './../../../services/router.service';
import { LayoutService } from 'src/app/services/layout.service';
import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';
import { ActivatedRoute } from '@angular/router';
import { getAsset } from 'src/app/utils/helpers';
import { ApiService } from 'src/app/services/api.service';
import { TranslationService } from 'src/app/services/translation.service';
import CONST from '@/constants';

@Component({
  selector: 'app-registrar',
  templateUrl: './registrar.component.html',
  styleUrls: ['./registrar.component.scss'],
})
export class RegistrarComponent implements OnInit {
  form = {
    password: '',
    passwordConfirm: '',
  };

  erros = {
    weakPassword: false,
    differentPassword: false,
    blank: false,
  };

  userName: string = '';

  submiting: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private routerService: RouterService,
    private apiService: ApiService,
    private userService: UsersService,
    private layoutService: LayoutService,
    private translate: TranslationService
  ) {}

  async getUser(token: string) {
    localStorage.setItem(CONST.TOKEN_KEY, token);
    this.apiService.getAll('registrar').subscribe(
      (response) => {
        this.userName = response.data.name.split(' ')[0];
      },
      (err) => {
        console.error(err);
        localStorage.removeItem(CONST.TOKEN_KEY);

        this.routerService.navigate(['']);
      }
    );
  }

  ngOnInit(): void {
    const { token } = this.activatedRoute.snapshot.queryParams;

    if (!token) {
      this.routerService.navigate(['']);
      return;
    }

    this.getUser(token);
  }

  getAsset = getAsset;

  onValidatePassword() {
    if (!this.form.password) {
      this.erros.weakPassword = false;
      this.erros.blank = true;
      return;
    }

    this.erros.blank = false;
    const digits = this.form.password.match(/\d/g);
    const nonDigits = this.form.password.match(/\D/g);
    this.erros.weakPassword =
      this.form.password.length < 8 || !digits || !nonDigits;

    this.onComparePassword();
  }

  onComparePassword() {
    if (this.form.password && this.form.passwordConfirm) {
      this.erros.differentPassword =
        this.form.password !== this.form.passwordConfirm;
    } else {
      this.erros.differentPassword = false;
    }
  }

  getPasswordErrorMessage() {
    return this.erros.weakPassword
      ? this.translate.getTranslation(this.getTranslation('senhaFraca'))
      : this.translate.getTranslation(this.getTranslation('erroSenha'));
  }

  onRegisterConfirm() {
    if (
      !this.erros.blank &&
      !this.erros.differentPassword &&
      !this.erros.weakPassword &&
      this.form.password &&
      this.form.passwordConfirm
    ) {
      this.submiting = true;

      this.apiService
        .post('registrar', { password: this.form.password })
        .subscribe(
          (response) => {
            this.userService.store(response.data);

            this.routerService.navigate(['']);
          },
          (err) => {
            this.layoutService.error(
              this.translate.getTranslation(
                this.getTranslation('erroDefinirSenha')
              )
            );
            this.submiting = false;
          }
        );
    }
  }

  getTranslation(key: string): string {
    return `LOGIN.REGISTRAR.${key}`;
  }
}
