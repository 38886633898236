import { Component, Input, OnInit } from '@angular/core';

import { RouterService } from 'src/app/services/router.service';

@Component({
  selector: 'app-cursos-navigation',
  templateUrl: './cursos-navigation.component.html',
  styleUrls: ['./cursos-navigation.component.scss'],
})
export class CursosNavigationComponent implements OnInit {
  @Input()
  menu: string = 'andamento';

  constructor(private routerService: RouterService) {}

  ngOnInit(): void {}

  navigate(route: string) {
    this.routerService.navigate(['eduplay', route]);
  }

  getTranslation(key: string): string {
    return `EDUPLAY.CURSOS_NAVIGATION.${key}`;
  }
}
