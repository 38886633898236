import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Sentry from '@sentry/browser';

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { IdpClient } from '@/react/data/idp/idp-client';
import { Activity, Plan } from '@/react/data/idp/interfaces';
import { grey } from '@mui/material/colors';
import { zodResolver } from '@hookform/resolvers/zod';
import { updatePlanSchema } from '@/react/data/idp/schemas';

import { ActivityCard } from '@/react/components/idp/shared/activity-card.component';
import { SectionHeader } from '@/react/components/idp/shared/section-header.component';

import {
  StartedAt,
  EstimatedEndsAt,
} from '@/react/components/idp/show-plan/date-display.component';
import { ShowPlanAction } from '@/react/components/idp/show-plan/show-plan-action';

enum EDITING_FIELD {
  TITLE = 'title',
  DESCRIPTION = 'description',
  DURATION_IN_MONTHS = 'duration_in_months',
}

const handleNewActivity = (planId: string) => {
  window.location.href = `/pdi/planos/${planId}/atividades/nova`;
};

const handleBackToPlanList = () => {
  window.location.href = '/pdi/planos';
};

type ShowPlanProps = {
  planId: string;
};

const ShowPlan = ({ planId }: ShowPlanProps) => {
  const [plan, setPlan] = React.useState<Plan>(null);
  const [activities, setActivities] = React.useState<Activity[]>(null);
  const [editingField, setEditingField] = React.useState<EDITING_FIELD>(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const inputRefs = {
    [EDITING_FIELD.TITLE]: React.useRef<HTMLInputElement>(null),
    [EDITING_FIELD.DESCRIPTION]: React.useRef<HTMLInputElement>(null),
    [EDITING_FIELD.DURATION_IN_MONTHS]: React.useRef<HTMLInputElement>(null),
  };

  const { control, handleSubmit, getValues, setValue } = useForm({
    resolver: zodResolver(updatePlanSchema),
  });

  const fetchPlan = async () => {
    try {
      const fetchedPlan = await IdpClient.showPlan(planId);
      const fetchedPlanActivities = await IdpClient.listPlanActivities(planId);
      setPlan(fetchedPlan);
      setActivities(fetchedPlanActivities);
    } catch (error) {
      Sentry.captureException(error);
      window.location.href = '/pdi/planos';
    }
  };

  const handleChange = () => {
    handleSubmit(async () => {
      try {
        const updatedPlan = await IdpClient.updatePlan(planId, getValues());
        setPlan(updatedPlan);
        setEditingField(null);
      } catch (error) {
        Sentry.captureException(error);
        setEditingField(null);
      }
    })();
  };

  const mountDurationOptions = () => {
    const options = [];
    for (let i = 1; i < 19; i += 1) {
      options.push(<MenuItem value={i}>{i}</MenuItem>);
    }
    return options;
  };

  const handleEditButton = (field: EDITING_FIELD) => {
    setEditingField(field);
  };

  useEffect(() => {
    if (planId) {
      fetchPlan();
    }
  }, [planId]);

  useEffect(() => {
    setValue('title', plan?.title);
    setValue('description', plan?.description);
    setValue('duration_in_months', plan?.durationInMonths);
  }, [plan]);

  useEffect(() => {
    inputRefs[editingField]?.current?.focus();
  }, [editingField]);

  return (
    plan && (
      <Grid container spacing={2} padding={3}>
        <Grid item xs={12}>
          <SectionHeader
            title="Plano de desenvolvimento"
            onBackClicked={handleBackToPlanList}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" fontSize={18} fontWeight={600}>
            Título do passo
          </Typography>
          <Typography variant="body2" fontSize={14}>
            [Descrição do passo]
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {editingField === EDITING_FIELD.TITLE || !plan.title ? (
            <Controller
              name="title"
              control={control}
              render={({ field }) => {
                return (
                  <FormControl fullWidth>
                    <FormLabel htmlFor="title">Título</FormLabel>
                    <TextField
                      {...field}
                      id="title"
                      placeholder="[Título do plano pré-preenchido]"
                      variant="outlined"
                      inputRef={inputRefs[EDITING_FIELD.TITLE]}
                      onBlur={handleChange}
                    />
                  </FormControl>
                );
              }}
            />
          ) : (
            <IconButton
              sx={{ width: '100%', padding: 0 }}
              onClick={() => handleEditButton(EDITING_FIELD.TITLE)}
            >
              <Box
                sx={{ width: '100%' }}
                justifyContent="space-between"
                display="flex"
              >
                <Typography variant="h5">{plan.title}</Typography>
                <EditIcon />
              </Box>
            </IconButton>
          )}
        </Grid>

        <Grid item xs={12}>
          {editingField === EDITING_FIELD.DESCRIPTION || !plan.description ? (
            <Controller
              name="description"
              control={control}
              render={({ field }) => {
                return (
                  <FormControl fullWidth>
                    <FormLabel htmlFor="description">
                      Descrição{' '}
                      <Box component="span" sx={{ pl: 1, fontWeight: 400 }}>
                        opcional
                      </Box>
                    </FormLabel>

                    <TextField
                      {...field}
                      id="description"
                      multiline
                      placeholder="[Descrição do plano]"
                      variant="outlined"
                      inputRef={inputRefs[EDITING_FIELD.DESCRIPTION]}
                      onBlur={handleChange}
                    />
                  </FormControl>
                );
              }}
            />
          ) : (
            <>
              <Typography variant="h6" fontSize={18} fontWeight={600}>
                Descrição
              </Typography>

              <IconButton
                onClick={() => handleEditButton(EDITING_FIELD.DESCRIPTION)}
                sx={{ padding: 0 }}
              >
                <Box display="flex">
                  <Typography>{plan.description}</Typography>
                  <EditIcon fontSize="small" sx={{ marginLeft: 2 }} />
                </Box>
              </IconButton>
            </>
          )}
        </Grid>

        <StartedAt plan={plan} />

        <Grid item xs={12}>
          {editingField === EDITING_FIELD.DURATION_IN_MONTHS ||
          !plan.durationInMonths ? (
            <Controller
              name="duration_in_months"
              control={control}
              render={({ field }) => {
                return (
                  <FormControl fullWidth>
                    <FormLabel htmlFor="duration_in_months">
                      Duração em meses
                    </FormLabel>

                    <Select
                      {...field}
                      variant="outlined"
                      displayEmpty
                      id="duration_in_months"
                      onChange={(e) => {
                        field.onChange(e);
                        handleChange();
                      }}
                      renderValue={(value) =>
                        value || (
                          <Typography color={grey[400]} fontSize={14}>
                            [Duração em meses]
                          </Typography>
                        )
                      }
                    >
                      {mountDurationOptions()}
                    </Select>
                  </FormControl>
                );
              }}
            />
          ) : (
            <>
              <Typography variant="h6" fontSize={18} fontWeight={600}>
                Duração em meses
              </Typography>

              <IconButton
                sx={{ paddingLeft: 0 }}
                onClick={() =>
                  handleEditButton(EDITING_FIELD.DURATION_IN_MONTHS)
                }
              >
                <Box display="flex">
                  <Typography>{plan.durationInMonths}</Typography>
                  <EditIcon fontSize="small" sx={{ marginLeft: 2 }} />
                </Box>
              </IconButton>
            </>
          )}
        </Grid>

        <EstimatedEndsAt plan={plan} />

        <Grid item xs={12}>
          <Box paddingTop={2}>
            <Typography variant="h5">Atividades</Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Button onClick={() => handleNewActivity(plan.id)} variant="text">
            Adicionar atividade
          </Button>
        </Grid>

        <Grid item xs={6}>
          <Button variant="text">Reordenar</Button>
        </Grid>

        {activities &&
          activities.map((activity) => (
            <ActivityCard planId={plan.id} activity={activity} />
          ))}

        <ShowPlanAction plan={plan} />
        {/* <Grid item xs={12} marginTop={2}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => setIsModalOpen(true)}
          >
            Iniciar plano
          </Button>
        </Grid>
        <ConfirmStartPlanDialog
          plan={plan}
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />

        <Grid item xs={12}>
          <Typography fontSize={12}>
            Inicie o plano para poder atualizar e acompanhar o progresso das
            atividades. Não se preocupe, você ainda poderá editar o plano como
            quiser.
          </Typography>
        </Grid> */}
      </Grid>
    )
  );
};

export { ShowPlan };
