const MuiInputlabel = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...{
        fontWeight: theme.typography.fontWeightRegular,
      },
    }),
  },
};

export default MuiInputlabel;
