import * as React from 'react';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { PriceTypes } from '@/react/data/catalog/interfaces';
import { formatMoney } from '@/react/utils/money';

import { useProductPage } from '../state';
import { ProductPageAsyncResources } from '../state/interfaces';

const LoadingDescription = () => (
  <>
    <Skeleton />
    <Skeleton width={'50%'} />
  </>
);

export const Refund = () => {
  const { loading: fetching, offer, subsidy } = useProductPage();
  const loading =
    fetching.includes(ProductPageAsyncResources.offer) ||
    fetching.includes(ProductPageAsyncResources.subsidy);

  if (
    !offer ||
    subsidy === undefined ||
    !subsidy?.refundable_value ||
    Number(subsidy.refundable_value.amount) === 0
  ) {
    return null;
  }

  const priceTypeLabel =
    offer.pricing_description.type === PriceTypes.tuition ? '/mês' : '';

  const refundDescription = `Reembolso disponível para esse curso: até ${formatMoney(
    subsidy?.refundable_value?.amount
  )}${priceTypeLabel ? ` ${priceTypeLabel}` : ''}.`;

  return (
    <Box
      sx={(theme) => ({
        alignItems: 'center',
        backgroundColor: theme.palette.primary['100'],
        display: 'flex',
        p: theme.spacing(2),
      })}
    >
      <Stack
        direction="row"
        spacing={2}
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
        width="100%"
      >
        <Box
          sx={(theme) => ({
            alignItems: 'center',
            backgroundColor: theme.palette.primary['300'],
            color: theme.palette.primary[900],
            display: 'flex',
            flex: '0 0 40px',
            height: '40px',
            justifyContent: 'center',
          })}
        >
          {loading ? (
            <CircularProgress
              sx={{
                color: (theme) => ({ color: theme.palette.primary[900] }),
                scale: '0.5',
              }}
            />
          ) : (
            <AttachMoneyRoundedIcon />
          )}
        </Box>
        <Typography
          color="primary.900"
          component="div"
          variant="body3"
          width="100%"
        >
          {loading ? <LoadingDescription /> : refundDescription}
        </Typography>
      </Stack>
    </Box>
  );
};
