import * as React from 'react';
import { Context } from './context';

import { State } from './interfaces';

export function useProductPage(): State {
  const context = React.useContext(Context);

  if (!context) {
    throw new Error('useProductPage must be used within a ProductPageContext');
  }

  const [state, setState] = context;

  return {
    course: state.course,
    courseId: state.courseId,
    institution: state.institution,
    isSingleOffer: state.isSingleOffer,
    loading: state.loading,
    offer: state.offer,
    updateState: setState,
    subsidy: state.subsidy,
    subscriptions: state.subscriptions,
  };
}
