import React from 'react';

import { IdpClient } from '@/react/data/idp/idp-client';
import { useNotification } from '@/react/components/notification';
import * as Sentry from '@sentry/browser';
import { IdpDialog } from '@/react/components/idp/dialogs/idp-dialog';
import { Plan } from '@/react/data/idp/interfaces';

export interface ConfirmStartPlanDialogProps {
  open: boolean;
  onClose: () => void;
  plan: Partial<Plan>;
}

const handleStartPlan = async ({ plan, onSuccess, onError }) => {
  try {
    await IdpClient.startPlan(plan.id);
    onSuccess();
  } catch (error) {
    if (error.response.status !== 422) {
      onError('Ocorreu um erro ao iniciar o plano. Tente novamente mais tarde');
      Sentry.captureException(error);
      return;
    }
    onError(
      'Só é possível ter um plano em andamento por vez. Para iniciar esse, finalize o plano em andamento.'
    );
  }
};

export const ConfirmStartPlanDialog = ({
  open,
  onClose,
  plan,
}: ConfirmStartPlanDialogProps) => {
  const { toast } = useNotification();

  const listeners = {
    onError: (error: string) => {
      toast({
        content: error,
        type: 'error',
      });
      onClose();
    },
    onSuccess: () => {
      window.location.reload();
    },
  };

  return (
    <IdpDialog
      open={open}
      onClose={onClose}
      onConfirm={() => handleStartPlan({ plan, ...listeners })}
      actionLabels={{
        confirm: 'Iniciar curso',
        cancel: 'Voltar',
      }}
      title="Você está iniciando o seu plano"
      subtitle="Vamos iniciar sua jornada de aprendizagem!"
      description="Depois de iniciado, você vai poder acompanhar e atualizar o progresso das atividades. E não se preocupe, mesmo depois de inicado é possível editá-lo à vontade."
    />
  );
};
