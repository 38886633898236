import { Component } from '@angular/core';
import { getSvg } from 'src/app/utils/helpers';

@Component({
  selector: 'app-cursos-duvidas',
  templateUrl: './cursos-duvidas.component.html',
  styleUrls: ['./cursos-duvidas.component.scss'],
})
export class CursosDuvidasComponent {
  getSvg = getSvg;

  getTranslation(key: string): string {
    return `SHARED.CURSOS_DUVIDAS.${key}`;
  }

  clickZendesk() {
    const externalLink =
      'https://atendimento.galena.com/hc/pt-br/requests/new?ticket_form_id=20704977946509';
    window.open(externalLink, '_blank');
  }
}
