import { formatDate } from '@/react/utils/date';
import { ProofOfPurchase, ProfOfPayment } from '../interfaces';

export const formatFormValues = async (
  resourceApproval,
  formValues,
  defaultValues
) => {
  const proofOfPurchase =
    resourceApproval?.refund_request?.receipts.filter((receipt) =>
      Object.keys(ProofOfPurchase).includes(receipt.type)
    ) || [];

  const proofOfPayment =
    resourceApproval?.refund_request?.receipts.filter((receipt) =>
      Object.keys(ProfOfPayment).includes(receipt.type)
    ) || [];

  const parseMonth = (month) => {
    if (typeof month === 'number') {
      return month.toString();
    }

    return month;
  };

  const base64ToBlob = (base64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  const generateFileFromJson = (receipt) => {
    const { filename, blob, content_type } = receipt.attachments.content;
    const blob_content = base64ToBlob(blob, content_type);
    return new File([blob_content], filename, { type: content_type });
  };

  const documentsProvingPurchase = proofOfPurchase.map((receipt) => {
    return generateFileFromJson(receipt);
  });

  const documentsProvingPayment = proofOfPayment.map((receipt) => {
    return generateFileFromJson(receipt);
  });

  return {
    ...defaultValues,
    ...formValues,
    documentsProvingPurchase: formValues
      ? formValues?.documentsProvingPurchase
      : documentsProvingPurchase?.length
        ? documentsProvingPurchase
        : [],

    documentsProvingPayment: formValues
      ? formValues.documentsProvingPayment
      : documentsProvingPayment.length
        ? documentsProvingPayment
        : [],

    optionalFiles: formValues?.optionalFiles || [],

    supplierName: formValues
      ? formValues?.supplierName
      : resourceApproval?.refund_request?.supplier_name || '',

    supplierType: formValues
      ? formValues?.supplierType
      : resourceApproval?.refund_request?.supplier_document?.length > 11
        ? 'legal_person'
        : 'natural_person' || 'legal_person',

    supplierDocument: formValues
      ? formValues?.supplierDocument
      : resourceApproval?.refund_request?.supplier_document || '',

    documentEmployeer: formValues
      ? formValues?.documentEmployeer
      : resourceApproval?.refund_request?.receiver_fiscal_document || '',

    paymentDate: formValues
      ? formValues?.paymentDate
      : formatDate(resourceApproval?.refund_request?.purchase_date) || '',

    paymentAmount: formValues
      ? formValues?.paymentAmount
      : resourceApproval?.refund_request?.amount || '',

    profOfPaymentPaymentDate: formValues
      ? formValues?.profOfPaymentPaymentDate
      : formatDate(resourceApproval?.refund_request?.payment_date) || '',

    profOfPaymentAmountPaid: formValues
      ? formValues?.profOfPaymentAmountPaid
      : resourceApproval?.refund_request?.purchase_amount || '',

    typeOfReceiptPurchase: formValues
      ? formValues?.typeOfReceiptPurchase
      : proofOfPurchase.length > 0
        ? 'nfe'
        : '',

    typeOfReceiptPayment: proofOfPayment.length > 0 ? 'billing_statement' : '',

    referenceMonth: formValues
      ? formValues?.referenceMonth
      : parseMonth(resourceApproval?.refund_request?.reference_month) || '',

    holderName: formValues
      ? formValues?.holderName
      : resourceApproval?.refund_request?.banking_info?.holder_name || '',

    documentEmployeerText: formValues
      ? formValues?.documentEmployeerText
      : resourceApproval?.refund_request?.receiver_fiscal_document || '',

    typeOfBankTransfer: formValues
      ? formValues.typeOfBankTransfer
      : resourceApproval?.refund_request?.banking_info?.bank
        ? 'ted'
        : 'pix' || '',

    bank: formValues
      ? formValues?.bank
      : resourceApproval?.refund_request?.banking_info?.bank || '',

    pixKey: formValues
      ? formValues?.pixKey
      : resourceApproval?.refund_request?.banking_info?.pix_key || '',

    pixType: formValues
      ? formValues?.pixType
      : resourceApproval?.refund_request?.banking_info?.pix_type || '',

    accountNumber: formValues
      ? formValues?.accountNumber
      : resourceApproval?.refund_request?.banking_info?.account_number || '',

    agency: formValues
      ? formValues?.agency
      : resourceApproval?.refund_request?.banking_info?.agency || '',
  };
};
