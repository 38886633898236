export enum WizardStepIds {
  ITEM_SOURCE_FORM = 'itemSourceForm',
  CATALOG_FORM = 'catalogForm',
  EXTERNAL_FORM = 'externalForm',
  RESOURCE_TYPE = 'resourceType',
  SUMMARY = 'summary',
  SENDING_RECEIPTS_FORM = 'sendingReceiptsForm',
  SENDING_RECEIPTS_SUMMARY = 'sendingReceiptsSummary',
  REFUND_DETAILS = 'RefundDetails',
  PAYMENT_RESUME = 'paymentResume',
}
