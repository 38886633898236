import { HTML_FONT_SIZE_IN_PX, ROOT_FONT_SIZE_IN_PX } from './constants';

/**
 * Converts input pixel value to REM unit
 *
 * @param {Number} value - Input in pixels
 *
 * @returns {String} - Output in REM
 */
const pxToRem = (value) => {
  const coef = ROOT_FONT_SIZE_IN_PX / 14;

  if (isNaN(value)) {
    throw new Error('[pxToRem] Only numbers please!');
  }

  return `${(value / HTML_FONT_SIZE_IN_PX) * coef}rem`;
};

export { pxToRem };
export default { pxToRem };
