import React from 'react';
import HelpCenterImage from './course-details-help-center-image.component';
import Button from '@mui/material/Button';
import { Section, Title } from '../components';
import { Box, Link, Stack, Typography } from '@mui/material';
import CONST from '@/constants';

export const CourseDetailsHelp = () => {
  return (
    <Section>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={5}
        padding={3}
        sx={{ backgroundColor: 'neutral.50', alignItems: 'center' }}
      >
        <Box width={{ xs: 0.8, sm: 0.5 }}>
          <HelpCenterImage />
        </Box>
        <Box width={1} textAlign={{ xs: 'center', sm: 'left' }}>
          <Title underline={false} spacing={2}>
            Ainda está com dúvida?
          </Title>
          <Typography
            color="neutral.900"
            variant="body2"
            sx={{ whiteSpace: 'pre-line' }}
            textAlign={{ xs: 'center', sm: 'left' }}
          >
            Clique no botão abaixo e acesse a central de ajuda. Caso não tenha
            encontrado sua dúvida e ainda precise de ajuda, envie sua dúvida{' '}
            <Link href={CONST.GALENA_SERVICE_REQUEST_URL} target="_blank">
              clicando aqui
            </Link>
            .
          </Typography>
          <Button
            color="primary"
            size="large"
            onClick={() =>
              window.open(CONST.GALENA_SERVICE_URL, '_blank').focus()
            }
            variant="contained"
            sx={{ mt: 3, width: { xs: '100%', sm: 'auto' } }}
          >
            Central de ajuda
          </Button>
        </Box>
      </Stack>
    </Section>
  );
};
