import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useRefundStatus } from '@/react/components/refund/utils/useRefundStatus';
import { formatDate } from '@/react/utils/date';
import { useRefundRequest } from '@/react/components/refund/refund-request/state/useRefundRequest';

export const RefundUnderReview = () => {
  return (
    <Grid container>
      <Grid item xs={12} md={4}>
        <Box
          sx={({ palette, spacing }) => ({
            color: palette.neutral[800],
            backgroundColor: palette.neutral[200],
            marginBottom: spacing(2),
            paddingLeft: spacing(3),
            paddingRight: spacing(3),
            paddingBottom: spacing('0.5'),
            paddingTop: spacing('0.5'),
            width: 'fit-content',
          })}
        >
          <Typography variant="body3">Aguarde: aprovação pendente</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export const RefundDeclined = ({ resourceApproval }) => {
  return (
    <Grid container>
      <Grid item xs={12} md={4} justifyContent="center">
        <Box
          sx={({ palette, spacing }) => ({
            color: palette.error[900],
            backgroundColor: palette.error[100],
            padding: '4px  12px',
            marginBottom: spacing(2),
            paddingLeft: spacing(3),
            paddingRight: spacing(3),
            paddingBottom: spacing('0.5'),
            paddingTop: spacing('0.5'),
            width: 'fit-content',
          })}
        >
          <Typography variant="body3">
            {resourceApproval?.refund_request?.decided_at &&
              `Documentação reprovada em: ${formatDate(resourceApproval?.refund_request?.decided_at)}`}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body3">
          {resourceApproval?.refund_request?.decision_comment}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const CorrectionNeeded = ({ resourceApproval }) => {
  return (
    <Grid container>
      <Grid item xs={12} md={4} justifyContent="center">
        <Box
          sx={({ palette, spacing }) => ({
            color: palette.error[900],
            backgroundColor: palette.warning[100],
            padding: '4px  12px',
            marginBottom: spacing(2),
            paddingLeft: spacing(3),
            paddingRight: spacing(3),
            paddingBottom: spacing('0.5'),
            paddingTop: spacing('0.5'),
          })}
        >
          <Typography variant="body3">Correção necessária</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body3">
          {resourceApproval?.refund_request?.decision_comment
            ? resourceApproval?.refund_request?.decision_comment
            : 'Item não corresponde ao pedido'}
        </Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        <Button
          color="primary"
          size="large"
          fullWidth
          sx={{ borderRadius: 0, marginTop: 2 }}
          variant="contained"
          href={`/meus-reembolsos/solicitacao/${resourceApproval.uuid}/pedido/${resourceApproval.refund_request.uuid}/editar-comprovante`}
        >
          Corrigir
        </Button>
      </Grid>
    </Grid>
  );
};

export const RefundCorrectionsRequired = ({ resourceApproval }) => {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        justifyContent="center"
        sx={({ palette, spacing }) => ({
          color: palette.neutral[800],
          backgroundColor: 'transparent',
          marginBottom: spacing(2),
          paddingLeft: spacing(3),
          paddingRight: spacing(3),
          paddingBottom: spacing('0.5'),
          paddingTop: spacing('0.5'),
        })}
      >
        <Typography variant="body3">
          {`Aprovado em: ${formatDate(resourceApproval?.refund_request?.decided_at)}`}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const InvoiceValidationSummary = () => {
  const { resourceApproval } = useRefundRequest();
  const { mappedStatus } = useRefundStatus(resourceApproval);

  return (
    <>
      {(mappedStatus === 'waitingForDocumentationRevisionStepFour') && <RefundUnderReview />}

      {(mappedStatus === 'canceledRefundRequestStepThree' ||
        mappedStatus === 'declinedRefundRequestStepFour') && (
        <RefundDeclined resourceApproval={resourceApproval} />
      )}

      {mappedStatus === 'needsRefundRequestRevisionStepFour' && (
        <CorrectionNeeded resourceApproval={resourceApproval} />
      )}

      {['pendingRefundPaymentStepFive', 'paidRefundRequestStepFive'].includes(mappedStatus) && (
        <RefundCorrectionsRequired resourceApproval={resourceApproval} />
      )}
    </>
  );
};
