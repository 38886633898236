import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { default as MuiPagination } from '@mui/material/Pagination';

const Pagination = ({ currentPage, totalPages, pageChange }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const paginationOptions = {
    siblingCount: isMobile ? 0 : 1,
    boundaryCount: isMobile ? 1 : 2,
    size: isMobile ? 'medium' : 'large',
  };

  const handlePageChange = (_, value) => {
    if (pageChange) pageChange(value);

    document.querySelector('.scrollable-container').scrollTo(0, 0);
  };

  return (
    <MuiPagination
      color="primary"
      count={totalPages}
      page={currentPage}
      onChange={handlePageChange}
      siblingCount={paginationOptions.siblingCount}
      boundaryCount={paginationOptions.boundaryCount}
      size={paginationOptions.size}
    />
  );
};

export default Pagination;
