<app-card
  (click)="onCardClick()"
  innerClass="d-flex flex-column curso-card {{
    courseCard.cardHighlight ? 'edupass-indica-border' : ''
  }} {{ detailsButton ? 'h-100' : '' }}"
  [clickable]="isClickable"
  *ngIf="courseCard"
>
  <div class="row" *ngIf="courseCard.cardHighlight">
    <div
      class="column Body-1 mt-2 bestFriday align-items-end ml-auto vertical-align"
    >
      EM DESTAQUE
    </div>
  </div>
  <img
    class="instituicao-imagem"
    [src]="courseCard.getImage()"
    alt="logo {{ courseCard.institution }}"
  />
  <h2
    class="text-center"
    [ngClass]="{
      'small-title': courseCard.name.length > 35
    }"
  >
    {{ courseCard.name }}
  </h2>

  <ng-container *ngIf="courseCard">
    <br />
    <div class="marketing-description">
      {{ courseCard.cardSubstitle }}
    </div>
  </ng-container>

  <hr class="w-100 mt-auto" />

  <div class="row">
    <div *ngIf="!leroyUser" class="col-6 border-right text-center">
      <span style="font-weight: 600; display: block; font-size: 0.9rem">
        {{ getTranslation('valorEdupass') | translate }}
      </span>

      <span *ngIf="!courseCard.priceUponRequest">
        <span
          *ngIf="courseCard.getPaymentType() === 'INSTALLMENTS'"
          class="BodyText-1"
        >
          em até {{ courseCard.instalments }} vezes de
          {{ courseCard.getFormattedInstallmentPrice() }}
        </span>

        <span
          *ngIf="courseCard.getPaymentType() !== 'INSTALLMENTS'"
          class="edupass-price BodyText-1-SemiBold"
        >
          {{ getPriceOrCourseFree() }}
        </span>
      </span>

      <p
        *ngIf="
          !courseCard.priceUponRequest && courseCard.getInstallmentPrice() !== 0
        "
        class="BodyText-2 mt-2"
      >
        ({{ getTranslation('ultimaAtualizacao') | translate }}
        {{ courseCard.lastUpdate }})
      </p>

      <p *ngIf="courseCard.priceUponRequest" class="BodyText-1 mt-2">
        {{ getTranslation('precoSobConsulta') | translate }}
      </p>
    </div>

    <div *ngIf="leroyUser" class="col-6 border-right text-center mt-3">
      <span class="BodyText-2">
        {{ getTranslation('leroyValue') | translate }}
      </span>
      <br />
      <span
        *ngIf="!courseCard.priceUponRequest"
        class="edupass-price BodyText-2-Bold"
      >
        {{ courseCard.getFormattedInstallmentPrice() }}
      </span>

      <p
        *ngIf="
          !courseCard.priceUponRequest && courseCard.getInstallmentPrice() !== 0
        "
        class="BodyText-2 mt-2"
      >
        ({{ getTranslation('ultimaAtualizacao') | translate }}
        {{ courseCard.lastUpdate }})
      </p>

      <p *ngIf="courseCard.priceUponRequest" class="BodyText-1">
        {{ getTranslation('precoSobConsulta') | translate }}
      </p>
    </div>

    <div
      class="col-6 d-flex flex-column align-items-center justify-content-center text-center"
    >
      <div *ngIf="!leroyUser">
        <span sytle="font-weight: 600">
          {{ getTranslation('bolsa') | translate }}
        </span>
        <br />
        <span class="edupass-price BodyText-1-SemiBold">
          {{ courseCard.priceUponRequest ? 'até ' : '' }}
          {{ getDiscount() }}%
        </span>
      </div>

      <div *ngIf="leroyUser">
        <span class="BodyText-2">
          {{ getTranslation('bolsa') | translate }}
        </span>
        <br />
        <span class="edupass-price BodyText-2-Bold">
          {{ courseCard.priceUponRequest ? 'até ' : '' }}
          {{ courseCard.getDiscount() }}%
        </span>

        <div>
          <span class="mt-1 BodyText-2">
            {{ getTranslation('leroyMerlin') | translate }}
          </span>
          <br />
          <span class="edupass-price BodyText-2-Bold">
            {{ getTranslation('leroyDiscount') | translate }}
          </span>
          <br />
          <component-tooltip
            [message]="getTranslation('leroyTooltip') | translate"
          ></component-tooltip>
        </div>
      </div>
    </div>
  </div>

  <hr class="mt-2 w-100" />

  <div class="row">
    <div class="col-6 mt-2">
      <div class="d-flex flex-row" style="font-size: 0.9rem">
        <span class="material-icons-outlined notranslate mr-1">
          menu_book
        </span>
        {{
          courseCard.priceUponRequest
            ? 'sob consulta'
            : courseCard.categoryDetailed
        }}
      </div>
    </div>

    <div class="col-6 mt-2">
      <div class="d-flex flex-row" style="font-size: 0.9rem">
        <span class="material-icons-outlined notranslate mr-1">
          {{ courseCard.modalityId > 1 ? 'emoji_people' : 'desktop_mac' }}
        </span>
        {{ courseCard.priceUponRequest ? 'sob consulta' : courseCard.modality }}
      </div>
    </div>
    <div class="col-6 mt-2">
      <div class="d-flex flex-row" style="font-size: 0.9rem">
        <span class="material-icons-outlined notranslate mr-1">schedule</span>
        {{ courseCard.priceUponRequest ? 'sob consulta' : courseCard.turn }}
      </div>
    </div>

    <div class="col-6 mt-2">
      <div class="d-flex flex-row" style="font-size: 0.9rem">
        <span class="material-icons-outlined notranslate mr-1"
          >location_city</span
        >
        {{
          courseCard.priceUponRequest ? 'sob consulta' : courseCard.institution
        }}
      </div>
    </div>

    <div class="col-6 mt-2">
      <div class="d-flex flex-row mb-4" style="font-size: 0.9rem">
        <span class="material-icons-outlined notranslate mr-1"
          >insert_invitation</span
        >
        {{ courseCard.priceUponRequest ? 'sob consulta' : courseCard.duration }}
      </div>
    </div>
    <div class="col-6 mt-2">
      <div class="d-flex flex-row" style="font-size: 0.9rem">
        <span class="material-icons-outlined notranslate mr-1">place</span>
        {{ courseCard.priceUponRequest ? 'sob consulta' : courseCard.city }}
      </div>
    </div>
  </div>

  <ng-content></ng-content>
</app-card>

<p *ngIf="leroyUser" class="BodyText-2 mt-3">
  <span class="BodyText-2-Bold">{{
    getTranslation('attention') | translate
  }}</span>
  {{ getTranslation('leroyAttention') | translate }}
</p>

<div class="skeleton" *ngIf="!courseCard"></div>
